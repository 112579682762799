import { gql } from "apollo-angular";

export const FIND_ALL_PAPERS = gql`
  query findAllPaperDefinition {
    findAllPaperDefinition {
      id
      lastModifiedDate
      createdDate
      name
      grammatur
      price
      volumen
      paperCertificationList {
        id
        name
      }
      paperId
      rollenBogenPapier
      stoffklasse
      faerbung
    }
  }
`;

export const FIND_PAPERS_BY_ID = gql`
  query getPaperdefinition($id: UUID!) {
    getPaperdefinition(id: $id) {
      id
      lastModifiedDate
      createdDate
      name
      grammatur
      price
      volumen
      paperCertificationList {
        id
        name
      }
      paperId
      rollenBogenPapier
      stoffklasse
      faerbung
    }
  }
`;

export const FIND_PAPERS_WITH_PRODUCTID = gql`
  query findAllPaperDefinitionByProdukt($produktId: UUID!) {
    findAllPaperDefinitionByProdukt(produktId: $produktId) {
      id
      lastModifiedDate
      createdDate
      name
      grammatur
      price
      volumen
      paperCertificationList {
        id
        name
      }
      paperId
      rollenBogenPapier
      stoffklasse
      faerbung
    }
  }
`;

export const CREATE_PAPER = gql`
  mutation createPaperdefinition(
    $rollenBogenPapier: String!
    $name: String!
    $stoffklasse: String!
    $faerbung: String!
    $grammatur: Float!
    $volumen: Float!
    $price: BigDecimal!
    $paperCertificationList: [UUID]
  ) {
    createPaperdefinition(
      rollenBogenPapier: $rollenBogenPapier
      name: $name
      stoffklasse: $stoffklasse
      faerbung: $faerbung
      grammatur: $grammatur
      volumen: $volumen
      price: $price
      paperCertificationList: $paperCertificationList
    ) {
      id
      name
    }
  }
`;

export const UPDATE_PAPER = gql`
  mutation updatePaperdefinition(
    $id: UUID!
    $rollenBogenPapier: String!
    $name: String!
    $stoffklasse: String!
    $faerbung: String!
    $grammatur: Float!
    $volumen: Float!
    $price: BigDecimal!
    $paperCertificationList: [UUID]
  ) {
    updatePaperdefinition(
      id: $id
      rollenBogenPapier: $rollenBogenPapier
      name: $name
      stoffklasse: $stoffklasse
      faerbung: $faerbung
      grammatur: $grammatur
      volumen: $volumen
      price: $price
      paperCertificationList: $paperCertificationList
    ) {
      id
      name
    }
  }
`;

export const DELETE_PAPER = gql`
  mutation deletePaper($id: UUID!) {
    deletePaper(id: $id)
  }
`;

export const FIND_ALL_PAPER_CERTIFICATION = gql`
  query findAllPaperCertification {
    findAllPaperCertification {
      lastModifiedDate
      createdDate
      id
      name
    }
  }
`;

export const DELETE_PAPER_PRODUKT = gql`
  mutation deletePaperProduktAssociation(
    $produktId: UUID!
    $paperdefinitionId: UUID!
  ) {
    deletePaperProduktAssociation(
      produktId: $produktId
      paperdefinitionId: $paperdefinitionId
    )
  }
`;

export const MERGE_PAPER_PRODUKT = gql`
  mutation mergePaperProduktAssociations($produktId: UUID!, $papers: [UUID!]!) {
    mergePaperProduktAssociations(produktId: $produktId, papers: $papers) {
      id
      lastModifiedDate
      createdDate
      name
      grammatur
      price
      volumen
      paperCertificationList {
        id
        name
      }
      paperId
      rollenBogenPapier
      stoffklasse
      faerbung
    }
  }
`;
