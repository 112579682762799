import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { PriceScaleDataSource } from "../../../model/calculation/produkt";
import { DxDataGridModule } from "devextreme-angular";

@Component({
  selector: "app-product-price-scale-datagrid",
  templateUrl: "./product-price-scale-datagrid.component.html",
  styleUrls: ["./product-price-scale-datagrid.component.scss"],
})
export class ProductPriceScaleDatagridComponent implements OnInit, OnChanges {
  @Input()
  dataSource: PriceScaleDataSource[];
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if ("datasource" in changes) console.log(this.dataSource);
  }

  ngOnInit(): void {}
}
@NgModule({
  declarations: [ProductPriceScaleDatagridComponent],
  exports: [
    ProductPriceScaleDatagridComponent,
    ProductPriceScaleDatagridComponent,
  ],
  imports: [DxDataGridModule],
})
export class ProductPriceScaleDatagridModule {}
