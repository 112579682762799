<div class="content-block" id="container">
  <h2>{{ formatMessage("optionMapping.title") }}</h2>
  <div
    class="d-flex align-items-center"
    style="padding-bottom: 20px; border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
  >
    <div style="margin-right: 0.5rem">
      {{ formatMessage("option.selectedPreislistenKatalog") }}
    </div>
    <dx-lookup
      #preislistenKatalogLookup
      *ngIf="priceData"
      [dataSource]="priceData"
      displayExpr="name"
      style="width: 400px"
      [value]="selectedPriceData"
      [searchEnabled]="true"
      (onValueChanged)="preislistenKatalogSelected($event)"
    >
    </dx-lookup>

    <dx-button
      stylingMode="contained"
      type="default"
      icon="close"
      [disabled]="!selectedPriceData"
      style="margin-left: 12px"
      (onClick)="resetSelectedPriceData($event)"
    ></dx-button>
  </div>
  <h3 class="sub-headline" style="margin-top: 20px">
    {{ formatMessage("optionMapping.unmappedOptions") }}
  </h3>
  <dx-data-grid
    id="unmappedOptionGrid"
    [dataSource]="unmappedOptions"
    keyExpr="id"
    [showBorders]="true"
    (onSaved)="saveUnmappedOptions($event)"
    (onCellClick)="handleWildcardEvent($event, 'unmapped')"
  >
    <dxo-load-panel [enabled]="unmappedGridLoading"> </dxo-load-panel>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing
      mode="batch"
      [allowUpdating]="true"
      [selectTextOnEditStart]="true"
      [startEditAction]="'click'"
    >
    </dxo-editing>

    <dxi-column
      dataField="optionName"
      caption="Option Name"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="optionTypeDisplayName"
      caption="Option Type"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="optionMappingProperties.specification"
      caption="Specification"
      [visible]="!!selectedPriceData"
    ></dxi-column>
    <dxi-column
      dataField="id"
      caption="ID"
      [allowEditing]="false"
      [visible]="false"
    ></dxi-column>
  </dx-data-grid>

  <div *ngIf="!!selectedPriceData">
    <h3 class="sub-headline">
      {{ formatMessage("optionMapping.mappedOptions") }}
    </h3>
    <dx-data-grid
      id="mappedOptionGrid"
      [dataSource]="mappedOptions"
      keyExpr="id"
      [showBorders]="true"
      (onSaved)="saveMappedOptions($event)"
      (onCellClick)="handleWildcardEvent($event, 'mapped')"
    >
      <dxo-load-panel [enabled]="mappedGridLoading"> </dxo-load-panel>
      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-editing
        mode="batch"
        [allowUpdating]="true"
        [selectTextOnEditStart]="true"
        [startEditAction]="'click'"
      >
      </dxo-editing>

      <dxi-column
        dataField="optionName"
        caption="Option Name"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="optionTypeDisplayName"
        caption="Option Type"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="optionMappingProperties.specification"
        caption="Specification"
      ></dxi-column>
      <dxi-column
        dataField="id"
        caption="ID"
        [allowEditing]="false"
        [visible]="false"
      ></dxi-column>
    </dx-data-grid>
  </div>
</div>
