import { Injectable } from "@angular/core";
import { LocaleService } from "./locale.service";
import * as moment from "moment";
import "moment/locale/de";
import { filter } from "rxjs/operators";

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

@Injectable()
export class MomentService {
  private _moment: any;

  constructor(ls: LocaleService) {
    this._moment = moment;
    let observable = ls
      .getCurrentLocale()
      .pipe(filter(inputIsNotNullOrUndefined))
      .toPromise()
      .then((value) => {
        this._moment.locale(value);
      });
  }

  get moment(): any {
    return this._moment;
  }
}
