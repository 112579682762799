<div
  class="d-flex flex-grow dx-card wide-card home-card"
  [class]="selected ? 'home-card-selected' : ''"
>
  <div class="d-flex flex-grow align-items-center right-divider">
    <div class="d-flex rounded-icon">
      <i [class]="'dx-icon-' + icon" style="font-size: 22px"></i>
    </div>
    <div class="d-flex flex-column">
      <span class="home-card-text">
        {{ title.first }}
      </span>
      <span class="home-card-text">{{ title.second }}</span>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <dx-load-indicator
      [visible]="loading"
      class="loading-quantity"
      height="18px"
      width="18px"
    ></dx-load-indicator>
    <span *ngIf="!loading" class="quantity">{{
      quantity != undefined ? quantity : "?"
    }}</span>
  </div>
</div>
