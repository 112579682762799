import { Component, Input, OnInit } from "@angular/core";
import { AncillaryItem } from "../../../../model/calculation/calculationResult";
import { CalculationTableConfig } from "../calculator-us-formData";
import { CalculatorService } from "../../../../services/calculator.service";

@Component({
  selector: "app-ancillary-item-text-prep",
  templateUrl: "./ancillary-item-text-prep.component.html",
  styleUrls: ["./ancillary-item-text-prep.component.scss"],
})
export class AncillaryItemTextPrepComponent implements OnInit {
  @Input()
  calcReponse: any;

  @Input()
  currentAncillaryItem: AncillaryItem;

  @Input()
  currentAncillaryItemIndex: number;

  @Input()
  calculationTableConfig: CalculationTableConfig;

  constructor(public calculatorService: CalculatorService) {}

  ngOnInit(): void {}
}
