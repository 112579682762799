import { Component, OnInit } from "@angular/core";
import { LocaleService } from "src/app/shared/services/locale.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-imprint",
  templateUrl: "./imprint.component.html",
  styleUrls: ["./imprint.component.scss"],
})
export class ImprintComponent implements OnInit {
  locale: Observable<string | null>;
  constructor(private localeService: LocaleService) {}

  ngOnInit(): void {
    this.locale = this.localeService.getCurrentLocale();
  }
}
