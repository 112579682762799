import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxButtonModule, DxDataGridModule } from "devextreme-angular";
import {
  DxiColumnModule,
  DxoEditingModule,
  DxoPagingModule,
} from "devextreme-angular/ui/nested";
import { OptionFormData } from "../../../../model/calculation/optionFormData";
import {
  ColumnFormData,
  OptionPriceInformation,
} from "../../../../model/calculation/optionPriceInformation";
import { ColumnFormPopupMoule } from "../option-data-grid-column-form/column-form-popup/column-form-popup.component";
import { ConfirmationDialogModule } from "../../../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-option-data-grid-difference",
  templateUrl: "./option-data-grid-difference.component.html",
  styleUrls: ["./option-data-grid-difference.component.scss"],
})
export class OptionDataGridDifferenceComponent implements OnInit {
  @Input() optionData: OptionFormData;
  @Output() optionDataChange = new EventEmitter<OptionFormData>();

  columnFormPopupVisible = false;
  columnFormData: ColumnFormData = new ColumnFormData();

  columnDialogVisible: boolean = false;
  columnDialogMessage: string;
  columnDialogData: any;

  constructor() {}

  ngOnInit(): void {}

  createColumn() {
    if (
      !this.optionData.optionPriceInformationColumnNames ||
      this.optionData.optionPriceInformationColumnNames.length === 0
    ) {
      this.columnFormData = new ColumnFormData();
      this.columnFormPopupVisible = true;
    }
  }

  saveColumnData(data: ColumnFormData): void {
    if (data.index === -1) {
      this.optionData.optionPriceInformationColumnNames.push(data.name);
    } else {
      this.optionData.optionPriceInformationColumnNames[data.index] = data.name;
    }
  }

  showColumnConfirmationDialog(data) {
    this.columnDialogData = data;
    this.columnDialogMessage = `Are you sure to delete column ${data.column.caption}.`;
    this.columnDialogVisible = true;
  }

  onCancelColumnConfirmationDialog() {
    this.columnDialogVisible = false;
  }

  deleteColumnData(data): void {
    const columnName = data.column.caption;
    const columnNameIndex =
      this.optionData.optionPriceInformationColumnNames?.findIndex(
        (name) => name == columnName
      );
    const optionPriceInformationLength =
      this.optionData.optionPriceInformation.length;
    console.log(columnName);
    console.log(columnNameIndex);
    console.log(this.optionData.optionPriceInformationColumnNames.length);

    if (columnNameIndex !== -1 && optionPriceInformationLength > 0) {
      for (let i = 0; i < optionPriceInformationLength; i++) {
        for (
          let j = columnNameIndex;
          j < this.optionData.optionPriceInformationColumnNames.length;
          j++
        ) {
          if (
            j ==
            this.optionData.optionPriceInformationColumnNames.length - 1
          ) {
            this.optionData.optionPriceInformation[i][`col${j}`] = undefined;
          } else {
            this.optionData.optionPriceInformation[i][`col${j}`] =
              this.optionData.optionPriceInformation[i][`col${j + 1}`];
          }
        }
      }
    }
    this.optionData.optionPriceInformationColumnNames?.splice(
      columnNameIndex,
      1
    );
    if (this.optionData.optionPriceInformationColumnNames.length == 0)
      this.optionData.optionPriceInformation = [];
    this.columnDialogVisible = false;
  }

  editColumn(data) {
    const colNameIndex =
      this.optionData.optionPriceInformationColumnNames.findIndex(
        (name) => name == data.column.caption
      );
    console.log(colNameIndex);
    this.columnFormData = new ColumnFormData(data.column.caption, colNameIndex);
    console.log(this.columnFormData);
    this.columnFormPopupVisible = true;
  }

  getDatafield(i: number): string {
    return `col${i}`;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxiColumnModule,
    DxoEditingModule,
    DxoPagingModule,
    DxButtonModule,
    ColumnFormPopupMoule,
    ConfirmationDialogModule,
  ],
  declarations: [OptionDataGridDifferenceComponent],
  exports: [OptionDataGridDifferenceComponent],
})
export class OptionDataGridDifferenceModule {}
