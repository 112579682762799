<tr>
  <td class="headline">{{ headLineText }}</td>
  <td></td>
  <td *ngIf="calculationTableConfig.showSecondCol"></td>
  <td *ngIf="calculationTableConfig.showThirdCol"></td>
</tr>

<app-ancillary-item
  style="display: contents"
  *ngFor="let item of displayedAncillaryItems[0]; index as i"
  [calculationTableConfig]="calculationTableConfig"
  [displayedAncillaryItems]="displayedAncillaryItems"
  [ancillaryItemName]="item.ancillaryCodeName"
  [index]="i"
></app-ancillary-item>

<tr class="sub-total" *ngIf="true">
  <td>{{ "Sub-Total" }}</td>
  <td>{{ calculatorService.convertToLocaleUsPriceString(subTotals[0], 2) }}</td>
  <td *ngIf="calculationTableConfig.showSecondCol">
    {{ calculatorService.convertToLocaleUsPriceString(subTotals[1], 2) }}
  </td>
  <td *ngIf="calculationTableConfig.showThirdCol">
    {{ calculatorService.convertToLocaleUsPriceString(subTotals[2], 2) }}
  </td>
</tr>
