import {
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { CommonModule } from "@angular/common";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxListComponent,
  DxListModule,
  DxPopoverModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToastModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { OrderService } from "../../services/order.service";
import {
  isReadyForPrinting,
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../model/order/order";
import DataSource from "devextreme/data/data_source";
import { Router, RouterModule } from "@angular/router";
import { P3AuthService } from "../../services";
import { Subscription } from "rxjs";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import {
  asStudio,
  Studio,
  StudioEntity,
} from "../../model/organization/organization";
import { OrganizationUnionTagModule } from "../tags/organization-union-tag/organization-union-tag.component";
import { OrganizationTagModule } from "../tags/organization-tag/organization-tag.component";
import { OrganizationService } from "../../services/organization.service";
import { compareStringsAlphabeticallyIgnoreCase } from "../../utils/stringUtils";
import {
  OrderStudioPopupModule,
  UpdateOrdersStudiosAssignmentsEvent,
} from "../order-studio-popup/order-studio-popup.component";
import CustomStore from "devextreme/data/custom_store";
import { ConfirmationDialogModule } from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-order-grid",
  templateUrl: "./order-grid.component.html",
  styleUrls: ["./order-grid.component.scss"],
})
export class OrderGridComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false })
  orderGrid: DxDataGridComponent;

  @ViewChild(DxListComponent, { static: false })
  studioList: DxListComponent;

  isAllowedToViewOrderDetails: boolean;
  isAllowedToChangeOrdersStudiosAssignments: boolean;

  subscriptions: Subscription[] = [];

  asStudio = asStudio;
  formatMessage = formatMessage;
  currentOrder: Order;
  helpPopupVisible: boolean = false;

  dataSourceDs: DataSource;
  allOrders: Order[];

  allStudios: Studio[];
  headerFilterStudiosOfPublisher: any[];
  headerFilterStudios: any[];

  pagerText: string;

  locale;
  stateStoreDelay: number = 0;

  readonly allowedPageSizes = [10, 20, 50];
  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;

  orderStudioPopupVisible: boolean = false;
  orderStudioPreFilter: any[] | undefined;
  ordersLoaded: boolean = false;

  studiosPopoverVisible: boolean = false;
  popoverTarget: string;
  studiosForPopover: any[];
  preSelectedStudiosIds: string[];

  showToast: boolean = false;
  toastMessage: string = "For some reason we need an initial value here...";
  toastType: string = "default";

  showConfirmationPopup: boolean = false;

  constructor(
    private router: Router,
    public orderService: OrderService,
    private organizationService: OrganizationService,
    private _p3AuthService: P3AuthService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {
    this.translateProductType = this.translateProductType.bind(this);
    this.translateStatus = this.translateStatus.bind(this);
  }

  ngOnInit(): void {
    this._p3AuthService.getUser().subscribe((e) => {
      if (e.identity.locale) {
        this.locale = e.identity.locale;
      }
    });

    const isAllowedToViewOrderDetails = this._p3AuthService
      .isAllowedToViewOrderDetails()
      .subscribe((result) => (this.isAllowedToViewOrderDetails = result));

    const isAllowedToChangeOrdersStudiosRelation = this._p3AuthService
      .isAllowedToChangeOrdersStudiosAssignments()
      .subscribe((data) => {
        this.isAllowedToChangeOrdersStudiosAssignments = data;
      });

    this.subscriptions.push(
      isAllowedToViewOrderDetails,
      isAllowedToChangeOrdersStudiosRelation
    );

    const store: any = new CustomStore({
      key: "id",
      load: () => {
        return this.orderService
          .getOrdersAsJsonByNativeQuery()
          .toPromise()
          .then((result) => {
            this.allOrders = result.map((order) => ({
              ...order,
              deliveryDateWithHighestQuantity:
                this.getDeliveryDateWithHighestQuantity(order),
              jobtype: this.getJobtype(order),
            }));

            this.ordersLoaded = true;

            return this.allOrders;
          })
          .catch((e) => {
            throw e;
          });
      },
    });

    this.dataSourceDs = new DataSource({
      store: store,
      pushAggregationTimeout: 0,
    });

    this.organizationService
      .findAllStudios()
      .toPromise()
      .then((studios) => {
        const sortedStudios = studios.sort((a, b) =>
          compareStringsAlphabeticallyIgnoreCase(a.displayName, b.displayName)
        );

        this.allStudios = sortedStudios;

        this.headerFilterStudiosOfPublisher = sortedStudios.map((it) => ({
          text: it.displayName,
          value: ["publisher.standardStudios", "contains", it.displayName],
        }));

        this.headerFilterStudios = sortedStudios.map((it) => ({
          text: it.displayName,
          value: ["studios", "contains", it.displayName],
        }));
      });
  }

  contentReady(): void {
    this.resetPaging();
    this.updatePagerInfo();
  }

  showConfirmDeletePopup(): void {
    this.showConfirmationPopup = true;
  }

  resetLocalStorage(): void {
    window.localStorage.removeItem("p3-orders");
    window.location.reload();
  }

  resetPaging(): void {
    //BPGP3M-227
    let localStorageString = window.localStorage.getItem("p3-orders");
    let gridColumns = this.orderGrid.instance.getVisibleColumns();
    if (localStorageString) {
      let localStorage = JSON.parse(localStorageString);
      localStorage.columns.forEach((e) => {
        // triggered with sorting by column
        if (e.sortIndex != null && gridColumns[e.visibleIndex]) {
          if (
            gridColumns[e.visibleIndex]?.sortIndex != e.sortIndex ||
            gridColumns[e.visibleIndex]?.sortOrder != e.sortOrder ||
            gridColumns[e.visibleIndex]?.filterValue != e.filterValue
          ) {
            this.orderGrid.instance.clearSelection();
            this.orderGrid.instance.option("focusedRowIndex", -1);
          }
        }
      });
    }
  }

  updatePagerInfo(): void {
    //BPGP3M-318
    let currentPageSize = this.orderGrid.instance.pageSize();
    let currentPage = this.orderGrid.instance.pageIndex() + 1;
    let totalEntries = this.orderGrid.instance.totalCount();

    let shownRows = new Array();
    shownRows[0] = currentPageSize * currentPage - (currentPageSize - 1); //min value per page
    if (currentPage * currentPageSize < totalEntries) {
      shownRows[1] = currentPageSize * currentPage; //max value per page
    } else {
      shownRows[1] = totalEntries;
    }
    shownRows[2] = totalEntries; //total amount of entries

    if (shownRows[0] != 0) {
      if (this.locale == "en-EN") {
        this.pagerText = `Showing ${shownRows[0]} to  ${shownRows[1]} of
        ${shownRows[2]} orders`;
      } else {
        this.pagerText = `Auftrag ${shownRows[0]} bis  ${shownRows[1]} von
        ${shownRows[2]}`;
      }
    }
  }

  getJobtype(order): string | null {
    if (isReadyForPrinting(order)) {
      return formatMessage("order.jobtype.approved");
    } else {
      return formatMessage("order.jobtype.withDataEditing");
    }
  }

  getDeliveryDateWithHighestQuantity(order): Date | null {
    if (!!order?.order?.delivery) {
      let deliveries = [...order.order.delivery];

      deliveries.sort((a, b) => {
        return b.quantity - a.quantity;
      });

      return deliveries[0].datelatest;
    }
    return null;
  }

  exportGrid(e: any): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  }

  showHelp(): void {
    this.helpPopupVisible = true;
  }

  navigateToUpload(order: Order): void {
    this.navigateAndResetTreeView(`/upload/${order.id}`);
  }

  navigateToOrderDetailsOnRowDoubleClick($event): void {
    this.navigateToOrderDetails($event.data);
  }

  navigateToOrderDetails(order: Order): void {
    this.navigateAndResetTreeView(`/orderdetails/${order.id}`);
  }

  private navigateAndResetTreeView(path: string): void {
    this.router.navigate([path]);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }

  translateProductType(rowData): string {
    const productType = this.orderService.mapCategoryToProductType(
      rowData.order.titleinfo.category
    );
    return formatMessage(`productType.${productType}`);
  }

  translateStatus(rowData): string {
    if (!!rowData.status.status) {
      return formatMessage(`order.statusValue.${rowData.status.status}`);
    }
    return formatMessage(`order.statusValue.NOT_SET`);
  }

  isValidCategory(order: Order): boolean {
    return this.orderService.isValidCategory(order.order.titleinfo.category);
  }

  hasCurrentOrderValidCategory(): boolean {
    if (this.currentOrder) {
      return this.orderService.isValidCategory(
        this.currentOrder.order.titleinfo.category
      );
    } else {
      return true;
    }
  }

  asStudios(studioEntities: StudioEntity[]): Studio[] {
    return !!studioEntities ? studioEntities.map((it) => asStudio(it)) : [];
  }

  calculateStudiosOfPublisherCellValue(rowData): string[] {
    return rowData.publisher?.standardStudios?.map((it) => it.displayName);
  }

  calculateStudiosCellValue(rowData): string[] {
    return rowData.studios?.map((it) => it.displayName);
  }

  filterStudios(filterValue): any {
    const column = this as any;

    return [column.calculateCellValue, "contains", filterValue];
  }

  openOrderStudioPopup(): void {
    this.orderStudioPreFilter = this.orderGrid.instance.getCombinedFilter();
    this.orderStudioPopupVisible = true;
  }

  prepareStudiosPopover(rowData: any): void {
    const standardStudios = rowData.data.publisher.standardStudios;
    const assignedStudios = rowData.data.studios;

    this.studiosForPopover = this.allStudios.map((studio) => ({
      ...studio,
      disabled:
        standardStudios.findIndex(
          (standardStudio) => standardStudio.id === studio.id
        ) != -1,
    }));

    this.preSelectedStudiosIds = [
      ...standardStudios.map((it) => it.id),
      ...assignedStudios.map((it) => it.id),
    ];

    this.popoverTarget = "#btn" + rowData.rowIndex;
    //this.studioList.searchValue = ""; ?
    this.studiosPopoverVisible = true;
    if (this.studioList?.searchValue) {
      this.studioList.searchValue = "";
    }
  }

  updateOrdersStudiosAssignmentsFromPopup(
    event: UpdateOrdersStudiosAssignmentsEvent
  ): void {
    this.updateOrdersStudiosAssignments(
      event.ordersIds,
      event.studiosToAddIds,
      event.studiosToRemoveIds
    );
  }

  assignStudiosToSelectedOrder(): void {
    this.orderGrid.instance
      .byKey(this.orderGrid.focusedRowKey)
      .then((selectedOrder) => {
        const selectedKeysWithoutStandardStudios =
          this.studioList.selectedItemKeys.filter(
            (id) =>
              selectedOrder.publisher.standardStudios.findIndex(
                (studio) => studio.id === id
              ) === -1
          );

        const studioIdsOfSelectedOrder = selectedOrder.studios.map(
          (it) => it.id
        );

        const idsToAdd = selectedKeysWithoutStandardStudios.filter(
          (id) => !studioIdsOfSelectedOrder.includes(id)
        );

        const idsToRemove = studioIdsOfSelectedOrder.filter(
          (id) => !selectedKeysWithoutStandardStudios.includes(id)
        );

        this.updateOrdersStudiosAssignments(
          [selectedOrder.id],
          idsToAdd,
          idsToRemove
        );

        this.studiosPopoverVisible = false;
      });
  }

  private updateOrdersStudiosAssignments(
    orderIds: string[],
    studiosToAddIds: string[],
    studiosToRemoveIds: string[]
  ): void {
    this.orderService
      .updateOrderStudiosAssignments(
        orderIds,
        studiosToAddIds,
        studiosToRemoveIds
      )
      .toPromise()
      .then((updatedOrders) => {
        const dataSourceOperations = updatedOrders.map((order) => ({
          type: "update",
          key: order.id,
          data: order,
        }));

        this.dataSourceDs.store().push(dataSourceOperations);

        this.toastMessage = formatMessage("order.studios.update.success");
        this.toastType = "success";
        this.showToast = true;
      })
      .catch(() => {
        this.toastMessage = formatMessage("order.studios.update.error");
        this.toastType = "error";
        this.showToast = true;

        this.orderGrid.instance.endCustomLoading();
      });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((it) => it.unsubscribe());
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxScrollViewModule,
    DxDateBoxModule,
    RouterModule,
    OrganizationUnionTagModule,
    OrganizationTagModule,
    DxSelectBoxModule,
    OrderStudioPopupModule,
    DxToastModule,
    DxPopoverModule,
    DxListModule,
    ConfirmationDialogModule,
  ],
  declarations: [OrderGridComponent],
  exports: [OrderGridComponent],
})
export class OrderGridModule {}
