<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetOptionGroupForm()"
  [title]="title"
  (onSave)="save()"
>
  <app-optiongroup-form [(optionGroupData)]="optionGroup">
  </app-optiongroup-form>
</app-action-popup>
