<div class="d-flex" style="flex-wrap: wrap">
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.titleinfo.isbn") }}
    </div>
    <div class="property-value">
      {{ order.order.titleinfo.isbn }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("productType") }}
    </div>
    <div class="property-value">
      {{ formatMessage("productType." + productType) }}
    </div>
  </div>
  <div class="property-container large-container">
    <div class="property-name">
      {{ formatMessage("sender.contact") }}
    </div>
    <div class="property-value">
      {{ order.sender.contact }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.idtitle") }}
    </div>
    <div class="property-value">
      {{ order.order.idtitle }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.titleinfo.pages") }}
    </div>
    <div class="property-value">
      {{ order.order.titleinfo.pages }}
    </div>
  </div>
  <div class="property-container large-container">
    <div class="property-name">
      {{ formatMessage("sender.email") }}
    </div>
    <div class="property-value">
      {{ order.sender.email }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.edition.number") }}
    </div>
    <div class="property-value">
      {{ order.order.edition.number }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.textpartprint.productsformat") }}
    </div>

    <div class="property-value">
      {{
        getFormatInformation(order, "breite") +
          " x " +
          getFormatInformation(order, "laenge") +
          " cm"
      }}
    </div>
  </div>
  <div class="property-container large-container">
    <div class="property-name">
      {{ formatMessage("receiver.contact") }}
    </div>
    <div class="property-value">
      {{ order.receiver.contact }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.totalprintrun") }}
    </div>
    <div class="property-value">
      {{ order.order.totalprintrun }}
    </div>
  </div>
  <div class="property-container small-container">
    <div class="property-name">
      {{ formatMessage("order.jobtype") }}
    </div>
    <div class="property-value">
      {{ jobType }}
    </div>
  </div>
  <div class="property-container large-container">
    <div class="property-name">
      {{ formatMessage("receiver.email") }}
    </div>
    <div class="property-value">
      {{ order.receiver.email }}
    </div>
  </div>
  <!-- Wird derzeit nicht benötigt
  <div class="small-container">
    <div class="property-name">
      {{ formatMessage("order.binding.bindingtype") }}
    </div>
    <div class="property-value">
      {{ order.order.binding.bindingtype }}
    </div>
  </div>
  <div class="small-container">
    <div class="property-name">
      {{ formatMessage("order.binding.adhesivetype") }}
    </div>
    <div class="property-value">
      {{ order.order.binding.adhesivetype }}
    </div>
  </div>
  <div class="small-container">
    <div class="property-name">
      {{ formatMessage("order.jobtype") }}
    </div>
    <div class="property-value">
      {{ jobType }}
    </div>
  </div>
  -->
</div>
