<div>
  <div class="center">
    <h1>
      404
      <div class="notFoundText">{{ formatMessage("notfound.message") }}</div>
    </h1>
    <dx-button
      stylingMode="contained"
      text="{{ formatMessage('back.home') }}"
      type="default"
      (onClick)="click($event)"
    >
    </dx-button>
  </div>
</div>
