<h2 class="content-block">{{ formatMessage("profile") }}</h2>

<div>
  <dx-form id="form" labelLocation="left" [formData]="user">
    <dxi-item itemType="group" cssClass="first-group" [colCount]="1">
      <dxo-col-count-by-screen [lg]="2"></dxo-col-count-by-screen>
      <dxi-item dataField="identity.loginName" disabled="true"
        ><dxo-label text="{{ formatMessage('username') }}"></dxo-label
      ></dxi-item>
      <dxi-item
        text="{{ formatMessage('email') }}"
        dataField="identity.email"
        disabled="true"
        ><dxo-label text="{{ formatMessage('email') }}"></dxo-label
      ></dxi-item>

      <dxi-item
        *ngIf="!environmentIsUsa"
        text="{{ formatMessage('locale') }}"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: locales,
          displayExpr: 'name',
          valueExpr: 'value',
          onValueChanged: onLocaleChanged,
          value: tmpLocale
        }"
      >
        <dxo-label text="{{ formatMessage('locale') }}"></dxo-label>
      </dxi-item>

      <dxi-item
        *ngIf="!environmentIsUsa"
        text="{{ formatMessage('newsletter') }}"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: newsletters,
          valueExpr: 'value',
          displayExpr: 'name',
          onValueChanged: onNewsletterChanged,
          value: user?.newsletterStatus?.subscribed
        }"
      >
        <dxo-label
          text="{{ formatMessage('subscribeNewsletter') }}"
        ></dxo-label>
      </dxi-item>

      <dxi-item dataField="role" disabled="true">
        <div *dxTemplate>
          <dx-text-box
            style="display: inline-block; width: 100%"
            [value]="mappedRole"
          ></dx-text-box>
        </div>
        <dxo-label text="{{ formatMessage('role') }}"></dxo-label
      ></dxi-item>
      <dxi-item>
        <div *dxTemplate class="form-item">
          <ng-container
            *ngIf="
              user !== undefined && user.worksFor[0] !== undefined;
              else tagBoxPlaceholder
            "
          >
            <div class="tag-box-item">
              <div *ngFor="let organization of user.worksFor" class="dx-tag">
                <div
                  class="dx-tag-content tag-box-tag"
                  style="cursor: default !important"
                >
                  {{ organization.displayName }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #tagBoxPlaceholder>
            <div class="placeholder-message">
              {{ formatMessage("worksFor.placeholder") }}
            </div>
          </ng-template>
        </div>
        <dxo-label text="{{ formatMessage('worksFor') }}"></dxo-label>
      </dxi-item>
      <dxi-item
        *ngIf="!environmentIsUsa"
        text="{{ formatMessage('notification') }}"
        editorType="dxCheckBox"
        [editorOptions]="{
          onValueChanged: onNotificationChanged,
          value: user?.notification
        }"
      >
        <dxo-label text="{{ formatMessage('notification') }}"></dxo-label>
      </dxi-item>
    </dxi-item>
  </dx-form>
</div>
