<dx-form
  id="userForm"
  labelMode="floating"
  [formData]="userData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newUserFormValidation"
>
  <dxi-item
    *ngIf="!userData.isInternal"
    dataField="firstName"
    [label]="{ text: formatMessage('user.firstName') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    *ngIf="!userData.isInternal"
    dataField="lastName"
    [label]="{ text: formatMessage('user.lastName') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="email"
    [label]="{ text: formatMessage('user.email') }"
    [editorOptions]="{
      stylingMode: 'underlined',
      disabled: userData.isInternal,
      inputAttr: { autocomplete: 'off' },
      onInput: emitEmailInputChange
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="email"
      [message]="formatMessage('validation.email')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="custom"
      [validationCallback]="emailComparison"
      [message]="formatMessage('validation.lowerEmail')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="locale"
    [label]="{ text: formatMessage('locale') }"
    editorType="dxSelectBox"
    [editorOptions]="{
      items: isEnvironmentUsa ? supportedLocalesUs : supportedLocales,
      displayExpr: translateLocaleValue,
      stylingMode: 'underlined'
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="role"
    [label]="{ text: formatMessage('user.role') }"
    editorType="dxSelectBox"
    [editorOptions]="{
      items: isEnvironmentUsa
        ? usaRoles
        : userData.isInternal
        ? internalUserRolesDe
        : externalUserRolesDe,
      displayExpr: translateRole,
      placeholder: '',
      stylingMode: 'underlined',
      onSelectionChanged: stayFocused
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="userData.role === MANUFACTURER_FOR_PUBLISHER_GROUP"
    dataField="worksForPublisherGroup"
    [label]="{ text: formatMessage('user.worksFor.publisherGroup') }"
    editorType="dxSelectBox"
    [editorOptions]="{
      items: publisherGroups,
      displayExpr: getPublisherGroupDisplayName,
      valueExpr: 'id',
      placeholder: '',
      stylingMode: 'underlined'
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="userData.role === AGENCY_EMPLOYEE"
    dataField="worksForStudio"
    [label]="{ text: formatMessage('user.worksFor.studio') }"
    editorType="dxSelectBox"
    [editorOptions]="{
      items: studios,
      displayExpr: 'displayName',
      valueExpr: 'id',
      placeholder: '',
      stylingMode: 'underlined'
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="userData.role === MANUFACTURER_FOR_PUBLISHER"
    dataField="worksForPublisher"
    [label]="{ text: formatMessage('user.worksFor.publisher') }"
    editorType="dxTagBox"
    [editorOptions]="{
      items: publisher,
      displayExpr: getPublisherDisplayName,
      valueExpr: 'id',
      searchEnabled: true,
      searchExpr: ['name', 'displayName'],
      placeholder: '',
      stylingMode: 'underlined',
      showSelectionControls: true,
      showDropDownButton: true
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
</dx-form>
