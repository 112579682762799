import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  UserFormComponent,
  UserFormModule,
} from "../user-form/user-form.component";
import { UserFormData } from "../../../model/user/userFormData";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import {
  Publisher,
  PublisherGroup,
  Studio,
} from "../../../model/organization/organization";

@Component({
  selector: "app-user-form-popup",
  templateUrl: "./user-form-popup.component.html",
  styleUrls: ["./user-form-popup.component.scss"],
})
export class UserFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(UserFormComponent) userForm: UserFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onSave = new EventEmitter<UserFormData>();

  @Input() user: UserFormData;
  @Input() creating: boolean = false;

  @Input() publisherGroups: PublisherGroup[];
  @Input() publisher: Publisher[];
  @Input() studios: Studio[];

  emailBeforeChange: string = "";
  showEmailHint: boolean = false;
  title: string;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("user" in changes) {
      this.emailBeforeChange = this.user.email;
    }

    if (this.creating) {
      this.title = formatMessage("user.create.external");
    } else {
      if (this.user.isInternal) {
        this.title = formatMessage("user.update.internal");
      } else {
        this.title = formatMessage("user.update.external");
      }
    }
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  setShowEmailHint(newEmailValue: String) {
    this.showEmailHint =
      !this.creating && this.emailBeforeChange !== newEmailValue;
  }

  resetUserForm(): void {
    this.userForm.resetValues();
    this.showEmailHint = false;
  }

  resetRoleValidation(): void {
    setTimeout(
      () =>
        this.userForm?.form?.instance
          .getEditor("role")
          ?.option("isValid", true),
      1
    );
  }

  save(): void {
    if (!this.userForm.isValid()) {
      return;
    }

    this.onSave.emit(this.user);

    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    UserFormModule,
    ActionPopupModule,
  ],
  declarations: [UserFormPopupComponent],
  exports: [UserFormPopupComponent],
})
export class UserFormPopupModule {}
