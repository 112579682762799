import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-calculator-de",
  templateUrl: "./calculator-de.component.html",
  styleUrls: ["./calculator-de.component.scss"],
})
export class CalculatorDeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
