import { Component, NgModule, OnInit, ViewChild } from "@angular/core";
import {
  DxButtonModule,
  DxFormComponent,
  DxFormModule,
  DxListModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxToastModule,
} from "devextreme-angular";
import { LocaleService } from "../../../shared/services/locale.service";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { UserService } from "../../services/user.service";
import { User } from "../../../shared/model/user/user";
import { IdentityRequest } from "../../model/user/userRequests";
import { UserFormData } from "../../model/user/userFormData";
import {
  newsletterSelectboxDe,
  newsletterSelectboxEn,
  userRoleDe,
  userRoleEn,
  userRoleUs,
} from "../../model/userProfilePage";
import { BrowserModule } from "@angular/platform-browser";
import { P3AuthService } from "../../services";
import { Locale, locales } from "../../model/locale";
import { OrganizationTagModule } from "../tags/organization-tag/organization-tag.component";
import { OrganizationUnionTagModule } from "../tags/organization-union-tag/organization-union-tag.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  @ViewChild(DxFormComponent, { static: false }) userForm: DxFormComponent;

  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };
  locales: Locale[] = locales;

  tmpLocale: string;
  formatMessage = formatMessage;
  console;
  newsletters;
  mappedRole: string;

  notificationInit: boolean;

  environmentIsUsa: boolean = environment.isUsa;

  constructor(
    private localeService: LocaleService,
    private userService: UserService,
    private authService: P3AuthService
  ) {
    this.console = console;
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  onNewsletterChanged = (e) => {
    if (e.previousValue) {
      this.subscribeNewsletter(e);
    }
  };

  updateOwnDetails(request: IdentityRequest, localeUpdate?: boolean) {
    this.userService
      .updateOwnDetails(request)
      .toPromise()
      .then(() => {
        notify({
          message: formatMessage("settings.updated"),
          type: "success",
          displayTime: 2500,
          height: 100,
        });
        if (localeUpdate) {
          parent.document.location.reload();
        }
      })
      .catch(() => {
        notify({
          message: "Error",
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      });
  }

  subscribeNewsletter(e) {
    if (this.user) this.user.newsletterStatus.subscribed = e.value;
    //console.log(e.value);
    var request = new IdentityRequest(new UserFormData(this.user));
    request.locale = this.tmpLocale;
    this.updateOwnDetails(request);
  }

  loadOwnUser(): void {
    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
          this.notificationInit = true;
        }
        this.loadOwnRole();
        this.tmpLocale = this.getLocale();
        if (this.tmpLocale == "de-DE") {
          this.newsletters = newsletterSelectboxDe;
        } else this.newsletters = newsletterSelectboxEn;
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  loadOwnRole() {
    if (this.environmentIsUsa) {
      for (let i in userRoleUs) {
        if (userRoleUs[i].value == this.user?.role) {
          this.mappedRole = userRoleUs[i].name;
        }
      }
    } else {
      if (this.user?.identity.locale == "de-DE") {
        for (let i in userRoleDe) {
          if (userRoleDe[i].value == this.user?.role) {
            this.mappedRole = userRoleDe[i].name;
          }
        }
      } else {
        for (let i in userRoleEn) {
          if (userRoleEn[i].value == this.user?.role) {
            this.mappedRole = userRoleEn[i].name;
          }
        }
      }
    }
  }

  onLocaleChanged = (e) => {
    const previousValue = e.previousValue;

    var request = new IdentityRequest(new UserFormData(this.user));
    request.locale = e.value;

    if (previousValue) {
      this.updateOwnDetails(request, true);
    }
  };

  onNotificationChanged = (e) => {
    if (this.user) this.user.notification = e.value;
    var request = new IdentityRequest(new UserFormData(this.user));
    request.locale = this.tmpLocale;
    request.notification = e.value;

    if (this.notificationInit) {
      this.updateOwnDetails(request);
    }
  };

  ngOnInit(): void {
    this.loadOwnUser();
  }
}

@NgModule({
  imports: [
    DxSelectBoxModule,
    DxFormModule,
    DxToastModule,
    DxButtonModule,
    DxListModule,
    BrowserModule,
    DxTextBoxModule,
    OrganizationTagModule,
    OrganizationUnionTagModule,
  ],
  declarations: [UserProfileComponent],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
