<div
  class="d-flex flex-grow flex-column content-block"
  style="width: 100%; height: 100%"
>
  <div class="d-flex flex-shrink">
    <h2>{{ formatMessage("printingtemplate.title") }}</h2>
  </div>

  <app-printing-template-component></app-printing-template-component>
</div>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
