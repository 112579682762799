import { Component, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ProduktService } from "../../shared/services/produkt.service";
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { ScreenService } from "../../shared/services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { DxDataGridComponent } from "devextreme-angular";
import {
  PreislistenKatalog,
} from "../../shared/model/calculation/produkt";
import { PaperHandlingPriceService } from "generated/api/services";

import { PaperHandlingPrice } from "generated/api/models";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { custom } from 'devextreme/ui/dialog';

const enum OperationTypes {
  DELETE,
  CHANGE_ENABLED_DISABLED_STATE,
}

@Component({
  selector: "app-papercosts-management",
  templateUrl: "./papercosts-management.component.html",
  styleUrls: ["./papercosts-management.component.scss"],
})
export class PaperCostsManagementComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;
  isMobile: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmOperation: OperationTypes;
  confirmButtonText: string;

  paperCosts: PaperHandlingPrice[] = [];
  paperHandlingPrice: PaperHandlingPrice;
  paperCostsPopupVisible = false;
  preislistenKataloge: PreislistenKatalog[];
  selectedPreislistenKatalog: PreislistenKatalog;
  paperConsumption: boolean;
  paperPrice: boolean;

  constructor(
    private produktService: ProduktService,
    private paperHandlingPriceService: PaperHandlingPriceService,
    private screen: ScreenService,
  ) {
    this.translateLocale = this.translateLocale.bind(this);
  }

  ngOnInit(): void {
    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#user-grid",
          offset: "-30 25",
        },
      },
    });

    this.loadPreislistenKataloge();
  }

  preislistenKatalogSelected(e) {
    this.selectedPreislistenKatalog = e.value;
    this.loadPaperCosts();
  }

  loadInitialSelectedKatalog() {
    this.loadPaperCosts();
  }

  placeFloatingActionButton(): void {
    // The floating action button has a known bug, that the initial position is wrong on a dx-data-grid. By invoking
    // this method on the "onContentReady" event of the dx-data-grid, the button is on the correct position.
    // https://supportcenter.devexpress.com/ticket/details/t743517/floating-action-button-the-initial-position-is-incorrect-if-the-container-doesn-t-have
    repaintFloatingActionButton();
  }

  loadPaperCosts(): void {
    if (this.selectedPreislistenKatalog) {
      this.paperHandlingPriceService.listPaperHandlingPrices({priceListCatalogId: this.selectedPreislistenKatalog.id})
      .subscribe(
        data => this.paperCosts = data,
        () => {this.showToastMessage("papercosts.load.error")}
      )
      this.paperHandlingPriceService.getPaperConsumption({priceListCatalogId: this.selectedPreislistenKatalog.id})
      .subscribe(
        data => {
          if(data.paperConsumption) this.paperConsumption = data.paperConsumption; 
          else this.paperConsumption = false;
        },
        () => {this.showToastMessage("papercosts.load.error")}
      )
      this.paperHandlingPriceService.getPaperPrice({priceListCatalogId: this.selectedPreislistenKatalog.id})
      .subscribe(
        data => {
          if(data.paperPrice) this.paperPrice = data.paperPrice; 
          else this.paperPrice = false;
        },
        () => {this.showToastMessage("papercosts.load.error")}
      )
    }
  }

  async loadPreislistenKataloge(): Promise<void> {
    try {
      this.preislistenKataloge = await this.produktService
        .findAllPreislistenKataloge()
        .toPromise();

      this.preislistenKataloge.sort((preislistenKatalog1, preislistenKatalog2) =>
        compareStringsAlphabeticallyIgnoreCase(
          preislistenKatalog1.name,
          preislistenKatalog2.name
        )
      );

    } catch (e) {
      console.log(e);
    }
  }

  createHandlingCost(): void {
    if(this.paperCosts.length == 1 && !this.paperCosts[0].maxWeight){
      this.showToastMessage(`paperCosts.invalid.entry`)
      return
    }
    if(0 < this.paperCosts.length) {
      this.showHandlingCostPopup(<PaperHandlingPrice>{
        minWeight: this.paperCosts[this.paperCosts.length-1].maxWeight
      })
    } else {
      this.showHandlingCostPopup(<PaperHandlingPrice>{})
    }

    
  }

  editHandlingCost(paperHandlingPrice: PaperHandlingPrice): void {
    this.showHandlingCostPopup(paperHandlingPrice);
  }

  private showHandlingCostPopup(paperHandlingPrice:PaperHandlingPrice){
    if (!this.selectedPreislistenKatalog) {
      this.showToastMessage(`paperCosts.priceList.notselected`);
    } else {
      this.paperHandlingPrice = paperHandlingPrice
      this.paperHandlingPrice.priceListCatalogId = this.selectedPreislistenKatalog.id
      this.paperCostsPopupVisible = true;
    }
  }


  updatePaperPrice($event: any){
    this.paperHandlingPriceService.updatePaperPrice({
      priceListCatalogId: this.selectedPreislistenKatalog.id,
      body: {paperPrice: $event.value}
    }).subscribe(
      data => this.loadPaperCosts(),
      () => { this.showToastMessage("paperCosts.save.error")}
    )
}

  updatePaperConsumption($event: any){
    this.paperHandlingPriceService.updatePaperConsumption({
      priceListCatalogId: this.selectedPreislistenKatalog.id,
      body: {paperConsumption: $event.value}
    }).subscribe(
      data => this.loadPaperCosts(),
      () => {this.showToastMessage("paperCosts.save.error")}
    )
  }

  deleteHandlingCost(paperHandlingPrice: PaperHandlingPrice) {
      var myDialog = custom({
      title: formatMessage("paperCosts.delete"),
      messageHtml: formatMessage("paperCost.sure"),
      buttons: [
        {
          text: formatMessage("paperCosts.yes"),
          onClick: () => { return {sure: true} }
        }, 
        {
          text: formatMessage("paperCosts.no"),
          onClick: () => { return {sure:false} }
        }
      ]
    });
    myDialog.show().done((dialogResult) => {
        if(dialogResult.sure){
          this.paperHandlingPriceService.deletePaperHandlingPrice({
            priceListCatalogId:this.selectedPreislistenKatalog.id,
            id: paperHandlingPrice.id!
          }).subscribe(() => this.loadPaperCosts());
        }
    });
  }


  async savePaperCosts(data: PaperHandlingPrice): Promise<void> {
    if(this.verifyHandlingPrices(data)){
      this.paperHandlingPriceService.savePaperHandlingPrice({
        priceListCatalogId: this.selectedPreislistenKatalog.id,
        body: data
      }).subscribe(
        () => this.loadPaperCosts(),
        (error) => {
          console.log("error",error)
          this.showToastMessage(`paperCosts.save.error`)
          this.loadPaperCosts()
        }
      )
    } else {
      this.showToastMessage(`paperCosts.invalid.entry`)
      this.loadPaperCosts()
    }
  }

  private verifyHandlingPrices(handlingPrice: PaperHandlingPrice): Boolean {
    const INVALID = false
    const VALID = true

    const prices = [...this.paperCosts]
    if( !handlingPrice.id) {
      prices.push(handlingPrice)
    }
    let normalized = prices.filter(price => {
      let _a: number = Number.MIN_VALUE;
      let _b: number = Number.MAX_VALUE;
      if(price.minWeight) _a = price.minWeight
      if(price.maxWeight) _b = price.maxWeight
      return _a < _b
    }).filter(price => price.maxWeight || price.minWeight)
    if( normalized.length != prices.length) {
      return INVALID
    }
    if( prices.length == 1 ){
      return VALID
    }
    prices.sort((a, b) => {
      let _a: number = Number.MIN_VALUE;
      let _b: number = Number.MIN_VALUE;
      if(a.minWeight) _a = a.minWeight
      if(b.minWeight) _b = b.minWeight
      return _b - _a
    })
    for( let i = 0; i < prices.length-1; i++) {
      let current =  prices[i];
      let next = prices[i+1];
      let _a: number = Number.MIN_VALUE;
      let _b: number = Number.MAX_VALUE;
      if(current.minWeight) _a = current.minWeight
      if(next.maxWeight) _b = next.maxWeight
      if( _a != _b ){
        return INVALID
      }
    }
    return VALID;
  }


  private translateLocale(rowData): string {
    return formatMessage(`locale.${rowData.identity.locale}`);
  }

  private showToastMessage(message: string): void {
    this.toastType = "error";
    this.toastMessage = formatMessage(message);
    this.showToast = true;  
  }

}
