<tr class="group-name" *ngIf="groupName">
  <td>{{ groupName }}</td>
</tr>
<tr *ngFor="let mehrpreisA1 of mehrpreisListA1; let indexOfElement = index">
  <td>{{ mehrpreisA1.optionName }}</td>
  <td></td>

  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA1[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA1[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>

  <td *ngIf="calculationResponse[1]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA2[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA2[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>

  <td *ngIf="calculationResponse[2]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA3[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA3[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>

  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA1[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA1[indexOfElement].fortDruckEx)
      }}
    </span>
  </td>

  <td *ngIf="false">
    <span *ngIf="mehrpreisListA1[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA1[indexOfElement].fixKosten)
      }}
    </span>
  </td>

  <td *ngIf="false">
    <span *ngIf="mehrpreisListA1[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(
          mehrpreisListA1[indexOfElement].variableKosten
        )
      }}
    </span>
  </td>
</tr>
