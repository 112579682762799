<div class="content-block">
  <div class="d-flex align-items-center" style="justify-content: space-between">
    <h2 style="margin-right: 1rem">
      {{ formatMessage("calculator.calculation.pageTitle") }}
    </h2>
    <div
      class="d-flex align-items-center"
      *ngIf="user && organizations && !environmentIsUsa"
    >
      <div style="margin-right: 0.5rem">
        {{ formatMessage("calculator.calculation.selectedPublisher") }}
      </div>
      <div>
        <dx-lookup
          class="organization-look-up"
          [dataSource]="organizations"
          style="min-width: 200px"
          [value]="selectedPublisher"
          [searchEnabled]="true"
          (onValueChanged)="publisherSelected($event)"
          [searchExpr]="['displayName', 'disoNumber']"
          fieldTemplate="orgaTemplate"
          itemTemplate="orgaTemplate"
          [label]="orgLookUpLabel"
          [labelMode]="selectedPublisher ? 'hidden' : 'static'"
        >
          <div *dxTemplate="let orga of 'orgaTemplate'">
            <span *ngIf="orga"
              >{{ orga.displayName }} ({{ orga.disoNumber }})</span
            >
          </div>
        </dx-lookup>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-shrink flex-column"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
  ></div>

  <div *ngIf="!environmentIsUsa">
    <app-calculator-book-details
      [produkte]="produkte"
    ></app-calculator-book-details>
    <app-calculator-default-values
      [defaultValues]="defaultValuesArr"
      [selectedProdukt]="selectedProdukt"
    ></app-calculator-default-values>
    <app-calculator-price-options
      [calculator]="calculator"
    ></app-calculator-price-options>
    <app-calculator-calculated-price></app-calculator-calculated-price>
    <div class="flex-box" *ngIf="calculationResponse">
      <!-- Send json request to campaign -->
      <dx-button
        class="flex-button"
        stylingMode="contained"
        [text]="formatMessage('calculator.button.createPdf')"
        type="default"
        (onClick)="postPDFDownload()"
      >
      </dx-button>
      <!-- Wird mit speichern implementiert-->
      <dx-button
        class="flex-button"
        stylingMode="contained"
        text="Speichern"
        type="default"
        (onClick)="saveCalculation()"
      >
      </dx-button>
    </div>
  </div>
  <div *ngIf="environmentIsUsa" style="margin-top: 2em">
    <app-calculator-us></app-calculator-us>
  </div>
</div>
