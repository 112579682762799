<table class="price-table">
  <thead>
    <!-- Überschrift -->
    <tr>
      <th>
        <!-- Überschriftspalte -->
      </th>
      <th>
        <!-- weitere Mehrkosten -->
      </th>
      <th *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToNumberWithThousandsSeparator(
            calculationResponse[0].gesamtAuflage
          )
        }}
        {{ formatMessage("calculator.priceTable.exPrice") }}
      </th>
      <th *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{
          converter.convertToNumberWithThousandsSeparator(
            calculationResponse[1].gesamtAuflage
          )
        }}
        {{ formatMessage("calculator.priceTable.exPrice") }}
      </th>
      <th *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{
          converter.convertToNumberWithThousandsSeparator(
            calculationResponse[2].gesamtAuflage
          )
        }}
        {{ formatMessage("calculator.priceTable.exPrice") }}
      </th>
      <th>{{ formatMessage("calculator.priceTable.fortDruck") }}</th>
      <th *ngIf="fixKostenBool">
        {{ formatMessage("calculator.priceTable.fix") }}
      </th>
      <th *ngIf="variableKostenBool">
        {{ formatMessage("calculator.priceTable.variabel") }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="calculationResponse[0]?.gesamtAuflage">
      <td class="headline">
        {{ formatMessage("calculator.priceTable.standardProdukt") }}
      </td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage"></td>
      <td></td>
    </tr>
    <!-- Technische Kosten -->
    <tr *ngIf="technischeKosten">
      <td>{{ formatMessage("calculator.priceTable.technischeKosten") }}</td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[0].tkStandardEx) }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[1].tkStandardEx) }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[2].tkStandardEx) }}
      </td>
      <td>
        {{
          converter.convertToCurrency(
            calculationResponse[0].fortdruckKosten?.summierteKosten
              ?.technischeKosten?.summierteKosten?.kategorieTotalKosten
          )
        }}
      </td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <!-- Papierkosten -->
    <tr *ngIf="papierKosten">
      <td>{{ formatMessage("calculator.priceTable.papierKosten") }}</td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[0].pkStandardEx) }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[1].pkStandardEx) }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[2].pkStandardEx) }}
      </td>
      <td>
        {{
          converter.convertToCurrency(
            calculationResponse[0].fortdruckKosten?.summierteKosten
              ?.papierkosten?.summierteKosten?.kategorieTotalKosten
          )
        }}
      </td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <!-- Zwischensumme -->
    <tr class="zwischensumme summe">
      <td>{{ formatMessage("calculator.priceTable.zwischensumme") }}</td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].zwischenSummeStandardProduktEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[1].zwischenSummeStandardProduktEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[2].zwischenSummeStandardProduktEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].zwischenSummeStandardProduktFortdruckEx
          )
        }}
      </td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <app-empty-line
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <!-- Aufschläge -->
    <tr
      *ngIf="
        (calculationResponse[0].aufschlaegeTechnischeKosten != undefined &&
          calculationResponse[0].aufschlaegeTechnischeKosten.length > 0) ||
        (calculationResponse[0].aufschlaegePapierkosten != undefined &&
          calculationResponse[0].aufschlaegePapierkosten.length > 0) ||
        (calculationResponse[0].aufschlaegeStandardproduktkosten != undefined &&
          calculationResponse[0].aufschlaegeStandardproduktkosten.length > 0)
      "
    >
      <td class="group-name">
        {{ formatMessage("calculator.priceTable.aufAbschlag") }}
      </td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage"></td>
      <td></td>
    </tr>

    <app-aufschlag
      style="display: contents"
      *ngIf="calculationResponse[0].aufschlaegeTechnischeKosten != undefined"
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="calculationResponse[0].aufschlaegeTechnischeKosten"
      [mehrpreisListA2]="calculationResponse[1].aufschlaegeTechnischeKosten"
      [mehrpreisListA3]="calculationResponse[2].aufschlaegeTechnischeKosten"
    ></app-aufschlag>
    <app-aufschlag
      style="display: contents"
      *ngIf="calculationResponse[0].aufschlaegePapierkosten != undefined"
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="calculationResponse[0].aufschlaegePapierkosten"
      [mehrpreisListA2]="calculationResponse[1].aufschlaegePapierkosten"
      [mehrpreisListA3]="calculationResponse[2].aufschlaegePapierkosten"
    ></app-aufschlag>
    <app-aufschlag
      style="display: contents"
      *ngIf="
        calculationResponse[0].aufschlaegeStandardproduktkosten != undefined
      "
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="
        calculationResponse[0].aufschlaegeStandardproduktkosten
      "
      [mehrpreisListA2]="
        calculationResponse[1].aufschlaegeStandardproduktkosten
      "
      [mehrpreisListA3]="
        calculationResponse[2].aufschlaegeStandardproduktkosten
      "
    ></app-aufschlag>
    <!-- weitere Mehrkosten -->
    <app-empty-line
      *ngIf="
        (calculationResponse[0].aufschlaegeTechnischeKosten != undefined &&
          calculationResponse[0].aufschlaegeTechnischeKosten.length > 0) ||
        (calculationResponse[0].aufschlaegePapierkosten != undefined &&
          calculationResponse[0].aufschlaegePapierkosten.length > 0) ||
        (calculationResponse[0].aufschlaegeStandardproduktkosten != undefined &&
          calculationResponse[0].aufschlaegeStandardproduktkosten.length > 0)
      "
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <tr
      *ngIf="
        calculationResponse[0].mehrpreiseGesamtpreisGroups != undefined &&
        calculationResponse[0].mehrpreiseGesamtpreisGroups.length > 0
      "
    >
      <td class="group-name">
        {{ formatMessage("calculator.priceTable.mehrMinder") }}
      </td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage"></td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage"></td>
      <td></td>
    </tr>
    <app-mehrpreis
      style="display: contents"
      *ngFor="
        let mehrGroup of calculationResponse[0].mehrpreiseGesamtpreisGroups;
        let indexOfelement = index
      "
      [calculationResponse]="calculationResponse"
      [mehrpreisGroupA1]="calculationResponse[0].mehrpreiseGesamtpreisGroups"
      [mehrpreisGroupA2]="calculationResponse[1].mehrpreiseGesamtpreisGroups"
      [mehrpreisGroupA3]="calculationResponse[2].mehrpreiseGesamtpreisGroups"
      [group]="mehrGroup"
      [index]="indexOfelement"
    ></app-mehrpreis>
    <!-- Zwischensumme -->
    <tr *ngIf="mehrpreise" class="zwischensumme summe">
      <td>
        {{ formatMessage("calculator.priceTable.zwischensummeMehrMinder") }}
      </td>
      <td></td>
      <td
        *ngIf="
          calculationResponse[0]?.gesamtAuflage &&
          calculationResponse[0]?.zwischenSummeMehrpreiseEx !== undefined
        "
      >
        {{
          converter.convertToCurrency(
            calculationResponse[0].zwischenSummeMehrpreiseEx
          )
        }}
      </td>
      <td
        *ngIf="
          calculationResponse[1]?.gesamtAuflage &&
          calculationResponse[1]?.zwischenSummeMehrpreiseEx !== undefined
        "
      >
        {{
          converter.convertToCurrency(
            calculationResponse[1].zwischenSummeMehrpreiseEx
          )
        }}
      </td>
      <td
        *ngIf="
          calculationResponse[2]?.gesamtAuflage &&
          calculationResponse[2]?.zwischenSummeMehrpreiseEx !== undefined
        "
      >
        {{
          converter.convertToCurrency(
            calculationResponse[2].zwischenSummeMehrpreiseEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].zwischenSummeMehrpreiseFortdruckEx
          )
        }}
      </td>
      <td *ngIf="fixKostenBool">
        <!-- Fix -->
      </td>
      <td *ngIf="variableKostenBool">
        <!-- Variabel -->
      </td>
    </tr>
    <app-empty-line
      *ngIf="mehrpreise"
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <!-- Gesamtsumme SP-->
    <tr *ngIf="mehrpreise" class="summe">
      <td>
        {{ formatMessage("calculator.priceTable.summeStandardproduktMM") }}
      </td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].gesamtSummeStandardAndMMPreiseEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[1].gesamtSummeStandardAndMMPreiseEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[2].gesamtSummeStandardAndMMPreiseEx
          )
        }}
      </td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].gesamtSummeStandardAndMMPreiseFortdruckEx
          )
        }}
      </td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <app-empty-line
      *ngIf="mehrpreise"
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <!-- Aufschläge -->
    <app-aufschlag
      style="display: contents"
      *ngIf="calculationResponse[0].aufschlaegeGesamtNurTkInklExtras"
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="
        calculationResponse[0].aufschlaegeGesamtNurTkInklExtras
      "
      [mehrpreisListA2]="
        calculationResponse[1].aufschlaegeGesamtNurTkInklExtras
      "
      [mehrpreisListA3]="
        calculationResponse[2].aufschlaegeGesamtNurTkInklExtras
      "
    ></app-aufschlag>

    <app-aufschlag
      style="display: contents"
      *ngIf="calculationResponse[0].aufschlaegeGesamtpreisInklExtras"
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="
        calculationResponse[0].aufschlaegeGesamtpreisInklExtras
      "
      [mehrpreisListA2]="
        calculationResponse[1].aufschlaegeGesamtpreisInklExtras
      "
      [mehrpreisListA3]="
        calculationResponse[2].aufschlaegeGesamtpreisInklExtras
      "
    ></app-aufschlag>

    <!-- Gesamtsumme -->
    <tr *ngIf="true" class="zwischensumme summe">
      <td>{{ formatMessage("calculator.priceTable.summeEx") }}</td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[0].totalEx) }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[1].totalEx) }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[2].totalEx) }}
      </td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{
          converter.convertToCurrency(
            calculationResponse[0].totalKostenFortdruckEx
          )
        }}
      </td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <tr *ngIf="true" class="summe">
      <td>{{ formatMessage("calculator.priceTable.summe") }}</td>
      <td></td>
      <td *ngIf="calculationResponse[0]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[0].total, 2) }}
      </td>
      <td *ngIf="calculationResponse[1]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[1].total, 2) }}
      </td>
      <td *ngIf="calculationResponse[2]?.gesamtAuflage">
        {{ converter.convertToCurrency(calculationResponse[2].total, 2) }}
      </td>
      <td></td>
      <td *ngIf="fixKostenBool"></td>
      <td *ngIf="variableKostenBool"></td>
    </tr>
    <app-empty-line
      *ngIf="
        calculationResponse[0].mehrpreiseOptional != undefined &&
        calculationResponse[0].mehrpreiseOptional.length > 0
      "
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <!-- weitere separate Mehrkosten -->
    <app-aufschlag
      style="display: contents"
      *ngIf="
        calculationResponse[0].mehrpreiseOptional != undefined &&
        calculationResponse[0].mehrpreiseOptional.length > 0
      "
      [calculationResponse]="calculationResponse"
      [mehrpreisListA1]="calculationResponse[0].mehrpreiseOptional"
      [mehrpreisListA2]="calculationResponse[1].mehrpreiseOptional"
      [mehrpreisListA3]="calculationResponse[2].mehrpreiseOptional"
    ></app-aufschlag>
    <app-empty-line
      style="display: contents"
      [calculationResponse]="calculationResponse"
    >
    </app-empty-line>
    <paper-handling
      style="display: contents"
      [calculationResponse]="calculationResponse"
    ></paper-handling>
  </tbody>
</table>
