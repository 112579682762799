import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LocaleService } from "../../shared/services/locale.service";
import { ScreenService } from "../../shared/services";
import { environment } from "../../../environments/environment";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import config from "devextreme/core/config";
import { UserService } from "../../shared/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, of } from "rxjs";
import { CalculatorService } from "../../shared/services/calculator.service";
import { ProductVariantService } from "../../shared/services/product-variant.service";
import { CalculationConfig } from "../../shared/model/calculation/calculationConfig";
import { Calculation } from "../../shared/model/calculation/calculation";
import { UriHelperService } from "../../shared/services/uri-helper.service";
import {
  EditionRequest,
  Produkt,
} from "../../shared/model/calculation/produkt";
import { User } from "../../shared/model/user/user";
import { OrganizationService } from "../../shared/services/organization.service";
import { Publisher } from "../../shared/model/organization/organization";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { getExtendedUserRole } from "../../shared/model/roles";
import { CalculatorBookDetailsComponent } from "../../shared/components/calculator/calculator-de/calculator-book-details/calculator-book-details.component";
import { CalculationResult } from "../../shared/model/calculation/calculationResult";
import { CalculatorPriceOptionsComponent } from "../../shared/components/calculator/calculator-de/calculator-price-options/calculator-price-options.component";
import { ProduktInformation } from "../../shared/model/calculation/produktInformation";
import { SelectedOption } from "../../shared/model/calculation/selectedOption";
import {
  CustomPaper,
  Calculation as CalculationResource,
  OwnOption as OwnOptionResource,
  OptionPriceInformation as OptionPriceInformationResource,
  UuiDv4,
  PaperType,
} from "generated/api/models";
import { CalculationService } from "generated/api/services";
import { OwnOption } from "src/app/shared/model/calculation/ownOption";
import { OptionPriceInformation } from "src/app/shared/model/calculation/optionPriceInformation";

enum CalculationModifier {
  NEW = "NEW",
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
}

@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.scss"],
})
export class CalculatorComponent implements OnDestroy, OnInit {
  @ViewChild(CalculatorBookDetailsComponent)
  calculator: CalculatorBookDetailsComponent;

  @ViewChild(CalculatorPriceOptionsComponent)
  priceOptions: CalculatorPriceOptionsComponent;

  uploadUrl = `${environment.baseUrl}/api/calculator/uploadCsvFile`;
  currentLocaleSubscription: Subscription;
  currentLocale: string | null;

  calculationModifierEnum = CalculationModifier;

  environmentIsUsa: boolean = environment.isUsa;

  formatMessage = formatMessage;
  calculatorUrl: SafeResourceUrl;

  printformerUrl: string;

  orgLookUpLabel = formatMessage(
    "calculator.calculation.selectedPublisher.label"
  );

  showNewCalculationButton: boolean = true;
  popupVisible: boolean = false;
  popupTitle: string;

  showToast: boolean = false;
  toastMessage: string = "For some reason we need an initial value here...";
  toastType: string = "default";

  showApiChooser: boolean = true;
  calculationConfigurations: Array<CalculationConfig> = [];
  calculationConfig: CalculationConfig;

  calculationModifier: CalculationModifier;

  calculations: Array<Calculation> = [];

  isMobile: boolean;
  produkte: Produkt[];
  public selectedProdukt: Produkt;
  defaultValues: ProduktInformation;
  public defaultValuesArr: ProduktInformation[];

  user: User;
  organizations: Publisher[];
  selectedPublisher: Publisher;

  calculationResponse: CalculationResult[];
  private calculationResponseSubscription: Subscription;

  calculation: CalculationResource;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private localeService: LocaleService,
    private screen: ScreenService,
    private userService: UserService,
    private calculatorService: CalculatorService,
    private productVariantService: ProductVariantService,
    private uriHelperService: UriHelperService,
    private organizationService: OrganizationService,
    private calculationService: CalculationService,
    private router: Router
  ) {
    this.calculationResponseSubscription = this.calculatorService
      .getCalculationResponse()
      .subscribe((response) => (this.calculationResponse = response));
    this.currentLocaleSubscription = this.localeService
      .getCurrentLocale()
      .subscribe((locale) => (this.currentLocale = locale));

    calculatorService.sendCalculationResponse(null);
    this.calculator = <CalculatorBookDetailsComponent>{};
  }

  public produktSelected(selectedProdukt) {
    this.defaultValues = this.calculatorService.defaultValues;
    this.defaultValuesArr = [];
    this.defaultValuesArr.push({ ...this.defaultValues });
    this.selectedProdukt = selectedProdukt;
  }

  publisherSelected(e) {
    if (this.calculation) return;
    this.produktSelected(undefined);
    this.selectedPublisher = e.value;
    this.calculatorService.selectedPublisher = this.selectedPublisher.id;
    this.calculatorService.initializedOptionList = false;
    this.loadCalculationConfigurations();
  }

  ngOnInit(): void {
    this.calculatorService.ownOptions = [];
    var calculationId: UuiDv4 = "";
    this.route.paramMap.subscribe((params) => {
      calculationId = params.get("id") || "";
    });
    if (calculationId != "") {
      this.loadCalculation(calculationId);
    } else {
      this.newCalculation();
    }
  }

  private loadCalculation(calculationId: UuiDv4): void {
    this.userService.findOwnUser().subscribe((user) => {
      this.user = user;
    });
    let publishers = new Array();
    this.calculationService
      .getCalculation({ id: calculationId })
      .subscribe((calculation) => {
        this.calculation = calculation;
        this.calculatorService
          .getPublisherByIdNoProduct(calculation.publisherId)
          .subscribe((value) => {
            if (value) {
              publishers.push(value);
              this.organizations = publishers;
            }
            this.selectedPublisher = this.organizations[0];
            this.calculatorService.selectedPublisher =
              this.selectedPublisher.id;
            if (calculation.author)
              this.calculator.calculationData.author = calculation.author;
            if (calculation.title)
              this.calculator.calculationData.title = calculation.title;

            if (calculation.publisherNumber)
              this.calculator.calculationData.publisherNumberInput =
                calculation.publisherNumber;
            if (calculation.titleNumber)
              this.calculator.calculationData.titleNumber =
                calculation.titleNumber;
            if (calculation.editionCounter)
              this.calculator.calculationData.editionCounter =
                calculation.editionCounter;
            if (calculation.orderNumber)
              this.calculator.calculationData.orderNumber =
                calculation.orderNumber;

            this.calculator.calculationData.editionVolumes[0] =
              calculation.edition1;
            if (calculation.edition2)
              this.calculator.calculationData.editionVolumes[1] =
                calculation.edition2;
            if (calculation.edition3)
              this.calculator.calculationData.editionVolumes[2] =
                calculation.edition3;
            if (calculation.ownOptions) {
              this.calculatorService.ownOptions =
                calculation.ownOptions.map<OwnOption>((ownOptionResource) =>
                  Object.assign({
                    optionName: ownOptionResource.optionName,
                    optionType: ownOptionResource.optionType,
                    optionPosition: ownOptionResource.optionPosition,
                    sortId: ownOptionResource.sortId,
                    optionPriceInformation:
                      ownOptionResource.optionPriceInformation?.map<OptionPriceInformation>(
                        (priceInformation) =>
                          Object.assign({
                            von: priceInformation.von,
                            bis: priceInformation.bis,
                            fixKosten: priceInformation.fixKosten,
                            variableKosten: priceInformation.variableKosten,
                            fortDruckKosten: priceInformation.fortDruckKosten,
                          })
                      ),
                    optionGroup: {
                      sortId: ownOptionResource.optionGroup?.sortId,
                      groupName: ownOptionResource.optionGroup?.groupName,
                    },
                  })
                );
            }
          });
      });
  }

  newCalculation(): void {
    this.userService.findOwnUser().subscribe((user) => {
      this.user = user;

      const extendedRole = getExtendedUserRole(user.role, user.worksFor);

      this.calculatorService.selectedPublisher = "";

      if (
        user &&
        (user.role == "ADMIN" ||
          user.role == "SALES_MANAGER" ||
          user.role == "ACCOUNT_MANAGER")
      ) {
        this.organizationService.findAllPublisher().subscribe((org) => {
          if (org) {
            org.sort((a, b) =>
              compareStringsAlphabeticallyIgnoreCase(
                a.displayName,
                b.displayName
              )
            );
            if (org.length == 1) {
              this.selectedPublisher = org[0];
              this.calculatorService.selectedPublisher =
                this.selectedPublisher.id;
            }
            this.organizations = org;

            this.loadCalculationConfigurations();
          }
        });
      } else if (extendedRole == "MANUFACTURER_FOR_PUBLISHER_GROUP") {
        this.organizationService
          .findPublisherByPublisherGroup(user.worksFor[0].id)
          .subscribe((value) => {
            if (value) {
              value.sort((a, b) =>
                compareStringsAlphabeticallyIgnoreCase(
                  a.displayName,
                  b.displayName
                )
              );
              if (value.length == 1) {
                this.selectedPublisher = value[0];
                this.calculatorService.selectedPublisher =
                  this.selectedPublisher.id;
              }
              this.organizations = value;

              this.loadCalculationConfigurations();
            }
          });
      } else {
        let publishers = new Array();
        if (user.worksFor) {
          // get publisher from organizations
          for (let i = 0; i < user.worksFor.length; i++) {
            let orga = user.worksFor[i];
            if (orga.id) {
              this.organizationService
                .findPublisherByOrganizationId(orga.id)
                .subscribe(
                  (value) => {
                    if (value) {
                      publishers.push(value);
                      this.organizations = publishers;
                    }
                    if (user.worksFor.length == 1 && publishers.length == 1) {
                      this.selectedPublisher = this.organizations[0];
                      this.calculatorService.selectedPublisher =
                        this.selectedPublisher.id;
                    }
                  },
                  (e) => {
                    console.error(e);
                  },
                  () => {
                    this.loadCalculationConfigurations();
                  }
                );
            }
          }
        }
      }
    });

    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];
    this.printformerUrl = environment.printformerUrl;

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#calculations-grid",
          offset: "-30 25",
        },
      },
    });
  }

  placeFloatingActionButton(): void {
    // The floating action button has a known bug, that the initial position is wrong on a dx-data-grid. By invoking
    // this method on the "onContentReady" event of the dx-data-grid, the button is on the correct position.
    // https://supportcenter.devexpress.com/ticket/details/t743517/floating-action-button-the-initial-position-is-incorrect-if-the-container-doesn-t-have
    this.showNewCalculationButton = true;
    repaintFloatingActionButton();
  }

  setUrlInIFrame(
    printformerApi: CalculationConfig,
    printformerId: string | undefined = undefined
  ) {
    this.popupTitle = formatMessage(
      `calculator.title.${this.calculationModifier}`,
      printformerApi.publisherName
    );

    let language = this.currentLocale?.startsWith("de") ? "de" : "en";

    let queryParams = Object.assign(
      {
        locale: language,
        label: formatMessage("save"),
        hidePrice: "no",
        url: location.origin,
        nachauflage: "no",
        createNewOrder:
          this.calculationModifier === CalculationModifier.DUPLICATE
            ? "yes"
            : "no",
      },
      !printformerId ? null : { printformerId: printformerId }
    );

    let encodedQueryParams =
      this.uriHelperService.serializeParameter(queryParams);
    let path = "/default/customer/calculator";

    const url = `${this.printformerUrl}${path}?${encodedQueryParams}&${printformerApi.apiHash}&${printformerApi.publisherHash}`;
    this.calculatorUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showError(error: string): void {
    this.toastMessage = error;
    this.toastType = "error";
    this.showToast = true;
  }

  showSuccess(success: string): void {
    this.toastMessage = success;
    this.toastType = "success";
    this.showToast = true;
  }

  prepareNewCalculation(): void {
    this.calculationModifier = CalculationModifier.NEW;
    if (
      !!this.calculationConfigurations &&
      this.calculationConfigurations.length == 1
    ) {
      this.selectCalculationConfig(
        this.calculationConfigurations[0],
        undefined
      );
    } else {
      this.popupTitle = formatMessage("calculator.NEW");
      this.showApiChooser = true;
    }
    this.popupVisible = true;
  }

  prepareModifyCalculation(
    modifier: CalculationModifier,
    existingCalculation: Calculation
  ): void {
    this.calculationModifier = modifier;
    let existingCalculationConfig =
      CalculationConfig.fromCalculation(existingCalculation);
    this.selectCalculationConfig(
      existingCalculationConfig,
      existingCalculation.printformerId
    );
    this.popupVisible = true;
  }

  loadCalculationConfigurations(): void {
    if (this.selectedPublisher) {
      this.produkte = [];
      this.calculator.resetValues();
      this.calculatorService
        .getPublisherByIdNoProduct(this.selectedPublisher.id)
        .subscribe((publisher) => {
          if (
            publisher &&
            publisher.preislistenKatalog &&
            publisher.preislistenKatalog.produktList
          ) {
            let produkte = publisher.preislistenKatalog.produktList;
            let produktArray: Produkt[] = [];
            for (const element of produkte) {
              let produkt = { ...element };
              produktArray.push(produkt);
            }
            this.produkte = produktArray;
          }
        });
    }
  }

  displayExpression(item): string {
    if (!item) {
      return "";
    }
    return `${item.publisherName} (${item.publisherDisoNumber})`;
  }

  selectCalculationConfig(
    calculationConfig: CalculationConfig,
    printformerId: string | undefined
  ): void {
    this.calculationConfig = calculationConfig;
    this.setUrlInIFrame(calculationConfig, printformerId);
    this.showApiChooser = false;
  }

  ngOnDestroy(): void {
    this.currentLocaleSubscription.unsubscribe();
    this.calculationResponseSubscription.unsubscribe();
  }

  postPDFDownload() {
    this.calculator.postPDFDownload();
  }

  saveCalculation() {
    var calculationResource = this.compileCalculation();
    if (calculationResource) {
      this.calculationService
        .saveCalculation({ body: calculationResource })
        .subscribe(
          (data) => this.router.navigate([`/calculations`]),
          (error) => this.showError(error)
        );
    }
  }

  private compileCalculation(): CalculationResource | void {
    if (!this.calculator.form.instance.validate().isValid) {
      return;
    }
    const editions = this.getEditions();
    var calculationResource: CalculationResource = {
      id: this.calculation?.id,
      author: this.calculator.calculationData.author,
      edition1: editions[0].auflage,
      edition2: editions[1]?.auflage,
      edition3: editions[2]?.auflage,
      editionCounter: this.calculator.calculationData.editionCounter,
      options: this.calculatorService.selectedOptions,
      ownOptions: this.calculatorService.ownOptions.map<OwnOptionResource>(
        (ownOption) =>
          Object.assign({
            optionName: ownOption.optionName,
            optionPosition: ownOption.optionPosition,
            optionType: ownOption.optionType,
            sortId: ownOption.sortId,
            optionGroup: {
              groupName: ownOption.optionGroup.groupName,
              sortId: ownOption.optionGroup.sortId,
            },
            optionPriceInformation:
              ownOption.optionPriceInformation.map<OptionPriceInformationResource>(
                (optionPriceInformation) =>
                  Object.assign({
                    bis: optionPriceInformation.bis,
                    fixKosten: optionPriceInformation.fixKosten,
                    fortDruckKosten: optionPriceInformation.fortDruckKosten,
                    variableKosten: optionPriceInformation.variableKosten,
                    von: optionPriceInformation.von,
                  })
              ),
          })
      ),
      orderNumber: this.calculator.calculationData.orderNumber,
      paperDefinitionId: this.calculator.calculationData.paper.id,
      priceListCatalogId: this.selectedPublisher.preislistenKatalog?.id ?? "",
      productId: this.calculatorService.produktId,
      productVariantId: editions[0].produktVariantenId,
      publisherId: this.calculatorService.selectedPublisher,
      publisherNumber: this.calculator.calculationData.publisherNumberInput,
      title: this.calculator.calculationData.title,
      titleNumber: this.calculator.calculationData.titleNumber,
    };
    if (this.calculator.isCustomPaperVisible) {
      calculationResource.customPaper = <CustomPaper>{
        name: this.calculator.calculationData.paperName,
        density: Number(this.calculator.calculationData.paperGram),
        volume: Number(this.calculator.calculationData.paperVol),
        price: Number(this.calculator.calculationData.paperPrice),
      };
    }
    if (this.calculator.calculationData.paperType) {
      calculationResource.paperType = <PaperType>{
        name: this.calculator.calculationData.paperType.name,
        stoffklasse: this.calculator.calculationData.paperType.stoffklasse,
        faerbung: this.calculator.calculationData.paperType.faerbung,
      };
    }
    return calculationResource;
  }

  private getEditions(): EditionRequest[] {
    return this.calculator.mapEdition();
  }
}
