import { Component, Input, NgModule, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxFormComponent, DxFormModule } from "devextreme-angular";
import {
  DxiItemModule,
  DxiValidationRuleModule,
  DxoLabelModule,
} from "devextreme-angular/ui/nested";
import { ProduktFormData } from "../../../../model/calculation/produktFormData";
import { environment } from "../../../../../../environments/environment";
import {
  editonSelectboxUs,
  produktTypeSelectboxUsMassMarket,
  produktTypeSelectboxUsTrade,
} from "../../../../model/calculation/produktSelectBoxValues";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-product-form-us",
  templateUrl: "./product-form-us.component.html",
  styleUrls: ["./product-form-us.component.scss"],
})
export class ProductFormUsComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() produktFormData: ProduktFormData;

  produktTypenDatasource: any;
  environmentIsUsa: boolean = environment.isUsa;
  editionDatasource: any = editonSelectboxUs;

  formProduktTypeDisabled: boolean;

  constructor() {
    this.setFormProduktName = this.setFormProduktName.bind(this);
    this.onEditionChange = this.onEditionChange.bind(this);
    this.formProduktTypeDisabled = true;
  }

  ngOnInit(): void {
    this.loadProduktTypeSelectboxIfProduktPresent();
  }

  setFormProduktName(e) {
    let formData = this.form.instance.option("formData");

    let edition: string = "";
    let type: string = "";
    let width: string = "";
    let height: string = "";
    let reprint: string = "";
    let twoTitles: string = "";

    if (formData.edition != undefined) {
      edition = formData.edition
        ? this.editionDatasource.find((e) => e.value == formData.edition).name
        : "";
    }

    if (formData.produktType != undefined) {
      type = formData.produktType
        ? " - " +
          this.produktTypenDatasource.find(
            (t) => t.value == formData.produktType
          ).name
        : "";
    }

    if (formData.width != undefined) {
      width = formData.width ? " - " + formData.width : "";
    }

    if (formData.height != undefined) {
      height = formData.height ? "x" + formData.height : "";
    }

    reprint = formData.reprint ? " - reprint" : "";
    twoTitles = formData.twoTitles ? " - 2" : "";

    formData.name = edition + type + width + height + reprint + twoTitles;
  }

  loadProduktTypeSelectboxIfProduktPresent(): void {
    switch (this.produktFormData.edition) {
      case "TRD":
        {
          this.produktTypenDatasource = produktTypeSelectboxUsTrade;
          this.formProduktTypeDisabled = false;
        }
        break;
      case "MM":
        {
          this.produktTypenDatasource = produktTypeSelectboxUsMassMarket;
          this.formProduktTypeDisabled = false;
        }
        break;
      default:
        // "displayExpr" und "valueExpr" darf nicht null sein
        this.produktTypenDatasource = produktTypeSelectboxUsTrade;
        this.formProduktTypeDisabled = true;
    }
  }

  onEditionChange(e) {
    this.produktFormData.produktType = "";
    if (e.value == "TRD") {
      this.produktTypenDatasource = produktTypeSelectboxUsTrade;
      this.formProduktTypeDisabled = false;
    }
    if (e.value == "MM") {
      this.produktTypenDatasource = produktTypeSelectboxUsMassMarket;
      this.formProduktTypeDisabled = false;
    }
    this.setFormProduktName(e);
  }

  resetValues(): void {
    this.form.instance.resetValues();
    this.formProduktTypeDisabled = true;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxiItemModule,
    DxiValidationRuleModule,
    DxoLabelModule,
  ],
  declarations: [ProductFormUsComponent],
  exports: [ProductFormUsComponent],
})
export class ProductFormUsModule {}
