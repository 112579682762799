<h3>
  <i class="dx-icon-bookmark dx-font-l"></i>
  {{ formatMessage("calculator.calculation.bookData") }}
</h3>
<div class="dx-card">
  <dx-form
    #calcForm
    id="calculationForm"
    labelMode="floating"
    [(formData)]="calculationData"
    colCount="2"
    [colCountByScreen]="{ xs: 1 }"
    validationGroup="newCalculationFormValidation"
  >
    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item
        dataField="author"
        [label]="{ text: formatMessage('calculator.calculation.author') }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 35
        }"
        [colSpan]="2"
      >
      </dxi-item>
      <dxi-item
        dataField="title"
        [label]="{
          text: formatMessage('calculator.calculation.title')
        }"
        [editorOptions]="{
          maxLength: 35,
          stylingMode: 'underlined'
        }"
        [colSpan]="4"
      >
      </dxi-item>
      <dxi-item
        dataField="publisherNumberInput"
        [label]="{
          text: formatMessage('calculator.calculation.publisherNumber')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 6
        }"
        [colSpan]="3"
      >
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,6}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="titleNumber"
        [label]="{ text: formatMessage('calculator.calculation.titleNumber') }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 35
        }"
        [colSpan]="3"
      >
      </dxi-item>
      <dxi-item
        dataField="editionCounter"
        [label]="{
          text: formatMessage('calculator.calculation.editionCounter')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 35
        }"
        [colSpan]="3"
      >
      </dxi-item>
      <dxi-item
        dataField="orderNumber"
        [label]="{ text: formatMessage('calculator.calculation.orderNumber') }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 35
        }"
        [colSpan]="3"
      >
      </dxi-item>
      <dxi-item
        #produktArt
        name="produktArt"
        editorType="dxSelectBox"
        [colSpan]="6"
      >
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [dataSource]="produkte"
            displayExpr="name"
            [label]="formatMessage('calculator.calculation.produktart') + ' *'"
            (onSelectionChanged)="selectProdukt($event)"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        itemType="group"
        [colCount]="3"
        [colSpan]="6"
        [colCountByScreen]="{ xs: 1 }"
      >
        <dxi-item
          dataField="editionVolumes[0]"
          [label]="{
            text: formatMessage('calculator.calculation.edition') + ' 1'
          }"
          [editorOptions]="{
            stylingMode: 'underlined',
            maxLength: 9,
            onValueChanged: validateEdition
          }"
          [colSpan]="1"
        >
          <dxi-validation-rule
            type="required"
            [message]="formatMessage('validation.required')"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="pattern"
            pattern="^[0-9]{1,9}$"
            [message]="formatMessage('validation.integerOnly')"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="custom"
            [validationCallback]="checkEdition"
            [message]="
              formatMessage(
                'calculator.calculation.minmaxedition',
                maxAuflage + '',
                minAuflage + ''
              )
            "
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="editionVolumes[1]"
          [label]="{
            text: formatMessage('calculator.calculation.edition') + ' 2'
          }"
          [editorOptions]="{
            stylingMode: 'underlined',
            maxLength: 9,
            onValueChanged: validateEdition
          }"
          [colSpan]="1"
        >
          <dxi-validation-rule
            type="pattern"
            pattern="^[0-9]{1,9}$"
            [message]="formatMessage('validation.integerOnly')"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="custom"
            [validationCallback]="checkEdition"
            [message]="
              formatMessage(
                'calculator.calculation.minmaxedition',
                maxAuflage + '',
                minAuflage + ''
              )
            "
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="editionVolumes[2]"
          [label]="{
            text: formatMessage('calculator.calculation.edition') + ' 3'
          }"
          [editorOptions]="{
            stylingMode: 'underlined',
            maxLength: 9,
            onValueChanged: validateEdition
          }"
          [colSpan]="1"
        >
          <dxi-validation-rule
            type="pattern"
            pattern="^[0-9]{1,9}$"
            [message]="formatMessage('validation.integerOnly')"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="custom"
            [validationCallback]="checkEdition"
            [message]="
              formatMessage(
                'calculator.calculation.minmaxedition',
                maxAuflage + '',
                minAuflage + ''
              )
            "
          ></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item #numberOfPages name="numberOfPages" [colSpan]="3">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="
              formatMessage('calculator.calculation.numberOfPages') + ' *'
            "
            [dataSource]="umfaenge"
            (onSelectionChanged)="selectUmfang($event)"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item #numberOfPages name="format" [colSpan]="3">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.format') + ' *'"
            [dataSource]="buchFormate"
            [displayExpr]="formatBuchFormate"
            (onValueChanged)="selectBuchFormat()"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item #paperType name="paperType" [colSpan]="6">
        <div *dxTemplate="let data">
          <dx-select-box
            [label]="formatMessage('calculator.calculation.paperType') + ' *'"
            stylingMode="underlined"
            [dataSource]="paperTypes"
            [displayExpr]="formatPaperTypeValues"
            (onValueChanged)="selectPaper($event)"
            [(value)]="data.component.option('formData')[data.name]"
            [disabled]="isCustomPaperVisible"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item #paper name="paper" [colSpan]="6">
        <div *dxTemplate="let data">
          <dx-select-box
            #paperSelectBox
            [label]="formatMessage('calculator.calculation.paper') + ' *'"
            stylingMode="underlined"
            [dataSource]="papersByPaperType"
            [displayExpr]="formatPaperValue"
            (onValueChanged)="tabOutTextBox()"
            [(value)]="data.component.option('formData')[data.name]"
            [disabled]="isCustomPaperVisible"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        #useCustomPaper
        name="useCustomPaper"
        editorType="dxCheckBox"
        [colSpan]="6"
      >
        <div *dxTemplate>
          <dx-check-box
            [text]="formatMessage('calculator.calculation.customPaper')"
            [(value)]="isCustomPaperVisible"
            (onValueChanged)="checkCustomPaper($event)"
          >
          </dx-check-box>
        </div>
      </dxi-item>
      <dxi-item
        itemType="group"
        [colCount]="6"
        [colSpan]="6"
        [colCountByScreen]="{ xs: 1, sm: 3 }"
      >
        <dxi-item #paper name="paperName" [colSpan]="3">
          <div *dxTemplate="let data">
            <dx-text-box
              #paperName
              [label]="
                formatMessage('calculator.calculation.customPaper.name') + ' *'
              "
              stylingMode="underlined"
              [(value)]="data.component.option('formData')[data.name]"
              [disabled]="!isCustomPaperVisible"
              (onValueChanged)="tabOutTextBox()"
            ></dx-text-box>
          </div>
          <dxi-validation-rule
            type="required"
            [message]="formatMessage('validation.required')"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item #paper name="paperGram" [colSpan]="1">
          <div *dxTemplate="let data">
            <dx-number-box
              #paperGram
              [label]="
                formatMessage('calculator.calculation.customPaper.gram') + ' *'
              "
              stylingMode="underlined"
              [(value)]="data.component.option('formData')[data.name]"
              [disabled]="!isCustomPaperVisible"
              (onValueChanged)="tabOutTextBox()"
            ></dx-number-box>
          </div>
          <dxi-validation-rule
            type="required"
            [message]="formatMessage('validation.required')"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item #paper name="paperVol" [colSpan]="1">
          <div *dxTemplate="let data">
            <dx-number-box
              #paperVol
              [label]="
                formatMessage('calculator.calculation.customPaper.vol') + ' *'
              "
              stylingMode="underlined"
              [(value)]="data.component.option('formData')[data.name]"
              [disabled]="!isCustomPaperVisible"
              (onValueChanged)="tabOutTextBox()"
            ></dx-number-box>
          </div>
          <dxi-validation-rule
            type="required"
            [message]="formatMessage('validation.required')"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item #paper name="paperPrice" [colSpan]="1">
          <div *dxTemplate="let data">
            <dx-number-box
              #paperVol
              [label]="
                formatMessage('calculator.calculation.customPaper.price') + ' *'
              "
              stylingMode="underlined"
              [(value)]="data.component.option('formData')[data.name]"
              [disabled]="!isCustomPaperVisible"
              (onValueChanged)="tabOutTextBox()"
            ></dx-number-box>
          </div>
          <dxi-validation-rule
            type="required"
            [message]="formatMessage('validation.required')"
          ></dxi-validation-rule>
        </dxi-item>
      </dxi-item>
    </dxi-item>
  </dx-form>
  <dx-toast
    [(visible)]="showToast"
    [type]="toastType"
    [message]="toastMessage"
  ></dx-toast>
  <dx-button
    style="margin-top: 1rem"
    [text]="formatMessage('calculator.button.calculate')"
    (onClick)="postCalculation()"
    [useSubmitBehavior]="true"
  >
  </dx-button>
</div>
