import { StudioEntity } from "../organization/organization";

export class Order {
  id: string;
  sender: {
    publisher: string;
    contact: string;
    email: string;
  };
  receiver: {
    contact: string;
    email: string;
  };
  status: {
    status: string;
    lasteventdate: Date;
  };
  order: {
    idtitle: string;
    ordernumberdiso: number;
    orderdate: Date;
    filedeliverydate: Date;
    customernumber: string;
    titleinfo: {
      category: string;
      title: string;
      isbn: string;
      author: string;
      pages: string;
    };
    totalprintrun: string;
    binding: {
      bindingtype: string;
      adhesivetype: string;
      spinetype: string;
    };
    edition: {
      number: string;
    };
    delivery: {
      datelatest: Date;
    };
    typesetting: {
      provisionofdata: string;
    };
    textpartprint: {
      blocklength: number;
      blockwidth: number;
    };
    automaticorder: string;
  };
  publisher: {
    standardStudios: StudioEntity[];
  };
  studios: StudioEntity[];
}

export function stripTwoTrailingZerosFromOrderNumberDiso(order: Order): number {
  return order.order.ordernumberdiso / 100;
}

export function isReadyForPrinting(order: Order): boolean {
  return order.order?.automaticorder === "ADA";
}

export function isUploadAllowed(order: Order): boolean {
  //console.log(order.status.status);
  if (
    order.status.status == "DA_UEBERSCHRITTEN" ||
    order.status.status == "IN_PRODUKTION" ||
    order.status.status == "VERSENDET" ||
    order.status.status == "FAKTURIERT" ||
    order.status.status == "ERLEDIGT" ||
    order.status.status == "STORNIERT"
  ) {
    return false;
  }
  return true;
}
