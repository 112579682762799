import { Component, Input, NgModule, OnInit, ViewChild } from "@angular/core";
import {
  HeaderModule,
  SideNavigationMenuModule,
} from "../../shared/components";
import { ScreenService } from "../../shared/services";
import { ItemClickEvent as TreeViewItemClickEvent } from "devextreme/ui/tree_view";
import { ItemClickEvent as ToolbarItemClickEvent } from "devextreme/ui/toolbar";
import { DxDrawerModule } from "devextreme-angular/ui/drawer";
import {
  DxScrollViewComponent,
  DxScrollViewModule,
} from "devextreme-angular/ui/scroll-view";
import { DxToolbarModule } from "devextreme-angular/ui/toolbar";
import { CommonModule } from "@angular/common";

import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-side-nav-inner-toolbar",
  templateUrl: "./side-nav-inner-toolbar.component.html",
  styleUrls: ["./side-nav-inner-toolbar.component.scss"],
})
export class SideNavInnerToolbarComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true })
  scrollView!: DxScrollViewComponent;
  selectedRoute = "";

  menuOpened!: boolean;
  temporaryMenuOpened = false;

  @Input()
  title!: string;

  menuMode = "shrink";
  menuRevealMode = "expand";
  minMenuSize = 0;
  shaderEnabled = false;

  constructor(private screen: ScreenService, private router: Router) {}

  ngOnInit() {
    this.menuOpened = this.screen.sizes["screen-large-or-up"];

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split("?")[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  /*hideDisabledEntries() {
    const footerNavElements =
      document.querySelectorAll<HTMLElement>(".dx-state-disabled");

    if (footerNavElements) {
      footerNavElements.forEach((element) => {
        const parent = element.closest("li");
        if (parent) {
          parent.style.display = "none";
        }
      });
    }
  }*/

  updateDrawer() {
    const isXSmall = this.screen.sizes["screen-x-small"];
    const isLarge = this.screen.sizes["screen-large-or-up"];

    this.menuMode = isLarge ? "shrink" : "overlap";
    this.menuRevealMode = isXSmall ? "slide" : "expand";
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  toggleMenu = (e: ToolbarItemClickEvent) => {
    this.menuOpened = !this.menuOpened;
    e.event?.stopPropagation();
  };

  get hideMenuAfterNavigation() {
    return this.menuMode === "overlap" || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event: TreeViewItemClickEvent) {
    const path = event.itemData?.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node?.selected) {
        pointerEvent?.preventDefault();
      } else {
        this.router.navigate([path]);
        this.scrollView.instance.scrollTo(0);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent?.stopPropagation();
      }
    } else {
      pointerEvent?.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    HeaderModule,
    DxToolbarModule,
    DxScrollViewModule,
    CommonModule,
  ],
  exports: [SideNavInnerToolbarComponent],
  declarations: [SideNavInnerToolbarComponent],
})
export class SideNavInnerToolbarModule {}
