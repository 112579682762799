<div class="dx-card" *ngIf="calculationResponse">
  <div style="line-break: anywhere">
    {{ json.stringify(calculationResponse.status) }} <br />
    {{ json.stringify(calculationResponse.requestId) }} <br />
    {{ json.stringify(calculationResponse.data) }} <br />
  </div>
  <table *ngIf="calculationTableConfig.responseSuccessful" class="price-table">
    <thead>
      <tr>
        <th style="text-align: left">Description</th>
        <th>
          Quantity 1 -
          {{ calculatorService.convertToLocaleUsString(quantities[0]) }}
        </th>
        <th *ngIf="calculationTableConfig.showSecondCol">
          Quantity 2 -
          {{
            calculatorService.convertToLocaleUsString(quantities[1]) ||
              calculatorService.convertToLocaleUsString(quantities[2])
          }}
        </th>
        <th *ngIf="calculationTableConfig.showThirdCol">
          Quantity 3 -
          {{ calculatorService.convertToLocaleUsString(quantities[2]) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <app-ancillary-item-group
        style="display: contents"
        *ngFor="let groupName of responseAncillaryGroupTypes; index as i"
        [calculationTableConfig]="calculationTableConfig"
        [calcReponse]="calculationResponse.data"
        [headLineText]="groupName.name"
        [invoiceItemsAncillaryType]="groupName.value"
      ></app-ancillary-item-group>
    </tbody>
    <tfoot>
      <tr class="total" *ngIf="true">
        <td class="headline">{{ "Total" }}</td>
        <td>
          {{ calculatorService.convertToLocaleUsPriceString(totals[0], 2) }}
        </td>
        <td *ngIf="calculationTableConfig.showSecondCol">
          {{ calculatorService.convertToLocaleUsPriceString(totals[1], 2) }}
        </td>
        <td *ngIf="calculationTableConfig.showThirdCol">
          {{ calculatorService.convertToLocaleUsPriceString(totals[2], 2) }}
        </td>
      </tr>
    </tfoot>
  </table>
</div>
