<dx-data-grid
  [dataSource]="allProduktPaperDefinition.paperDefinitionList"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
  class="d-flex flex-grow dx-card wide-card"
  style="padding: 4px 12px; border-radius: 6px"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [showInfo]="true"
    [allowedPageSizes]="[15, 30, 50, 'all']"
  ></dxo-pager>

  <dxo-header-filter
    [visible]="true"
    [allowSearch]="true"
    [texts]="{
      emptyValue: formatMessage('user.dataGrid.headerFilter.emptyValue')
    }"
  ></dxo-header-filter>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>

  <dxi-column
    *ngIf="allProduktPaperDefinition?.paperSingleSelect === false"
    [caption]="formatMessage('other.actions')"
    dataField="addToProdukt"
    dataType="boolean"
    showEditorAlways="true"
    alignment="center"
  ></dxi-column>

  <dxi-column
    *ngIf="allProduktPaperDefinition?.paperSingleSelect === true"
    [caption]="formatMessage('other.actions')"
    [allowHeaderFiltering]="true"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
  </dxi-column>

  <dxi-column
    dataField="name"
    [caption]="formatMessage('paperDataGrid.name')"
  ></dxi-column>
  <dxi-column
    dataField="paperId"
    [caption]="formatMessage('paperDataGrid.paperId')"
    sortOrder="asc"
  ></dxi-column>
  <dxi-column
    dataField="grammatur"
    [caption]="formatMessage('paperDataGrid.grammatur')"
  ></dxi-column>
  <dxi-column
    dataField="price"
    [caption]="formatMessage('paperDataGrid.price')"
  ></dxi-column>
  <dxi-column
    dataField="volumen"
    [caption]="formatMessage('paperDataGrid.volumen')"
  ></dxi-column>
  <dxi-column
    dataField="paperCertificationList"
    cellTemplate="paperCertificationListTemplate"
    [caption]="formatMessage('paperDataGrid.paperCertificationList')"
  >
    <div *dxTemplate="let d of 'paperCertificationListTemplate'">
      <div
        class="dx-adaptive-item-text"
        *ngIf="d?.data?.paperCertificationList?.length > 0; else noCertificate"
      >
        <div *ngFor="let certificate of d?.data?.paperCertificationList">
          {{ certificate.name }}
        </div>
      </div>
      <ng-template #noCertificate>
        <div class="dx-adaptive-item-text">ohne</div>
      </ng-template>
    </div>
  </dxi-column>
  <dxi-column
    dataField="rollenBogenPapier"
    [caption]="formatMessage('paperDataGrid.rollenBogenPapier')"
  ></dxi-column>
  <dxi-column
    dataField="stoffklasse"
    [caption]="formatMessage('paperDataGrid.stoffklasse')"
  ></dxi-column>
  <dxi-column
    dataField="faerbung"
    [caption]="formatMessage('paperDataGrid.faerbung')"
  ></dxi-column>
  <div *dxTemplate="let data of 'actionsTemplate'">
    <input
      class="addToProduktSpezifikationRadioButtons"
      (change)="reset(data.data)"
      [checked]="data.data.addToProduktSpezifikation"
      type="radio"
      name="addToProduktSpezifikation"
      id="{{ data.data.id }}"
    />
  </div>
</dx-data-grid>
