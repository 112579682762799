import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import {
  ProduktFormData,
  produktNameFormData,
} from "../../../model/calculation/produktFormData";
import { CommonModule } from "@angular/common";
import {
  editonSelectboxUs,
  produktTypSelectboxDe,
  produktTypSelectboxEn,
} from "../../../model/calculation/produktSelectBoxValues";
import notify from "devextreme/ui/notify";
import { P3AuthService } from "../../../services";
import { User } from "../../../model/user/user";
import { environment } from "src/environments/environment";
import {
  ProductFormDeComponent,
  ProductFormDeModule,
} from "./product-form-de/product-form-de.component";
import {
  ProductFormUsComponent,
  ProductFormUsModule,
} from "./product-form-us/product-form-us.component";

@Component({
  selector: "app-produkt-form",
  templateUrl: "./produkt-form.component.html",
  styleUrls: ["./produkt-form.component.scss"],
})
export class ProduktFormComponent implements OnInit {
  @ViewChild(ProductFormDeComponent) formDe: ProductFormDeComponent;
  @ViewChild(ProductFormUsComponent) formUs: ProductFormUsComponent;

  formatMessage = formatMessage;

  @Input() produktData: ProduktFormData;
  @Output() produktDataChange = new EventEmitter<ProduktFormData>();

  environmentIsUsa: boolean = environment.isUsa;

  formDataName = new produktNameFormData();

  tmpLocale: string;
  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };

  // nur für de Relevant
  produktTypenDatasource;

  constructor(private authService: P3AuthService) {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
  }

  ngOnInit(): void {
    this.loadSelectBoxes();
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  loadSelectBoxes(): void {
    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
        }
        this.tmpLocale = this.getLocale();
        if (this.tmpLocale == "de-DE") {
          this.produktTypenDatasource = produktTypSelectboxDe;
        } else {
          this.produktTypenDatasource = produktTypSelectboxEn;
        }
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  resetValues(): void {
    if (this.environmentIsUsa) {
      this.formUs.resetValues();
    } else {
      this.formDe.resetValues();
    }
  }

  isValid(): boolean | undefined {
    if (this.environmentIsUsa) {
      return this.formUs.form.instance.validate().isValid;
    }
    return this.formDe.form.instance.validate().isValid;
  }

  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }
}

@NgModule({
  imports: [
    DxFormModule,
    CommonModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    ProductFormDeModule,
    ProductFormUsModule,
  ],
  declarations: [ProduktFormComponent],
  exports: [ProduktFormComponent],
})
export class ProduktFormModule {}
