import { v4 as uuid } from "uuid";
import { UuiDv4 } from "../../../../../../generated/api/models/uui-dv-4";
import { Publisher } from "../../../model/organization/organization";
export interface CalculatorUsFormData {
  date: Date;
  author: string;
  title: string;
  publisher: Publisher;
  customerContact: string;
  bpgContact: string;
  printNumber: string;
  quantity: number[];
  product: any;
  binding: string;
  pressMethod: string;
  edition: string;
  width: number;
  height: number;
  pages: number;
  reprint: boolean;
  cartonOrPacks: string;
  paper: any;
  paperSpecs: string;
  paperInLb: number;
  numberOfTitles: number;
  trimSizeGroup: string;
  selectedOptions: CalculatorOption[];
}

export interface CalculatorUsResponse {
  status: string;
  requestId: string;
  data: any;
}

export interface CalculatorUsPdfRequest extends CalculatorUsResponse {
  language: string;
  labelUrl: string;
  pdfFooterLine1: string;
  pdfFooterLine2: string;
  pdfVersion: string;
  generalInformation: {
    author: string;
    customer: string;
    customerContact: string;
    date: string;
    quoteNumber: string;
    bpgContact: string;
    bpgEmail: string;
  };
  jobSpecification: {
    title: string;
    quantity: number[];
    trimSize: string;
    pages: string;
    prep: string;
    proofs: string;
    plates: string;
    paper: string;
    print: string;
    bind: string;
    pack: string;
  };
}

export class CalculatorUsFormDropdownValues {
  public editionValues = [
    { displayExpr: "Trade", value: "TRD" },
    { displayExpr: "Mass Market", value: "MM" },
  ];

  public bindingValues = [
    { displayExpr: "Softcover", value: "TRS" },
    { displayExpr: "Hardcover", value: "TRH" },
    { displayExpr: "Premium", value: "MMP" },
    { displayExpr: "Rack", value: "MMR" },
    { displayExpr: "Digest", value: "MMD" },
  ];

  public pressMethodValues = [
    { displayExpr: "Head To Head", value: "HH" },
    { displayExpr: "Head To Foot", value: "HF" },
  ];

  public initialOrReprintValues = [
    { displayExpr: "Initial", value: false },
    { displayExpr: "Reprint", value: true },
  ];

  public widthValues = [4.125, 5, 6];

  public heightValues = [6.625, 6.75, 6.875, 7.5, 8, 9];

  public pagesValues = this.getPagesValues();

  private getPagesValues() {
    let values = <any>[];
    for (let i = 96; i <= 1024; i += 16) {
      values.push(i);
    }
    let specialValues = [200, 232, 264, 1280, 1296];
    values.push(...specialValues);
    values.sort((a, b) => {
      return a - b;
    });
    return values;
  }

  public paperSpecsValues = []; //?
}

export class CalculatorRequest {
  requestId: UuiDv4;
  publisherId: string;
  width: number;
  height: number;
  numberOfPages: number;
  quantity: [
    {
      shippedQuantity: number;
      orderQuantity: number;
    }
  ];
  reprint: boolean;
  edition: string; // MM, TRD
  binding: string; //MMD, MMP, MMR, TRH, TRS
  numberOfTitles: number;
  pressMethod: string; // HH, HF

  //TODO: Option Selection missing
  optionList: CalculatorOption[] = [];

  /*TODO: add to request
  paperIndex: number;
  additionalInformation: AdditionalInformation;
   */
  constructor(formData: CalculatorUsFormData, publisherId: string) {
    this.requestId = uuid();
    console.log(this.requestId);
    this.publisherId = publisherId;
    this.width = formData.width;
    this.height = formData.height;
    this.quantity = this.prepareQuantityArray(formData);
    this.reprint = formData.reprint;
    this.binding = formData.binding;
    this.edition = formData.edition;
    this.numberOfPages = formData.pages;
    this.numberOfTitles = formData.numberOfTitles;
    this.pressMethod = formData.pressMethod;
    this.optionList = formData.selectedOptions;
  }

  private prepareQuantityArray(formData: CalculatorUsFormData) {
    let quantityArray = <any>[];
    console.log(formData);
    for (let i = 0; i < Object.keys(formData.quantity).length; i++) {
      if (formData.quantity[i] != null && formData.quantity[i] != 0) {
        let quantityObject = {
          orderQuantity: formData.quantity[i],
          shippedQuantity: formData.quantity[i],
        };
        quantityArray.push(quantityObject);
      }
    }
    return quantityArray;
  }
}

class AdditionalInformation {
  language: string;
  title: string;
  author: string;
  titleNumber: string;
  orderNumber: string;
  customerId: string;
  isbn: string;
  printingNumberOfReprints: number;
}

export class CalculatorOption {
  optionId: string;
  optionValue: number;
  specification: string;
  wildcard: boolean;
}

export class CalculationTableConfig {
  responseSuccessful: boolean;
  showSecondCol: boolean;
  showThirdCol: boolean;

  constructor(
    responseSuccessful: boolean,
    showSecondCol: boolean,
    showThirdCol: boolean
  ) {
    this.responseSuccessful = responseSuccessful;
    this.showSecondCol = showSecondCol;
    this.showThirdCol = showThirdCol;
  }
}

export class SubTotalsDto {
  headline: string;
  subTotals: number[];

  constructor(headline: string, subTotals: number[]) {
    this.headline = headline;
    this.subTotals = subTotals;
  }
}

export interface TrimSize {
  width: number;
  height: number;
}

export interface TrimSizeValidationResponse {
  width: TrimSizeValidation;
  height: TrimSizeValidation;
}

export interface TrimSizeValidation {
  validationRule: TrimSizeRuleType;
  comparer: number;
}

export enum TrimSizeRuleType {
  HEIGHT_LESS_THAN_OR_EQUAL_TO = "HEIGHT_LESS_THAN_OR_EQUAL_TO", // double <= input
  WIDTH_LESS_THAN_OR_EQUAL_TO = "WIDTH_LESS_THAN_OR_EQUAL_TO", // double <= input
  HEIGHT_GREATER_THAN_OR_EQUAL_TO = "HEIGHT_GREATER_THAN_OR_EQUAL_TO", // double >= input
  WIDTH_GREATER_THAN_OR_EQUAL_TO = "WIDTH_GREATER_THAN_OR_EQUAL_TO", // double >= input
  WIDTH_EQUAL_TO = "WIDTH_EQUAL_TO", // double == input
  HEIGHT_GREATER_THAN = "HEIGHT_GREATER_THAN", // double > input
}
