import { Component, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import config from "devextreme/core/config";
import { ScreenService } from "../../shared/services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { DxDataGridComponent } from "devextreme-angular";
import { UserSuggestionService } from "../../shared/services/usersuggestion.service";
import { UserSuggestion } from "../../shared/model/user/user";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-suggestion.component.html",
  styleUrls: ["./user-suggestion.component.scss"],
})
export class UserSuggestionComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;
  isMobile: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  userSuggestions: UserSuggestion[] = [];

  constructor(
    private userSuggestionService: UserSuggestionService,
    private screen: ScreenService
  ) {
    this.translateLocale = this.translateLocale.bind(this);
  }

  ngOnInit(): void {
    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#user-grid",
          offset: "-35 30",
        },
      },
    });

    this.loadUsers();
  }

  loadUsers(): void {
    this.userSuggestionService
      .findAllUserSuggestions()
      .toPromise()
      .then((result) => (this.userSuggestions = result))
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("user.load.error");
        this.showToast = true;
      });
  }

  translateLocale(rowData): string {
    return formatMessage(`locale.${rowData.identity.locale}`);
  }
}
