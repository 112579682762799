import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { DxButtonModule, DxPopupModule } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() icon: string = "";
  @Input() iconColor: string = "";
  @Input() title: string;
  @Input() message: string;
  @Input() confirmButtonText: string;

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [DxButtonModule, DxPopupModule, CommonModule],
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
