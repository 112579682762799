import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TreeViewInstanceService {
  public treeViewInstance;

  getTreeViewInstance() {
    return this.treeViewInstance;
  }

  setTreeViewInstance(value) {
    this.treeViewInstance = value;
  }
}
