<ng-template [ngIf]="(locale | async) === 'en-EN'" [ngIfElse]="default">
  <h2 class="content-block">Nutzungsbedingungen</h2>
  <h3 class="content-block">
    Nutzungsbedingungen Kundenportal GGP Media GmbH - Stand 2023
  </h3>
  <h4 class="content-block">1. Allgemeines</h4>
  <div class="content-block">
    <h5>
      1.1. Die GGP Media GmbH (nachfolgend „GGP“) bietet ihren Auftraggebern
      (aktuellen und potentiellen Auftraggebern, nachfolgend gemeinsam
      „Auftraggeber“) auf der Homepage
      <a href="https://www.ggp-p3.de">www.ggp-p3.de</a> ein internetbasiertes
      Kundenportal (das „Kundenportal“) an.
    </h5>
    <h5>
      1.2. Die Nutzung des Kundenportals durch den Auftraggeber erfolgt
      ausschließlich auf Grundlage dieser allgemeinen Nutzungsbedingungen
      (nachfolgend die „Nutzungsbedingungen“), die der Auftraggeber mit dem
      Antrag auf Registrierung als Nutzer anerkennt. Nur beim GGP registrierte
      und freigeschaltete Nutzer können Zugriff auf das Kundenportal erhalten.
      Ein Anspruch auf Registrierung und Freischaltung als Nutzer besteht nicht.
    </h5>
    <h5>
      1.3. Im zugriffsgeschützten Bereich des Kundenportals können die Nutzer
      für den Auftraggeber, in dem vom GGP angebotenen Umfang, Produkte
      konfigurieren und kalkulieren, Kalkulationen bzw. Angebote abspeichern und
      online verwalten sowie Verträge abschließen und online verwalten. Darüber
      hinaus können die Nutzer Daten zu Aufträgen hochladen und das
      Adressmanagement für Rechnungsstellung und Auslieferung pflegen und
      verwalten. Die Nutzer des Auftraggebers können über Statusinformationen
      Fortschrittsaktivitäten und Handlungsanweisungen einsehen und über
      integrierte Kontaktformulare mit GGP in Kontakt treten.
    </h5>
    <h5>
      1.4. Bitte lesen Sie diese Bedingungen sorgfältig vor Ihrem Antrag auf
      Registrierung als Nutzer des Kundenportals durch und speichern oder
      drucken Sie diese aus.
    </h5>
    <h5>
      1.5. Für den Inhalt der unter Nutzung des Kundenportals geschlossenen
      und/oder verwalteten Rechtsverhältnisse gelten unabhängig von diesen
      Nutzungsbedingungen die vereinbarten Bedingungen des jeweiligen
      Rechtsgeschäfts.
    </h5>
  </div>
  <h4 class="content-block">2. Nutzungsberechtigung</h4>
  <div class="content-block">
    <h5>
      2.1. Das Recht zur Nutzung des Kundenportals beschränkt sich auf
      berechtigte Mitarbeiter des GGP und der mit ihm verbundenen Unternehmen
      die berechtigten Mitarbeiter des jeweiligen Auftraggebers, sowie die
      berechtigten Mitarbeiter unterbeauftragter Parteien, die als Nutzer
      zugelassen worden sind.
    </h5>
    <h5>
      2.2. Als Nutzer können ausschließlich natürliche Personen zugelassen
      werden. Ist der Auftraggeber eine juristische Person (z.B. GmbH,
      Aktiengesellschaft) oder eine Personengesellschaft, so muss diese eine
      natürliche Person als nutzungsberechtigte Person benennen.
    </h5>
    <h5>
      2.3. Unabhängig vom Innenverhältnis zwischen dem Nutzer und dem jeweiligen
      Auftraggeber gilt jeder, der sich durch die Eingabe der Zugangsdaten als
      Nutzer für einen Auftraggeber legitimiert, im Verhältnis gegenüber dem GGP
      als berechtigt, für den betreffenden Auftraggeber rechtsverbindliche
      Erklärungen abzugeben und entgegenzunehmen. Der GGP ist nicht
      verpflichtet, eine Prüfung der Legitimation vorzunehmen. Der Auftraggeber
      garantiert gegenüber dem GGP, dass die sich für ihn legitimierenden Nutzer
      rechtlich in der Lage und berechtigt sind, für ihn bindende Verträge
      abzuschließen.
    </h5>
  </div>
  <h4 class="content-block">3. Registrierung, Freischaltung, Passwort</h4>
  <div class="content-block">
    <h5>
      3.1. Die Nutzung des Kundenportals setzt eine Registrierung und
      Freischaltung des Nutzers voraus.
    </h5>
    <h5>
      3.2. Bei der Registrierung werden persönliche Daten des Auftraggebers und
      Nutzers abgefragt (z.B. Unternehmen, Name, Vorname, Email-Adresse,
      Umsatzsteuer-ID, Straße, Postleitzahl, Wohnort). Es sind wahrheitsgemäße,
      vollständige und richtige Angaben zu machen.
    </h5>
    <h5>
      3.3. Im Laufe der Registrierung wählt der Nutzer ein persönliches
      Passwort. Nachdem der Nutzer die Registrierungsanfrage erstellt hat, wird
      der zukünftige Auftraggeber zunächst durch GGP validiert. Nach
      erfolgreicher Validierung wird der Benutzer durch GGP freigeschaltet,
      woraufhin er eine E-Mail zur Bestätigung der E-Mail-Adresse erhält. Nach
      erfolgreicher Bestätigung der E-Mail-Adresse kann sich der Nutzer mit dem
      persönlichen Benutzernamen (Email-Adresse) und dem Passwort in den
      zugriffsgeschützten Bereich des Kundenportals für den Auftraggeber
      einloggen.
    </h5>
    <h5>
      3.4. GGP ist jederzeit und ohne Angabe von Gründen berechtigt, Anträge auf
      Registrierung und/oder Freischaltung abzulehnen oder Zugriffsrechte
      einzelner Nutzer einzuschränken oder zu entziehen.
    </h5>
  </div>
  <h4 class="content-block">4. Verfügbarkeit, Betrieb und Kosten</h4>
  <div class="content-block">
    <h5>
      4.1. Der GGP übernimmt keine Gewähr, dass die Website www.ggp-p3.de sowie
      das Kundenportal jederzeit ununterbrochen und fehlerfrei arbeiten und zur
      Verfügung stehen. GGP wird sich jedoch im Rahmen des wirtschaftlich
      Zumutbaren bemühen, den ununterbrochenen und fehlerfreien Betrieb der
      Website und des Kundenportals zu ermöglichen.
    </h5>
    <h5>
      4.2. GGP behält sich vor, den Leistungsumfang des Kundenportals jederzeit
      und ohne Vorankündigung zu ändern, einzuschränken oder zu erweitern, oder
      dessen Betrieb einzustellen. GGP ist berechtigt, den Zugang zum
      Kundenportal oder Teilen davon jederzeit und ohne Vorankündigung zu
      sperren.
    </h5>
    <h5>
      4.3. GGP verlangt für die Bereitstellung und Nutzung des Kundenportals
      kein Entgelt. Soweit dem Auftraggeber im Rahmen der Bereitstellung und
      Nutzung des Kundeportals Aufwendungen entstehen (z.B. für die Nutzung des
      Internets oder Bereitstellung, Wartung und Administration der für die
      Nutzung erforderlichen Soft- und Hardware) trägt diese der Auftraggeber
      selbst. Der Auftraggeber ist dafür verantwortlich, alle technischen und
      organisatorischen Vorkehrungen zu treffen, um Zugang zum Kundenportal zu
      haben.
    </h5>
  </div>
  <h4 class="content-block">
    5. Pflichten und Obliegenheiten des Auftraggebers
  </h4>
  <div class="content-block">
    <h5>5.1. Der Auftraggeber ist verpflichtet,</h5>
    <h6>
      5.1.1. Passwort und Benutzername regelmäßig zu ändern, vertraulich zu
      behandeln und nicht an Dritte weiterzugeben sowie unverzüglich zu ändern
      bzw. vom GGP ändern zu lassen, sofern er vermutet, dass unberechtigte
      Dritte hiervon Kenntnis erlangt haben; andernfalls kann es einem Dritten
      möglich sein, durch unbefugte Nutzung der Benutzerkennung und des
      Kennworts im Namen des Auftraggebers die Leistungen des GGP in Anspruch zu
      nehmen und rechtsverbindliche Willenserklärungen für den Auftraggeber
      abzugeben;
    </h6>
    <h6>
      5.1.2. den GGP unverzüglich zu informieren, sofern er vermutet, dass
      unberechtigte Dritte Kenntnis von Passwort und Benutzername erlangt haben;
      in diesem Fall wird der GGP den Zugang sperren – es ist dann eine neue
      Registrierung erforderlich.
    </h6>
    <h6>
      5.1.3. die über sich, seine berechtigten Nutzer bzw. sein Unternehmen
      mitgeteilten Daten stets aktuell, vollständig und richtig zu halten;
    </h6>
    <h6>
      5.1.4. das Kundenportal und die darin zur Verfügung gestellten
      elektronischen Kommunikationsmittel zeitlich und inhaltlich ausschließlich
      im Rahmen der Geschäftsbeziehung und im Rahmen des angebotenen
      Nutzungsumfangs zu nutzen, Missbrauch zu unterlassen bzw. nicht zu
      gestatten, und die technische Verbindung zum Kundenportal ausschließlich
      über die Homepage www.ggp-p3.de herzustellen;
    </h6>
    <h6>
      5.1.5. dafür zu sorgen, dass durch ihn bzw. durch seine von ihm genutzten
      Datenverarbeitungssyteme keine Störungen des Kundenportals (insbesondere
      durch Viren, Trojaner, sonstige Malware) erfolgen;
    </h6>
    <h6>
      5.1.6. dafür zu sorgen, dass seine Handlungen und Unterlassungen keine
      Rechte Dritter, insbesondere gewerblichen Schutzrechte oder Urheberrechte
      oder Persönlichkeitsrechte verletzen;
    </h6>
    <h6>
      5.1.7. sämtliche im Rahmen der Nutzung des Kundenportals erhaltenen
      Informationen und Kenntnisse vertraulich zu behandeln, ausschließlich im
      Rahmen der Geschäftsbeziehung mit GGP zu verwenden, nicht Dritten zu
      überlassen und gegen die Kenntnisnahme durch Unbefugte zu sichern;
    </h6>
    <h6>
      5.1.8. sämtliche von ihm berechtigten Nutzer zu verpflichten, diese
      Nutzungsbedingungen einzuhalten.
    </h6>
    <h5>
      5.2. Im Falle eines Verstoßes gegen diese Nutzungsbedingungen ist der GGP
      berechtigt, dem Auftraggeber und den von ihm berechtigten Nutzern den
      Zugang zum Kundenportal ganz oder zeitlich befristet zu sperren. Dies gilt
      auch dann, wenn nach der angemessenen Einschätzung des GGP ein solcher
      Verstoß vorliegt. Der Zugang wird erst wieder freigeschaltet, wenn der
      Verstoß dauerhaft beseitigt ist.
    </h5>
  </div>
  <h4 class="content-block">6. Elektronische Kommunikation, Archivierung</h4>
  <div class="content-block">
    <h5>
      6.1. Das Gesetz kann vorsehen, dass der Auftraggeber bestimmte
      Informationen oder Mitteilungen schriftlich erhält. Mit der Nutzung des
      Kundenportals akzeptiert der Auftraggeber, dass diese Kommunikation auch
      in elektronischer Form ablaufen kann. Der GGP kann den Auftraggeber per
      Email kontaktieren. Der Auftraggeber kann den GGP entweder über
      Kontaktformulare im Kundenportal oder per E-Mail Nachrichten hinterlassen.
      Der Auftraggeber ist damit einverstanden, dass geschäftliche Korrespondenz
      auf elektronischem Wege geführt wird und stimmt zu, dass alle Verträge,
      Mitteilungen, Informationen, welche der GGP ihm elektronisch übermittelt,
      alle gesetzlichen Anforderungen einer Schriftform erfüllen. Ein Anspruch
      auf elektronische Kommunikation ist damit nicht verbunden.
    </h5>
    <h5>
      6.2. Der Auftraggeber ist verpflichtet, den im Kundenportal hinterlegten
      Email-Account (entspricht dem Benutzernamen) regelmäßig auf den Eingang
      neuer Nachrichten zu überprüfen. Nachteile aufgrund einer verspäteten
      Überprüfung gehen zu Lasten des Auftraggebers.
    </h5>
    <h5>
      6.3. Sofern eine Übermittlung von Daten über das Kundenportal nicht
      möglich ist, entbindet dies den Auftraggeber nicht von seinen im Rahmen
      der abgeschlossenen Verträge bestehenden Mitteilungspflichten. In diesem
      Fall sind die sonstigen zulässigen Mitteilungswege zu nutzen.
    </h5>
    <h5>
      6.4. Jede Partei ist selbst verpflichtet, Nachrichten entsprechend den
      gesetzlichen Regelungen (insbesondere vollständig, manipulationsgeschützt,
      löschungs- und überschreibungssicher) aufzuzeichnen und zu archivieren.
      Dementsprechend ist der GGP nicht verpflichtet, die Daten des
      Auftraggebers zu speichern und zu archivieren.
    </h5>
    <h5>
      6.5. Die von jeder Partei zu beachtenden Aufbewahrungsfristen für ihre
      elektronischen Dokumente ergeben sich aus den gesetzlichen Vorschriften.
    </h5>
  </div>
  <h4 class="content-block">7. Beendigung der Nutzung, Sperrung</h4>
  <div class="content-block">
    <h5>
      7.1. Jede Partei ist berechtigt, die Nutzung des Kundenportals ohne
      Einhaltung einer Frist schriftlich oder in Textform zu kündigen. Im Falle
      der Kündigung ist GGP verpflichtet, alle vom Auftraggeber zur Verfügung
      gestellten Unterlagen und die über den Auftraggeber und seine berechtigten
      Nutzer gespeicherten personenbezogenen Daten unverzüglich für eine weitere
      Verarbeitung zu sperren und mit dem nächsten Bereinigungslauf zu löschen.
      Gesetzliche Aufbewahrungspflichten bleiben unberührt.
    </h5>
    <h5>
      7.2. Aus Sicherheitsgründen wird ein Nutzer automatisch gesperrt, wenn
      fünf Mal hintereinander versucht wird, sich mit einem falschen Passwort
      anzumelden. Ist dies der Fall, ist eine erneute Freischaltung durch den
      GGP notwendig.
    </h5>
    <h5>
      7.3. Mit der Beendigung der Geschäftsbeziehung oder der Sperrung des
      Auftraggebers oder des Einstellung des Kundenportals endet die Nutzung des
      Kundenportals automatisch.
    </h5>
  </div>
  <h4 class="content-block">8. Haftung</h4>
  <div class="content-block">
    <h5>
      8.1. Eine Haftung des GGP sowie seiner Vertreter und Erfüllungsgehilfen
      auf Schadenersatz, insbesondere wegen Verzugs, Nichterfüllung,
      Schlechtleistung oder unerlaubter Handlung besteht nur bei Verletzung
      wesentlicher Vertragspflichten, auf deren Erfüllung der Auftraggeber in
      besonderem Maß vertrauen durfte. GGP haftet dabei nur für
      vertragstypische, vorhersehbare Schäden. Die Haftung für mittelbare
      Schäden, insbesondere Mangelfolgeschäden, unvorhersehbare Schäden oder
      untypische Schäden sowie entgangenen Gewinn ist ausgeschlossen. Der
      Haftungsausschluss gilt nicht für Vorsatz und grobe Fahrlässigkeit oder
      bei der schuldhaften Verletzung von Leben, Körper oder Gesundheit. Im
      Übrigen ist eine Haftung des GGP ausgeschlossen, soweit nicht zwingende
      gesetzliche Regelungen eine Haftung vorsehen.
    </h5>
    <h5>
      8.2. Der Auftraggeber haftet für eigene sowie zurechenbare
      Pflichtverletzungen, insbesondere die Verletzung seiner Pflichten und
      Obliegenheiten nach Ziff. 5.1, nach den gesetzlichen Vorschriften.
      Insbesondere haftet der Auftraggeber allein für Schäden, die durch den
      Missbrauch seines Kennwortes und/oder der Benutzerkennung verursacht
      werden.
    </h5>
    <h5>
      8.3. Soweit das Kundenportal Verlinkungen auf andere Seiten und Quellen
      Dritter enthält, werden diese Links nur zu Information zur Verfügung
      gestellt. Der GGP identifiziert sich nicht mit dem Inhalt der verlinkten
      Seiten und Quellen und kann den Inhalt dieser Seiten und Quellen nicht
      kontrollieren. Der GGP übernimmt deshalb keine Verantwortung für den
      Inhalt oder irgendwelche Verluste oder Schäden, die auf deren Nutzung
      beruhen.
    </h5>
  </div>
  <h4 class="content-block">9. Datenschutz</h4>
  <div class="content-block">
    <h5>
      9.1. GGP unterliegt als datenverarbeitende Stelle den jeweils gültigen
      Bestimmungen des Datenschutzes. GGP beachtet bei der Erhebung, der Nutzung
      und der Verarbeitung personenbezogener Daten die jeweils anwendbaren
      datenschutzrechtlichen Bestimmungen.
    </h5>
    <h5>
      9.2. Mit der Nutzung des Kundenportals stimmen Sie der Erhebung, Nutzung
      und Speicherung Ihrer personenbezogenen Daten durch den GGP gemäß den
      <a [routerLink]="['/datenschutz']">Datenschutzbestimmungen</a>
      zu. Bitte lesen Sie diese Datenschutzbestimmungen daher sorgfältig durch.
    </h5>
  </div>
  <h4 class="content-block">10. Urheber- und Markenrechte</h4>
  <div class="content-block">
    <h5>
      Alle Inhalte (Texte, Bilder, Grafiken, Ton-, Video- und Animationsdateien
      sowie deren Anordnung u. a.) auf dem Kundenportal unterliegen dem Schutz
      des Urheberrechts und anderer Schutzgesetze. Der Rechtsschutz gilt auch
      gegenüber Datenbanken und ähnlichen Einrichtungen. Die Inhalte sind nur
      für den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte
      dieser Website dürfen außerhalb der Grenzen des Urheberrechts ohne
      schriftliche Genehmigung des GGP nicht in irgendeiner Form vervielfältigt,
      verbreitet, verändert oder Dritten zugänglich gemacht werden. Einige
      Bereiche des Kundenportals enthalten außerdem Bilder, die dem Copyright
      Dritter unterliegen. Soweit nicht anders angegeben, sind alle
      Markenzeichen auf dem Kundenportal markenrechtlich geschützt.
    </h5>
  </div>
  <h4 class="content-block">11. Änderungen</h4>
  <div class="content-block">
    <h5>
      11.1. GGP behält sich das Recht vor, diese Nutzungsbedingungen von Zeit zu
      Zeit zu ändern und sie der technischen und rechtlichen Entwicklung
      anzupassen. Die neuen Bedingungen werden rechtzeitig vor dem Zeitpunkt, zu
      dem sie in Kraft treten, über den Account des Auftraggebers oder per Email
      in einer Form bekanntgegeben, die es dem Auftraggeber erlaubt, die
      Änderungen in lesbarer Form zu speichern oder auszudrucken.
    </h5>
    <h5>
      11.2. GGP wird dem Auftraggeber in der Änderungsmitteilung auf den
      Zeitpunkt des beabsichtigten Wirksamwerdens der Änderungen sowie auf das
      Recht zur kostenfreien und fristlosen Kündigung hinweisen. Die Zustimmung
      des Auftraggebers zu den neuen Bedingungen gilt als erteilt, wenn der
      Auftraggeber das Kundenportal nach Inkrafttreten der neuen
      Nutzungsbedingungen weiterhin nutzt oder nicht innerhalb von zwei Wochen
      vor dem Zeitpunkt des vorgesehenen Inkrafttretens ausdrücklich anzeigt.
      Auf diese Genehmigungswirkung wird der GGP den Auftraggeber gesondert
      hinweisen.
    </h5>
    <h5>
      11.3. Sollte der Auftraggeber den geänderten Nutzungsbedingungen
      widersprechen, kann der das Kundenportal ab dem Zeitpunkt des
      Inkrafttretens der geänderten Nutzungsbedingungen nicht weiter nutzen.
    </h5>
  </div>
  <h4 class="content-block">12. Sonstiges</h4>
  <div class="content-block">
    <h5>
      12.1. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss
      der Regelungen des UN-Kaufrechts sowie der Regelungen des internationalen
      Privatrechts.
    </h5>
    <h5>
      12.2. Ist der Auftraggeber Kaufmann i.S.d. HGB, juristische Person des
      öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, ist
      ausschließlicher – auch internationaler Gerichtsstand für alle sich aus
      dem Vertragsverhältnis unmittelbar oder mittelbar ergebenden
      Streitigkeiten, der Geschäftssitz des GGP in Pößneck. Entsprechendes gilt,
      wenn der Auftraggeber Unternehmer i.S.v. § 14 BGB ist. Der GGP ist jedoch
      in allen Fällen auch berechtigt, Klage am allgemeinen Gerichtsstand des
      Auftraggebers zu erheben. Vorrangige gesetzliche Vorschriften,
      insbesondere zu ausschließlichen Zuständigkeiten, bleiben unberührt.
    </h5>
  </div>
</ng-template>
<ng-template #default>
  <h2 class="content-block">Nutzungsbedingungen</h2>
  <h3 class="content-block">
    Nutzungsbedingungen Kundenportal GGP Media GmbH - Stand 2023
  </h3>
  <h4 class="content-block">1. Allgemeines</h4>
  <div class="content-block">
    <h5>
      1.1. Die GGP Media GmbH (nachfolgend „GGP“) bietet ihren Auftraggebern
      (aktuellen und potentiellen Auftraggebern, nachfolgend gemeinsam
      „Auftraggeber“) auf der Homepage
      <a href="https://www.ggp-p3.de">www.ggp-p3.de</a> ein internetbasiertes
      Kundenportal (das „Kundenportal“) an.
    </h5>
    <h5>
      1.2. Die Nutzung des Kundenportals durch den Auftraggeber erfolgt
      ausschließlich auf Grundlage dieser allgemeinen Nutzungsbedingungen
      (nachfolgend die „Nutzungsbedingungen“), die der Auftraggeber mit dem
      Antrag auf Registrierung als Nutzer anerkennt. Nur beim GGP registrierte
      und freigeschaltete Nutzer können Zugriff auf das Kundenportal erhalten.
      Ein Anspruch auf Registrierung und Freischaltung als Nutzer besteht nicht.
    </h5>
    <h5>
      1.3. Im zugriffsgeschützten Bereich des Kundenportals können die Nutzer
      für den Auftraggeber, in dem vom GGP angebotenen Umfang, Produkte
      konfigurieren und kalkulieren, Kalkulationen bzw. Angebote abspeichern und
      online verwalten sowie Verträge abschließen und online verwalten. Darüber
      hinaus können die Nutzer Daten zu Aufträgen hochladen und das
      Adressmanagement für Rechnungsstellung und Auslieferung pflegen und
      verwalten. Die Nutzer des Auftraggebers können über Statusinformationen
      Fortschrittsaktivitäten und Handlungsanweisungen einsehen und über
      integrierte Kontaktformulare mit GGP in Kontakt treten.
    </h5>
    <h5>
      1.4. Bitte lesen Sie diese Bedingungen sorgfältig vor Ihrem Antrag auf
      Registrierung als Nutzer des Kundenportals durch und speichern oder
      drucken Sie diese aus.
    </h5>
    <h5>
      1.5. Für den Inhalt der unter Nutzung des Kundenportals geschlossenen
      und/oder verwalteten Rechtsverhältnisse gelten unabhängig von diesen
      Nutzungsbedingungen die vereinbarten Bedingungen des jeweiligen
      Rechtsgeschäfts.
    </h5>
  </div>
  <h4 class="content-block">2. Nutzungsberechtigung</h4>
  <div class="content-block">
    <h5>
      2.1. Das Recht zur Nutzung des Kundenportals beschränkt sich auf
      berechtigte Mitarbeiter des GGP und der mit ihm verbundenen Unternehmen
      die berechtigten Mitarbeiter des jeweiligen Auftraggebers, sowie die
      berechtigten Mitarbeiter unterbeauftragter Parteien, die als Nutzer
      zugelassen worden sind.
    </h5>
    <h5>
      2.2. Als Nutzer können ausschließlich natürliche Personen zugelassen
      werden. Ist der Auftraggeber eine juristische Person (z.B. GmbH,
      Aktiengesellschaft) oder eine Personengesellschaft, so muss diese eine
      natürliche Person als nutzungsberechtigte Person benennen.
    </h5>
    <h5>
      2.3. Unabhängig vom Innenverhältnis zwischen dem Nutzer und dem jeweiligen
      Auftraggeber gilt jeder, der sich durch die Eingabe der Zugangsdaten als
      Nutzer für einen Auftraggeber legitimiert, im Verhältnis gegenüber dem GGP
      als berechtigt, für den betreffenden Auftraggeber rechtsverbindliche
      Erklärungen abzugeben und entgegenzunehmen. Der GGP ist nicht
      verpflichtet, eine Prüfung der Legitimation vorzunehmen. Der Auftraggeber
      garantiert gegenüber dem GGP, dass die sich für ihn legitimierenden Nutzer
      rechtlich in der Lage und berechtigt sind, für ihn bindende Verträge
      abzuschließen.
    </h5>
  </div>
  <h4 class="content-block">3. Registrierung, Freischaltung, Passwort</h4>
  <div class="content-block">
    <h5>
      3.1. Die Nutzung des Kundenportals setzt eine Registrierung und
      Freischaltung des Nutzers voraus.
    </h5>
    <h5>
      3.2. Bei der Registrierung werden persönliche Daten des Auftraggebers und
      Nutzers abgefragt (z.B. Unternehmen, Name, Vorname, Email-Adresse,
      Umsatzsteuer-ID, Straße, Postleitzahl, Wohnort). Es sind wahrheitsgemäße,
      vollständige und richtige Angaben zu machen.
    </h5>
    <h5>
      3.3. Im Laufe der Registrierung wählt der Nutzer ein persönliches
      Passwort. Nachdem der Nutzer die Registrierungsanfrage erstellt hat, wird
      der zukünftige Auftraggeber zunächst durch GGP validiert. Nach
      erfolgreicher Validierung wird der Benutzer durch GGP freigeschaltet,
      woraufhin er eine E-Mail zur Bestätigung der E-Mail-Adresse erhält. Nach
      erfolgreicher Bestätigung der E-Mail-Adresse kann sich der Nutzer mit dem
      persönlichen Benutzernamen (Email-Adresse) und dem Passwort in den
      zugriffsgeschützten Bereich des Kundenportals für den Auftraggeber
      einloggen.
    </h5>
    <h5>
      3.4. GGP ist jederzeit und ohne Angabe von Gründen berechtigt, Anträge auf
      Registrierung und/oder Freischaltung abzulehnen oder Zugriffsrechte
      einzelner Nutzer einzuschränken oder zu entziehen.
    </h5>
  </div>
  <h4 class="content-block">4. Verfügbarkeit, Betrieb und Kosten</h4>
  <div class="content-block">
    <h5>
      4.1. Der GGP übernimmt keine Gewähr, dass die Website www.ggp-p3.de sowie
      das Kundenportal jederzeit ununterbrochen und fehlerfrei arbeiten und zur
      Verfügung stehen. GGP wird sich jedoch im Rahmen des wirtschaftlich
      Zumutbaren bemühen, den ununterbrochenen und fehlerfreien Betrieb der
      Website und des Kundenportals zu ermöglichen.
    </h5>
    <h5>
      4.2. GGP behält sich vor, den Leistungsumfang des Kundenportals jederzeit
      und ohne Vorankündigung zu ändern, einzuschränken oder zu erweitern, oder
      dessen Betrieb einzustellen. GGP ist berechtigt, den Zugang zum
      Kundenportal oder Teilen davon jederzeit und ohne Vorankündigung zu
      sperren.
    </h5>
    <h5>
      4.3. GGP verlangt für die Bereitstellung und Nutzung des Kundenportals
      kein Entgelt. Soweit dem Auftraggeber im Rahmen der Bereitstellung und
      Nutzung des Kundeportals Aufwendungen entstehen (z.B. für die Nutzung des
      Internets oder Bereitstellung, Wartung und Administration der für die
      Nutzung erforderlichen Soft- und Hardware) trägt diese der Auftraggeber
      selbst. Der Auftraggeber ist dafür verantwortlich, alle technischen und
      organisatorischen Vorkehrungen zu treffen, um Zugang zum Kundenportal zu
      haben.
    </h5>
  </div>
  <h4 class="content-block">
    5. Pflichten und Obliegenheiten des Auftraggebers
  </h4>
  <div class="content-block">
    <h5>5.1. Der Auftraggeber ist verpflichtet,</h5>
    <h6>
      5.1.1. Passwort und Benutzername regelmäßig zu ändern, vertraulich zu
      behandeln und nicht an Dritte weiterzugeben sowie unverzüglich zu ändern
      bzw. vom GGP ändern zu lassen, sofern er vermutet, dass unberechtigte
      Dritte hiervon Kenntnis erlangt haben; andernfalls kann es einem Dritten
      möglich sein, durch unbefugte Nutzung der Benutzerkennung und des
      Kennworts im Namen des Auftraggebers die Leistungen des GGP in Anspruch zu
      nehmen und rechtsverbindliche Willenserklärungen für den Auftraggeber
      abzugeben;
    </h6>
    <h6>
      5.1.2. den GGP unverzüglich zu informieren, sofern er vermutet, dass
      unberechtigte Dritte Kenntnis von Passwort und Benutzername erlangt haben;
      in diesem Fall wird der GGP den Zugang sperren – es ist dann eine neue
      Registrierung erforderlich.
    </h6>
    <h6>
      5.1.3. die über sich, seine berechtigten Nutzer bzw. sein Unternehmen
      mitgeteilten Daten stets aktuell, vollständig und richtig zu halten;
    </h6>
    <h6>
      5.1.4. das Kundenportal und die darin zur Verfügung gestellten
      elektronischen Kommunikationsmittel zeitlich und inhaltlich ausschließlich
      im Rahmen der Geschäftsbeziehung und im Rahmen des angebotenen
      Nutzungsumfangs zu nutzen, Missbrauch zu unterlassen bzw. nicht zu
      gestatten, und die technische Verbindung zum Kundenportal ausschließlich
      über die Homepage www.ggp-p3.de herzustellen;
    </h6>
    <h6>
      5.1.5. dafür zu sorgen, dass durch ihn bzw. durch seine von ihm genutzten
      Datenverarbeitungssyteme keine Störungen des Kundenportals (insbesondere
      durch Viren, Trojaner, sonstige Malware) erfolgen;
    </h6>
    <h6>
      5.1.6. dafür zu sorgen, dass seine Handlungen und Unterlassungen keine
      Rechte Dritter, insbesondere gewerblichen Schutzrechte oder Urheberrechte
      oder Persönlichkeitsrechte verletzen;
    </h6>
    <h6>
      5.1.7. sämtliche im Rahmen der Nutzung des Kundenportals erhaltenen
      Informationen und Kenntnisse vertraulich zu behandeln, ausschließlich im
      Rahmen der Geschäftsbeziehung mit GGP zu verwenden, nicht Dritten zu
      überlassen und gegen die Kenntnisnahme durch Unbefugte zu sichern;
    </h6>
    <h6>
      5.1.8. sämtliche von ihm berechtigten Nutzer zu verpflichten, diese
      Nutzungsbedingungen einzuhalten.
    </h6>
    <h5>
      5.2. Im Falle eines Verstoßes gegen diese Nutzungsbedingungen ist der GGP
      berechtigt, dem Auftraggeber und den von ihm berechtigten Nutzern den
      Zugang zum Kundenportal ganz oder zeitlich befristet zu sperren. Dies gilt
      auch dann, wenn nach der angemessenen Einschätzung des GGP ein solcher
      Verstoß vorliegt. Der Zugang wird erst wieder freigeschaltet, wenn der
      Verstoß dauerhaft beseitigt ist.
    </h5>
  </div>
  <h4 class="content-block">6. Elektronische Kommunikation, Archivierung</h4>
  <div class="content-block">
    <h5>
      6.1. Das Gesetz kann vorsehen, dass der Auftraggeber bestimmte
      Informationen oder Mitteilungen schriftlich erhält. Mit der Nutzung des
      Kundenportals akzeptiert der Auftraggeber, dass diese Kommunikation auch
      in elektronischer Form ablaufen kann. Der GGP kann den Auftraggeber per
      Email kontaktieren. Der Auftraggeber kann den GGP entweder über
      Kontaktformulare im Kundenportal oder per E-Mail Nachrichten hinterlassen.
      Der Auftraggeber ist damit einverstanden, dass geschäftliche Korrespondenz
      auf elektronischem Wege geführt wird und stimmt zu, dass alle Verträge,
      Mitteilungen, Informationen, welche der GGP ihm elektronisch übermittelt,
      alle gesetzlichen Anforderungen einer Schriftform erfüllen. Ein Anspruch
      auf elektronische Kommunikation ist damit nicht verbunden.
    </h5>
    <h5>
      6.2. Der Auftraggeber ist verpflichtet, den im Kundenportal hinterlegten
      Email-Account (entspricht dem Benutzernamen) regelmäßig auf den Eingang
      neuer Nachrichten zu überprüfen. Nachteile aufgrund einer verspäteten
      Überprüfung gehen zu Lasten des Auftraggebers.
    </h5>
    <h5>
      6.3. Sofern eine Übermittlung von Daten über das Kundenportal nicht
      möglich ist, entbindet dies den Auftraggeber nicht von seinen im Rahmen
      der abgeschlossenen Verträge bestehenden Mitteilungspflichten. In diesem
      Fall sind die sonstigen zulässigen Mitteilungswege zu nutzen.
    </h5>
    <h5>
      6.4. Jede Partei ist selbst verpflichtet, Nachrichten entsprechend den
      gesetzlichen Regelungen (insbesondere vollständig, manipulationsgeschützt,
      löschungs- und überschreibungssicher) aufzuzeichnen und zu archivieren.
      Dementsprechend ist der GGP nicht verpflichtet, die Daten des
      Auftraggebers zu speichern und zu archivieren.
    </h5>
    <h5>
      6.5. Die von jeder Partei zu beachtenden Aufbewahrungsfristen für ihre
      elektronischen Dokumente ergeben sich aus den gesetzlichen Vorschriften.
    </h5>
  </div>
  <h4 class="content-block">7. Beendigung der Nutzung, Sperrung</h4>
  <div class="content-block">
    <h5>
      7.1. Jede Partei ist berechtigt, die Nutzung des Kundenportals ohne
      Einhaltung einer Frist schriftlich oder in Textform zu kündigen. Im Falle
      der Kündigung ist GGP verpflichtet, alle vom Auftraggeber zur Verfügung
      gestellten Unterlagen und die über den Auftraggeber und seine berechtigten
      Nutzer gespeicherten personenbezogenen Daten unverzüglich für eine weitere
      Verarbeitung zu sperren und mit dem nächsten Bereinigungslauf zu löschen.
      Gesetzliche Aufbewahrungspflichten bleiben unberührt.
    </h5>
    <h5>
      7.2. Aus Sicherheitsgründen wird ein Nutzer automatisch gesperrt, wenn
      fünf Mal hintereinander versucht wird, sich mit einem falschen Passwort
      anzumelden. Ist dies der Fall, ist eine erneute Freischaltung durch den
      GGP notwendig.
    </h5>
    <h5>
      7.3. Mit der Beendigung der Geschäftsbeziehung oder der Sperrung des
      Auftraggebers oder des Einstellung des Kundenportals endet die Nutzung des
      Kundenportals automatisch.
    </h5>
  </div>
  <h4 class="content-block">8. Haftung</h4>
  <div class="content-block">
    <h5>
      8.1. Eine Haftung des GGP sowie seiner Vertreter und Erfüllungsgehilfen
      auf Schadenersatz, insbesondere wegen Verzugs, Nichterfüllung,
      Schlechtleistung oder unerlaubter Handlung besteht nur bei Verletzung
      wesentlicher Vertragspflichten, auf deren Erfüllung der Auftraggeber in
      besonderem Maß vertrauen durfte. GGP haftet dabei nur für
      vertragstypische, vorhersehbare Schäden. Die Haftung für mittelbare
      Schäden, insbesondere Mangelfolgeschäden, unvorhersehbare Schäden oder
      untypische Schäden sowie entgangenen Gewinn ist ausgeschlossen. Der
      Haftungsausschluss gilt nicht für Vorsatz und grobe Fahrlässigkeit oder
      bei der schuldhaften Verletzung von Leben, Körper oder Gesundheit. Im
      Übrigen ist eine Haftung des GGP ausgeschlossen, soweit nicht zwingende
      gesetzliche Regelungen eine Haftung vorsehen.
    </h5>
    <h5>
      8.2. Der Auftraggeber haftet für eigene sowie zurechenbare
      Pflichtverletzungen, insbesondere die Verletzung seiner Pflichten und
      Obliegenheiten nach Ziff. 5.1, nach den gesetzlichen Vorschriften.
      Insbesondere haftet der Auftraggeber allein für Schäden, die durch den
      Missbrauch seines Kennwortes und/oder der Benutzerkennung verursacht
      werden.
    </h5>
    <h5>
      8.3. Soweit das Kundenportal Verlinkungen auf andere Seiten und Quellen
      Dritter enthält, werden diese Links nur zu Information zur Verfügung
      gestellt. Der GGP identifiziert sich nicht mit dem Inhalt der verlinkten
      Seiten und Quellen und kann den Inhalt dieser Seiten und Quellen nicht
      kontrollieren. Der GGP übernimmt deshalb keine Verantwortung für den
      Inhalt oder irgendwelche Verluste oder Schäden, die auf deren Nutzung
      beruhen.
    </h5>
  </div>
  <h4 class="content-block">9. Datenschutz</h4>
  <div class="content-block">
    <h5>
      9.1. GGP unterliegt als datenverarbeitende Stelle den jeweils gültigen
      Bestimmungen des Datenschutzes. GGP beachtet bei der Erhebung, der Nutzung
      und der Verarbeitung personenbezogener Daten die jeweils anwendbaren
      datenschutzrechtlichen Bestimmungen.
    </h5>
    <h5>
      9.2. Mit der Nutzung des Kundenportals stimmen Sie der Erhebung, Nutzung
      und Speicherung Ihrer personenbezogenen Daten durch den GGP gemäß den
      <a [routerLink]="['/datenschutz']">Datenschutzbestimmungen</a>
      zu. Bitte lesen Sie diese Datenschutzbestimmungen daher sorgfältig durch.
    </h5>
  </div>
  <h4 class="content-block">10. Urheber- und Markenrechte</h4>
  <div class="content-block">
    <h5>
      Alle Inhalte (Texte, Bilder, Grafiken, Ton-, Video- und Animationsdateien
      sowie deren Anordnung u. a.) auf dem Kundenportal unterliegen dem Schutz
      des Urheberrechts und anderer Schutzgesetze. Der Rechtsschutz gilt auch
      gegenüber Datenbanken und ähnlichen Einrichtungen. Die Inhalte sind nur
      für den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte
      dieser Website dürfen außerhalb der Grenzen des Urheberrechts ohne
      schriftliche Genehmigung des GGP nicht in irgendeiner Form vervielfältigt,
      verbreitet, verändert oder Dritten zugänglich gemacht werden. Einige
      Bereiche des Kundenportals enthalten außerdem Bilder, die dem Copyright
      Dritter unterliegen. Soweit nicht anders angegeben, sind alle
      Markenzeichen auf dem Kundenportal markenrechtlich geschützt.
    </h5>
  </div>
  <h4 class="content-block">11. Änderungen</h4>
  <div class="content-block">
    <h5>
      11.1. GGP behält sich das Recht vor, diese Nutzungsbedingungen von Zeit zu
      Zeit zu ändern und sie der technischen und rechtlichen Entwicklung
      anzupassen. Die neuen Bedingungen werden rechtzeitig vor dem Zeitpunkt, zu
      dem sie in Kraft treten, über den Account des Auftraggebers oder per Email
      in einer Form bekanntgegeben, die es dem Auftraggeber erlaubt, die
      Änderungen in lesbarer Form zu speichern oder auszudrucken.
    </h5>
    <h5>
      11.2. GGP wird dem Auftraggeber in der Änderungsmitteilung auf den
      Zeitpunkt des beabsichtigten Wirksamwerdens der Änderungen sowie auf das
      Recht zur kostenfreien und fristlosen Kündigung hinweisen. Die Zustimmung
      des Auftraggebers zu den neuen Bedingungen gilt als erteilt, wenn der
      Auftraggeber das Kundenportal nach Inkrafttreten der neuen
      Nutzungsbedingungen weiterhin nutzt oder nicht innerhalb von zwei Wochen
      vor dem Zeitpunkt des vorgesehenen Inkrafttretens ausdrücklich anzeigt.
      Auf diese Genehmigungswirkung wird der GGP den Auftraggeber gesondert
      hinweisen.
    </h5>
    <h5>
      11.3. Sollte der Auftraggeber den geänderten Nutzungsbedingungen
      widersprechen, kann der das Kundenportal ab dem Zeitpunkt des
      Inkrafttretens der geänderten Nutzungsbedingungen nicht weiter nutzen.
    </h5>
  </div>
  <h4 class="content-block">12. Sonstiges</h4>
  <div class="content-block">
    <h5>
      12.1. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss
      der Regelungen des UN-Kaufrechts sowie der Regelungen des internationalen
      Privatrechts.
    </h5>
    <h5>
      12.2. Ist der Auftraggeber Kaufmann i.S.d. HGB, juristische Person des
      öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, ist
      ausschließlicher – auch internationaler Gerichtsstand für alle sich aus
      dem Vertragsverhältnis unmittelbar oder mittelbar ergebenden
      Streitigkeiten, der Geschäftssitz des GGP in Pößneck. Entsprechendes gilt,
      wenn der Auftraggeber Unternehmer i.S.v. § 14 BGB ist. Der GGP ist jedoch
      in allen Fällen auch berechtigt, Klage am allgemeinen Gerichtsstand des
      Auftraggebers zu erheben. Vorrangige gesetzliche Vorschriften,
      insbesondere zu ausschließlichen Zuständigkeiten, bleiben unberührt.
    </h5>
  </div>
</ng-template>
