<dx-form
  id="papierForm"
  labelMode="floating"
  [(formData)]="papierFormData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
>
  <dxi-item
    *ngIf="editing"
    dataField="paperId"
    style="opacity: 1"
    [editorOptions]="{ disabled: true, stylingMode: 'underlined' }"
  >
    <dxo-label text="{{ formatMessage('paperForm.paperId') }}"></dxo-label>
  </dxi-item>
  <dxi-item dataField="name" [editorOptions]="{ stylingMode: 'underlined' }">
    <dxo-label text="{{ formatMessage('paperForm.name') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="grammatur"
    [editorOptions]="{ stylingMode: 'underlined' }"
    editorType="dxNumberBox"
  >
    <dxo-label text="{{ formatMessage('paperForm.grammatur') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      [pattern]="grammaturPattern"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="volumen"
    [editorOptions]="{ stylingMode: 'underlined' }"
    editorType="dxNumberBox"
  >
    <dxo-label text="{{ formatMessage('paperForm.volumen') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      [pattern]="doubleDigitPattern"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="price"
    [editorOptions]="{ stylingMode: 'underlined' }"
    editorType="dxNumberBox"
  >
    <dxo-label text="{{ formatMessage('paperForm.price') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      [pattern]="doubleDigitPattern"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="rollenBogenPapier"
    [editorOptions]="{ stylingMode: 'underlined', items: dropDownRollenBogen }"
    editorType="dxSelectBox"
  >
    <dxo-label
      text="{{ formatMessage('paperForm.rollenBogenPapier') }}"
    ></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="stoffklasse"
    [editorOptions]="{ stylingMode: 'underlined', items: dropDownStoffklasse }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('paperForm.stoffklasse') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="faerbung"
    [editorOptions]="{ stylingMode: 'underlined', items: dropDownFaerbung }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('paperForm.faerbung') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="paperCertificationList"
    [editorOptions]="{
      stylingMode: 'underlined',
      items: paperCertificationList,
      displayExpr: 'name'
    }"
    editorType="dxTagBox"
  >
    <dxo-label
      text="{{ formatMessage('paperForm.paperCertificationList') }}"
    ></dxo-label>
  </dxi-item>
</dx-form>
