export const newsletterSelectboxDe = [
  {
    name: "Deutsch",
    value: "NEWSLETTER_DE",
  },
  {
    name: "Englisch",
    value: "NEWSLETTER_EN",
  },
  {
    name: "Nicht abonniert",
    value: "UNSUBSCRIBED",
  },
];

export const newsletterSelectboxEn = [
  {
    name: "German",
    value: "NEWSLETTER_DE",
  },
  {
    name: "English",
    value: "NEWSLETTER_EN",
  },
  {
    name: "Unsubscribed",
    value: "UNSUBSCRIBED",
  },
];

export const userRoleDe = [
  {
    name: "Hersteller",
    value: "MANUFACTURER",
  },
  {
    name: "Agentur Mitarbeiter",
    value: "AGENCY_EMPLOYEE",
  },
  {
    name: "Administrator",
    value: "ADMIN",
  },
  {
    name: "Kundenbetreuer",
    value: "ACCOUNT_MANAGER",
  },
  {
    name: "Sales Manager",
    value: "SALES_MANAGER",
  },
  {
    name: "Datenbearbeitung/PV",
    value: "DATA_PROCESSING_PV",
  },
  {
    name: "Prepress Mitarbeiter",
    value: "PREPRESS_EMPLOYEE",
  },
];

export const userRoleEn = [
  {
    name: "Manufacturer",
    value: "MANUFACTURER",
  },
  {
    name: "Agency employee",
    value: "AGENCY_EMPLOYEE",
  },
  {
    name: "Admin",
    value: "ADMIN",
  },
  {
    name: "Account manager",
    value: "ACCOUNT_MANAGER",
  },
  {
    name: "Sales manager",
    value: "SALES_MANAGER",
  },
  {
    name: "Dataprocessing/PV",
    value: "DATA_PROCESSING_PV",
  },
  {
    name: "Prepress employee",
    value: "PREPRESS_EMPLOYEE",
  },
];

export const userRoleUs = [
  {
    name: "Admin US",
    value: "ADMIN_US",
  },
];
