import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import {
  supportedLocales,
  supportedLocalesUs,
} from "src/app/shared/model/supportedLocales";
import { UserFormData } from "../../../model/user/userFormData";
import { CommonModule } from "@angular/common";
import {
  Publisher,
  PublisherGroup,
  Studio,
} from "../../../model/organization/organization";
import {
  EXTENDED_EXTERNAL_USER_ROLES,
  INTERNAL_USER_ROLES,
  AGENCY_EMPLOYEE,
  MANUFACTURER_FOR_PUBLISHER,
  MANUFACTURER_FOR_PUBLISHER_GROUP,
  ADMIN_US,
  USA_ROLES,
} from "../../../model/roles";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"],
})
export class UserFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  supportedLocales = supportedLocales;
  supportedLocalesUs = supportedLocalesUs;

  externalUserRolesDe = EXTENDED_EXTERNAL_USER_ROLES;
  internalUserRolesDe = INTERNAL_USER_ROLES.filter(
    (role) => !USA_ROLES.includes(role)
  );

  usaRoles = USA_ROLES;

  MANUFACTURER_FOR_PUBLISHER_GROUP = MANUFACTURER_FOR_PUBLISHER_GROUP;
  MANUFACTURER_FOR_PUBLISHER = MANUFACTURER_FOR_PUBLISHER;
  AGENCY_EMPLOYEE = AGENCY_EMPLOYEE;

  isEnvironmentUsa = environment.isUsa;

  @Input() publisherGroups: PublisherGroup[];
  @Input() publisher: Publisher[];
  @Input() studios: Studio[];

  @Input() userData: UserFormData;
  @Output() userDataChange = new EventEmitter<UserFormData>();
  @Output() emailInput: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.translateRole = this.translateRole.bind(this);
    this.getPublisherGroupDisplayName =
      this.getPublisherGroupDisplayName.bind(this);
    this.getPublisherDisplayName = this.getPublisherDisplayName.bind(this);
    this.emitEmailInputChange = this.emitEmailInputChange.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
    this.emailComparison = this.emailComparison.bind(this);
  }

  ngOnInit(): void {}

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  translateRole(data): string {
    return formatMessage(`user.role.${data}`);
  }

  getPublisherGroupDisplayName(data): string {
    const displayName = data?.displayName ? " (" + data.displayName + ")" : "";

    return data?.disoNumber ? data.disoNumber + displayName : "";
  }

  getPublisherDisplayName(data): string {
    return data?.displayName + " (" + data?.disoNumber + ")";
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  emitEmailInputChange(e): void {
    this.emailInput.emit(e.event.currentTarget.value);
  }

  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }

  emailComparison(e) {
    return e.value.toLowerCase() === e.value;
  }
}

@NgModule({
  imports: [DxFormModule, CommonModule, DxCheckBoxModule, DxTagBoxModule],
  declarations: [UserFormComponent],
  exports: [UserFormComponent],
})
export class UserFormModule {}
