import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActionPopupModule } from "../../../../action-popup/action-popup.component";
import {
  OptionGroupFormComponent,
  OptionGroupFormModule,
} from "../../../optionGroup-form/optionGroup-form.component";
import { OptionGroupFormData } from "../../../../../model/calculation/optionGroupFormData";
import { ScreenService } from "../../../../../services";
import { formatMessage } from "devextreme/localization";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import {
  ColumnFormComponent,
  ColumnFormModule,
} from "../column-form/column-form.component";
import { ColumnFormData } from "../../../../../model/calculation/optionPriceInformation";

@Component({
  selector: "app-column-form-popup",
  templateUrl: "./column-form-popup.component.html",
  styleUrls: ["./column-form-popup.component.scss"],
})
export class ColumnFormPopupComponent implements OnInit {
  @ViewChild(ColumnFormComponent)
  optionGroupForm: ColumnFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  @Input() visible: boolean;
  @Input() columnForm: ColumnFormData;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<ColumnFormData>();

  title: string = "Create Column";

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetColumnForm(): void {
    this.optionGroupForm.resetValues();
  }

  resetRoleValidation(): void {
    setTimeout(
      () =>
        this.optionGroupForm?.form?.instance
          .getEditor("role")
          ?.option("isValid", true),
      1
    );
  }

  save(): void {
    if (!this.optionGroupForm.isValid()) {
      return;
    }

    this.onSave.emit(this.columnForm);

    this.visible = false;
  }
}

@NgModule({
  imports: [ActionPopupModule, OptionGroupFormModule, ColumnFormModule],
  declarations: [ColumnFormPopupComponent],
  exports: [ColumnFormPopupComponent],
})
export class ColumnFormPopupMoule {}
