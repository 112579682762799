import { Component, OnInit } from "@angular/core";
import "devextreme/data/odata/store";
import { formatMessage } from "devextreme/localization";

@Component({
  templateUrl: "calculations.component.html",
})
export class CalculationsComponent implements OnInit {
  formatMessage = formatMessage;
  ngOnInit(): void {}
}
