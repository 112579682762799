import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ProduktSpezifikationService } from "../../../services/produkt-spezifikation.service";
import {
  DxDataGridModule,
  DxButtonModule,
  DxToastModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { CommonModule } from "@angular/common";
import { ProduktFormData } from "../../../model/calculation/produktFormData";
import { ProduktSpezifikationCategory } from "../../../model/calculation/produktSpezifikation";

@Component({
  selector: "app-produkt-spezifikation-editbox",
  templateUrl: "./produkt-spezifikation-editbox.component.html",
  styleUrls: ["./produkt-spezifikation-editbox.component.scss"],
})
export class ProduktSpezifikationEditboxComponent implements OnInit, OnChanges {
  formatMessage = formatMessage;

  @Input() produktData: ProduktFormData;
  @Input() produktSpezifikationCategory: ProduktSpezifikationCategory;

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmButtonText: string;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  initializing: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    const produktSpezifikationIds =
      this.produktSpezifikationCategory.defaultValues.map((item) => item.id);
    const incrementedId = Math.max.apply(null, produktSpezifikationIds) + 1;
    data.customItem = {
      value: data.text,
      id: incrementedId,
    };

    this.produktSpezifikationCategory.datasource
      .store()
      .insert(data.customItem)
      .catch((error) => {
        throw error;
      });
    this.produktSpezifikationCategory.datasource.reload();
  }

  save(data): void {
    if (!data.selectedItem) {
      return;
    }

    this.produktSpezifikationCategory.value = data.selectedItem.value + "";
  }

  resetValue() {
    if (this.produktSpezifikationCategory.value) {
      this.produktSpezifikationCategory.value = "";
    }
  }

  setInitializingToFalse() {
    this.initializing = false;
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    DxButtonModule,
    ActionPopupModule,
    CommonModule,
    DxToastModule,
    DxSelectBoxModule,
  ],
  declarations: [ProduktSpezifikationEditboxComponent],
  exports: [ProduktSpezifikationEditboxComponent],
})
export class ProduktSpezifikationEditBoxModule {}
