<dx-data-grid
  [dataSource]="uploadLinks"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [style]="'height: ' + height"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting></dxo-sorting>

  <dxi-column
    dataField="orderPiece"
    [caption]="formatMessage('upload.orderPiece')"
    [calculateCellValue]="translateOrderPiece"
  >
  </dxi-column>
  <dxi-column
    dataField="validUntilDate"
    dataType="date"
    format="shortDateShortTime"
    [caption]="formatMessage('upload.link.validTime')"
    [calculateDisplayValue]="displayCounter"
    [hidingPriority]="3"
  ></dxi-column>
  <dxi-column
    [caption]="formatMessage('other.actions')"
    [showInColumnChooser]="false"
    type="buttons"
    alignment="center"
    cellTemplate="downloadTemplate"
  >
    <div *dxTemplate="let data of 'downloadTemplate'">
      <dx-button
        icon="link"
        [hint]="formatMessage('upload.link.hint.toClipboard')"
        (onClick)="copyUploadLinkToClipboard(data)"
        stylingMode="text"
        type="default"
      ></dx-button>

      <dx-button
        icon="email"
        [hint]="formatMessage('upload.link.hint.sendMail')"
        (onClick)="sendUploadLinkViaMail(data)"
        stylingMode="text"
        type="default"
      ></dx-button>
    </div>
  </dxi-column>
</dx-data-grid>
