<div
  class="
    d-flex
    flex-grow flex-column
    content-block
    align-items-center
    justify-content-center
    text-center
  "
>
  <i
    class="material-icons mdi-happy"
    style="font-size: 52px; color: #003366"
  ></i>
  <span
    [style]="
      isXSmall
        ? 'font-size: 30px; margin: 2px 0 20px 0'
        : 'font-size: 34px; margin: 2px 0 50px 0'
    "
    style="font-weight: 400; color: #003366"
  >
    {{ formatMessage("welcome.title") }}
  </span>
  <span
    style="
      font-size: 16px;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.65);
      max-width: 600px;
    "
  >
    {{ formatMessage("welcome.subtitle") }}
  </span>
</div>
