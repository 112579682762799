<div class="container">
  <div class="images">
    <a href="{{ formatMessage('footer.ggplink') }}" target="_blank">
      <img class="brand-logo" src="assets/ggp.jpg" alt="zu GGP" />
    </a>
  </div>
  <div class="copyright">
    <div>© GGP Media a part of Bertelsmann Marketing Services</div>
    <div>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.imprint') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/impressum')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.dataprotection') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/datenschutz')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.tcs') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/agb')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.tou') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/nutzungsbedingungen')"
      >
      </dx-button>
    </div>
  </div>
</div>
