import { Component, Input, NgModule, OnInit } from "@angular/core";
import { DxButtonModule, DxDataGridModule } from "devextreme-angular";
import { CommonModule } from "@angular/common";
import { stripTwoTrailingZerosFromOrderNumberDiso } from "../../../shared/model/order/order";
import { formatMessage } from "devextreme/localization";
import { Upload } from "../../../shared/model/upload/upload";
import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../../shared/services/treeViewInstance.service";

@Component({
  selector: "app-upload-grid-with-orders",
  templateUrl: "./upload-grid-with-orders.component.html",
  styleUrls: ["./upload-grid-with-orders.component.scss"],
})
export class UploadGridWithOrdersComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() uploads: Upload[] = [];
  @Input() isExternalUser: boolean;
  @Input() showOrderNumberDiso: boolean;
  @Input() showReceiverContact: boolean;

  constructor(
    private router: Router,
    private treeViewInstanceService: TreeViewInstanceService
  ) {
    this.displayOrderNumberDiso = this.displayOrderNumberDiso.bind(this);
    this.translateOrderPiece = this.translateOrderPiece.bind(this);
  }

  ngOnInit(): void {}

  translateOrderPiece(upload: Upload) {
    return formatMessage(`upload.orderPiece.${upload.orderPiece}`);
  }

  displayOrderNumberDiso(upload: Upload): number {
    return stripTwoTrailingZerosFromOrderNumberDiso(upload.order);
  }

  navigateToOrderDetails(upload: Upload): void {
    this.router.navigate([`/orderdetails/${upload.order.id}`]);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }

  getPreflightIconClass(rowData) {
    const styleClass = "dx-font-m mr-4";

    if (rowData.data.preflightStatus === "SUCCESSFUL") {
      return "dx-icon-check success " + styleClass;
    } else if (rowData.data.preflightStatus === "FAILED") {
      return "dx-icon-close error " + styleClass;
    }

    return "";
  }

  translatePreflightStatus(rowData) {
    return formatMessage(
      `upload.preflight.status.${rowData.data.preflightStatus}`
    );
  }
}

@NgModule({
  imports: [DxButtonModule, DxDataGridModule, CommonModule],
  declarations: [UploadGridWithOrdersComponent],
  exports: [UploadGridWithOrdersComponent],
})
export class UploadGridWithOrdersModule {}
