import { Component, OnInit, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  formatMessage = formatMessage;

  constructor(private router: Router) {}

  click(event) {
    this.router.navigate(["/"]);
  }

  ngOnInit(): void {}
}
