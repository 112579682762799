export class Broschurumschlag {
  produktTeil: string;
  language: string;
  records: BroschurumschlagRecords[];
}

export class BroschurumschlagRecords {
  produktTeilKnz: string;
  nutzenBreite: string;
  nutzenHoehe: string;
  formatBreite: string;
  rueckenBreite: string;
  anschnitt: string;
  ueberklebung2fR: string;
  ueberklebung4fR: string;
  bezeichnung: string;
  produktTyp: string;
  endFormat: string;
  umfang: string;
  inhaltsPapier: string;
  bindeArt: string;
  umschlagPapier: string;
  umfangBildTeil: string;
  papierBildTeil: string;
  datum: string;
}

export class CalculatedBroschurumschlagRecord {
  nutzenBreite: number;
  nutzenHoehe: number;
  formatBreite: number;
  rueckenBreite: number;
}
