import { Component, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  Option,
  OptionMappingProperties,
  UpdateOptionMappingRequest,
} from "../../shared/model/calculation/option";
import { OptionService } from "../../shared/services/option.service";
import { PreislistenKatalog } from "../../shared/model/calculation/produkt";
import { ProduktService } from "../../shared/services/produkt.service";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { DxDataGridComponent, DxLookupComponent } from "devextreme-angular";
import { OptionMappingDropdownValues } from "./option-mapping-dropdown-values";

@Component({
  selector: "app-option-mapping",
  templateUrl: "./option-mapping.component.html",
  styleUrls: ["./option-mapping.component.scss"],
})
export class OptionMappingComponent implements OnInit {
  @ViewChild("unmappedOptionGrid") unmappedOptionGrid: DxDataGridComponent;
  @ViewChild("mappedOptionGrid") mappedOptionGrid: DxDataGridComponent;
  @ViewChild("preislistenKatalogLookup")
  preislistenKatalogLookup: DxLookupComponent;

  formatMessage = formatMessage;
  optionMappingDropdownValues = new OptionMappingDropdownValues();
  unmappedGridLoading = false;
  mappedGridLoading = false;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  options: Option[] = [];
  unmappedOptions: Option[] = [];
  mappedOptions: Option[] = [];
  priceData: PreislistenKatalog[];
  selectedPriceData: PreislistenKatalog;

  constructor(
    private optionService: OptionService,
    private produktService: ProduktService
  ) {
    this.onCustomItemCreating = this.onCustomItemCreating.bind(this);
  }

  ngOnInit(): void {
    this.loadPriceData().then((r) => {
      console.log("dropdown loaded");

      // load all unmapped options initially
      this.loadOptionsBySelectedCatalog();
    });
  }

  private async loadPriceData() {
    try {
      this.priceData = await this.produktService
        .findAllPreislistenKataloge()
        .toPromise();

      this.sortPreislistenKataloge();
    } catch (e) {}
  }

  sortPreislistenKataloge(): void {
    this.priceData.sort((preislistenKatalog1, preislistenKatalog2) =>
      this.comparePreislistenKatalogeByName(
        preislistenKatalog1,
        preislistenKatalog2
      )
    );
  }

  comparePreislistenKatalogeByName(
    preislistenKatalog1: PreislistenKatalog,
    preislistenKatalog2: PreislistenKatalog
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      preislistenKatalog1.name,
      preislistenKatalog2.name
    );
  }

  preislistenKatalogSelected($event: any) {
    this.selectedPriceData = $event.value;

    this.options = [];
    this.unmappedOptions = [];
    this.mappedOptions = [];

    this.loadOptionsBySelectedCatalog();
  }

  private loadOptionsBySelectedCatalog() {
    if (this.selectedPriceData) {
      // load all options by selected preislisten katalog
      this.unmappedGridLoading = true;
      this.mappedGridLoading = true;
      this.optionService
        .getOptionsByPreislistenKatalog(this.selectedPriceData)
        .toPromise()
        .then((result) => {
          this.options = [...result];
          this.mapOptionTypeDisplayName(this.options);
          this.mapOptionsToDatagrid(this.options);
        })
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("option.load.error");
          this.showToast = true;
        })
        .finally(() => {
          this.unmappedGridLoading = false;
          this.mappedGridLoading = false;
        });
    } else {
      // load all unmapped options
      this.unmappedGridLoading = true;
      this.optionService
        .getAllOptionMappingProperties()
        .toPromise()
        .then((result) => {
          this.options = [...result];
          this.mapOptionTypeDisplayName(this.options);
          this.mapOptionsToDatagrid(this.options);
        })
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("option.load.error");
          this.showToast = true;
        })
        .finally(() => {
          this.unmappedGridLoading = false;
        });
    }
  }

  private mapOptionTypeDisplayName(options: Option[]) {
    for (let i = 0; i < options.length; i++) {
      switch (options[i].optionType) {
        case "CARTONS":
          options[i].optionTypeDisplayName = "Cartons (Qty/Unit: 1000)";
          break;
        case "DIFFERENCE":
          options[i].optionTypeDisplayName = "Difference (Qty/Unit: 1000)";
          break;
        case "INSERTS":
          options[i].optionTypeDisplayName = "Inserts (Qty/Unit: 1000)";
          break;
        case "ONE_TIME_PRICES":
          options[i].optionTypeDisplayName = "One-time Prices (Qty/Unit: 1)";
          break;
        case "ONE_TIME_PRICES_1000":
          options[i].optionTypeDisplayName = "One-time Prices (Qty/Unit: 1000)";
          break;
        case "SURCHARGE":
          options[i].optionTypeDisplayName = "Surcharge (Qty/Unit: 1)";
          break;
        default:
          break;
      }
    }
  }

  private mapOptionsToDatagrid(options: Option[]) {
    for (let option of options) {
      if (option.optionMappingProperties) {
        this.mappedOptions.push(option);
      } else {
        this.unmappedOptions.push(option);
      }
    }
  }

  saveUnmappedOptions($event: any) {
    console.log("--- Save Unmapped Options ---");
    console.log($event);
    console.log(this.unmappedOptions);
    this.saveOptions(this.unmappedOptions);
  }

  saveMappedOptions($event: any) {
    console.log("--- Save Mapped Options ---");
    console.log($event);
    console.log(this.mappedOptions);
    this.saveOptions(this.mappedOptions);
  }

  saveOptions(options: Option[]) {
    let request: UpdateOptionMappingRequest[] = [];

    for (let i = 0; i < options.length; i++) {
      let option;
      if (options[i].optionMappingProperties?.specification !== undefined) {
        let optionProperties = <OptionMappingProperties>{
          specification: options[i].optionMappingProperties?.specification,
          wildcard: true,
        };

        option = <UpdateOptionMappingRequest>{
          id: options[i].id,
          preislistenKatalogId: this.selectedPriceData.id,
          optionMappingProperties: optionProperties,
        };
      }
      console.log(option);
      request.push(option);
    }

    console.log(options);
    this.optionService
      .saveOptionMappingProperties(request)
      .toPromise()
      .then((result) => {
        this.options = [...result];
        this.unmappedOptions = [];
        this.mappedOptions = [];

        this.mapOptionTypeDisplayName(result);
        this.mapOptionsToDatagrid(result);
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("option.load.error");
        this.showToast = true;
      });
  }

  handleWildcardEvent($event: any, datagrid: any) {
    // fail early
    if ($event.column.name !== "optionMappingProperties.wildcard") {
      return;
    }
    return;
    /*
      let datagridInstance;
      if (datagrid === 'unmapped'){
        datagridInstance = this.unmappedOptionGrid.instance;
      } else if (datagrid === 'mapped'){
        datagridInstance = this.mappedOptionGrid.instance;
      }

      if ($event.value){
        datagridInstance.beginUpdate();
        datagridInstance.columnOption("optionMappingProperties.width", "visible", false);
        datagridInstance.columnOption("optionMappingProperties.height", "visible", false);
        datagridInstance.columnOption("optionMappingProperties.edition", "visible", false);
        datagridInstance.columnOption("optionMappingProperties.binding", "visible", false);
        datagridInstance.columnOption("optionMappingProperties.specification", "visible", false)
        datagridInstance.endUpdate();
      } else {
        datagridInstance.beginUpdate();
        datagridInstance.columnOption("optionMappingProperties.width", "visible", true);
        datagridInstance.columnOption("optionMappingProperties.height", "visible", true);
        datagridInstance.columnOption("optionMappingProperties.edition", "visible", true);
        datagridInstance.columnOption("optionMappingProperties.binding", "visible", true);
        datagridInstance.columnOption("optionMappingProperties.specification", "visible", true)
        datagridInstance.endUpdate();
      }
      console.log($event);
     */
  }

  onCustomItemCreating(data: any) {
    console.log(data);

    if (!data.text) {
      data.customItem = null;
      return;
    }

    if (isNaN(data.text)) {
      data.customItem = null;
      return;
    }
    data.customItem = data.text;
  }

  resetSelectedPriceData(e: any) {
    this.preislistenKatalogLookup.instance.reset();
  }
}
