<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetOptionForm()"
  [title]="title"
  (onSave)="save()"
  maxWidth="910px"
>
  <app-option-form
    [(optionData)]="option"
    [optionUnitsAdmin]="optionUnitsAdmin"
    [produkte]="produkte"
    [preisListe]="preisListe"
    [dependentOptions]="dependentOptions"
  >
  </app-option-form>
</app-action-popup>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
