import { PreislistenKatalog } from "./produkt";
import { OptionGroup } from "./optionGroup";

export class OptionGroupFormData {
  id = "";
  groupName = "";
  preislistenKatalog = <PreislistenKatalog>{};
  sortId: number;

  // US
  ancillaryType: string;

  constructor(optionGroup?: OptionGroup) {
    if (optionGroup) {
      this.groupName = optionGroup.groupName + "";
      this.sortId = optionGroup.sortId;
      if (optionGroup.preislistenKatalog)
        this.preislistenKatalog = optionGroup.preislistenKatalog;
      this.id = optionGroup.id;
      this.ancillaryType = optionGroup.ancillaryType;
    }
  }
}
