import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { PreislistenKatalogFormData } from "../../../model/calculation/preislistenKatalogFormData";
import { CommonModule } from "@angular/common";
import { OptionGroupFormData } from "../../../model/calculation/optionGroupFormData";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-optiongroup-form",
  templateUrl: "./optionGroup-form.component.html",
  styleUrls: ["./optionGroup-form.component.scss"],
})
export class OptionGroupFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() optionGroupData: OptionGroupFormData;
  @Output() optionGroupDataChange = new EventEmitter<OptionGroupFormData>();

  environmentIsUsa = environment.isUsa;

  constructor() {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
  }

  ngOnInit(): void {}

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }
}

@NgModule({
  imports: [
    DxFormModule,
    CommonModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
  ],
  declarations: [OptionGroupFormComponent],
  exports: [OptionGroupFormComponent],
})
export class OptionGroupFormModule {}
