import { ProduktSpezifikationDefaultValue } from "./produktSpezifikationDefaultValue";
import DataSource from "devextreme/data/data_source";

export class ProduktSpezifikation {
  id: string;
  masterCategory: string;
  category: string;
  value: string;
  produktId: string;

  constructor(
    id: string,
    masterCategory: string,
    category: string,
    value: string,
    produktId: string
  ) {
    this.id = id;
    this.masterCategory = masterCategory;
    this.category = category;
    this.value = value;
    this.produktId = produktId;
  }
}

export class ProduktSpezifikationRequest {
  masterCategory: string;
  category: string;
  value: string;
  produktId: string;

  constructor(
    masterCategory: string,
    category: string,
    value: string,
    produktId: string
  ) {
    this.masterCategory = masterCategory;
    this.category = category;
    this.value = value;
    this.produktId = produktId;
  }
}

export class ProduktSpezifikationCategory {
  id: string;
  text: string;
  expanded?: boolean;
  selected?: boolean;
  content: string;
  masterCategory: string;
  category: string;
  paperSingleSelect: boolean;
  value: string | null;
  defaultValues: ProduktSpezifikationDefaultValue[];
  datasource: DataSource;
}

export class ProduktSpezifikationMasterCategory {
  id: string;
  masterCategory: string;
  text: string;
  expanded?: boolean;
  selected?: boolean;
  items: ProduktSpezifikationCategory[];
}

export class ProduktSpezifikationPermissions {
  produktType: string;
  masterCategoryPermission: MasterCategoryPermission[];
}

export class MasterCategoryPermission {
  masterCategory: string;
  visibility: boolean;
  categoryPermission: CategoryPermission[];
}

export class CategoryPermission {
  category: string;
  visibility: boolean;
}
