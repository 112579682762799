/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PaperPrice } from '../models/paper-price';
import { UuiDv4 } from '../models/uui-dv-4';

@Injectable({
  providedIn: 'root',
})
export class PaperPriceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPaperPrice
   */
  static readonly GetPaperPricePath = '/price-list-catalogs/{priceListCatalogId}/paperPrice';

  /**
   * get paperPrice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaperPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaperPrice$Response(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PaperPrice>> {

    const rb = new RequestBuilder(this.rootUrl, PaperPriceService.GetPaperPricePath, 'get');
    if (params) {
      rb.path('priceListCatalogId', params.priceListCatalogId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaperPrice>;
      })
    );
  }

  /**
   * get paperPrice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaperPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaperPrice(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
  },
  context?: HttpContext

): Observable<PaperPrice> {

    return this.getPaperPrice$Response(params,context).pipe(
      map((r: StrictHttpResponse<PaperPrice>) => r.body as PaperPrice)
    );
  }

  /**
   * Path part for operation updatePaperPrice
   */
  static readonly UpdatePaperPricePath = '/price-list-catalogs/{priceListCatalogId}/paperPrice';

  /**
   * update paperPrice
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaperPrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaperPrice$Response(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
    body: PaperPrice
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PaperPrice>> {

    const rb = new RequestBuilder(this.rootUrl, PaperPriceService.UpdatePaperPricePath, 'put');
    if (params) {
      rb.path('priceListCatalogId', params.priceListCatalogId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaperPrice>;
      })
    );
  }

  /**
   * update paperPrice
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaperPrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaperPrice(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
    body: PaperPrice
  },
  context?: HttpContext

): Observable<PaperPrice> {

    return this.updatePaperPrice$Response(params,context).pipe(
      map((r: StrictHttpResponse<PaperPrice>) => r.body as PaperPrice)
    );
  }

}
