import { Component, Input, OnInit } from "@angular/core";
import {
  CalculationResult,
  Mehrpreis,
} from "../../../../../../model/calculation/calculationResult";
import { NumberConverterService } from "../../../../../../services/number-converter.service";

@Component({
  selector: "app-aufschlag",
  templateUrl: "./aufschlag.component.html",
  styleUrls: ["./aufschlag.component.scss"],
})
export class AufschlagComponent implements OnInit {
  @Input()
  mehrpreisListA1: Mehrpreis[];

  @Input()
  mehrpreisListA2: Mehrpreis[];

  @Input()
  mehrpreisListA3: Mehrpreis[];

  @Input()
  calculationResponse: CalculationResult[];

  @Input()
  groupName: string;

  constructor(public converter: NumberConverterService) {}

  ngOnInit(): void {}
}
