<ng-container *ngIf="order$ | async as order; else noOrder">
  <div
    class="d-flex"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.1); padding-bottom: 12px"
  >
    <div class="details-subtitle">
      <div class="author-title">
        <span
          *ngIf="order.order.titleinfo.author"
          [title]="formatMessage('order.details.order.author')"
          style="cursor: default"
        >
          {{ order.order.titleinfo.author }}
        </span>
        <span
          *ngIf="order.order.titleinfo.title"
          [title]="formatMessage('order.details.order.title')"
          style="cursor: default"
        >
          {{ " - " + order.order.titleinfo.title }}
        </span>
      </div>
      <div>
        <span *ngIf="order.sender.publisher" style="cursor: default">
          {{ order.sender.publisher }}
        </span>
        <span *ngIf="order.order.ordernumberdiso" style="cursor: default">
          {{
            " - " +
              formatMessage("order.details.orderNumberDiso") +
              ": " +
              orderNumberDisoDisplay(order)
          }}
        </span>
      </div>
    </div>

    <div
      class="flex-grow d-flex"
      style="justify-content: end; align-items: end"
    >
      <dx-button
        id="upload-button-from-details"
        icon="upload"
        type="default"
        [disabled]="!hasCurrentOrderValidCategory()"
        [hint]="
          hasCurrentOrderValidCategory()
            ? ''
            : formatMessage('upload.notPossible')
        "
        [text]="formatMessage('upload.title')"
        (click)="navigateToUploadFromDetails()"
        style="pointer-events: auto"
      >
      </dx-button>
    </div>
  </div>

  <div class="d-flex kachel-container">
    <app-kachel
      [title]="formatMessage('order.details.properties.title')"
      iconClass="material-icons-outlined mdi-assignment"
      class="flex-grow properties-kachel"
    >
      <app-order-details-properties
        [order]="order"
        class="d-flex flex-grow flex-column kachel-component"
      ></app-order-details-properties>
    </app-kachel>

    <div class="d-flex status-studios-container">
      <app-kachel
        [title]="formatMessage('order.details.agency.title')"
        iconClass="material-icons-outlined mdi-business"
        class="studios-kachel"
      >
        <div class="kachel-component d-flex flex-grow">
          <app-order-details-agency
            [title]="formatMessage('order.publisher.standardStudios')"
            [studioEntityList]="order.publisher?.standardStudios || []"
            class="d-flex flex-grow flex-column"
            style="width: 50%; padding-right: 5px"
          ></app-order-details-agency>
          <app-order-details-agency
            [title]="formatMessage('order.studios')"
            [studioEntityList]="order.studios"
            class="d-flex flex-grow flex-column"
            style="width: 50%; padding-left: 5px"
          ></app-order-details-agency>
        </div>
      </app-kachel>

      <app-kachel
        [title]="formatMessage('order.details.status.title')"
        iconClass="material-icons-outlined mdi-timeline"
        class="flex-grow status-kachel"
      >
        <div
          class="
            d-flex
            flex-grow flex-column
            justify-content-center
            align-items-center
            text-center
            kachel-component
          "
        >
          <span
            *ngIf="order?.status?.status; else noStatusText"
            class="order-status-text"
          >
            {{ formatMessage("order.statusValue." + order.status.status) }}
          </span>
          <ng-template #noStatusText>
            <span
              style="
                font-size: 14px;
                font-weight: 300;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              {{ formatMessage("order.details.status.none") }}
            </span>
          </ng-template>
        </div>
      </app-kachel>
    </div>

    <app-kachel
      *ngIf="isAllowedToViewD3Documents"
      [title]="formatMessage('order.details.documents.title')"
      iconClass="dx-icon-file"
      class="flex-grow documents-kachel"
    >
      <app-order-details-documents
        [orderId]="orderId"
        class="d-flex flex-grow flex-column kachel-component"
      ></app-order-details-documents>
    </app-kachel>

    <app-kachel
      *ngIf="isAllowedToViewLogging"
      [title]="formatMessage('order.details.logging.title')"
      iconClass="dx-icon-variable"
      class="flex-grow logging-kachel"
    >
      <app-order-details-logging
        [orderId]="orderId"
        class="d-flex flex-grow flex-column kachel-component"
      ></app-order-details-logging>
    </app-kachel>
  </div>
</ng-container>

<ng-template #noOrder>
  <div
    class="
      d-flex
      flex-grow
      align-items-center
      justify-content-center
      text-center
    "
  >
    <dx-load-indicator [visible]="loading"></dx-load-indicator>
    <span *ngIf="!loading" style="font-weight: 300; font-size: 18px">
      {{ errorMessage }}
    </span>
  </div>
</ng-template>

<dx-toast
  [(visible)]="showToast"
  type="error"
  [message]="errorMessage"
></dx-toast>
