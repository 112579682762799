import { PreislistenKatalog } from "../calculation/produkt";
import { Paperdefinition } from "../calculation/paperdefinition";

export interface Organization {
  id: string;
  displayName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  __typename: string;
}

export class PublisherGroup implements Organization {
  id: string;
  disoNumber: number;
  displayName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  __typename: string;
}

export class Publisher implements Organization {
  id: string;
  disoNumber: number;
  name: string;
  displayName: string;
  publisherGroup: Publisher;
  standardStudios: Studio[];
  createdDate: Date;
  lastModifiedDate: Date;
  __typename: string;
  preislistenKatalog?: PreislistenKatalog;
}

export class Studio implements Organization {
  id: string;
  displayName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  __typename: string;

  constructor(studioEntity: StudioEntity) {
    this.id = studioEntity.id;
    this.displayName = studioEntity.displayName;
    this.createdDate = studioEntity.createdDate;
    this.lastModifiedDate = studioEntity.lastModifiedDate;
    this.__typename = "Studio";
  }
}

export class StudioEntity {
  id: string;
  displayName: string;
  createdDate: Date;
  lastModifiedDate: Date;
}

export function asStudio(studioEntity: StudioEntity): Studio {
  return new Studio(studioEntity);
}

export function isPublisherGroup(organization: Organization): boolean {
  return organization.__typename === "PublisherGroup";
}

export function isPublisher(organization: Organization): boolean {
  return organization.__typename === "Publisher";
}

export function isStudio(organization: Organization): boolean {
  return organization.__typename === "Studio";
}

export function getPublisherGroupId(organization: Organization[]): string {
  if (organization.length === 1 && isPublisherGroup(organization[0])) {
    return organization[0].id;
  } else {
    return "";
  }
}

export function getPublisherIds(organizations: Organization[]): string[] {
  if (organizations.every((it) => isPublisher(it))) {
    return organizations.map((it) => it.id);
  } else {
    return [];
  }
}

export function getStudioId(organization: Organization[]): string {
  if (organization.length === 1 && isStudio(organization[0])) {
    return organization[0].id;
  } else {
    return "";
  }
}

export function getDisplayNameOfOrganization(
  organization: Organization
): string {
  if (isPublisherGroup(organization)) {
    return getExtendedPublisherGroupDisplayName(organization as PublisherGroup);
  } else {
    return organization.displayName;
  }
}

export function getExtendedPublisherGroupDisplayName(
  pg: PublisherGroup
): string {
  const displayName = pg.displayName ? " (" + pg.displayName + ")" : "";

  return pg.disoNumber ? pg.disoNumber + displayName : "";
}
