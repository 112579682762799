<div class="container">
  <div class="left-content">
    <dx-tree-view
      height="420px"
      [dataSource]="produktSpezifikationMasterCategories"
      selectionMode="single"
      [selectByClick]="true"
      (onItemSelectionChanged)="changeSelection($event)"
    ></dx-tree-view>
  </div>
  <div class="right-content" *ngIf="selectedProduktSpezifikationCategory">
    <div
      class="d-flex"
      *ngIf="
        selectedProduktSpezifikationCategory != null &&
        selectedProduktSpezifikationCategory.content === 'EditBox'
      "
    >
      <div>
        <app-produkt-spezifikation-editbox
          [produktData]="produktData"
          [produktSpezifikationCategory]="selectedProduktSpezifikationCategory"
          class="d-flex flex-grow flex-column kachel-component"
        ></app-produkt-spezifikation-editbox>
      </div>
    </div>
    <div
      class="d-flex"
      *ngIf="
        selectedProduktSpezifikationCategory != null &&
        selectedProduktSpezifikationCategory.content === 'PaperGrid'
      "
    >
      <div>
        <app-produkt-spezifikation-papers
          [produktData]="produktData"
          [produktSpezifikationCategory]="selectedProduktSpezifikationCategory"
          class="d-flex flex-grow flex-column kachel-component"
        ></app-produkt-spezifikation-papers>
      </div>
    </div>
  </div>
</div>

<div class="button-container">
  <dx-button
    icon="save"
    [hint]="formatMessage('save')"
    stylingMode="text"
    type="default"
    [text]="formatMessage('save')"
    (onClick)="saveProduktSpezifikationen()"
  ></dx-button>
</div>
