<h2 class="d-flex align-items-center">
  <span style="margin-right: 6px">
    {{ formatMessage("organization.management." + organizationType) }}
  </span>
  <dx-button
    [hint]="formatMessage('organization.create.' + organizationType)"
    icon="plus"
    type="default"
    stylingMode="contained"
    style="border-radius: 32px; width: 32px; height: 32px"
    (onClick)="onCreate.emit(organizationType)"
  >
  </dx-button>
</h2>

<dx-data-grid
  [dataSource]="organizations"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
  class="d-flex flex-grow dx-card wide-card"
  style="padding: 4px 12px; border-radius: 6px"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>

  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [showInfo]="true"
    [allowedPageSizes]="[15, 30, 50]"
  ></dxo-pager>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-export [enabled]="true"></dxo-export>

  <dxi-column
    *ngIf="isPublisher"
    dataField="disoNumber"
    dataType="string"
    [caption]="formatMessage('organization.disoNumber')"
  ></dxi-column>
  <dxi-column
    *ngIf="isPublisher"
    dataField="name"
    [caption]="formatMessage('organization.disoName')"
  ></dxi-column>
  <dxi-column
    dataField="displayName"
    [caption]="formatMessage('organization.displayName')"
  ></dxi-column>
  <dxi-column
    *ngIf="isPublisher"
    dataField="preislistenKatalog.name"
    [caption]="formatMessage('organization.preislistenKatalog')"
  ></dxi-column>
  <dxi-column
    *ngIf="isPublisher"
    dataField="publisherGroup"
    [caption]="formatMessage('organization.publisher.publisherGroup')"
    cellTemplate="publisherGroupTemplate"
  >
    <div *dxTemplate="let d of 'publisherGroupTemplate'">
      <app-organization-tag
        *ngIf="d?.data?.publisherGroup"
        [organization]="d.data.publisherGroup"
      ></app-organization-tag>
    </div>
  </dxi-column>
  <dxi-column
    *ngIf="isPublisher && !environmentIsUSA"
    dataField="standardStudios"
    [caption]="formatMessage('organization.publisher.standardStudios')"
    cellTemplate="studioTemplate"
  >
    <div *dxTemplate="let d of 'studioTemplate'">
      <ng-container
        *ngIf="d.data.standardStudios.length > 3; else organizationTags"
      >
        <app-organization-union-tag
          [identifier]="'tag' + d.rowIndex + d.columnIndex"
          [organizations]="d.data.standardStudios"
          [label]="formatMessage('organization.studios')"
        ></app-organization-union-tag>
      </ng-container>
      <ng-template #organizationTags>
        <app-organization-tag
          *ngFor="let studio of d.data.standardStudios"
          [organization]="studio"
        ></app-organization-tag>
      </ng-template>
    </div>
  </dxi-column>
  <dxi-column
    [caption]="formatMessage('other.actions')"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="edit"
        [hint]="formatMessage('edit')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="onUpdate.emit(data.data)"
      ></dx-button>

      <dx-button
        class="action-button"
        icon="trash"
        [hint]="formatMessage('delete')"
        stylingMode="text"
        type="default"
        (onClick)="onDelete.emit(data.data)"
      ></dx-button>
    </div>
  </dxi-column>
</dx-data-grid>
