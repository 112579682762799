import { ProduktService } from "../../services/produkt.service";
import { Router } from "@angular/router";
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { P3AuthService } from "../../services";
import {
  DxFormModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
  DxDataGridModule,
  DxFormComponent,
} from "devextreme-angular";
import { combineLatest, Observable, Subscription } from "rxjs";
import { BrowserModule } from "@angular/platform-browser";
import { KachelModule } from "../kachel/kachel.component";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { ProduktSpezifikationOverviewModule } from "./produkt-spezifikation-overview/produkt-spezifikation-overview.component";
import { ProduktCsvUploadModule } from "./produkt-csv-upload/produkt-csv-upload.component";
import { ReturnButtonModule } from "../return-button/return-button.component";
import {
  PriceScaleDataSource,
  Produkt,
  ProduktVariante,
  ProduktVariantenDataSource,
} from "../../model/calculation/produkt";
import {
  ProduktFormData,
  produktNameFormData,
} from "../../model/calculation/produktFormData";
import {
  UpdateProduktRequest,
  UpdateProduktRequestUS,
} from "../../model/calculation/produktRequests";
import { ConfirmationDialogModule } from "../confirmation-dialog/confirmation-dialog.component";
import {
  editonSelectboxUs,
  produktTypSelectboxDe,
  produktTypSelectboxEn,
} from "../../model/calculation/produktSelectBoxValues";
import notify from "devextreme/ui/notify";
import { User } from "../../model/user/user";
import { AppModule } from "../../../app.module";
import { ProduktProduktVariantenDatagridModule } from "./produkt-produkt-varianten-datagrid/produkt-produkt-varianten-datagrid.component";
import { timeout } from "rxjs/operators";
import { ProduktSpezifikationOptionManagementModule } from "./produkt-option-management/produkt-option-management.component";
import { Option } from "../../model/calculation/option";
import { OptionService } from "../../services/option.service";
import { ProduktSpezifikationService } from "../../services/produkt-spezifikation.service";
import { ProductVariantService } from "../../services/product-variant.service";
import { environment } from "../../../../environments/environment";
import { ProductPriceScaleDatagridModule } from "./product-price-scale-datagrid/product-price-scale-datagrid.component";
import { ProductFormDeModule } from "../produkt-management/produkt-form/product-form-de/product-form-de.component";
import { ProductFormUsModule } from "../produkt-management/produkt-form/product-form-us/product-form-us.component";

@Component({
  selector: "app-produkt-details",
  templateUrl: "./produkt-details.component.html",
  styleUrls: ["./produkt-details.component.scss"],
})
export class ProduktDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  formatMessage = formatMessage;

  @Input() produktId: string;

  produkt$: Observable<Produkt>;
  produktFormData: ProduktFormData = new ProduktFormData();
  currentProdukt: Produkt;

  produktVariantenDataSource: ProduktVariantenDataSource[] = [];
  priceScaleDataSource: PriceScaleDataSource[] = [];

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmButtonText: string;

  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";
  showToast: boolean = false;

  subscriptions: Array<Subscription> = [];

  loading: boolean = true;

  tmpLocale: string;

  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };

  //user: User = <User>{};
  produktTypenDatasource;
  options: Option[];

  environmentIsUsa = environment.isUsa;

  constructor(
    public produktSpezifikationService: ProduktSpezifikationService,
    public produktService: ProduktService,
    public optionService: OptionService,
    private productVariantService: ProductVariantService,
    private router: Router,
    private _p3AuthService: P3AuthService,
    private authService: P3AuthService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  ngOnInit(): void {
    this.loadSelectBoxes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("produktId" in changes) {
      this.produktId = changes["produktId"].currentValue;
      this.loading = true;
      this.loadOptions();
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  reloadDataGridData() {
    console.log("triggered");
    this.loading = true;
    this.loadData();
  }

  loadData() {
    if (this.environmentIsUsa)
      this.produkt$ = this.produktService.getUsaProduct(this.produktId);
    else
      this.produkt$ = this.produktService.getProduktWithRawImportEntries(
        this.produktId
      );
    let produktSubscription = combineLatest([this.produkt$]).subscribe(
      ([produkt]) => {
        // USA
        if (this.environmentIsUsa) {
          this.produktFormData = new ProduktFormData(produkt);
          this.currentProdukt = produkt;
          if (produkt.priceScaleEntries != null)
            this.priceScaleDataSource = this.mapPriceScaleToDataSource(produkt);
          this.loading = false;
        }
        // DE
        else {
          produkt.produktVarianten = Array.from(
            this.productVariantService.getProductVariantsWithTechPriceList(
              produkt
            )
          );
          this.produktFormData = new ProduktFormData(produkt);
          this.currentProdukt = produkt;
          this.produktVariantenDataSource =
            this.mapProduktToProduktVarianten(produkt);
          this.loading = false;
        }
      },
      (error) => {
        if (error.message === "NotFoundException") {
          this.toastMessage = formatMessage("produkt.details.produktNotFound");
        } else {
          this.toastMessage = formatMessage("produkt.details.error");
        }

        this.showToast = true;
        this.loading = false;
      }
    );

    this.subscriptions.push(produktSubscription);
  }

  private mapProduktToProduktVarianten(produkt: Produkt) {
    let ds: ProduktVariantenDataSource[] = [];
    produkt.produktVarianten.forEach((variante) => {
      let produktVarianteDs: ProduktVariantenDataSource = {
        breite: variante.breite,
        fdContent: variante.technischePreisliste.fdContent,
        fdCover: variante.technischePreisliste.fdCover,
        fdTk: variante.technischePreisliste.fdTk,
        fdTkCover: variante.technischePreisliste.fdTkCover,
        fdTotal: variante.technischePreisliste.fdTotal,
        hoehe: variante.hoehe,
        maxAuflage: variante.maxAuflage,
        minAuflage: variante.minAuflage,
        paperContentFix: variante.technischePreisliste.paperContentFix,
        paperContentVar: variante.technischePreisliste.paperContentVar,
        paperCoverFix: variante.technischePreisliste.paperCoverFix,
        paperCoverVar: variante.technischePreisliste.paperCoverVar,
        paperId: variante.technischePreisliste.paperId,
        pricePerPiece: variante.technischePreisliste.pricePerPiece,
        tkContentFix: variante.technischePreisliste.tkContentFix,
        tkContentVar: variante.technischePreisliste.tkContentVar,
        tkCoverFix: variante.technischePreisliste.tkCoverFix,
        tkCoverVar: variante.technischePreisliste.tkCoverVar,
        umfang: variante.umfang,
        standardFormat: variante.technischePreisliste.standardFormat,
        rollenBreite: variante.technischePreisliste.rollenBreite,
        zylinderUmfang: variante.technischePreisliste.zylinderUmfang,
      };
      ds.push(produktVarianteDs);
    });
    ds.sort((a, b) => (a.umfang < b.umfang ? -1 : 1));
    return ds;
  }

  private mapPriceScaleToDataSource(produkt: Produkt) {
    let ds: PriceScaleDataSource[] = [];
    produkt.priceScaleEntries.forEach((priceScaleEntry) => {
      let produktVarianteDs: PriceScaleDataSource = {
        pages: priceScaleEntry.pages,
        width: priceScaleEntry.width,
        height: priceScaleEntry.height,
        quantity: priceScaleEntry.quantity,
        paperId: priceScaleEntry.paperId,
        imposeOutputCtp: priceScaleEntry.imposeOutputCtp,
        proofBook: priceScaleEntry.proofBook,
        plates: priceScaleEntry.plates,
        textMr: priceScaleEntry.textMr,
        textRun: priceScaleEntry.textRun,
        bindingMr: priceScaleEntry.bindingMr,
        bindingRun: priceScaleEntry.bindingRun,
        additionals: priceScaleEntry.additionals,
      };
      ds.push(produktVarianteDs);
    });
    ds.sort((a, b) => (a.quantity < b.quantity ? -1 : 1));
    return ds;
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  loadSelectBoxes(): void {
    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
        }
        this.tmpLocale = this.getLocale();
        if (this.tmpLocale == "de-DE") {
          this.produktTypenDatasource = produktTypSelectboxDe;
        } else {
          this.produktTypenDatasource = produktTypSelectboxEn;
        }
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  update(): void {
    if (this.currentProdukt.produktType != this.produktFormData.produktType) {
      this.produktSpezifikationService
        .deleteProduktSpezifikationByProdukt(this.produktFormData.id)
        .toPromise()
        .then(() => {})
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("produkt.update.error");
        })
        .finally(() => {
          this.showToast = true;
        });
    }

    this.updateProdukt(this.produktFormData)
      .then((updatedProdukt) => {
        this.toastMessage = formatMessage("produkt.update.success");
        this.toastType = "success";
      })
      .catch((e) => {
        if (["ALREADY_EXISTS", "NOT_FOUND"].includes(e.message)) {
          this.toastMessage = formatMessage(`produkt.error.${e.message}`);
        } else {
          this.toastMessage = formatMessage("produkt.update.error");
        }

        this.toastType = "error";
      })
      .finally(() => {
        this.showToast = true;
        this.loadData();
      });
  }

  updateProdukt(data: ProduktFormData): Promise<Produkt> {
    if (this.environmentIsUsa) {
      console.log("Update US");

      return this.produktService
        .updateProduktUs(
          new UpdateProduktRequestUS(
            data.id,
            data.name,
            data.produktType,
            data.circulationLimit,
            data.width,
            data.height,
            data.edition,
            data.reprint,
            data.twoTitles
          )
        )
        .toPromise();
    } else {
      return this.produktService
        .updateProdukt(
          new UpdateProduktRequest(
            data.id,
            data.name,
            data.produktType,
            data.circulationLimit
          )
        )
        .toPromise();
    }
  }

  showConfirmationPopupForUpdateProdukt() {
    this.confirmationPopupTitle = formatMessage(`produkt.edit.confirm.title`);
    this.confirmationPopupMessage = formatMessage(
      `produkt.edit.confirm.message`,
      this.produktFormData.name
    );
    this.confirmButtonText = formatMessage("save");

    this.showConfirmationPopup = true;
  }

  private loadOptions() {
    this.optionService.findOptionByProdukt(this.produktId).subscribe(
      (r) => {
        this.options = r.produktOptions;
      },
      () => {
        console.log("No options found");
      }
    );
  }
}

@NgModule({
  imports: [
    DxFormModule,
    DxToastModule,
    DxTextBoxModule,
    BrowserModule,
    DxTextAreaModule,
    DxCheckBoxModule,
    KachelModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxLoadIndicatorModule,
    ReturnButtonModule,
    ProduktSpezifikationOverviewModule,
    ProduktCsvUploadModule,
    ConfirmationDialogModule,
    DxDataGridModule,
    ProduktProduktVariantenDatagridModule,
    ProduktSpezifikationOptionManagementModule,
    ProductPriceScaleDatagridModule,
    ProductFormDeModule,
    ProductFormUsModule,
  ],
  declarations: [ProduktDetailsComponent],
  exports: [ProduktDetailsComponent],
})
export class ProduktDetailsModule {}
