<div class="content-block" id="container">
  <h2>{{ formatMessage("userManagement") }}</h2>

  <div class="d-flex" *ngIf="isAllowedToAuthorizeInternalUsers">
    <app-alert
      *ngIf="notAuthorizedInternalUsers.length > 0"
      [message]="translateInternalUserAlertMessage()"
      [multiLine]="isMobile"
      [style]="isMobile ? 'width: 100%' : ''"
      style="margin-bottom: 20px; cursor: pointer"
      (click)="authorizeUserPopupVisible = true"
    ></app-alert>
  </div>

  <dx-speed-dial-action
    [elementAttr]="{ id: 'faButton' }"
    *ngIf="!isMobile && showNewUserButton; else textButton"
    icon="add"
    (onClick)="createUser()"
  ></dx-speed-dial-action>

  <ng-template #textButton>
    <dx-button
      *ngIf="showNewUserButton"
      stylingMode="contained"
      type="default"
      icon="add"
      [text]="formatMessage('user.create.external')"
      style="margin-bottom: 12px; width: 100%"
      (onClick)="createUser()"
    ></dx-button>
  </ng-template>

  <dx-data-grid
    id="user-grid"
    [dataSource]="users"
    (onContentReady)="placeFloatingActionButton()"
    [columnAutoWidth]="!isMobile"
    [columnHidingEnabled]="true"
    class="dx-card wide-card"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="[15, 30, 50, 'all']"
    ></dxo-pager>

    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
      [texts]="{
        emptyValue: formatMessage('user.dataGrid.headerFilter.emptyValue')
      }"
    ></dxo-header-filter>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
      dataField="identity.loginName"
      [caption]="formatMessage('user.loginName')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="identity.firstName"
      [caption]="formatMessage('user.firstName')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="identity.lastName"
      [caption]="formatMessage('user.lastName')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="identity.email"
      [caption]="formatMessage('user.email')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      *ngIf="!environmentIsUsa"
      dataField="newsletterStatus.subscribed"
      [caption]="formatMessage('user.newsletterList')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="identity.enabled"
      [showEditorAlways]="false"
      dataType="boolean"
      [trueText]="formatMessage('user.enabled')"
      [falseText]="formatMessage('user.notEnabled')"
      [caption]="formatMessage('user.enabled')"
      [allowHeaderFiltering]="false"
      cellTemplate="enabledIconTemplate"
    >
      <div *dxTemplate="let d of 'enabledIconTemplate'">
        <i
          *ngIf="d.data.identity.enabled"
          class="dx-icon-check success dx-font-m"
        ></i>
        <i
          *ngIf="!d.data.identity.enabled"
          class="dx-icon-close dx-font-m"
          style="color: grey"
        ></i>
      </div>
    </dxi-column>

    <dxi-column
      dataField="role"
      [caption]="formatMessage('user.role')"
      [calculateCellValue]="translateRole"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      *ngIf="!environmentIsUsa"
      dataField="worksFor"
      [caption]="formatMessage('user.worksFor')"
      [headerFilter]="{
        dataSource: worksForHeaderFilterDataSource
      }"
      [calculateDisplayValue]="calculateWorksForCellValue"
      [calculateCellValue]="calculateWorksForCellValue"
      [calculateFilterExpression]="filterWorksFor"
      cellTemplate="worksForTemplate"
    >
      <div *dxTemplate="let d of 'worksForTemplate'">
        <ng-container *ngIf="d.data.worksFor.length > 4; else organizationTags">
          <app-organization-union-tag
            [identifier]="'tag' + d.rowIndex + d.columnIndex"
            [organizations]="d.data.worksFor"
            [label]="formatMessage('user.worksFor.publisher')"
          ></app-organization-union-tag>
        </ng-container>
        <ng-template #organizationTags>
          <app-organization-tag
            *ngFor="let organization of d.data.worksFor"
            [organization]="organization"
          ></app-organization-tag>
        </ng-template>
      </div>
    </dxi-column>

    <dxi-column
      dataField="identity.locale"
      [caption]="formatMessage('locale')"
      [calculateCellValue]="translateLocale"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="identity.userCreateDate"
      [caption]="formatMessage('user.createDate')"
      [allowHeaderFiltering]="false"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>
    <dxi-column
      dataField="identity.userLastModifiedDate"
      [caption]="formatMessage('user.lastModifiedDate')"
      [allowHeaderFiltering]="false"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>
    <dxi-column
      [caption]="formatMessage('other.actions')"
      [allowHeaderFiltering]="false"
      type="buttons"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
      <div *dxTemplate="let data of 'actionsTemplate'">
        <dx-button
          class="action-button"
          icon="edit"
          [hint]="formatMessage('edit')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="editUser(data.data)"
        ></dx-button>

        <dx-button
          class="action-button"
          [hint]="
            data.data.identity.enabled
              ? formatMessage('user.disable')
              : formatMessage('user.enable')
          "
          stylingMode="text"
          type="default"
          [icon]="
            data.data.identity.enabled
              ? 'material-icons mdi-lock'
              : 'material-icons mdi-lock-open'
          "
          style="margin-right: 8px"
          (onClick)="showConfirmationPopupForChangeEnabledStatus(data.data)"
        >
          <i
            *ngIf="data.data.identity.enabled; else lockOpen"
            class="dx-icon material-icons mdi-lock"
          ></i>
          <ng-template #lockOpen>
            <i class="dx-icon material-icons mdi-lock-open"></i>
          </ng-template>
        </dx-button>

        <dx-button
          class="action-button"
          icon="trash"
          [hint]="formatMessage('delete')"
          stylingMode="text"
          type="default"
          (onClick)="showConfirmationPopupForDeleteUser(data.data)"
        ></dx-button>
      </div>
    </dxi-column>
    <dxo-toolbar>
      <dxi-item
        location="after"
        cssClass="toolbar-export-button"
        name="exportButton"
      ></dxi-item>
    </dxo-toolbar>
    <dxo-export
      [enabled]="true"
      [fileName]="formatMessage('user.export.filename')"
    ></dxo-export>
  </dx-data-grid>
</div>

<app-authorize-users-popup
  [(visible)]="authorizeUserPopupVisible"
  [users]="notAuthorizedInternalUsers"
  (onSave)="authorizeInternalUsers($event)"
></app-authorize-users-popup>

<app-user-form-popup
  [(visible)]="userPopupVisible"
  [user]="userFormData"
  [creating]="!selectedUserId"
  [publisherGroups]="publisherGroups"
  [publisher]="publisher"
  [studios]="studios"
  (onSave)="save($event)"
></app-user-form-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="executeConfirmOperation()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
