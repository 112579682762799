export class Locale {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export const locales = [
  new Locale("English", "en-EN"),
  new Locale("Deutsch", "de-DE"),
];
