<dx-data-grid
  id="gridContainer"
  [dataSource]="optionData.optionPriceInformation"
  [showBorders]="true"
  [wordWrapEnabled]="true"
  (onRowInserted)="addSurchargePossible($event)"
  (onRowRemoved)="addSurchargePossible($event)"
  (onContentReady)="addSurchargePossible($event)"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    mode="form"
    [allowUpdating]="true"
    [allowAdding]="allowAdding"
    [allowDeleting]="true"
  >
  </dxo-editing>

  <dxi-column dataField="surcharge" [caption]="'Surcharge (%)'"> </dxi-column>
</dx-data-grid>
