import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { Option } from "../../../../../model/calculation/option";
import { DxListComponent } from "devextreme-angular";
import { CalculatorService } from "../../../../../services/calculator.service";
import { ProduktOptionResult } from "../../../../../model/calculation/produktOptionResult";
import { OptionHeader } from "../../../../../model/calculation/optionHeader";
import { formatMessage } from "devextreme/localization";
import { SelectedOption } from "../../../../../model/calculation/selectedOption";
import { LocaleService } from "../../../../../services/locale.service";
import { OptionPriceInformation } from "../../../../../model/calculation/optionPriceInformation";
import { OwnOption } from "../../../../../model/calculation/ownOption";
import { OptionGroup } from "../../../../../model/calculation/optionGroup";
import {
  dropOptionPositionDe,
  dropOptionPositionEn,
} from "../../../../option-management/option-form/optionSelectBoxValues";
import { ActivatedRoute } from "@angular/router";
import { UuiDv4 } from "generated/api/models";
import { CalculationService } from "generated/api/services";
import { CalculatorPriceOptionsComponent } from "../calculator-price-options.component";

@Component({
  selector: "app-price-option-list",
  templateUrl: "./price-option-list.component.html",
  styleUrls: ["./price-option-list.component.scss"],
})
export class PriceOptionListComponent implements OnInit {
  @ViewChild(DxListComponent)
  list: DxListComponent;

  data: Option[];

  ownData: OwnOption[];

  listDataSource: any;

  listOwnDataSource: any;

  prohibitedOptions: Option[] = [];

  formatMessage = formatMessage;

  @Input()
  neededOptions: Set<string>;

  @Input()
  optionsWithMissingInput: SelectedOption[];

  @Input()
  ownOptionsWithMissingInput: OwnOption[];

  ownOptions: OwnOption[];

  @Input()
  optionsWithPriceInformation: boolean;
  currentLocale: string | null;
  optionPositionDatasource: any;
  value: any;

  constructor(
    private calculatorService: CalculatorService,
    private localeService: LocaleService,
    private route: ActivatedRoute,
    private calculationService: CalculationService,
    private calculatorPriceOptions: CalculatorPriceOptionsComponent
  ) {}

  prepareOptions(): void {
    this.prepareDataSource();
    this.prepareOwnOptionSource();
    this.selectLoaded();
  }

  private selectLoaded(): void {
    var calculationId: UuiDv4 = "";
    this.route.paramMap.subscribe((params) => {
      calculationId = params.get("id") || "";
      if (calculationId != "") {
        this.calculationService
          .getCalculation({ id: calculationId })
          .subscribe((calculation) => {
            if (calculation.options) {
              var allOptions = this.list.items
                .flatMap((item) => item.items)
                .flatMap((item) => item);
              for (var i = 0; i < calculation.options.length; i++) {
                var option = allOptions.find(
                  (option) => option.id === calculation.options![i].id!
                );
                if (option) {
                  option.userInput = "" + calculation.options![i].userInput;
                  this.list.instance.selectItem(option);
                }
              }
              this.calculatorPriceOptions.submitOptionen(true);
            }
          });
      }
    });
  }

  private prepareDataSource(): void {
    this.list.instance.unselectAll();

    this.data = this.calculatorService.produktOptions.options;
    console.log("data", this.data);
    this.listDataSource = new DataSource({
      store: this.data,
      postProcess: (data) => {
        return data.sort((a, b) => {
          return (
            a.items[0]?.optionGroup.sortId - b.items[0]?.optionGroup.sortId
          );
        });
      },
      sort: ["sortId"],
      key: "id",
      group: "optionGroup.groupName",
    });
    setTimeout(() => {
      this.prepareActiveGroupHeader();
    }, 1);
  }

  private prepareOwnOptionSource(): void {
    this.ownData = this.calculatorService.ownOptions || [];

    this.listOwnDataSource = new DataSource({
      store: this.ownData,
      key: "sortId",
    });
  }

  ngOnInit(): void {
    this.localeService.getCurrentLocale().subscribe((locale) => {
      this.currentLocale = locale;
      this.loadOptionPositionDatasource();
    });
  }

  onInitialized(e) {
    e.component.option("selectionByClick", false);
  }

  onSelectionChanged($event: any) {
    let selectedOptions = [];
    this.list.selectedItemKeys.forEach((item) => {
      selectedOptions = selectedOptions.concat(item.id);
    });
    this.calculatorService
      .findProduktOptions(this.calculatorService.produktId, selectedOptions)
      .subscribe(
        (r) => {
          //console.log(r);
          this.calculatorService.produktOptions = r;
        },
        () => {
          this.calculatorService.produktOptions = <ProduktOptionResult>{};
        },
        () => {
          this.handleOptionRules($event);
        }
      );
  }

  private handleOptionRules(e) {
    let selectables = this.calculatorService.produktOptions.selectable;
    let paths = this.calculatorService.produktOptions.paths;

    let prohibitedOptions: Option[] = [];
    let neededOptions: Option[] = [];

    let currentlySelectedOptions: Option[] = [];
    let currentlyRemovedOptions: Option[] = [];

    paths.forEach((selectedOption) => {
      e.addedItems.forEach((addedItem) => {
        if (addedItem.id == selectedOption.id) {
          currentlySelectedOptions.push(selectedOption);
        }
      });
      e.removedItems.forEach((removedItem) => {
        if (removedItem.id == selectedOption.id) {
          currentlyRemovedOptions.push(selectedOption);
        }
      });
      if (selectedOption.prohibit) {
        selectedOption.prohibit.forEach((prohibitOption) => {
          prohibitedOptions.push(prohibitOption);
        });
      }
      /*
      if (selectedOption.needs) {
        if (selectedOption.needs.edgeType === "AND") {
          selectedOption.needs.nodeList.forEach((neededOption) => {
            neededOptions.push(neededOption);
          });
        }
      }*/
    });

    this.data.forEach((option) => {
      option.disabled = false;
      option.disabled = option.dependsOn;

      /*
      currentlySelectedOptions.forEach((currentlySelectedOption)=>{
        if (currentlySelectedOption){
          if (currentlySelectedOption.needs?.edgeType === "AND"){
            currentlySelectedOption.needs?.nodeList.forEach((currentlyNeededOption)=>{
              neededOptions.forEach((neededOption) => {
                if (neededOption.id === option.id && neededOption.id === currentlyNeededOption.id) {
                  let groupFound = false;
                  this.list.selectedItems.forEach((item) => {
                    if (item.key === option.optionGroup.groupName) {
                      item.items.push(option);
                      option.dependsOn = true;
                      groupFound = true;
                    }
                  });
                  if (!groupFound) {
                    this.list.selectedItems.push({
                      items: [option],
                      key: option.optionGroup.groupName,
                    });
                  }
                }
              });
            })
          }
        }
      })
      */
      currentlyRemovedOptions.forEach((currentlyRemovedOption) => {
        currentlyRemovedOption.disabled = false;
        currentlyRemovedOption.dependsOn = false;
        this.list.selectedItemKeys = this.list.selectedItemKeys.filter(
          (selectedOption) => {
            return selectedOption !== currentlyRemovedOption;
          }
        );
      });

      prohibitedOptions.forEach((prohibitOption) => {
        if (!prohibitOption) return;
        if (prohibitOption.id == option.id) {
          option.disabled = true;
          this.list.selectedItemKeys = this.list.selectedItemKeys.filter(
            (selectedOption) => {
              return selectedOption !== option;
            }
          );
        }
      });
    });

    // clear user input by unselecting
    if (e.addedItems.length == 0 && e.removedItems.length > 0)
      e.removedItems.forEach((option) => {
        if (option.showUserInput) option.userInput = null;
      });

    if (this.list.selectedItems.length > 0) this.listDataSource.load();
  }

  private prepareActiveGroupHeader() {
    this.list.items.forEach((group) => {
      let headerConfig: OptionHeader = {
        showUserInput: false,
        showFixkosten: false,
        showVarKosten: false,
        showFortdruckkosten: false,
        showWert: false,
      };
      //console.log(headerConfig)
      group.items.forEach((item: Option) => {
        if (item.optionPriceInformation) {
          item.optionPriceInformation.forEach((info) => {
            if (info.fixKosten) {
              headerConfig.showFixkosten = true;
            }
            if (info.variableKosten) {
              headerConfig.showVarKosten = true;
            }
            if (info.fortDruckKosten) {
              headerConfig.showFortdruckkosten = true;
            }
          });
        }
        if (item.optionType) {
          switch (item.optionType) {
            case "MEHRPREIS":
              item.showUserInput = false;
              break;
            case "STAFFELMEHRPREIS":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "STUECKMEHRPREIS":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "AUFSCHLAG_TECHNISCHE_KOSTEN_EINGABEWERT":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "AUFSCHLAG_PAPIERKOSTEN_EINGABEWERT":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "AUFSCHLAG_STANDARDPRODUKTKOSTEN_EINGABEWERT":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_EINGABEWERT":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            case "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_EINGABEWERT":
              headerConfig.showUserInput = true;
              item.showUserInput = true;
              break;
            default:
              item.showUserInput = false;
              headerConfig.showUserInput = true;
              break;
          }
        }
      });
      group.items.forEach((item) => {
        item.headerConfig = headerConfig;
      });
    });
    //console.log(this.list.items)
  }

  getPriceInformation(data: Option, kostenArt: string) {
    let locale = this.currentLocale?.startsWith("de") ? "de-DE" : "en-EN";

    function isMatrixPrice(priceInfo: OptionPriceInformation) {
      return (
        priceInfo.fixKosten !== undefined ||
        priceInfo.variableKosten !== undefined ||
        priceInfo.fortDruckKosten !== undefined
      );
    }

    switch (kostenArt) {
      case "fixKosten":
        if (data.optionPriceInformation.length == 1)
          return data.optionPriceInformation[0].fixKosten.toLocaleString(
            locale,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
        if (data.optionPriceInformation.length == 0) return;
        for (let i = 1; i < data.optionPriceInformation.length; i++) {
          let priceInfo = data.optionPriceInformation[i];
          if (isMatrixPrice(priceInfo)) {
            return "Matrixpreis";
          }
        }
        return data.optionPriceInformation[0].fixKosten.toLocaleString(locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      case "variableKosten":
        if (data.optionPriceInformation.length == 1)
          return data.optionPriceInformation[0].variableKosten.toLocaleString(
            locale,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
        if (data.optionPriceInformation.length == 0) return;
        for (let i = 1; i < data.optionPriceInformation.length; i++) {
          let priceInfo = data.optionPriceInformation[i];
          if (isMatrixPrice(priceInfo)) {
            return "Matrixpreis";
          }
        }
        return data.optionPriceInformation[0].variableKosten.toLocaleString(
          locale,
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        );

      case "fortDruckKosten":
        if (data.optionPriceInformation.length == 1) {
          return data.optionPriceInformation[0].fortDruckKosten.toLocaleString(
            locale,
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          );
        }

        if (data.optionPriceInformation.length == 0) return;
        for (let i = 1; i < data.optionPriceInformation.length; i++) {
          let priceInfo = data.optionPriceInformation[i];
          if (isMatrixPrice(priceInfo)) {
            return "Matrixpreis";
          }
        }
        return data.optionPriceInformation[0].fortDruckKosten.toLocaleString(
          locale,
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        );
      case "fixedValue":
        return data.fixedValue.toLocaleString(locale);
      default:
        return null;
    }
  }

  validateOptionList() {
    let selectedOptions = this.calculatorService.produktOptions.paths;
    let neededOptions: Set<Option> = new Set();
    let optionsLeft: Set<string> = new Set();

    if (!selectedOptions) {
      let variable: Set<string> = new Set();
      return variable;
    }

    selectedOptions.forEach((sOption) => {
      if (sOption.needs) {
        if (sOption.needs.nodeList.length > 0) {
          sOption.needs.nodeList.forEach((nOption) => {
            neededOptions.add(nOption);
          });
        }
      }
    });

    neededOptions.forEach((nOption) => {
      let isPresent = false;
      selectedOptions.forEach((sOption) => {
        if (sOption.id == nOption.id) {
          isPresent = true;
        }
      });
      if (!isPresent) {
        let nOptionName = this.mapOptionIdToOptionName(nOption);
        if (nOptionName) optionsLeft.add(nOptionName);
      }
    });
    return optionsLeft;
  }

  mapOptionIdToOptionName(nOption: Option) {
    let options = this.calculatorService.produktOptions.options;
    for (let i = 0; i < options.length; i++) {
      if (nOption.id == options[i].id) {
        return options[i].optionName;
      }
    }
    return;
  }

  getOptionsWithMissingInput() {
    let options = { ...this.list.selectedItems };
    let found = false;
    let optionsWithMissingInput: SelectedOption[] = [];
    Object.keys(options).forEach((key) => {
      let group = options[key];
      if (group.items) {
        group.items.forEach((option: Option) => {
          if (option.showUserInput) {
            if (option.userInput == undefined) {
              let selectedOption = {
                id: option.id,
              };
              optionsWithMissingInput.push(selectedOption);
            } else {
              if (!option.showUserInput) found = true;
              else if (
                option.optionPriceInformation &&
                option.optionPriceInformation.length > 0
              ) {
                option.optionPriceInformation.forEach((info) => {
                  if (
                    info.von <= option.userInput &&
                    info.bis >= option.userInput
                  ) {
                    found = true;
                  }
                });
              }
            }
          } else {
            // TODO: Possibly comparison with current edition necessary
            found = true;
          }
        });
      }
    });
    return { optionsWithMissingInput, found };
  }

  formatUserInputToTwoDigits(e) {
    if (e.component.option("value")) {
      let oldValue = e.component.option("value");
      let newValue = parseFloat(oldValue).toFixed(2);
      e.component.option("value", newValue);
    } else e.component.option("value", null);
  }

  addOwnOptions($event: any) {
    let ownOptions = new OwnOption();

    ownOptions.optionName = "Name";
    ownOptions.optionPosition = "GESAMTPREIS";
    ownOptions.sortId = 1;
    ownOptions.optionType = "MEHRPREIS";
    ownOptions.optionGroup = new OptionGroup();
    ownOptions.optionGroup.sortId = 999;
    ownOptions.optionGroup.groupName = "Eigene Optionen";
    let priceInformation = new OptionPriceInformation();
    priceInformation.bis = 1000000;
    priceInformation.von = 0;
    priceInformation.fixKosten = 0;
    priceInformation.fortDruckKosten = 0;
    priceInformation.variableKosten = 0;
    ownOptions.optionPriceInformation = [];
    ownOptions.optionPriceInformation.push(priceInformation);
    this.ownData.push(ownOptions);
    this.listOwnDataSource.load();
  }

  validateOwnOptions() {
    let invalidOwnOptions: OwnOption[] = [];
    if (!this.ownData) return invalidOwnOptions;

    for (let i = 0; i < this.ownData.length; i++) {
      if (
        this.ownData[i].optionName == "" ||
        this.ownData[i].optionName == null
      ) {
        invalidOwnOptions.push(this.ownData[i]);
        continue;
      }
      if (this.ownData[i].optionPriceInformation[0].fixKosten == null) {
        invalidOwnOptions.push(this.ownData[i]);
        continue;
      }
      if (this.ownData[i].optionPriceInformation[0].variableKosten == null) {
        invalidOwnOptions.push(this.ownData[i]);
      }
    }
    this.calculatorService.ownOptions = this.ownData;

    return invalidOwnOptions;
  }

  private loadOptionPositionDatasource() {
    switch (this.currentLocale) {
      case "de-DE":
        this.optionPositionDatasource = dropOptionPositionDe;
        break;
      case "en-EN":
        this.optionPositionDatasource = dropOptionPositionEn;
        break;
      // In Zukunft wird es eventuell benötigt
      //case "us-US": this.optionPositionDatasource = dropOptionPositionUs;break;
    }
  }
}
