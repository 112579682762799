<ng-template [ngIf]="(locale | async) === 'en-EN'" [ngIfElse]="default">
  <h2 class="content-block">Imprint</h2>
  <h3 class="content-block">
    Information according to Section 5 Subsection 1 of the German Telemedia Act
    (Telemediengesetz [TMG])
  </h3>
  <h5 class="content-block">
    GGP Media GmbH <br />
    Karl-Marx-Straße 24 <br />
    07381 Pößneck
  </h5>
  <h5 class="content-block">
    Telephone: +49 3647 430-0 <br />
    Fax: +49 3647 430-390 <br />
    E-Mail:
    <a href="mailto:ggp.poessneck@bertelsmann.de"
      >ggp.poessneck@bertelsmann.de</a
    >
  </h5>
  <h5 class="content-block">
    Court of registration: Local Court of Jena, HRB 200481<br />
    VAT ID number pursuant to Section 27a of the German Value Added Tax Act
    (Umsatzsteuergesetz [UStG]): DE811148509<br />
    Managing Directors: Sven Isecke, Dirk Kemmerer
  </h5>
  <h5 class="content-block">
    Responsible according to the German Press Law (§55 RStV):<br />
    Sven Isecke<br />
    Address as above
  </h5>
  <h3 class="content-block">Copyright</h3>
  <h5 class="content-block">
    Copyright 2023 GGP Media GmbH. All rights reserved. All content (text,
    images, graphics, sound, video and animation files as well as their grouping
    etc.) on the GGP Media GmbH website are protected by copyright and other
    intellectual property laws. This legal protection also extends to databases
    and similar facilities. The contents are made freely available for viewing
    online solely in accordance with legal provisions. The content of these
    sites may not be duplicated, distributed, changed or made accessible to
    third parties in any form beyond the confines of copyright law without prior
    written permission from GGP Media GmbH. Furthermore, several areas of the
    GGP Media GmbH website contain images that are copyrighted by third parties.
    Wherever not otherwise specified, all trademarks on the GGP Media GmbH
    website are protected by trademark law.
  </h5>
  <h3 class="content-block">Liability</h3>
  <h5 class="content-block">
    All information contained in this website has been carefully checked. We
    make every effort to continuously add to and update the available
    information. However, we cannot guarantee that the material contained herein
    is complete, correct and absolutely up-to-date. GGP Media GmbH makes this
    information available without any explicit or implicit promise or guarantee.
    GGP Media GmbH accepts no liability for damages incurred directly or
    indirectly by using this website, as far as they are not attributable to
    intention or gross negligence on the part of direct services Gütersloh GmbH.
    The pages of our website contain links to external websites. These links are
    intended solely to provide information. We are not responsible for the
    content of any linked websites. The content of linked pages is the exclusive
    responsibility of their operators.
  </h5>
  <h3 class="content-block">Editors</h3>
  <h5 class="content-block">
    GGP Media GmbH<br />
    public relations
  </h5>
</ng-template>
<ng-template #default>
  <h2 class="content-block">Impressum</h2>
  <h3 class="content-block">Angaben gemäß § 5 Abs. 1 TMG</h3>
  <h5 class="content-block">
    GGP Media GmbH<br />
    Karl-Marx-Straße 24<br />
    07381 Pößneck<br />
  </h5>
  <h5 class="content-block">
    Telefon: +49 3647 430-0<br />
    Telefax: +49 3647 430-390<br />
    E-Mail:

    <a href="mailto:ggp.poessneck@bertelsmann.de"
      >ggp.poessneck@bertelsmann.de</a
    >
  </h5>
  <h5 class="content-block">
    Registergericht: Amtsgericht Jena, HRB 200481<br />
    Umsatzsteuer-Identifikationsnummer nach § 27a UStG: DE811148509<br />
    Geschäftsführer: Sven Isecke, Dirk Kemmerer
  </h5>
  <h5 class="content-block">
    V.i.S.d. §55 RStV:<br />
    Sven Isecke<br />
    Anschrift wie oben
  </h5>
  <h3 class="content-block">Urheberrecht</h3>
  <h5 class="content-block">
    Copyright 2023 GGP Media GmbH. Alle Rechte vorbehalten. Alle Inhalte (Texte,
    Bilder, Grafiken, Ton-, Video- und Animationsdateien sowie deren Anordnung
    u. a.) auf der Website von GGP Media unterliegen dem Schutz des
    Urheberrechts und anderer Schutzgesetze. Der Rechtsschutz gilt auch
    gegenüber Datenbanken und ähnlichen Einrichtungen. Die Inhalte sind nur für
    den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte dieser
    Website dürfen außerhalb der Grenzen des Urheberrechts ohne schriftliche
    Genehmigung der GGP Media GmbH nicht in irgendeiner Form vervielfältigt,
    verbreitet, verändert oder Dritten zugänglich gemacht werden. Einige
    Bereiche der Website von GGP Media enthalten außerdem Bilder, die dem
    Copyright Dritter unterliegen. Soweit nicht anders angegeben, sind alle
    Markenzeichen auf der Website markenrechtlich geschützt.
  </h5>
  <h3 class="content-block">Haftungshinweis</h3>
  <h5 class="content-block">
    Alle Angaben dieses Internetangebotes wurden sorgfältig geprüft. Wir bemühen
    uns, dieses Informationsangebot stetig zu erweitern und zu aktualisieren.
    Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität
    kann jedoch nicht übernommen werden. GGP Media stellt diese Informationen
    ohne jegliche Zusicherung oder Gewährleistung jedweder Art, sei sie
    ausdrücklich oder stillschweigend, zur Verfügung. Die GGP Media GmbH
    schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der
    Benutzung dieser Website entstehen, aus, soweit diese nicht auf Vorsatz oder
    grober Fahrlässigkeit von GGP Media beruhen. Unsere Internetseiten enthalten
    Links auf externe Webseiten. Diese Links haben einen reinen
    Informationszweck. Auf die Entwicklung der Inhalte dieser verlinkten
    Webseiten haben wir keinen Einfluss. Für den Inhalt der verlinkten Seiten
    sind daher ausschließlich deren Betreiber verantwortlich.
  </h5>
  <h3 class="content-block">Redaktion</h3>
  <h5 class="content-block">
    GGP Media GmbH<br />
    Öffentlichkeitsarbeit
  </h5>
</ng-template>
