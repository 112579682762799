import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ItemClickEvent } from "devextreme/ui/tree_view";
import {
  DxTreeViewComponent,
  DxTreeViewModule,
} from "devextreme-angular/ui/tree-view";

import * as events from "devextreme/events";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { P3AuthService } from "../../services";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";
import { UpdateInternalUserRequest } from "../../model/user/userRequests";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-side-navigation-menu",
  templateUrl: "./side-navigation-menu.component.html",
  styleUrls: ["./side-navigation-menu.component.scss"],
})
export class SideNavigationMenuComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  treeviewInstance;

  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();
  navigationSubscription: Subscription;

  saveTreeInstance(e) {
    this.treeviewInstance = e.component;
  }

  private _selectedItem!: String;

  @Input() set selectedItem(value: String) {
    this._selectedItem = value;

    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  private _items!: Record<string, unknown>[];
  get items() {
    return this._items;
  }

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private authService: P3AuthService,
    private treeViewInstanceService: TreeViewInstanceService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.navigationSubscription = this.authService
      .getNavigationForCurrentUser()
      .subscribe((navigation) => {
        this._items = navigation.map((item) => {
          if (item.path && !/^\//.test(item.path)) {
            item.path = `/${item.path}`;
          }
          return { ...item, expanded: !this._compactMode };
        });
      });
  }

  onItemClick(event: ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, "dxclick", (e: Event) => {
      this.openMenu.next(e);
    });
    this.treeViewInstanceService.setTreeViewInstance(this.treeviewInstance);
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, "dxclick");
    this.navigationSubscription.unsubscribe();
  }

  setSelectedItem() {
    this.menu.instance.selectItem(this._selectedItem);
  }
}

@NgModule({
  imports: [DxTreeViewModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
