import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  Organization,
  PublisherGroup,
} from "../../../model/organization/organization";
import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { OrganizationType } from "../../../model/organization/organizationFormData";
import { PublisherGroupCardModule } from "../publisher-group-card/publisher-group-card.component";
import { ScreenService } from "../../../services";

@Component({
  selector: "app-publisher-group-grid",
  templateUrl: "./publisher-group-grid.component.html",
  styleUrls: ["./publisher-group-grid.component.scss"],
})
export class PublisherGroupGridComponent implements OnInit {
  isMediumOrDownScreen: boolean;

  formatMessage = formatMessage;

  @Input() publisherGroups: PublisherGroup[];
  @Output() onCreate = new EventEmitter<OrganizationType>();
  @Output() onUpdate = new EventEmitter<Organization>();
  @Output() onDelete = new EventEmitter<Organization>();

  publisherGroupsVisible: boolean = true;

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.isMediumOrDownScreen = this.screen.sizes["screen-medium-or-down"];
  }

  createPublisherGroup() {
    this.onCreate.emit(OrganizationType.PUBLISHER_GROUP);
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule, PublisherGroupCardModule],
  declarations: [PublisherGroupGridComponent],
  exports: [PublisherGroupGridComponent],
})
export class PublisherGroupGridManagement {}
