<dx-form
  id="preislistenKatalogForm"
  labelMode="floating"
  [formData]="preislistenKatalogData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newPreislistenKatalogFormValidation"
>
  <dxi-item
    dataField="name"
    [label]="{ text: formatMessage('preislistenKatalog.name') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
</dx-form>
