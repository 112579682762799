export class VorUndNachsatz {
  produktTeil: string;
  language: string;
  records: VorUndNachsatzRecords[];
}

export class VorUndNachsatzRecords {
  produktTeilKnz: string;
  nutzenBreite: string;
  nutzenHoehe: string;
  formatBreite: string;
  anschnitt: string;
  kritRandRL: string;
  kritRandOU: string;
  bezeichnung: string;
  produktTyp: string;
  endFormat: string;
  umfang: string;
  inhaltsPapier: string;
  bindeArt: string;
  rueckenForm: string;
  pappenDicke: string;
  umfangBildTeil: string;
  papierBildTeil: string;
  deckelKanteA: string;
  deckelKanteOU: string;
  datum: string;
}

export class CalculatedVorUndNachsatzRecord {
  nutzenBreite: number;
  nutzenHoehe: number;
  formatBreite: number;
}
