import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  NgModule,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {
  DxFileUploaderModule,
  DxFileUploaderComponent,
  DxProgressBarModule,
  DxButtonModule,
  DxFormModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import { AlertModule } from "../../../alert/alert.component";
import { ScreenService } from "../../../../services";
import { CommonModule } from "@angular/common";
import { ProduktFormData } from "../../../../model/calculation/produktFormData";

@Component({
  selector: "app-calculator-csv-import",
  templateUrl: "./calculator-csv-import.component.html",
  styleUrls: ["./calculator-csv-import.component.scss"],
})
export class CalculatorCsvImportComponent implements OnInit {
  uploadEndpointUrl = `${environment.baseUrl}/api/calculator/uploadCsvFile`;
  environmentIsUsa: boolean = environment.isUsa;
  @ViewChild(DxFileUploaderComponent) fileUploader: any;

  @Output() reloadEvent = new EventEmitter<any>();

  constructor(private http: HttpClient, private screenService: ScreenService) {
    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onSelectedFileChanged = this.onSelectedFileChanged.bind(this);

    if (this.environmentIsUsa) {
      this.uploadEndpointUrl = `${environment.baseUrl}/api/calculator/us/uploadPriceScale`;
    }
    console.log(this.uploadEndpointUrl);
  }

  @Input() produktData: ProduktFormData;

  isUploading: boolean = false;
  isDropZoneActive = false;
  showErrorBorders = false;
  invalidFileMessage: string = "";
  successAlertMessage: string = "";
  progressVisible = false;
  progressValue = 0;

  isLargeOrUp: boolean;

  hasNetworkConnection: boolean;
  showReconnectedToNetwork = false;

  formatMessage = formatMessage;

  ngOnInit(): void {
    this.hasNetworkConnection = window.navigator.onLine;
    this.isLargeOrUp = this.screenService.sizes["screen-large-or-up"];
  }

  @HostListener("window:online")
  setNetworkOnline(): void {
    this.showReconnectedToNetwork =
      !this.hasNetworkConnection && this.isUploading;
    this.hasNetworkConnection = true;
  }

  @HostListener("window:offline")
  setNetworkOffline(): void {
    this.showReconnectedToNetwork = false;
    this.hasNetworkConnection = false;
  }

  resetUploadValues() {
    this.isUploading = false;
    this.successAlertMessage = "";
    this.invalidFileMessage = "";

    this.progressVisible = false;
    this.progressValue = 0;
  }

  resetDropZoneErrorValues() {
    this.showErrorBorders = false;
    this.invalidFileMessage = "";
  }

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === "dropzone-external") {
      this.isDropZoneActive = true;
    }
  }

  onDropZoneLeave(e) {
    this.resetDropZoneErrorValues();

    if (e.dropZoneElement.id === "dropzone-external")
      this.isDropZoneActive = false;
  }

  onDropZoneClick(e) {
    if (!this.hasNetworkConnection) {
      e.preventDefault();
    }
  }

  onSelectedFileChanged() {
    let firstFile = this.fileUploader.instance._files[0];

    if (firstFile) {
      if (!firstFile.isValid()) {
        this.invalidFileMessage = formatMessage(
          "upload.invalid.csv",
          firstFile.value.name
        );

        this.isDropZoneActive = false;
        this.showErrorBorders = true;
      } else {
        this.resetDropZoneErrorValues();

        const file = this.fileUploader.instance._files[0];
        console.log(file);

        const formData = new FormData();
        formData.append("file", file.value);
        formData.append("produktId", this.produktData.id);

        if (file) {
          /*
          this.fileUploader.uploadUrl = `${environment.baseUrl}/api/calculator/uploadCsvFile`;

          let request = new XMLHttpRequest();
          request.open(
            this.fileUploader.instance.option("uploadMethod"),
            this.fileUploader.uploadUrl,
            true
          );
          file.request = request;

          request.onreadystatechange = function (oEvent) {
            if (request.readyState === 4) {
              if (request.status === 200) {
                CalculatorCsvImportComponent.successHandler(file.value);
              } else {
                CalculatorCsvImportComponent.errorHandler(file.value);
              }
            }
          };

          file.request.send(formData);

          this.fileUploader.instance._isProgressStarted = false;
*/
          this.uploadCsvFile(formData, file).then((r) =>
            console.log("success")
          );
        }
      }
    }
  }

  private async uploadCsvFile(formData, file) {
    this.http.post(this.uploadEndpointUrl, formData).subscribe(
      () => {
        let fileUploaderInstance = <any>this.fileUploader.instance;
        fileUploaderInstance._isProgressStarted = false;
        CalculatorCsvImportComponent.successHandler(file.value);
        this.progressVisible = false;
        this.progressValue = 100;
      },
      (error) => {
        CalculatorCsvImportComponent.errorHandler(file.value);
      },
      () => {
        this.reloadEvent.next();
      }
    );
  }

  onProgress(e) {
    this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
  }

  onUploadStarted(e) {
    this.successAlertMessage = "";
    this.isUploading = true;
    this.progressVisible = true;
  }

  onUploadError(e) {
    this.resetUploadValues();
  }

  private static errorHandler(file) {
    notify(formatMessage("produkt.upload.error", file.name), "error", 3000);
  }

  private static successHandler(file) {
    notify(formatMessage("produkt.upload.success", file.name), "success", 3000);
  }

  refresh() {
    window.location.reload();
  }
}

@NgModule({
  imports: [
    DxFormModule,
    DxFileUploaderModule,
    DxButtonModule,
    AlertModule,
    DxProgressBarModule,
    CommonModule,
  ],
  declarations: [CalculatorCsvImportComponent],
  exports: [CalculatorCsvImportComponent],
})
export class CalculatorCsvImportModule {}
