import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Upload } from "../../../model/upload/upload";
import { isSingleUploadOrderPiece } from "../../../model/productTypes";

@Component({
  selector: "app-upload-data-grid",
  templateUrl: "./upload-data-grid.component.html",
  styleUrls: ["./upload-data-grid.component.scss"],
})
export class UploadDataGridComponent implements OnInit {
  formatMessage = formatMessage;

  @Input()
  uploads: Upload[];

  @Input()
  height: string;

  @Input()
  isAllowedToDownloadUploads: boolean;

  @Input()
  isAllowedToReactivateUpload: boolean;

  @Input()
  isOrderReadyForPrinting: boolean;

  @Output()
  onDeactivateUpload: EventEmitter<Upload> = new EventEmitter<Upload>();

  uploadedFilesRowHint: string;

  hoveredRowIndex: number;

  constructor() {}

  ngOnInit(): void {}

  translateOrderPiece(rowData) {
    return formatMessage(`upload.orderPiece.${rowData.orderPiece}`);
  }

  translatePreflightStatus(rowData) {
    return formatMessage(
      `upload.preflight.status.${rowData.data.preflightStatus}`
    );
  }

  translateAvCheckStatus(rowData) {
    if (rowData.data.avCheckStatus?.status === "NO_THREATS_FOUND") {
      return "";
    }

    return formatMessage(
      `upload.avCheck.status.${rowData.data.avCheckStatus?.status}`
    );
  }

  getAvCheckIconClass(rowData) {
    const styleClass = "dx-font-m mr-4";

    if (rowData.data.avCheckStatus?.status === "NO_THREATS_FOUND") {
      return "dx-icon-check success " + styleClass;
    } else if (rowData.data.avCheckStatus?.status === "THREATS_FOUND") {
      return "dx-icon-warning error " + styleClass;
    } else if (
      rowData.data.avCheckStatus?.status === "UNSUPPORTED" ||
      rowData.data.avCheckStatus?.status === "ACCESS_DENIED" ||
      rowData.data.avCheckStatus?.status === "FAILED"
    ) {
      return "dx-icon-close error " + styleClass;
    }

    return "";
  }

  getPreflightIconClass(rowData) {
    const styleClass = "dx-font-m mr-4";

    if (rowData.data.preflightStatus === "SUCCESSFUL") {
      return "dx-icon-check success " + styleClass;
    } else if (rowData.data.preflightStatus === "FAILED") {
      return "dx-icon-close error " + styleClass;
    }

    return "";
  }

  showDownloadButton(rowData) {
    return rowData.row.data.avCheckStatus.status === "NO_THREATS_FOUND";
  }

  showReactivateButton(rowData) {
    return (
      rowData.row.data.active &&
      isSingleUploadOrderPiece(rowData.row.data.orderPiece)
    );
  }

  showDownloadReportButton(rowData) {
    return !!rowData.data.preflightReportUrl;
  }

  onCellHoverChanged(e) {
    if (e.rowType === "data") {
      this.hoveredRowIndex = e.row.rowIndex;
    }

    if (e.rowType === "data" && !e.data.active) {
      if (e.eventType === "mouseover") {
        this.uploadedFilesRowHint = formatMessage("upload.deactivated.hint");
      }
    } else {
      this.uploadedFilesRowHint = "";
    }
  }

  setDeactivatedUploadRowClass(event) {
    if (event.rowType === "data") {
      if (!event.data.active) {
        event.rowElement.classList.add("deactivated-row");
      }
    }
  }
}
