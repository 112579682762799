export const blattdickeUmschlag = [
  {
    papiergruppe: "KARTON_180_220",
    blattdicke: 0.28,
  },
  {
    papiergruppe: "KARTON_225_350",
    blattdicke: 0.35,
  },
  {
    papiergruppe: "BILDERDRUCK_MATT_130_200",
    blattdicke: 0.17,
  },
  {
    papiergruppe: "BILDERDRUCK_MATT_210_300",
    blattdicke: 0.3,
  },
  {
    papiergruppe: "BILDERDRUCK_GLAENZEND_130_200",
    blattdicke: 0.14,
  },
  {
    papiergruppe: "BILDERDRUCK_GLAENZEND_210_300",
    blattdicke: 0.19,
  },
];

export const aufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier = [
  {
    inhaltPapier: "WERKDRUCKPAPIER",
    blockStaerkeVon: 0,
    blockStaerkeBis: 10,
    aufschlag: 1.0,
  },
  {
    inhaltPapier: "UNGESTRICHENES_PAPIER",
    blockStaerkeVon: 0,
    blockStaerkeBis: 10,
    aufschlag: 1.0,
  },
  {
    inhaltPapier: "BILDERDRUCKPAPIER",
    blockStaerkeVon: 0,
    blockStaerkeBis: 10,
    aufschlag: 1.5,
  },
  {
    inhaltPapier: "WERKDRUCKPAPIER",
    blockStaerkeVon: 10.0000000001,
    blockStaerkeBis: 10000000,
    aufschlag: 1.5,
  },
  {
    inhaltPapier: "UNGESTRICHENES_PAPIER",
    blockStaerkeVon: 10.0000000001,
    blockStaerkeBis: 10000000,
    aufschlag: 1.5,
  },
  {
    inhaltPapier: "BILDERDRUCKPAPIER",
    blockStaerkeVon: 10.0000000001,
    blockStaerkeBis: 10000000,
    aufschlag: 2.0,
  },
];

export const aufschlagRueckenbreiteByUmfang = [
  {
    umfangVon: 0,
    umfangBis: 863,
    aufschlag: 0,
  },
  {
    umfangVon: 864,
    umfangBis: 1007,
    aufschlag: 0.5,
  },
  {
    umfangVon: 1008,
    umfangBis: 1167,
    aufschlag: 1.0,
  },
  {
    umfangVon: 1168,
    umfangBis: 1327,
    aufschlag: 1.5,
  },
  {
    umfangVon: 1328,
    umfangBis: 10000000,
    aufschlag: 2.0,
  },
];

export const aufschlagSURueckenbreiteByRueckenformAndProduktstaerke = [
  {
    rueckenform: "RUND",
    produktstaerkeVon: 0,
    produktstaerkeBis: 39.99999999,
    aufschlag: 1,
  },
  {
    rueckenform: "RUND",
    produktstaerkeVon: 40,
    produktstaerkeBis: 10000000,
    aufschlag: 2,
  },
  {
    rueckenform: "GERADE",
    produktstaerkeVon: 0,
    produktstaerkeBis: 39.99999999,
    aufschlag: 1,
  },
  {
    rueckenform: "GERADE",
    produktstaerkeVon: 40,
    produktstaerkeBis: 10000000,
    aufschlag: 1,
  },
];

export const aufschlagFormatbreiteKlappenbroschur = [
  {
    klappenart: "LEICHT_UEBERSTEHEND",
    aufschlag: 1,
  },
  {
    klappenart: "LEICHT_ZURUECKGESETZT",
    aufschlag: -2,
  },
  {
    klappenart: "BUENDIG",
    aufschlag: 0,
  },
];

export const volumenfaktorBildteile = [
  {
    bildteilMaterial: "BILDERDRUCK_GLAENZEND_115",
    grammatur: 115,
    volumenfaktor: 0.7,
  },
  {
    bildteilMaterial: "BILDERDRUCK_MATT_115",
    grammatur: 115,
    volumenfaktor: 0.82,
  },
  {
    bildteilMaterial: "BILDERDRUCK_GLAENZEND_130",
    grammatur: 130,
    volumenfaktor: 0.7,
  },
  {
    bildteilMaterial: "BILDERDRUCK_MATT_130",
    grammatur: 130,
    volumenfaktor: 0.7,
  },
];

export const produktTypDe = [
  {
    key: "SCHUTZUMSCHLAG",
    name: "Schutzumschlag",
  },
  {
    key: "BEZUG",
    name: "Bezug (UE)",
  },
  {
    key: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
    name: "Hardcover mit Schutzumschlag",
  },
  {
    key: "BROSCHURUMSCHLAG",
    name: "Broschurumschlag",
  },
  {
    key: "KLAPPENBROSCHUR",
    name: "Klappenbroschur",
  },
  {
    key: "FARBSCHNITT",
    name: "Farbschnitt",
  },
];

export const produktTypEn = [
  {
    key: "SCHUTZUMSCHLAG",
    name: "Schutzumschlag",
  },
  {
    key: "BEZUG",
    name: "Bezug (UE)",
  },
  {
    key: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
    name: "Hardcover mit Schutzumschlag",
  },
  {
    key: "BROSCHURUMSCHLAG",
    name: "Broschurumschlag",
  },
  {
    key: "KLAPPENBROSCHUR",
    name: "Klappenbroschur",
  },
  {
    key: "FARBSCHNITT",
    name: "Farbschnitt",
  },
];

export const aufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke = [
  {
    rueckenform: "RUND",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 0,
    produktstaerkeBis: 17,
    aufschlag: 1.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 17.0000000001,
    produktstaerkeBis: 30,
    aufschlag: 2.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 30.0000000001,
    produktstaerkeBis: 43,
    aufschlag: 3.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 43.0000000001,
    produktstaerkeBis: 55,
    aufschlag: 4.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 55.0000000001,
    produktstaerkeBis: 10000000,
    aufschlag: 5.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 0,
    produktstaerkeBis: 15,
    aufschlag: 1.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 15.0000000001,
    produktstaerkeBis: 25,
    aufschlag: 2.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 25.0000000001,
    produktstaerkeBis: 35,
    aufschlag: 3.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 35.0000000001,
    produktstaerkeBis: 45,
    aufschlag: 4.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 45.0000000001,
    produktstaerkeBis: 50,
    aufschlag: 5.0,
  },
  {
    rueckenform: "RUND",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 50.0000000001,
    produktstaerkeBis: 10000000,
    aufschlag: 6.0,
  },
  {
    rueckenform: "GERADE",
    bindeart: "KLEBEBINDEN",
    produktstaerkeVon: 0,
    produktstaerkeBis: 10000000,
    aufschlag: -1.0,
  },
  {
    rueckenform: "GERADE",
    bindeart: "FADENHEFTEN",
    produktstaerkeVon: 0,
    produktstaerkeBis: 10000000,
    aufschlag: -1.0,
  },
];

export const abzugDeckelbreite = [
  {
    rueckenform: "RUND",
    blockstaerkeVon: 15.00000001,
    blockstaerkeBis: 10000000,
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2.5,
    abschlag: -5.0,
  },
  {
    rueckenform: "RUND",
    blockstaerkeVon: 15.00000001,
    blockstaerkeBis: 10000000,
    pappenstaerkeVon: 2.50000001,
    pappenstaerkeBis: 10000000,
    abschlag: -6.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 15.00000001,
    blockstaerkeBis: 10000000,
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    abschlag: -4.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 15.00000001,
    blockstaerkeBis: 10000000,
    pappenstaerkeVon: 2.00000001,
    pappenstaerkeBis: 2.5,
    abschlag: -4.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 15.00000001,
    blockstaerkeBis: 10000000,
    pappenstaerkeVon: 2.50000001,
    pappenstaerkeBis: 10000000,
    abschlag: -5.0,
  },
  {
    rueckenform: "RUND",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    abschlag: -5.0,
  },
  {
    rueckenform: "RUND",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 2.00000001,
    pappenstaerkeBis: 2.5,
    abschlag: -6.0,
  },
  {
    rueckenform: "RUND",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 2.50000001,
    pappenstaerkeBis: 10000000,
    abschlag: -7.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    abschlag: -4.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 2.00000001,
    pappenstaerkeBis: 2.5,
    abschlag: -5.0,
  },
  {
    rueckenform: "GERADE",
    blockstaerkeVon: 0,
    blockstaerkeBis: 15,
    pappenstaerkeVon: 2.50000001,
    pappenstaerkeBis: 10000000,
    abschlag: -6.0,
  },
];

export const falzbreite = [
  {
    rueckenform: "RUND",
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 30,
    falzbreite: 7.5,
  },
  {
    rueckenform: "RUND",
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    rueckenbreiteVon: 30.000000001,
    rueckenbreiteBis: 10000000,
    falzbreite: 8.0,
  },
  {
    rueckenform: "RUND",
    pappenstaerkeVon: 2.0000000001,
    pappenstaerkeBis: 2.5,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 10000000,
    falzbreite: 9.0,
  },
  {
    rueckenform: "RUND",
    pappenstaerkeVon: 2.5000000001,
    pappenstaerkeBis: 10000000,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 10000000,
    falzbreite: 10.0,
  },
  {
    rueckenform: "GERADE",
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 2,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 10000000,
    falzbreite: 8.0,
  },
  {
    rueckenform: "GERADE",
    pappenstaerkeVon: 2.0000000001,
    pappenstaerkeBis: 2.5,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 10000000,
    falzbreite: 9.0,
  },
  {
    rueckenform: "GERADE",
    pappenstaerkeVon: 2.5000000001,
    pappenstaerkeBis: 10000000,
    rueckenbreiteVon: 0,
    rueckenbreiteBis: 10000000,
    falzbreite: 10.0,
  },
];

export const breiteHoeheEinschlaege = [
  {
    pappenstaerkeVon: 0,
    pappenstaerkeBis: 1.3,
    abschlag: 14,
  },
  {
    pappenstaerkeVon: 1.30000000001,
    pappenstaerkeBis: 2,
    abschlag: 15,
  },
  {
    pappenstaerkeVon: 2.00000000001,
    pappenstaerkeBis: 3,
    abschlag: 16,
  },
  {
    pappenstaerkeVon: 3.00000000001,
    pappenstaerkeBis: 4,
    abschlag: 17,
  },
  {
    pappenstaerkeVon: 4.00000000001,
    pappenstaerkeBis: 10000000,
    abschlag: 19,
  },
];

export const deckelkantenObenUnten = [
  {
    endformatHoeheMax: 23,
    deckelkantenGesamt: 4.0,
  },
  {
    endformatHoeheMax: 10000000,
    deckelkantenGesamt: 5.0,
  },
];
