import { Component, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-printing-template",
  templateUrl: "./printing-template.component.html",
  styleUrls: ["./printing-template.component.scss"],
})
export class PrintingTemplateComponent implements OnInit {
  formatMessage = formatMessage;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  constructor() {}

  ngOnInit(): void {}
}
