<div class="content-block" id="container">
  <h2>
    {{ formatMessage("paperManagement") }}
    <dx-button
      [hint]="formatMessage('paperManagement.create')"
      icon="plus"
      type="default"
      stylingMode="contained"
      style="border-radius: 32px; width: 32px; height: 32px"
      (onClick)="createPaper()"
    >
    </dx-button>
  </h2>
</div>
<div class="d-flex flex-grow flex-column content-block">
  <app-papier-data-grid
    [papers]="allPapers"
    [certficateFilter]="certificateFilter"
    (onEdit)="updatePaper($event)"
    (onDelete)="showConfirmationPopupForDeletePaper($event)"
  >
  </app-papier-data-grid>
</div>
<app-action-popup
  [(visible)]="papierPopupVisible"
  (onSave)="save()"
  (onShowing)="resetValues(); resetRequiredFieldsValidation()"
  [title]="title"
>
  <app-papier-form [editing]="editing" [papierFormData]="papierFormData">
  </app-papier-form>
</app-action-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="deletePaperAndUpdateView()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
