import { Component, Input, NgModule, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxDataGridModule } from "devextreme-angular";
import {
  DxiColumnModule,
  DxoEditingModule,
  DxoPagingModule,
} from "devextreme-angular/ui/nested";
import { formatMessage } from "devextreme/localization";
import { OptionFormData } from "../../../../model/calculation/optionFormData";

@Component({
  selector: "app-option-data-grid-de",
  templateUrl: "./option-data-grid-de.component.html",
  styleUrls: ["./option-data-grid-de.component.scss"],
})
export class OptionDataGridDeComponent implements OnInit {
  @Input() optionData: OptionFormData;
  constructor() {}

  ngOnInit(): void {}
  setColumnNameOfOptionPriceInformation(optionType: any, columnIndex: number) {
    return formatMessage(`optionData.${optionType}`)[columnIndex];
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxiColumnModule,
    DxoEditingModule,
    DxoPagingModule,
  ],
  declarations: [OptionDataGridDeComponent],
  exports: [OptionDataGridDeComponent],
})
export class OptionDataGridDeModule {}
