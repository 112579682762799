/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PaperConsumption } from '../models/paper-consumption';
import { UuiDv4 } from '../models/uui-dv-4';

@Injectable({
  providedIn: 'root',
})
export class PaperConsumptionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPaperConsumption
   */
  static readonly GetPaperConsumptionPath = '/price-list-catalogs/{priceListCatalogId}/paperConsumption';

  /**
   * get paperConsumption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaperConsumption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaperConsumption$Response(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PaperConsumption>> {

    const rb = new RequestBuilder(this.rootUrl, PaperConsumptionService.GetPaperConsumptionPath, 'get');
    if (params) {
      rb.path('priceListCatalogId', params.priceListCatalogId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaperConsumption>;
      })
    );
  }

  /**
   * get paperConsumption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPaperConsumption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaperConsumption(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
  },
  context?: HttpContext

): Observable<PaperConsumption> {

    return this.getPaperConsumption$Response(params,context).pipe(
      map((r: StrictHttpResponse<PaperConsumption>) => r.body as PaperConsumption)
    );
  }

  /**
   * Path part for operation updatePaperConsumption
   */
  static readonly UpdatePaperConsumptionPath = '/price-list-catalogs/{priceListCatalogId}/paperConsumption';

  /**
   * update paperConsumption
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePaperConsumption()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaperConsumption$Response(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
    body: PaperConsumption
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PaperConsumption>> {

    const rb = new RequestBuilder(this.rootUrl, PaperConsumptionService.UpdatePaperConsumptionPath, 'put');
    if (params) {
      rb.path('priceListCatalogId', params.priceListCatalogId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaperConsumption>;
      })
    );
  }

  /**
   * update paperConsumption
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePaperConsumption$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePaperConsumption(params: {

    /**
     * UUID of the PriceListCatalogId
     */
    priceListCatalogId: UuiDv4;
    body: PaperConsumption
  },
  context?: HttpContext

): Observable<PaperConsumption> {

    return this.updatePaperConsumption$Response(params,context).pipe(
      map((r: StrictHttpResponse<PaperConsumption>) => r.body as PaperConsumption)
    );
  }

}
