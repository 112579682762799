import { gql } from "apollo-angular";

export const GET_USER_SUGGESTIONS = gql`
  query {
    findAllUserSuggestions {
      id
      firstName
      lastName
      email
      createdDate
      lastModifiedDate
      locale
    }
  }
`;
