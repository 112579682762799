//SelectBox Values
export const produktTypSelectboxDe = [
  {
    name: "Hardcover ohne Schutzumschlag",
    value: "HARDCOVER_OHNE_SCHUTZUMSCHLAG",
  },
  {
    name: "Hardcover mit Schutzumschlag",
    value: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
  },
  {
    name: "Softcover",
    value: "SOFTCOVER",
  },
  {
    name: "Softcover mit Klappen",
    value: "SOFTCOVER_MIT_KLAPPEN",
  },
  {
    name: "Sonstiges",
    value: "SONSTIGES",
  },
];

export const produktTypSelectboxEn = [
  {
    name: "Hardcover without Dustjacket",
    value: "HARDCOVER_OHNE_SCHUTZUMSCHLAG",
  },
  {
    name: "Hardcover with Dustjacket",
    value: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
  },
  {
    name: "Softcover",
    value: "SOFTCOVER",
  },
  {
    name: "Softcover with Flaps",
    value: "SOFTCOVER_MIT_KLAPPEN",
  },
  {
    name: "Other",
    value: "SONSTIGES",
  },
];

export const produktTypeSelectboxUsTrade = [
  {
    name: "Hardcover",
    value: "TRH",
  },
  {
    name: "Softcover",
    value: "TRS",
  },
];

export const produktTypeSelectboxUsMassMarket = [
  {
    name: "Head to Head",
    value: "HH",
  },
  {
    name: "Head to Foot",
    value: "HF",
  },
];

export const editonSelectboxUs = [
  {
    name: "Mass Market",
    value: "MM",
  },
  {
    name: "Trade",
    value: "TRD",
  },
];
