<dx-form
  id="produktForm"
  labelMode="floating"
  [formData]="produktFormData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newProduktFormValidation"
>
  <dxi-item
    dataField="name"
    [label]="{ text: formatMessage('produkt.name') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    #produktTyp
    name="produktType"
    dataField="produktType"
    [colSpan]="2"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: produktTypenDatasource,
      valueExpr: 'value',
      displayExpr: 'name'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label
      text="{{ formatMessage('printing.template.produkttyp') }}"
    ></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="circulationLimit"
    editorType="dxCheckBox"
    [label]="{ text: formatMessage('produkt.circulationlimit') }"
  >
    <dxo-label text="Low Average">LABEL</dxo-label>
  </dxi-item>
</dx-form>
