<div class="content-block" style="padding-top: 30px">
  <img
    src="{{ listmonkUrl }}public/static/logo.png"
    style="padding-bottom: 30px"
  />
  <br />
  <a href="{{ listmonkUrl }}" target="_blank">{{ listmonkUrl }}</a>

  <dx-toast
    [(visible)]="showToast"
    [type]="toastType"
    [message]="toastMessage"
  ></dx-toast>
</div>
