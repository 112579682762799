import { UserFormData } from "./userFormData";

export class UpdateInternalUserRequest {
  id: string;
  role: string;
  locale: string;

  constructor(id: string, role: string, locale: string) {
    this.id = id;
    this.role = role;
    this.locale = locale;
  }
}

export class IdentityRequest {
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  subscribeToNewsletter: string;
  notification: boolean;

  constructor(userForm: UserFormData) {
    this.firstName = userForm.firstName;
    this.lastName = userForm.lastName;
    this.email = userForm.email;
    this.locale = userForm.locale;
    this.subscribeToNewsletter = userForm.subscribeToNewsletter;
    this.notification = userForm.notification;
  }
}

export class AuthorizeInternalUserRequest {
  sub: string;
  role: string;

  constructor(sub: string, role: string) {
    this.sub = sub;
    this.role = role;
  }
}
