import {
  Component,
  Input,
  OnInit,
  ViewChild,
  NgModule,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  DxButtonModule,
  DxCheckBoxComponent,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import {
  Paperdefinition,
  ProduktPaperDefinition,
} from "../../../model/calculation/paperdefinition";
import { CommonModule } from "@angular/common";
import DevExpress from "devextreme";
import DataSource = DevExpress.data.DataSource;
import DxCheckBox from "devextreme/ui/check_box";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-produkt-papers-grid",
  templateUrl: "./produkt-papers-grid.component.html",
  styleUrls: ["./produkt-papers-grid.component.scss"],
})
export class ProduktPapersGridComponent implements OnInit, AfterViewChecked {
  @ViewChild(DxDataGridComponent) form: DxDataGridComponent;

  @Input() visible: boolean;
  @Input() allProduktPaperDefinition: ProduktPaperDefinition;

  @Output() onSave = new EventEmitter<ProduktPaperDefinition>();

  formatMessage = formatMessage;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    console.log(this.visible);
    this.cdRef.detectChanges();
  }

  save(): void {
    this.onSave.emit(this.allProduktPaperDefinition);
    this.visible = false;
  }

  reset(data) {
    if (this.visible) {
      this.allProduktPaperDefinition.paperDefinitionList.forEach((paper) => {
        paper.addToProduktSpezifikation = false;
      });
      data.addToProduktSpezifikation = true;
    }
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    DxCheckBoxModule,
    DxButtonModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [ProduktPapersGridComponent],
  exports: [ProduktPapersGridComponent],
})
export class ProduktPapersGridModule {}
