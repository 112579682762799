<div class="dx-tag">
  <div
    [id]="identifier"
    class="dx-tag-content"
    [class]="
      isPublisher && !hideColors
        ? 'publisher-union-tag'
        : isStudio && !hideColors
        ? 'studio-union-tag'
        : ''
    "
    style="padding-right: 11px"
    (mouseenter)="popoverVisible = !popoverVisible"
    (mouseleave)="popoverVisible = !popoverVisible"
  >
    {{ organizations.length + " " + label }}
  </div>
</div>

<dx-tooltip
  [(visible)]="popoverVisible"
  [target]="'#' + identifier"
  position="top"
>
  <div *dxTemplate="let data of 'content'" style="padding: 12px">
    <app-organization-tag
      *ngFor="let organization of organizations"
      [organization]="organization"
      [hideColors]="hideColors"
    ></app-organization-tag>
  </div>
</dx-tooltip>
