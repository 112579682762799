import { OrderService } from "../../../services/order.service";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { MomentService } from "../../../services";
import { Observable } from "rxjs";
import { Orderevent } from "../../../model/orderevent";

@Component({
  selector: "app-order-details-logging",
  templateUrl: "./order-details-logging.component.html",
  styleUrls: ["./order-details-logging.component.scss"],
})
export class OrderDetailsLoggingComponent implements OnInit, OnChanges {
  formatMessage = formatMessage;

  @Input() orderId: string;

  orderevents$: Observable<Orderevent[]>;

  constructor(public orderService: OrderService, public ms: MomentService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("orderId" in changes) {
      this.orderId = changes["orderId"].currentValue;
      this.loadData();
    }
  }

  loadData() {
    this.orderevents$ = this.orderService.orderEvents(this.orderId);
  }
}
