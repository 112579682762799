import { Produkt, PreislistenKatalog } from "./produkt";

export class ProduktFormData {
  id = "";
  name = "";
  preislistenKatalog = <PreislistenKatalog>{};
  produktType = "";
  circulationLimit = false;
  width: number;
  height: number;
  edition = "";
  reprint = false;
  twoTitles: boolean = false;

  constructor(produkt?: Produkt) {
    if (produkt) {
      this.name = produkt.name + "";
      this.produktType = produkt.produktType + "";
      this.circulationLimit = produkt.circulationLimit;
      if (produkt.preislistenKatalog)
        this.preislistenKatalog = produkt.preislistenKatalog;
      this.id = produkt.id;
      this.width = produkt.width;
      this.height = produkt.height;
      this.edition = produkt.edition;
      this.reprint = produkt.reprint;
      this.twoTitles = produkt.twoTitles;
    }
  }
}

export class produktNameFormData {
  name: string = " ";
  edition: string = " ";
  produktType: string = " ";
  width: number = 0;
  height: number = 0;
}
