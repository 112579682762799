import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { ScreenService } from "../../services";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
} from "devextreme-angular";

@Component({
  selector: "app-action-popup",
  templateUrl: "./action-popup.component.html",
  styleUrls: ["./action-popup.component.scss"],
})
export class ActionPopupComponent implements OnInit {
  isXSmall: boolean;
  formatMessage = formatMessage;

  @Input() maxWidth: string = "650px";
  @Input() title: string;
  @Input() visible: boolean;
  @Input() closeOnOutsideClick: boolean = true;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onHidden = new EventEmitter<void>();
  @Output() onShowing = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<void>();

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }
}

@NgModule({
  imports: [DxButtonModule, DxScrollViewModule, DxPopupModule],
  declarations: [ActionPopupComponent],
  exports: [ActionPopupComponent],
})
export class ActionPopupModule {}
