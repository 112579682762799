<app-action-popup
  [(visible)]="visible"
  [title]="formatMessage('user.authorizePopup')"
  maxWidth="1000px"
  (onHidden)="resetRoles(); visibleChange.emit(visible)"
  (onSave)="save()"
>
  <dx-data-grid
    [dataSource]="users"
    [columnHidingEnabled]="false"
    [sorting]="{ mode: 'none' }"
    [showRowLines]="false"
    style="margin-bottom: 10px"
  >
    <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>

    <dxi-column
      dataField="loginName"
      [hidingPriority]="loginNameHidingPriority"
      [width]="460"
      [caption]="formatMessage('user.loginName')"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="firstName"
      [hidingPriority]="4"
      [caption]="formatMessage('user.firstName')"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="lastName"
      [hidingPriority]="3"
      [caption]="formatMessage('user.lastName')"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="email"
      [hidingPriority]="emailHidingPriority"
      [width]="isXSmall || isSmall ? 230 : 260"
      [caption]="formatMessage('user.email')"
      [allowEditing]="false"
    ></dxi-column>
    <dxi-column
      dataField="userCreateDate"
      [hidingPriority]="1"
      [caption]="formatMessage('user.createDate')"
      [allowEditing]="false"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>
    <dxi-column
      dataField="userLastModifiedDate"
      [hidingPriority]="2"
      [caption]="formatMessage('user.lastModifiedDate')"
      [allowEditing]="false"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>

    <dxi-column
      dataField="role"
      [hidingPriority]="roleHidingPriority"
      [width]="170"
      [caption]="formatMessage('user.role.assign')"
      [showEditorAlways]="true"
      editCellTemplate="editRoleTemplate"
    >
      <div *dxTemplate="let cellData of 'editRoleTemplate'">
        <dx-select-box
          [(value)]="cellData.value"
          [items]="isEnvironmentUsa ? usaRoles : internalUserRolesDe"
          [displayExpr]="translateRole"
          (onValueChanged)="cellData.setValue($event.value)"
        ></dx-select-box>
      </div>
    </dxi-column>
  </dx-data-grid>
</app-action-popup>
