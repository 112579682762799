export class Bezug {
  produktTeil: string;
  language: string;
  records: BezugRecords[];
}

export class BezugRecords {
  produktTeilKnz: string;
  nutzenBreite: string;
  nutzenHoehe: string;
  rueckenBreite: string;
  deckelBreite: string;
  deckelHoehe: string;
  falzBreite: string;
  einschlag: string;
  bezeichnung: string;
  produktTyp: string;
  endFormat: string;
  umfang: string;
  inhaltsPapier: string;
  bindeArt: string;
  rueckenForm: string;
  pappenDicke: string;
  umfangBildTeil: string;
  papierBildTeil: string;
  deckelKanteA: string;
  deckelKanteOU: string;
  datum: string;
}

export class CalculatedBezugRecord {
  rueckenBreite: number;
  falzBreite: number;
  deckelKanteA: number;
  deckelKanteOU: number;
  deckelBreite: number;
  deckelHoehe: number;
  einschlag: number;
  nutzenBreite: number;
  nutzenHoehe: number;
}
