<tr *ngIf="calculationResponse[0]?.showPaperConsumption">
  <td>{{ formatMessage("calculator.pdf.tabelle.papier.verbrauch") }}</td>
  <td></td>

  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[0].paperConsumption) }}
    </span>
  </td>

  <td *ngIf="calculationResponse[1]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[1].paperConsumption) }}
    </span>
  </td>

  <td *ngIf="calculationResponse[2]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[2].paperConsumption) }}
    </span>
  </td>
  <td></td>
</tr>

<tr *ngIf="calculationResponse[0]?.showPaperHandling">
  <td>{{ formatMessage("calculator.pdf.tabelle.papier.handling") }}</td>
  <td></td>

  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[0].paperHandling) }}
    </span>
  </td>

  <td *ngIf="calculationResponse[1]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[1].paperHandling) }}
    </span>
  </td>

  <td *ngIf="calculationResponse[2]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[2].paperHandling) }}
    </span>
  </td>
  <td></td>
</tr>

<tr *ngIf="calculationResponse[0]?.showPaperPrice">
  <td>{{ formatMessage("calculator.pdf.tabelle.papier.preis") }}</td>
  <td></td>

  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span>
      {{ converter.convertToCurrency(calculationResponse[0].paperPrice) }}
    </span>
  </td>
  <td *ngIf="calculationResponse[1]?.gesamtAuflage"></td>
  <td *ngIf="calculationResponse[2]?.gesamtAuflage"></td>
  <td></td>
</tr>
