<div class="dx-card" id="accordion">
  <dx-accordion
    #accordion
    [dataSource]="sortedDefaultOptions"
    [collapsible]="true"
    [animationDuration]="300"
    [selectedIndex]="-1"
    (onSelectionChanged)="onSelectionChanged($event)"
    [disabled]="selectedProdukt == undefined"
  >
    <div *dxTemplate="let itemData of 'title'">
      <h3>
        <i *ngIf="collapsed; else unfolded" class="dx-icon-plus dx-font-l"></i>
        <ng-template #unfolded>
          <i class="dx-icon-minus dx-font-l"></i>
        </ng-template>
        {{ formatMessage("calculator.calculation.defaultValues") }}
      </h3>
    </div>
    <div *dxTemplate="let itemData of 'item'">
      <ul class="accordion-list">
        <span
          style="display: contents"
          *ngFor="let key of getItemKeys(itemData)"
        >
          <li
            *ngIf="
              key !== '__typename' &&
              itemData[key] != '' &&
              itemData[key].value != ''
            "
          >
            <div class="default-value">
              {{ getKeyByLanguage(key, itemData[key]) }}
            </div>
            <div class="default-value">
              {{ getItemData(key, itemData[key]) }}
            </div>
          </li>
        </span>
      </ul>
    </div>
  </dx-accordion>
</div>
