<!-- english text -->
<ng-template [ngIf]="(locale | async) === 'en-EN'" [ngIfElse]="default">
  <h2 class="content-block">Privacy</h2>
  <h3 class="content-block">
    Privacy Statement Data protection on the GGP Media GmbH website
  </h3>
  <h5 class="content-block">
    GGP Media GmbH welcomes your visit to our website and your interest in our
    company and range of services. Protection of personal data is important to
    us. All the web-based activities of GGP Media GmbH occur in accordance with
    applicable legal provisions on the protection of personal data and data
    security. This data protection information notifies you about how GGP Media
    GmbH handles information collected during your visit to the website of GGP
    Media GmbH.
  </h5>
  <h3 class="content-block">Collection and processing of personal data</h3>
  <h5 class="content-block">
    Personal data refers to all information that identifies you, such as your
    name, your email address, or your mailing address. Such data is stored only
    if you provide it to GGP Media GmbH, for example when you creating an
    account or subscribing to the newsletter.
  </h5>
  <h3 class="content-block">Use and disclosure of personal data</h3>
  <h5 class="content-block">
    DGGP Media GmbH uses your personal data exclusively for purposes of
    technical website administration, to give you access to special information,
    or to otherwise communicate with you. GGP Media GmbH will neither disclose
    your personal data to third parties nor use it in any other way. GGP Media
    GmbH employees are obliged to maintain confidentiality.
  </h5>
  <h3 class="content-block">Freedom of choice</h3>
  <h5 class="content-block">
    You are free to decide what information you provide to GGP Media GmbH. If
    you choose not to disclose any information, please be aware that you may not
    be able to access selected content on the GGP Media GmbH website. Users of
    our online services may ask for deletion of data at any time. If your
    personal details changes (e.g. zip code, email or postal address), you can
    inform GGP Media GmbH about the changes by sending an email to
    <a href="mailto:datenschutz.ggp@bertelsmann.de"
      >datenschutz.ggp@bertelsmann.de</a
    >
    to correct or update your personal data.
  </h5>
  <h3 class="content-block">Automatically Collected Information</h3>
  <h5 class="content-block">
    Whenever you connect to GGP Media GmbH´s website some general information is
    being collected automatically (not via your registration) wich is not
    personal (like internet browser, number of visits, average log-in duration,
    websites browsed). This information will be used for improving the appeal,
    the content and the functionality of the website. Your data will not be used
    for any other purpose or and won't be transmitted to a third party.
  </h5>
  <h3 class="content-block">Cookies/Google Analytics</h3>
  <h5 class="content-block">
    This website uses Google Analytics, a web tracking software from the Google
    Inc. (“Google”). Google Analytics uses cookies. Cookies are text files that
    are temporarily placed on your hard drive to obtain information about usage
    of the website. Furthermore, the information (including your IP-Address),
    created by the cookie, is transfered to and saved on a server from Google in
    the USA. In addition, on this website Google Analytics were extended on the
    "gat._anonymizeIp();" code, wich is provide by Google, to ensure a anonymous
    gathering of IP-Addresses. To make a identification of a user impossible,
    before transmission the last two positions of the IP-Address were cut.
  </h5>
  <h5 class="content-block">
    In authority of the owner of this website, Google will use this information,
    to analyse your usage of the website, to generate reports of the website
    activity for the owner of the website and provide services for them (?) that
    are connected with the usage of the website or the internet. In no case,
    Google will bring your IP-Address in connection or together with other
    stored Google data.
  </h5>
  <h5 class="content-block">
    You can prevent the storage of cookies by making the appropriate adjustments
    to your browser settings. However, we would point out that in this case all
    functions may not be able to fully use.
  </h5>
  <h5 class="content-block">
    Googles survey and usage of you IP-Address can be objected at any time and
    with effect for the future. There is the possibility to istall a
    Browser-Plugin provide by Google. The Plug-In and other further information
    you can find on the Google website.
    <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank"
      >http://tools.google.com/dlpage/gaoptout?hl=en</a
    >.
  </h5>

  <h3 class="content-block">Security</h3>
  <h5 class="content-block">
    GGP Media GmbH takes precautions to protect your personal information
    against loss, destruction, falsification, manipulation and unauthorized
    access. In doing so, the data protection laws of the Federal Republic of
    Germany are of course observed.
  </h5>
  <h3 class="content-block">Minor</h3>
  <h5 class="content-block">
    GGP Media GmbH strongly advises all parents and guardians to instruct their
    children in safe and responsible handling of personal data on the Internet.
    Without the consent of parents or guardians, minors should not submit any
    personal data to the GGP Media GmbH website. GGP Media GmbH will never
    knowingly collect personal data from minors or use it in any way or disclose
    it to third parties without permission.
  </h5>
  <h3 class="content-block">Links to other websites</h3>
  <h5 class="content-block">
    This privacy statement applies to the GGP Media GmbH
    <a href="https://www.ggp-p3.de">www.ggp-p3.de</a> homepage. The Web sites of
    this homepage may contain links to other providers within and outside the
    Bertelsmann Group, to which this privacy protection statement does not
    apply. When you leave the GGP Media GmbH website, it is recommended that you
    carefully read the privacy statements of each website that collects
    personally identifiable information.
  </h5>
  <h3 class="content-block">Right to information</h3>
  <h5 class="content-block">
    If you have any questions concerning the processing of your personal data,
    please contact our data protection coordinator.
  </h5>

  <h5 class="content-block address">
    GGP Media GmbH <br />
    Datenschutzkoordinator<br />
    Karl-Marx-Straße 24<br />
    07381 Pößneck<br />
    Germany
  </h5>
  <h5 class="content-block address">
    <a href="mailto:datenschutz.ggp@bertelsmann.de"
      >datenschutz.ggp@bertelsmann.de</a
    >
  </h5>
  <h5 class="content-block">
    On request, you will be notified in writing in accordance with applicable
    legislation as to whether any of your personal data was stored during your
    visit to the GGP Media GmbH website, and if so what data was stored.
  </h5>
  <h3 class="content-block">Notification of changes</h3>
  <h5 class="content-block">
    Should these data protection guidelines be amended, the changes will be
    announced in this statement, on the homepage, and at other appropriate
    places.
  </h5>
</ng-template>
<!-- german text -->
<ng-template #default>
  <h2 class="content-block">Datenschutzhinweise und Cookies</h2>
  <h5 class="content-block">
    Die Datenschutzhinweise erfüllen die Informationspflichten gemäß den
    Anforderungen aus Art. 12 ff. der EU Datenschutzgrundverordnung (nachfolgend
    „DSGVO“ genannt) und geben Ihnen einen Überblick über die Verarbeitung Ihrer
    personenbezogenen Daten (nachfolgend „Daten“ genannt) auf dieser Webseite.
  </h5>
  <h3 class="content-block">
    1 Wer ist für die Verarbeitung meiner Daten verantwortlich?
  </h3>
  <h5 class="content-block">Die</h5>
  <h5 class="content-block address">
    GGP Media GmbH<br />
    Karl-Marx-Straße 24<br />
    07381 Pößneck
  </h5>
  <h5 class="content-block address">
    Telefon: +49 (0) 3647 430 0<br />
    E-Mail:
    <a href="mailto:ggp.kontakt@bertelsmann.de">ggp.kontakt@bertelsmann.de</a>
  </h5>
  <h5 class="content-block">
    ist für die Verarbeitung Ihrer Daten auf dieser Webseite verantwortlich
    (nachfolgend „Firma“ genannt). Die Firma verarbeitet personenbezogene Daten
    im Einklang mit den Bestimmungen der DSGVO und dem Bundesdatenschutzgesetz
    (nachfolgend „BDSG“ genannt).
  </h5>
  <h5 class="content-block">
    Den Datenschutzkoordinator der Firma erreichen Sie unter der oben genannten
    Postadresse, mit dem Zusatz „Datenschutzkoordinator“ oder unter der
    E-Mail-Adresse: datenschutz.ggp@bertelsmann.de.
  </h5>
  <h3 class="content-block">2 Welche Daten werden erfasst?</h3>
  <h5 class="content-block">
    Wenn Sie die Webseite besuchen werden vom aufrufenden Rechner automatisch
    Informationen erfasst (nachfolgend „Zugriffsdaten“ genannt). Diese
    Zugriffsdaten beinhalten Server-Log-Files, die in der Regel aus
    Informationen über den Browsertyp und die Browserversion, dem
    Betriebssystem, dem Internet-Service-Provider, dem Datum und der Uhrzeit der
    Verwendung der Webseite, die zuvor besuchten Webseiten und über die Webseite
    neu aufgerufene Webseiten und die IP-Adresse des Rechners bestehen. Mit
    Ausnahme der IP-Adresse sind die Server-Log-Files nicht personenbeziehbar.
    Eine IP-Adresse ist personenbeziehbar, wenn sie bei der Nutzung des
    Internetanschlusses dauerhaft vergeben ist und der Internetprovider diese
    einer Person zuordnen kann.
  </h5>
  <h5 class="content-block">
    Einige Funktionen der Website erfordern es, dass Sie der Firma
    personenbezogene Daten mitteilen. In diesem Fall werden die von Ihnen
    mitgeteilten Daten verwendet, um Ihnen die gewünschte Leistung erbringen
    oder das jeweilige Anliegen bearbeiten zu können (z.B. Login-Daten,
    Suchworte, Filterkriterien, Formular- oder Vertragseingaben,
    auftragsbezogene Daten wie Auftragsdaten und Druckdaten, Lieferadressen,
    Klickdaten).
  </h5>
  <h3 class="content-block">3 Welche Cookies werden eingesetzt?</h3>
  <h5 class="content-block">
    Auf der Webseite werden Cookies eingesetzt. Cookies sind kleine Textdateien,
    die beim Besuch einer Webseite auf Ihrem Rechner gespeichert werden. Die
    gespeicherten Cookies sind dem von Ihnen verwendeten Browser zugeordnet.
    Wird die entsprechende Webseite erneut aufgerufen, sendet der Webbrowser den
    Inhalt der Cookies zurück und ermöglicht so eine Wiedererkennung des
    Nutzers. Bestimmte Cookies werden gelöscht, wenn Sie sich ausloggen oder die
    Browser-Sitzung beenden (sogenannte „Transiente Cookies“). Andere Cookies
    werden für eine vorgegebene Zeit oder dauerhaft gespeichert (sogenannte
    „temporäre Cookies“ oder „persistente Cookies“). Die Löschung dieser Cookies
    findet automatisch nach Ablauf der definierten Zeit statt. Auch können Sie
    in den Sicherheitseinstellungen Ihres Browsers die Cookies jederzeit löschen
    sowie den Einsatz von Cookies entsprechend Ihren Wünschen konfigurieren. Die
    Firma weist Sie dabei jedoch darauf hin, dass Sie eventuell nicht alle
    Funktionen der Webseite nutzen können.
  </h5>
  <h5 class="content-block">
    Grundsätzlich handelt es sich bei Cookies nur um eine Online-Kennung ohne
    Personenbezug. Personenbezogen werden die Cookies dann, wenn die durch die
    Cookies erzeugten Informationen zusätzlich mit weiteren Daten
    zusammengeführt werden. Dabei kann zwischen Cookies, die für die
    Bereitstellung der Webseite erforderlich sind, und Cookies, die für weitere
    Zwecke wie etwa Analyse des Nutzerverhaltens oder Werbung erforderlich sind,
    unterschieden werden.
  </h5>
  <h5 class="content-block">
    Zu den Cookies, die für die Bereitstellung der Webseite erforderlich sind,
    zählen insbesondere die Folgenden:
  </h5>
  <ul class="content-block">
    <li>
      Cookies, die der Identifizierung bzw. Authentifizierung der Nutzer dienen
    </li>
    <li>
      Cookies, die bestimmte Nutzereingaben (z. B. Inhalt eines Warenkorbs oder
      eines Online-Formulars) temporär speichern
    </li>
    <li>
      Cookies, die bestimmte Nutzerpräferenzen (z. B. Such-, Sprach oder
      Filtereinstellungen) speichern
    </li>
    <li>
      Cookies, die Daten speichern um die störungsfreie Wiedergabe von Video-
      bzw. Audioinhalten zu gewährleisten
    </li>
  </ul>
  <h5 class="content-block">
    Zu den Cookies, die für weitere Zwecke der Webseite erforderlich sind,
    zählen die Analyse-Cookies, um das Nutzungsverhalten unserer Nutzer
    aufzeichnen und in statistischer Form auswerten zu können (z. B. geklickte
    Werbebanner, besuchte Unterseiten, gestellte Suchanfragen).
  </h5>
  <h3 class="content-block">
    4 Welche Daten werden zu welchen Zwecken erfasst?
  </h3>
  <h5 class="content-block">
    Die Zwecke der Datenverarbeitung können sich aus technischen, vertraglichen
    oder gesetzlichen Erfordernissen sowie ggf. aus einer Einwilligung ergeben.
  </h5>
  <h5 class="content-block">
    Die Firma verwendet die in Ziffer 2 genannten Daten zu folgenden Zwecken:
  </h5>
  <ul class="content-block">
    <li>
      zur Bereitstellung der Webseite und Gewährleistung der technischen
      Sicherheit, insbesondere zur Behebung technischer Fehler und um
      sicherzustellen, dass Unbefugte keinen Zugriff auf die Systeme der
      Webseite erlangen
    </li>
    <li>
      zur Reichweitenmessungen und Webanalysen, um die Webseite für Sie
      effizienter und interessanter zu gestalten sowie Marktforschung zu
      betreiben
    </li>
    <li>
      zur Kommunikation, Vertragsanbahnung, Auftrags- und Versandabwicklung und
      Kundenpflege
    </li>
  </ul>
  <h5 class="content-block">
    Weitere Informationen zu diesen Zwecken der Datenverarbeitung finden Sie in
    den nachfolgenden Abschnitten dieser Datenschutzhinweise.
  </h5>
  <h3 class="content-block">4.1 Technische Bereitstellung der Webseite</h3>
  <h4 class="content-block">
    4.1.1 Beschreibung und Umfang der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Für die Funktionsfähigkeit der Webseite, die Durchführung von
    Sicherheitsanalysen und die Abwehr von Angriffen werden die Server-Log-Files
    als Bestandteil der anfallenden Zugriffsdaten nach Ziffer 2 vom
    Computersystem des aufrufenden Rechners beim Betreten und während der
    Nutzung der Webseite automatisch erfasst und kurzfristig gespeichert. Eine
    Speicherung der Server-Log-Files zusammen mit anderen Daten findet
    grundsätzlich nicht statt. Die Firma verwendet die Server-Log-Files für
    statistische Auswertungen, um technische Störfälle zu analysieren und zu
    beheben, Angriffs- und Betrugsversuche abzuwehren sowie die
    Funktionsfähigkeit der Webseite zu optimieren.
  </h5>
  <h4 class="content-block">
    4.1.2 Zwecke und Rechtsgrundlage der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Die Rechtsgrundlage für die Erfassung der Server-Log-Files ist Art. 6 Abs. 1
    lit. f DSGVO. In der Funktionalität der Webseite, der Durchführung von
    Sicherheitsanalysen und der Abwehr von Gefahren liegen die berechtigten
    Interessen der Firma.
  </h5>
  <h4 class="content-block">
    4.1.3 Dauer der Speicherung oder Kriterien für die Festlegung dieser Dauer
  </h4>
  <h5 class="content-block">
    Nach Aufruf der Webseiten werden die Server-Log-Files auf dem Webserver
    gespeichert. Elektronisch gespeicherte Daten werden spätestens nach 10
    Jahren gelöscht. Eine Auswertung während dieser Speicherdauer erfolgt
    ausschließlich im Fall eines Angriffs oder bei Funktionsstörungen der
    Webseite.
  </h5>
  <h4 class="content-block">
    4.1.4 Widerspruchs- und Beseitigungsmöglichkeiten
  </h4>
  <h5 class="content-block">
    Sie haben das Recht, Widerspruch gegen die Verarbeitung Ihrer Daten im
    Rahmen von Server-Logfiles einzulegen, soweit dafür Gründe vorliegen, die
    sich aus Ihrer besonderen Situation ergeben. Möchten Sie von Ihrem
    Widerspruchsrecht Gebrauch machen, wenden Sie sich bitte an die unter Ziffer
    1 angegebenen Kontaktadresse.
  </h5>
  <h3 class="content-block">4.2 Kontaktformular, E-Mail- und Telefonkontakt</h3>
  <h4 class="content-block">
    4.2.1 Beschreibung und Umfang der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Auf der Webseite besteht die Möglichkeit, die Firma über ein
    Kontaktformular, eine E-Mail-Adresse oder eine Telefonnummer zu
    kontaktieren. Nehmen Sie diese Möglichkeit war, so werden bei dem
    Kontaktformular eingegebenen Daten, Ihre E-Mail-Adresse und/oder Ihre
    Telefonnummer sowie Ihr Anliegen an die Firma übermittelt. Abhängig von dem
    Anliegen (z.B. Fragen zu den Produkten und Dienstleistungen der Firma,
    Geltendmachung Ihrer Betroffenenrechte wie etwa Auskunft) werden Ihre
    Kontaktdaten weiterführend (mit Hilfe von Dienstleistern) verarbeitet.
    Soweit für die Bearbeitung Ihres Anliegens erforderlich, können Ihre
    Kontaktdaten an Dritte (z.B. Partnerunternehmen) weitergeleitet werden.
  </h5>
  <h4 class="content-block">
    4.2.2 Zwecke und Rechtsgrundlage der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Die Rechtsgrundlage für die Verarbeitung Ihrer Kontaktdaten erfolgt auf der
    Grundlage von Art. 6 Abs. 1 lit. f DSGVO. In der Bearbeitung Ihres Anliegens
    und weiteren Kommunikation liegen die berechtigten Interessen. Zielt Ihre
    Kontaktaufnahme auf den Abschluss eines Vertrages mit der Firma ab, so ist
    die Rechtsgrundlage für die Verarbeitung Ihrer Kontaktdaten Art. 6 Abs. 1
    lit. b DSGVO.
  </h5>
  <h4 class="content-block">
    4.2.3 Dauer der Speicherung oder Kriterien zur Festlegung dieser Dauer
  </h4>
  <h5 class="content-block">
    Nach der Bearbeitung Ihres Anliegens und der Beendigung weiterführender
    Kommunikation werden die Kontaktdaten gelöscht. Anderes gilt, wenn Ihre
    Kontaktaufnahme auf den Abschluss eines Vertrages mit der Firma abzielt oder
    Sie Ihre Betroffenenrechte wie etwa Auskunft geltend machen. Hierzu werden
    die Daten solange gespeichert, bis die vertraglichen und/oder gesetzlichen
    Verpflichtungen erfüllt sind und gesetzliche Aufbewahrungsfristen (derzeit 6
    bis10 Jahre) einer Löschung nicht entgegenstehen.
  </h5>
  <h4 class="content-block">
    4.2.4 Widerspruchs- und Beseitigungsmöglichkeiten
  </h4>
  <h5 class="content-block">
    Sie haben das Recht, Widerspruch gegen die Verarbeitung Ihrer Kontaktdaten
    einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen
    Situation ergeben. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen,
    wenden Sie sich bitte an die unter Ziffer 1 angegebenen Kontaktadresse.
    Sofern Sie widersprechen, kann die Kommunikation nicht fortgeführt werden.
    Anderes gilt, wenn die Speicherung Ihrer Kontaktdaten für die
    Vertragsanbahnung oder Erfüllung eines Vertrages oder Geltendmachung Ihrer
    Betroffenenrechte erforderlich ist.
  </h5>
  <h3 class="content-block">4.3 Versand von Mitteilung</h3>
  <h4 class="content-block">
    4.3.1 Beschreibung und Umfang der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Auf der Webseite besteht die Möglichkeit, kostenfrei via E-Mail allgemeine
    Newsletter oder Statusinformationen zu Ihren Aufträgen zu abonnieren. Dabei
    werden Ihre Daten aus der Anmeldemaske an die Firma übermittelt. Mit
    Aktivierung dieser Funktionen über die jeweiligen Eingabemasken auf unserer
    Webseite und den Verweis auf diese Datenschutzhinweise geben Sie die
    Einwilligung für die Verwendung Ihrer Daten für diese Dienste. Eine
    Weitergabe Ihrer Daten an Dritte erfolgt nicht. Pflichtdaten für den Erhalt
    der Mitteilungen ist entweder Ihre E-Mail-Adresse oder Ihre Telefonnummer.
    Auch ist die Verarbeitung Ihrer Zugangsdaten erforderlich, um die Abgabe
    Ihrer Einwilligung nachweisen zu können. Weitere Daten können freiwillig
    angegeben werden, um Sie persönlich ansprechen zu können.
  </h5>
  <h4 class="content-block">
    4.3.2 Zwecke und Rechtsgrundlage der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Die Verarbeitung Ihrer Daten über die Anmeldemaske ist für die Werbung zu
    den Waren und/oder Dienstleistungen der Firma erforderlich. Die
    Rechtsgrundlage ist die Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.
  </h5>
  <h4 class="content-block">
    4.3.3 Dauer der Speicherung oder Kriterien zur Festlegung dieser Dauer
  </h4>
  <h5 class="content-block">
    Die Daten werden während des Abonnements der Mitteilungen gespeichert. Nach
    deren Abbestellung werden die Daten ausschließlich gespeichert, um
    nachzuweisen, dass die Firma für den Versand der Mitteilungen Ihre
    Einwilligung eingeholt hat. Gleiches gilt, sofern Sie Ihre Einwilligung
    widerrufen haben.
  </h5>
  <h4 class="content-block">
    4.3.4 Widerspruchs- und Beseitigungsmöglichkeiten
  </h4>
  <h5 class="content-block">
    Sie können Ihre Einwilligung für den Erhalt von Mitteilungen jederzeit
    widerrufen und der Firma Ihren Widerruf mitteilen, indem Sie die
    entsprechenden Funktionen über die jeweilige Eingabemaske unserer Webseite
    deaktivieren.
  </h5>
  <h3 class="content-block">4.4 Google Tag Manager</h3>
  <h5 class="content-block">
    Die Webseite verwendet für die Funktionsfähigkeit den Google Tag Manager.
    Bereitgestellt wird dieser Dienst von der Google Inc., 1600 Amphitheatre
    Parkway, Mountain View, CA 94043, USA (Google Inc.). Durch den Google Tag
    Manager können die in diese Webseite eingebundenen Webtracking-Dienste
    (siehe Ziffer 4.4) und weitere Dienste durch sogenannte „Tags“ (dies sind
    Platzhalter für einen Website-Code) verwaltet werden. Der Google Tag Manager
    implementiert lediglich diese Tags. Es werden keine Cookies eingesetzt und
    keine personenbezogenen Daten gezielt durch den Google Tag Manager erfasst.
    Der Google Tool Manager löst lediglich andere Tags aus, die wiederum ggf.
    Daten erfassen. Jedoch greift der Google Tag Manager nicht auf diese Daten
    zu.
  </h5>
  <h3 class="content-block">4.5 Google Analytics (Webtracking)</h3>
  <h4 class="content-block">
    4.5.1 Beschreibung und Umfang der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Die Webseite setzt den Dienst Google Analytics ein. Anbieter von Google
    Analytics ist die Google Inc. Durch Google Analytics wird ein pseudonymes
    Nutzungsprofil erstellt, um die Nutzerfreundlichkeit der Webseite zu
    optimieren. Dabei werden Ihre Zugangsdaten nach Ziffer 2 erfasst und
    mithilfe von Analyse-Cookies nach Ziffer 3 wird das Nutzungsverhalten
    ausgewertet. Für das Webtracking ist eine persönliche Identifizierung
    grundsätzlich nicht erforderlich, sodass bei Erfassung Ihrer Zugangsdaten
    die gespeicherte IP-Adresse vor Übermittlung an Google Inc. gekürzt wird und
    ein Rückschluss auf Ihre Person nicht möglich ist. Das pseudonyme
    Nutzungsprofil wird nach Übermittlung zwecks Optimierung der
    Nutzerfreundlichkeit ausgewertet. Eine Zusammenführung mit anderen Daten von
    Google Inc. findet nicht statt. Über einen Vertrag zur Auftragsverarbeitung
    stellt die Firma sicher, dass Google Inc. die Daten nur nach deren Weisung
    verarbeitet.
  </h5>
  <h5 class="content-block">
    Mehr Informationen zur Datenverarbeitung bei Google Analytics finden Sie in
    der Datenschutzerklärung von Google:
    <a href="https://policies.google.com/privacy?hl=de" target="_blank">
      https://policies.google.com/privacy?hl=de</a
    >.
  </h5>
  <h4 class="content-block">
    4.5.2 Beschreibung und Umfang der Datenverarbeitung
  </h4>
  <h5 class="content-block">
    Die Rechtsgrundlage für Erfassung und Auswertung von pseudonymen
    Nutzungsprofile ist Art. 6 Abs. 1 lit. f DSGVO / § 15 Abs. 3
    Telemediengesetz (TMG). In der Optimierung der Nutzerfreundlichkeit der
    Webseite und die Reichweitenmessung liegen die berechtigten Interessen der
    Firma.
  </h5>
  <h4 class="content-block">
    4.5.3 Dauer der Speicherung oder Kriterien zur Festlegung dieser Dauer
  </h4>
  <h5 class="content-block">
    Die Daten, die bei dem Einsatz von Google Analytics erfasst und ausgewertet,
    werden in der Regel solange gespeichert, bis Sie deren Einsatz
    widersprechen. Die Speicherdauer der Analyse-Cookies beträgt maximal 24
    Monate.
  </h5>
  <h4 class="content-block">
    4.5.4 Widerspruchs- und Beseitigungsmöglichkeiten
  </h4>
  <h5 class="content-block">
    Sie können dem Einsatz von Google Analytics wiedersprechen, indem Sie Ihre
    Browsereinstellungen ändern und/oder folgenden Link betätigen, um die
    verfügbaren Browser-Plugins herunterladen und installieren:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
      >https://tools.google.com/dlpage/gaoptout?hl=de</a
    >
  </h5>
  <h3 class="content-block">4.6 Verlinkungen zu anderen Webseiten</h3>
  <h5 class="content-block">
    Diese Webseite enthält Links zu Webseiten anderer Webseitenbetreiber. Mit
    Betätigung der Links gelangen Sie auf die jeweilige Webseite (z.B.
    Tochterfirmen, Facebook, Twitter). Dabei werden keine personenbezogenen
    Daten von Ihnen an die Webseitenbetreiber übermittelt.
  </h5>
  <h5 class="content-block">
    Die Datenschutzhinweise erstrecken sich ausschließlich auf die Webseite der
    Firma, für die Datenschutzhinweise anderer Webseiten ist die Firma nicht
    verantwortlich. Dennoch ist es für bei Besuch der verlinkten Webseiten
    empfehlenswert, auch deren Datenschutzhinweise sorgfältig zu lesen.
  </h5>
  <h3 class="content-block">5 Wer erhält meine Daten?</h3>
  <h5 class="content-block">
    Innerhalb der Firma erhalten diejenigen Stellen Zugriff auf Ihre Daten, die
    diese zur Erfüllung der unter Ziffer 4 dargestellten Zwecke benötigen. Auch
    von der Firma eingesetzte Dienstleister können Zugriff auf Ihre Daten
    erhalten (sogenannte „Auftragsverarbeiter“, z.B. Rechenzentren,
    Webtracking). Über Verträge zur Auftragsverarbeitung sind die
    Weisungsgebundenheit, die Datensicherheit und der vertrauliche Umgang mit
    Ihren Daten durch diese Dienstleister sichergestellt.
  </h5>
  <h5 class="content-block">
    Eine Datenweitergabe an weitere Empfänger wie z.B. Werbepartner, Anbieter
    von Social Media Diensten oder Kreditinstitute (sogenannte „Dritte“) findet
    grundsätzlich nicht statt.
  </h5>
  <h3 class="content-block">
    6 Werden meine Daten außerhalb der EU bzw. des EWR verarbeitet
    (Drittlandtransfer)?
  </h3>
  <h5 class="content-block">
    Durch den Einsatz von Google Analytics nach Ziffer 4.4 findet
    Drittlandtransfer statt, da sich die Rechenzentren von Google Inc. außerhalb
    der Europäischen Union bzw. des Europäischen Wirtschaftsraumes („EU bzw.
    EWR“) befinden. Ein solcher Drittlandtransfer kann dazu führen, dass Ihre
    Daten in ein Land übermittelt werden, welches nicht den gleichen
    Datenschutzstandard wie die EU bzw. der EWR gewährleistet. Über die
    Zertifizierung nach dem EU-US Privacy Shield stellt Google Inc. jedoch
    sicher, dass bei dem Drittlandtransfer das europäischen Datenschutzniveau
    gewährleistet ist. Sie können unter den in Ziffer 1 genannten Kontaktdaten
    eine Kopie dieser Garantien anfordern.
  </h5>
  <h3 class="content-block">7 Welche Datenschutzrechte habe ich?</h3>
  <h5 class="content-block">
    Sie haben jederzeit das Recht auf Auskunft über die bei uns zu Ihrer Person
    gespeicherten persönlichen Daten. Sollten Daten über Ihre Person falsch oder
    nicht mehr aktuell sein, haben Sie das Recht, deren Berichtigung zu
    verlangen. Sie haben außerdem das Recht, die Löschung oder Einschränkung der
    Verarbeitung Ihrer Daten nach Maßgabe von Art. 17 bzw. Art. 18 DSGVO zu
    verlangen. Ihnen kann weiterhin ein Recht auf Herausgabe der von Ihnen
    bereitgestellten Daten in einem gängigen und maschinenlesbaren Format
    zustehen (Recht auf Datenübertragbarkeit).
  </h5>
  <h5 class="content-block">
    Wenn Sie eine Einwilligung zur Verarbeitung personenbezogener Daten für
    bestimmte Zwecke erteilt haben, können Sie die Einwilligung jederzeit mit
    Wirkung für die Zukunft widerrufen. Der Widerruf ist zu richten an die Firma
    unter der nach Ziffer 1 genannten Kontaktadresse.
  </h5>
  <h5 class="content-block">
    Nach Maßgabe des Art. 21 DSGVO haben Sie weiter das Recht, aus Gründen, die
    sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
    Verarbeitung Ihrer Daten, die nach der Rechtsgrundlage des Art. 6 Abs. 1
    lit. f DSGVO erfolgt, Widerspruch einzulegen. Auch haben Sie das Recht
    jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke
    der Direktwerbung Widerspruch einzulegen. Gleiches gilt für die
    automatisierten Verfahren bei Einsatz einzelner Cookies, sofern diese nicht
    für die Bereitstellung der Webseite zwingend erforderlich sind.
  </h5>
  <h5 class="content-block">
    Daneben haben Sie die Möglichkeit, sich an eine Datenschutzbehörde zu wenden
    und dort Beschwerde einzureichen. Die für die Firma zuständige Behörde ist
    die
  </h5>
  <h5 class="content-block address">
    Thüringer Landesbeauftragter für den Datenschutz und die
    Informationsfreiheit<br />
    Postfach 900455<br />
    99107 Erfurt<br />
  </h5>
  <h5 class="content-block address">
    Telefon: 0 361 / 57 311 29 00<br />
    Fax: 0 361 / 57 311 29 04<br />
    E-Mail:
    <a href="mailto:poststelle@datenschutz.thueringen.de"
      >poststelle@datenschutz.thueringen.de</a
    >
  </h5>
  <h5 class="content-block">
    Sie können sich aber auch an die für Ihren Wohnort zuständige
    Datenschutzbehörde wenden.
  </h5>
  <h3 class="content-block">
    8 Inwieweit gibt es eine automatisierte Entscheidungsfindung?
  </h3>
  <h5 class="content-block">
    Zu den unter Ziffer 4 genannten Zwecken nutzen wir keine vollautomatisierten
    Entscheidungsfindungen.
  </h5>
  <h3 class="content-block">9 Findet Profiling statt?</h3>
  <h5 class="content-block">
    Zu den unter Ziffer 4 genannten Zwecken findet kein Profiling statt.
  </h5>
  <h6 class="content-block">Stand der Datenschutzhinweise: August 2018</h6>
</ng-template>
