<tr class="group-name" *ngIf="group.optionGroup && group.optionGroup.groupName">
  <td>{{ group.optionGroup.groupName }}</td>
</tr>
<tr *ngFor="let mehrpreis of group.mehrpreisList; let indexOfElement = index">
  <td class="option-name">{{ mehrpreis.optionName }}</td>
  <td></td>
  <td *ngIf="calculationResponse[0]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA1[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA1[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>
  <td *ngIf="calculationResponse[1]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA2[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA2[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>
  <td *ngIf="calculationResponse[2]?.gesamtAuflage">
    <span *ngIf="mehrpreisListA3[indexOfElement].mehrpreisEx != undefined">
      {{
        converter.convertToCurrency(mehrpreisListA3[indexOfElement].mehrpreisEx)
      }}
    </span>
  </td>
  <td>{{ converter.convertToCurrency(mehrpreis.fortDruckEx) }}</td>
  <td *ngIf="false">
    {{ converter.convertToCurrency(mehrpreis.fixKosten) }}
  </td>
  <td *ngIf="false">
    {{ converter.convertToCurrency(mehrpreis.variableKosten) }}
  </td>
</tr>
