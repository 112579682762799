<div
  (click)="openNewsletter()"
  (mouseenter)="showNewTabIcon = true"
  (mouseleave)="showNewTabIcon = false"
  class="dx-card wide-card news-tile"
>
  <div
    *ngIf="showDeleteIcon"
    class="delete-icon"
    [title]="formatMessage('delete')"
    (click)="$event.stopPropagation(); onDelete.emit(newsId)"
  >
    <i class="dx-icon-trash" style="font-size: 18px"></i>
  </div>

  <i
    *ngIf="showNewTabIcon"
    class="material-icons-outlined mdi-open-in-new top-right-icon"
  ></i>

  <img
    *ngIf="!!previewImage"
    style="object-fit: cover"
    [src]="previewImage"
    alt="News preview image"
    width="100%"
    height="135px"
  />

  <div class="news-tile-body">
    <div class="news-tile-date">
      {{ createdDate }}
    </div>

    <div class="news-tile-title">
      {{ title }}
    </div>

    <div *ngIf="!!description" class="news-tile-subtitle">
      {{ description }}
    </div>
  </div>
</div>
