import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { DxFormComponent, DxSelectBoxComponent } from "devextreme-angular";
import {
  CustomPaper,
  Paperdefinition,
} from "../../../../model/calculation/paperdefinition";
import { EditionRequest, Produkt } from "../../../../model/calculation/produkt";
import { environment } from "../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { CalculatorService } from "../../../../services/calculator.service";
import { CalculationResult } from "../../../../model/calculation/calculationResult";
import { ProduktOptionResult } from "../../../../model/calculation/produktOptionResult";
import { SelectedOption } from "../../../../model/calculation/selectedOption";
import { OwnOption } from "../../../../model/calculation/ownOption";
import { ProduktInformation } from "../../../../model/calculation/produktInformation";
import { CalculatorComponent } from "../../../../../pages/calculator/calculator.component";
import { ScalerService } from "../../../../services/scaler.service";
import { LocaleService } from "../../../../services/locale.service";
import { Subscription, combineLatest } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Calculation, UuiDv4 } from "generated/api/models";
import { CalculationService } from "generated/api/services";
import { OrganizationService } from "../../../../services/organization.service";
import { ProduktSpezifikationService } from "../../../../services/produkt-spezifikation.service";
import { ProduktService } from "src/app/shared/services/produkt.service";
import { ProductVariantService } from "src/app/shared/services/product-variant.service";
import { compareStringsAlphabeticallyIgnoreCase } from "../../../../utils/stringUtils";

export interface FormData {
  author: string;
  edition: EditionRequest[];
  editionVolumes: Number[];
  editionCounter: string;
  format: string;
  breite: number;
  hoehe: number;
  numberOfPages: number;
  orderNumber: string;
  paper: any;
  paperGram: string;
  paperName: string;
  paperPrice: string;
  paperType: PaperType;
  paperVol: string;
  produktArt: Produkt;
  publisherNumber: string;
  publisherNumberInput: string;
  title: string;
  titleNumber: string;
  selectedOptions?: SelectedOption[];
  ownOptions?: OwnOption[];
  language: string;
  isCustomPaper: boolean;
  customPaper: CustomPaper;
}

export interface PaperType {
  name: string;
  stoffklasse: string;
  faerbung: string;
}

@Component({
  selector: "app-calculator-book-details",
  templateUrl: "./calculator-book-details.component.html",
  styleUrls: ["./calculator-book-details.component.scss"],
})
export class CalculatorBookDetailsComponent implements AfterViewInit {
  private MAX_THICKNESS = 60;
  @ViewChild(DxFormComponent) form: DxFormComponent;
  @ViewChild("paperSelectBox") paperSelectBox: DxSelectBoxComponent;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  formatMessage = formatMessage;
  calculationData = <FormData>{};
  calculation: Calculation | undefined;

  isCustomPaperVisible: any = false;

  umfaenge: number[] = [];

  buchFormate: string[] = [];

  minAuflage: number = 0;
  maxAuflage: number = 0;

  papers: Array<Paperdefinition> = [];
  paperTypes: PaperType[] = [];
  papersByPaperType: Array<Paperdefinition> = [];

  currentLocaleSubscription: Subscription;
  currentLocale: string | null;

  @Input()
  produkte: Produkt[];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private calculatorService: CalculatorService,
    private localeService: LocaleService,
    private produktSpezifikationService: ProduktSpezifikationService,
    private calculator: CalculatorComponent,
    private scalerService: ScalerService,
    private calculationService: CalculationService,
    private organizationService: OrganizationService,
    private productService: ProduktService,
    private productVariantService: ProductVariantService
  ) {
    this.postCalculation = this.postCalculation.bind(this);
    this.checkEdition = this.checkEdition.bind(this);
    this.tabOutTextBox = this.tabOutTextBox.bind(this);
    this.validateEdition = this.validateEdition.bind(this);
    this.formatBuchFormate = this.formatBuchFormate.bind(this);

    this.calculatorService.defaultValues = <ProduktInformation>{
      bezug: "",
      bindeArt: "",
      broschurUmschlag: "",
      deckelPappenDicke: "",
      deckenPraegung: "",
      etiketten: "",
      inhalt: "",
      kapitalBand: "",
      leimArt: "",
      lieferung: "",
      rillungBroschurUmschlag: "",
      rueckenForm: "",
      schutzUmschlag: "",
      sonstigesTeil: "",
      sonstigeWV: "",
      verpackung: "",
      vorNachsatz: "",
      sonstiges: "",
    };

    this.calculationData.editionVolumes = new Array<Number>(3);
    this.calculator.produktSelected(undefined);
    this.currentLocaleSubscription = this.localeService
      .getCurrentLocale()
      .subscribe((locale) => (this.currentLocale = locale));
  }

  ngAfterViewInit(): void {
    var calculationId: UuiDv4 = "";
    this.route.paramMap.subscribe((params) => {
      calculationId = params.get("id") || "";
    });
    if (calculationId != "") {
      // load existing calculation
      this.calculationService
        .getCalculation({ id: calculationId })
        .subscribe((calculation) => {
          this.calculation = calculation;
          this.calculatorService
            .getPublisherByIdNoProduct(calculation.publisherId)
            .subscribe((publisher) => {
              if (
                publisher &&
                publisher.preislistenKatalog &&
                publisher.preislistenKatalog.produktList
              ) {
                let produkte = publisher.preislistenKatalog.produktList;
                let produktArray: Produkt[] = [];
                for (const element of produkte) {
                  let produkt = { ...element };
                  produktArray.push(produkt);
                }
                this.produkte = produktArray;
                var product = this.produkte.find(
                  (product) => product.id === calculation.productId
                );
                if (product && calculation.volume) {
                  setTimeout(() => {
                    var produktWithPages = this.productService.getProduktPages(
                      product!!.id
                    );
                    var produktVariants =
                      this.productService.getProduktVariantsForPages(
                        product!!.id,
                        calculation.volume!!
                      );
                    combineLatest([
                      produktWithPages,
                      produktVariants,
                    ]).subscribe(([productPages, importEntries]) => {
                      if (productPages && importEntries) {
                        console.log("calculation", calculation);
                        this.umfaenge = productPages.importEntries.map(
                          (entry) => entry.pages
                        );
                        var idx = this.produkte.findIndex(
                          (p) => p.id == product!!.id
                        );
                        this.produkte[idx] = {
                          ...this.produkte[idx],
                          ...productPages,
                        };

                        var produktVarianten = Array.from(
                          this.productVariantService.getProductVariantsFromImportEntries(
                            importEntries,
                            produkte[idx].circulationLimit
                          )
                        );
                        this.produkte[idx].produktVarianten = produktVarianten;
                        this.calculationData.produktArt = this.produkte[idx];

                        this.calculationData.numberOfPages =
                          calculation.volume!!;

                        this.reloadUmfaenge();
                        this.reloadBuchFormat();

                        this.calculationData.format =
                          calculation.width + "," + calculation.height;
                        this.reloadPapers();

                        if (calculation.paperType) {
                          var pType = <PaperType>{
                            name: calculation.paperType.name,
                            stoffklasse: calculation.paperType.stoffklasse,
                            faerbung: calculation.paperType.faerbung,
                          };
                          this.calculationData.paperType =
                            this.paperTypes.filter(
                              (paperType) =>
                                pType.name === paperType.name &&
                                pType.stoffklasse === paperType.stoffklasse &&
                                pType.faerbung === paperType.faerbung
                            )[0];
                        } // paperType
                        console.log(
                          "paperType",
                          this.calculationData.paperType
                        );
                        if (calculation.paperDefinitionId) {
                          this.reloadPapersByType(
                            this.calculationData.paperType
                          );
                          this.setPaperWeight(calculation.paperDefinitionId!);
                        } else if (
                          calculation.customPaper &&
                          calculation.customPaper.id
                        ) {
                          this.isCustomPaperVisible = true;
                          this.calculationData.paperName =
                            calculation.customPaper.name;
                          this.calculationData.paperGram =
                            "" + calculation.customPaper.density;
                          this.calculationData.paperVol =
                            "" + calculation.customPaper.volume;
                          this.calculationData.paperPrice =
                            "" + calculation.customPaper.price;
                          this.calculationData.customPaper = <CustomPaper>{
                            name: calculation.customPaper.name,
                            grammatur: calculation.customPaper.density,
                            volumen: calculation.customPaper.volume,
                            price: calculation.customPaper.price,
                          };
                        } else {
                          console.log("not setting paperWeight");
                        }
                        this.calculatorService.produktId = product!.id;
                        this.calculatorService
                          .findProduktOptions(product!.id, [])
                          .subscribe(
                            (result) => {
                              console.log("options", result);
                              this.calculatorService.produktOptions = result;
                              this.calculator.priceOptions.priceList.prepareOptions();
                            },
                            () => {
                              this.calculatorService.produktOptions = <
                                ProduktOptionResult
                              >{};
                            }
                          );

                        this.calculationData.selectedOptions =
                          calculation.options?.map(
                            (option) =>
                              <SelectedOption>{
                                id: option.id,
                                userInput: option.userInput,
                              }
                          );
                        this.calculationData.ownOptions =
                          calculation.ownOptions?.map(
                            (ownOption) =>
                              <OwnOption>{
                                optionGroup: ownOption.optionGroup,
                                optionName: ownOption.optionName,
                                optionPosition: ownOption.optionPosition,
                                optionType: ownOption.optionType,
                                sortId: ownOption.sortId,
                                optionPriceInformation:
                                  ownOption.optionPriceInformation,
                              }
                          );
                        if (
                          calculation.pricesChanged ||
                          (calculation.structureChanged &&
                            calculation.paperDefinitionId != null)
                        ) {
                          this.calculationService
                            .saveCalculation({ body: calculation })
                            .subscribe(
                              () => this.postCalculation(),
                              (error) => this.showError(error)
                            );
                        } else if (calculation.paperDefinitionId != null) {
                          this.postCalculation();
                        }
                      } // if(productPages && importEntries)
                      console.log("calculationData", this.calculationData);
                    }); // combineLatest
                  });
                }
              }
            });
        });
    }
  }

  resetValues() {
    this.umfaenge = [];
    this.buchFormate = [];
    this.paperTypes = [];
    this.papersByPaperType = [];
    this.resetOptions();
    this.resetCalculationResponse();
    this.form.instance.resetValues();
  }

  resetFormat() {
    this.buchFormate = [];
    this.paperTypes = [];
    this.papersByPaperType = [];
  }

  resetPapers() {
    this.paperTypes = [];
    this.papersByPaperType = [];
  }

  reloadSelectBoxUmfang() {
    if (
      this.calculationData["produktArt"] != null ||
      this.calculationData["produktArt"] != undefined
    ) {
      this.calculatorService.initializedOptionList = false;

      this.form.instance.beginUpdate();

      this.form.instance.updateData("numberOfPages", "");
      this.form.instance.updateData("format", "");

      this.form.instance.updateData("paperType", "");
      this.form.instance.updateData("paper", "");

      this.reloadUmfaenge();

      this.form.instance.endUpdate();
    }
  }

  reloadSelectBoxBuchFormat() {
    if (this.calculationData["numberOfPages"] > 0) {
      this.calculatorService.initializedOptionList = false;

      this.form.instance.beginUpdate();

      this.form.instance.updateData("format", "");

      this.form.instance.updateData("paperType", "");
      this.form.instance.updateData("paper", "");

      this.reloadBuchFormat();

      this.form.instance.endUpdate();
    }
  }

  reloadSelectBoxPaper() {
    if (this.calculationData["format"]) {
      this.calculatorService.initializedOptionList = false;

      this.form.instance.beginUpdate();

      this.form.instance.updateData("paperType", "");
      this.form.instance.updateData("paper", "");

      this.reloadPapers();

      this.form.instance.endUpdate();
    }
  }

  reloadProduktInformationen() {
    if (
      this.calculationData["produktArt"] != null ||
      this.calculationData["produktArt"] != undefined
    ) {
      this.calculatorService.initializedOptionList = false;

      let produkt = this.calculationData["produktArt"];
      this.form.instance.beginUpdate();

      let language = this.currentLocale?.startsWith("de") ? "de" : "en";

      this.produktSpezifikationService
        .getProduktInformationen(produkt.id, language)
        .subscribe(
          (result) => {
            this.calculatorService.defaultValues = { ...result };
          },
          () => {
            this.calculatorService.defaultValues = <ProduktInformation>{};
          },
          () => {
            this.calculator.produktSelected(produkt);
          }
        );
      this.form.instance.endUpdate();
    }
  }

  reloadOptions() {
    if (
      this.calculationData["produktArt"] != null ||
      this.calculationData["produktArt"] != undefined
    ) {
      this.calculatorService.initializedOptionList = false;

      let produkt = this.calculationData["produktArt"];
      this.form.instance.beginUpdate();

      this.calculatorService.produktId = produkt.id;
      this.calculatorService.findProduktOptions(produkt.id, []).subscribe(
        (result) => {
          this.calculatorService.produktOptions = result;
        },
        () => {
          this.calculatorService.produktOptions = <ProduktOptionResult>{};
        }
      );

      this.form.instance.endUpdate();
    }
  }

  resetOptions() {
    this.calculatorService.selectedOptions = [];
  }

  resetOwnOptions() {
    this.calculatorService.ownOptions = [];
  }

  resetCalculationResponse() {
    this.calculatorService.sendCalculationResponse(null);
  }

  reloadUmfaenge() {
    if (this.calculationData.produktArt) {
      this.umfaenge = [];

      for (const entry of this.calculationData.produktArt.importEntries) {
        if (!this.umfaenge.includes(entry.pages)) {
          this.umfaenge.push(entry.pages);
        }
      }

      this.umfaenge.sort((a, b) => {
        return a - b;
      });
    }
  }

  formatBuchFormate(format) {
    if (format) {
      let formatArray = format.split(",");
      let hoehe = formatArray[0];
      let breite = formatArray[1];

      hoehe = hoehe / 10;
      breite = breite / 10;

      let locale = this.currentLocale?.startsWith("de") ? "de-DE" : "en-EN";

      return `${Number(hoehe).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} x ${Number(breite).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} cm`;
    }
    return "";
  }

  reloadBuchFormat() {
    this.buchFormate = [];

    const allBuchFormate: string[] = [];

    for (const art of this.calculationData["produktArt"].produktVarianten) {
      if (this.calculationData["numberOfPages"] === art.umfang) {
        allBuchFormate.push(`${art.breite},${art.hoehe}`);
      }
    }

    this.buchFormate = allBuchFormate.filter(
      (value, index, categoryArray) => categoryArray.indexOf(value) === index
    );
  }

  reloadPapers() {
    // papers : every paper of a product
    // paperTypes : papers grouped by name
    // papersByPaperType : papers with selected type

    this.paperTypes = [];
    this.papersByPaperType = [];
    this.papers = [];

    if (this.calculationData["produktArt"]?.paperdefinitionSet) {
      var formatArray = this.calculationData.format.split(",");
      var breite = Number(formatArray[0]);
      var hoehe = Number(formatArray[1]);

      for (const art of this.calculationData["produktArt"].produktVarianten) {
        if (
          this.calculationData["numberOfPages"] === art.umfang &&
          hoehe === art.hoehe &&
          breite === art.breite
        ) {
          for (const paper of this.calculationData["produktArt"]
            .paperdefinitionSet) {
            if (
              paper.paperId === art.technischePreisliste.paperId &&
              !this.papers.includes(paper)
            ) {
              this.papers.push(paper);
            }
          }
        }
      }
    }

    if (this.papers) {
      this.papers.forEach((paper) => {
        let paperType: PaperType = {
          name: paper.name,
          faerbung: paper.faerbung,
          stoffklasse: paper.stoffklasse,
        };
        this.paperTypes.push(paperType);
      });

      this.paperTypes.sort((a, b) =>
        compareStringsAlphabeticallyIgnoreCase(
          this.formatPaperTypeValues(a),
          this.formatPaperTypeValues(b)
        )
      );

      // Hilfsfunktion zum Überprüfen von Duplikaten
      function isDuplicate(
        element: PaperType,
        index: number,
        array: PaperType[]
      ) {
        return (
          index !==
          array.findIndex(
            (item) =>
              item.name === element.name &&
              item.faerbung === element.faerbung &&
              item.stoffklasse === element.stoffklasse
          )
        );
      }
      this.paperTypes = this.paperTypes.filter(
        (element, index, array) => !isDuplicate(element, index, array)
      );
    }
  }

  reloadPapersByType(paperType: PaperType) {
    console.log("reloadPapersByType");
    let selectedPaper: PaperType = paperType;
    this.papersByPaperType = [];

    this.papers.forEach((paper) => {
      if (
        paper.name === selectedPaper.name &&
        paper.stoffklasse === selectedPaper.stoffklasse &&
        paper.faerbung === selectedPaper.faerbung
      ) {
        this.papersByPaperType.push(paper);
      }
    });

    this.papersByPaperType.sort((a, b) => {
      console.log("reloadPapersByType exit");
      return a.grammatur - b.grammatur || a.volumen - b.volumen;
    });
    this.form.instance.updateData("paper", "");
  }

  formatPaperValue(paper: Paperdefinition) {
    if (paper) {
      return `${paper.grammatur} g/m² * ${paper.volumen} Vol.`;
    }
    return "";
  }

  formatPaperTypeValues(paperType: PaperType) {
    if (paperType) {
      return `${paperType.name} ${paperType.stoffklasse} ${paperType.faerbung}`;
    }
    return "";
  }

  validateEdition(e) {
    if (e.value > this.maxAuflage && e.value < this.minAuflage) {
      return;
    } else this.tabOutTextBox();
  }

  // TODO: OnValueChanged with Editions
  public tabOutTextBox() {
    if (
      !this.calculationData.produktArt ||
      !this.calculationData.numberOfPages ||
      !this.calculationData.format
    ) {
      this.resetCalculationResponse();
      return;
    }

    if (
      !this.isCustomPaperVisible &&
      (!this.calculationData.paperType || !this.calculationData.paper)
    ) {
      this.resetCalculationResponse();
      return;
    }

    setTimeout(() => this.calculateMinAuflage());
    setTimeout(() => this.calculateMaxAuflage());

    this.postCalculation();
  }

  postCalculation() {
    if (!this.form.instance.validate().isValid) {
      this.resetCalculationResponse();
      return;
    }

    this.calculationData.breite = 0;
    this.calculationData.hoehe = 0;
    var formatArray = this.calculationData.format.split(",");

    this.calculationData.breite = Number(formatArray[0]);
    this.calculationData.hoehe = Number(formatArray[1]);

    this.calculationData.ownOptions = [];

    this.calculationData.ownOptions = this.calculatorService.ownOptions;

    let calculationDataCopy = { ...this.calculationData };
    calculationDataCopy.edition = this.mapEdition();

    if (this.calculatorService.selectedOptions) {
      calculationDataCopy.selectedOptions =
        this.calculatorService.selectedOptions;
    }

    if (this.calculatorService.selectedPublisher) {
      calculationDataCopy.publisherNumber =
        this.calculatorService.selectedPublisher;
    }

    calculationDataCopy.produktArt = <Produkt>{
      id: calculationDataCopy.produktArt.id,
      name: calculationDataCopy.produktArt.name,
    };

    calculationDataCopy.language = this.currentLocale?.startsWith("de")
      ? "de"
      : "en";

    // create paper from custom paper values
    if (this.isCustomPaperVisible) {
      calculationDataCopy.isCustomPaper = true;
      calculationDataCopy.customPaper = <CustomPaper>{
        name: calculationDataCopy.paperName,
        grammatur: Number(calculationDataCopy.paperGram),
        volumen: Number(calculationDataCopy.paperVol),
        price: Number(calculationDataCopy.paperPrice),
      };
      calculationDataCopy.paper = <Paperdefinition>{};
    }

    // Seitenumfang/2 * Grammatur * Volumenfaktor / 1000 =Blockstärke (mm)
    let thickness;
    if (this.isCustomPaperVisible) {
      thickness =
        ((calculationDataCopy.numberOfPages / 2) *
          calculationDataCopy.customPaper.grammatur *
          calculationDataCopy.customPaper.volumen) /
        1000;
    } else {
      thickness =
        ((calculationDataCopy.numberOfPages / 2) *
          calculationDataCopy.paper.grammatur *
          calculationDataCopy.paper.volumen) /
        1000;
    }
    if (this.MAX_THICKNESS < thickness) {
      this.showError(formatMessage("calculator.book.thickness"));
      this.resetCalculationResponse();
      return;
    }

    console.log(calculationDataCopy);

    this.http
      .post<CalculationResult>(
        `${environment.baseUrl}/api/calculator/submitCalculationData`,
        calculationDataCopy
      )
      .subscribe((data) => {
        this.calculatorService.sendCalculationResponse(data);
      });
  }

  showError(error: string): void {
    this.toastMessage = error;
    this.toastType = "error";
    this.showToast = true;
  }

  postPDFDownload() {
    if (!this.form.instance.validate().isValid) {
      return;
    }

    this.calculationData.breite = 0;
    this.calculationData.hoehe = 0;
    var formatArray = this.calculationData.format.split(",");

    this.calculationData.breite = Number(formatArray[0]);
    this.calculationData.hoehe = Number(formatArray[1]);

    this.calculationData.ownOptions = [];

    this.calculationData.ownOptions = this.calculatorService.ownOptions;

    let calculationDataCopy = { ...this.calculationData };
    calculationDataCopy.edition = this.mapEdition();

    if (this.calculatorService.selectedOptions) {
      calculationDataCopy.selectedOptions =
        this.calculatorService.selectedOptions;
    }

    if (this.calculatorService.selectedPublisher) {
      calculationDataCopy.publisherNumber =
        this.calculatorService.selectedPublisher;
    }

    calculationDataCopy.produktArt = <Produkt>{
      id: calculationDataCopy.produktArt.id,
      name: calculationDataCopy.produktArt.name,
    };

    calculationDataCopy.language = this.currentLocale?.startsWith("de")
      ? "de"
      : "en";

    // create paper from custom paper values
    if (this.isCustomPaperVisible) {
      calculationDataCopy.isCustomPaper = true;
      calculationDataCopy.customPaper = <CustomPaper>{
        name: calculationDataCopy.paperName,
        grammatur: Number(calculationDataCopy.paperGram),
        volumen: Number(calculationDataCopy.paperVol),
        price: Number(calculationDataCopy.paperPrice),
      };
      calculationDataCopy.paper = <Paperdefinition>{};
    }

    console.log(calculationDataCopy);

    this.http
      .post<string>(
        `${environment.baseUrl}/api/calculator/generateCalculationPDF`,
        calculationDataCopy
      )
      .subscribe((data) => {
        let json = JSON.stringify(data);
        this.scalerService.downloadCalculatorPdf("calculation", json);
      });
  }

  mapEdition(): EditionRequest[] {
    let editions: EditionRequest[] = [];
    let umfang = this.calculationData.numberOfPages;
    let produkt = this.calculationData.produktArt;

    if (umfang && produkt && this.calculationData["editionVolumes"]) {
      Object.keys(this.calculationData.editionVolumes).forEach((e) => {
        let mapped = false;
        let auflage = this.calculationData.editionVolumes[e];

        for (let i = 0; i < produkt.produktVarianten.length; i++) {
          let aktuelleVariante = produkt.produktVarianten[i];
          if (this.isCustomPaperVisible) {
            if (
              aktuelleVariante.umfang == umfang &&
              aktuelleVariante.minAuflage <= auflage &&
              aktuelleVariante.maxAuflage >= auflage &&
              aktuelleVariante.breite == this.calculationData.breite &&
              aktuelleVariante.hoehe == this.calculationData.hoehe
            ) {
              editions.push({
                auflage: auflage,
                produktVariantenId: aktuelleVariante.id,
                valid: true,
                produktId: produkt.id,
              });
              mapped = true;
              return;
            }
          } else {
            if (
              aktuelleVariante.umfang == umfang &&
              aktuelleVariante.minAuflage <= auflage &&
              aktuelleVariante.maxAuflage >= auflage &&
              aktuelleVariante.breite == this.calculationData.breite &&
              aktuelleVariante.hoehe == this.calculationData.hoehe &&
              aktuelleVariante.technischePreisliste.paperId ==
                this.calculationData.paper.paperId
            ) {
              editions.push({
                auflage: auflage,
                produktVariantenId: aktuelleVariante.id,
                valid: true,
                produktId: produkt.id,
              });
              mapped = true;
            }
          }
        }
        // create dummy edition
        // TODO: produktVariante not found error handling
        if (!mapped) {
          editions.push({
            auflage: 0,
            produktVariantenId: "0",
            valid: false,
            produktId: "0",
          });
        }
      });
    }
    return editions;
  }

  selectUmfang(event: any) {
    console.log("selecUmfang", event);
    if (event.selectedItem && !this.calculation) {
      this.productService
        .getProduktVariantsForPages(
          this.calculationData.produktArt.id,
          event.selectedItem
        )
        .subscribe((importEntries) => {
          if (importEntries) {
            var produktVarianten = Array.from(
              this.productVariantService.getProductVariantsFromImportEntries(
                importEntries,
                this.calculationData.produktArt.circulationLimit
              )
            );
            var idx = this.produkte.findIndex(
              (product) => product.id == this.calculationData.produktArt.id
            );
            this.produkte[idx].produktVarianten = produktVarianten;
            console.log("merged", this.produkte[idx]);
            setTimeout(() => {
              this.calculationData.produktArt = this.produkte[idx];
              this.reloadSelectBoxBuchFormat();
              this.resetPapers();
            });
          }
        });
    } else {
      console.log("ignoring selectUmfang");
    }
    console.log("selecUmfang exit", event);
  }

  selectBuchFormat() {
    console.log("selectBuchFormat enter");
    if (!this.calculation) {
      this.reloadSelectBoxPaper();
    } else {
      console.log("ignoring");
    }
  }

  selectProdukt(event: any) {
    console.log("selectProdukt enter", event);
    if (event.selectedItem && event.selectedItem.importEntries == undefined) {
      // load produkt
      this.productService
        .getProduktPages(event.selectedItem.id)
        .subscribe((produkt) => {
          if (produkt) {
            this.umfaenge = produkt.importEntries.map((entry) => entry.pages);
            var idx = this.produkte.findIndex(
              (product) => product.id == event.selectedItem.id
            );
            this.produkte[idx] = { ...this.produkte[idx], ...produkt };
            console.log("merged", this.produkte[idx]);
            this.calculationData.produktArt = this.produkte[idx];
          }
        });
    } else if (event.selectedItem && !this.calculation) {
      this.resetOwnOptions();
      this.reloadSelectBoxUmfang();
      this.reloadOptions();
      this.reloadProduktInformationen();
      this.tabOutTextBox();
      this.resetOptions();
      this.resetFormat();
      this.resetPapers();
      this.resetCalculationResponse();
    } else {
      console.log("ignoring");
    }

    let language = this.currentLocale?.startsWith("de") ? "de" : "en";
    if (event.selectedItem) {
      this.produktSpezifikationService
        .getProduktInformationen(event.selectedItem.id, language)
        .subscribe(
          (result) => {
            this.calculatorService.defaultValues = { ...result };
          },
          () => {
            this.calculatorService.defaultValues = <ProduktInformation>{};
          },
          () => {
            this.calculator.produktSelected(event.selectedItem);
          }
        );
    }
  }

  selectPaper(event: any) {
    console.log("selectPaper enter", event);
    console.log("selectPaper calculation", this.calculation);
    if (event.value && !this.calculation) {
      this.reloadPapersByType(event.value);
    } else {
      console.log("ignoring");
      this.calculation = undefined;
    }
    console.log("selectPaper exit");
  }

  setNumberOfPages(productVariantId: string) {
    var productVariant = this.calculationData.produktArt.produktVarianten.find(
      (x) => x.id === productVariantId
    );
    if (productVariant) {
      this.calculationData.numberOfPages = productVariant.umfang;
    }
  }

  setPaperWeight(paperDefinitionId: string) {
    console.log("setPaperWeight enter", this.papers);
    var paperDefinition = this.papers.find((x) => x.id === paperDefinitionId);
    if (paperDefinition) {
      this.calculationData.paper = paperDefinition;
    } else {
      console.log("no paperWeight");
    }
  }

  checkEdition(e) {
    if (e.value) {
      if (this.maxAuflage == 0 && this.minAuflage == 0) {
        return true;
      }
      return e.value <= this.maxAuflage && e.value >= this.minAuflage;
    } else {
      return true;
    }
  }

  calculateMaxAuflage() {
    let produkt = this.calculationData["produktArt"];

    if (produkt && produkt.produktVarianten && this.calculationData.format) {
      var formatArray = this.calculationData.format.split(",");
      var breite = Number(formatArray[0]);
      var hoehe = Number(formatArray[1]);

      this.maxAuflage = 0;

      produkt.produktVarianten.forEach((produktVariante) => {
        if (this.isCustomPaperVisible) {
          if (
            breite === produktVariante.breite &&
            hoehe === produktVariante.hoehe &&
            this.calculationData.numberOfPages === produktVariante.umfang &&
            produktVariante.maxAuflage > this.maxAuflage
          ) {
            this.maxAuflage = produktVariante.maxAuflage;
            return;
          }
        } else {
          if (
            breite === produktVariante.breite &&
            hoehe === produktVariante.hoehe &&
            this.calculationData.numberOfPages === produktVariante.umfang &&
            produktVariante.maxAuflage > this.maxAuflage &&
            produktVariante.technischePreisliste.paperId ==
              this.calculationData.paper.paperId
          ) {
            this.maxAuflage = produktVariante.maxAuflage;
          }
        }
      });
    } else {
      this.maxAuflage = 999999999;
    }
  }

  calculateMinAuflage() {
    let produkt = this.calculationData["produktArt"];

    if (produkt && produkt.produktVarianten && this.calculationData.format) {
      var formatArray = this.calculationData.format.split(",");
      var breite = Number(formatArray[0]);
      var hoehe = Number(formatArray[1]);

      this.minAuflage = 999999999;

      produkt.produktVarianten.forEach((produktVariante) => {
        if (this.isCustomPaperVisible) {
          if (
            breite === produktVariante.breite &&
            hoehe === produktVariante.hoehe &&
            this.calculationData.numberOfPages === produktVariante.umfang &&
            produktVariante.minAuflage < this.minAuflage
          ) {
            this.minAuflage = produktVariante.minAuflage;
            return;
          }
        } else {
          if (
            breite === produktVariante.breite &&
            hoehe === produktVariante.hoehe &&
            this.calculationData.numberOfPages === produktVariante.umfang &&
            produktVariante.minAuflage < this.minAuflage &&
            produktVariante.technischePreisliste.paperId ==
              this.calculationData.paper.paperId
          ) {
            this.minAuflage = produktVariante.minAuflage;
          }
        }
      });
    } else {
      this.minAuflage = 0;
    }
  }

  checkCustomPaper(e) {
    this.form.instance.beginUpdate();

    setTimeout(() => {
      //checked
      if (e.value) {
        this.form.instance.updateData("paperType", "");
        this.form.instance.updateData("paper", "");
      }
      // unchecked
      else {
        this.form.instance.updateData("paperName", "");
        this.form.instance.updateData("paperGram", "");
        this.form.instance.updateData("paperVol", "");
        this.form.instance.updateData("paperPrice", "");
      }
      this.form.instance.endUpdate();
    }, 1);
  }
}
