<dx-button
  *ngIf="isAllowedToChangeOrdersStudiosAssignments && ordersLoaded"
  [text]="formatMessage('order.studios.edit')"
  type="default"
  stylingMode="contained"
  (onClick)="openOrderStudioPopup()"
  style="margin-bottom: 10px"
></dx-button>

<div class="grid-shadow">
  <dx-data-grid
    id="order-grid"
    class="dx-card wide-card"
    [dataSource]="dataSourceDs"
    [showBorders]="false"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [allowColumnResizing]="false"
    [allowColumnReordering]="true"
    (onExporting)="exportGrid($event)"
    (onRowDblClick)="navigateToOrderDetailsOnRowDoubleClick($event)"
    (onContentReady)="contentReady()"
  >
    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
    ></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="p3-orders"
      [savingTimeout]="stateStoreDelay"
    ></dxo-state-storing>

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="allowedPageSizes"
      displayMode="compact"
    ></dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-column-chooser
      [enabled]="true"
      mode="select"
      [allowSearch]="true"
      [height]="520"
    ></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column
      dataField="order.titleinfo.title"
      [caption]="formatMessage('order.titleinfo.title')"
      cell
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="order.titleinfo.isbn"
      [caption]="formatMessage('order.titleinfo.isbn')"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="order.titleinfo.author"
      [caption]="formatMessage('order.titleinfo.author')"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="sender.publisher"
      [caption]="formatMessage('sender.publisher')"
    ></dxi-column>

    <dxi-column
      dataField="sender.contact"
      [caption]="formatMessage('sender.contact')"
    ></dxi-column>

    <dxi-column
      dataField="receiver.contact"
      [caption]="formatMessage('receiver.contact')"
    ></dxi-column>

    <dxi-column
      dataField="order.filedeliverydate"
      [caption]="formatMessage('order.filedeliverydate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="deliveryDateWithHighestQuantity"
      [caption]="formatMessage('order.arrival')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="status.status"
      [caption]="formatMessage('order.status.status')"
      [allowHeaderFiltering]="true"
      [allowSearch]="true"
      [calculateDisplayValue]="translateStatus"
      [calculateCellValue]="translateStatus"
    >
    </dxi-column>

    <dxi-column
      dataField="order.idtitle"
      [caption]="formatMessage('order.idtitle')"
    ></dxi-column>

    <dxi-column
      dataField="order.ordernumberdiso"
      [caption]="formatMessage('order.ordernumberdiso')"
      [allowHeaderFiltering]="false"
      [calculateCellValue]="orderNumberDisoDisplay"
    ></dxi-column>

    <dxi-column
      dataField="order.edition.number"
      [caption]="formatMessage('order.edition.number')"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="jobtype"
      [caption]="formatMessage('order.jobtype')"
      [allowHeaderFiltering]="true"
    ></dxi-column>

    <dxi-column
      dataField="publisher.standardStudios"
      [caption]="formatMessage('order.publisher.standardStudios')"
      [calculateDisplayValue]="calculateStudiosOfPublisherCellValue"
      [calculateCellValue]="calculateStudiosOfPublisherCellValue"
      [calculateFilterExpression]="filterStudios"
      [headerFilter]="{
        dataSource: headerFilterStudiosOfPublisher
      }"
      cellTemplate="publisherStandardStudios"
    >
      <div *dxTemplate="let d of 'publisherStandardStudios'">
        <ng-container
          *ngIf="
            d.data.publisher?.standardStudios?.length > 1;
            else standardStudioTags
          "
        >
          <app-organization-union-tag
            [identifier]="'tag' + d.rowIndex + d.columnIndex"
            [organizations]="asStudios(d.data.publisher?.standardStudios)"
            [hideColors]="true"
            [label]="formatMessage('order.studios.unionTag')"
          ></app-organization-union-tag>
        </ng-container>
        <ng-template #standardStudioTags>
          <app-organization-tag
            *ngFor="let studio of d.data.publisher?.standardStudios"
            [organization]="asStudio(studio)"
            [hideColors]="true"
          ></app-organization-tag>
        </ng-template>
      </div>
    </dxi-column>

    <dxi-column
      dataField="studios"
      [caption]="formatMessage('order.studios')"
      [calculateDisplayValue]="calculateStudiosCellValue"
      [calculateCellValue]="calculateStudiosCellValue"
      [calculateFilterExpression]="filterStudios"
      [headerFilter]="{
        dataSource: headerFilterStudios
      }"
      cellTemplate="orderStudios"
    >
      <div *dxTemplate="let d of 'orderStudios'">
        <ng-container *ngIf="d.data.studios.length > 1; else studioTags">
          <app-organization-union-tag
            [identifier]="'tag' + d.rowIndex + d.columnIndex"
            [organizations]="asStudios(d.data.studios)"
            [hideColors]="true"
            [label]="formatMessage('order.studios.unionTag')"
          ></app-organization-union-tag>
        </ng-container>
        <ng-template #studioTags>
          <app-organization-tag
            *ngFor="let studio of d.data.studios"
            [organization]="asStudio(studio)"
            [hideColors]="true"
          ></app-organization-tag>
        </ng-template>
      </div>
    </dxi-column>

    <dxi-column
      dataField="order.titleinfo.category"
      [caption]="formatMessage('productType')"
      [calculateCellValue]="translateProductType"
    ></dxi-column>

    <dxi-column
      dataField="order.orderdate"
      [caption]="formatMessage('order.orderdate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="status.lasteventdate"
      [caption]="formatMessage('order.status.lasteventdate')"
      [allowHeaderFiltering]="false"
      [format]="formatMessage('dateformat')"
      dataType="date"
    ></dxi-column>

    <dxi-column
      dataField="order.customernumber"
      [caption]="formatMessage('order.customernumber')"
    ></dxi-column>

    <dxi-column
      dataField="sender.email"
      [caption]="formatMessage('sender.email')"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      dataField="receiver.email"
      [caption]="formatMessage('receiver.email')"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      fixedPosition="right"
      [allowHeaderFiltering]="false"
      [caption]="formatMessage('other.actions')"
      [showInColumnChooser]="false"
      type="buttons"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
      <div *dxTemplate="let data of 'actionsTemplate'">
        <dx-button
          class="action-button"
          icon="upload"
          [hint]="formatMessage('upload.title')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="navigateToUpload(data.data)"
          [visible]="isValidCategory(data.data)"
        ></dx-button>

        <dx-button
          class="action-button"
          icon="material-icons-outlined mdi-show-details"
          [hint]="formatMessage('order.navigate.details')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="navigateToOrderDetails(data.data)"
        >
          <i class="dx-icon material-icons-outlined mdi-show-details"></i>
        </dx-button>

        <dx-button
          *ngIf="isAllowedToChangeOrdersStudiosAssignments"
          [id]="'btn' + data.rowIndex"
          class="action-button"
          icon="checklist"
          [hint]="formatMessage('order.studios.edit')"
          stylingMode="text"
          type="default"
          (onClick)="prepareStudiosPopover(data)"
        ></dx-button>
      </div>
    </dxi-column>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button
            icon="deletetable"
            [hint]="formatMessage('order.grid.reset.localstorage.hint')"
            (onClick)="showConfirmDeletePopup()"
          >
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <dx-button
          [hint]="formatMessage('help.toolbar')"
          type="normal"
          icon="help"
          (onClick)="showHelp()"
        >
        </dx-button>
      </dxi-item>
      <dxi-item location="after" name="exportButton"></dxi-item>
      <dxi-item location="after" name="columnChooserButton"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
  <div [hidden]="!pagerText" class="pager-info">
    {{ pagerText }}
  </div>
</div>
<dx-popup
  [hideOnOutsideClick]="true"
  [dragEnabled]="false"
  [(visible)]="helpPopupVisible"
  [width]="400"
  [height]="170"
  contentTemplate="helpContent"
  titleTemplate="title"
>
  <dxi-toolbar-item location="before">
    <div *dxTemplate="let data of 'title'">
      <span class="popup-toolbar-headline">{{
        formatMessage("help.toolbar")
      }}</span>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item location="after">
    <i class="dx-icon-tips popup-toolbar-icon"></i>
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'helpContent'">
    <span style="font-weight: bold">{{ formatMessage("help.headline") }}</span>
    <p>
      {{ formatMessage("help.content.first") }}
    </p>
    <p>
      {{ formatMessage("help.content.second") }}
    </p>
  </div>
</dx-popup>

<app-order-studio-popup
  *ngIf="isAllowedToChangeOrdersStudiosAssignments && ordersLoaded"
  [(visible)]="orderStudioPopupVisible"
  [orders]="allOrders"
  [preFilter]="orderStudioPreFilter"
  [studios]="allStudios"
  (save)="updateOrdersStudiosAssignmentsFromPopup($event)"
></app-order-studio-popup>

<dx-popover
  [target]="popoverTarget"
  [visible]="studiosPopoverVisible"
  (onHidden)="studiosPopoverVisible = false"
>
  <dxo-position my="top right" at="bottom right" [of]="popoverTarget">
  </dxo-position>

  <div *dxTemplate="let data of 'content'" style="padding-top: 16px">
    <div class="d-flex" style="max-width: 200px">
      <dx-list
        id="studioList"
        [dataSource]="studiosForPopover"
        displayExpr="displayName"
        keyExpr="id"
        selectionMode="all"
        height="257px"
        [showSelectionControls]="true"
        [selectedItemKeys]="preSelectedStudiosIds"
        [searchEnabled]="true"
        searchExpr="displayName"
      ></dx-list>
    </div>

    <div
      class="d-flex"
      style="
        justify-content: space-between;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      "
    >
      <dx-button
        [text]="formatMessage('cancel')"
        stylingMode="text"
        (onClick)="studiosPopoverVisible = false"
      ></dx-button>

      <dx-button
        [text]="formatMessage('save')"
        type="default"
        stylingMode="text"
        (onClick)="assignStudiosToSelectedOrder()"
      ></dx-button>
    </div>
  </div>
</dx-popover>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  icon="dx-icon-warning"
  iconColor="warning"
  [title]="formatMessage('order.grid.reset.localstorage.popup.title')"
  [message]="formatMessage('order.grid.reset.localstorage.popup.message')"
  [confirmButtonText]="
    formatMessage('order.grid.reset.localstorage.popup.confirmButtonText')
  "
  (onConfirm)="resetLocalStorage()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
