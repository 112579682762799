import { Option } from "./option";

export class Edge {
  edgeType: string;
  nodeList: Option[];

  constructor(edgeType: string, nodeList: Option[]) {
    this.edgeType = edgeType;
    this.nodeList = nodeList;
  }

  toJSON(): any {
    return {
      edgeType: this.edgeType,
      nodeList: this.nodeList.map((option) => option.id),
    };
  }
}
