import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { DxPopoverModule, DxTooltipModule } from "devextreme-angular";
import {
  isPublisher,
  isStudio,
  Organization,
} from "../../../model/organization/organization";
import { CommonModule } from "@angular/common";
import { OrganizationTagModule } from "../organization-tag/organization-tag.component";

@Component({
  selector: "app-organization-union-tag",
  templateUrl: "./organization-union-tag.component.html",
  styleUrls: ["./organization-union-tag.component.scss"],
})
export class OrganizationUnionTagComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() organizations: Organization[];
  @Input() identifier: string;
  @Input() label: string;
  @Input() hideColors: boolean = false;

  popoverVisible: boolean = false;

  isPublisher: boolean;
  isStudio: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isPublisher = isPublisher(this.organizations[0]);
    this.isStudio = isStudio(this.organizations[0]);
  }
}

@NgModule({
  imports: [
    DxPopoverModule,
    CommonModule,
    DxTooltipModule,
    OrganizationTagModule,
  ],
  declarations: [OrganizationUnionTagComponent],
  exports: [OrganizationUnionTagComponent],
})
export class OrganizationUnionTagModule {}
