import { Component, Input, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { DocumentInfo } from "../../../model/order/document-info";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-order-details-documents",
  templateUrl: "./order-details-documents.component.html",
  styleUrls: ["./order-details-documents.component.scss"],
})
export class OrderDetailsDocumentsComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() orderId: string;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  invoiceCount: any;
  confirmationCount: any;
  deliveryNoteCount: any;

  invoiceDocuments: DocumentInfo[] = [];
  confirmationDocuments: DocumentInfo[] = [];
  deliveryNoteDocuments: DocumentInfo[] = [];

  loading = true;
  loadingVisible = false;
  isAllowedToViewD3Documents: boolean;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getCount();
  }

  getCount() {
    this.http
      .get(
        environment.baseUrl + "/api/d3/documentCount?orderId=" + this.orderId,
        { responseType: "json" }
      )
      .subscribe(
        (response: any) => {
          this.confirmationCount = 0;
          this.deliveryNoteCount = 0;
          this.invoiceCount = 0;

          // Arrays leeren
          this.invoiceDocuments = [];
          this.confirmationDocuments = [];
          this.deliveryNoteDocuments = [];

          response.forEach((d3DocumentInfoGroup: any) => {
            switch (d3DocumentInfoGroup.fileCategory) {
              case "DAB":
                this.confirmationCount =
                  d3DocumentInfoGroup.documentInfos.length;
                this.confirmationDocuments = d3DocumentInfoGroup.documentInfos;
                break;
              case "DLIES":
                this.deliveryNoteCount =
                  d3DocumentInfoGroup.documentInfos.length;
                this.deliveryNoteDocuments = d3DocumentInfoGroup.documentInfos;
                break;
              case "DFAKI":
                this.invoiceCount = d3DocumentInfoGroup.documentInfos.length;
                this.invoiceDocuments = d3DocumentInfoGroup.documentInfos;
                break;
            }
          });

          this.loading = false;
        },
        (error) => {
          console.error(error);
        },
        () => {
          console.log("getCount complete");
        }
      );
  }

  download(type: string, number: string) {
    this.loadingVisible = true;

    let documentInfo: DocumentInfo;
    let index = parseInt(number);
    switch (type) {
      case "DFAKI":
        documentInfo = this.invoiceDocuments[index];
        break;
      case "DAB":
        documentInfo = this.confirmationDocuments[index];
        break;
      case "DLIES":
        documentInfo = this.deliveryNoteDocuments[index];
        break;
      default:
        this.showError(
          formatMessage(
            "order.details.documents.d3.error.fileCategory.unknown"
          ) +
            " " +
            type
        );
        return;
    }

    this.http
      .get(
        environment.baseUrl +
          "/api/d3/document?documentId=" +
          documentInfo.documentId +
          "&orderId=" +
          this.orderId +
          "&fileName=" +
          documentInfo.fileName,
        {
          responseType: "blob",
        }
      )
      .subscribe(
        (response: any) => {
          let dataType = response.type;
          let binaryData = [] as any;
          binaryData.push(response);

          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          downloadLink.setAttribute("target", "_blank");
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          this.loadingVisible = false;
        },
        (error) => {
          this.loadingVisible = false;
          this.showError(
            formatMessage("order.document.notAvailable") +
            " " +
            formatMessage("order." + type)
          );
        }
      );
  }

  showError(error: string) {
    this.toastMessage = error;
    this.toastType = "error";
    this.showToast = true;
  }
}
