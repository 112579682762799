<ng-container *ngIf="orderevents$ | async as orderevents; else loading">
  <div *ngIf="orderevents.length; else noEvents" class="detail-entry">
    <div *ngFor="let orderevent of orderevents; let i = index">
      <div class="d-flex order-info">
        <span class="order-info-text">{{ i + 1 }}. {{ orderevent.type }}</span>
        <div>
          {{ ms.moment(orderevent.timestamp).fromNow() }} ({{
            ms.moment(orderevent.timestamp).calendar()
          }})
        </div>
      </div>

      <dx-text-area
        [autoResizeEnabled]="true"
        value="{{ orderevent.payload | json }}"
        [inputAttr]="{ class: 'textarea' }"
      >
      </dx-text-area>
    </div>
  </div>
</ng-container>
<ng-template #noEvents>
  <div class="detail-no-data-text">
    {{ formatMessage("order.details.event.notfound") }}
  </div>
</ng-template>
<ng-template #loading>
  <div class="details-loading-text">{{ formatMessage("loading") }}</div>
</ng-template>
