<ng-container *ngIf="order$ | async as order">
  <div class="d-flex flex-column flex-shrink" style="padding: 20px 0">
    <div
      class="upload-title"
      *ngIf="statusNotUploadAllowed; else readyForPrinting"
    >
      <app-return-button returnPath="/auftraege"> </app-return-button>
      {{ formatMessage("upload.statusNotReadyForUpload.title") }}
    </div>
    <ng-template #readyForPrinting>
      <div class="upload-title" *ngIf="orderReadyForPrinting">
        <app-return-button returnPath="/auftraege"> </app-return-button>
        {{ formatMessage("upload.readyForPrinting.title") }}
      </div>
      <div class="upload-title" *ngIf="!orderReadyForPrinting">
        <app-return-button returnPath="/auftraege"> </app-return-button>
        {{ formatMessage("upload.notReadyForPrinting.title") }}
      </div>
    </ng-template>

    <app-alert
      *ngIf="statusNotUploadAllowed; else readyForPrintingAlert"
      class="d-flex"
      icon="dx-icon-warning"
      [message]="
        formatMessage(
          'upload.statusNotReadyForUpload.subtitle',
          translateStatus(order.status.status)
        )
      "
      type="error"
      style="margin-top: 10px"
    ></app-alert>

    <ng-template #readyForPrintingAlert>
      <app-alert
        class="d-flex"
        [message]="
          orderReadyForPrinting
            ? formatMessage('upload.readyForPrinting.subtitle')
            : formatMessage('upload.notReadyForPrinting.subtitle')
        "
        [type]="orderReadyForPrinting ? 'success' : 'warning'"
        style="margin-top: 10px"
      ></app-alert>
    </ng-template>
  </div>

  <div class="d-flex flex-column flex-grow">
    <div
      class="d-flex"
      [class]="isXSmall ? 'flex-column' : ''"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.1); padding-bottom: 12px"
    >
      <div class="text">
        <div class="font-size-18">
          <span
            *ngIf="order.order.titleinfo.title"
            [title]="formatMessage('upload.order.title')"
            style="cursor: default"
          >
            {{ order.order.titleinfo.title }}
          </span>
          <span
            *ngIf="order.order.titleinfo.author"
            [title]="formatMessage('upload.order.author')"
            style="cursor: default"
          >
            {{ " - " + order.order.titleinfo.author }}
          </span>
          <span *ngIf="order.order.titleinfo.isbn" style="cursor: default">
            {{
              " - " +
                formatMessage("upload.order.isbn") +
                " " +
                order.order.titleinfo.isbn
            }}
          </span>
        </div>
        <div>
          <span *ngIf="order.order.idtitle" style="cursor: default">
            {{
              formatMessage("order.idtitle") +
                ": " +
                order.order.idtitle +
                " - "
            }}
          </span>
          <span *ngIf="order.order.edition.number" style="cursor: default">
            {{
              formatMessage("order.edition.number") +
                ": " +
                order.order.edition.number
            }}
          </span>
        </div>
        <div>
          <span [title]="formatMessage('productType')" style="cursor: default">
            {{ formatMessage("productType." + productType) }}
          </span>
          <span *ngIf="order.order.ordernumberdiso" style="cursor: default">
            {{
              " - " +
                formatMessage("order.ordernumberdiso") +
                " GGP: " +
                orderNumberDisoDisplay(order)
            }}
          </span>
        </div>
      </div>

      <div class="flex-grow d-flex flex-wrap" style="justify-content: end">
        <dx-button
          [text]="formatMessage('order.details.title')"
          type="default"
          stylingMode="contained"
          style="margin-left: 15px; align-self: end"
          (click)="navigateToDetailFromUpload()"
        ></dx-button>

        <div
          class="flex-grow d-flex"
          style="justify-content: end; align-items: end"
        >
          <a
            [href]="getGuidelinesLink()"
            target="_blank"
            class="d-flex info-text align-items-center"
          >
            <span>{{ formatMessage("upload.info") }}</span>
          </a>
        </div>
      </div>
    </div>

    <div
      *ngIf="productType === 'Unknown'; else uploadArea"
      class="d-flex justify-content-center flex-column align-items-center"
      style="height: 300px"
    >
      <span *ngIf="productType === 'Unknown'" class="text text-center">
        {{ formatMessage("upload.notPossible") }}
      </span>
      <span *ngIf="statusNotUploadAllowed" class="text text-center">
        {{
          formatMessage("upload.statusNotReadyForUpload.subtitle") +
            " " +
            order.status.status
        }}
      </span>

      <dx-button
        [text]="formatMessage('upload.backToOrders')"
        type="default"
        (onClick)="navigateToOrders()"
        style="margin-top: 12px"
        [style]="isXSmall ? 'width: 100%' : ''"
      ></dx-button>
    </div>

    <ng-template #uploadArea>
      <div
        id="container"
        class="d-flex flex-grow flex-column"
        style="margin-top: 20px"
      >
        <div
          *ngIf="!statusNotUploadAllowed"
          id="top"
          class="d-flex flex-grow"
          [class]="isSmallOrDown ? 'flex-column' : ''"
          style="margin-bottom: 30px"
        >
          <div
            id="upload-card-container"
            class="d-flex flex-column"
            [style]="
              isSmallOrDown
                ? ''
                : isAllowedToGenerateUploadLinks
                ? 'width: 75%'
                : 'width: 100%'
            "
          >
            <div
              class="d-flex align-items-center card-title"
              style="margin-bottom: 14px"
            >
              <i class="dx-icon-upload dx-font-l" style="margin-right: 3px"></i>
              <span>{{ formatMessage("upload.area") }}</span>
            </div>

            <div
              id="upload-card"
              class="d-flex flex-grow dx-card wide-card"
              [class]="isLargeOrUp ? '' : 'flex-column'"
              style="padding: 16px 20px; border-radius: 8px"
            >
              <div
                [class]="isLargeOrUp ? 'd-flex flex-column' : ''"
                [style]="isLargeOrUp ? 'padding-right: 24px; width: 30%' : ''"
              >
                <dx-select-box
                  width="100%"
                  [dataSource]="orderPieces"
                  [displayExpr]="translateOrderPiece"
                  [searchEnabled]="true"
                  [disabled]="selectBoxDisabled"
                  searchMode="contains"
                  [searchExpr]="translateOrderPiece"
                  [showClearButton]="true"
                  [placeholder]="formatMessage('upload.orderPieces.choose')"
                  [(selectedItem)]="selectedOrderPiece"
                  stylingMode="outlined"
                  style="margin-bottom: 12px"
                ></dx-select-box>

                <div
                  *ngIf="isLargeOrUp"
                  class="
                    d-flex
                    flex-grow
                    align-items-center
                    justify-content-center
                  "
                >
                  <img
                    *ngIf="productType"
                    [src]="bookGraphic"
                    alt="Book Components"
                    [style]="isXLarge ? 'margin: 0 50px' : 'margin: 0 10px'"
                    class="product-img"
                  />
                </div>
              </div>

              <app-file-uploader
                [orderId]="orderId"
                [orderPiece]="selectedOrderPiece"
                (uploading)="disableSelectBox($event)"
                (uploadSuccessful)="handleSuccessfulUpload($event)"
                (uploadError)="handleUploadError($event)"
                (fileAlreadyExists)="handleFileAlreadyExists($event)"
                class="d-flex flex-grow"
                [style]="isLargeOrUp ? 'width: 70%' : ''"
              ></app-file-uploader>
            </div>
          </div>

          <div
            id="upload-link-card-container"
            *ngIf="isAllowedToGenerateUploadLinks"
            class="d-flex flex-column"
            [style]="
              isSmallOrDown
                ? 'margin-top: 30px'
                : 'margin-left: 30px; width: 25%'
            "
          >
            <div
              class="d-flex align-items-center card-title"
              style="margin-bottom: 14px"
            >
              <i class="dx-icon-link dx-font-l" style="margin-right: 3px"></i>
              <span>
                {{ formatMessage("upload.link.table") }}
              </span>
            </div>

            <div
              id="upload-link-card"
              class="d-flex flex-grow flex-column dx-card wide-card"
              style="padding: 12px 16px; border-radius: 8px"
            >
              <div
                id="upload-link-grid"
                class="
                  d-flex
                  flex-grow flex-column
                  justify-content-center
                  text-center
                "
              >
                <app-upload-link-data-grid
                  *ngIf="
                    activeUploadLinks.length > 0;
                    else noActiveUploadLinksText
                  "
                  [uploadLinks]="activeUploadLinks"
                  [orderTitle]="order.order.titleinfo.title"
                  [orderIsbn]="order.order.titleinfo.isbn"
                  [height]="isXLarge ? '30vh' : '15vh'"
                  (onLinkCopied)="showLinkCopiedToast($event)"
                ></app-upload-link-data-grid>

                <ng-template #noActiveUploadLinksText>
                  <div
                    class="
                      d-flex
                      flex-grow flex-column
                      justify-content-center
                      text-center
                    "
                    [style]="isXLarge ? 'height: 30vh' : 'height: 15vh'"
                  >
                    <span class="no-data-text">
                      {{ formatMessage("upload.link.noData") }}
                    </span>
                  </div>
                </ng-template>

                <div
                  class="
                    d-flex
                    flex-column flex-grow
                    align-items-center
                    justify-content-center
                  "
                >
                  <dx-select-box
                    [dataSource]="orderPieces"
                    [displayExpr]="translateOrderPiece"
                    stylingMode="outlined"
                    [(value)]="orderPieceForUploadLink"
                    style="width: 75%"
                  ></dx-select-box>

                  <dx-button
                    [text]="formatMessage('upload.link.generate')"
                    type="default"
                    (onClick)="generateUploadLink()"
                    style="margin-top: 16px"
                    [disabled]="!orderPieceForUploadLink"
                  ></dx-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bottom" class="d-flex flex-column">
          <div
            class="d-flex align-items-center card-title"
            style="margin-bottom: 14px"
          >
            <i class="dx-icon-doc dx-font-l" style="margin-right: 3px"></i>
            <span>{{ formatMessage("upload.table") }}</span>
          </div>
          <div
            id="uploaded-files-card"
            class="d-flex flex-column dx-card wide-card"
            style="padding: 12px 16px; border-radius: 8px"
          >
            <app-upload-data-grid
              *ngIf="uploadedFiles.length > 0; else noUploadedFilesText"
              [height]="isXLarge ? '20vh' : '15vh'"
              [uploads]="uploadedFiles"
              [isOrderReadyForPrinting]="orderReadyForPrinting"
              [isAllowedToDownloadUploads]="isAllowedToDownloadUploads"
              [isAllowedToReactivateUpload]="isAllowedToReactivateUpload"
              (onDeactivateUpload)="deactivateUpload($event)"
            ></app-upload-data-grid>
            <ng-template #noUploadedFilesText>
              <div
                class="
                  d-flex
                  flex-grow flex-column
                  justify-content-center
                  text-center
                "
                [style]="isXLarge ? 'height: 20vh' : 'height: 15vh'"
              >
                <span class="no-data-text">
                  {{ formatMessage("upload.table.noData") }}
                </span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
