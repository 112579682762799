import { Component, Input, NgModule, OnInit } from "@angular/core";
import { DxButtonModule, DxLoadIndicatorModule } from "devextreme-angular";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-home-card",
  templateUrl: "./home-card.component.html",
  styleUrls: ["./home-card.component.scss"],
})
export class HomeCardComponent implements OnInit {
  @Input() icon: string;
  @Input() title: any;
  @Input() quantity: number | undefined;
  @Input() selected: boolean = false;
  @Input() loading: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [DxButtonModule, DxLoadIndicatorModule, CommonModule],
  declarations: [HomeCardComponent],
  exports: [HomeCardComponent],
})
export class HomeCardModule {}
