import { Injectable, NgZone } from "@angular/core";
import { P3AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  private timeout = 60000 * 60 * 2; //2hours
  private logoutTimer: any;
  private localStorageName = "last-activity";

  constructor(private authService: P3AuthService) {
    this.setupTimers();
  }

  // Function to reset the timers
  private resetTimer() {
    this.clearTimers();
    this.startTimers();
    this.recordLastActivity();
  }

  // Set up event listeners for user activity
  private setupTimers() {
    document.addEventListener("mousemove", () => this.resetTimer());
    document.addEventListener("mousedown", () => this.resetTimer());
    document.addEventListener("keypress", () => this.resetTimer());
    document.addEventListener("touchmove", () => this.resetTimer());
    document.addEventListener("scroll", () => this.resetTimer());
    this.startTimers();
  }

  private startTimers() {
    this.logoutTimer = setTimeout(() => {
      this.logOut();
    }, this.timeout);
  }

  private clearTimers() {
    if (this.logoutTimer) clearTimeout(this.logoutTimer);
  }

  private recordLastActivity() {
    window.localStorage.setItem(this.localStorageName, new Date().toString());
  }

  public checkLogout() {
    const lastActivityDateString = window.localStorage.getItem(
      this.localStorageName
    );
    const lastActivityDate = lastActivityDateString
      ? new Date(Date.parse(lastActivityDateString))
      : new Date(0);
    const currentDate = new Date();
    const diffMs = currentDate.getTime() - lastActivityDate.getTime();
    const diffMins = Math.floor(diffMs / (1000 * 60));
    console.log("diffMins: " + diffMins);
    if (diffMins >= 120) {
      this.logOut();
    }
  }

  logOut() {
    this.authService.logOut();
  }
}
