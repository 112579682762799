<h3>
  <i class="dx-icon-bookmark dx-font-l"></i>
  {{ formatMessage("printing.template.headline") }}
</h3>
<div class="dx-card">
  <dx-form
    #form
    id="form"
    labelMode="floating"
    [(formData)]="printingTemplateData"
    colCount="1"
    [colCountByScreen]="{ xs: 1 }"
    validationGroup="newPrintingTemplateFormValidation"
  >
    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item
        dataField="titel"
        [label]="{ text: formatMessage('printing.template.titel') }"
        [editorOptions]="{ stylingMode: 'underlined', maxLength: 100 }"
        [colSpan]="6"
      >
      </dxi-item>

      <dxi-item
        #produktTyp
        name="produkttyp"
        dataField="produkttyp"
        [colSpan]="6"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: produktTypenDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.produkttyp') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #breite
        name="breite"
        [colSpan]="2"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
      >
        <div *dxTemplate="let data">
          <dx-text-box
            id="widthTextBox"
            style="display: inline-block; width: 100%"
            class="template-text-box"
            [label]="formatMessage('printing.template.breite') + ' *'"
            placeholder="{{
              formatMessage('printing.template.placeholder.valueInMM')
            }}"
            [(value)]="data.component.option('formData')[data.name]"
            (onValueChanged)="calculateDefaultValueKlappenbreite($event)"
            maxLength="3"
          ></dx-text-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,3}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="hoehe"
        [label]="{ text: formatMessage('printing.template.hoehe') }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 3,
          placeholder: formatMessage('printing.template.placeholder.valueInMM')
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,3}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="umfang"
        [label]="{
          text: formatMessage('printing.template.umfang')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 4,
          placeholder: formatMessage('printing.template.placeholder.pages')
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,4}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          [validationCallback]="isNumberEven"
          [message]="formatMessage('validation.evenNumber')"
        >
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #inhaltPapier
        name="inhaltPapier"
        dataField="inhaltPapier"
        [colSpan]="2"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: inhaltPapierDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.inhaltPapier') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="inhaltPapierGrammatur"
        [label]="{
          text: formatMessage('printing.template.inhaltPapierGrammatur')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 3,
          placeholder: formatMessage('printing.template.placeholder.grammage')
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,3}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="inhaltPapierVolumen"
        [label]="{
          text: formatMessage('printing.template.inhaltPapierVolumen')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          placeholder: formatMessage('printing.template.placeholder.volume')
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^\d{1,1}([\.,]\d{1,2})?$"
          [message]="
            formatMessage('validation.2decimalplacesAndNumberLowerThanTen')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #bildteilUmfang
        name="bildteilUmfang"
        dataField="bildteilUmfang"
        [colSpan]="2"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: bildteilUmfangDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.bildteilUmfang') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #bildteilMaterial
        name="bildteilMaterial"
        dataField="bildteilMaterial"
        [colSpan]="2"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: bildteilMaterialDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.bildteilMaterial') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #Farbschnitt
        name="farbschnitt"
        dataField="farbschnitt"
        [colSpan]="2"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: farbschnittDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.farbschnitt') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #broschurumschlagMaterial
        name="broschurumschlagMaterial"
        dataField="broschurumschlagMaterial"
        [colSpan]="getColSpanBroschurumschlagMaterial()"
        *ngIf="isBroschurUmschlagMaterialVisible()"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: broschurumschlagMaterialDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{
            formatMessage('printing.template.broschurumschlagMaterial')
          }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="pappenstaerke"
        [label]="{
          text: formatMessage('printing.template.pappenstaerke')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          placeholder: formatMessage('printing.template.placeholder.valueInMM')
        }"
        [colSpan]="2"
        *ngIf="isPappenstaerkeVisible()"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^\d{1,1}([\.,]\d{1,2})?$"
          [message]="
            formatMessage('validation.2decimalplacesAndNumberLowerThanTen')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="deckelkanteAussen"
        [label]="{
          text: formatMessage('printing.template.deckelkanteAussen')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          placeholder: formatMessage(
            'printing.template.placeholder.deckelkanteAussen'
          )
        }"
        [colSpan]="2"
        *ngIf="isDeckelkanteAussenVisible()"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^\d{1,1}([\.,]\d{1,1})?$"
          [message]="
            formatMessage('validation.1decimalplacesAndNumberLowerThanTen')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="deckelkanteObenUnten"
        [label]="{
          text: formatMessage('printing.template.deckelkanteObenUnten')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          placeholder: formatMessage(
            'printing.template.placeholder.deckelkanteObenUnten'
          )
        }"
        [colSpan]="2"
        *ngIf="isDeckelkanteObenUntenVisible()"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^\d{1,1}([\.,]\d{1,1})?$"
          [message]="
            formatMessage('validation.1decimalplacesAndNumberLowerThanTen')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="klappenbreiteBu"
        [label]="{
          text: formatMessage('printing.template.klappenbreiteBU')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          maxLength: 3,
          placeholder: formatMessage('printing.template.placeholder.valueInMM')
        }"
        [colSpan]="2"
        *ngIf="isKlappenbreiteBuVisible()"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,3}$"
          [message]="formatMessage('validation.integerOnly')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          [validationCallback]="isNumberBiggerThanBreite"
          [message]="
            formatMessage(
              'printing.template.validation.error.NumberBiggerThanBreite',
              this.printingTemplateData.breite
            )
          "
        >
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #klappen
        name="klappen"
        dataField="klappen"
        [colSpan]="2"
        *ngIf="isKlappenVisible()"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: klappenDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.klappen') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #bindeArt
        name="bindeart"
        dataField="bindeart"
        [colSpan]="getColSpanBindeart()"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: bindeartDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.bindeart') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        #rueckenform
        name="rueckenform"
        dataField="rueckenform"
        [colSpan]="getColSpanRueckenform()"
        *ngIf="isRueckenformVisible()"
        [editorOptions]="{
          stylingMode: 'underlined',
          dataSource: rueckenformDatasource,
          valueExpr: 'value',
          displayExpr: 'name'
        }"
        editorType="dxSelectBox"
      >
        <dxo-label
          text="{{ formatMessage('printing.template.rueckenform') }}"
        ></dxo-label>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="klappenbreiteSu"
        [label]="{
          text: formatMessage('printing.template.klappenbreiteSU')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          placeholder: formatMessage('printing.template.placeholder.valueInMM')
        }"
        [colSpan]="2"
        *ngIf="isKlappenbreiteSuVisible()"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          pattern="^[0-9]{1,3}$"
          [message]="formatMessage('validation.numericOnly')"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          [validationCallback]="isNumberBiggerThanBreite"
          [message]="
            formatMessage(
              'printing.template.validation.error.NumberBiggerThanBreite',
              this.printingTemplateData.breite
            )
          "
        >
        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dx-form>
  <dx-button
    style="margin-top: 50px"
    text="{{ formatMessage('printing.template.button.create') }}"
    (onClick)="generatePrintingTemplate()"
  >
  </dx-button>
</div>
