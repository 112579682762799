<div
  *ngIf="!loading; else loadingTemplate"
  class="d-flex flex-grow flex-column content-block"
  style="padding-top: 20px"
>
  <div class="d-flex flex-shrink">
    <span style="font-size: 30px; font-weight: 300">{{ greeting }}</span>
  </div>

  <div class="d-flex home-container">
    <div class="d-flex flex-column flex-grow">
      <div
        *ngIf="isAdmin; else home"
        class="dx-card responsive-paddings"
        style="border-radius: 6px; margin-top: 40px"
      >
        {{ info }}
      </div>

      <ng-template #home>
        <div
          class="d-flex"
          [class]="isXSmall ? 'flex-column' : ''"
          style="
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 5px 0 10px 0;
            align-items: end;
          "
        >
          <span class="home-text" [style]="isSmall ? 'margin-right: 12px' : ''">
            {{
              formatMessage(
                homeTextKey,
                formatMessage("timeWindow.lower." + this.selectedTimeWindow)
              )
            }}
          </span>
          <div *ngIf="!(isSmall || isXSmall)" class="d-flex flex-grow"></div>
          <dx-select-box
            class="d-flex blue-select"
            [items]="timeWindows"
            [(value)]="selectedTimeWindow"
            [displayExpr]="translateTimeWindow"
            (onValueChanged)="handleTimeWindowChange()"
            [style]="isSmall ? 'width: 35%' : 'width: 110px'"
          ></dx-select-box>
        </div>

        <div
          class="d-flex"
          style="flex-wrap: wrap; gap: 20px; margin-top: 20px"
        >
          <app-home-card
            *ngIf="!isSalesManager"
            class="d-flex yellow-icon"
            [title]="formatMessage('home.fileDeliveryDates')"
            icon="event"
            [loading]="loadingByTimeWindowChange"
            [selected]="selectedItem === homeItems.FILE_DELIVERY_DATE"
            [quantity]="ordersByFileDeliveryDate?.length"
            [style]="isXSmall || isSmall ? 'width: 100%' : ''"
            (click)="selectedItem = homeItems.FILE_DELIVERY_DATE"
          ></app-home-card>
          <app-home-card
            *ngIf="!isSalesManager"
            class="d-flex red-icon"
            [title]="formatMessage('home.preflights')"
            icon="warning"
            [loading]="loadingByTimeWindowChange"
            [selected]="selectedItem === homeItems.PREFLIGHTS"
            [quantity]="ordersByFailedPreflightsWithDuplicates?.length"
            [style]="isXSmall || isSmall ? 'width: 100%' : ''"
            (click)="selectedItem = homeItems.PREFLIGHTS"
          ></app-home-card>
          <app-home-card
            *ngIf="!isSalesManager"
            class="d-flex blue-icon"
            [title]="formatMessage('home.uploads')"
            icon="file"
            [loading]="loadingByTimeWindowChange"
            [selected]="selectedItem === homeItems.UPLOADS"
            [quantity]="lastUploads?.length"
            [style]="isXSmall || isSmall ? 'width: 100%' : ''"
            (click)="selectedItem = homeItems.UPLOADS"
          ></app-home-card>
          <app-home-card
            *ngIf="showCalculations"
            class="d-flex teal-icon"
            [title]="formatMessage('home.calculations')"
            icon="money"
            [loading]="loadingByTimeWindowChange"
            [selected]="selectedItem === homeItems.CALCULATIONS"
            [quantity]="calculations?.length"
            [style]="isXSmall || isSmall || isSalesManager ? 'width: 100%' : ''"
            (click)="selectedItem = homeItems.CALCULATIONS"
          ></app-home-card>
        </div>

        <div
          class="dx-card wide-card"
          style="margin-top: 20px; padding: 12px; border-radius: 6px"
        >
          <div
            *ngIf="
              loadingByTimeWindowChange || showNoDataText() || showErrorText();
              else dataGrids
            "
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              text-center
            "
            style="height: 220px"
          >
            <dx-load-indicator
              [visible]="loadingByTimeWindowChange"
            ></dx-load-indicator>
            <span
              *ngIf="!loadingByTimeWindowChange && showNoDataText()"
              class="no-data-text"
            >
              {{ formatMessage("home.noData." + this.selectedItem) }}
            </span>
            <span
              *ngIf="!loadingByTimeWindowChange && showErrorText()"
              class="no-data-text"
            >
              {{ formatMessage("home.noData.error") }}
            </span>
          </div>

          <ng-template #dataGrids>
            <app-simple-order-grid
              *ngIf="selectedItem === homeItems.FILE_DELIVERY_DATE"
              [orders]="ordersByFileDeliveryDate"
              [isExternalUser]="isExternalUser"
              [showOrderNumberDiso]="showOrderNumberDiso"
              [showReceiverContact]="showReceiverContact"
            ></app-simple-order-grid>

            <app-simple-order-grid
              *ngIf="selectedItem === homeItems.PREFLIGHTS"
              [orders]="ordersByFailedPreflights"
              [isExternalUser]="isExternalUser"
              [showOrderNumberDiso]="showOrderNumberDiso"
              [showReceiverContact]="showReceiverContact"
              [showNumberOfPreflights]="true"
              [ordersByFailedPreflightsWithDuplicates]="
                ordersByFailedPreflightsWithDuplicates
              "
            ></app-simple-order-grid>

            <app-upload-grid-with-orders
              *ngIf="selectedItem === homeItems.UPLOADS"
              [uploads]="lastUploads"
              [isExternalUser]="isExternalUser"
              [showOrderNumberDiso]="showOrderNumberDiso"
              [showReceiverContact]="showReceiverContact"
            ></app-upload-grid-with-orders>

            <app-calculation-grid
              *ngIf="selectedItem === homeItems.CALCULATIONS"
              [calculations]="calculations"
            ></app-calculation-grid>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <div class="d-flex flex-column flex-shrink news-container">
      <div
        class="d-flex"
        style="
          min-height: 47px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 5px 0 10px 0;
          align-items: end;
          margin-bottom: 20px;
        "
      >
        <span class="home-text" [style]="isSmall ? 'margin-right: 12px' : ''">
          {{ formatMessage("news") }}
        </span>
        <div
          *ngIf="isAllowedToCreateNewsElement && !(isSmall || isXSmall)"
          class="d-flex flex-grow"
        ></div>
        <dx-button
          *ngIf="isAllowedToCreateNewsElement"
          [hint]="formatMessage('news.add')"
          icon="plus"
          type="default"
          stylingMode="contained"
          style="border-radius: 31px; width: 31px; height: 31px"
          (onClick)="popupVisible = true"
        ></dx-button>
      </div>

      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          text-center
          no-news-container
        "
        *ngIf="lastNewsletterIds.length === 0; else tiles"
      >
        <span class="no-news-text">{{ formatMessage("news.none") }}</span>
      </div>
      <ng-template #tiles>
        <div class="d-flex news-tile-container">
          <app-news-element-tile
            *ngFor="let newsletterId of lastNewsletterIds"
            [newsId]="newsletterId"
            [showDeleteIcon]="isAllowedToCreateNewsElement"
            (onDelete)="handleOnDelete($event)"
          ></app-news-element-tile>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div
    class="
      d-flex
      flex-grow flex-column
      content-block
      align-items-center
      justify-content-center
    "
  >
    <dx-load-indicator [visible]="loading"></dx-load-indicator>
  </div>
</ng-template>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>

<app-news-element-popup
  *ngIf="popupVisible"
  [(visible)]="popupVisible"
  (onSave)="handleNewsElementOnSave()"
  (onError)="handleNewsElementOnError()"
></app-news-element-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="formatMessage('news.delete.title')"
  [message]="formatMessage('news.delete.message')"
  [confirmButtonText]="formatMessage('delete')"
  icon="dx-icon-warning"
  iconColor="warning"
  (onConfirm)="executeDeleteOperation()"
></app-confirmation-dialog>
