import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToastModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  OptionFormComponent,
  OptionFormModule,
} from "../option-form/option-form.component";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import {
  PreislistenKatalog,
  Produkt,
} from "../../../model/calculation/produkt";
import { OptionFormData } from "../../../model/calculation/optionFormData";
import { Option } from "../../../model/calculation/option";
import {
  OptionUnit,
  OptionUnitAdmin,
} from "../../../model/calculation/optionUnit";

@Component({
  selector: "app-option-form-popup",
  templateUrl: "./option-form-popup.component.html",
  styleUrls: ["./option-form-popup.component.scss"],
})
export class OptionFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(OptionFormComponent) optionForm: OptionFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  @Input() visible: boolean;
  @Input() option: OptionFormData;
  @Input() options: Option[];
  @Input() produkte: Produkt[];
  @Input() optionUnitsAdmin: OptionUnitAdmin[];
  @Input() preisListe: PreislistenKatalog;
  @Input() dependentOptions: Option[];

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<OptionFormData>();

  title: string;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = formatMessage("option.create");
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetOptionForm(): void {
    this.optionForm.resetValues();
  }

  save(): void {
    if (!this.optionForm.isValid()) {
      return;
    }

    // check if option already exists
    // REVOKED WITH BPGP3M-718
    /*
    let alreadyExists = false;

    this.options.forEach((p) => {
      if (p.optionName == this.option.optionName && p.id != this.option.id) {
        this.toastType = "error";
        this.toastMessage = formatMessage("option.create.alreadyExists");
        this.showToast = true;
        alreadyExists = true;
        return;
      }
    });
   */

    //if (!alreadyExists) {

    //To prevent options from having unwanted properties
    if (
      this.option.optionType !== "STAFFELMEHRPREIS" &&
      this.option.optionType !== "STUECKMEHRPREIS"
    ) {
      delete this.option.optionUnitId;
    }
    this.onSave.emit(this.option);

    //}

    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    OptionFormModule,
    ActionPopupModule,
    DxToastModule,
  ],
  declarations: [OptionFormPopupComponent],
  exports: [OptionFormPopupComponent],
})
export class OptionFormPopupModule {}
