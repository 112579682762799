<div
  class="alert"
  [class]="
    isSuccess()
      ? 'success-alert'
      : isError()
      ? 'error-alert'
      : isWarning()
      ? 'warning-alert'
      : ''
  "
>
  <div class="d-flex align-items-center">
    <i
      *ngIf="showIcon"
      [class]="icon"
      [style]="multiLine ? 'margin-right: 12px' : 'margin-right: 5px'"
    ></i>
    <span>
      {{ message }}
    </span>
    <div [style]="multiLine ? 'margin-left: 12px' : 'margin-left: 5px'">
      <ng-content></ng-content>
    </div>
  </div>
</div>
