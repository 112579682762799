<dx-data-grid
  id="gridContainer"
  [dataSource]="optionsDataSource"
  (onSaving)="selectProhibitedOption($event)"
  (onSaved)="setUpdateProhibitOptionsToFalse($event)"
>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [allowedPageSizes]="['all', 20]"
    [showInfo]="true"
  ></dxo-pager>

  <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
  <dxi-column
    [allowEditing]="false"
    dataField="optionName"
    [allowSorting]="false"
  ></dxi-column>
  <dxi-column dataField="needsSomething" [allowSorting]="false"></dxi-column>
  <dxi-column
    dataField="prohibitsSomething"
    [allowSorting]="false"
  ></dxi-column>
  <dxi-column
    [allowEditing]="false"
    dataField="optionGroup"
    [groupIndex]="1"
    [calculateGroupValue]="sortByOptionGroup"
    groupCellTemplate="sortByOptionGroupTemplate"
  ></dxi-column>
  <dxi-column
    [allowEditing]="false"
    dataField="group"
    [groupIndex]="0"
    groupCellTemplate="sortByGroupAndSortIdTemplate"
    [calculateGroupValue]="sortByGroupSortId"
  >
  </dxi-column>

  <div
    *dxTemplate="let data of 'sortByOptionGroupTemplate'"
    class="option-list-row"
  >
    <div class="option-list-column">
      {{ data.value.split(";")[1] }}
    </div>
    <div class="option-list-column">
      <li *ngFor="let row of getClickedNeededOptionsPerGroup(data)">
        {{ row }}
      </li>
    </div>
    <div class="option-list-column">
      <li *ngFor="let row of getClickedProhibitOptionsPerGroup(data)">
        {{ row }}
      </li>
    </div>
  </div>
  <div
    *dxTemplate="let data of 'sortByGroupAndSortIdTemplate'; let i"
    class="option-list-row"
  >
    <div class="option-list-column">
      <span class="group-header" *ngIf="getFirstRowPerGroup(i)">
        {{ data.value.split(";")[2] }}
      </span>
      {{ data.value.split(";")[3] }}
    </div>
    <div class="option-list-column">
      <li *ngFor="let row of getClickedNeededOptionsPerRow(data)">{{ row }}</li>
    </div>
    <div class="option-list-column">
      <li *ngFor="let row of getClickedProhibitOptionsPerRow(data)">
        {{ row }}
      </li>
    </div>
  </div>

  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
</dx-data-grid>
<div class="button-container">
  <dx-button
    icon="save"
    [hint]="formatMessage('save')"
    stylingMode="text"
    type="default"
    [text]="formatMessage('save')"
    (onClick)="saveOptions()"
  ></dx-button>
</div>
