import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DxFormComponent, DxFormModule } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import {
  OrganizationFormData,
  PublisherForm,
  PublisherGroupForm,
  StudioForm,
} from "../../../model/organization/organizationFormData";
import { CommonModule } from "@angular/common";
import {
  PublisherGroup,
  Studio,
} from "../../../model/organization/organization";
import { PreislistenKatalog } from "../../../model/calculation/produkt";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-organization-form",
  templateUrl: "./organization-form.component.html",
  styleUrls: ["./organization-form.component.scss"],
})
export class OrganizationFormComponent implements OnInit, OnChanges {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  formatMessage = formatMessage;

  @Input() organization: OrganizationFormData;
  @Input() publisherGroups: PublisherGroup[];
  @Input() preislistenKatalogList: PreislistenKatalog[];
  @Input() studios: Studio[];

  isPublisherGroup: boolean = false;
  isPublisher: boolean = false;
  isStudio: boolean = false;

  environmentIsUsa = environment.isUsa;

  constructor() {
    this.getPublisherGroupDisplayValue =
      this.getPublisherGroupDisplayValue.bind(this);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isPublisherGroup = this.organization instanceof PublisherGroupForm;
    this.isPublisher = this.organization instanceof PublisherForm;
    this.isStudio = this.organization instanceof StudioForm;
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  getPublisherGroupDisplayValue(data): string | undefined {
    return data?.disoNumber
      ? data.disoNumber + " (" + data.displayName + ")"
      : "";
  }
}

@NgModule({
  imports: [DxFormModule, CommonModule],
  declarations: [OrganizationFormComponent],
  exports: [OrganizationFormComponent],
})
export class OrganizationFormModule {}
