import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PreislistenKatalog } from "../model/calculation/produkt";
import { Apollo } from "apollo-angular";
import { pluck, tap } from "rxjs/operators";
import {
  GET_OPTIONS_BY_OPTION_GROUP,
  GET_OPTION_GROUPS_BY_PREISLISTEN_KATALOG,
  UPDATE_OPTION,
  UPDATE_OPTION_GROUP,
  DELETE_OPTION,
  DELETE_OPTION_GROUP,
  CREATE_OPTION,
  CREATE_OPTION_GROUP,
  GET_ALL_OPTION_UNIT,
  FIND_OPTION_BY_PRODUKT,
  FIND_PRODUKT_OPTIONS,
  GET_OPTION,
  UPDATE_PRODUKT_OPTION,
  GET_OPTIONS_BY_PREISLISTEN_KATALOG,
  UPDATE_OPTION_MAPPING_PROPERTIES,
  FIND_ALL_OPTION_MAPPING_PROPERTIES,
} from "../graphql/calculatorGql";

import {
  Option,
  UpdateOptionMappingRequest,
} from "../model/calculation/option";
import { OptionGroup } from "../model/calculation/optionGroup";
import { OptionRequest } from "../model/calculation/optionRequest";
import { OptionUnit } from "../model/calculation/optionUnit";
import { ProduktOptionRuleset } from "../model/calculation/produktOptionRuleset";

@Injectable({
  providedIn: "root",
})
export class OptionService {
  constructor(private apollo: Apollo) {}

  findAllOptionUnit(): Observable<OptionUnit[]> {
    return this.apollo
      .query({
        query: GET_ALL_OPTION_UNIT,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllOptionUnit"));
  }

  findOptionsByOptionGroup(optionGroup: OptionGroup): Observable<Option[]> {
    return this.apollo
      .query({
        query: GET_OPTIONS_BY_OPTION_GROUP,
        fetchPolicy: "no-cache",
        variables: { optionGroupId: optionGroup.id },
      })
      .pipe(pluck("data", "findOptionByOptionGroup"));
  }

  findOptionGroupsByPreislistenKatalog(
    preislistenKatalog: PreislistenKatalog
  ): Observable<OptionGroup[]> {
    return this.apollo
      .query({
        query: GET_OPTION_GROUPS_BY_PREISLISTEN_KATALOG,
        fetchPolicy: "no-cache",
        variables: { preislistenKatalogId: preislistenKatalog.id },
      })
      .pipe(pluck("data", "findOptionGroup"));
  }

  updateProduktOption(ruleset: ProduktOptionRuleset): Observable<any> {
    return this.apollo.mutate({
      mutation: UPDATE_PRODUKT_OPTION,
      variables: {
        ruleset: JSON.stringify(ruleset),
      },
    });
  }

  updateOption(optionRequest: OptionRequest): Observable<Option> {
    return this.apollo
      .mutate({
        mutation: UPDATE_OPTION,
        variables: {
          optionRequest: optionRequest,
        },
      })
      .pipe(pluck("data", "updateOption"));
  }

  updateOptionGroup(
    id: string,
    groupName: string,
    sortId: number,
    ancillaryType: string
  ): Observable<OptionGroup> {
    return this.apollo
      .mutate({
        mutation: UPDATE_OPTION_GROUP,
        variables: {
          id: id,
          groupName: groupName,
          sortId: sortId,
          ancillaryType: ancillaryType,
        },
      })
      .pipe(pluck("data", "updateOptionGroup"));
  }

  deleteOption(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_OPTION,
      variables: {
        id: id,
      },
    });
  }

  deleteOptionGroup(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_OPTION_GROUP,
      variables: {
        id: id,
      },
    });
  }

  createOption(optionRequest: OptionRequest): Observable<Option> {
    return this.apollo
      .mutate({
        mutation: CREATE_OPTION,
        variables: {
          optionRequest: optionRequest,
        },
      })
      .pipe(pluck("data", "createOption"));
  }

  getOption(option: Option): Observable<Option> {
    if (option.id) {
      let optionObs: Observable<Option> = this.apollo
        .query({
          query: GET_OPTION,
          fetchPolicy: "no-cache",
          variables: { optionId: option.id },
        })
        .pipe(pluck("data", "getOption"));

      optionObs = optionObs.pipe(
        tap((observableOption: Option) => {
          observableOption.needs = option.needs;
          observableOption.prohibit = option.prohibit;
        })
      );
      return optionObs;
    } else return Observable.create();
  }

  findOptionByProdukt(produktId: string): Observable<ProduktOptionRuleset> {
    return this.apollo
      .query({
        query: FIND_OPTION_BY_PRODUKT,
        fetchPolicy: "no-cache",
        variables: { produktId: produktId },
      })
      .pipe(pluck("data", "findOptionByProdukt"));
  }

  createOptionGroup(
    groupName: string,
    preislistenKatalogId: string,
    sortId: number,
    ancillaryType: string
  ): Observable<OptionGroup> {
    console.log(groupName + ", " + preislistenKatalogId + ", " + sortId);

    return this.apollo
      .mutate({
        mutation: CREATE_OPTION_GROUP,
        variables: {
          groupName: groupName,
          preislistenKatalogId: preislistenKatalogId,
          sortId: sortId,
          ancillaryType: ancillaryType,
        },
      })
      .pipe(pluck("data", "createOptionGroup"));
  }

  getOptionsByPreislistenKatalog(
    preislistenKatalog: PreislistenKatalog
  ): Observable<Option[]> {
    return this.apollo
      .query({
        query: GET_OPTIONS_BY_PREISLISTEN_KATALOG,
        fetchPolicy: "no-cache",
        variables: { preislistenKatalogId: preislistenKatalog.id },
      })
      .pipe(pluck("data", "findOptionMappingByPreislistenKatalog"));
  }

  getAllOptionMappingProperties(): Observable<Option[]> {
    return this.apollo
      .query({
        query: FIND_ALL_OPTION_MAPPING_PROPERTIES,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllOptionWithoutMappingProperties"));
  }

  saveOptionMappingProperties(
    options: UpdateOptionMappingRequest[]
  ): Observable<Option[]> {
    return this.apollo
      .mutate({
        mutation: UPDATE_OPTION_MAPPING_PROPERTIES,
        variables: {
          options: options,
        },
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "updateOptionMappingProperties"));
  }
}
