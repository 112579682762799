import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from "@angular/core";
import {
  CalculationTableConfig,
  SubTotalsDto,
} from "../../calculator-us-formData";
import { CalculatorService } from "../../../../../services/calculator.service";
import {
  AncillaryItem,
  AncillaryItemGroup,
} from "../../../../../model/calculation/calculationResult";

@Component({
  selector: "app-ancillary-item-group",
  templateUrl: "./ancillary-item-group.component.html",
  styleUrls: ["./ancillary-item-group.component.scss"],
})
export class AncillaryItemGroupComponent implements OnInit {
  @Input()
  calcReponse: any;

  @Input()
  calculationTableConfig: CalculationTableConfig;

  @Input()
  headLineText: string;

  @Input()
  invoiceItemsAncillaryType: string;

  displayedAncillaryItems: any[] = [];
  subTotals: number[] = [0, 0, 0];

  constructor(public calculatorService: CalculatorService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    let indexOfSubTotal: number;

    if (this.invoiceItemsAncillaryType == "OPTION_GROUP") {
      // finding SubTotal of Group
      indexOfSubTotal =
        this.calcReponse.ancillaryItemGroupList[0].ancillaryItemList?.findIndex(
          (subTotal) => {
            return (
              subTotal.invoiceItemAncillaryType == "SUMMARY" &&
              subTotal.ancillaryCodeName == this.headLineText
            );
          }
        );

      // finding all Ancillary Items of that Group
      for (let i = 0; i < this.calcReponse.ancillaryItemGroupList.length; i++) {
        if (this.calcReponse.ancillaryItemGroupList[i].ancillaryItemList) {
          this.displayedAncillaryItems[i] =
            this.calcReponse.ancillaryItemGroupList[
              i
            ].ancillaryItemList?.filter((item) => {
              return (
                item.invoiceItemAncillaryType == "OPTION_GROUP" &&
                item.ancillaryTypeName == this.headLineText
              );
            });

          // Creating SubTotals Array
          this.subTotals[i] =
            this.calcReponse.ancillaryItemGroupList[i].ancillaryItemList[
              indexOfSubTotal
            ].totalPrice;
        }
      }
    } else {
      // finding SubTotal of Group
      indexOfSubTotal =
        this.calcReponse.ancillaryItemGroupList[0].ancillaryItemList?.findIndex(
          (subTotal) => {
            return (
              subTotal.invoiceItemAncillaryType == "SUMMARY" &&
              subTotal.ancillaryTypeName == this.invoiceItemsAncillaryType
            );
          }
        );

      // finding all Ancillary Items of that Group
      for (let i = 0; i < this.calcReponse.ancillaryItemGroupList.length; i++) {
        if (this.calcReponse.ancillaryItemGroupList[i].ancillaryItemList) {
          this.displayedAncillaryItems[i] =
            this.calcReponse.ancillaryItemGroupList[
              i
            ].ancillaryItemList?.filter((item) => {
              return (
                item.invoiceItemAncillaryType == this.invoiceItemsAncillaryType
              );
            });

          // Creating SubTotals Array
          this.subTotals[i] =
            this.calcReponse.ancillaryItemGroupList[i].ancillaryItemList[
              indexOfSubTotal
            ].totalPrice;
        }
      }
    }
  }
}
