<dx-data-grid
  [dataSource]="papers"
  [showBorders]="false"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="false"
  [allowColumnReordering]="true"
  class="d-flex flex-grow dx-card wide-card"
  style="padding: 4px 12px; border-radius: 6px"
  (onExporting)="exportGrid($event)"
>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>

  <dxo-state-storing
    [enabled]="true"
    type="localStorage"
    storageKey="p3-papers"
    [savingTimeout]="stateStoreDelay"
  ></dxo-state-storing>

  <dxo-column-chooser
    [enabled]="true"
    mode="select"
    [allowSearch]="true"
    [height]="520"
  ></dxo-column-chooser>

  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [showInfo]="true"
    [allowedPageSizes]="allowedPageSizes"
    displayMode="compact"
  ></dxo-pager>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>

  <dxi-column
    dataField="paperId"
    [caption]="formatMessage('paperDataGrid.paperId')"
    sortOrder="asc"
    [allowHeaderFiltering]="false"
  ></dxi-column>

  <dxi-column
    dataField="name"
    [caption]="formatMessage('paperDataGrid.name')"
  ></dxi-column>

  <dxi-column
    dataField="grammatur"
    [caption]="formatMessage('paperDataGrid.grammatur')"
  ></dxi-column>

  <dxi-column
    dataField="volumen"
    [caption]="formatMessage('paperDataGrid.volumen')"
  ></dxi-column>

  <dxi-column
    dataField="stoffklasse"
    [caption]="formatMessage('paperDataGrid.stoffklasse')"
  ></dxi-column>

  <dxi-column
    dataField="faerbung"
    [caption]="formatMessage('paperDataGrid.faerbung')"
  ></dxi-column>

  <dxi-column
    dataField="paperCertificationList[0].name"
    cellTemplate="paperCertificationListTemplate"
    [caption]="formatMessage('paperDataGrid.paperCertificationList')"
  >
    <div *dxTemplate="let d of 'paperCertificationListTemplate'">
      <div
        class="dx-adaptive-item-text"
        *ngIf="d?.data?.paperCertificationList?.length > 0; else noCertificate"
      >
        <div *ngFor="let certificate of d?.data?.paperCertificationList">
          {{ certificate.name }}
        </div>
      </div>
      <ng-template #noCertificate>
        <div class="dx-adaptive-item-text">
          {{ formatMessage("paperDataGrid.paperCertificationList.empty") }}
        </div>
      </ng-template>
    </div>
    <dxo-header-filter [dataSource]="certficateFilter"></dxo-header-filter>
  </dxi-column>

  <dxi-column
    dataField="rollenBogenPapier"
    [caption]="formatMessage('paperDataGrid.rollenBogenPapier')"
  ></dxi-column>

  <dxi-column
    dataField="price"
    [caption]="formatMessage('paperDataGrid.price')"
  ></dxi-column>

  <dxi-column
    fixedPosition="right"
    [caption]="formatMessage('other.actions')"
    [allowHeaderFiltering]="false"
    [showInColumnChooser]="false"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="edit"
        [hint]="formatMessage('edit')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="editPaper(data.data)"
      ></dx-button>
      <dx-button
        class="action-button"
        icon="trash"
        [hint]="formatMessage('delete')"
        stylingMode="text"
        type="default"
        (onClick)="deletePaper(data.data)"
      ></dx-button>
    </div>
  </dxi-column>

  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-button
          icon="deletetable"
          [hint]="formatMessage('paperDataGrid.reset.localstorage.hint')"
          (onClick)="showConfirmDeletePopup()"
        >
        </dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <dx-button
        [hint]="formatMessage('help.toolbar')"
        type="normal"
        icon="help"
        (onClick)="showHelp()"
      >
      </dx-button>
    </dxi-item>
    <dxi-item location="after" name="exportButton"></dxi-item>
    <dxi-item location="after" name="columnChooserButton"></dxi-item>
  </dxo-toolbar>
</dx-data-grid>

<dx-popup
  [hideOnOutsideClick]="true"
  [dragEnabled]="false"
  [(visible)]="helpPopupVisible"
  [width]="400"
  [height]="170"
  contentTemplate="helpContent"
  titleTemplate="title"
>
  <dxi-toolbar-item location="before">
    <div *dxTemplate="let data of 'title'">
      <span class="popup-toolbar-headline">{{
        formatMessage("help.toolbar")
      }}</span>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item location="after">
    <i class="dx-icon-tips popup-toolbar-icon"></i>
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'helpContent'">
    <span style="font-weight: bold">{{ formatMessage("help.headline") }}</span>
    <p>
      {{ formatMessage("help.content.first") }}
    </p>
    <p>
      {{ formatMessage("help.content.second") }}
    </p>
  </div>
</dx-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  icon="dx-icon-warning"
  iconColor="warning"
  [title]="formatMessage('paperDataGrid.reset.localstorage.popup.title')"
  [message]="formatMessage('paperDataGrid.reset.localstorage.popup.message')"
  [confirmButtonText]="
    formatMessage('paperDataGrid.reset.localstorage.popup.confirmButtonText')
  "
  (onConfirm)="resetLocalStorage()"
></app-confirmation-dialog>
