<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetPreislistenKatalogForm()"
  [title]="title"
  (onSave)="save()"
>
  <app-preislistenKatalog-form [(preislistenKatalogData)]="preislistenKatalog">
  </app-preislistenKatalog-form>
</app-action-popup>
