import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Organization } from "../../../model/organization/organization";
import { DxButtonModule, DxDataGridModule } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { OrganizationType } from "../../../model/organization/organizationFormData";
import { CommonModule } from "@angular/common";
import { OrganizationTagModule } from "../../tags/organization-tag/organization-tag.component";
import { OrganizationUnionTagModule } from "../../tags/organization-union-tag/organization-union-tag.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-organization-data-grid",
  templateUrl: "./organization-data-grid.component.html",
  styleUrls: ["./organization-data-grid.component.scss"],
})
export class OrganizationDataGridComponent implements OnInit {
  @Input() organizationType: OrganizationType;
  @Input() organizations: Organization[];

  @Output() onCreate = new EventEmitter<OrganizationType>();
  @Output() onUpdate = new EventEmitter<Organization>();
  @Output() onDelete = new EventEmitter<Organization>();

  formatMessage = formatMessage;

  isPublisherGroup: boolean = false;
  isPublisher: boolean = false;
  isStudio: boolean = false;
  environmentIsUSA: boolean = environment.isUsa;
  constructor() {
    this.getPublisherGroupValue = this.getPublisherGroupValue.bind(this);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isPublisherGroup =
      this.organizationType === OrganizationType.PUBLISHER_GROUP;
    this.isPublisher = this.organizationType === OrganizationType.PUBLISHER;
    this.isStudio = this.organizationType === OrganizationType.STUDIO;
  }

  getPublisherGroupValue(data): string | undefined {
    const displayName = data?.publisherGroup?.displayName
      ? " (" + data.publisherGroup.displayName + ")"
      : "";

    return data?.publisherGroup?.disoNumber
      ? data.publisherGroup?.disoNumber + displayName
      : "";
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    CommonModule,
    DxButtonModule,
    OrganizationTagModule,
    OrganizationUnionTagModule,
  ],
  declarations: [OrganizationDataGridComponent],
  exports: [OrganizationDataGridComponent],
})
export class OrganizationDataGridModule {}
