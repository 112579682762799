import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { PreislistenKatalogFormData } from "../../../model/calculation/preislistenKatalogFormData";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-preislistenKatalog-form",
  templateUrl: "./preislistenKatalog-form.component.html",
  styleUrls: ["./preislistenKatalog-form.component.scss"],
})
export class PreislistenKatalogFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() preislistenKatalogData: PreislistenKatalogFormData;
  @Output() preislistenKatalogDataChange =
    new EventEmitter<PreislistenKatalogFormData>();

  constructor() {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
  }

  ngOnInit(): void {}

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }
}

@NgModule({
  imports: [
    DxFormModule,
    CommonModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
  ],
  declarations: [PreislistenKatalogFormComponent],
  exports: [PreislistenKatalogFormComponent],
})
export class PreislistenKatalogFormModule {}
