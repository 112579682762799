import { formatMessage } from "devextreme/localization";

export function isSingleUploadOrderPiece(orderPiece: string): boolean {
  return ["INH", "UE", "BDR", "SU", "BU"].includes(orderPiece);
}

/* Represents the product type 'hardcover without wrapper' (dt: Hardcover ohne
Schutzumschlag). Contains all order pieces with label and abbreviation. */
const hardcoverWithoutWrapper = [
  "INH",
  "BT",
  "VNS",
  "UE",
  "UEV",
  "PS",
  "SMPS",
  "FS",
  "SMFS",
  "ETI",
  "SMETI",
  "BEI",
  "BHE",
  "PK",
  "BDR",
  "SMBDR",
  "SO",
];

/* Represents the product type 'hardcover with wrapper' (dt: Hardcover mit
Schutzumschlag). Contains all order pieces with label and abbreviation. */
const hardcoverWithWrapper = [
  "INH",
  "BT",
  "VNS",
  "SU",
  "SUV",
  "UE",
  "UEV",
  "PS",
  "SMPS",
  "FS",
  "SMFS",
  "ETI",
  "SMETI",
  "BEI",
  "BHE",
  "PK",
  "BDR",
  "SMBDR",
  "SO",
];

/* Represents the product type 'paperback' (dt: Broschur). Contains all order
pieces with label and abbreviation. */
const paperback = [
  "INH",
  "BT",
  "BU",
  "BUV",
  "FS",
  "SMFS",
  "ETI",
  "SMETI",
  "BEI",
  "BHE",
  "PK",
  "BDR",
  "SMBDR",
  "SO",
];

/* Represents the product type 'softcover' (dt: Klappenbroschur). Contains all
order pieces with label and abbreviation. */
const softcover = [
  "INH",
  "BT",
  "BU",
  "BUV",
  "FS",
  "SMFS",
  "ETI",
  "SMETI",
  "BEI",
  "BHE",
  "PK",
  "BDR",
  "SMBDR",
  "SO",
];

/* Represents the product type 'other' (dt: Sonstiges). Contains all order
pieces with label and abbreviation. */
const other = ["SO"];

export const productTypeMapping = {
  HardcoverWithoutWrapper: hardcoverWithoutWrapper,
  HardcoverWithWrapper: hardcoverWithWrapper,
  Paperback: paperback,
  Softcover: softcover,
  Other: other,
};

export function getBookGraphicResourceForProductType(productType: string) {
  switch (productType) {
    case "Paperback":
      return "assets/bookgraphics/Broschur.png";
    case "Softcover":
      return "assets/bookgraphics/Klappenbroschur.png";
    case "HardcoverWithWrapper":
      return "assets/bookgraphics/Hardcover_mit_SU.png";
    case "HardcoverWithoutWrapper":
      return "assets/bookgraphics/Hardcover_ohne_SU.png";
    case "Other":
      return "assets/bookgraphics/Sonstiges.png";
    default:
      return "";
  }
}
