<div class="content-block" id="container">
  <h2>{{ formatMessage("produktManagement") }}</h2>

  <div class="d-flex align-items-center">
    <div style="margin-right: 0.5rem">
      {{ formatMessage("produkt.selectedPreislistenKatalog") }}
    </div>
    <dx-lookup
      *ngIf="preislistenKataloge"
      [dataSource]="preislistenKataloge"
      displayExpr="name"
      style="min-width: 200px"
      [value]="selectedPreislistenKatalog"
      [searchEnabled]="true"
      (onValueChanged)="preislistenKatalogSelected($event)"
    >
    </dx-lookup>

    <dx-button
      *ngIf="showNewProduktButton"
      stylingMode="contained"
      type="default"
      icon="edit"
      [disabled]="editPreislistenKatalogDisabled"
      style="margin-left: 12px"
      (onClick)="editPreislistenKatalog()"
    ></dx-button>

    <dx-button
      *ngIf="showNewProduktButton"
      stylingMode="contained"
      type="default"
      icon="add"
      style="margin-left: 12px"
      (onClick)="createPreislistenKatalog()"
    ></dx-button>
  </div>

  <dx-speed-dial-action
    [elementAttr]="{ id: 'faButton' }"
    *ngIf="!isMobile && showNewProduktButton; else textButton"
    icon="add"
    (onClick)="createProdukt()"
  ></dx-speed-dial-action>

  <ng-template #textButton>
    <dx-button
      *ngIf="showNewProduktButton"
      stylingMode="contained"
      type="default"
      icon="add"
      [text]="formatMessage('produkt.create')"
      style="margin-bottom: 12px; width: 100%"
      (onClick)="createProdukt()"
    ></dx-button>
  </ng-template>

  <dx-data-grid
    id="user-grid"
    [dataSource]="produkte"
    (onContentReady)="placeFloatingActionButton()"
    [columnAutoWidth]="!isMobile"
    [columnHidingEnabled]="true"
    class="dx-card wide-card"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="[15, 30, 50, 'all']"
    ></dxo-pager>

    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
      [texts]="{
        emptyValue: formatMessage('produkt.dataGrid.headerFilter.emptyValue')
      }"
    ></dxo-header-filter>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
      dataField="name"
      [caption]="formatMessage('produkt.name')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      [caption]="formatMessage('other.actions')"
      [allowHeaderFiltering]="false"
      type="buttons"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
      <div *dxTemplate="let data of 'actionsTemplate'">
        <dx-button
          class="action-button"
          icon="edit"
          [hint]="formatMessage('produkt.navigate.details')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="navigateToProduktDetails(data.data)"
        >
        </dx-button>

        <dx-button
          class="action-button"
          icon="trash"
          [hint]="formatMessage('delete')"
          stylingMode="text"
          type="default"
          (onClick)="showConfirmationPopupForDeleteProdukt(data.data)"
        ></dx-button>
      </div>
    </dxi-column>
    <dxo-toolbar>
      <dxi-item
        location="after"
        cssClass="toolbar-export-button"
        name="exportButton"
      ></dxi-item>
    </dxo-toolbar>
    <dxo-export
      [enabled]="true"
      [fileName]="formatMessage('produkt.export.filename')"
    ></dxo-export>
  </dx-data-grid>
</div>

<app-produkt-form-popup
  [(visible)]="produktPopupVisible"
  [produkt]="produktFormData"
  [preislistenKataloge]="preislistenKataloge"
  [produkte]="produktFormProdukte"
  (onSave)="saveProdukt($event)"
></app-produkt-form-popup>

<app-preislistenKatalog-form-popup
  [(visible)]="preislistenKatalogPopupVisible"
  [preislistenKatalog]="preislistenKatalogFormData"
  [creating]="!selectedPreislistenKatalogId"
  (onSave)="savePreislistenKatalog($event)"
></app-preislistenKatalog-form-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="deleteProduktAndUpdateView()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>

