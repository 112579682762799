import { Component, Input, NgModule, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  // Can be "info", "success", "warning" or "error"
  @Input() type: string = "info";
  @Input() multiLine: boolean = false;

  @Input() icon: string = "dx-icon-info";
  @Input() showIcon: boolean = true;
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}

  isSuccess(): boolean {
    return this.type === "success";
  }

  isWarning(): boolean {
    return this.type === "warning";
  }

  isError(): boolean {
    return this.type === "error";
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertModule {}
