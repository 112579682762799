<div class="d-flex flex-grow flex-column content-block">
  <div class="d-flex flex-shrink">
    <span class="page-title">
      <app-return-button returnPath="/auftraege"> </app-return-button>
      {{ formatMessage("order.details.title") }}
    </span>
  </div>
  <div *ngIf="orderId !== ''" class="d-flex flex-grow">
    <app-order-details
      [orderId]="orderId"
      class="d-flex flex-grow flex-column"
    ></app-order-details>
  </div>
</div>
