import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { CalculatorBookDetailsComponent } from "../calculator-book-details/calculator-book-details.component";
import { PriceOptionListComponent } from "./price-option-list/price-option-list.component";
import { CalculatorService } from "../../../../services/calculator.service";
import { Option } from "../../../../model/calculation/option";
import { SelectedOption } from "../../../../model/calculation/selectedOption";
import { OwnOption } from "../../../../model/calculation/ownOption";

@Component({
  selector: "app-calculator-price-options",
  templateUrl: "./calculator-price-options.component.html",
  styleUrls: ["./calculator-price-options.component.scss"],
})
export class CalculatorPriceOptionsComponent {
  @Input()
  calculator: CalculatorBookDetailsComponent;

  @ViewChild(PriceOptionListComponent)
  priceList: PriceOptionListComponent;

  neededOptions: Set<string>;
  optionsWithMissingInput: SelectedOption[];
  optionWithPriceInformation: boolean = true;
  ownOptionsWithMissingInput: OwnOption[];

  constructor(private calculatorService: CalculatorService) {}

  formatMessage = formatMessage;

  showOptionen: boolean = false;
  optionTitle: string;

  submitOptionen(doNotClose: boolean = false) {
    // TODO: Validierung
    this.neededOptions = new Set();
    this.optionsWithMissingInput = [];
    this.ownOptionsWithMissingInput = [];
    this.optionWithPriceInformation = true;

    this.neededOptions = this.priceList.validateOptionList();
    this.ownOptionsWithMissingInput = this.priceList.validateOwnOptions();

    if (this.calculatorService.produktOptions.paths) {
      if (this.calculatorService.produktOptions.paths.length > 0) {
        let optionInfo = this.priceList.getOptionsWithMissingInput();
        this.optionsWithMissingInput = optionInfo.optionsWithMissingInput;
        this.optionWithPriceInformation = optionInfo.found;
      }
    }

    if (this.neededOptions.size > 0) return;
    if (this.optionsWithMissingInput.length > 0) return;
    if (this.ownOptionsWithMissingInput.length > 0) return;
    if (!this.optionWithPriceInformation) return;

    this.calculatorService.selectedOptions = this.mapOptionsToSelectedOptions();
    if (!doNotClose) this.showOptionen = false;
    this.calculator.tabOutTextBox();
  }

  openOptions($event: any) {
    this.neededOptions = new Set();

    this.optionTitle = this.formatMessage(
      "calculator.calculation.options.title"
    );
    if (!this.calculatorService.initializedOptionList) {
      this.priceList.prepareOptions();
      this.calculatorService.initializedOptionList = true;
    }
    this.showOptionen = true;
  }

  private mapOptionsToSelectedOptions() {
    let options = { ...this.priceList.list.selectedItems };
    let selectedOptions: SelectedOption[] = [];
    Object.keys(options).forEach((key) => {
      let group = options[key];
      if (group.items) {
        group.items.forEach((option: Option) => {
          let selectedOption: SelectedOption = {
            id: option.id,
            userInput: option.userInput,
          };
          selectedOptions.push(selectedOption);
        });
      }
    });
    return selectedOptions;
  }
}
