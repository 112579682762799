<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetUserForm(); resetRoleValidation()"
  [title]="title"
  (onSave)="save()"
>
  <app-user-form
    [(userData)]="user"
    [publisherGroups]="publisherGroups"
    [publisher]="publisher"
    [studios]="studios"
    (emailInput)="setShowEmailHint($event)"
  ></app-user-form>

  <span footer *ngIf="showEmailHint">
    {{ formatMessage("user.emailChanged.hint") }}
  </span>
</app-action-popup>
