<div class="content-block" id="container">
  <h2>{{ formatMessage("optionManagement") }}</h2>

  <div class="d-flex align-items-center">
    <div style="margin-right: 0.5rem">
      {{ formatMessage("option.selectedPreislistenKatalog") }}
    </div>
    <dx-lookup
      *ngIf="preislistenKataloge"
      [dataSource]="preislistenKataloge"
      displayExpr="name"
      style="min-width: 200px"
      [value]="selectedPreislistenKatalog"
      [searchEnabled]="true"
      (onValueChanged)="preislistenKatalogSelected($event)"
    >
    </dx-lookup>

    <div style="margin-right: 0.5rem" *ngIf="optionGroups">
      {{ formatMessage("option.selectedOptionGroup") }}
    </div>
    <dx-lookup
      *ngIf="optionGroups"
      [dataSource]="optionGroups"
      displayExpr="groupName"
      style="min-width: 200px"
      [value]="selectedOptionGroup"
      [searchEnabled]="true"
      (onValueChanged)="optionGroupSelected($event)"
    >
    </dx-lookup>

    <div *ngIf="optionGroups">
      <dx-button
        stylingMode="contained"
        type="default"
        icon="edit"
        [disabled]="editOptionGroupDisabled"
        style="margin-left: 12px"
        (onClick)="editOptionGroup()"
      ></dx-button>
    </div>
    <div *ngIf="optionGroups">
      <dx-button
        *ngIf="showNewOptionButton"
        stylingMode="contained"
        type="default"
        icon="add"
        style="margin-left: 12px"
        (onClick)="createOptionGroup()"
      ></dx-button>
    </div>
    <div *ngIf="optionGroups">
      <dx-button
        stylingMode="contained"
        type="default"
        icon="trash"
        [disabled]="editOptionGroupDisabled"
        style="margin-left: 12px"
        (onClick)="showConfirmationPopupForDeleteOptionGroup()"
      ></dx-button>
    </div>
  </div>

  <dx-speed-dial-action
    [elementAttr]="{ id: 'faButton' }"
    *ngIf="!isMobile && showNewOptionButton; else textButton"
    icon="add"
    (onClick)="createOption()"
  ></dx-speed-dial-action>

  <ng-template #textButton>
    <dx-button
      *ngIf="showNewOptionButton"
      stylingMode="contained"
      type="default"
      icon="add"
      [text]="formatMessage('option.create')"
      style="margin-bottom: 12px; width: 100%"
      (onClick)="createOption()"
    ></dx-button>
  </ng-template>

  <dx-data-grid
    id="user-grid"
    [dataSource]="options"
    (onContentReady)="placeFloatingActionButton()"
    [columnAutoWidth]="!isMobile"
    [columnHidingEnabled]="true"
    class="dx-card wide-card"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="[15, 30, 50, 'all']"
    ></dxo-pager>

    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
      [texts]="{
        emptyValue: formatMessage('option.dataGrid.headerFilter.emptyValue')
      }"
    ></dxo-header-filter>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
      dataField="optionName"
      [caption]="formatMessage('option.name')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      dataField="sortId"
      [caption]="formatMessage('option.sortId')"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      *ngIf="environmentIsUsa"
      dataField="invoiceCategory"
      caption="Invoice Category"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      *ngIf="environmentIsUsa"
      dataField="optionTypeDisplayName"
      caption="Option Type"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      *ngIf="environmentIsUsa"
      dataField="id"
      caption="Option ID"
      [allowHeaderFiltering]="false"
    ></dxi-column>
    <dxi-column
      [caption]="formatMessage('other.actions')"
      [allowHeaderFiltering]="false"
      type="buttons"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
      <div *dxTemplate="let data of 'actionsTemplate'">
        <dx-button
          class="action-button"
          icon="edit"
          [hint]="formatMessage('option.navigate.details')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="editOption(data.data)"
        >
        </dx-button>

        <dx-button
          class="action-button"
          icon="trash"
          [hint]="formatMessage('delete')"
          stylingMode="text"
          type="default"
          (onClick)="showConfirmationPopupForDeleteOption(data.data)"
        ></dx-button>
      </div>
    </dxi-column>
    <dxo-toolbar>
      <dxi-item
        location="after"
        cssClass="toolbar-export-button"
        name="exportButton"
      ></dxi-item>
    </dxo-toolbar>
    <dxo-export
      [enabled]="true"
      [fileName]="formatMessage('option.export.filename')"
    ></dxo-export>
  </dx-data-grid>
</div>

<app-option-form-popup
  [(visible)]="optionPopupVisible"
  [option]="optionFormData"
  [options]="optionFormOptions"
  [produkte]="produkte"
  [optionUnitsAdmin]="optionUnitsAdmin"
  [dependentOptions]="optionFormDependentOptions"
  (onSave)="saveOption($event)"
  [preisListe]="selectedPreislistenKatalog"
></app-option-form-popup>

<app-optiongroup-form-popup
  [(visible)]="optionGroupPopupVisible"
  [optionGroup]="optionGroupFormData"
  [creating]="!selectedOptionGroupId"
  (onSave)="saveOptionGroup($event)"
></app-optiongroup-form-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationOptionPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="deleteOptionAndUpdateView()"
></app-confirmation-dialog>

<app-confirmation-dialog
  [(visible)]="showConfirmationOptionGroupPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="deleteOptionGroup()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
