<div
  class="d-flex flex-grow flex-column content-block"
  [style]="
    isXLarge
      ? 'padding-top: 40px'
      : isXSmall
      ? 'padding-top: 20px'
      : 'padding-top: 30px'
  "
>
  <div
    *ngIf="loading || uploadLinkErrorMessage"
    class="
      d-flex
      flex-grow
      justify-content-center
      align-items-center
      text-center
    "
  >
    <dx-load-indicator [visible]="loading"></dx-load-indicator>
    <div
      class="d-flex flex-column"
      *ngIf="uploadLinkErrorMessage || statusLinkUploadNotAllowed"
    >
      <i
        class="material-icons mdi-sad"
        style="font-size: 52px; color: rgba(0, 0, 0, 0.87)"
      ></i>
      <span
        [style]="
          isXSmall
            ? 'font-size: 20px; margin: 15px 0 10px 0'
            : 'font-size: 30px; margin: 15px 0 2px 0'
        "
        style="font-weight: 300"
      >
        {{ uploadLinkErrorMessage }}
      </span>
      <span
        *ngIf="showErrorSubtitle"
        style="font-size: 16px; font-weight: 300; color: rgba(0, 0, 0, 0.4)"
      >
        {{ formatMessage("upload.link.error.subtitle") }}
      </span>
    </div>
  </div>
  <div
    *ngIf="!uploadLinkErrorMessage && !loading"
    class="d-flex flex-grow flex-column"
  >
    <div
      class="d-flex justify-content-center"
      [style]="isXSmall ? 'padding-bottom: 20px' : 'padding-bottom: 40px'"
    >
      <div
        class="d-flex flex-shrink flex-column justify-content-center"
        [style]="isXSmall ? 'text-align: center' : 'text-align: right'"
      >
        <div class="d-flex flex-column">
          <span
            [class]="
              isXLarge
                ? 'title-x-large'
                : isLarge
                ? 'title-large'
                : isXSmall
                ? 'title-x-small'
                : 'title'
            "
            >{{ formatMessage("upload.title") }}</span
          >
          <span
            [class]="
              isXLarge
                ? 'order-piece-x-large'
                : isLarge
                ? 'order-piece-large'
                : isXSmall
                ? 'order-piece-x-small'
                : 'order-piece'
            "
            style="cursor: default"
            [title]="formatMessage('upload.orderPiece')"
          >
            {{ formatMessage("upload.orderPiece." + uploadLink.orderPiece) }}
          </span>
          <span
            [class]="isXLarge || isLarge ? 'text-large' : 'text'"
            style="cursor: default"
            [style]="isXLarge || isLarge ? 'padding-top: 3px' : ''"
            [title]="formatMessage('productType')"
          >
            {{ formatMessage("productType." + productType) }}
          </span>
        </div>
        <div
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1); margin: 10px 0"
        ></div>
        <div class="d-flex flex-column">
          <span
            [class]="isXLarge || isLarge ? 'text-large' : 'text'"
            style="cursor: default"
            [title]="formatMessage('upload.order.title')"
          >
            {{ orderInfo.title }}
          </span>
          <span
            [class]="isXLarge || isLarge ? 'text-large' : 'text'"
            style="cursor: default"
            [title]="formatMessage('upload.order.author')"
          >
            {{ orderInfo.author }}
          </span>
          <span
            [class]="isXLarge || isLarge ? 'text-large' : 'text'"
            style="cursor: default"
          >
            {{ formatMessage("upload.order.isbn") + " " + orderInfo.isbn }}
          </span>
        </div>
      </div>

      <div *ngIf="!isXSmall" class="flex-shrink d-flex">
        <img
          *ngIf="productType != 'Other'"
          [src]="bookGraphicResource"
          alt="Book Components"
          [style]="
            isXLarge
              ? 'width: 200px; margin-left: 50px'
              : isLarge
              ? 'width: 185px; margin-left: 50px'
              : 'width: 165px; margin-left: 40px'
          "
        />
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div
        class="d-flex flex-column"
        [style]="
          isMediumOrDownScreen
            ? 'width: 100%'
            : isLarge
            ? 'width: 80%'
            : 'width: 75%'
        "
      >
        <app-file-uploader
          *ngIf="
            !showSuccessAlertForSingleUpload;
            else successAlertForSingleUploadTemplate
          "
          [orderId]="uploadLink.orderId"
          [orderPiece]="orderPiece"
          [uploadLink]="uploadLink"
          (uploadSuccessful)="handleUploadSuccess($event)"
          (fileAlreadyExists)="handleFileAlreadyExists($event)"
          (uploadError)="handleUploadError($event)"
          class="d-flex"
          style="width: 100%"
          [style]="isXLarge ? 'height: 45vh' : 'height: 40vh'"
        ></app-file-uploader>

        <ng-template #successAlertForSingleUploadTemplate>
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              dx-theme-border-color
              drop-zone-imitation-border
            "
            style="width: 100%"
            [style]="isXLarge ? 'height: 45vh' : 'height: 40vh'"
          >
            <app-alert
              type="success"
              [message]="successAlertMessage"
              icon="material-icons mdi-success"
            ></app-alert>
          </div>
        </ng-template>

        <div
          class="d-flex flex-shrink justify-content-center"
          style="padding-top: 10px"
        >
          <a
            [href]="guidelinesLink"
            target="_blank"
            class="d-flex info-text align-items-center text-center"
          >
            <span>{{ formatMessage("upload.info") }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-toast
  [(visible)]="showToast"
  type="error"
  [message]="toastMessage"
></dx-toast>
