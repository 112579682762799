<div class="d-flex flex-grow flex-column content-block">
  <div class="d-flex flex-shrink">
    <span class="page-title">
      <app-return-button returnPath="/produkt" produkt="{{ produktId }}">
      </app-return-button>
      {{ formatMessage("produkt.details.title") }}
    </span>
  </div>
  <div *ngIf="produktId !== ''" class="d-flex flex-grow">
    <app-produkt-details
      [produktId]="produktId"
      class="d-flex flex-grow flex-column"
    ></app-produkt-details>
  </div>
</div>
