import { Component, Input, NgModule, OnInit } from "@angular/core";
import {
  getExtendedPublisherGroupDisplayName,
  isPublisher,
  isPublisherGroup,
  isStudio,
  Organization,
  PublisherGroup,
} from "../../../model/organization/organization";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-organization-tag",
  templateUrl: "./organization-tag.component.html",
  styleUrls: ["./organization-tag.component.scss"],
})
export class OrganizationTagComponent implements OnInit {
  isPublisherGroup = isPublisherGroup;
  isPublisher = isPublisher;
  isStudio = isStudio;

  @Input() organization: Organization;
  @Input() hideColors: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  getPublisherGroupDisplayName(organization: Organization): string {
    return getExtendedPublisherGroupDisplayName(organization as PublisherGroup);

    // TODO would be better to not use methods for the name
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [OrganizationTagComponent],
  exports: [OrganizationTagComponent],
})
export class OrganizationTagModule {}
