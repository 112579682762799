<header>
  <dx-toolbar class="header-toolbar" style="padding: 8px 0">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <dxi-item location="before" [text]="title" *ngIf="title">
      <div *dxTemplate>
        <img
          class="brand-logo"
          src="assets/p3-ggp.png"
          alt="{{ title }}"
          title="{{ title }}"
        />
      </div>
    </dxi-item>

    <dxi-item *ngIf="!isLoggedIn; else userLoggedInHeader" location="after">
      <dx-button
        stylingMode="text"
        [text]="currentLocale === 'de-DE' ? 'DE' : 'EN'"
        (onClick)="toggleLocale()"
      ></dx-button>
    </dxi-item>

    <ng-template #userLoggedInHeader>
      <dxi-item
        location="after"
        locateInMenu="auto"
        menuItemTemplate="menuItem"
        *ngIf="currentUser$ | async as user; else userNotAuthorizedTemplate"
      >
        <div *dxTemplate="let data of 'item'">
          <dx-button
            #userButton
            class="user-button authorization"
            width="auto"
            height="100%"
            stylingMode="text"
          >
            <div *dxTemplate="let data of 'content'">
              <app-user-panel
                [user]="user"
                [menuItems]="userMenuItems"
                menuMode="context"
                [userButton]="userButton"
              ></app-user-panel>
            </div>
          </dx-button>
        </div>
      </dxi-item>

      <div *dxTemplate="let data of 'menuItem'">
        <app-user-panel
          [user]="currentUser$ | async"
          [menuItems]="userMenuItems"
          menuMode="list"
        ></app-user-panel>
      </div>
    </ng-template>

    <ng-template #userNotAuthorizedTemplate>
      <dxi-item location="after">
        <dx-button
          stylingMode="text"
          [text]="currentLocale === 'de-DE' ? 'DE' : 'EN'"
          (onClick)="toggleLocale()"
        ></dx-button>
      </dxi-item>
      <dxi-item location="after">
        <dx-button
          stylingMode="text"
          icon="dx-icon material-icons mdi-logout"
          [hint]="formatMessage('logout')"
          (onClick)="logOut()"
        >
        </dx-button>
      </dxi-item>
    </ng-template>
  </dx-toolbar>
</header>
