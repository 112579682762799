<dx-popup
  [(visible)]="visible"
  [showTitle]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  maxWidth="400px"
  [dragEnabled]="false"
  height="auto"
>
  <dxi-toolbar-item location="before">
    <div *dxTemplate="let data of 'title'">
      <p class="d-flex align-items-center popup-title">
        <i
          *ngIf="icon"
          [class]="icon + ' ' + iconColor"
          style="font-size: 20px; margin-right: 4px"
        ></i>
        <span>
          {{ title }}
        </span>
      </p>
    </div>
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" style="padding-top: 16px">
    <div class="popup-message">
      {{ message }}
    </div>

    <div
      class="d-flex flex-grow"
      style="align-items: center; justify-content: end"
    >
      <dx-button
        [text]="formatMessage('cancel')"
        stylingMode="text"
        (onClick)="
          visible = false; visibleChange.emit(visible); onCancel.emit()
        "
        style="margin: 0 10px"
      ></dx-button>

      <dx-button
        [text]="confirmButtonText"
        type="default"
        (onClick)="
          visible = false; visibleChange.emit(visible); onConfirm.emit()
        "
      ></dx-button>
    </div>
  </div>
</dx-popup>
