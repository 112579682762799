import { Component, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Router } from "@angular/router";
import { ScreenService } from "../../shared/services";

@Component({
  selector: "app-not-authorized",
  templateUrl: "./not-authorized.component.html",
  styleUrls: ["./not-authorized.component.scss"],
})
export class NotAuthorizedComponent implements OnInit {
  formatMessage = formatMessage;
  isXSmall: boolean;

  constructor(private router: Router, private screen: ScreenService) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  navigateToHome(): void {
    this.router.navigate(["/"]);
  }
}
