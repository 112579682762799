<div
  class="
    d-flex
    flex-grow flex-column
    content-block
    align-items-center
    justify-content-center
    text-center
  "
>
  <i
    class="material-icons mdi-sad"
    style="font-size: 52px; color: rgba(0, 0, 0, 0.87)"
  ></i>
  <span
    [style]="
      isXSmall
        ? 'font-size: 20px; margin: 15px 0 10px 0'
        : 'font-size: 30px; margin: 15px 0 2px 0'
    "
    style="font-weight: 300"
  >
    {{ formatMessage("notAuthorized.title") }}
  </span>
  <span style="font-size: 16px; font-weight: 300; color: rgba(0, 0, 0, 0.4)">
    {{ formatMessage("notAuthorized.subtitle") }}
  </span>
  <dx-button
    [style]="isXSmall ? 'margin-top: 50px; width:100%' : 'margin-top: 125px'"
    stylingMode="contained"
    text="{{ formatMessage('back.home') }}"
    type="default"
    (click)="navigateToHome()"
  ></dx-button>
</div>
