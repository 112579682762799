import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Identity, User } from "../model/user/user";
import {
  AuthorizeInternalUserRequest,
  IdentityRequest,
  UpdateInternalUserRequest,
} from "../model/user/userRequests";
import { Apollo } from "apollo-angular";
import { pluck } from "rxjs/operators";
import { UserFormData } from "../model/user/userFormData";
import {
  AUTHORIZE_INTERNAL_USERS,
  CREATE_ADMIN_US_USER,
  CREATE_AGENCY_EMPLOYEE,
  CREATE_MANUFACTURER_FOR_PUBLISHER,
  CREATE_MANUFACTURER_FOR_PUBLISHER_GROUP,
  DELETE_USER,
  DISABLE_USER,
  ENABLE_USER,
  FIND_ALL_NOT_AUTHORIZED_INTERNAL_USERS,
  FIND_ALL_USERS,
  FIND_OWN_USER,
  UPDATE_ADMINUS_USER,
  UPDATE_AGENCY_EMPLOYEE,
  UPDATE_INTERNAL_USER,
  UPDATE_MANUFACTURER_FOR_PUBLISHER,
  UPDATE_MANUFACTURER_FOR_PUBLISHER_GROUP,
  UPDATE_OWN_DETAILS,
} from "../graphql/userGql";
import {
  ADMIN_US,
  AGENCY_EMPLOYEE,
  MANUFACTURER_FOR_PUBLISHER,
  MANUFACTURER_FOR_PUBLISHER_GROUP,
} from "../model/roles";
import DevExpress from "devextreme";
import data = DevExpress.data;

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private apollo: Apollo) {}

  findAllUsers(): Observable<User[]> {
    return this.apollo
      .query({
        query: FIND_ALL_USERS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllUsers"));
  }

  findOwnUser(): Observable<User> {
    return this.apollo
      .query({
        query: FIND_OWN_USER,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findOwnUser"));
  }

  findAllNotAuthorizedInternalUsers(): Observable<Identity[]> {
    return this.apollo
      .query({
        query: FIND_ALL_NOT_AUTHORIZED_INTERNAL_USERS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findNotAuthorizedInternalUsers"));
  }

  createExternalUser(user: UserFormData): Observable<User> {
    if (user.role === MANUFACTURER_FOR_PUBLISHER_GROUP) {
      return this.apollo
        .mutate({
          mutation: CREATE_MANUFACTURER_FOR_PUBLISHER_GROUP,
          variables: {
            identity: new IdentityRequest(user),
            publisherGroup: user.worksForPublisherGroup,
          },
        })
        .pipe(pluck("data", "createManufacturerForPublisherGroup"));
    } else if (user.role === MANUFACTURER_FOR_PUBLISHER) {
      return this.apollo
        .mutate({
          mutation: CREATE_MANUFACTURER_FOR_PUBLISHER,
          variables: {
            identity: new IdentityRequest(user),
            publisher: user.worksForPublisher,
          },
        })
        .pipe(pluck("data", "createManufacturerForPublisher"));
    } else if (user.role === AGENCY_EMPLOYEE) {
      return this.apollo
        .mutate({
          mutation: CREATE_AGENCY_EMPLOYEE,
          variables: {
            identity: new IdentityRequest(user),
            studio: user.worksForStudio,
          },
        })
        .pipe(pluck("data", "createAgencyEmployee"));
    } else if (user.role === ADMIN_US) {
      return this.apollo
        .mutate({
          mutation: CREATE_ADMIN_US_USER,
          variables: {
            identity: new IdentityRequest(user),
          },
        })
        .pipe(pluck("data", "createExternalAdminUsUser"));
    } else {
      throw Error("Invalid user role: " + user.role);
    }
  }

  updateExternalUser(id: string, user: UserFormData): Observable<User> {
    if (user.role === MANUFACTURER_FOR_PUBLISHER_GROUP) {
      return this.apollo
        .mutate({
          mutation: UPDATE_MANUFACTURER_FOR_PUBLISHER_GROUP,
          variables: {
            id: id,
            identity: new IdentityRequest(user),
            publisherGroup: user.worksForPublisherGroup,
          },
        })
        .pipe(pluck("data", "updateManufacturerForPublisherGroup"));
    } else if (user.role === MANUFACTURER_FOR_PUBLISHER) {
      return this.apollo
        .mutate({
          mutation: UPDATE_MANUFACTURER_FOR_PUBLISHER,
          variables: {
            id: id,
            identity: new IdentityRequest(user),
            publisher: user.worksForPublisher,
          },
        })
        .pipe(pluck("data", "updateManufacturerForPublisher"));
    } else if (user.role === AGENCY_EMPLOYEE) {
      return this.apollo
        .mutate({
          mutation: UPDATE_AGENCY_EMPLOYEE,
          variables: {
            id: id,
            identity: new IdentityRequest(user),
            studio: user.worksForStudio,
          },
        })
        .pipe(pluck("data", "updateAgencyEmployee"));
    } else if (user.role === ADMIN_US) {
      return this.apollo
        .mutate({
          mutation: UPDATE_ADMINUS_USER,
          variables: {
            id: id,
            identity: new IdentityRequest(user),
          },
        })
        .pipe(pluck("data", "updateAdminUsUser"));
    } else {
      throw Error("Invalid user role: " + user.role);
    }
  }

  updateInternalUser(user: UpdateInternalUserRequest): Observable<User> {
    return this.apollo
      .mutate({
        mutation: UPDATE_INTERNAL_USER,
        variables: { updateRequest: user },
      })
      .pipe(pluck("data", "updateInternalUser"));
  }

  authorizeInternalUser(
    request: AuthorizeInternalUserRequest[]
  ): Observable<User[]> {
    return this.apollo
      .mutate({
        mutation: AUTHORIZE_INTERNAL_USERS,
        variables: {
          authorizationRequests: request,
        },
      })
      .pipe(pluck("data", "authorizeInternalUsers"));
  }

  updateOwnDetails(request: IdentityRequest): Observable<User> {
    return this.apollo
      .mutate({
        mutation: UPDATE_OWN_DETAILS,
        variables: {
          identity: request,
        },
      })
      .pipe(pluck("data", "updateOwnDetails"));
  }

  deleteUser(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_USER,
      variables: {
        id: id,
      },
    });
  }

  changeUserEnabledStatus(
    id: string,
    oldEnabledState: boolean
  ): Observable<User> {
    if (oldEnabledState) {
      return this.disableUser(id);
    } else {
      return this.enableUser(id);
    }
  }

  private enableUser(id: string): Observable<User> {
    return this.apollo
      .mutate({
        mutation: ENABLE_USER,
        variables: {
          id: id,
        },
      })
      .pipe(pluck("data", "enableUser"));
  }

  private disableUser(id: string): Observable<User> {
    return this.apollo
      .mutate({
        mutation: DISABLE_USER,
        variables: {
          id: id,
        },
      })
      .pipe(pluck("data", "disableUser"));
  }
}
