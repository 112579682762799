import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { FIND_ALL_PAGES_IN_PRICE_SCALE_BY_PRODUCT_IS } from "../graphql/calculatorGql";
import { pluck } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PriceScaleEntryService {
  constructor(private apollo: Apollo) {}

  findAllPagesInPriceScaleByProductId(productId: string): Observable<number[]> {
    return this.apollo
      .query({
        query: FIND_ALL_PAGES_IN_PRICE_SCALE_BY_PRODUCT_IS,
        variables: { productId: productId },
      })
      .pipe(pluck("data", "findAllPagesInPriceScaleByProductId"));
  }
}
