<dx-form
  id="organizationForm"
  labelMode="floating"
  [formData]="organization"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
>
  <dxi-item
    *ngIf="isPublisherGroup || isPublisher"
    dataField="disoNumber"
    [label]="{ text: formatMessage('organization.disoNumber') }"
    editorType="dxTextBox"
    [editorOptions]="{ stylingMode: 'underlined', maxLength: 6 }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      pattern="^[0-9]{1,8}$"
      [message]="formatMessage('validation.numericOnly')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    *ngIf="isPublisher"
    dataField="name"
    [label]="{ text: formatMessage('organization.disoName') }"
    [editorOptions]="{ stylingMode: 'underlined', maxLength: 10 }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="displayName"
    [label]="{ text: formatMessage('organization.displayName') }"
    [editorOptions]="{ stylingMode: 'underlined', maxLength: 50 }"
    [colSpan]="isStudio ? 2 : 1"
    [cssClass]="isStudio ? 'remove-padding' : ''"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    *ngIf="isPublisher"
    dataField="preislistenKatalog"
    [label]="{
      text: formatMessage('organization.publisher.preislistenKatalog')
    }"
    editorType="dxSelectBox"
    [editorOptions]="{
      showClearButton: true,
      items: preislistenKatalogList,
      displayExpr: 'name',
      valueExpr: 'id',
      placeholder: '',
      stylingMode: 'underlined'
    }"
  ></dxi-item>
  <dxi-item
    *ngIf="isPublisher"
    dataField="publisherGroup"
    [label]="{ text: formatMessage('organization.publisher.publisherGroup') }"
    editorType="dxSelectBox"
    [editorOptions]="{
      showClearButton: true,
      items: publisherGroups,
      displayExpr: getPublisherGroupDisplayValue,
      valueExpr: 'id',
      placeholder: '',
      stylingMode: 'underlined'
    }"
  ></dxi-item>
  <dxi-item
    *ngIf="isPublisher && !environmentIsUsa"
    dataField="standardStudios"
    [label]="{ text: formatMessage('organization.publisher.standardStudios') }"
    editorType="dxTagBox"
    [editorOptions]="{
      items: studios,
      displayExpr: 'displayName',
      valueExpr: 'id',
      searchEnabled: true,
      searchExpr: 'displayName',
      placeholder: '',
      stylingMode: 'underlined',
      showSelectionControls: true,
      showDropDownButton: true
    }"
  >
  </dxi-item>
</dx-form>
