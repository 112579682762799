import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import {
  Organization,
  Publisher,
  PublisherGroup,
  Studio,
} from "../model/organization/organization";
import { pluck } from "rxjs/operators";
import {
  OrganizationFormData,
  PublisherForm,
  PublisherGroupForm,
  StudioForm,
} from "../model/organization/organizationFormData";
import {
  CREATE_PUBLISHER,
  CREATE_PUBLISHER_GROUP,
  CREATE_STUDIO,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_GROUP,
  DELETE_STUDIO,
  FIND_ALL_PUBLISHER,
  FIND_ALL_PUBLISHER_GROUPS,
  FIND_ALL_STUDIOS,
  UPDATE_PUBLISHER,
  UPDATE_PUBLISHER_GROUP,
  UPDATE_STUDIO,
  FIND_PUBLISHER_BY_PUBLISHER_GROUP,
} from "../graphql/organizationGql";
import {
  GET_PUBLISHER_BY_ID,
  GET_PUBLISHER_BY_ID_NO_VARIANTS,
} from "../graphql/calculatorGql";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  constructor(private apollo: Apollo) {}

  findAllPublisherGroups(): Observable<PublisherGroup[]> {
    return this.apollo
      .query({
        query: FIND_ALL_PUBLISHER_GROUPS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllPublisherGroups"));
  }

  findAllPublisher(): Observable<Publisher[]> {
    return this.apollo
      .query({
        query: FIND_ALL_PUBLISHER,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllPublisher"));
  }

  findAllStudios(): Observable<Studio[]> {
    return this.apollo
      .query({
        query: FIND_ALL_STUDIOS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllStudios"));
  }

  findPublisherByPublisherGroup(id: string): Observable<Publisher[]> {
    return this.apollo
      .query({
        query: FIND_PUBLISHER_BY_PUBLISHER_GROUP,
        fetchPolicy: "no-cache",
        variables: { publisherGroupId: id },
      })
      .pipe(pluck("data", "findAllPublisherByPublisherGroup"));
  }

  findPublisherByOrganizationId(id: string): Observable<Publisher> {
    return this.apollo
      .query({
        query: GET_PUBLISHER_BY_ID_NO_VARIANTS,
        fetchPolicy: "no-cache",
        variables: { id: id },
      })
      .pipe(pluck("data", "getPublisherNoVariants"));
  }

  createOrganization(
    organization: OrganizationFormData
  ): Observable<Organization> {
    if (organization instanceof PublisherGroupForm) {
      return this.createPublisherGroup(organization);
    } else if (organization instanceof PublisherForm) {
      return this.createPublisher(organization);
    } else if (organization instanceof StudioForm) {
      return this.createStudio(organization);
    } else {
      throw new Error("Invalid organization type.");
    }
  }

  private createPublisherGroup(
    publisherGroup: PublisherGroupForm
  ): Observable<PublisherGroup> {
    return this.apollo
      .mutate({
        mutation: CREATE_PUBLISHER_GROUP,
        variables: {
          disoNumber: publisherGroup.disoNumber,
          displayName: publisherGroup.displayName,
        },
      })
      .pipe(pluck("data", "createPublisherGroup"));
  }

  private createPublisher(publisher: PublisherForm): Observable<Publisher> {
    return this.apollo
      .mutate({
        mutation: CREATE_PUBLISHER,
        variables: {
          disoNumber: publisher.disoNumber,
          name: publisher.name,
          displayName: publisher.displayName,
          publisherGroup: publisher.publisherGroup || null,
          standardStudios: publisher.standardStudios || [],
          preislistenKatalog: publisher.preislistenKatalog || null,
        },
      })
      .pipe(pluck("data", "createPublisher"));
  }

  private createStudio(studio: StudioForm): Observable<Studio> {
    return this.apollo
      .mutate({
        mutation: CREATE_STUDIO,
        variables: {
          displayName: studio.displayName,
        },
      })
      .pipe(pluck("data", "createStudio"));
  }

  updateOrganization(
    id: string,
    organization: OrganizationFormData
  ): Observable<Organization> {
    if (organization instanceof PublisherGroupForm) {
      return this.updatePublisherGroup(id, organization);
    } else if (organization instanceof PublisherForm) {
      return this.updatePublisher(id, organization);
    } else if (organization instanceof StudioForm) {
      return this.updateStudio(id, organization);
    } else {
      throw new Error("Invalid organization type.");
    }
  }

  private updatePublisherGroup(
    id: string,
    publisherGroup: PublisherGroupForm
  ): Observable<PublisherGroup> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PUBLISHER_GROUP,
        variables: {
          id: id,
          disoNumber: publisherGroup.disoNumber,
          displayName: publisherGroup.displayName,
        },
      })
      .pipe(pluck("data", "updatePublisherGroup"));
  }

  private updatePublisher(
    id: string,
    publisher: PublisherForm
  ): Observable<Publisher> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PUBLISHER,
        variables: {
          id: id,
          disoNumber: publisher.disoNumber,
          name: publisher.name,
          displayName: publisher.displayName,
          publisherGroup: publisher.publisherGroup || null,
          standardStudios: publisher.standardStudios || [],
          //standardStudios: publisher.prestandardStudios || [],
          preislistenKatalog: publisher.preislistenKatalog || null,
        },
      })
      .pipe(pluck("data", "updatePublisher"));
  }

  private updateStudio(id: string, studio: StudioForm): Observable<Studio> {
    return this.apollo
      .mutate({
        mutation: UPDATE_STUDIO,
        variables: {
          id: id,
          displayName: studio.displayName,
        },
      })
      .pipe(pluck("data", "updateStudio"));
  }

  deletePublisherGroup(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_PUBLISHER_GROUP,
      variables: {
        id: id,
      },
    });
  }

  deletePublisher(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_PUBLISHER,
      variables: {
        id: id,
      },
    });
  }

  deleteStudio(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_STUDIO,
      variables: {
        id: id,
      },
    });
  }
}
