import { Component, OnInit } from "@angular/core";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-login-sso",
  templateUrl: "./login-sso.component.html",
  styleUrls: ["./login-sso.component.scss"],
})
export class LoginSsoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    Auth.federatedSignIn();
  }
}
