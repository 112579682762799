import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UriHelperService {

  constructor() {
  }

  serializeParameter(obj): string {
    const serializedObject: Array<string> = [];
    for (const parameter in obj) {
      if (obj.hasOwnProperty(parameter)) {
        serializedObject.push(encodeURIComponent(parameter) + "=" + encodeURIComponent(obj[parameter]));
      }
    }
    return serializedObject.join("&");
  };

}
