import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import Auth from "@aws-amplify/auth";
import { environment } from "src/environments/environment";

@Injectable()
export class AwsCognitoAuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(environment.baseUrl + "/api/public/upload")) {
      return next.handle(request);
    } else {
      return from(Auth.currentSession()).pipe(
        switchMap((cognitoUserSession) => {
          this.logTokenIatAndExp(cognitoUserSession);
          let idToken = cognitoUserSession.getIdToken();

          request = request.clone({
            withCredentials: false,
            setHeaders: {
              Authorization: `Bearer ${idToken.getJwtToken()}`,
            },
          });

          return next.handle(request);
        })
      );
    }
  }

  logTokenIatAndExp(cognitoUserSession) {
    if (!environment.production) {
      const json = JSON.parse(
        atob(cognitoUserSession.getIdToken().getJwtToken().split(".")[1])
      );

      var iatDate = new Date(0);
      iatDate.setUTCSeconds(json.iat);

      var expDate = new Date(0);
      expDate.setUTCSeconds(json.exp);
    }
  }
}
