import { Injectable } from "@angular/core";
import { pluck } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  DELETE_ALL_PRODUKTSPEZIFIKATION_BY_PRODUKT,
  DELETE_SELECTED_PRODUKTSPEZIFIKATION_BY_PRODUKT,
  GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT,
  GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT_AND_CATEGORY,
  GET_PRODUKT_SPEZIFIKATION_DEFAULT_VALUE_BY_PRODUKT_AND_CATEGORY,
  SAVE_OR_DELETE_PRODUKTSPEZIFIKATION,
} from "../graphql/calculatorGql";
import { Apollo } from "apollo-angular";
import { ProduktInformation } from "../model/calculation/produktInformation";
import {
  ProduktSpezifikation,
  ProduktSpezifikationCategory,
  ProduktSpezifikationMasterCategory,
  ProduktSpezifikationPermissions,
  ProduktSpezifikationRequest,
} from "../model/calculation/produktSpezifikation";
import { ProduktFormData } from "../model/calculation/produktFormData";
import { ProduktSpezifikationDefaultValue } from "../model/calculation/produktSpezifikationDefaultValue";
import DataSource from "devextreme/data/data_source";
import { PaperService } from "./paper.service";

@Injectable({
  providedIn: "root",
})
export class ProduktSpezifikationService {
  get produktSpezifikationTree(): ProduktSpezifikationMasterCategory[] {
    return this._produktSpezifikationTree;
  }

  set produktSpezifikationTree(value: ProduktSpezifikationMasterCategory[]) {
    this._produktSpezifikationTree = value;
  }

  get selectedProduktSpezifikationCategory(): ProduktSpezifikationCategory {
    return this._selectedProduktSpezifikationCategory;
  }

  set selectedProduktSpezifikationCategory(
    value: ProduktSpezifikationCategory
  ) {
    this._selectedProduktSpezifikationCategory = value;
  }

  private _produktSpezifikationTree: ProduktSpezifikationMasterCategory[];
  private _selectedProduktSpezifikationCategory: ProduktSpezifikationCategory;

  constructor(private apollo: Apollo, private paperService: PaperService) {}

  loadProduktSpezifikationValues(produktFormData: ProduktFormData): void {
    try {
      this.produktSpezifikationTree.forEach((masterCategory) => {
        masterCategory.items.forEach((category) => {
          category.value = null;

          if (
            category.masterCategory === "INHALT" &&
            category.category === "PAPIER"
          ) {
            this.paperService
              .findAllPaperDefinitionByProdukt(produktFormData.id)
              .subscribe((papers) => {
                papers.forEach((paper) => {
                  if (category.value === null) {
                    category.value = paper.id;
                  } else {
                    category.value = category.value + ";" + paper.id;
                  }
                });
              });
          } else {
            this.findProduktSpezifikation(category, produktFormData).subscribe(
              (selectedValue) => {
                if (selectedValue) {
                  category.value = selectedValue.value;
                }
              }
            );

            this.findProduktSpezifikationDefaultValues(
              category,
              produktFormData
            ).subscribe((defaultValues) => {
              if (defaultValues) {
                category.defaultValues = defaultValues;
                if (
                  this.selectedProduktSpezifikationCategory?.masterCategory ===
                    "VOR_UND_NACHSATZ" &&
                  this.selectedProduktSpezifikationCategory?.category ===
                    "FARBIGKEIT"
                ) {
                  category.datasource = new DataSource({
                    store: {
                      data: category.defaultValues,
                      type: "array",
                      key: "value",
                    },
                    sort: function (e) {
                      if (e.value == "unbedruckt") return "!";
                      if (e.value == "unprinted") return "!";
                      else return e.value;
                    },
                  });
                } else {
                  category.datasource = new DataSource({
                    store: {
                      data: category.defaultValues,
                      type: "array",
                      key: "value",
                    },
                  });
                }
              }
            });
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  loadProduktSpezifikationMasterCategories(
    produktFormData: ProduktFormData
  ): void {
    console.log("loadProduktSpezifikationMasterCategories");
    this.produktSpezifikationTree = this.getProduktSpezifikationCategories(
      produktFormData.produktType
    );

    if (this.produktSpezifikationTree[0])
      this.selectedProduktSpezifikationCategory =
        this.produktSpezifikationTree[0].items[0];
  }

  changeSelection(e) {
    console.log(e.itemData);
    const produktSpezifikation = e.itemData;

    if (produktSpezifikation.text) {
      this.selectedProduktSpezifikationCategory = e.itemData;
    }
  }

  findProduktSpezifikationDefaultValues(
    produktSpezifikationCategory: ProduktSpezifikationCategory,
    produkt: ProduktFormData
  ): Observable<ProduktSpezifikationDefaultValue[]> {
    return this.apollo
      .query({
        query: GET_PRODUKT_SPEZIFIKATION_DEFAULT_VALUE_BY_PRODUKT_AND_CATEGORY,
        fetchPolicy: "no-cache",
        variables: {
          produktId: produkt.id,
          masterCategory: produktSpezifikationCategory.masterCategory,
          category: produktSpezifikationCategory.category,
        },
      })
      .pipe(
        pluck(
          "data",
          "findProduktSpezifikationDefaultValuesByProduktIdAndMasterCategoryAndCategory"
        )
      );
  }

  findProduktSpezifikation(
    produktSpezifikationCategory: ProduktSpezifikationCategory,
    produkt: ProduktFormData
  ): Observable<ProduktSpezifikation> {
    return this.apollo
      .query({
        query: GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT_AND_CATEGORY,
        fetchPolicy: "no-cache",
        variables: {
          produktId: produkt.id,
          masterCategory: produktSpezifikationCategory.masterCategory,
          category: produktSpezifikationCategory.category,
        },
      })
      .pipe(
        pluck(
          "data",
          "findProduktSpezifikationenByProduktIdAndMasterCategoryAndCategory"
        )
      );
  }

  getProduktInformationen(
    produktId: string,
    language: string
  ): Observable<ProduktInformation> {
    return this.apollo
      .query({
        query: GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT,
        fetchPolicy: "no-cache",
        variables: {
          produktId: produktId,
          language: language,
        },
      })
      .pipe(pluck("data", "getProduktInformationen"));
  }
  getProduktSpezifikationCategories(
    produktType: string
  ): ProduktSpezifikationMasterCategory[] {
    var returnValue: ProduktSpezifikationMasterCategory[] = [];

    for (let i = 0; i < produktSpezifikationCategories.length; i++) {
      if (
        this.checkMasterCategoryPermission(
          produktType,
          produktSpezifikationCategories[i].masterCategory
        )
      ) {
        let master: ProduktSpezifikationMasterCategory =
          new ProduktSpezifikationMasterCategory();
        master.id = produktSpezifikationCategories[i].id;
        master.masterCategory =
          produktSpezifikationCategories[i].masterCategory;
        master.text = produktSpezifikationCategories[i].text;
        master.expanded = produktSpezifikationCategories[i].expanded;
        master.selected = produktSpezifikationCategories[i].selected;
        master.items = [];

        for (
          let j = 0;
          j < produktSpezifikationCategories[i].items.length;
          j++
        ) {
          if (
            this.checkCategoryPermission(
              produktType,
              produktSpezifikationCategories[i].masterCategory,
              produktSpezifikationCategories[i].items[j].category
            )
          ) {
            master.items.push(produktSpezifikationCategories[i].items[j]);
          }
        }
        returnValue.push(master);
      }
    }

    return returnValue;
  }

  deleteProduktSpezifikationByProdukt(produktId: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_ALL_PRODUKTSPEZIFIKATION_BY_PRODUKT,
      variables: {
        produktId: produktId,
      },
    });
  }

  deleteSelectedProduktSpezifikationByProdukt(
    produktId: string,
    masterCategory: string,
    category: string
  ): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_SELECTED_PRODUKTSPEZIFIKATION_BY_PRODUKT,
      variables: {
        produktId: produktId,
        masterCategory: masterCategory,
        category: category,
      },
    });
  }

  checkMasterCategoryPermission(
    produktType: string,
    masterCategory: string
  ): boolean {
    var permission = false;

    produktSpezifikationPermissions.forEach(
      (produktSpezifikationPermission) => {
        if (produktSpezifikationPermission.produktType === produktType) {
          produktSpezifikationPermission.masterCategoryPermission.forEach(
            (masterCategoryPermission) => {
              if (masterCategoryPermission.masterCategory === masterCategory) {
                permission = masterCategoryPermission.visibility;
              }
            }
          );
        }
      }
    );
    return permission;
  }

  checkCategoryPermission(
    produktType: string,
    masterCategory: string,
    category: string
  ): boolean {
    var permission = false;

    produktSpezifikationPermissions.forEach(
      (produktSpezifikationPermission) => {
        if (produktSpezifikationPermission.produktType === produktType) {
          produktSpezifikationPermission.masterCategoryPermission.forEach(
            (masterCategoryPermission) => {
              if (masterCategoryPermission.masterCategory === masterCategory) {
                masterCategoryPermission.categoryPermission.forEach(
                  (categoryPermission) => {
                    if (categoryPermission.category === category) {
                      permission = categoryPermission.visibility;
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
    return permission;
  }

  saveOrDeleteProduktSpezifikation(
    produktSpezifikationRequest: ProduktSpezifikationRequest[]
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: SAVE_OR_DELETE_PRODUKTSPEZIFIKATION,
        variables: {
          produktSpezifikationen: produktSpezifikationRequest,
        },
      })
      .pipe(pluck("data", "saveOrDeleteProduktSpezifikation"));
  }
}

const produktSpezifikationCategories: ProduktSpezifikationMasterCategory[] = [
  {
    id: "1",
    masterCategory: "INHALT",
    text: "Inhalt",
    expanded: true,
    items: [
      <ProduktSpezifikationCategory>{
        id: "1_1",
        text: "Farbigkeit",
        selected: true,
        content: "EditBox",
        masterCategory: "INHALT",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "1_2",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "INHALT",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "1_3",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "INHALT",
        category: "PAPIER",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "1_4",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "INHALT",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "2",
    masterCategory: "VOR_UND_NACHSATZ",
    text: "Vor- und Nachsatz",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "2_1",
        text: "Seitenanzahl",
        content: "EditBox",
        masterCategory: "VOR_UND_NACHSATZ",
        category: "SEITENANZAHL",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "2_2",
        text: "Farbigkeit",
        content: "EditBox",
        masterCategory: "VOR_UND_NACHSATZ",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "2_3",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "VOR_UND_NACHSATZ",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "2_4",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "VOR_UND_NACHSATZ",
        category: "PAPIER",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "2_5",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "VOR_UND_NACHSATZ",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "3",
    masterCategory: "BROSCHURUMSCHLAG",
    text: "Broschurumschlag",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "3_1",
        text: "Seitenanzahl",
        content: "EditBox",
        masterCategory: "BROSCHURUMSCHLAG",
        category: "SEITENANZAHL",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "3_2",
        text: "Farbigkeit",
        content: "EditBox",
        masterCategory: "BROSCHURUMSCHLAG",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "3_3",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "BROSCHURUMSCHLAG",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "3_4",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "BROSCHURUMSCHLAG",
        category: "PAPIER",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "3_5",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "BROSCHURUMSCHLAG",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "4",
    masterCategory: "BEZUG",
    text: "Bezug",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "4_1",
        text: "Farbigkeit",
        content: "EditBox",
        masterCategory: "BEZUG",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "4_2",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "BEZUG",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "4_3",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "BEZUG",
        category: "PAPIER",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "4_4",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "BEZUG",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "5",
    masterCategory: "SCHUTZUMSCHLAG",
    text: "Schutzumschlag",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "5_1",
        text: "Seitenanzahl",
        content: "EditBox",
        masterCategory: "SCHUTZUMSCHLAG",
        category: "SEITENANZAHL",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "5_2",
        text: "Farbigkeit",
        content: "EditBox",
        masterCategory: "SCHUTZUMSCHLAG",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "5_3",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "SCHUTZUMSCHLAG",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "5_4",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "SCHUTZUMSCHLAG",
        category: "PAPIER",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "5_5",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "SCHUTZUMSCHLAG",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "6",
    masterCategory: "SONSTIGES_TEIL",
    text: "Sonstiges Teil",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "6_1",
        text: "Bezeichnung",
        content: "EditBox",
        masterCategory: "SONSTIGES_TEIL",
        category: "BEZEICHNUNG",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "6_2",
        text: "Seitenanzahl",
        content: "EditBox",
        masterCategory: "SONSTIGES_TEIL",
        category: "SEITENANZAHL",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "6_3",
        text: "Farbigkeit",
        content: "EditBox",
        masterCategory: "SONSTIGES_TEIL",
        category: "FARBIGKEIT",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "6_4",
        text: "Farbart",
        content: "EditBox",
        masterCategory: "SONSTIGES_TEIL",
        category: "FARBART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "6_5",
        text: "Papier",
        content: "PaperGrid",
        masterCategory: "SONSTIGES_TEIL",
        category: "PAPIER",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "6_6",
        text: "Veredelung",
        content: "EditBox",
        masterCategory: "SONSTIGES_TEIL",
        category: "VEREDELUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "7",
    masterCategory: "WEITERVERARBEITUNG",
    text: "Weiterverarbeitung",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "7_1",
        text: "Bindeart",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "BINDEART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_2",
        text: "Leimart",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "LEIMART",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_3",
        text: "Kapitalband",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "KAPITALBAND",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_4",
        text: "Deckelpappendicke",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "DECKELPAPPENDICKE",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_5",
        text: "Deckenprägung",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "DECKENPRÄGUNG",
        paperSingleSelect: true,
      },
      <ProduktSpezifikationCategory>{
        id: "7_6",
        text: "Rückenform",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "RÜCKENFORM",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_7",
        text: "Rillung",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "RILLUNG",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "7_8",
        text: "Sonstige WV",
        content: "EditBox",
        masterCategory: "WEITERVERARBEITUNG",
        category: "SONSTIGE_WV",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "8",
    masterCategory: "VERPACKUNG_UND_VERSAND",
    text: "Verpackung und Versand",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "8_1",
        text: "Etikettierung",
        content: "EditBox",
        masterCategory: "VERPACKUNG_UND_VERSAND",
        category: "ETIKETTIERUNG",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "8_2",
        text: "Verpackung",
        content: "EditBox",
        masterCategory: "VERPACKUNG_UND_VERSAND",
        category: "VERPACKUNG",
        paperSingleSelect: false,
      },
      <ProduktSpezifikationCategory>{
        id: "8_3",
        text: "Lieferung",
        content: "EditBox",
        masterCategory: "VERPACKUNG_UND_VERSAND",
        category: "LIEFERUNG",
        paperSingleSelect: false,
      },
    ],
  },
  {
    id: "9",
    masterCategory: "SONSTIGES",
    text: "Sonstiges",
    expanded: false,
    items: [
      <ProduktSpezifikationCategory>{
        id: "9_1",
        text: "Bezeichnung",
        content: "EditBox",
        masterCategory: "SONSTIGES",
        category: "BEZEICHNUNG",
        paperSingleSelect: false,
      },
    ],
  },
];

const produktSpezifikationPermissions: ProduktSpezifikationPermissions[] = [
  {
    produktType: "HARDCOVER_OHNE_SCHUTZUMSCHLAG",
    masterCategoryPermission: [
      {
        masterCategory: "INHALT",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "VOR_UND_NACHSATZ",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BROSCHURUMSCHLAG",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BEZUG",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SCHUTZUMSCHLAG",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "SONSTIGES_TEIL",
        visibility: true,
        categoryPermission: [
          { category: "BEZEICHNUNG", visibility: true },
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "WEITERVERARBEITUNG",
        visibility: true,
        categoryPermission: [
          { category: "BINDEART", visibility: true },
          { category: "LEIMART", visibility: true },
          { category: "KAPITALBAND", visibility: true },
          { category: "DECKELPAPPENDICKE", visibility: true },
          { category: "DECKENPRÄGUNG", visibility: true },
          { category: "RÜCKENFORM", visibility: true },
          { category: "RILLUNG", visibility: false },
          { category: "SONSTIGE_WV", visibility: true },
        ],
      },
      {
        masterCategory: "VERPACKUNG_UND_VERSAND",
        visibility: true,
        categoryPermission: [
          { category: "ETIKETTIERUNG", visibility: true },
          { category: "VERPACKUNG", visibility: true },
          { category: "LIEFERUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES",
        visibility: true,
        categoryPermission: [{ category: "BEZEICHNUNG", visibility: true }],
      },
    ],
  },

  {
    produktType: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
    masterCategoryPermission: [
      {
        masterCategory: "INHALT",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "VOR_UND_NACHSATZ",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BROSCHURUMSCHLAG",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BEZUG",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SCHUTZUMSCHLAG",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES_TEIL",
        visibility: true,
        categoryPermission: [
          { category: "BEZEICHNUNG", visibility: true },
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "WEITERVERARBEITUNG",
        visibility: true,
        categoryPermission: [
          { category: "BINDEART", visibility: true },
          { category: "LEIMART", visibility: true },
          { category: "KAPITALBAND", visibility: true },
          { category: "DECKELPAPPENDICKE", visibility: true },
          { category: "DECKENPRÄGUNG", visibility: true },
          { category: "RÜCKENFORM", visibility: true },
          { category: "RILLUNG", visibility: false },
          { category: "SONSTIGE_WV", visibility: true },
        ],
      },
      {
        masterCategory: "VERPACKUNG_UND_VERSAND",
        visibility: true,
        categoryPermission: [
          { category: "ETIKETTIERUNG", visibility: true },
          { category: "VERPACKUNG", visibility: true },
          { category: "LIEFERUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES",
        visibility: true,
        categoryPermission: [{ category: "BEZEICHNUNG", visibility: true }],
      },
    ],
  },

  {
    produktType: "SOFTCOVER",
    masterCategoryPermission: [
      {
        masterCategory: "INHALT",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "VOR_UND_NACHSATZ",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BROSCHURUMSCHLAG",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "BEZUG",
        visibility: false,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "SCHUTZUMSCHLAG",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "SONSTIGES_TEIL",
        visibility: true,
        categoryPermission: [
          { category: "BEZEICHNUNG", visibility: true },
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "WEITERVERARBEITUNG",
        visibility: true,
        categoryPermission: [
          { category: "BINDEART", visibility: true },
          { category: "LEIMART", visibility: true },
          { category: "KAPITALBAND", visibility: false },
          { category: "DECKELPAPPENDICKE", visibility: false },
          { category: "DECKENPRÄGUNG", visibility: false },
          { category: "RÜCKENFORM", visibility: false },
          { category: "RILLUNG", visibility: true },
          { category: "SONSTIGE_WV", visibility: true },
        ],
      },
      {
        masterCategory: "VERPACKUNG_UND_VERSAND",
        visibility: true,
        categoryPermission: [
          { category: "ETIKETTIERUNG", visibility: true },
          { category: "VERPACKUNG", visibility: true },
          { category: "LIEFERUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES",
        visibility: true,
        categoryPermission: [{ category: "BEZEICHNUNG", visibility: true }],
      },
    ],
  },

  {
    produktType: "SOFTCOVER_MIT_KLAPPEN",
    masterCategoryPermission: [
      {
        masterCategory: "INHALT",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "VOR_UND_NACHSATZ",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "BROSCHURUMSCHLAG",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "BEZUG",
        visibility: false,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "SCHUTZUMSCHLAG",
        visibility: false,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: false },
          { category: "FARBIGKEIT", visibility: false },
          { category: "FARBART", visibility: false },
          { category: "PAPIER", visibility: false },
          { category: "VEREDELUNG", visibility: false },
        ],
      },
      {
        masterCategory: "SONSTIGES_TEIL",
        visibility: true,
        categoryPermission: [
          { category: "BEZEICHNUNG", visibility: true },
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "WEITERVERARBEITUNG",
        visibility: true,
        categoryPermission: [
          { category: "BINDEART", visibility: true },
          { category: "LEIMART", visibility: true },
          { category: "KAPITALBAND", visibility: false },
          { category: "DECKELPAPPENDICKE", visibility: false },
          { category: "DECKENPRÄGUNG", visibility: false },
          { category: "RÜCKENFORM", visibility: false },
          { category: "RILLUNG", visibility: true },
          { category: "SONSTIGE_WV", visibility: true },
        ],
      },
      {
        masterCategory: "VERPACKUNG_UND_VERSAND",
        visibility: true,
        categoryPermission: [
          { category: "ETIKETTIERUNG", visibility: true },
          { category: "VERPACKUNG", visibility: true },
          { category: "LIEFERUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES",
        visibility: true,
        categoryPermission: [{ category: "BEZEICHNUNG", visibility: true }],
      },
    ],
  },

  {
    produktType: "SONSTIGES",
    masterCategoryPermission: [
      {
        masterCategory: "INHALT",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "VOR_UND_NACHSATZ",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "BROSCHURUMSCHLAG",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "BEZUG",
        visibility: true,
        categoryPermission: [
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SCHUTZUMSCHLAG",
        visibility: true,
        categoryPermission: [
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES_TEIL",
        visibility: true,
        categoryPermission: [
          { category: "BEZEICHNUNG", visibility: true },
          { category: "SEITENANZAHL", visibility: true },
          { category: "FARBIGKEIT", visibility: true },
          { category: "FARBART", visibility: true },
          { category: "PAPIER", visibility: true },
          { category: "VEREDELUNG", visibility: true },
        ],
      },
      {
        masterCategory: "WEITERVERARBEITUNG",
        visibility: true,
        categoryPermission: [
          { category: "BINDEART", visibility: true },
          { category: "LEIMART", visibility: true },
          { category: "KAPITALBAND", visibility: true },
          { category: "DECKELPAPPENDICKE", visibility: true },
          { category: "DECKENPRÄGUNG", visibility: true },
          { category: "RÜCKENFORM", visibility: true },
          { category: "RILLUNG", visibility: true },
          { category: "SONSTIGE_WV", visibility: true },
        ],
      },
      {
        masterCategory: "VERPACKUNG_UND_VERSAND",
        visibility: true,
        categoryPermission: [
          { category: "ETIKETTIERUNG", visibility: true },
          { category: "VERPACKUNG", visibility: true },
          { category: "LIEFERUNG", visibility: true },
        ],
      },
      {
        masterCategory: "SONSTIGES",
        visibility: true,
        categoryPermission: [{ category: "BEZEICHNUNG", visibility: true }],
      },
    ],
  },
];
