export class Schutzumschlag {
  produktTeil: string;
  language: string;
  records: SchutzumschlagRecords[];
}

export class SchutzumschlagRecords {
  produktTeilKnz: string;
  nutzenBreite: string;
  nutzenHoehe: string;
  formatBreite: string;
  rueckenBreite: string;
  anschnitt: string;
  klappenBreite: string;
  fondEinschlag: string;
  textAbstand: string;
  bezeichnung: string;
  produktTyp: string;
  endFormat: string;
  umfang: string;
  inhaltsPapier: string;
  bindeArt: string;
  rueckenForm: string;
  pappenDicke: string;
  umfangBildTeil: string;
  papierBildTeil: string;
  deckelKanteA: string;
  deckelKanteOU: string;
  datum: string;
}

export class CalculatedSchutzumschlagRecord {
  nutzenBreite: number;
  nutzenHoehe: number;
  formatBreite: number;
  rueckenBreite: number;
  klappenBreite: number;
}
