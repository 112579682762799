<div class="grid-shadow">
  <dx-data-grid
    id="calculations-grid"
    class="wide-card"
    [dataSource]="calculations"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [allowColumnResizing]="false"
    (onRowDblClick)="showDetails($event.data)"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxi-column
      dataField="publisherName"
      [caption]="formatMessage('calculations.list.publisher')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="title"
      [caption]="formatMessage('calculations.list.title')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="orderNumber"
      [caption]="formatMessage('calculations.list.orderNumber')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="productName"
      [caption]="formatMessage('calculations.list.productType')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="bookFormat"
      [caption]="formatMessage('calculations.list.bookFormat')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="volume"
      [caption]="formatMessage('calculations.list.volume')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      dataField="editionCounter"
      [caption]="formatMessage('calculations.list.editionCounter')"
      alignment="left"
    ></dxi-column>

    <dxi-column
      [allowHeaderFiltering]="false"
      [showInColumnChooser]="false"
      [caption]="formatMessage('calculations.state')"
      cellTemplate="cellTemplate"
    >
    </dxi-column>

    <div *dxTemplate="let row of 'cellTemplate'">
      <div *ngIf="row.data.structureChanged; else checkPriceChange">
        <i
          style="color: Tomato"
          id="structureChanged"
          class="dx-icon-remove"
        ></i>
        <dx-tooltip
          target="#structureChanged"
          showEvent="mouseenter"
          hideEvent="mouseleave"
        >
          <div *dxTemplate="let row = row; of: 'content'">
            {{ formatMessage("calculations.state.structureChange") }}
          </div>
        </dx-tooltip>
      </div>
      <ng-template #checkPriceChange>
        <div *ngIf="row.data.pricesChanged; else stateOK">
          <i style="color: Gold" id="pricesChanged" class="dx-icon-info"></i>
          <dx-tooltip
            target="#pricesChanged"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.state.priceChange") }}
            </div>
          </dx-tooltip>
        </div>
        <ng-template #stateOK>
          <i style="color: ForestGreen" id="stateOK" class="dx-icon-check"></i>
          <dx-tooltip
            target="#stateOK"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.ok") }}
            </div>
          </dx-tooltip>
        </ng-template>
      </ng-template>
    </div>
  </dx-data-grid>
</div>
