<div class="widget-container d-flex flex-grow flex-column">
  <div
    id="dropzone-external"
    class="d-flex flex-grow"
    style="font-size: 14px"
    [ngClass]="
      isDropZoneActive
        ? ['dx-theme-accent-as-border-color', 'dropzone-active']
        : ['dx-theme-border-color']
    "
  >
    <div
      class="
        d-flex
        flex-column flex-grow
        dropzone-text
        justify-content-center
        align-items-center
        text-center
      "
    >
      <app-alert
        *ngIf="successAlertMessage"
        type="success"
        [message]="successAlertMessage"
        icon="material-icons mdi-success"
        [style]="isLargeOrUp ? 'margin-bottom: 30px' : 'margin-bottom: 20px'"
      ></app-alert>

      <span *ngIf="!isUploading">{{
        formatMessage("upload.dragAndDrop")
      }}</span>
      <span *ngIf="!isUploading">{{
        formatMessage("upload.fileChooser")
      }}</span>

      <span
        *ngIf="invalidFileMessage"
        class="error-message"
        style="margin-top: 20px"
        >{{ invalidFileMessage }}</span
      >

      <dx-progress-bar
        class="upload-progress"
        width="30%"
        [min]="0"
        [max]="100"
        [showStatus]="false"
        [visible]="progressVisible"
        [value]="progressValue"
      ></dx-progress-bar>

      <app-alert
        *ngIf="!hasNetworkConnection && !invalidFileMessage"
        type="error"
        icon="dx-icon-warning"
        [message]="formatMessage('upload.noNetwork')"
        [style]="isLargeOrUp ? 'margin-top: 30px' : 'margin-top: 20px'"
      ></app-alert>

      <app-alert
        *ngIf="showReconnectedToNetwork && isUploading"
        type="error"
        [multiLine]="true"
        icon="dx-icon-pulldown"
        [message]="formatMessage('upload.networkReconnect')"
        [style]="isLargeOrUp ? 'margin: 30px 60px 0 60px' : 'margin-top: 20px'"
        style="cursor: pointer"
        (click)="refresh()"
      ></app-alert>
    </div>
  </div>
  <dx-file-uploader
    dialogTrigger="#dropzone-external"
    dropZone="#dropzone-external"
    [readOnly]="isUploading"
    [multiple]="false"
    [allowedFileExtensions]="['.xlsx']"
    uploadMode="useButtons"
    uploadMethod="PUT"
    name="file"
    [visible]="false"
    [uploadHeaders]="{
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }"
    (onDropZoneEnter)="onDropZoneEnter($event)"
    (onDropZoneLeave)="onDropZoneLeave($event)"
    (onValueChanged)="onSelectedFileChanged()"
    (onProgress)="onProgress($event)"
    (onUploadStarted)="onUploadStarted($event)"
    (onUploadError)="onUploadError($event)"
    (click)="onDropZoneClick($event)"
  ></dx-file-uploader>
</div>
