<div class="user-panel">
  <div class="user-info">
    <div class="image-container">
      <i class="user-icon dx-icon-user"></i>
    </div>
    <div class="user-name">{{ user?.identity?.email }}</div>
  </div>
  <dx-context-menu
    *ngIf="menuMode === 'context'"
    [items]="menuItems"
    target=".user-button"
    showEvent="dxclick"
    cssClass="user-menu"
    [width]="setContextMenuWidth()"
  >
    <dxo-position my="top" at="bottom" [of]="userButton"></dxo-position>
  </dx-context-menu>
  <dx-list
    *ngIf="menuMode === 'list'"
    class="dx-toolbar-menu-action"
    [items]="menuItems"
  >
  </dx-list>
</div>
