import { Component, Input, NgModule, OnInit, ViewChild } from "@angular/core";
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import {
  DxiColumnModule,
  DxoEditingModule,
  DxoPagingModule,
} from "devextreme-angular/ui/nested";
import { OptionFormData } from "../../../../model/calculation/optionFormData";

@Component({
  selector: "app-option-data-grid-surcharge",
  templateUrl: "./option-data-grid-surcharge.component.html",
  styleUrls: ["./option-data-grid-surcharge.component.scss"],
})
export class OptionDataGridSurchargeComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @Input() optionData: OptionFormData;

  allowAdding: boolean;
  constructor() {
    this.addSurchargePossible = this.addSurchargePossible.bind(this);
  }

  ngOnInit(): void {}
  addSurchargePossible(e) {
    this.allowAdding = this.dataGrid.instance.totalCount() < 1;
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    DxiColumnModule,
    DxoEditingModule,
    DxoPagingModule,
  ],
  declarations: [OptionDataGridSurchargeComponent],
  exports: [OptionDataGridSurchargeComponent],
})
export class OptionDataGridSurchargeModule {}
