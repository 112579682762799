import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  PreislistenKatalogFormComponent,
  PreislistenKatalogFormModule,
} from "../preislistenKatalog-form/preislistenKatalog-form.component";
import { PreislistenKatalogFormData } from "../../../model/calculation/preislistenKatalogFormData";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { PreislistenKatalog } from "../../../model/calculation/produkt";

@Component({
  selector: "app-preislistenKatalog-form-popup",
  templateUrl: "./preislistenKatalog-form-popup.component.html",
  styleUrls: ["./preislistenKatalog-form-popup.component.scss"],
})
export class PreislistenKatalogFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(PreislistenKatalogFormComponent)
  preislistenKatalogForm: PreislistenKatalogFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  @Input() visible: boolean;
  @Input() preislistenKatalog: PreislistenKatalogFormData;
  @Input() creating: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<PreislistenKatalogFormData>();

  title: string;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.creating) {
      this.title = formatMessage("preislistenKatalog.create");
    } else {
      this.title = formatMessage("preislistenKatalog.update");
    }
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetPreislistenKatalogForm(): void {
    this.preislistenKatalogForm.resetValues();
  }

  resetRoleValidation(): void {
    setTimeout(
      () =>
        this.preislistenKatalogForm?.form?.instance
          .getEditor("role")
          ?.option("isValid", true),
      1
    );
  }

  save(): void {
    if (!this.preislistenKatalogForm.isValid()) {
      return;
    }

    this.onSave.emit(this.preislistenKatalog);

    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    PreislistenKatalogFormModule,
    ActionPopupModule,
  ],
  declarations: [PreislistenKatalogFormPopupComponent],
  exports: [PreislistenKatalogFormPopupComponent],
})
export class PreislistenKatalogFormPopupModule {}
