import {
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../model/order/order";
import { OrderService } from "../../services/order.service";
import { Router } from "@angular/router";
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { P3AuthService } from "../../services";
import {
  DxButtonModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
} from "devextreme-angular";
import { combineLatest, Observable, Subscription } from "rxjs";
import { BrowserModule } from "@angular/platform-browser";
import { OrderDetailsAgencyComponent } from "./order-details-agency/order-details-agency.component";
import { OrderDetailsDocumentsComponent } from "./order-details-documents/order-details-documents.component";
import { OrderDetailsLoggingComponent } from "./order-details-logging/order-details-logging.component";
import { KachelModule } from "../kachel/kachel.component";
import { OrganizationUnionTagModule } from "../tags/organization-union-tag/organization-union-tag.component";
import { OrganizationTagModule } from "../tags/organization-tag/organization-tag.component";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { OrderDetailsPropertiesComponent } from "./order-details-properties/order-details-properties.component";
import { ReturnButtonModule } from "../return-button/return-button.component";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit, OnChanges, OnDestroy {
  formatMessage = formatMessage;
  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;

  @Input() orderId: string;
  productType: string;

  order$: Observable<Order>;
  currentOrder: Order;

  subscriptions: Array<Subscription> = [];
  isAllowedToViewD3Documents: boolean;
  isAllowedToViewLogging: boolean;

  loading: boolean = true;
  showToast: boolean = false;
  errorMessage: string = "Error";

  constructor(
    public orderService: OrderService,
    private router: Router,
    private _p3AuthService: P3AuthService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  ngOnInit(): void {
    const isAllowedToViewD3DocumentsSubscription = this._p3AuthService
      .isAllowedToViewD3Documents()
      .subscribe((data) => {
        this.isAllowedToViewD3Documents = data;
      });

    const isAllowedToViewLoggingSubscription = this._p3AuthService
      .isAllowedToViewLogging()
      .subscribe((data) => {
        this.isAllowedToViewLogging = data;
      });

    this.subscriptions.push(
      isAllowedToViewD3DocumentsSubscription,
      isAllowedToViewLoggingSubscription
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("orderId" in changes) {
      this.orderId = changes["orderId"].currentValue;
      this.loading = true;
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  loadData() {
    this.order$ = this.orderService.getOrder(this.orderId);

    let orderSubscription = combineLatest([this.order$]).subscribe(
      ([order]) => {
        this.currentOrder = order;

        this.productType = this.orderService.mapCategoryToProductType(
          order.order.titleinfo.category
        );

        this.loading = false;
      },
      (error) => {
        if (error.message === "NotFoundException") {
          this.errorMessage = formatMessage("order.details.orderNotFound");
        } else {
          this.errorMessage = formatMessage("order.details.error");
        }

        this.showToast = true;

        this.loading = false;
      }
    );

    this.subscriptions.push(orderSubscription);
  }

  hasCurrentOrderValidCategory(): boolean {
    if (this.currentOrder) {
      return this.orderService.isValidCategory(
        this.currentOrder.order.titleinfo.category
      );
    } else {
      return true;
    }
  }

  navigateToUploadFromDetails(): void {
    if (this.hasCurrentOrderValidCategory()) {
      this.router.navigate([`/upload/${this.currentOrder.id}`]);
      this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
    }
  }
}

@NgModule({
  imports: [
    DxToastModule,
    DxTextBoxModule,
    BrowserModule,
    DxTextAreaModule,
    KachelModule,
    OrganizationUnionTagModule,
    OrganizationTagModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxLoadIndicatorModule,
    ReturnButtonModule,
  ],
  declarations: [
    OrderDetailsComponent,
    OrderDetailsAgencyComponent,
    OrderDetailsDocumentsComponent,
    OrderDetailsLoggingComponent,
    OrderDetailsPropertiesComponent,
  ],
  exports: [OrderDetailsComponent],
})
export class OrderDetailsModule {}
