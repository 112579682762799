import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RumErrorTypes } from "../../rumMonitorConfiguration";
import { FileUploaderComponent } from "../components/upload-data/file-uploader/file-uploader.component";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  private xmlHttpRequest: XMLHttpRequest;

  static orderId: string;
  static orderPiece: string;
  static fileName: string;
  static uploadUrl: string;

  // State Observables
  static isUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  static progressVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  static progressValue: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  public constructor() {}

  prepareAndUpload(file: any, http: HttpClient, url: string) {
    // Check if File Size is > 0
    if (file?.value.size > 0) {
      const xmlHttpRequest = this.getXmlHttpRequest();

      // On Upload start
      xmlHttpRequest.upload.addEventListener("loadstart", function (ev) {
        FileUploadService.isUploading.next(true);
        FileUploadService.progressVisible.next(true);
      });

      // While Upload
      xmlHttpRequest.upload.addEventListener("progress", function (ev) {
        FileUploadService.progressValue.next((ev.loaded / ev.total) * 100);
      });

      // event listener for success or error cant be used
      // because it does not check the final http status code
      // therefore readyState 4 is Used --> The request is complete, and the response is ready for processing
      xmlHttpRequest.onreadystatechange = function (oEvent) {
        if (xmlHttpRequest.readyState === 4) {
          // After success Upload
          if (xmlHttpRequest.status === 200) {
            FileUploaderComponent.successHandler(file.value);
          }
          // After failed/error Upload
          else {
            const url = `${environment.baseUrl}/api/upload/${FileUploadService.orderId}/${FileUploadService.orderPiece}/${FileUploadService.fileName}`;
            http
              .delete<void>(url)
              .toPromise()
              .catch((error) => {});

            let awsRumErrorObj = {
              errorStatus: xmlHttpRequest.status,
              errorMessage: xmlHttpRequest.statusText,
              requestUrl: FileUploaderComponent._uploadUrl,
              fileDetails: file.value,
            };
            FileUploaderComponent.errorHandler(file.value);
            FileUploaderComponent.errorHandlerAwsRum(
              RumErrorTypes.S3_REQUEST_ERROR,
              awsRumErrorObj
            );
          }
          FileUploadService.isUploading.next(false);
          FileUploadService.progressVisible.next(false);
          FileUploadService.progressValue.next(0);
        }
      };

      // request Configuration
      xmlHttpRequest.open("PUT", url, true);

      file.request = xmlHttpRequest;

      file.request.send(file.value);
    } else {
      FileUploaderComponent.errorHandler(file.value);
      FileUploaderComponent.errorHandlerAwsRum(
        RumErrorTypes.UPLOAD_FILE_VALUE_ERROR,
        {
          error: "File Value 0",
          fileDetails: file.value,
        }
      );
    }
  }

  private getXmlHttpRequest(): XMLHttpRequest {
    if (!this.xmlHttpRequest) {
      this.xmlHttpRequest = new XMLHttpRequest();
    }
    return this.xmlHttpRequest;
  }
}
