<!--Alter Stand-->
<div class="grid-shadow">
  <dx-data-grid
    id="calculations-grid"
    class="dx-card wide-card"
    [dataSource]="dataSource"
    [showBorders]="false"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [allowColumnResizing]="false"
    [allowColumnReordering]="true"
    (onRowDblClick)="showDetails($event.data)"
  >
    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
    ></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="p3-calculations"
      [savingTimeout]="stateStoreDelay"
    ></dxo-state-storing>

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="allowedPageSizes"
      displayMode="compact"
    ></dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-column-chooser
      [enabled]="true"
      mode="select"
      [allowSearch]="true"
      [height]="520"
    ></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column
      dataField="id"
      [caption]="formatMessage('calculations.list.id')"
    ></dxi-column>

    <dxi-column
      dataField="publisherName"
      [caption]="formatMessage('calculations.list.publisher')"
    ></dxi-column>

    <dxi-column
      dataField="publisherNumber"
      [caption]="formatMessage('calculations.list.publisherNumber')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="author"
      [caption]="formatMessage('calculations.list.author')"
    ></dxi-column>

    <dxi-column
      dataField="title"
      [caption]="formatMessage('calculations.list.title')"
    ></dxi-column>

    <dxi-column
      dataField="titleNumber"
      [caption]="formatMessage('calculations.list.titleNumber')"
    ></dxi-column>

    <dxi-column
      dataField="editionCounter"
      [caption]="formatMessage('calculations.list.editionCounter')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="orderNumber"
      [caption]="formatMessage('calculations.list.orderNumber')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="productName"
      [caption]="formatMessage('calculations.list.productType')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="volume"
      [caption]="formatMessage('calculations.list.volume')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="bookFormat"
      [caption]="formatMessage('calculations.list.bookFormat')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="paperName"
      [caption]="formatMessage('calculations.list.paper')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="userName"
      [caption]="formatMessage('calculations.list.creator')"
      [visible]="false"
      cellTemplate="userNameTemplate"
    >
      <div *dxTemplate="let data of 'userNameTemplate'">
        <div *ngIf="data.data.userName === '-'; else userName">
          {{ formatMessage("calculations.list.creatorMissing") }}
        </div>
        <ng-template #userName>
          <div>{{ data.data.userName }}</div>
        </ng-template>
      </div>
    </dxi-column>

    <dxi-column
      dataField="created"
      [caption]="formatMessage('calculations.list.createdDate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="updated"
      [caption]="formatMessage('calculations.list.modifiedDate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      fixedPosition="right"
      [allowHeaderFiltering]="false"
      [caption]="formatMessage('other.actions')"
      [showInColumnChooser]="false"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
    </dxi-column>

    <div *dxTemplate="let row of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="material-icons-outlined mdi-show-details"
        [hint]="formatMessage('calculations.navigate.details')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="showDetails(row.data)"
      >
        <i class="dx-icon material-icons-outlined mdi-show-details"></i>
      </dx-button>
      <dx-button
        class="action-button"
        icon="redo"
        [hint]="formatMessage('calculations.copy')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="copyCalculation(row.data)"
      >
      </dx-button>
      <dx-button
        class="action-button"
        icon="trash"
        [hint]="formatMessage('calculations.delete')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="deleteCalculation(row.data)"
      >
      </dx-button>
    </div>

    <div *dxTemplate="let row of 'cellTemplate'">
      <div *ngIf="row.data.structureChanged; else checkPriceChange">
        <i
          style="color: Tomato"
          id="structureChanged"
          class="dx-icon-remove"
        ></i>
        <dx-tooltip
          target="#structureChanged"
          showEvent="mouseenter"
          hideEvent="mouseleave"
        >
          <div *dxTemplate="let row = row; of: 'content'">
            {{ formatMessage("calculations.state.structureChange") }}
          </div>
        </dx-tooltip>
      </div>
      <ng-template #checkPriceChange>
        <div *ngIf="row.data.pricesChanged; else stateOK">
          <i style="color: Gold" id="pricesChanged" class="dx-icon-info"></i>
          <dx-tooltip
            target="#pricesChanged"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.state.priceChange") }}
            </div>
          </dx-tooltip>
        </div>
        <ng-template #stateOK>
          <i style="color: ForestGreen" id="stateOK" class="dx-icon-check"></i>
          <dx-tooltip
            target="#stateOK"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.ok") }}
            </div>
          </dx-tooltip>
        </ng-template>
      </ng-template>
    </div>
  </dx-data-grid>
  <div [hidden]="!pagerText" class="pager-info">
    {{ pagerText }}
  </div>
</div>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>

<!--
<div class="grid-shadow">
  <dx-data-grid
    id="calculations-grid"
    class="dx-card wide-card"
    keyExpr="id"
    [dataSource]="calculations"
    [showBorders]="false"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="true"
    [allowColumnResizing]="false"
    [allowColumnReordering]="true"
    (onRowDblClick)="showDetails($event.data)"
  >
    <dxo-header-filter
      [visible]="true"
      [allowSearch]="true"
    ></dxo-header-filter>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="p3-calculations"
      [savingTimeout]="stateStoreDelay"
    ></dxo-state-storing>

    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="allowedPageSizes"
      displayMode="compact"
    ></dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>

    <dxo-column-chooser
      [enabled]="true"
      mode="select"
      [allowSearch]="true"
      [height]="520"
    ></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column
      dataField="id"
      [caption]="formatMessage('calculations.list.id')"
    ></dxi-column>

    <dxi-column
      dataField="publisherName"
      [caption]="formatMessage('calculations.list.publisher')"
    ></dxi-column>

    <dxi-column
      dataField="publisherNumber"
      [caption]="formatMessage('calculations.list.publisherNumber')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="author"
      [caption]="formatMessage('calculations.list.author')"
    ></dxi-column>

    <dxi-column
      dataField="title"
      [caption]="formatMessage('calculations.list.title')"
    ></dxi-column>

    <dxi-column
      dataField="titleNumber"
      [caption]="formatMessage('calculations.list.titleNumber')"
    ></dxi-column>

    <dxi-column
      dataField="editionCounter"
      [caption]="formatMessage('calculations.list.editionCounter')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="orderNumber"
      [caption]="formatMessage('calculations.list.orderNumber')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="productName"
      [caption]="formatMessage('calculations.list.productType')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="volume"
      [caption]="formatMessage('calculations.list.volume')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="bookFormat"
      [caption]="formatMessage('calculations.list.bookFormat')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="paperName"
      [caption]="formatMessage('calculations.list.paper')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="userName"
      [caption]="formatMessage('calculations.list.creator')"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="created"
      [caption]="formatMessage('calculations.list.createdDate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="updated"
      [caption]="formatMessage('calculations.list.modifiedDate')"
      [format]="formatMessage('dateformat')"
      dataType="date"
      [allowHeaderFiltering]="false"
    ></dxi-column>

    <dxi-column
      [allowHeaderFiltering]="false"
      [showInColumnChooser]="false"
      [caption]="formatMessage('calculations.state')"
      cellTemplate="cellTemplate"
    >
    </dxi-column>

    <dxi-column
      fixedPosition="right"
      [allowHeaderFiltering]="false"
      [caption]="formatMessage('other.actions')"
      [showInColumnChooser]="false"
      alignment="center"
      cellTemplate="actionsTemplate"
    >
    </dxi-column>

    <div *dxTemplate="let row of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="material-icons-outlined mdi-show-details"
        [hint]="formatMessage('calculations.navigate.details')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="showDetails(row.data)"
      >
        <i class="dx-icon material-icons-outlined mdi-show-details"></i>
      </dx-button>
      <dx-button
        *ngIf="!row.data.structureChanged"
        class="action-button"
        icon="redo"
        [hint]="formatMessage('calculations.copy')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="copyCalculation(row.data)"
      >
      </dx-button>
      <dx-button
        class="action-button"
        icon="trash"
        [hint]="formatMessage('calculations.delete')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="deleteCalculation(row.data)"
      >
      </dx-button>
    </div>

    <div *dxTemplate="let row of 'cellTemplate'">
      <div *ngIf="row.data.structureChanged; else checkPriceChange">
        <i
          style="color: Tomato"
          id="structureChanged"
          class="dx-icon-remove"
        ></i>
        <dx-tooltip
          target="#structureChanged"
          showEvent="mouseenter"
          hideEvent="mouseleave"
        >
          <div *dxTemplate="let row = row; of: 'content'">
            {{ formatMessage("calculations.state.structureChange") }}
          </div>
        </dx-tooltip>
      </div>
      <ng-template #checkPriceChange>
        <div *ngIf="row.data.pricesChanged; else stateOK">
          <i style="color: Gold" id="pricesChanged" class="dx-icon-info"></i>
          <dx-tooltip
            target="#pricesChanged"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.state.priceChange") }}
            </div>
          </dx-tooltip>
        </div>
        <ng-template #stateOK>
          <i style="color: ForestGreen" id="stateOK" class="dx-icon-check"></i>
          <dx-tooltip
            target="#stateOK"
            showEvent="mouseenter"
            hideEvent="mouseleave"
          >
            <div *dxTemplate="let row = row; of: 'content'">
              {{ formatMessage("calculations.ok") }}
            </div>
          </dx-tooltip>
        </ng-template>
      </ng-template>
    </div>

    <dxo-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button
            icon="deletetable"
            [hint]="formatMessage('order.grid.reset.localstorage.hint')"
            (onClick)="showConfirmDeletePopup()"
          >
          </dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after" name="exportButton"></dxi-item>
      <dxi-item location="after" name="columnChooserButton"></dxi-item>
    </dxo-toolbar>
  </dx-data-grid>
  <div [hidden]="!pagerText" class="pager-info">
    {{ pagerText }}
  </div>
</div>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  icon="dx-icon-warning"
  iconColor="warning"
  [title]="formatMessage('order.grid.reset.localstorage.popup.title')"
  [message]="formatMessage('order.grid.reset.localstorage.popup.message')"
  [confirmButtonText]="
    formatMessage('order.grid.reset.localstorage.popup.confirmButtonText')
  "
  (onConfirm)="resetLocalStorage()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
-->
