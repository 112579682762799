import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  Produkt,
  PreislistenKatalog,
  ImportEntry,
} from "../model/calculation/produkt";
import { ProduktFormData } from "../model/calculation/produktFormData";
import { PreislistenKatalogFormData } from "../model/calculation/preislistenKatalogFormData";
import { Apollo } from "apollo-angular";
import { pluck } from "rxjs/operators";
import {
  GET_PRODUKTE_BY_PREISLISTEN_KATALOG,
  GET_PREISLISTENKATALOGE,
  CREATE_PREISLISTENKATALOG,
  UPDATE_PREISLISTENKATALOG,
  GET_PRODUKT,
  UPDATE_PRODUKT,
  DELETE_PRODUKT,
  CREATE_PRODUKT,
  GET_PRODUKT_INFORMATION_BY_PREISLISTEN_KATALOG,
  GET_PRODUKT_IMPORT,
  GET_PRODUKT_IMPORT_PAGES,
  GET_VARIANTS_FOR_PAGES,
  CREATE_PRODUKT_US,
  UPDATE_PRODUKT_US,
  GET_USA_PRODUCT_PRICE_SCALE,
} from "../graphql/calculatorGql";
import {
  UpdateProduktRequest,
  UpdateProduktRequestUS,
} from "../model/calculation/produktRequests";
import { UpdatePreislistenKatalogRequest } from "../model/calculation/preislistenKatalogRequests";

@Injectable({
  providedIn: "root",
})
export class ProduktService {
  public selectedProduktId = "";
  public selectedProdukt: Produkt;

  constructor(private apollo: Apollo) {}

  getSelectedProdukt() {
    return this.selectedProdukt;
  }

  setSelectedProduktId(value) {
    this.selectedProduktId = value;
    if (value != "") {
      this.getProdukt(value).subscribe((produkt) => {
        this.selectedProdukt = produkt;
      });
    } else {
      this.selectedProdukt = <Produkt>{};
    }
  }

  getProdukt(produktId: string): Observable<Produkt> {
    return this.apollo
      .query({
        query: GET_PRODUKT,
        fetchPolicy: "no-cache",
        variables: { id: produktId },
      })
      .pipe(pluck("data", "getProdukt"));
  }

  getProduktWithRawImportEntries(produktId: string): Observable<Produkt> {
    return this.apollo
      .query({
        query: GET_PRODUKT_IMPORT,
        fetchPolicy: "no-cache",
        variables: { id: produktId },
      })
      .pipe(pluck("data", "getProdukt"));
  }

  getProduktPages(produktId: string): Observable<Produkt> {
    return this.apollo
      .query({
        query: GET_PRODUKT_IMPORT_PAGES,
        fetchPolicy: "no-cache",
        variables: { id: produktId },
      })
      .pipe(pluck("data", "getProduktImportPages"));
  }

  getProduktVariantsForPages(
    produktId: string,
    pages: number
  ): Observable<ImportEntry[]> {
    return this.apollo
      .query({
        query: GET_VARIANTS_FOR_PAGES,
        fetchPolicy: "no-cache",
        variables: { id: produktId, pages: pages },
      })
      .pipe(pluck("data", "getProduktVariantsForPages"));
  }

  findProduktsByPreislistenKatalog(
    preislistenKatalog: PreislistenKatalog
  ): Observable<Produkt[]> {
    return this.apollo
      .query({
        query: GET_PRODUKTE_BY_PREISLISTEN_KATALOG,
        fetchPolicy: "no-cache",
        variables: { preislistenKatalogId: preislistenKatalog.id },
      })
      .pipe(pluck("data", "findProduktsByPreislistenKatalog"));
  }

  findProductInformationByPreislistenKatalog(
    preislistenKatalog: PreislistenKatalog
  ): Observable<Produkt[]> {
    return this.apollo
      .query({
        query: GET_PRODUKT_INFORMATION_BY_PREISLISTEN_KATALOG,
        fetchPolicy: "no-cache",
        variables: { preislistenKatalogId: preislistenKatalog.id },
      })
      .pipe(pluck("data", "findProductInformationByPreislistenKatalog"));
  }

  findAllPreislistenKataloge(): Observable<PreislistenKatalog[]> {
    return this.apollo
      .query({
        query: GET_PREISLISTENKATALOGE,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllPreislistenKatalog"));
  }

  updateProdukt(produkt: UpdateProduktRequest): Observable<Produkt> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUKT,
        variables: {
          id: produkt.id,
          name: produkt.name,
          produktType: produkt.produktType,
          circulationLimit: produkt.circulationLimit,
        },
      })
      .pipe(pluck("data", "updateProdukt"));
  }

  updateProduktUs(produkt: UpdateProduktRequestUS): Observable<Produkt> {
    console.log(produkt);
    return this.apollo
      .mutate({
        mutation: UPDATE_PRODUKT_US,
        variables: {
          id: produkt.id,
          name: produkt.name,
          produktType: produkt.produktType,
          width: produkt.width,
          height: produkt.height,
          edition: produkt.edition,
          reprint: produkt.reprint,
          twoTitles: produkt.twoTitles,
        },
      })
      .pipe(pluck("data", "updateProduktUs"));
  }

  updatePreislistenKatalog(
    preislistenKatalog: UpdatePreislistenKatalogRequest
  ): Observable<PreislistenKatalog> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PREISLISTENKATALOG,
        variables: {
          id: preislistenKatalog.id,
          name: preislistenKatalog.name,
        },
      })
      .pipe(pluck("data", "updatePreislistenKatalog"));
  }

  deleteProdukt(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_PRODUKT,
      variables: {
        id: id,
      },
    });
  }

  createProdukt(produkt: ProduktFormData): Observable<Produkt> {
    return this.apollo
      .mutate({
        mutation: CREATE_PRODUKT,
        variables: {
          name: produkt.name,
          preislistenKatalogId: produkt.preislistenKatalog.id,
          produktType: produkt.produktType,
          circulationLimit: produkt.circulationLimit,
        },
      })
      .pipe(pluck("data", "createProdukt"));
  }

  createProduktUs(produkt: ProduktFormData): Observable<Produkt> {
    return this.apollo
      .mutate({
        mutation: CREATE_PRODUKT_US,
        variables: {
          name: produkt.name,
          preislistenKatalogId: produkt.preislistenKatalog.id,
          produktType: produkt.produktType,
          circulationLimit: produkt.circulationLimit,
          width: produkt.width,
          height: produkt.height,
          edition: produkt.edition,
          reprint: produkt.reprint,
          twoTitles: produkt.twoTitles,
        },
      })
      .pipe(pluck("data", "createProduktUs"));
  }

  createPreislistenKatalog(
    preislistenKatalog: PreislistenKatalogFormData
  ): Observable<PreislistenKatalog> {
    return this.apollo
      .mutate({
        mutation: CREATE_PREISLISTENKATALOG,
        variables: {
          name: preislistenKatalog.name,
        },
      })
      .pipe(pluck("data", "createPreislistenKatalog"));
  }

  getUsaProduct(produktId: string): Observable<Produkt> {
    return this.apollo
      .query({
        query: GET_USA_PRODUCT_PRICE_SCALE,
        fetchPolicy: "no-cache",
        variables: { id: produktId },
      })
      .pipe(pluck("data", "getProdukt"));
  }
}
