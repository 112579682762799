<dx-popup
  [(visible)]="visible"
  [showTitle]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [dragEnabled]="false"
  height="auto"
  maxHeight="90%"
  maxWidth="1900px"
  width="90%"
  (onHidden)="visibleChange.emit(visible)"
  (onShown)="applyPrefilter()"
>
  <div
    *dxTemplate="let data of 'content'"
    style="padding-top: 18px"
    id="orderStudioPopupContent"
  >
    <dx-scroll-view>
      <div class="d-flex" [class]="isMediumOrDown ? 'flex-column' : ''">
        <div class="d-flex flex-column">
          <dx-data-grid
            [dataSource]="simpleDataSource"
            keyExpr="id"
            [showBorders]="false"
            [showRowLines]="true"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="true"
            (onSelectionChanged)="handleOrderSelectionChanged($event)"
            [class]="isXSmall || isSmall ? 'multiline-toolbar' : ''"
          >
            <dxo-load-panel [enabled]="true"></dxo-load-panel>

            <dxo-toolbar>
              <dxi-item
                location="before"
                name="searchPanel"
                cssClass="custom-search-panel"
              ></dxi-item>
              <dxi-item location="before">
                <dx-date-box
                  type="date"
                  [placeholder]="formatMessage('order.studios.datebox')"
                  [showClearButton]="true"
                  (onValueChanged)="handleDateValueChanged($event)"
                ></dx-date-box>
              </dxi-item>
              <dxi-item
                [location]="isXSmall || isSmall ? 'before' : 'after'"
                [visible]="isPrefilterActive"
              >
                <app-alert
                  class="d-flex"
                  [showIcon]="false"
                  [message]="
                    isSmall || isXSmall
                      ? ''
                      : formatMessage('orders.prefiltered')
                  "
                  style="margin-right: 8px"
                >
                  <dx-button
                    id="filter-button"
                    [hint]="formatMessage('orders.resetPrefilter')"
                    type="default"
                    stylingMode="text"
                    icon="material-icons mdi-filter-off"
                    (onClick)="removePrefilter()"
                  >
                    <i class="dx-icon material-icons mdi-filter-off"></i>
                  </dx-button>
                </app-alert>
              </dxi-item>
            </dxo-toolbar>
            <dxo-search-panel
              [visible]="true"
              [placeholder]="formatMessage('order.studios.search')"
            ></dxo-search-panel>

            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager
              [visible]="true"
              [showInfo]="true"
              displayMode="compact"
            ></dxo-pager>

            <dxo-selection
              [allowSelectAll]="true"
              selectAllMode="page"
              mode="multiple"
              showCheckBoxesMode="always"
            ></dxo-selection>

            <dxi-column
              dataField="sender.publisher"
              [caption]="formatMessage('sender.publisher')"
              [allowSearch]="true"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              dataField="order.ordernumberdiso"
              dataType="string"
              [caption]="formatMessage('order.ordernumberdiso')"
              [allowSearch]="true"
              [calculateCellValue]="orderNumberDisoDisplay"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              dataField="order.titleinfo.author"
              [caption]="formatMessage('order.titleinfo.author')"
              [allowSearch]="true"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              dataField="order.titleinfo.title"
              [caption]="formatMessage('order.titleinfo.title')"
              [allowSearch]="true"
            ></dxi-column>

            <dxi-column
              dataField="order.titleinfo.isbn"
              [caption]="formatMessage('order.titleinfo.isbn')"
              [allowSearch]="true"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              dataField="order.orderdate"
              [caption]="formatMessage('order.orderdate')"
              [format]="formatMessage('dateformat')"
              dataType="date"
              [allowSearch]="true"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              dataField="publisher.standardStudios"
              [caption]="formatMessage('order.publisher.standardStudios')"
              [calculateDisplayValue]="calculateStudiosOfPublisherCellValue"
              [calculateCellValue]="calculateStudiosOfPublisherCellValue"
              cellTemplate="publisherStandardStudios"
            >
              <div
                *dxTemplate="let d of 'publisherStandardStudios'"
                class="d-flex flex-wrap"
              >
                <app-organization-tag
                  *ngFor="let studio of d.data.publisher?.standardStudios"
                  [organization]="asStudio(studio)"
                  [class]="getStandardStudioClass(studio, d)"
                ></app-organization-tag>
              </div>
            </dxi-column>

            <dxi-column
              dataField="studios"
              [caption]="formatMessage('order.studios')"
              [calculateDisplayValue]="calculateStudiosCellValue"
              [calculateCellValue]="calculateStudiosCellValue"
              cellTemplate="orderStudios"
            >
              <div
                *dxTemplate="let d of 'orderStudios'"
                class="d-flex flex-wrap"
              >
                <app-organization-tag
                  *ngFor="let studio of d.data.studios"
                  [organization]="asStudio(studio)"
                  [class]="getOrderStudioClass(studio, d)"
                ></app-organization-tag>
              </div>
            </dxi-column>
          </dx-data-grid>
        </div>

        <div
          [class]="isMediumOrDown ? 'divider-horizontal' : 'divider-vertical'"
        ></div>

        <div
          class="d-flex flex-grow"
          [class]="isMediumOrDown ? 'align-items-center' : ''"
          [style]="isMediumOrDown ? '' : 'min-width: 270px; max-width: 270px'"
        >
          <div
            class="d-flex flex-grow"
            [class]="
              isMediumOrDown ? (isXSmall ? 'flex-column' : '') : 'flex-column'
            "
            [title]="
              disableTagBoxes
                ? formatMessage('order.studios.disabledTagBoxes.hint')
                : ''
            "
            [style]="disableTagBoxes ? 'cursor: pointer' : ''"
          >
            <div
              *ngIf="!isMediumOrDown"
              id="spacerTop"
              class="d-flex flex-grow"
              style="min-height: 10px; max-height: 95px"
            ></div>
            <div
              class="d-flex flex-column"
              [style]="
                isMediumOrDown
                  ? isXSmall
                    ? 'width: 100%; padding-bottom: 10px'
                    : 'width: 50%; padding-right: 10px'
                  : 'padding-bottom: 25px'
              "
            >
              <div
                class="d-flex align-items-center label-text"
                [style]="disableTagBoxes ? 'color: rgba(0,0,0,0.3)' : ''"
                style="margin-bottom: 10px"
              >
                <span style="margin-right: 4px">
                  {{ formatMessage("order.studios.toAdd") }}
                </span>
                <i
                  class="material-icons-outlined mdi-info"
                  style="font-size: 16px"
                  [title]="
                    disableTagBoxes
                      ? ''
                      : formatMessage('order.studios.toAdd.hint')
                  "
                ></i>
              </div>

              <div class="d-flex flex-grow">
                <dx-tag-box
                  id="tagBoxToAdd"
                  [items]="addableStudios"
                  [(value)]="studiosToAdd"
                  [disabled]="disableTagBoxes"
                  displayExpr="displayName"
                  [searchEnabled]="true"
                  searchExpr="displayName"
                  stylingMode="filled"
                  [showSelectionControls]="true"
                  [showDropDownButton]="true"
                  (onValueChanged)="handleToAddValueChanged()"
                  [width]="isMediumOrDown ? '100%' : '90%'"
                ></dx-tag-box>
              </div>
            </div>

            <div
              class="d-flex flex-column"
              [style]="
                isMediumOrDown
                  ? isXSmall
                    ? 'width: 100%'
                    : 'width: 50%; padding-left: 10px'
                  : ''
              "
            >
              <div
                class="d-flex align-items-center label-text"
                [style]="disableTagBoxes ? 'color: rgba(0,0,0,0.3)' : ''"
                style="margin-bottom: 10px"
              >
                <span style="margin-right: 4px">
                  {{ formatMessage("order.studios.toRemove") }}
                </span>
                <i
                  class="material-icons-outlined mdi-info"
                  style="font-size: 16px"
                  [title]="
                    disableTagBoxes
                      ? ''
                      : formatMessage('order.studios.toRemove.hint')
                  "
                ></i>
              </div>
              <div class="d-flex flex-grow">
                <dx-tag-box
                  [items]="removableStudios"
                  [(value)]="studiosToRemove"
                  [disabled]="disableTagBoxes"
                  displayExpr="displayName"
                  [searchEnabled]="true"
                  searchExpr="displayName"
                  stylingMode="filled"
                  [showSelectionControls]="true"
                  [showDropDownButton]="true"
                  (onValueChanged)="handleToRemoveValueChanged()"
                  [width]="isMediumOrDown ? '100%' : '90%'"
                ></dx-tag-box>
              </div>
            </div>
            <div
              *ngIf="!isMediumOrDown"
              id="spacerBottom"
              class="d-flex flex-grow"
              style="min-height: 10px; max-height: 40px"
            ></div>
          </div>
        </div>
      </div>

      <div
        class="d-flex"
        [class]="isXSmall ? 'flex-column' : 'align-items-center'"
        style="margin-top: 18px; justify-content: end"
      >
        <div
          *ngIf="showConflictMessage"
          class="d-flex flex-shrink"
          style="align-items: center; max-width: 75%"
          [style]="isXSmall ? 'margin-bottom: 10px' : ''"
        >
          <div class="d-flex align-items-center">
            <i
              class="dx-icon-warning conflict-icon"
              style="padding-right: 5px"
            ></i>
            <span class="conflict-text">{{
              formatMessage("order.studios.conflict.message")
            }}</span>
          </div>
        </div>

        <div
          class="d-flex flex-grow"
          style="align-items: center; justify-content: end"
        >
          <dx-button
            [text]="formatMessage('cancel')"
            stylingMode="text"
            (onClick)="visible = false"
            style="margin: 0 10px"
          ></dx-button>

          <dx-button
            [text]="formatMessage('save')"
            type="default"
            (onClick)="onSave()"
          ></dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
