<dx-data-grid
  id="upload-data-grid"
  [style]="'height: auto;'"
  [dataSource]="uploads"
  [showBorders]="false"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
  [hint]="uploadedFilesRowHint"
  (onRowPrepared)="setDeactivatedUploadRowClass($event)"
  (onCellHoverChanged)="onCellHoverChanged($event)"
>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-sorting></dxo-sorting>

  <dxi-column
    dataField="orderPiece"
    [caption]="formatMessage('upload.orderPiece')"
    [calculateCellValue]="translateOrderPiece"
  >
  </dxi-column>
  <dxi-column
    dataField="filename"
    [caption]="formatMessage('upload.filename')"
    [hidingPriority]="2"
  ></dxi-column>
  <dxi-column
    dataField="uploadDate"
    dataType="date"
    format="shortDateShortTime"
    [caption]="formatMessage('upload.uploadDate')"
    [hidingPriority]="3"
  ></dxi-column>
  <dxi-column
    dataField="avCheckStatus.status"
    [caption]="formatMessage('upload.avCheck')"
    [hidingPriority]="4"
    cellTemplate="avStatusTemplate"
  >
    <div *dxTemplate="let data of 'avStatusTemplate'">
      <div class="d-flex align-items-center">
        <i [class]="getAvCheckIconClass(data)"></i>
        <span>
          {{ translateAvCheckStatus(data) }}
        </span>
      </div>
    </div>
  </dxi-column>
  <dxi-column
    *ngIf="isOrderReadyForPrinting"
    dataField="preflightStatus"
    [caption]="formatMessage('upload.status')"
    [hidingPriority]="4"
    cellTemplate="preflightTemplate"
  >
    <div *dxTemplate="let data of 'preflightTemplate'">
      <div class="d-flex align-items-center">
        <i [class]="getPreflightIconClass(data)"></i>
        <span>
          {{ translatePreflightStatus(data) }}
        </span>
        <a
          [href]="data.data.preflightReportUrl"
          target="_blank"
          [title]="formatMessage('upload.download.report')"
          *ngIf="showDownloadReportButton(data)"
        >
          <dx-button
            class="action-button"
            icon="download"
            [hint]="formatMessage('upload.download.report')"
            stylingMode="text"
            type="default"
            style="margin-left: 4px"
          ></dx-button>
        </a>
      </div>
    </div>
  </dxi-column>
  <dxi-column
    dataField="downloadUrl"
    [caption]="formatMessage('other.actions')"
    [showInColumnChooser]="false"
    type="buttons"
    alignment="center"
    cellTemplate="downloadTemplate"
    [visible]="isAllowedToDownloadUploads"
  >
    <div *dxTemplate="let data of 'downloadTemplate'">
      <a [href]="data.row.data.downloadUrl" *ngIf="isAllowedToDownloadUploads">
        <dx-button
          class="action-button"
          icon="download"
          [visible]="showDownloadButton(data)"
          [hint]="formatMessage('upload.download')"
          stylingMode="text"
          type="default"
        ></dx-button>
      </a>
      <dx-button
        *ngIf="isAllowedToReactivateUpload && isOrderReadyForPrinting"
        class="action-button"
        [style]="showDownloadButton(data) ? 'margin-left: 5px' : ''"
        icon="material-icons-outlined mdi-refresh"
        [visible]="showReactivateButton(data)"
        [hint]="formatMessage('upload.reactivate')"
        stylingMode="text"
        type="default"
        (onClick)="onDeactivateUpload.emit(data.row.data)"
      >
        <i class="dx-icon material-icons-outlined mdi-refresh"></i>
      </dx-button>
    </div>
  </dxi-column>
</dx-data-grid>
