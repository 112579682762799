<div>
  <div class="error-msg-container">
    <span *ngIf="neededOptions && neededOptions.size > 0"
      >Es müssen noch folgende Optionen hinzugefügt werden:
      <ul class="error-msg-list">
        <li *ngFor="let option of neededOptions">{{ option }}</li>
      </ul>
    </span>
    <span
      *ngIf="
        (optionsWithMissingInput && optionsWithMissingInput.length > 0) ||
          (ownOptionsWithMissingInput && ownOptionsWithMissingInput.length > 0);
        else checkForOptionsWithPriceInformation
      "
      >Es sind nicht alle benötigten Eingabefelder befüllt.
    </span>
    <ng-template #checkForOptionsWithPriceInformation>
      <span *ngIf="!optionsWithPriceInformation"
        >{{
          formatMessage("calculator.calculation.options.error.priceInformation")
        }}
      </span>
    </ng-template>
  </div>

  <dx-list
    class="option-list"
    selectionMode="multiple"
    [dataSource]="listDataSource"
    [grouped]="true"
    [showSelectionControls]="true"
    [repaintChangesOnly]="true"
    [selectedItemKeys]="[]"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onInitialized)="onInitialized($event)"
  >
    <div *dxTemplate="let groupObj of 'group'" class="option-list-header">
      <div style="margin-bottom: 1em">
        <div class="item-group-name">{{ groupObj.key }}</div>
      </div>
      <div class="header-flex">
        <div style="width: 41px"><!-- checkbox --></div>
        <div class="option-list-row-item item-1">
          {{ formatMessage("calculator.calculation.options.headline.name") }}
        </div>
        <div
          *ngIf="groupObj.items[0]?.headerConfig?.showUserInput"
          class="option-list-row-item item-2"
        >
          {{
            formatMessage("calculator.calculation.options.headline.userInput")
          }}
        </div>
        <div
          *ngIf="groupObj.items[0]?.headerConfig?.showUserInput"
          class="option-list-row-item item-3"
        >
          <!--Einheit-->
        </div>
        <div
          *ngIf="groupObj.items[0]?.headerConfig?.showFixkosten"
          class="option-list-row-item item-5"
        >
          {{ formatMessage("calculator.calculation.options.headline.fix") }}
          <br />
          {{
            formatMessage("calculator.calculation.options.headline.fix.unit")
          }}
        </div>
        <div
          *ngIf="groupObj.items[0]?.headerConfig?.showVarKosten"
          class="option-list-row-item item-6"
        >
          {{ formatMessage("calculator.calculation.options.headline.var") }}
          <br />
          {{
            formatMessage("calculator.calculation.options.headline.var.unit")
          }}
        </div>
        <div
          *ngIf="groupObj.items[0]?.headerConfig?.showFortdruckkosten"
          class="option-list-row-item item-7"
        >
          {{
            formatMessage("calculator.calculation.options.headline.fortdruck")
          }}
          <br />
          {{
            formatMessage(
              "calculator.calculation.options.headline.fortdruck.unit"
            )
          }}
        </div>
      </div>
    </div>
    <div *dxTemplate="let data of 'item'" class="option-list-grid-container">
      <div class="option-list-row-item item-1">{{ data.optionName }}</div>
      <div
        *ngIf="data?.headerConfig?.showUserInput"
        class="option-list-row-item item-2"
      >
        <div *ngIf="data?.showUserInput; else festWert">
          <dx-number-box
            *ngIf="data?.optionType == 'STUECKMEHRPREIS'; else decimalNumberBox"
            [(value)]="data.userInput"
            [showClearButton]="true"
            width="100px"
            height="1.5rem"
            format="#.#"
          ></dx-number-box>
          <ng-template #decimalNumberBox>
            <dx-number-box
              [(value)]="data.userInput"
              [showClearButton]="true"
              width="100px"
              height="1.5rem"
              (onValueChanged)="formatUserInputToTwoDigits($event)"
            ></dx-number-box>
          </ng-template>
        </div>
        <ng-template #festWert>
          <div>
            <span *ngIf="data.fixedValue != 0">{{
              getPriceInformation(data, "fixedValue")
            }}</span>
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="data?.headerConfig?.showUserInput"
        class="option-list-row-item item-3"
      >
        {{ data.optionUnit?.unitNameDe }}
      </div>
      <div
        *ngIf="data?.headerConfig?.showFixkosten"
        class="option-list-row-item item-5"
      >
        {{ getPriceInformation(data, "fixKosten") }}
      </div>
      <div
        *ngIf="data?.headerConfig?.showVarKosten"
        class="option-list-row-item item-6"
      >
        {{ getPriceInformation(data, "variableKosten") }}
      </div>
      <div
        *ngIf="data?.headerConfig?.showFortdruckkosten"
        class="option-list-row-item item-7"
      >
        {{ getPriceInformation(data, "fortDruckKosten") }}
      </div>
    </div>
  </dx-list>

  <div class="own-option-list-container">
    <div style="margin-bottom: 1em">
      <div class="item-group-name own-option-list-header">
        {{ formatMessage("calculator.calculation.options.headline.title") }}
      </div>
    </div>
    <div class="header-flex" *ngIf="ownData && ownData.length > 0">
      <div style="width: 12px"></div>
      <div class="own-option-list-sub-header item-1">
        {{ formatMessage("calculator.calculation.options.headline.name") }}
      </div>
      <div class="own-option-list-row-item own-option-list-sub-header">
        {{ formatMessage("calculator.calculation.options.headline.fix") }}
        <br />
        {{ formatMessage("calculator.calculation.options.headline.fix.unit") }}
      </div>
      <div class="own-option-list-row-item own-option-list-sub-header">
        {{ formatMessage("calculator.calculation.options.headline.var") }}
        <br />
        {{ formatMessage("calculator.calculation.options.headline.var.unit") }}
      </div>
      <div class="own-option-list-row-item own-option-list-sub-header">
        {{ formatMessage("calculator.calculation.options.headline.endPrice") }}
      </div>
      <div style="width: 32px"></div>
    </div>
    <dx-list
      class="own-option-list"
      [dataSource]="listOwnDataSource"
      [activeStateEnabled]="false"
      [allowItemDeleting]="true"
    >
      <div
        *dxTemplate="let data of 'item'"
        class="{{ data.id }} option-list-grid-container"
      >
        <div class="own-option-list-row-item item-1">
          <dx-text-box [(value)]="data.optionName"></dx-text-box>
        </div>
        <div class="own-option-list-row-item">
          <dx-number-box
            [(value)]="data.optionPriceInformation[0].fixKosten"
          ></dx-number-box>
        </div>
        <div class="own-option-list-row-item">
          <dx-number-box
            [(value)]="data.optionPriceInformation[0].variableKosten"
            [(value)]="data.optionPriceInformation[0].fortDruckKosten"
          ></dx-number-box>
        </div>
        <div class="own-option-list-row-item">
          <dx-select-box
            [dataSource]="optionPositionDatasource"
            displayExpr="name"
            valueExpr="value"
            [(value)]="data.optionPosition"
          ></dx-select-box>
        </div>
      </div>
    </dx-list>
    <dx-button
      class="option-button"
      stylingMode="contained"
      [text]="formatMessage('calculations.price.option.list.button')"
      type="default"
      (onClick)="addOwnOptions($event)"
    >
    </dx-button>
  </div>
</div>
