import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { CommonModule } from "@angular/common";
import notify from "devextreme/ui/notify";
import { PaperHandlingPrice } from "generated/api/models";

@Component({
  selector: "app-papercosts-form",
  templateUrl: "./papercosts-form.component.html",
  styleUrls: ["./papercosts-form.component.scss"],
})
export class PaperCostsFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() paperCosts: PaperHandlingPrice;
  @Output() paperCostsChange = new EventEmitter<PaperHandlingPrice>();
 
  constructor() {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
  }

  ngOnInit(): void {
  }

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }
  
  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }
}

@NgModule({
  imports: [
    DxFormModule,
    CommonModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
  ],
  declarations: [PaperCostsFormComponent],
  exports: [PaperCostsFormComponent],
})
export class PaperCostsFormModule {}
