import { Component, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Observable } from "rxjs";
import { LocaleService } from "../../shared/services/locale.service";
import { ScreenService } from "../../shared/services";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {
  formatMessage = formatMessage;
  locale: Observable<string | null>;
  isXSmall: boolean;

  constructor(
    private localeService: LocaleService,
    private screen: ScreenService
  ) {}

  ngOnInit(): void {
    this.locale = this.localeService.getCurrentLocale();
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }
}
