import {
  ACCOUNT_MANAGER,
  ADMIN,
  ADMIN_US,
  CALCULATOR_USER_ROLES_DE,
  EXTERNAL_USER_ROLES,
  INTERNAL_USER_ROLES,
  MANUFACTURER,
  PREPRESS_EMPLOYEE,
  SALES_MANAGER,
  USA_ROLES,
} from "./shared/model/roles";

export const navigation_de = [
  {
    text: "Mein P3",
    path: "/",
    icon: "home",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },
  {
    text: "Aufträge",
    path: "/auftraege",
    icon: "bulletlist",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },

  //Alter Stand
  {
    text: "Kalkulationen",
    icon: "folder",
    allowedRoles: [...CALCULATOR_USER_ROLES_DE],
    items: [
      {
        text: "Kalkulator",
        icon: "money",
        path: "/kalkulationen",
        allowedRoles: [...CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Kalkulationen",
        icon: "money",
        path: "/calculations",
        allowedRoles: [...CALCULATOR_USER_ROLES_DE],
      },
    ],
  },
  // Stand 29.07.2024: Archivierungslösung feature soll zu einem
  // anderen Zeitpunkt realeased werden oder geändert werden
  /*
  {
    text: "Kalkulationen",
    icon: "folder",
    allowedRoles: [CALCULATOR_USER_ROLES_DE],
    items: [
      {
        text: "Kalkulator",
        icon: "money",
        path: "/kalkulationen",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Aktive",
        icon: "money",
        path: "/calculations",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Archiv",
        icon: "inactivefolder",
        path: "/archived-calculations",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
    ],
  },
   */

  {
    text: "Standvorlagen",
    icon: "background",
    path: "/standvorlagen",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },
  {
    text: "Administration",
    icon: "folder",
    allowedRoles: [ADMIN, SALES_MANAGER],
    items: [
      {
        text: "Verlage & Agenturen",
        icon: "box",
        path: "/organizations",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Benutzer",
        icon: "user",
        path: "/user",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Preisverwaltung",
        icon: "product",
        path: "/produkt",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Papierhandling",
        icon: "product",
        path: "/papercosts",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Option",
        icon: "detailslayout",
        path: "/option",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Papierverwaltung",
        icon: "file",
        path: "/papers",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Benutzervorschläge",
        icon: "plus",
        path: "/usersuggestions",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Newsletter",
        icon: "email",
        path: "/newsletter",
        allowedRoles: [ADMIN],
      },
    ],
  },
];

export const navigation_en = [
  {
    text: "My P3",
    path: "/",
    icon: "home",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },
  {
    text: "Jobs",
    path: "/auftraege",
    icon: "bulletlist",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },

  //Alter Stand
  {
    text: "Calculation",
    icon: "folder",
    allowedRoles: [...CALCULATOR_USER_ROLES_DE],
    items: [
      {
        text: "Calculator",
        icon: "money",
        path: "/kalkulationen",
        allowedRoles: [...CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Calculations",
        icon: "money",
        path: "/calculations",
        allowedRoles: [...CALCULATOR_USER_ROLES_DE],
      },
    ],
  },
  // Stand 29.07.2024: Archivierungslösung feature soll zu einem
  // anderen Zeitpunkt realeased werden oder geändert werden
  /*
  {
    text: "Calculation",
    icon: "folder",
    allowedRoles: [CALCULATOR_USER_ROLES_DE],
    items: [
      {
        text: "Calculator",
        icon: "money",
        path: "/kalkulationen",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Active",
        icon: "money",
        path: "/calculations",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
      {
        text: "Archive",
        icon: "inactivefolder",
        path: "/archived-calculations",
        allowedRoles: [CALCULATOR_USER_ROLES_DE],
      },
    ],
  },
   */

  {
    text: "Templates",
    icon: "background",
    path: "/standvorlagen",
    allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
  },
  {
    text: "Administration",
    icon: "folder",
    allowedRoles: [ADMIN, SALES_MANAGER],
    items: [
      {
        text: "Publisher & Agencies",
        icon: "box",
        path: "/organizations",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "User",
        icon: "user",
        path: "/user",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Product",
        icon: "product",
        path: "/produkt",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Paper Handling",
        icon: "product",
        path: "/papercosts",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },

      {
        text: "Options",
        icon: "detailslayout",
        path: "/option",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Papers",
        icon: "file",
        path: "/papers",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "User suggestions",
        icon: "plus",
        path: "/usersuggestions",
        allowedRoles: [ADMIN, SALES_MANAGER],
      },
      {
        text: "Newsletter",
        icon: "email",
        path: "/newsletter",
        allowedRoles: [ADMIN],
      },
    ],
  },
];

export const navigation_us = [
  {
    text: "My P3",
    path: "/",
    icon: "home",
    allowedRoles: [...USA_ROLES, ...INTERNAL_USER_ROLES],
  },
  {
    text: "Calculator",
    icon: "money",
    path: "/kalkulationen",
    allowedRoles: [...USA_ROLES],
  },
  {
    text: "Administration",
    icon: "folder",
    allowedRoles: [ADMIN_US],
    items: [
      {
        text: "Publisher & Agencies",
        icon: "box",
        path: "/organizations",
        allowedRoles: [ADMIN_US],
      },
      {
        text: "User",
        icon: "user",
        path: "/user",
        allowedRoles: [ADMIN_US],
      },
      {
        text: "Product",
        icon: "product",
        path: "/produkt",
        allowedRoles: [ADMIN_US],
      },
      {
        text: "Options",
        icon: "detailslayout",
        path: "/option",
        allowedRoles: [ADMIN_US],
      },
      {
        text: "Option Mapping",
        icon: "increaseindent",
        path: "/option-mapping",
        allowedRoles: [ADMIN_US],
      },
      {
        text: "Papers",
        icon: "file",
        path: "/papers",
        allowedRoles: [ADMIN_US],
      },
    ],
  },
];
