<dx-data-grid
  id="gridContainer"
  [dataSource]="optionData.optionPriceInformation"
  [showBorders]="true"
  [wordWrapEnabled]="true"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    mode="form"
    [allowUpdating]="true"
    [allowAdding]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>

  <dxi-column dataField="from"> </dxi-column>
  <dxi-column dataField="to"></dxi-column>
  <dxi-column dataField="insertPages" [caption]="'Insert Pages'"></dxi-column>
  <dxi-column dataField="insertConfig" [caption]="'Insert Config'"></dxi-column>
  <dxi-column dataField="plates" [caption]="'Plates ($)'"></dxi-column>
  <dxi-column dataField="makeReady" [caption]="'Make Ready ($)'"></dxi-column>
  <dxi-column dataField="run" [caption]="'Run ($)'"></dxi-column>
</dx-data-grid>
