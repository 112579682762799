<h2>
  {{ formatMessage("produkt.paperProduktAssignment") }}
  <dx-button
    [hint]="formatMessage('paperManagement.create')"
    icon="plus"
    type="default"
    stylingMode="contained"
    style="border-radius: 32px; width: 32px; height: 32px"
    (onClick)="showAddPapersPopup()"
  >
  </dx-button>
</h2>

<dx-data-grid
  [dataSource]="allAssociatedPapers"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
  class="d-flex flex-grow dx-card wide-card"
  style="padding: 4px 12px; border-radius: 6px"
>
  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxi-column
    dataField="paperId"
    [caption]="formatMessage('paperDataGrid.paperId')"
    sortOrder="asc"
  ></dxi-column>

  <dxi-column
    dataField="name"
    [caption]="formatMessage('paperDataGrid.name')"
  ></dxi-column>

  <dxi-column
    dataField="grammatur"
    [caption]="formatMessage('paperDataGrid.grammatur')"
  ></dxi-column>

  <dxi-column
    dataField="volumen"
    [caption]="formatMessage('paperDataGrid.volumen')"
  ></dxi-column>

  <dxi-column
    dataField="stoffklasse"
    [caption]="formatMessage('paperDataGrid.stoffklasse')"
  ></dxi-column>

  <dxi-column
    dataField="faerbung"
    [caption]="formatMessage('paperDataGrid.faerbung')"
  ></dxi-column>

  <dxi-column
    dataField="paperCertificationList"
    cellTemplate="paperCertificationListTemplate"
    [caption]="formatMessage('paperDataGrid.paperCertificationList')"
  >
    <div *dxTemplate="let d of 'paperCertificationListTemplate'">
      <div
        class="dx-adaptive-item-text"
        *ngIf="d?.data?.paperCertificationList?.length > 0; else noCertificate"
      >
        <div *ngFor="let certificate of d?.data?.paperCertificationList">
          {{ certificate.name }}
        </div>
      </div>
      <ng-template #noCertificate>
        <div class="dx-adaptive-item-text">ohne</div>
      </ng-template>
    </div>
  </dxi-column>

  <dxi-column
    dataField="rollenBogenPapier"
    [caption]="formatMessage('paperDataGrid.rollenBogenPapier')"
  ></dxi-column>

  <dxi-column
    dataField="price"
    [caption]="formatMessage('paperDataGrid.price')"
  ></dxi-column>

  <dxi-column
    [caption]="formatMessage('other.actions')"
    [allowHeaderFiltering]="false"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="trash"
        [hint]="formatMessage('edit')"
        stylingMode="text"
        type="default"
        style="margin-right: 8px"
        (onClick)="showConfirmationPopupForDeletePaperAssociation(data.data)"
      ></dx-button>
    </div>
  </dxi-column>
</dx-data-grid>

<app-action-popup
  [(visible)]="paperPopupVisible"
  [title]="formatMessage('produkt.details.papers.add')"
  [maxWidth]="'1950px'"
  (onSave)="saveProduktPapierAssociation()"
>
  <app-produkt-papers-grid
    [visible]="paperPopupVisible"
    [allProduktPaperDefinition]="allProduktPaperDefinitions"
  >
  </app-produkt-papers-grid>
</app-action-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="deleteProduktPapierAssociationAndUpdateView()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
