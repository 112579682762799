<dx-data-grid
  [dataSource]="uploads"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50]"
    [showInfo]="true"
    displayMode="compact"
  ></dxo-pager>

  <dxo-export [enabled]="true"></dxo-export>

  <dxi-column
    *ngIf="showOrderNumberDiso"
    dataField="order.order.ordernumberdiso"
    dataType="string"
    [caption]="formatMessage('order.ordernumberdiso')"
    [calculateCellValue]="displayOrderNumberDiso"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    dataField="order.sender.publisher"
    [caption]="formatMessage('sender.publisher')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.order.titleinfo.author"
    [caption]="formatMessage('order.titleinfo.author')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    dataField="order.order.titleinfo.title"
    [caption]="formatMessage('order.titleinfo.title')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.order.titleinfo.isbn"
    [caption]="formatMessage('order.titleinfo.isbn')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.order.idtitle"
    [caption]="formatMessage('order.idtitle')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    [caption]="formatMessage('upload.orderPiece')"
    dataField="orderPiece"
    [calculateCellValue]="translateOrderPiece"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    [caption]="formatMessage('upload.filename')"
    dataField="filename"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    [caption]="formatMessage('upload.uploadDate')"
    dataField="uploadDate"
    [allowSorting]="false"
    [format]="formatMessage('dateformat')"
    dataType="date"
  ></dxi-column>

  <dxi-column
    [caption]="formatMessage('upload.status')"
    dataField="preflightStatus"
    [allowSorting]="false"
    cellTemplate="preflightTemplate"
  >
    <div *dxTemplate="let data of 'preflightTemplate'">
      <div class="d-flex align-items-center">
        <i [class]="getPreflightIconClass(data)"></i>
        <span>
          {{ translatePreflightStatus(data) }}
        </span>
      </div>
    </div>
  </dxi-column>

  <dxi-column
    *ngIf="showReceiverContact"
    dataField="order.receiver.contact"
    [caption]="formatMessage('receiver.contact')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    fixedPosition="right"
    [caption]="formatMessage('other.actions')"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="material-icons-outlined mdi-show-details"
        [hint]="formatMessage('order.navigate.details')"
        stylingMode="text"
        type="default"
        (onClick)="navigateToOrderDetails(data.data)"
      >
        <i class="dx-icon material-icons-outlined mdi-show-details"></i>
      </dx-button>
    </div>
  </dxi-column>
</dx-data-grid>
