// Rum Monitoring is only used in DE
// so every time Rum gets called Try and Catch is necessary

import { AwsRum } from "aws-rum-web";
import { environment } from "../environments/environment";
import { PageAttributes } from "aws-rum-web/dist/cjs/sessions/PageManager";

export class AwsRumService extends AwsRum {
  private static _instance: AwsRumService;

  private static readonly configuration = environment.rumSettings;

  private constructor() {
    if (!environment.isUsa) {
      super(
        AwsRumService.configuration.applicationId,
        AwsRumService.configuration.applicationVersion,
        AwsRumService.configuration.applicationRegion,
        AwsRumService.configuration.rumConfig
      );
    }
  }

  public static getInstance(): AwsRumService {
    if (!AwsRumService._instance) {
      AwsRumService._instance = new AwsRumService();
      this._instance.recordPageView({ pageId: "/upload" });
    }
    return AwsRumService._instance;
  }
}

export function createAwsRumError(e) {
  try {
    AwsRumService.getInstance().recordError(e);
  } catch (e) {}
}
export function createAwsRumEvent(eventName: string, object: any) {
  try {
    AwsRumService.getInstance().recordEvent(eventName, object);
  } catch (e) {
    console.log("event error");
  }
}
export function createAwsRumPageView(payload: string | PageAttributes) {
  try {
    AwsRumService.getInstance().recordPageView(payload);
  } catch (e) {}
}

export enum RumErrorTypes {
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  S3_REQUEST_ERROR = "S3_REQUEST_ERROR",
  UPLOAD_FILE_VALUE_ERROR = "UPLOAD_FILE_VALUE_ERROR ",
}
