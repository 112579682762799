import { PreislistenKatalog } from "./produkt";

export class PreislistenKatalogFormData {
  id = "";
  name = "";

  constructor(preislistenKatalog?: PreislistenKatalog) {
    if (preislistenKatalog) {
      this.name = preislistenKatalog.name;
      this.id = preislistenKatalog.id;
    }
  }
}
