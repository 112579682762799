<dx-data-grid [dataSource]="produktVariantenDataSource" keyExpr="umfang">
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-column-chooser [enabled]="true" mode="select"> </dxo-column-chooser>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-load-panel [enabled]="true"> </dxo-load-panel>
  <dxo-paging [pageSize]="10"> </dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25]"
    [showNavigationButtons]="true"
    [visible]="true"
    [showInfo]="true"
  >
  </dxo-pager>
  <dxi-column
    dataField="umfang"
    caption="Seiten"
    headerCellTemplate="titleHeaderTemplate"
    sortOrder="asc"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="breite"
    caption="Breite (mm)"
    headerCellTemplate="titleHeaderTemplate"
    sortOrder="asc"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="hoehe"
    caption="Höhe (mm)"
    headerCellTemplate="titleHeaderTemplate"
    sortOrder="asc"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="minAuflage"
    caption="Auflage (Ex)"
    headerCellTemplate="titleHeaderTemplate"
    sortOrder="asc"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="paperId"
    caption="PapierId"
    headerCellTemplate="titleHeaderTemplate"
    sortOrder="asc"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="paperContentFix"
    caption="Inhalt Papier Fix (m²/Auflage)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="paperContentVar"
    caption="Inhalt Papier Variabel (m²/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="paperCoverFix"
    caption="Umschlag Papier Fix (Bg/Auflage)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="paperCoverVar"
    caption="Umschlag Papier Variabel (Bg/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="tkContentFix"
    caption="Inhalt TK Fix (€/Auflage)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="tkContentVar"
    caption="Inhalt TK Variabel (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="tkCoverFix"
    caption="Umschlag TK Fix (€/Auflage)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="tkCoverVar"
    caption="Umschlag TK Variabel (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="pricePerPiece"
    caption="Gesamtkosten (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="fdContent"
    caption="Inhalt Papier FD (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="fdCover"
    caption="Umschlag Papier FD (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="fdTk"
    caption="Inhalt TK FD (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="fdTkCover"
    caption="Umschlag TK FD (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="fdTotal"
    caption="Gesamtkosten FD (€/Ex)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="standardFormat"
    caption="StdFormat"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="rollenBreite"
    caption="RB (cm)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <dxi-column
    dataField="zylinderUmfang"
    caption="ZU (cm)"
    headerCellTemplate="titleHeaderTemplate"
  >
    ></dxi-column
  >
  <div *dxTemplate="let info of 'titleHeaderTemplate'">
    <p style="white-space: normal">{{ info.column.caption }}</p>
  </div>
</dx-data-grid>
