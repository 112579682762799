import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { DxFormComponent, DxFormModule } from "devextreme-angular";
import { OptionGroupFormData } from "../../../../../model/calculation/optionGroupFormData";
import { formatMessage } from "devextreme/localization";
import { ColumnFormData } from "../../../../../model/calculation/optionPriceInformation";

@Component({
  selector: "app-column-form",
  templateUrl: "./column-form.component.html",
  styleUrls: ["./column-form.component.scss"],
})
export class ColumnFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() columnFormData: ColumnFormData;
  @Output() columnFormDataChange = new EventEmitter<ColumnFormData>();

  constructor() {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
  }

  ngOnInit(): void {}

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }
}

@NgModule({
  imports: [DxFormModule],
  declarations: [ColumnFormComponent],
  exports: [ColumnFormComponent],
})
export class ColumnFormModule {}
