<app-header
  class="layout-header"
  [menuToggleEnabled]="false"
  [title]="title"
  [isLoggedIn]="false"
></app-header>

<dx-scroll-view class="with-footer" showScrollbar="always">
  <div class="content">
    <ng-content></ng-content>
  </div>

  <div class="content-block">
    <ng-content select="app-footer"></ng-content>
  </div>
</dx-scroll-view>
