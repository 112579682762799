import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "./user.service";
import { environment } from "../../../environments/environment";
import { User } from "../model/user/user";

@Injectable({
  providedIn: "root",
})
export class MaintenanceGuardService implements CanActivate {
  user: User;

  constructor(private router: Router, private userService: UserService) {}

  canActivate(): boolean {
    this.userService.findOwnUser().subscribe((user) => {
      this.user = user;
    });

    if (
      environment.maintenanceMode &&
      (!this.user ||
        (this.user.role != "ADMIN" && this.user.role != "ADMIN_US"))
    ) {
      this.router.navigate(["/maintenance"]);
      return false;
    }
    return true;
  }
}
