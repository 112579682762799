import { Order } from "../order/order";

export class AVStatus {
  status: string;

  constructor(status: string) {
    this.status = status;
  }
}

export class Upload {
  order: Order;
  orderPiece: string;
  filename: string;
  uploadDate: Date;
  preflightStatus: string;
  avCheckStatus: AVStatus;
  downloadUrl?: string;
  preflightReportUrl?: string;
  active: boolean;

  constructor(
    order: Order,
    orderPiece: string,
    filename: string,
    uploadDate: Date,
    preflightStatus: string,
    avCheckStatus: AVStatus,
    downloadUrl: string | undefined,
    active: boolean
  ) {
    this.order = order;
    this.orderPiece = orderPiece;
    this.filename = filename;
    this.uploadDate = uploadDate;
    this.preflightStatus = preflightStatus;
    this.avCheckStatus = avCheckStatus;
    this.downloadUrl = downloadUrl;
    this.active = active;
  }
}
