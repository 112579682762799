<div
  class="dx-card d-flex flex-column publisher-group-card"
  (click)="showOverlay = !showOverlay"
>
  <i
    *ngIf="showIcon"
    class="
      dx-icon-group
      publisher-group-card-icon
      d-flex
      justify-content-center
    "
  ></i>
  <span class="publisher-group-card-diso-number">
    {{ publisherGroup.disoNumber }}
  </span>
  <span
    *ngIf="publisherGroup.displayName"
    class="publisher-group-card-display-name"
  >
    {{ publisherGroup.displayName }}
  </span>
  <i
    *ngIf="!publisherGroup.displayName"
    class="publisher-group-card-display-name-placeholder"
  >
    {{ formatMessage("organization.publisherGroup.noDisplayName") }}
  </i>

  <div
    class="card-overlay card-overlay--absolute"
    [class]="showOverlay ? 'card-overlay--active' : ''"
    style="z-index: 5"
  >
    <div
      class="card-overlay__scrim"
      [style]="showOverlay ? 'opacity: 0.7' : 'opacity: 0'"
    ></div>
    <div *ngIf="showOverlay" class="card-overlay__content">
      <dx-button
        icon="edit"
        stylingMode="contained"
        type="default"
        class="rounded-button"
        style="margin-right: 6px"
        [hint]="formatMessage('edit')"
        (onClick)="onUpdate.emit(publisherGroup)"
      ></dx-button>
      <dx-button
        icon="trash"
        stylingMode="contained"
        type="default"
        class="rounded-button"
        style="margin-left: 6px"
        [hint]="formatMessage('delete')"
        (onClick)="onDelete.emit(publisherGroup)"
      ></dx-button>
    </div>
  </div>
</div>
