import { Injectable } from "@angular/core";

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    return "P3-GGP";
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
