import { Component, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ProduktService } from "../../shared/services/produkt.service";
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { ScreenService } from "../../shared/services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { DxDataGridComponent } from "devextreme-angular";
import { IdentityToAuthorize } from "../../shared/model/user/user";
import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../shared/services/treeViewInstance.service";
import {
  Produkt,
  PreislistenKatalog,
} from "../../shared/model/calculation/produkt";
import { UpdateProduktRequest } from "../../shared/model/calculation/produktRequests";
import { UpdatePreislistenKatalogRequest } from "../../shared/model/calculation/preislistenKatalogRequests";
import { ProduktFormData } from "../../shared/model/calculation/produktFormData";
import { PreislistenKatalogFormData } from "../../shared/model/calculation/preislistenKatalogFormData";
import { getDisplayNameOfOrganization } from "../../shared/model/organization/organization";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { ProduktSpezifikationService } from "../../shared/services/produkt-spezifikation.service";
import { environment } from "src/environments/environment";

const enum OperationTypes {
  DELETE,
  CHANGE_ENABLED_DISABLED_STATE,
}

@Component({
  selector: "app-produkt-management",
  templateUrl: "./produkt-management.component.html",
  styleUrls: ["./produkt-management.component.scss"],
})
export class ProduktManagementComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;
  isMobile: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmOperation: OperationTypes;
  confirmButtonText: string;

  worksForHeaderFilterDataSource: any[];

  produkte: Produkt[] = [];
  showNewProduktButton = false;
  notAuthorizedInternalUsers: IdentityToAuthorize[] = [];
  produktPopupVisible = false;
  selectedProduktId: string | undefined = undefined;
  selectedProduktEnabledState: boolean;
  produktFormData: ProduktFormData = new ProduktFormData();

  preislistenKataloge: PreislistenKatalog[];
  selectedPreislistenKatalog: PreislistenKatalog;
  selectedPreislistenKatalogId: string | undefined = undefined;
  preislistenKatalogFormData: PreislistenKatalogFormData =
    new PreislistenKatalogFormData();
  preislistenKatalogPopupVisible = false;
  editPreislistenKatalogDisabled = true;
  produktFormProdukte: Produkt[];

  environmentIsUsa: boolean = environment.isUsa;

  constructor(
    private router: Router,
    private produktService: ProduktService,
    private produktSpezifikationService: ProduktSpezifikationService,
    private screen: ScreenService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {
    this.translateLocale = this.translateLocale.bind(this);
  }

  ngOnInit(): void {
    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#user-grid",
          offset: "-30 25",
        },
      },
    });

    this.loadPreislistenKataloge();
  }

  preislistenKatalogSelected(e) {
    this.selectedPreislistenKatalog = e.value;
    this.showNewProduktButton = true;
    this.editPreislistenKatalogDisabled = false;
    this.loadProdukte();
  }

  loadInitialSelectedKatalog() {
    this.showNewProduktButton = true;
    this.editPreislistenKatalogDisabled = false;
    this.loadProdukte();
  }

  placeFloatingActionButton(): void {
    // The floating action button has a known bug, that the initial position is wrong on a dx-data-grid. By invoking
    // this method on the "onContentReady" event of the dx-data-grid, the button is on the correct position.
    // https://supportcenter.devexpress.com/ticket/details/t743517/floating-action-button-the-initial-position-is-incorrect-if-the-container-doesn-t-have
    this.showNewProduktButton = true;
    repaintFloatingActionButton();
  }

  loadProdukte(): void {
    if (this.selectedPreislistenKatalog) {
      this.produktService
        .findProductInformationByPreislistenKatalog(
          this.selectedPreislistenKatalog
        )
        .toPromise()
        .then((result) => (this.produkte = [...result]))
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("produkt.load.error");
          this.showToast = true;
        });
    }
  }

  async loadPreislistenKataloge(): Promise<void> {
    try {
      this.preislistenKataloge = await this.produktService
        .findAllPreislistenKataloge()
        .toPromise();

      this.sortPreislistenKataloge();

      let produkt = this.produktService.getSelectedProdukt();
      if (produkt) {
        if (produkt.preislistenKatalog) {
          this.preislistenKataloge.forEach((katalog) => {
            if (katalog.id == produkt.preislistenKatalog?.id) {
              this.selectedPreislistenKatalog = katalog;
              this.loadInitialSelectedKatalog();
            }
          });
          this.produktService.setSelectedProduktId("");
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  sortPreislistenKataloge(): void {
    this.preislistenKataloge.sort((preislistenKatalog1, preislistenKatalog2) =>
      this.comparePreislistenKatalogeByName(
        preislistenKatalog1,
        preislistenKatalog2
      )
    );
  }

  comparePreislistenKatalogeByName(
    preislistenKatalog1: PreislistenKatalog,
    preislistenKatalog2: PreislistenKatalog
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      preislistenKatalog1.name,
      preislistenKatalog2.name
    );
  }

  createProdukt(): void {
    if (!this.selectedPreislistenKatalog) {
      this.toastMessage = formatMessage(
        `produkt.preislistenkatalog.notselected`
      );
      this.toastType = "error";
      this.showToast = true;
    } else {
      this.selectedProduktId = undefined;
      this.produktFormData = new ProduktFormData();
      this.produktFormProdukte = this.produkte;
      this.produktFormData.preislistenKatalog = this.selectedPreislistenKatalog;
      this.produktPopupVisible = true;
    }
  }

  editProdukt(produkt: Produkt): void {
    this.selectedProduktId = produkt.id;
    this.produktFormData = new ProduktFormData(produkt);
    this.produktPopupVisible = true;
  }

  async saveProdukt(data: ProduktFormData): Promise<void> {
    if (this.environmentIsUsa) {
      await this.saveNewProduktUsAndUpdateView(data);
    } else {
      await this.saveNewProduktAndUpdateView(data);
    }
  }

  async savePreislistenKatalog(
    data: PreislistenKatalogFormData
  ): Promise<void> {
    if (!this.selectedPreislistenKatalogId) {
      await this.saveNewPreislistenKatalogAndUpdateView(data);
    } else {
      await this.updatePreislistenKatalogAndUpdateView(data);
    }
  }

  removeProduktFromView() {
    const selectedProduktIndex = this.produkte.findIndex(
      (it) => it.id === this.selectedProduktId
    );

    if (selectedProduktIndex !== -1) {
      this.produkte.splice(selectedProduktIndex, 1);
    }
  }

  showConfirmationPopupForDeleteProdukt(produkt: Produkt) {
    this.selectedProduktId = produkt.id;

    this.confirmationPopupTitle = formatMessage(`produkt.delete.confirm.title`);
    this.confirmationPopupMessage = formatMessage(
      `produkt.delete.confirm.message`,
      produkt.name
    );
    this.confirmButtonText = formatMessage("delete");

    this.confirmOperation = OperationTypes.DELETE;
    this.showConfirmationPopup = true;
  }

  deleteProduktAndUpdateView(): void {
    if (!this.selectedProduktId) return;

    this.produktSpezifikationService
      .deleteProduktSpezifikationByProdukt(this.selectedProduktId)
      .toPromise()
      .then(() => {
        if (this.selectedProduktId) {
          this.produktService
            .deleteProdukt(this.selectedProduktId)
            .toPromise()
            .then(() => {
              this.removeProduktFromView();

              this.toastType = "success";
              this.toastMessage = formatMessage("produkt.delete.success");
            })
            .catch(() => {
              this.toastType = "error";
              this.toastMessage = formatMessage("produkt.delete.error");
            })
            .finally(() => {
              this.showToast = true;
            });
        }
      });
  }

  async saveNewProduktAndUpdateView(data: ProduktFormData): Promise<void> {
    await this.produktService
      .createProdukt(data)
      .toPromise()
      .then((createdProdukt) => {
        this.produkte.unshift(createdProdukt);
        this.toastMessage = formatMessage("produkt.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("produkt.create.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async saveNewProduktUsAndUpdateView(data: ProduktFormData): Promise<void> {
    await this.produktService
      .createProduktUs(data)
      .toPromise()
      .then((createdProdukt) => {
        this.produkte.unshift(createdProdukt);
        this.toastMessage = formatMessage("produkt.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("produkt.create.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async saveNewPreislistenKatalogAndUpdateView(
    data: PreislistenKatalogFormData
  ): Promise<void> {
    await this.produktService
      .createPreislistenKatalog(data)
      .toPromise()
      .then((createdPreislistenKatalog) => {
        this.preislistenKataloge.unshift(createdPreislistenKatalog);
        this.toastMessage = formatMessage("preislistenKatalog.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("preislistenKatalog.create.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async updatePreislistenKatalogAndUpdateView(
    data: PreislistenKatalogFormData
  ): Promise<void> {
    this.updatePreislistenKatalog(data)
      .then((updatedPreislistenKatalog) => {
        this.updatePreislistenKatalogInView(updatedPreislistenKatalog);

        this.toastMessage = formatMessage("preislistenKatalog.update.success");
        this.toastType = "success";
      })
      .catch((e) => {
        if (["ALREADY_EXISTS", "NOT_FOUND"].includes(e.message)) {
          this.toastMessage = formatMessage(
            `preislistenKatalog.error.${e.message}`
          );
        } else {
          this.toastMessage = formatMessage("preislistenKatalog.update.error");
        }

        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async updatePreislistenKatalog(
    data: PreislistenKatalogFormData
  ): Promise<PreislistenKatalog> {
    return await this.produktService
      .updatePreislistenKatalog(
        new UpdatePreislistenKatalogRequest(data.id, data.name)
      )
      .toPromise();
  }

  updatePreislistenKatalogInView(
    updatedPreislistenKatalog: PreislistenKatalog
  ) {
    const selectedPreislistenKatalogIndex = this.preislistenKataloge.findIndex(
      (it) => it.id === this.selectedPreislistenKatalogId
    );

    if (selectedPreislistenKatalogIndex !== -1) {
      this.preislistenKataloge.splice(
        selectedPreislistenKatalogIndex,
        1,
        updatedPreislistenKatalog
      );
    }

    this.selectedPreislistenKatalog = updatedPreislistenKatalog;
  }

  calculateWorksForCellValue(rowData) {
    return rowData.worksFor.map((it) => getDisplayNameOfOrganization(it));
  }

  filterWorksFor(filterValue) {
    const column = this as any;

    return [column.calculateCellValue, "contains", filterValue];
  }

  translateLocale(rowData): string {
    return formatMessage(`locale.${rowData.identity.locale}`);
  }

  createPreislistenKatalog(): void {
    this.selectedPreislistenKatalogId = undefined;
    this.preislistenKatalogFormData = new PreislistenKatalogFormData();
    this.preislistenKatalogPopupVisible = true;
  }

  editPreislistenKatalog(): void {
    this.selectedPreislistenKatalogId = this.selectedPreislistenKatalog.id;
    this.preislistenKatalogFormData = new PreislistenKatalogFormData();
    this.preislistenKatalogFormData.id = this.selectedPreislistenKatalog.id;
    this.preislistenKatalogFormData.name = this.selectedPreislistenKatalog.name;
    this.preislistenKatalogPopupVisible = true;
  }

  navigateToProduktDetails(produkt: Produkt): void {
    this.navigateAndResetTreeView(`/produktdetails/${produkt.id}`);
  }

  private navigateAndResetTreeView(path: string): void {
    this.router.navigate([path]);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }
}
