<form class="login-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">
    <dxi-item
      dataField="email"
      editorType="dxTextBox"
      [editorOptions]="{
        stylingMode: 'filled',
        placeholder: 'Email',
        mode: 'email'
      }"
    >
      <dxi-validation-rule
        type="required"
        message="Email is required"
      ></dxi-validation-rule>
      <dxi-validation-rule
        type="email"
        message="Email is invalid"
      ></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item
      dataField="password"
      editorType="dxTextBox"
      [editorOptions]="{
        stylingMode: 'filled',
        placeholder: 'Password',
        mode: 'password'
      }"
    >
      <dxi-validation-rule
        type="required"
        message="Password is required"
      ></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item
      dataField="rememberMe"
      editorType="dxCheckBox"
      [editorOptions]="{
        text: 'Remember me',
        elementAttr: { class: 'form-text' }
      }"
    >
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options
        width="100%"
        type="default"
        [useSubmitBehavior]="true"
        [template]="'signInTemplate'"
      >
      </dxo-button-options>
    </dxi-item>

    <dxi-item>
      <div class="link">
        <a routerLink="/reset-password">Forgot password?</a>
      </div>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options
        text="Create an account"
        width="100%"
        [onClick]="onCreateAccountClick"
      ></dxo-button-options>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator
            width="24px"
            height="24px"
            [visible]="true"
          ></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Sign In</ng-template>
      </span>
    </ng-container>
  </dx-form>
</form>
