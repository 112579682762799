<div class="dx-card">
  <dx-form
    #calcForm
    id="calculationForm"
    labelMode="floating"
    [(formData)]="calculationData"
    colCount="1"
    [colCountByScreen]="{ xs: 1 }"
    validationGroup="newCalculationFormValidation"
    (onFieldDataChanged)="onFormFieldChanged()"
  >
    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item style="padding-top: 1.5em" [colSpan]="2"
        ><span
          class="d-flex flex-shrink flex-column form-headline"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
          >General Information</span
        ></dxi-item
      >
      <dxi-item [colSpan]="3" itemType="empty"> <!-- placeholder --> </dxi-item>
      <dxi-item name="date" [colSpan]="1">
        <div *dxTemplate="let data">
          <dx-text-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.date') + ' *'"
            [(value)]="
              getTodaysDate() || data.component.option('formData')[data.name]
            "
            [readOnly]="true"
          ></dx-text-box>
        </div>
      </dxi-item>
      <dxi-item
        dataField="author"
        [label]="{ text: formatMessage('calculator.calculation.us.author') }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
      </dxi-item>
      <dxi-item
        dataField="title"
        [label]="{ text: formatMessage('calculator.calculation.us.title') }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="4"
      >
      </dxi-item>
      <dxi-item name="publisher" [colSpan]="2">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="
              formatMessage('calculator.calculation.us.publisher') + ' *'
            "
            [dataSource]="organizations"
            displayExpr="displayName"
            [searchEnabled]="true"
            [(value)]="selectedPublisher"
            (onValueChanged)="publisherSelected($event)"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="customerContact"
        [label]="{
          text: formatMessage('calculator.calculation.us.customerContact')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
      </dxi-item>
      <dxi-item
        dataField="bpgContact"
        [label]="{
          text: formatMessage('calculator.calculation.us.bpgContact')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
      </dxi-item>
      <!--
      TODO: Not needed
      <dxi-item
        dataField="printNumber"
        [label]="{
          text: formatMessage('calculator.calculation.us.printNumber')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
      </dxi-item>
      -->
    </dxi-item>
    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item style="padding-top: 1.5em" [colSpan]="2"
        ><span
          class="d-flex flex-shrink flex-column form-headline"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
          >Job Specifications</span
        ></dxi-item
      >
      <dxi-item [colSpan]="4" itemType="empty"> <!-- placeholder --> </dxi-item>
      <dxi-item
        dataField="quantity[0]"
        [label]="{
          text: formatMessage('calculator.calculation.us.quantity') + ' 1'
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule type="numeric"></dxi-validation-rule>
        <dxi-validation-rule
          type="range"
          [min]="1"
          [message]="
            formatMessage('calculator.calculation.validation.quantity.range')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="quantity[1]"
        [label]="{
          text: formatMessage('calculator.calculation.us.quantity') + ' 2'
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule
          *ngIf="!!calculationData?.quantity?.[2]"
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule type="numeric"></dxi-validation-rule>
        <dxi-validation-rule
          type="range"
          [min]="1"
          [message]="
            formatMessage('calculator.calculation.validation.quantity.range')
          "
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="quantity[2]"
        [label]="{
          text: formatMessage('calculator.calculation.us.quantity') + ' 3'
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
        <dxi-validation-rule type="numeric"></dxi-validation-rule>
        <dxi-validation-rule
          type="range"
          [min]="1"
          [message]="
            formatMessage('calculator.calculation.validation.quantity.range')
          "
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item [colSpan]="6" itemType="empty"> <!-- placeholder --> </dxi-item>
      <dxi-item name="product" [colSpan]="2">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.product') + ' *'"
            [dataSource]="products"
            displayExpr="name"
            [(value)]="selectedProduct"
            (onValueChanged)="productSelected($event)"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="trimSizeGroup"
        [colSpan]="2"
        [label]="{
          text: formatMessage('calculator.calculation.us.trimSizeGroup')
        }"
        [editorOptions]="{
          stylingMode: 'underlined',
          readOnly: true
        }"
      >
      </dxi-item>
      <dxi-item [colSpan]="2" itemType="empty"> <!-- placeholder --> </dxi-item>
      <dxi-item
        dataField="binding"
        [colSpan]="1"
        *ngIf="!calculationData.pressMethod"
        [editorOptions]="{
          stylingMode: 'underlined',
          label: formatMessage('calculator.calculation.us.binding'),
          dataSource: calculatorUsFormDropdownValues.bindingValues,
          valueExpr: 'value',
          displayExpr: 'displayExpr',
          readOnly: true
        }"
        editorType="dxSelectBox"
      >
      </dxi-item>

      <dxi-item
        dataField="pressMethod"
        [colSpan]="1"
        *ngIf="calculationData.pressMethod"
        [editorOptions]="{
          stylingMode: 'underlined',
          label: formatMessage('calculator.calculation.us.pressMethod'),
          dataSource: calculatorUsFormDropdownValues.pressMethodValues,
          valueExpr: 'value',
          displayExpr: 'displayExpr',
          readOnly: true
        }"
        editorType="dxSelectBox"
      >
      </dxi-item>
      <dxi-item
        dataField="edition"
        [colSpan]="1"
        [editorOptions]="{
          stylingMode: 'underlined',
          label: formatMessage('calculator.calculation.us.edition'),
          dataSource: calculatorUsFormDropdownValues.editionValues,
          valueExpr: 'value',
          displayExpr: 'displayExpr',
          readOnly: true
        }"
        editorType="dxSelectBox"
      >
      </dxi-item>

      <dxi-item dataField="width" name="width" [colSpan]="1">
        <div *dxTemplate="let data">
          <dx-text-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.width') + ' *'"
            [(value)]="data.component.option('formData')[data.name]"
            [readOnly]="!selectedProduct"
            (onValueChanged)="validateWidth()"
          ></dx-text-box>
        </div>
        <dxi-validation-rule
          *ngIf="!!selectedProduct"
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>

        <dxi-validation-rule
          type="custom"
          [validationCallback]="validateWidth"
          [message]="widthValidationMessage"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item dataField="height" name="height" [colSpan]="1">
        <div *dxTemplate="let data">
          <dx-text-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.height') + ' *'"
            [(value)]="data.component.option('formData')[data.name]"
            [readOnly]="!selectedProduct"
            (onValueChanged)="validateHeight()"
          ></dx-text-box>
        </div>
        <dxi-validation-rule
          *ngIf="!!selectedProduct"
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
        <dxi-validation-rule type="numeric"></dxi-validation-rule>
        <dxi-validation-rule
          type="custom"
          [validationCallback]="validateHeight"
          [message]="heightValidationMessage"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item name="pages" [colSpan]="1">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.pages') + ' *'"
            [dataSource]="pageDropdownDataSource"
            [readOnly]="!pageDropdownDataSource"
            [searchEnabled]="true"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="reprint"
        [colSpan]="1"
        [editorOptions]="{
          stylingMode: 'underlined',
          label:
            formatMessage('calculator.calculation.us.initialOrReprint') + ' *',
          dataSource: calculatorUsFormDropdownValues.initialOrReprintValues,
          valueExpr: 'value',
          displayExpr: 'displayExpr',
          readOnly: true
        }"
        editorType="dxSelectBox"
      >
        <!-- Dont needed
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="
              formatMessage('calculator.calculation.us.initialOrReprint') + ' *'"
            [dataSource]="calculatorUsFormDropdownValues.initialOrReprintValues"
            displayExpr="displayExpr"
            valueExpr="value"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
        -->
      </dxi-item>

      <dxi-item [colSpan]="6" itemType="empty"> <!-- placeholder --> </dxi-item>

      <dxi-item
        *ngIf="usedCartonPackOption?.cartonColumnNames[0]"
        name="cartonOrPacks"
        [colSpan]="2"
      >
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="
              formatMessage('calculator.calculation.us.cartonPacks') + ' *'
            "
            [dataSource]="usedCartonPackOption?.cartonColumnNames"
            [(value)]="selectedCartonPack"
            (onSelectionChanged)="onCartonPackSelected($event)"
            (onFocusIn)="onCartonPackFocusIn()"
          ></dx-select-box>
        </div>
        <dxi-validation-rule
          type="required"
          [message]="formatMessage('validation.required')"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        *ngIf="!usedCartonPackOption?.cartonColumnNames[0]"
        name="cartonOrPacks"
        [colSpan]="2"
      >
        <div *dxTemplate="let data">
          <dx-text-box
            [label]="
              formatMessage('calculator.calculation.us.cartonPacks') + ' *'
            "
            stylingMode="underlined"
            [readOnly]="true"
            [value]="selectedProduct ? 'Cartons / Pack' : ''"
          >
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item name="paper" [colSpan]="2">
        <div *dxTemplate="let data">
          <dx-select-box
            stylingMode="underlined"
            [label]="formatMessage('calculator.calculation.us.paper')"
            [dataSource]="calculatorUsFormDropdownValues.paperSpecsValues"
            displayExpr="displayExpr"
            valueExpr="value"
            [(value)]="data.component.option('formData')[data.name]"
          ></dx-select-box>
        </div>
      </dxi-item>
      <dxi-item
        dataField="paperInLb"
        [label]="{
          text: formatMessage('calculator.calculation.us.paperInLb')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      >
      </dxi-item>
      <dxi-item [colSpan]="6" itemType="empty"> <!-- placeholder --> </dxi-item>
      <dxi-item
        dataField="prep"
        [label]="{
          text: formatMessage('calculator.calculation.us.prep')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
      <dxi-item
        dataField="proofText"
        [label]="{
          text: formatMessage('calculator.calculation.us.proofText')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
      <dxi-item
        dataField="plates"
        [label]="{
          text: formatMessage('calculator.calculation.us.plates')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
      <dxi-item
        dataField="paperText"
        [label]="{
          text: formatMessage('calculator.calculation.us.paperText')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
      <dxi-item
        dataField="printText"
        [label]="{
          text: formatMessage('calculator.calculation.us.printText')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
      <dxi-item
        dataField="bind"
        [label]="{
          text: formatMessage('calculator.calculation.us.bind')
        }"
        [editorOptions]="{
          stylingMode: 'underlined'
        }"
        [colSpan]="2"
      ></dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="6" [colCountByScreen]="{ xs: 1 }">
      <dxi-item style="padding-top: 1.5em" [colSpan]="6">
        <div *dxTemplate="let data">
          <dx-button
            style="margin-top: 1rem"
            [disabled]="additionalSpecButtonDisabled"
            [text]="
              formatMessage(
                'calculator.calculation.us.button.additionalSpecification'
              )
            "
            (onClick)="onAddSpecButtonSelected()"
            [useSubmitBehavior]="true"
          >
          </dx-button>
        </div>
      </dxi-item>
    </dxi-item>
  </dx-form>

  <div class="form-buttons">
    <dx-button
      [text]="formatMessage('calculator.button.calculate')"
      (onClick)="calculate()"
      [useSubmitBehavior]="true"
      type="default"
    >
    </dx-button>
    <dx-button
      [text]="formatMessage('calculator.button.createPdf')"
      (onClick)="downloadPdf()"
      [useSubmitBehavior]="true"
      [disabled]="!calculationResponse"
      type="default"
    >
    </dx-button>
    <span>* Required fields</span>
  </div>
</div>
<!--
<div class="dx-card" *ngIf="calculationResponse">
  <div style="line-break: anywhere">
    {{ json.stringify(calculationResponse.status) }} <br />
    {{ json.stringify(calculationResponse.requestId) }} <br />
    {{ json.stringify(calculationResponse.data) }} <br />
  </div>
  <table *ngIf="calculationTableConfig.responseSuccessful" class="price-table">
    <thead>
      <tr>
        <th>Description</th>
        <th>
          Quantity 1 -
          {{ calculatorService.convertToLocaleUsString(quantities[0]) }}
        </th>
        <th *ngIf="calculationTableConfig.showSecondCol">
          Quantity 2 -
          {{
            calculatorService.convertToLocaleUsString(quantities[1]) ||
              calculatorService.convertToLocaleUsString(quantities[2])
          }}
        </th>
        <th *ngIf="calculationTableConfig.showThirdCol">
          Quantity 3 -
          {{ calculatorService.convertToLocaleUsString(quantities[2]) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <app-ancillary-item-group
        style="display: contents"
        *ngFor="let groupName of responseAncillaryGroupTypes; index as i"
        [calculationTableConfig]="calculationTableConfig"
        [calcReponse]="calculationResponse.data"
        [headLineText]="groupName.name"
        [invoiceItemsAncillaryType]="groupName.value"
      ></app-ancillary-item-group>
    </tbody>
    <tfoot>
      <tr class="total" *ngIf="true">
        <td class="headline">{{ "Total" }}</td>
        <td>{{ "xxx.xx" }}</td>
        <td *ngIf="calculationTableConfig.showSecondCol">{{ "xxx.xx" }}</td>
        <td *ngIf="calculationTableConfig.showThirdCol">{{ "xxx.xx" }}</td>
      </tr>
    </tfoot>
  </table>
</div>
-->
<app-calculator-us-price-table
  [calculationResponse]="calculationResponse"
  [calculationTableConfig]="calculationTableConfig"
  [quantities]="quantities"
  [responseAncillaryGroupTypes]="responseAncillaryGroupTypes"
></app-calculator-us-price-table>

<app-action-popup
  [(visible)]="showAdditionalSpecifications"
  maxWidth="40%"
  (onShowing)="(null)"
  title="Additional Specifications"
  (onSave)="submitOptionen()"
>
  <app-additional-specification-list
    [optionGroups]="additionalOptionGroups"
    [selectedProduct]="selectedProduct"
  ></app-additional-specification-list>
</app-action-popup>
