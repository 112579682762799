<h2
  style="cursor: pointer"
  (click)="publisherGroupsVisible = !publisherGroupsVisible"
>
  <span>{{ formatMessage("organization.management.publisherGroup") }}</span>
  <i
    *ngIf="!publisherGroupsVisible"
    class="dx-icon-chevrondown"
    style="font-size: 24px"
  ></i>
  <i
    *ngIf="publisherGroupsVisible"
    class="dx-icon-chevronup"
    style="font-size: 24px"
  ></i>
</h2>

<div
  class="d-flex"
  [style]="publisherGroupsVisible ? '' : 'display: none'"
  style="padding-bottom: 20px; flex-wrap: wrap"
>
  <app-publisher-group-card
    *ngFor="let publisherGroup of publisherGroups"
    [publisherGroup]="publisherGroup"
    [showIcon]="!isMediumOrDownScreen"
    (onUpdate)="onUpdate.emit($event)"
    (onDelete)="onDelete.emit($event)"
  ></app-publisher-group-card>

  <div
    class="dx-card plus-card d-flex align-items-center justify-content-center"
    [class]="isMediumOrDownScreen ? 'plus-card--small' : ''"
    (click)="createPublisherGroup()"
    [title]="formatMessage('organization.create.publisherGroup')"
  >
    <i class="dx-icon-plus" style="font-size: 26px; color: white"></i>
  </div>
</div>
