<dx-form
  id="columnForm"
  labelMode="floating"
  [formData]="columnFormData"
  colCount="1"
  [colCountByScreen]="{ xs: 1 }"
>
  <dxi-item dataField="name">
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
</dx-form>
