<div *ngIf="calculationResponse">
  <h3>
    <i class="dx-icon-money dx-font-l"></i>
    {{ formatMessage("calculator.calculation.price") }}
  </h3>
  <div class="dx-card table-wrapper" id="price" style="min-height: 5rem">
    <app-price-table [calculationResponse]="calculationResponse">
    </app-price-table>
  </div>
</div>
