<tr>
  <td>{{ ancillaryItemName }}</td>
  <td>
    {{ calculatorService.convertToLocaleUsPriceString(totalPrices[0], 2) }}
  </td>
  <td *ngIf="calculationTableConfig.showSecondCol">
    {{ calculatorService.convertToLocaleUsPriceString(totalPrices[1], 2) }}
  </td>
  <td *ngIf="calculationTableConfig.showThirdCol">
    {{ calculatorService.convertToLocaleUsPriceString(totalPrices[2], 2) }}
  </td>
</tr>
