<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  [title]="formatMessage('news.popup.title')"
  [closeOnOutsideClick]="false"
  maxWidth="800px"
  (onSave)="save()"
>
  <dx-form
    id="newsElementForm"
    labelMode="floating"
    [(formData)]="newsInformationRequest"
    colCount="2"
    [colCountByScreen]="{ xs: 1 }"
    validationGroup="newElementValidationGroup"
  >
    <dxi-item
      dataField="titleDE"
      [label]="{ text: formatMessage('news.titleDE') }"
      [editorOptions]="{ stylingMode: 'underlined', maxLength: 50 }"
    >
      <dxi-validation-rule
        type="required"
        [message]="formatMessage('validation.required')"
      ></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      dataField="titleEN"
      [label]="{ text: formatMessage('news.titleEN') }"
      [editorOptions]="{ stylingMode: 'underlined', maxLength: 50 }"
    >
      <dxi-validation-rule
        type="required"
        [message]="formatMessage('validation.required')"
      ></dxi-validation-rule>
    </dxi-item>
    <dxi-item
      dataField="descriptionDE"
      editorType="dxTextArea"
      [label]="{ text: formatMessage('news.descriptionDE') }"
      [editorOptions]="{ stylingMode: 'underlined', maxLength: 255 }"
    ></dxi-item>
    <dxi-item
      dataField="descriptionEN"
      editorType="dxTextArea"
      [label]="{ text: formatMessage('news.descriptionEN') }"
      [editorOptions]="{ stylingMode: 'underlined', maxLength: 255 }"
    ></dxi-item>
  </dx-form>

  <div class="dropzones-container">
    <div class="dropzone-container-left d-flex flex-column flex-grow">
      <span class="dropzone-label">
        {{ formatMessage("news.newsletterDE") }}
      </span>
      <div
        id="newsletter-de-drop-zone-external"
        class="
          dropzone-external
          d-flex
          flex-column
          justify-content-center
          align-items-center
          text-center
        "
        [ngClass]="
          isNewsletterDeDropZoneActive
            ? ['dx-theme-accent-as-border-color', 'dropzone-active']
            : showErrorBordersOnNewsletterDE
            ? ['dropzone-error-border']
            : ['dx-theme-border-color']
        "
      >
        <div *ngIf="newsletterDeErrorMessage" class="error-text">
          {{ newsletterDeErrorMessage }}
        </div>

        <div *ngIf="newsletterDeSelectedFileName" class="selected-file-text">
          {{ newsletterDeSelectedFileName }}
        </div>

        <div
          *ngIf="!newsletterDeErrorMessage && !newsletterDeSelectedFileName"
          class="d-flex flex-column input-text"
        >
          <span>
            {{ formatMessage("news.upload.dragAndDrop") }}
          </span>
          <span>
            {{ formatMessage("news.upload.fileChooser") }}
          </span>
        </div>

        <dx-file-uploader
          #newsletterDE
          dialogTrigger="#newsletter-de-drop-zone-external"
          dropZone="#newsletter-de-drop-zone-external"
          [multiple]="false"
          accept="application/pdf"
          [maxFileSize]="MAX_UPLOAD_FILESIZE"
          uploadMode="useButton"
          [visible]="false"
          (onDropZoneEnter)="
            resetNewsletterDeDropzoneErrorValues();
            isNewsletterDeDropZoneActive = true
          "
          (onDropZoneLeave)="isNewsletterDeDropZoneActive = false"
          (onValueChanged)="handleNewsletterDeFileChanged()"
        ></dx-file-uploader>
      </div>
      <div
        class="required-error-text"
        *ngIf="newsletterDeRequiredError && !newsletterDeErrorMessage"
      >
        {{ formatMessage("validation.required") }}
      </div>
    </div>

    <div class="dropzone-container-right d-flex flex-column flex-grow">
      <span class="dropzone-label">
        {{ formatMessage("news.newsletterEN") }}
      </span>
      <div
        id="newsletter-en-drop-zone-external"
        class="
          dropzone-external
          d-flex
          flex-column flex-grow
          justify-content-center
          align-items-center
          text-center
        "
        [ngClass]="
          isNewsletterEnDropZoneActive
            ? ['dx-theme-accent-as-border-color', 'dropzone-active']
            : showErrorBordersOnNewsletterEN
            ? ['dropzone-error-border']
            : ['dx-theme-border-color']
        "
      >
        <div *ngIf="newsletterEnErrorMessage" class="error-text">
          {{ newsletterEnErrorMessage }}
        </div>

        <div *ngIf="newsletterEnSelectedFileName" class="selected-file-text">
          {{ newsletterEnSelectedFileName }}
        </div>

        <div
          *ngIf="!newsletterEnErrorMessage && !newsletterEnSelectedFileName"
          class="d-flex flex-column input-text"
        >
          <span>
            {{ formatMessage("news.upload.dragAndDrop") }}
          </span>
          <span>
            {{ formatMessage("news.upload.fileChooser") }}
          </span>
        </div>

        <dx-file-uploader
          #newsletterEN
          dialogTrigger="#newsletter-en-drop-zone-external"
          dropZone="#newsletter-en-drop-zone-external"
          [multiple]="false"
          accept="application/pdf"
          [maxFileSize]="MAX_UPLOAD_FILESIZE"
          uploadMode="useButton"
          [visible]="false"
          (onDropZoneEnter)="
            resetNewsletterEnDropzoneErrorValues();
            isNewsletterEnDropZoneActive = true
          "
          (onDropZoneLeave)="isNewsletterEnDropZoneActive = false"
          (onValueChanged)="handleNewsletterEnFileChanged()"
        ></dx-file-uploader>
      </div>
      <div
        class="required-error-text"
        *ngIf="newsletterEnRequiredError && !newsletterEnErrorMessage"
      >
        {{ formatMessage("validation.required") }}
      </div>
    </div>

    <div class="dropzone-container-left d-flex flex-column flex-grow">
      <span class="dropzone-label">
        {{ formatMessage("news.previewImage") }}
      </span>
      <div
        id="preview-image-drop-zone-external"
        class="
          dropzone-external
          d-flex
          flex-column flex-grow
          justify-content-center
          align-items-center
          text-center
        "
        [ngClass]="
          isPreviewImageDropZoneActive
            ? ['dx-theme-accent-as-border-color', 'dropzone-active']
            : showErrorBordersOnPreviewImage
            ? ['dropzone-error-border']
            : ['dx-theme-border-color']
        "
      >
        <div *ngIf="previewImageErrorMessage" class="error-text">
          {{ previewImageErrorMessage }}
        </div>

        <div *ngIf="previewImageSelectedFileName" class="selected-file-text">
          {{ previewImageSelectedFileName }}
        </div>

        <div
          *ngIf="!previewImageErrorMessage && !previewImageSelectedFileName"
          class="d-flex flex-column input-text"
        >
          <span>
            {{ formatMessage("news.upload.dragAndDrop") }}
          </span>
          <span>
            {{ formatMessage("news.upload.fileChooser") }}
          </span>
        </div>

        <dx-file-uploader
          #previewImage
          dialogTrigger="#preview-image-drop-zone-external"
          dropZone="#preview-image-drop-zone-external"
          [multiple]="false"
          [accept]="'image/png,image/jpg,image/jpeg,image/gif'"
          [maxFileSize]="MAX_UPLOAD_FILESIZE"
          uploadMode="useButton"
          [visible]="false"
          (onDropZoneEnter)="
            resetPreviewImageDropzoneErrorValues();
            isPreviewImageDropZoneActive = true
          "
          (onDropZoneLeave)="isPreviewImageDropZoneActive = false"
          (onValueChanged)="handlePreviewImageFileChanged()"
        ></dx-file-uploader>
      </div>
      <div
        class="required-error-text"
        *ngIf="previewImageRequiredError && !previewImageErrorMessage"
      >
        {{ formatMessage("validation.required") }}
      </div>
    </div>
  </div>

  <div footer class="d-flex align-items-center">
      <i
        class="dx-icon-warning conflict-icon"
        style="padding-right: 5px"
        [style]="maxFilesizeExceeded ? 'color: red;' : ''"
      ></i>
      <span
        class="conflict-text"
        [style]="maxFilesizeExceeded ? 'color: red;' : ''"
      >
        {{ formatMessage("news.popup.hint") }}
      </span>
  </div>
</app-action-popup>
