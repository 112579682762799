import { Component, OnDestroy, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { P3AuthService, ScreenService } from "../../shared/services";
import { OrderService } from "../../shared/services/order.service";
import {
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../shared/model/order/order";
import { TimeWindow } from "../../shared/model/timeWindow";
import {
  ACCOUNT_MANAGER,
  ADMIN,
  ADMIN_US,
  DATA_PROCESSING_PV,
  EXTERNAL_USER_ROLES,
  MANUFACTURER,
  PREPRESS_EMPLOYEE,
  SALES_MANAGER,
} from "../../shared/model/roles";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Upload } from "../../shared/model/upload/upload";
import { UploadService } from "../../shared/services/upload.service";
import { NewsService } from "../../shared/services/news.service";
import { Subscription } from "rxjs";
import { CalculationService } from "../../../../generated/api/services/calculation.service";
import { Calculation } from "../../../../generated/api/models/calculation";

enum HomeItems {
  FILE_DELIVERY_DATE = "FILE_DELIVERY_DATE",
  PREFLIGHTS = "PREFLIGHTS",
  UPLOADS = "UPLOADS",
  CALCULATIONS = "CALCULATIONS",
}

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  formatMessage = formatMessage;
  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;
  homeItems = HomeItems;

  isXSmall: boolean;
  isSmall: boolean;

  loading: boolean = true;
  loadingByTimeWindowChange: boolean = false;

  toastType: string = "success";
  toastMessage: string = "For some reason we need an initial value here...";
  showToast: boolean = false;

  greeting: string;
  userRole: string;

  isSalesManager: boolean;
  isAdmin: boolean;

  showCalculations: boolean = false;
  showOrderNumberDiso: boolean;
  isExternalUser: boolean;
  showReceiverContact: boolean;

  ordersByFileDeliveryDate: Order[];
  ordersByFailedPreflights: Order[];
  ordersByFailedPreflightsWithDuplicates: Order[] = [];
  lastUploads: Upload[];
  calculations: Calculation[] = [];
  unfilteredCalculations: Calculation[] = [];
  selectedItem: HomeItems = HomeItems.FILE_DELIVERY_DATE;

  timeWindows: TimeWindow[] = [
    TimeWindow.TODAY,
    TimeWindow.WEEK,
    TimeWindow.MONTH,
  ];
  selectedTimeWindow: TimeWindow;

  info: string = "";
  homeTextKey: string = "";

  lastNewsletterIds: string[] = [];

  isAllowedToCreateNewsElementSubscription: Subscription;
  isAllowedToCreateNewsElement: boolean;
  popupVisible: boolean = false;

  showConfirmationPopup: boolean = false;
  newsIdToDelete: string;

  constructor(
    private http: HttpClient,
    private authService: P3AuthService,
    private orderService: OrderService,
    private uploadService: UploadService,
    private screenService: ScreenService,
    private _p3AuthService: P3AuthService,
    private newsService: NewsService,
    private calculationService: CalculationService
  ) {
    this.translateTimeWindow = this.translateTimeWindow.bind(this);
  }

  ngOnInit(): void {
    this.isXSmall = this.screenService.sizes["screen-x-small"];
    this.isSmall = this.screenService.sizes["screen-small"];

    this.isAllowedToCreateNewsElementSubscription = this._p3AuthService
      .isAllowedToCreateNewsElement()
      .subscribe((data) => {
        this.isAllowedToCreateNewsElement = data;
      });

    this.setTimeWindowFromLocalStorageOrDefault();

    this.loadUserAndData();

    this.loadLastNewsletters();

    this.loadCalculations();
  }

  ngOnDestroy(): void {
    this.isAllowedToCreateNewsElementSubscription?.unsubscribe();
  }

  translateTimeWindow(timeWindow: string): string {
    return formatMessage("timeWindow.upper." + timeWindow);
  }

  showNoDataText(): boolean {
    return (
      (this.selectedItem === HomeItems.FILE_DELIVERY_DATE &&
        this.ordersByFileDeliveryDate?.length === 0) ||
      (this.selectedItem === HomeItems.PREFLIGHTS &&
        this.ordersByFailedPreflights?.length === 0) ||
      (this.selectedItem === HomeItems.UPLOADS &&
        this.lastUploads?.length === 0) ||
      (this.selectedItem === HomeItems.CALCULATIONS &&
        this.calculations?.length === 0)
    );
  }

  showErrorText(): boolean {
    return (
      (this.selectedItem === HomeItems.FILE_DELIVERY_DATE &&
        !this.ordersByFileDeliveryDate) ||
      (this.selectedItem === HomeItems.PREFLIGHTS &&
        !this.ordersByFailedPreflights) ||
      (this.selectedItem === HomeItems.UPLOADS && !this.lastUploads) ||
      (this.selectedItem === HomeItems.CALCULATIONS && !this.calculations)
    );
  }

  handleTimeWindowChange(): void {
    this.loadingByTimeWindowChange = true;

    localStorage.setItem("p3_time_window_home", this.selectedTimeWindow);

    this.loadData().finally(() => (this.loadingByTimeWindowChange = false));
  }

  private loadLastNewsletters(): void {
    this.newsService.loadLastNewsletters().then((data) => {
      this.lastNewsletterIds = data;
    });
  }

  private loadCalculations(): void {
    this.calculationService
      //.listCalculationsByArchivedState({ archivedState: "ACTIVE", toDo: true })
      .listCalculations({ toDo: true })
      .toPromise()
      .then((result) =>
        result.map((calculation) => ({
          ...calculation,
          bookFormat: `${calculation.width ?? "?"} x ${
            calculation.height ?? "?"
          }`,
        }))
      )
      .catch((e) => {
        throw e;
      })
      .then((data) => {
        this.unfilteredCalculations = [...data];
        this.calculations = this.filterCalculationsByDate(data);
      });
  }

  private filterCalculationsByDate(data: Calculation[]) {
    let time: Date = new Date();
    let dayInMilliseconds = 24 * 60 * 60 * 1000;

    switch (this.selectedTimeWindow) {
      case TimeWindow.TODAY:
        time = new Date();
        break;
      case TimeWindow.WEEK:
        time = new Date(time.setTime(time.getTime() - 6 * dayInMilliseconds));
        break;
      case TimeWindow.MONTH:
        time = new Date(time.setTime(time.getTime() - 29 * dayInMilliseconds));
        break;
    }

    let filteredCalculation: Calculation[] = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].updated || data[i].updated == undefined) {
        continue;
      }
      let updated = new Date(<string>data[i].updated);

      let today = new Date(
        new Date().setTime(time.getTime() - dayInMilliseconds)
      );

      if (today.getTime() <= updated.getTime()) {
        filteredCalculation.push(data[i]);
      }
    }
    return filteredCalculation;
  }

  private loadUserAndData(): void {
    this.authService
      .getUser()
      .toPromise()
      .then((user) => {
        this.calculateGreetings(
          user?.identity?.firstName,
          user?.identity?.lastName
        );

        this.userRole = user?.role ? user.role : "";

        this.customizeView();

        if (this.isAdmin) {
          // TODO concept for admin is currently missing.
          this.http
            .get<any>(environment.baseUrl + "/actuator/info")
            .toPromise()
            .then((data) => {
              this.info = JSON.stringify(data);
              this.loading = false;
            });
        } else {
          this.loadData().then(() => (this.loading = false));
        }

        // Sales Manager can only access the calculation tab, so it will be preselected
        if (this.userRole === SALES_MANAGER) {
          this.selectedItem = this.homeItems.CALCULATIONS;
        }
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("home.error");
        this.showToast = true;

        this.loading = false;
      });
  }

  private async loadData(): Promise<void> {
    try {
      if (this.isSalesManager) {
        this.calculations = this.filterCalculationsByDate(
          this.unfilteredCalculations
        );
        return;
      }
      this.ordersByFileDeliveryDate = await this.orderService
        .getOrdersByFileDeliveryDateInTimeWindow(this.selectedTimeWindow)
        .toPromise();

      this.ordersByFailedPreflightsWithDuplicates = await this.orderService
        .getOrdersWithFailedPreflightsByFileDeliveryDateInTimeWindow(
          this.selectedTimeWindow
        )
        .toPromise();

      this.ordersByFailedPreflights = [
        ...new Set(this.ordersByFailedPreflightsWithDuplicates),
      ];

      this.lastUploads = await this.uploadService
        .getActiveUploadsByUploadDateInTimeWindow(this.selectedTimeWindow)
        .toPromise();

      this.calculations = this.filterCalculationsByDate(
        this.unfilteredCalculations
      );
    } catch (e) {
      this.toastType = "error";
      this.toastMessage = formatMessage("home.error");
      this.showToast = true;
    }
  }

  private customizeView(): void {
    if (this.userRole === ACCOUNT_MANAGER || this.userRole === MANUFACTURER) {
      this.homeTextKey = "home.text.allItems";
    } else if (this.userRole === SALES_MANAGER) {
      this.homeTextKey = "home.text.onlyCalculation";
    } else {
      this.homeTextKey = "home.text.onlyOrdersAndUploads";
    }

    this.isSalesManager = this.userRole === SALES_MANAGER;
    this.isAdmin = this.userRole === ADMIN || this.userRole === ADMIN_US;

    this.showCalculations =
      this.userRole === ACCOUNT_MANAGER ||
      this.isSalesManager ||
      this.userRole === MANUFACTURER;

    this.showOrderNumberDiso = [
      ACCOUNT_MANAGER,
      DATA_PROCESSING_PV,
      PREPRESS_EMPLOYEE,
    ].includes(this.userRole);

    this.isExternalUser = EXTERNAL_USER_ROLES.includes(this.userRole);

    this.showReceiverContact = [DATA_PROCESSING_PV, PREPRESS_EMPLOYEE].includes(
      this.userRole
    );
  }

  private calculateGreetings(
    firstName: string | undefined = undefined,
    lastName: string | undefined = undefined
  ): void {
    const now = new Date();

    const isMorning =
      now.getHours() < 11 || (now.getHours() === 11 && now.getMinutes() <= 30);

    const isEvening = now.getHours() >= 18;

    const greetingPrefix = isMorning
      ? formatMessage("home.greeting.morning")
      : isEvening
      ? formatMessage("home.greeting.evening")
      : formatMessage("home.greeting");

    const name = firstName
      ? ", " + firstName + (lastName ? " " + lastName : "")
      : lastName
      ? ", " + lastName
      : "";

    this.greeting = greetingPrefix + name + "!";
  }

  private setTimeWindowFromLocalStorageOrDefault(): void {
    const item = localStorage.getItem("p3_time_window_home");

    if (
      item != null &&
      Object.values(TimeWindow).includes(item as TimeWindow)
    ) {
      this.selectedTimeWindow = item as TimeWindow;
    } else {
      this.selectedTimeWindow = TimeWindow.TODAY;
    }
  }

  handleNewsElementOnSave(): void {
    this.toastType = "success";
    this.toastMessage = formatMessage("news.create.success");
    this.showToast = true;

    this.loadLastNewsletters();
  }

  handleNewsElementOnError(): void {
    this.toastType = "error";
    this.toastMessage = formatMessage("news.create.error");
    this.showToast = true;
  }

  handleOnDelete(newsId: string) {
    this.newsIdToDelete = newsId;
    this.showConfirmationPopup = true;
  }

  executeDeleteOperation() {
    this.newsService
      .deleteNewsletter(this.newsIdToDelete)
      .then((_) => {
        this.toastType = "success";
        this.toastMessage = formatMessage("news.delete.success");
        this.showToast = true;

        this.loadLastNewsletters();
      })
      .catch((e) => {
        this.toastType = "error";
        this.toastMessage = formatMessage("news.delete.error");
        this.showToast = true;
      })
      .finally(() => {
        this.showConfirmationPopup = false;
      });
  }
}
