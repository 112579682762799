import { Component, OnChanges, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Paperdefinition } from "../../shared/model/calculation/paperdefinition";
import {
  OrganizationType,
  PublisherGroupForm,
} from "../../shared/model/organization/organizationFormData";
import { PaperService } from "../../shared/services/paper.service";
import { ProduktFormData } from "../../shared/model/calculation/produktFormData";
import { OrganizationFormComponent } from "../../shared/components/organization-management/organization-form/organization-form.component";
import { PapierFormComponent } from "../../shared/components/papier-management/papier-form/papier-form.component";
import { PapierFormData } from "../../shared/model/calculation/papierFormData";
import {
  Organization,
  PublisherGroup,
} from "../../shared/model/organization/organization";
import { Produkt } from "../../shared/model/calculation/produkt";

const enum OperationTypes {
  DELETE,
  CHANGE_ENABLED_DISABLED_STATE,
}

@Component({
  selector: "app-papier-management",
  templateUrl: "./papier-management.component.html",
  styleUrls: ["./papier-management.component.scss"],
})
export class PapierManagementComponent implements OnInit {
  @ViewChild(PapierFormComponent)
  paperForm: PapierFormComponent;

  formatMessage = formatMessage;
  papierPopupVisible = false;
  papierFormData = <Paperdefinition>{};

  allPapers: Paperdefinition[];

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  selectedPaperId;
  title;
  editing: boolean;

  confirmOperation: OperationTypes;
  confirmationPopupTitle: string;
  confirmationPopupMessage: string;

  showConfirmationPopup: boolean = false;
  confirmButtonText: string;

  certificateFilter: any[] = [];

  constructor(private paperService: PaperService) {}

  save() {
    if (!this.paperForm.isValid()) {
      return;
    } else {
      this.papierFormData.volumen = Number(
        this.papierFormData.volumen.toFixed(2)
      );
      this.papierFormData.price = Number(this.papierFormData.price.toFixed(2));

      if (this.editing) {
        this.paperService
          .updatePaperdefinition(this.papierFormData)
          .subscribe((value) => {
            console.log(value);
            this.getPaperValues();
          });
        this.papierPopupVisible = false;
        this.editing = false;
      } else {
        // create new product
        console.log(this.papierFormData);
        this.paperService
          .createPaperdefinition(this.papierFormData)
          .subscribe((value) => {
            console.log(value);
            this.getPaperValues();
          });
        this.papierPopupVisible = false;
        this.editing = false;
      }
    }
  }

  resetValues(): void {
    this.paperForm?.resetValues();
  }

  updatePaper(paper: Paperdefinition): void {
    this.editing = true;
    this.title = formatMessage("paperForm.title.edit");
    this.papierFormData = { ...paper };
    this.selectedPaperId = { ...paper }.id;
    this.papierPopupVisible = true;
  }

  resetRequiredFieldsValidation(): void {
    setTimeout(() => {
      this.paperForm?.form?.instance.getEditor("name")?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("grammatur")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("volumen")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("price")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("rollenBogenPapier")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("stoffklasse")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("faerbung")
        ?.option("isValid", true);
      this.paperForm?.form?.instance
        .getEditor("paperCertification")
        ?.option("isValid", true);
    }, 1);
  }

  createPaper() {
    this.editing = false;
    this.title = formatMessage("paperForm.title.create");
    this.papierFormData = <Paperdefinition>{};
    this.selectedPaperId = "";
    this.papierPopupVisible = true;
  }

  ngOnInit(): void {
    this.getPaperValues();
  }

  getPaperValues() {
    this.paperService
      .findAllPapers()
      .toPromise()
      .then(
        (data) => (
          (this.allPapers = [...data]), this.setCertificateFilter(data)
        )
      );
  }

  showConfirmationPopupForDeletePaper(currentPaper: Paperdefinition) {
    let papier = { ...currentPaper };
    this.selectedPaperId = papier.id;

    this.confirmationPopupTitle = formatMessage(`paper.delete.confirm.title`);
    this.confirmationPopupMessage = formatMessage(
      `paper.delete.confirm.message`,
      papier.name
    );
    this.confirmButtonText = formatMessage("delete");

    this.confirmOperation = OperationTypes.DELETE;
    this.showConfirmationPopup = true;
  }

  deletePaperAndUpdateView(): void {
    if (!this.selectedPaperId) return;

    this.paperService
      .deletePaper(this.selectedPaperId)
      .toPromise()
      .then(() => {
        this.removePaperFromView();

        this.toastType = "success";
        this.toastMessage = formatMessage("paper.delete.success");
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("paper.delete.error");
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  removePaperFromView() {
    const selectedPaperIndex = this.allPapers.findIndex(
      (it) => it.id === this.selectedPaperId
    );

    if (selectedPaperIndex !== -1) {
      this.allPapers.splice(selectedPaperIndex, 1);
    }
  }

  setCertificateFilter(data: any): void {
    let allCertificates: string[] = [];

    for (let paper of data) {
      if (paper.paperCertificationList.length > 0) {
        allCertificates.indexOf(paper.paperCertificationList[0].name) == -1
          ? allCertificates.push(paper.paperCertificationList[0].name)
          : null;
      }
    }

    allCertificates.sort();

    for (let certificate of allCertificates) {
      this.certificateFilter.push({
        text: certificate,
        value: ["paperCertificationList[0].name", "=", certificate],
      });
    }

    this.certificateFilter.push({
      text: formatMessage("paperDataGrid.paperCertificationList.empty"),
      value: ["paperCertificationList[0].name", "=", null],
    });
  }
}
