import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DxButtonModule, DxLoadIndicatorModule } from "devextreme-angular";
import { CommonModule } from "@angular/common";
import { formatDate, formatMessage } from "devextreme/localization";
import { LocaleService } from "../../../services/locale.service";
import { NewsService } from "../../../services/news.service";

@Component({
  selector: "app-news-element-tile",
  templateUrl: "./news-element-tile.component.html",
  styleUrls: ["./news-element-tile.component.scss"],
})
export class NewsElementTileComponent implements OnInit {
  formatMessage = formatMessage;
  @Input()
  newsId: string;

  @Input() showDeleteIcon: boolean;
  @Output() onDelete = new EventEmitter<string>();

  currentLocale: string | null;

  previewImage;
  title: string = "";
  description: string = "";
  createdDate: string = "";

  showNewTabIcon: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private localeService: LocaleService,
    private newsService: NewsService
  ) {}

  ngOnInit(): void {
    this.loadLocalizedNewsletter();
  }

  private loadLocalizedNewsletter() {
    this.localeService.getCurrentLocale().subscribe((locale) => {
      this.currentLocale = locale;

      this.newsService.loadNewsletterInfo(this.newsId).then((data) => {
        this.setLocalizedNewsInformation(data);
      });

      this.newsService.loadNewsletterContent(
        this.newsId,
        "contentPreview",
        (data) =>
          (this.previewImage = this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(data)
          ))
      );
    });
  }

  private setLocalizedNewsInformation(data: any): void {
    if (this.currentLocale === "de-DE") {
      this.title = data.titleDE;
      this.description = data.descriptionDE;
    } else {
      this.title = data.titleEN;
      this.description = data.descriptionEN;
    }
    this.createdDate = formatDate(new Date(data.createdDate), "shortDate");
  }

  openNewsletter(): void {
    this.newsService.loadNewsletterContent(
      this.newsId,
      this.currentLocale === "de-DE" ? "contentDE" : "contentEN",
      (data) => window.open(URL.createObjectURL(data))
    );
  }
}

@NgModule({
  imports: [DxButtonModule, DxLoadIndicatorModule, CommonModule],
  declarations: [NewsElementTileComponent],
  exports: [NewsElementTileComponent],
})
export class NewsElementKachelModule {}
