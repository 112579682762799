import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Auth } from "aws-amplify";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { User } from "../model/user/user";
import { UserService } from "./user.service";
import {
  ACCOUNT_MANAGER,
  ADMIN,
  ADMIN_US,
  INTERNAL_USER_ROLES,
  MANUFACTURER,
  SALES_MANAGER,
} from "../model/roles";
import {
  isPublisher,
  isPublisherGroup,
  Organization,
  Publisher,
} from "../model/organization/organization";
import { OrganizationService } from "./organization.service";
import { TreeViewInstanceService } from "./treeViewInstance.service";
import {
  navigation_de,
  navigation_en,
  navigation_us,
} from "../../app-navigation";
import { flatMap } from "rxjs/internal/operators";

export interface IUser {
  email: string;
  username: string;
}

const defaultPath = "/";
const defaultUser = {
  username: "",
  email: "",
};

@Injectable()
export class AuthService {
  private _user: IUser | null = defaultUser;
  get loggedIn(): boolean {
    return !!this._user;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(private router: Router) {}

  async logIn(email: string, password: string) {
    try {
      // Send request
      console.log(email, password);
      this._user = { ...defaultUser, email };
      this.router.navigate([this._lastAuthenticatedPath]);

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  }

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
        data: null,
      };
    }
  }

  async createAccount(email: string, password: string) {
    try {
      // Send request
      console.log(email, password);

      this.router.navigate(["/create-account"]);
      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  }

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  }

  async resetPassword(email: string) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  }

  async logOut() {
    this._user = null;
    this.router.navigate(["/login-form"]);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      "login-form",
      "reset-password",
      "create-account",
      "change-password/:recoveryCode",
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(["/login-form"]);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath =
        route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}
@Injectable()
export class P3AuthService {
  private _user: IUser | null = defaultUser;
  get loggedIn(): boolean {
    return true;
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {}

  async getJwtToken(): Promise<String> {
    try {
      let session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch {
      return "";
    }
  }

  getUser(): Observable<User> {
    let sub = new BehaviorSubject<User>({
      id: "",
      identity: {
        sub: "",
        loginName: "",
        firstName: "",
        lastName: "",
        email: "",
        isInternal: false,
        enabled: false,
        locale: "",
        userCreateDate: new Date(),
        userLastModifiedDate: new Date(),
      },
      newsletterStatus: { subscribed: "" },
      role: "",
      worksFor: [],
      notification: true,
    });
    // Send request
    let user = this.userService.findOwnUser();
    user.subscribe(sub);
    //return sub.pipe(tap((item) => console.log(item)));
    return sub;
  }

  isAllowedToViewOrderDetails(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user != null &&
            [MANUFACTURER, ...INTERNAL_USER_ROLES].includes(user.role)
        )
      );
  }

  isAllowedToGenerateUploadLinks(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user != null &&
            [MANUFACTURER, ...INTERNAL_USER_ROLES].includes(user.role)
        )
      );
  }

  isAllowedToAuthorizeInternalUsers(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(map((user) => user?.role === ADMIN || user?.role === ADMIN_US));
  }

  isAllowedToDownloadUploads(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user != null &&
            [MANUFACTURER, ...INTERNAL_USER_ROLES].includes(user.role)
        )
      );
  }

  isAllowedToReactivateUpload(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user?.role === ADMIN ||
            user?.role === ACCOUNT_MANAGER ||
            user?.role === ADMIN_US
        )
      );
  }

  isAllowedToChangeOrdersStudiosAssignments(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user?.role === ADMIN ||
            user?.role === ACCOUNT_MANAGER ||
            user?.role === MANUFACTURER ||
            user?.role === ADMIN_US
        )
      );
  }

  isAllowedToViewLogging(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(map((user) => user?.role === ADMIN));
  }

  isAllowedToViewD3Documents(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user?.role === ADMIN ||
            user?.role === ACCOUNT_MANAGER ||
            user?.role === SALES_MANAGER ||
            user?.role === SALES_MANAGER ||
            user?.role === MANUFACTURER ||
            user?.role === ADMIN_US
        )
      );
  }

  isAllowedToCreateNewsElement(): Observable<boolean> {
    return this.userService
      .findOwnUser()
      .pipe(
        map(
          (user) =>
            user?.role === ADMIN ||
            user?.role === SALES_MANAGER ||
            user?.role === ADMIN_US
        )
      );
  }

  isLoggedIn(): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  logOut() {
    console.log("logOut");
    Auth.signOut();
  }

  getNavigationForCurrentUser(): Observable<any> {
    return this.userService.findOwnUser().pipe(
      flatMap((user) => {
        let navigation =
          user?.identity?.locale == "de-DE"
            ? navigation_de
            : user?.identity?.locale == "en-EN"
            ? navigation_en
            : navigation_us;

        navigation = navigation.filter((item) => {
          return P3AuthService.isAllowedToSeeNavigationItem(
            user,
            item.allowedRoles
          );
        });

        navigation = navigation.map((item) => {
          if (item.items && item.items.length > 0) {
            const filteredSubItems = item.items.filter((it) =>
              P3AuthService.isAllowedToSeeNavigationItem(user, it.allowedRoles)
            );

            return {
              ...item,
              items: filteredSubItems,
            };
          } else {
            return item;
          }
        });
        return of(navigation);
      })
    );
  }

  private static isAllowedToSeeNavigationItem(
    user: User | null,
    allowedRoles: string[] | undefined
  ): boolean {
    if (!allowedRoles) return true;

    if (!user) return false;

    return allowedRoles.includes(user.role);
  }
}

@Injectable()
export class P3AuthGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _p3AuthService: P3AuthService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this._p3AuthService.isLoggedIn()) {
      // Do we need this check?
      this.navigateAndResetTreeView("/notauthorized");
      return new Promise((resolve) => resolve(false));
    }

    if (next.url.findIndex((it) => it.path === "welcome") != -1) {
      return this.canActivateWelcome();
    }

    if (!next.data || Object.keys(next.data).length === 0) {
      return new Promise((resolve) => resolve(true));
    }

    return new Promise((resolve) => {
      this.userService
        .findOwnUser()
        .toPromise()
        .then((user) => {
          if (!user) {
            this.navigateAndResetTreeView("/welcome");
            resolve(false);
            return;
          }

          if (!next.data.allowedRoles.includes(user.role)) {
            this.navigateAndResetTreeView("/notauthorized");
            resolve(false);
            return;
          }
          /*
          if (
            next.url.findIndex((it) => it.path === "kalkulationen") != -1 &&
            user.role === MANUFACTURER
          ) {
            this.canManufacturerActivateCalculation(user.worksFor, resolve);
            return;
          }
          */

          resolve(true);
        })
        .catch(() => {
          this.navigateAndResetTreeView("/notauthorized");
          resolve(false);
        });
    });
  }

  private canActivateWelcome(): Promise<boolean> {
    return new Promise((resolve) => {
      this.userService
        .findOwnUser()
        .toPromise()
        .then((user) => {
          if (!user) {
            resolve(true);
          } else {
            this._router.navigate(["/"]);
            resolve(false);
          }
        })
        .catch(() => {
          this.navigateAndResetTreeView("/notauthorized");
          resolve(false);
        });
    });
  }

  private navigateAndResetTreeView(path: string) {
    this.treeViewInstanceService?.getTreeViewInstance()?.unselectAll();
    this._router.navigate([path]);
  }
}
