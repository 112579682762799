<div class="content-block" id="container">
  <h2>{{ formatMessage("paperCosts.title") }}</h2>

  <div class="d-flex align-items-center">
    <div style="margin-right: 0.5rem">
      {{ formatMessage("paperCosts.selectedPriceList") }}
    </div>
    <dx-lookup
      *ngIf="preislistenKataloge"
      [dataSource]="preislistenKataloge"
      displayExpr="name"
      style="min-width: 200px"
      [value]="selectedPreislistenKatalog"
      [searchEnabled]="true"
      (onValueChanged)="preislistenKatalogSelected($event)"
    >
    </dx-lookup>
  </div>

  <div *ngIf="selectedPreislistenKatalog">
    <dx-speed-dial-action
      [elementAttr]="{ id: 'faButton' }"
      *ngIf="!isMobile; else textButton"
      icon="add"
      (onClick)="createHandlingCost()"
    ></dx-speed-dial-action>

    <ng-template #textButton>
      <dx-button
        stylingMode="contained"
        type="default"
        icon="add"
        [text]="formatMessage('produkt.create')"
        style="margin-bottom: 12px; width: 100%"
        (onClick)="createHandlingCost()"
      ></dx-button>
    </ng-template>

    <p>&nbsp;</p>
    <div>
      <dx-check-box
        [value]="paperConsumption"
        [text]="formatMessage('paperCosts.paperConsumption')" 
        (onValueChanged)="updatePaperConsumption($event)"     
        class="dx-card wide-card"
      ></dx-check-box>
    </div>
  
    <p>&nbsp;</p>
    <div>
      <dx-check-box
        [value]="paperPrice"
        [text]="formatMessage('paperCosts.paperPrice')" 
        (onValueChanged)="updatePaperPrice($event)"     
        class="dx-card wide-card"
      ></dx-check-box>
    </div>
  
    <p>&nbsp;</p>
    <h4>{{ formatMessage("paperCosts.paperCosts.title") }}</h4>

    <dx-data-grid
      id="user-grid"
      [dataSource]="paperCosts"
      (onContentReady)="placeFloatingActionButton()"
      [columnAutoWidth]="!isMobile"
      [columnHidingEnabled]="true"
      class="dx-card wide-card"
    >
      <dxo-load-panel [enabled]="true"></dxo-load-panel>

      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [allowedPageSizes]="[15, 30, 50, 'all']"
      ></dxo-pager>

      <dxo-header-filter
        [visible]="false"
        [allowSearch]="false"
        [texts]="{
          emptyValue: formatMessage('produkt.dataGrid.headerFilter.emptyValue')
        }"
      ></dxo-header-filter>

      <dxo-filter-row [visible]="false"></dxo-filter-row>

      <dxi-column
        dataField="minWeight"
        [caption]="formatMessage('paperCosts.minWeight')"
        [allowHeaderFiltering]="false"
      ></dxi-column>
      <dxi-column
        dataField="maxWeight"
        [caption]="formatMessage('paperCosts.maxWeight')"
        [allowHeaderFiltering]="false"
      ></dxi-column>
      <dxi-column
        dataField="price"
        [caption]="formatMessage('paperCosts.price')"
        [allowHeaderFiltering]="false"
      ></dxi-column>

      <dxi-column
        [caption]="formatMessage('other.actions')"
        [allowHeaderFiltering]="false"
        type="buttons"
        alignment="center"
        cellTemplate="actionsTemplate"
      >
        <div *dxTemplate="let data of 'actionsTemplate'">
          <dx-button
            class="action-button"
            icon="edit"
            [hint]="formatMessage('produkt.navigate.details')"
            stylingMode="text"
            type="default"
            style="margin-right: 8px"
            (onClick)="editHandlingCost(data.data)"
          ></dx-button>

          <dx-button
            class="action-button"
            icon="trash"
            [hint]="formatMessage('delete')"
            stylingMode="text"
            type="default"
            (onClick)="deleteHandlingCost(data.data)"
          ></dx-button>
        </div>
      </dxi-column>
      <dxo-toolbar>
        <dxi-item
          location="after"
          cssClass="toolbar-export-button"
          name="exportButton"
        ></dxi-item>
      </dxo-toolbar>
      <dxo-export
        [enabled]="true"
        [fileName]="formatMessage('produkt.export.filename')"
      ></dxo-export>
    </dx-data-grid>
  </div>
</div>

<app-papercosts-form-popup
  [(visible)]="paperCostsPopupVisible"
  [paperCosts]="paperHandlingPrice"
  (onSave)="savePaperCosts($event)"
></app-papercosts-form-popup>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>

