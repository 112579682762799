import { Component, OnInit, ViewChild } from "@angular/core";
import {
  OrganizationFormData,
  OrganizationType,
  PublisherForm,
  PublisherGroupForm,
  StudioForm,
} from "../../shared/model/organization/organizationFormData";
import {
  Organization,
  Publisher,
  PublisherGroup,
  Studio,
} from "../../shared/model/organization/organization";
import { formatMessage } from "devextreme/localization";
import { OrganizationService } from "../../shared/services/organization.service";
import { OrganizationFormComponent } from "../../shared/components/organization-management/organization-form/organization-form.component";
import { ScreenService } from "../../shared/services";
import { OrderService } from "../../shared/services/order.service";
import { PreislistenKatalog } from "../../shared/model/calculation/produkt";
import { ProduktService } from "../../shared/services/produkt.service";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-organization-management",
  templateUrl: "./organization-management.component.html",
  styleUrls: ["./organization-management.component.scss"],
})
export class OrganizationManagementComponent implements OnInit {
  @ViewChild(OrganizationFormComponent)
  organizationForm: OrganizationFormComponent;

  isMediumOrDownScreen: boolean;

  organizationType = OrganizationType;

  allPublisher: Publisher[];
  allPublisherGroups: PublisherGroup[] = [];
  allStudios: Studio[] = [];

  allPreislistenKataloge: PreislistenKatalog[] = [];

  formatMessage = formatMessage;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  showOrganizationPopup: boolean = false;
  organizationPopupTitle: string;

  showConfirmationPopup: boolean = false;
  confirmationPopupTitle: string;
  confirmationPopupMessage: string;

  selectedOrganization: OrganizationFormData;
  selectedOrganizationType: OrganizationType;
  selectedOrganizationId: string | undefined = undefined;

  enviromentIsUsa = environment.isUsa;

  constructor(
    private organizationService: OrganizationService,
    private orderService: OrderService,
    private screen: ScreenService,
    private produktService: ProduktService
  ) {}

  ngOnInit(): void {
    this.isMediumOrDownScreen = this.screen.sizes["screen-medium-or-down"];

    this.organizationService
      .findAllPublisher()
      .toPromise()
      .then((data) => (this.allPublisher = [...data]))
      .catch(() =>
        this.showError(
          formatMessage("organization.find.error." + OrganizationType.PUBLISHER)
        )
      );

    this.organizationService
      .findAllPublisherGroups()
      .toPromise()
      .then((data) => {
        this.allPublisherGroups = [...data];
        this.sortPublisherGroupsByDisoNumber();
      })
      .catch(() =>
        this.showError(
          formatMessage(
            "organization.find.error." + OrganizationType.PUBLISHER_GROUP
          )
        )
      );

    this.organizationService
      .findAllStudios()
      .toPromise()
      .then((data) => (this.allStudios = [...data]))
      .catch(() =>
        this.showError(
          formatMessage("organization.find.error." + OrganizationType.STUDIO)
        )
      );

    this.loadPreislistenKataloge();
  }

  async loadPreislistenKataloge(): Promise<void> {
    try {
      this.allPreislistenKataloge = await this.produktService
        .findAllPreislistenKataloge()
        .toPromise();

      this.sortPreislistenKataloge();
    } catch (e) {
      console.log(e);
    }
  }

  sortPreislistenKataloge(): void {
    this.allPreislistenKataloge.sort(
      (preislistenKatalog1, preislistenKatalog2) =>
        this.comparePreislistenKatalogeByName(
          preislistenKatalog1,
          preislistenKatalog2
        )
    );
  }

  comparePreislistenKatalogeByName(
    preislistenKatalog1: PreislistenKatalog,
    preislistenKatalog2: PreislistenKatalog
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      preislistenKatalog1.name,
      preislistenKatalog2.name
    );
  }

  sortPublisherGroupsByDisoNumber(): void {
    this.allPublisherGroups.sort((publisherGroup1, publisherGroup2) => {
      const disoNumber1 = publisherGroup1.disoNumber;
      const disoNumber2 = publisherGroup2.disoNumber;

      return disoNumber1 < disoNumber2 ? -1 : disoNumber1 > disoNumber2 ? 1 : 0;
    });
  }

  showError(error: string) {
    this.toastMessage = error;
    this.toastType = "error";
    this.showToast = true;
  }

  createOrganization(type: OrganizationType): void {
    if (type === OrganizationType.PUBLISHER_GROUP) {
      this.selectedOrganization = new PublisherGroupForm();
    } else if (type === OrganizationType.PUBLISHER) {
      this.selectedOrganization = new PublisherForm();
    } else {
      this.selectedOrganization = new StudioForm();
    }

    this.selectedOrganizationType = type;
    this.organizationPopupTitle = formatMessage("organization.create." + type);
    this.selectedOrganizationId = undefined;
    this.showOrganizationPopup = true;
  }

  updatePublisherGroup(organization: Organization): void {
    this.selectedOrganizationType = OrganizationType.PUBLISHER_GROUP;
    this.selectedOrganization = new PublisherGroupForm(
      organization as PublisherGroup
    );

    this.showUpdateOrganizationPopup(organization.id);
  }

  updatePublisher(organization: Organization): void {
    this.selectedOrganizationType = OrganizationType.PUBLISHER;
    this.selectedOrganization = new PublisherForm(organization as Publisher);

    this.showUpdateOrganizationPopup(organization.id);
  }

  updateStudio(organization: Organization): void {
    this.selectedOrganizationType = OrganizationType.STUDIO;
    this.selectedOrganization = new StudioForm(organization as Studio);

    this.showUpdateOrganizationPopup(organization.id);
  }

  showUpdateOrganizationPopup(organizationId: string): void {
    this.selectedOrganizationId = organizationId;
    this.organizationPopupTitle = formatMessage(
      "organization.update." + this.selectedOrganizationType
    );
    this.showOrganizationPopup = true;
  }

  save(): void {
    if (!this.organizationForm.isValid()) {
      return;
    }

    if (!!this.selectedOrganizationId) {
      this.organizationService
        .updateOrganization(
          this.selectedOrganizationId,
          this.selectedOrganization
        )
        .toPromise()
        .then((data) => {
          this.addUpdatedOrganization(data);

          this.toastMessage = formatMessage(
            "organization.update.success." + this.selectedOrganizationType
          );
          this.toastType = "success";
        })
        .catch((e) => {
          if (e.message === "ALREADY_EXISTS") {
            this.toastMessage = formatMessage(
              "organization.error.ALREADY_EXISTS." +
                this.selectedOrganizationType
            );
          } else {
            this.toastMessage = formatMessage(
              "organization.update.error." + this.selectedOrganizationType
            );
          }

          this.toastType = "error";
        })
        .finally(() => {
          this.showToast = true;
          this.showOrganizationPopup = false;
        });
    } else {
      this.organizationService
        .createOrganization(this.selectedOrganization)
        .toPromise()
        .then((data) => {
          this.addCreatedOrganization(data);

          this.toastMessage = formatMessage(
            "organization.create.success." + this.selectedOrganizationType
          );
          this.toastType = "success";
        })
        .catch((e) => {
          if (e.message === "ALREADY_EXISTS") {
            this.toastMessage = formatMessage(
              "organization.error.ALREADY_EXISTS." +
                this.selectedOrganizationType
            );
          } else {
            this.toastMessage = formatMessage(
              "organization.create.error." + this.selectedOrganizationType
            );
          }

          this.toastType = "error";
        })
        .finally(() => {
          this.showToast = true;
          this.showOrganizationPopup = false;
        });
    }
  }

  showConfirmDeletePopup(
    organization: Organization,
    organizationType: OrganizationType
  ): void {
    this.selectedOrganizationType = organizationType;
    this.selectedOrganizationId = organization.id;

    this.confirmationPopupTitle = this.formatMessage(
      "organization.delete." + this.selectedOrganizationType
    );
    this.confirmationPopupMessage = this.formatMessage(
      "organization.delete." +
        this.selectedOrganizationType +
        ".confirmationMessage"
    );
    this.showConfirmationPopup = true;
  }

  deleteOrganization(): void {
    if (!!this.selectedOrganizationId) {
      if (this.selectedOrganizationType === OrganizationType.PUBLISHER_GROUP) {
        this.deletePublisherGroup(this.selectedOrganizationId);
      } else if (this.selectedOrganizationType === OrganizationType.PUBLISHER) {
        this.deletePublisher(this.selectedOrganizationId);
      } else {
        this.deleteStudio(this.selectedOrganizationId);
      }
    }
  }

  deletePublisherGroup(publisherGroupId: string): void {
    this.organizationService
      .deletePublisherGroup(publisherGroupId)
      .toPromise()
      .then(() => {
        this.removeDeletedPublisherGroup(publisherGroupId);

        this.toastMessage = formatMessage(
          "organization.delete.success.publisherGroup"
        );
      })
      .catch(() => {
        this.toastMessage = formatMessage(
          "organization.delete.error.publisherGroup"
        );
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  deletePublisher(publisherId: string): void {
    this.organizationService
      .deletePublisher(publisherId)
      .toPromise()
      .then(() => {
        this.removeDeletedPublisher(publisherId);

        this.toastMessage = formatMessage(
          "organization.delete.success.publisher"
        );
      })
      .catch(() => {
        this.toastMessage = formatMessage(
          "organization.delete.error.publisher"
        );
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  deleteStudio(studioId: string): void {
    this.organizationService
      .deleteStudio(studioId)
      .toPromise()
      .then(() => {
        this.removeDeletedStudio(studioId);

        this.toastMessage = formatMessage("organization.delete.success.studio");
      })
      .catch(() => {
        this.toastMessage = formatMessage("organization.delete.error.studio");
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  addCreatedOrganization(organization: Organization): void {
    if (this.selectedOrganizationType === OrganizationType.PUBLISHER_GROUP) {
      this.allPublisherGroups.unshift(organization as PublisherGroup);
      this.sortPublisherGroupsByDisoNumber();
    } else if (this.selectedOrganizationType === OrganizationType.PUBLISHER) {
      this.allPublisher.unshift(organization as Publisher);
    } else {
      this.allStudios.unshift(organization as Studio);
    }
  }

  addUpdatedOrganization(organization: Organization): void {
    if (this.selectedOrganizationType === OrganizationType.PUBLISHER_GROUP) {
      this.addUpdatedPublisherGroup(organization as PublisherGroup);
    } else if (this.selectedOrganizationType === OrganizationType.PUBLISHER) {
      this.addUpdatedPublisher(organization as Publisher);
    } else {
      this.addUpdatedStudio(organization as Studio);
    }
  }

  addUpdatedPublisherGroup(publisherGroup: PublisherGroup): void {
    const index = this.allPublisherGroups.findIndex(
      (it) => it.id === publisherGroup.id
    );
    this.allPublisherGroups.splice(index, 1, publisherGroup);
    this.sortPublisherGroupsByDisoNumber();

    this.allPublisher = this.allPublisher.map((it) => {
      if (!!it.publisherGroup && it.publisherGroup.id === publisherGroup.id) {
        return { ...it, publisherGroup: publisherGroup };
      } else {
        return it;
      }
    }) as Publisher[];
  }

  addUpdatedPublisher(publisher: Publisher): void {
    const index = this.allPublisher.findIndex((it) => it.id === publisher.id);

    this.allPublisher.splice(index, 1, publisher);
  }

  addUpdatedStudio(studio: Studio): void {
    const index = this.allStudios.findIndex((it) => it.id === studio.id);
    this.allStudios.splice(index, 1, studio);

    this.allPublisher.forEach((it) => {
      const indexOfStandardStudio = it.standardStudios.findIndex(
        (standardStudio) => standardStudio.id === studio.id
      );

      if (indexOfStandardStudio > -1) {
        it.standardStudios.splice(indexOfStandardStudio, 1, studio);
      }
    });
  }

  removeDeletedPublisherGroup(publisherGroupId: string): void {
    const index = this.allPublisherGroups.findIndex(
      (it) => it.id === publisherGroupId
    );
    this.allPublisherGroups.splice(index, 1);

    this.allPublisher = this.allPublisher.map((it) => {
      if (!!it.publisherGroup && it.publisherGroup.id === publisherGroupId) {
        return { ...it, publisherGroup: null };
      } else {
        return it;
      }
    }) as Publisher[];
  }

  removeDeletedPublisher(publisherId: string): void {
    const index = this.allPublisher.findIndex((it) => it.id === publisherId);
    this.allPublisher.splice(index, 1);
  }

  removeDeletedStudio(studioId: string): void {
    const index = this.allStudios.findIndex((it) => it.id === studioId);
    this.allStudios.splice(index, 1);

    this.allPublisher.forEach((it) => {
      const indexOfStandardStudio = it.standardStudios.findIndex(
        (standardStudio) => standardStudio.id === studioId
      );

      if (indexOfStandardStudio > -1) {
        it.standardStudios.splice(indexOfStandardStudio, 1);
      }
    });
  }

  resetValues(): void {
    this.organizationForm?.resetValues();
  }

  resetRequiredFieldsValidation(): void {
    setTimeout(() => {
      this.organizationForm?.form?.instance
        .getEditor("disoNumber")
        ?.option("isValid", true);
      this.organizationForm?.form?.instance
        .getEditor("name")
        ?.option("isValid", true);
    }, 1);
  }
}
