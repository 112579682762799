<dx-data-grid [dataSource]="dataSource" keyExpr="quantity">
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-column-chooser [enabled]="true" mode="select"> </dxo-column-chooser>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-load-panel [enabled]="true"> </dxo-load-panel>
  <dxo-paging [pageSize]="10"> </dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25]"
    [showNavigationButtons]="true"
    [visible]="true"
    [showInfo]="true"
  >
  </dxo-pager>
  <dxi-column dataField="pages"></dxi-column>
  <dxi-column dataField="width"></dxi-column>
  <dxi-column dataField="height"></dxi-column>
  <dxi-column dataField="quantity" sortOrder="asc"></dxi-column>
  <dxi-column dataField="paperId"></dxi-column>
  <dxi-column dataField="imposeOutputCtp"></dxi-column>
  <dxi-column dataField="proofBook"></dxi-column>
  <dxi-column dataField="plates"></dxi-column>
  <dxi-column dataField="textMr"></dxi-column>
  <dxi-column dataField="textRun"></dxi-column>
  <dxi-column dataField="bindingMr"></dxi-column>
  <dxi-column dataField="bindingRun"></dxi-column>
  <dxi-column dataField="additionals"></dxi-column>
</dx-data-grid>
