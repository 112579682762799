import { gql } from "apollo-angular";

export const GET_ORDERS = gql`
  query orders {
    orders {
      id
      sender {
        publisher
        contact
        email
      }
      receiver {
        contact
        email
      }
      status {
        status
      }
      order {
        idtitle
        ordernumberdiso
        orderdate
        filedeliverydate
        customernumber
        titleinfo {
          category
          title
          isbn
          author
        }
        edition {
          number
        }
        delivery {
          datelatest
          quantity
        }
        textpartprint {
          blocklength
          blockwidth
        }
      }
      publisher {
        standardStudios {
          id
          displayName
        }
      }
      studios {
        id
        displayName
      }
    }
  }
`;

export const GET_ORDER_EVENTS = gql`
  query orderEvents($id: String!) {
    orderEvents(id: $id) {
      payload
      timestamp
      type
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: String!) {
    order(id: $id) {
      id
      sender {
        publisher
        contact
        email
      }
      status {
        status
      }
      receiver {
        contact
        email
      }
      order {
        idtitle
        ordernumberdiso
        orderdate
        filedeliverydate
        customernumber
        titleinfo {
          category
          title
          isbn
          author
          pages
        }
        totalprintrun
        binding {
          bindingtype
          adhesivetype
          spinetype
        }
        edition {
          number
        }
        delivery {
          datelatest
        }
        automaticorder
        textpartprint {
          blocklength
          blockwidth
        }
      }
      publisher {
        standardStudios {
          createdDate
          lastModifiedDate
          id
          displayName
        }
      }
      studios {
        id
        displayName
      }
    }
  }
`;

export const GET_ORDER_TITLE_INFO_AND_TYPE_SETTING = gql`
  query getOrderTitleInfoAndTypeSetting($id: String!) {
    order(id: $id) {
      order {
        titleinfo {
          title
          author
          isbn
          category
        }
        ordernumberdiso
        typesetting {
          provisionofdata
        }
        automaticorder
      }
    }
  }
`;

export const GET_ORDERS_BY_FILE_DELIVERY_DATE = gql`
  query getOrdersByFileDeliveryDateInTimeWindow($timeWindow: TimeWindow!) {
    getOrdersByFileDeliveryDateInTimeWindow(timeWindow: $timeWindow) {
      id
      order {
        idtitle
        titleinfo {
          title
          isbn
          author
          category
        }
        ordernumberdiso
        filedeliverydate
      }
      sender {
        publisher
      }
      status {
        status
      }
      receiver {
        contact
      }
    }
  }
`;

export const GET_ORDERS_WITH_FAILED_PREFLIGHTS_BY_FILE_DELIVERY_DATE = gql`
  query getOrdersWithFailedPreflightsByFileDeliveryDateInTimeWindow(
    $timeWindow: TimeWindow!
  ) {
    getOrdersWithFailedPreflightsByFileDeliveryDateInTimeWindow(
      timeWindow: $timeWindow
    ) {
      id
      order {
        idtitle
        titleinfo {
          title
          isbn
          author
          category
        }
        ordernumberdiso
        filedeliverydate
      }
      sender {
        publisher
      }
      receiver {
        contact
      }
      status {
        status
      }
    }
  }
`;

export const UPDATE_ORDERS_STUDIO_ASSIGNMENTS = gql`
  mutation updateOrderStudiosAssignments(
    $ordersIds: [UUID!]
    $studiosToAddIds: [UUID!]
    $studiosToRemoveIds: [UUID!]
  ) {
    updateOrderStudiosAssignments(
      ordersIds: $ordersIds
      studiosToAddIds: $studiosToAddIds
      studiosToRemoveIds: $studiosToRemoveIds
    ) {
      id
      publisher {
        standardStudios {
          id
          displayName
        }
      }
      studios {
        id
        displayName
      }
    }
  }
`;
