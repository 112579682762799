import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Paperdefinition } from "../../../model/calculation/paperdefinition";
import { Produkt } from "../../../model/calculation/produkt";
import { ProduktFormData } from "../../../model/calculation/produktFormData";
import { PublisherGroup } from "../../../model/organization/organization";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
  selector: "app-papier-data-grid",
  templateUrl: "./papier-data-grid.component.html",
  styleUrls: ["./papier-data-grid.component.scss"],
})
export class PapierDataGridComponent implements OnInit {
  @Input() papers: Paperdefinition[];

  @Input() certficateFilter: any[];

  @Output() onEdit = new EventEmitter<Paperdefinition>();

  @Output() onDelete = new EventEmitter<Paperdefinition>();

  stateStoreDelay: number = 0;
  showConfirmationPopup: boolean = false;
  helpPopupVisible: boolean = false;
  readonly allowedPageSizes = [10, 20, 50];

  formatMessage = formatMessage;

  constructor() {}

  editPaper(paper: Paperdefinition): void {
    this.onEdit.emit(paper);
  }

  deletePaper(paper: Paperdefinition): void {
    this.onDelete.emit(paper);
  }

  exportGrid(e: any): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      worksheet: worksheet,
      component: e.component,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
  }

  resetLocalStorage(): void {
    window.localStorage.removeItem("p3-papers");
    window.location.reload();
  }

  showConfirmDeletePopup(): void {
    this.showConfirmationPopup = true;
  }

  showHelp(): void {
    this.helpPopupVisible = true;
  }
  ngOnInit(): void {}
}
