import { Injectable } from "@angular/core";
import {
  ImportEntry,
  TechnischePreisliste,
  ProduktVariante,
  Produkt,
  PriceScaleEntry,
} from "../model/calculation/produkt";

const PRODUCT_VARIANT_GROUP: ProductVariantGroup = (productVariant) =>
  "" +
  productVariant.pages +
  productVariant.width +
  productVariant.height +
  productVariant.paperId;

const PAGE_GROUP: ProductVariantGroup = (productVariant) =>
  "" + productVariant.pages;

const SIMPLE_TECHPRICELIST_MAPPER: TechPriceListMapper = (productVariant) =>
  <TechnischePreisliste>{ paperId: productVariant.paperId };
const FULL_TECHPRICELIST_MAPPER: TechPriceListMapper = (productVariant) =>
  <TechnischePreisliste>{
    paperId: productVariant.paperId,
    paperContentFix: productVariant.paperContentFix,
    paperContentVar: productVariant.paperContentVar,
    paperCoverFix: productVariant.paperCoverFix,
    paperCoverVar: productVariant.paperCoverVar,
    tkContentFix: productVariant.tkContentFix,
    tkContentVar: productVariant.tkContentVar,
    tkCoverFix: productVariant.tkCoverFix,
    tkCoverVar: productVariant.tkCoverVar,
    pricePerPiece: productVariant.pricePerPiece,
    fdContent: productVariant.fdContent,
    fdCover: productVariant.fdCover,
    fdTk: productVariant.fdTk,
    fdTkCover: productVariant.fdTkCover,
    fdTotal: productVariant.fdTotal,
    standardFormat: productVariant.standardFormat,
    rollenBreite: productVariant.rollenBreite,
    zylinderUmfang: productVariant.zylinderUmfang,
  };

@Injectable({
  providedIn: "root",
})
export class ProductVariantService {
  public getProductVariants(produkt: Produkt): Set<ProduktVariante> {
    return this.doConvert(
      produkt.importEntries,
      produkt.circulationLimit,
      SIMPLE_TECHPRICELIST_MAPPER
    );
  }

  public getProductVariantsFromImportEntries(
    importEntries: ImportEntry[],
    circulationLimit: boolean
  ): Set<ProduktVariante> {
    return this.doConvert(
      importEntries,
      circulationLimit,
      SIMPLE_TECHPRICELIST_MAPPER
    );
  }

  public getProductPages(produkt: Produkt): Set<ProduktVariante> {
    var productVariants: ProductVariant[] = [];
    for (const entry of produkt.importEntries) {
      productVariants.push(<ProductVariant>{
        pages: entry.pages,
        auflage: entry.auflage,
      });
    }
    productVariants = productVariants.sort(
      createComparator(
        (productVariant) => productVariant.pages,
        (productVariant) => productVariant.width,
        (productVariant) => productVariant.height,
        (productVariant) => productVariant.auflage,
        (productVariant) => productVariant.paperId
      )
    );
    var productVariantsMap = this.group(productVariants, PAGE_GROUP);
    return this.transform(
      productVariantsMap,
      produkt.circulationLimit,
      SIMPLE_TECHPRICELIST_MAPPER
    );
  }

  public getProductVariantsWithTechPriceList(
    produkt: Produkt
  ): Set<ProduktVariante> {
    return this.doConvert(
      produkt.importEntries,
      produkt.circulationLimit,
      FULL_TECHPRICELIST_MAPPER
    );
  }

  private doConvert(
    importEntries: ImportEntry[],
    circulationLimit: boolean,
    techPriceListMapper: TechPriceListMapper
  ): Set<ProduktVariante> {
    var productVariants: ProductVariant[] = [];
    for (const importEntry of importEntries) {
      productVariants.push(...this.splitVariantByPaperIds(importEntry));
    }
    productVariants = productVariants.sort(
      createComparator(
        (productVariant) => productVariant.pages,
        (productVariant) => productVariant.width,
        (productVariant) => productVariant.height,
        (productVariant) => productVariant.auflage,
        (productVariant) => productVariant.paperId
      )
    );
    var productVariantsMap = this.group(productVariants, PRODUCT_VARIANT_GROUP);

    return this.transform(
      productVariantsMap,
      circulationLimit,
      techPriceListMapper
    );
  }

  private transform(
    productVariantsMap: Map<string, ProductVariant[]>,
    circulationLimit: boolean,
    techPriceListMapper: TechPriceListMapper
  ): Set<ProduktVariante> {
    var produktVarianten: Set<ProduktVariante> = new Set();
    productVariantsMap.forEach((productVariants, group) => {
      var index = 0;

      for (const productVariant of productVariants) {
        if (0 < productVariant.auflage) {
          produktVarianten.add(<ProduktVariante>{
            id: productVariant.id,
            breite: productVariant.width,
            hoehe: productVariant.height,
            umfang: productVariant.pages,
            minAuflage: productVariant.auflage,
            maxAuflage: this.calculateMaxAuflage(
              index,
              productVariants,
              circulationLimit
            ),
            technischePreisliste: techPriceListMapper(productVariant),
          });
        }

        index++;
      }
    });
    return produktVarianten;
  }

  private calculateMaxAuflage(
    index: number,
    productVariants: ProductVariant[],
    circulationLimit
  ): number {
    if (productVariants.length > index + 1) {
      return productVariants[index + 1].auflage - 1;
    } else {
      if (circulationLimit) {
        return productVariants[index].auflage;
      } else {
        return 999_999_999;
      }
    }
  }

  private splitVariantByPaperIds(importEntry: ImportEntry): any[] {
    var productVariants: ProductVariant[] = [];
    var paperIds = importEntry.paperId.split("-");
    if (paperIds.length > 1) {
      for (let splitPaperId of paperIds) {
        productVariants.push(this.map(importEntry, splitPaperId));
      }
    } else {
      productVariants.push(this.map(importEntry, importEntry.paperId));
    }
    return productVariants;
  }

  private mapUs(entry: PriceScaleEntry, paperId: string): ProductVariantUs {
    var variant = <ProductVariantUs>{
      id:
        entry.pages +
        "-" +
        entry.width +
        "-" +
        entry.height +
        "-" +
        entry.quantity +
        "-" +
        paperId,
      pages: entry.pages,
      width: entry.width,
      height: entry.height,
      quantity: entry.quantity,
      paperId: Number(paperId),
      imposeOutputCtp: entry.imposeOutputCtp,
      proofBook: entry.proofBook,
      plates: entry.plates,
      textMr: entry.textMr,
      textRun: entry.textRun,
      bindingMr: entry.bindingMr,
      bindingRun: entry.bindingRun,
      additionals: entry.additionals,
    };
    return variant;
  }

  private map(entry: ImportEntry, paperId: string): ProductVariant {
    var variant = <ProductVariant>{
      id:
        entry.pages +
        "-" +
        entry.width +
        "-" +
        entry.height +
        "-" +
        entry.auflage +
        "-" +
        paperId,
      pages: entry.pages,
      width: entry.width,
      height: entry.height,
      auflage: entry.auflage,
      paperId: Number(paperId),

      paperContentFix: entry.paperContentFix,
      paperContentVar: entry.paperContentVar,
      paperCoverFix: entry.paperCoverFix,
      paperCoverVar: entry.paperCoverVar,
      tkContentFix: entry.tkContentFix,
      tkContentVar: entry.tkContentVar,
      tkCoverFix: entry.tkCoverFix,
      tkCoverVar: entry.tkCoverVar,
      pricePerPiece: entry.pricePerPiece,
      fdContent: entry.fdContent,
      fdCover: entry.fdCover,
      fdTk: entry.fdTk,
      fdTkCover: entry.fdTkCover,
      fdTotal: entry.fdTotal,
      standardFormat: entry.standardFormat,
      rollenBreite: entry.rollenBreite,
      zylinderUmfang: entry.zylinderUmfang,
    };
    return variant;
  }
  private group(
    productVariants: ProductVariant[],
    group: ProductVariantGroup
  ): Map<string, ProductVariant[]> {
    let groupedMap = new Map();
    for (const productVariant of productVariants) {
      if (!groupedMap.has(group(productVariant))) {
        groupedMap.set(group(productVariant), []);
      }
      groupedMap.get(group(productVariant)).push(productVariant);
    }
    return groupedMap;
  }
}

type ComparatorSelector<T> = (value: T, other: T) => number | string | null;

type ProductVariantGroup = (productVariant: ProductVariant) => string;

type TechPriceListMapper = (
  productVariant: ProductVariant
) => TechnischePreisliste;

function createComparator<T>(...selectors: ComparatorSelector<T>[]) {
  return (a: T, b: T) => {
    for (const selector of selectors) {
      const valA = selector(a, b);
      if (valA === null) continue;
      const valB = selector(b, a);
      if (valB === null || valA == valB) continue;
      if (valA > valB) return 1;
      if (valA < valB) return -1;
    }
    return 0;
  };
}

interface ProductVariant extends Omit<ImportEntry, "paperId"> {
  paperId: number;
}

interface ProductVariantUs extends Omit<PriceScaleEntry, "paperId"> {
  paperId: number;
}
