<div class="d-flex justify-content-center" *ngIf="loading; else showDocuments">
  <div class="details-loading-text">{{ formatMessage("loading") }}</div>
</div>
<ng-template #showDocuments>
  <div class="d-flex documents-outer">
    <div class="d-flex flex-column documents-section width-divider">
      <div class="d-flex align-items-center documents-title">
        <i
          class="material-icons-outlined mdi-check-circle documents-title-icon"
        ></i>
        <span> {{ formatMessage("order.details.confirmation.title") }}</span>
      </div>
      <div class="d-flex flex-shrink">
        <div
          class="documents-container"
          *ngIf="confirmationCount > 0; else emptyText"
        >
          <div
            class="document-button"
            (click)="download('DAB', i.toString())"
            *ngFor="
              let item of [].constructor(confirmationCount);
              let i = index
            "
          >
            <i class="dx-icon-pdffile document-button-icon"></i>
            <div class="document-text">
              {{ formatMessage("order.confirmation") }} {{ i + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column documents-section width-divider">
      <div class="d-flex align-items-center documents-title">
        <i class="material-icons-outlined mdi-truck documents-title-icon"></i>
        <span>{{ formatMessage("order.details.deliveryNote.title") }}</span>
      </div>
      <div class="d-flex">
        <div
          class="documents-container"
          *ngIf="deliveryNoteCount > 0; else emptyText"
        >
          <div
            class="document-button"
            (click)="download('DLIES', i.toString())"
            *ngFor="
              let item of [].constructor(deliveryNoteCount);
              let i = index
            "
          >
            <i class="dx-icon-pdffile document-button-icon"></i>
            <div class="document-text">
              {{ formatMessage("order.deliveryNote") }} {{ i + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column documents-section">
      <div class="d-flex align-items-center documents-title">
        <i class="material-icons-outlined mdi-receipt documents-title-icon"></i>
        <span>{{ formatMessage("order.details.invoice.title") }}</span>
      </div>
      <div class="d-flex">
        <div
          class="documents-container"
          *ngIf="invoiceCount > 0; else emptyText"
        >
          <div
            class="document-button"
            (click)="download('DFAKI', i.toString())"
            *ngFor="let item of [].constructor(invoiceCount); let i = index"
          >
            <i class="dx-icon-pdffile document-button-icon"></i>
            <div class="document-text">
              {{ formatMessage("order.invoice") }} {{ i + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyText>
  <div
    class="
      d-flex
      align-items-center
      justify-content-center
      text-center
      detail-no-data-text
    "
    style="height: 50px"
  >
    {{ formatMessage("order.details.empty") }}
  </div>
</ng-template>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
