<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetPaperCostsForm()"
  [title]="title"
  (onSave)="save()"
>
  <app-papercosts-form [(paperCosts)]="paperCosts"> </app-papercosts-form>
</app-action-popup>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
