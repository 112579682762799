export class NewsElement {
  id: string;
  createdDate: Date;

  filenameContentDE: string;
  contentDE: any;

  filenameContentEN: string;
  contentEN: any;

  filenamePreviewImage: string;
  contentPreviewImage: any;
}

export class NewsInformationRequest {
  titleDE: string;
  titleEN: string;

  descriptionDE: string;
  descriptionEN: string;
}
