import { Component, NgModule, OnInit, ViewChild } from "@angular/core";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLookupModule,
  DxToastModule,
  DxTooltipModule,
} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { Router } from "@angular/router";
import { P3AuthService } from "../../services";
import { CalculationService } from "../../../../../generated/api/services/calculation.service";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { Calculation } from "../../../../../generated/api/models/calculation";
import { custom } from "devextreme/ui/dialog";
import { formatMessage } from "devextreme/localization";
import { ArchivedState } from "../calculations-grid/calculations-grid.component";
import { ConfirmationDialogModule } from "../confirmation-dialog/confirmation-dialog.component";
import { CommonModule } from "@angular/common";
import {
  selectBoxMonthValuesDE,
  selectBoxMonthValuesEN,
} from "./selectBoxValues";

@Component({
  selector: "app-calculations-archived-grid",
  templateUrl: "./calculations-archived-grid.component.html",
  styleUrls: ["./calculations-archived-grid.component.scss"],
})
export class CalculationsArchivedGridComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  calculationGrid: DxDataGridComponent;
  customStore: CustomStore;
  dataSource: DataSource;
  calculations: Calculation[];
  locale: string;
  readonly allowedPageSizes = [10, 20, 50];

  showToast: boolean = false;
  toastMessage: string = "For some reason we need an initial value here...";
  toastType: string = "default";

  formatMessage = formatMessage;

  showConfirmationPopup: boolean = false;
  pagerText: string;
  stateStoreDelay: number = 0;

  lookUpYears: { name: string; value: Date | null }[] = [];
  selectedYear: Date | undefined = undefined;
  lookUpMonths: any;
  selectedMonth: number | undefined;

  constructor(
    private _p3AuthService: P3AuthService,
    private calculationService: CalculationService
  ) {}

  ngOnInit(): void {
    this._p3AuthService.getUser().subscribe((e) => {
      if (e.identity.locale) {
        this.locale = e.identity.locale;
        this.loadLookUpMonths();
      }
    });
    this.loadLookUpYears();
  }

  reArchiveCalculation(calculation: Calculation): void {
    var myDialog = custom({
      title: formatMessage("calculations.rearchive"),
      messageHtml: formatMessage("calculations.sure"),
      buttons: [
        {
          text: formatMessage("calculations.yes"),
          onClick: () => {
            return { sure: true };
          },
        },
        {
          text: formatMessage("calculations.no"),
          onClick: () => {
            return { sure: false };
          },
        },
      ],
    });
    myDialog.show().done((dialogResult) => {
      if (dialogResult.sure) {
        this.calculationService
          .updateCalculationArchivedState({
            id: calculation.id!,
            archivedState: ArchivedState.ACTIVE,
          })
          .toPromise()
          .then(() => {
            //this.customStore.load()
            this.dataSource.reload();

            this.toastMessage = formatMessage(
              "calculations.rearchived.success"
            );
            this.toastType = "success";
          })
          .catch((error) => {
            this.toastMessage = formatMessage("calculations.rearchived.error");
            this.toastType = "error";
          })
          .finally(() => (this.showToast = true));
      }
    });
  }

  deleteCalculation(calculation: Calculation): void {
    var myDialog = custom({
      title: formatMessage("calculations.delete"),
      messageHtml: formatMessage("calculations.sure"),
      buttons: [
        {
          text: formatMessage("calculations.yes"),
          onClick: () => {
            return { sure: true };
          },
        },
        {
          text: formatMessage("calculations.no"),
          onClick: () => {
            return { sure: false };
          },
        },
      ],
    });
    myDialog.show().done((dialogResult) => {
      if (dialogResult.sure) {
        this.calculationService
          .deleteCalculation({ id: calculation.id! })
          .toPromise()
          .then(() => {
            //this.customStore.load()
            this.dataSource.reload();

            this.toastMessage = formatMessage("calculations.deleted.success");
            this.toastType = "success";
          })
          .catch((e) => {
            this.toastMessage = formatMessage("calculations.deleted.error");
            this.toastType = "error";
          })
          .finally(() => (this.showToast = true));
      }
    });
  }

  ngOnDestroy() {}

  resetLocalStorage(): void {
    window.localStorage.removeItem("p3-calculations-archived");
    window.location.reload();
  }

  showConfirmDeletePopup() {
    this.showConfirmationPopup = true;
  }

  loadLookUpYears() {
    const startDate: Date = new Date(2023, 0, 1);
    const currentDate: Date = new Date();
    this.lookUpYears.push({ name: formatMessage("select.all"), value: null });
    for (let i = startDate.getFullYear(); i <= currentDate.getFullYear(); i++) {
      this.lookUpYears.push({ name: i.toString(), value: new Date(i, 0, 1) });
    }
  }

  loadAllArchivedCalculations() {
    this.customStore = new CustomStore({
      key: "id",
      load: () => {
        return this.calculationService
          .listCalculationsByArchivedState({
            archivedState: ArchivedState.ARCHIVED,
          })
          .toPromise()
          .then((result) =>
            result.map((calculation) => ({
              ...calculation,
              bookFormat: `${calculation.width ?? "?"} x ${
                calculation.height ?? "?"
              }`,
            }))
          )
          .catch((e) => {
            throw e;
          });
      },
    });
    this.loadDataSource();
  }

  onYearSelected(e) {
    if (!e.value) {
      this.selectedYear = undefined;
      this.loadAllArchivedCalculations();
    } else {
      this.selectedYear = e.value;
      this.selectedMonth != undefined
        ? this.onMonthSelected({ value: this.selectedMonth })
        : null;
    }
  }

  onMonthSelected(e) {
    this.selectedMonth = e.value;
    if (this.selectedMonth == -1) {
      this.loadAllCalculationsByYear();
    } else {
      this.loadAllCalculationsByYearAndMonth();
    }
  }

  loadAllCalculationsByYear() {
    this.customStore = new CustomStore({
      key: "id",
      load: () => {
        return this.calculationService
          .listArchivedCalculationsByYear$Response({
            year: this.selectedYear!.toISOString(),
          })
          .toPromise()
          .then((result) =>
            result.body.map((calculation) => ({
              ...calculation,
              bookFormat: `${calculation.width ?? "?"} x ${
                calculation.height ?? "?"
              }`,
            }))
          )
          .catch((e) => {
            throw e;
          });
      },
    });
    this.loadDataSource();
  }

  loadAllCalculationsByYearAndMonth() {
    this.customStore = new CustomStore({
      key: "id",
      load: () => {
        return this.calculationService
          .listArchivedCalculationsByYear$Response({
            year: this.selectedYear!.toISOString(),
            month: this.selectedMonth,
          })
          .toPromise()
          .then((result) =>
            result.body.map((calculation) => ({
              ...calculation,
              bookFormat: `${calculation.width ?? "?"} x ${
                calculation.height ?? "?"
              }`,
            }))
          )
          .catch((e) => {
            throw e;
          });
      },
    });
    this.loadDataSource();
  }

  loadDataSource() {
    this.dataSource = new DataSource({
      store: this.customStore,
      pushAggregationTimeout: 0,
    });
  }

  loadLookUpMonths() {
    this.locale == "de-DE"
      ? (this.lookUpMonths = selectBoxMonthValuesDE)
      : (this.lookUpMonths = selectBoxMonthValuesEN);
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    DxButtonModule,
    DxTooltipModule,
    DxToastModule,
    ConfirmationDialogModule,
    CommonModule,
    DxLookupModule,
  ],
  declarations: [CalculationsArchivedGridComponent],
  exports: [CalculationsArchivedGridComponent],
})
export class CalculationsArchivedGridModule {}
