<dx-form
  id="paperCostsForm"
  labelMode="floating"
  [formData]="paperCosts"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newPaperCostsFormValidation"
>

<dxi-item
    dataField="minWeight"
    [label]="{ text: formatMessage('paperCosts.minWeight') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="pattern"
      pattern="^[0-9]{1,9}$"
      [message]="formatMessage('validation.integerOnly')"
    ></dxi-validation-rule>
</dxi-item>

<dxi-item
  dataField="maxWeight"
  [label]="{ text: formatMessage('paperCosts.maxWeight') }"
  [editorOptions]="{ stylingMode: 'underlined' }"
>
  <dxi-validation-rule
    type="pattern"
    pattern="^[0-9]{1,9}$"
    [message]="formatMessage('validation.integerOnly')"
  ></dxi-validation-rule>
</dxi-item>

<dxi-item
  dataField="price"
  [label]="{ text: formatMessage('paperCosts.price') }"
  [editorOptions]="{ stylingMode: 'underlined' }"
>
  <dxi-validation-rule
    type="required"
    [message]="formatMessage('validation.required')"
  ></dxi-validation-rule>

  <dxi-validation-rule
    type="numeric"
    [message]="formatMessage('validation.numericOnly')"
  ></dxi-validation-rule>

</dxi-item>

</dx-form>
