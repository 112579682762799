<dx-data-grid
  id="gridContainer"
  [dataSource]="optionData.optionPriceInformation"
  [showBorders]="true"
  [wordWrapEnabled]="true"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    mode="form"
    [allowUpdating]="true"
    [allowAdding]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>
  <dxi-column
    dataField="von"
    [caption]="setColumnNameOfOptionPriceInformation(optionData.optionType, 0)"
  >
  </dxi-column>
  <dxi-column
    dataField="bis"
    [caption]="setColumnNameOfOptionPriceInformation(optionData.optionType, 1)"
  ></dxi-column>
  <dxi-column
    dataField="fixKosten"
    [caption]="setColumnNameOfOptionPriceInformation(optionData.optionType, 2)"
  ></dxi-column>
  <dxi-column
    dataField="variableKosten"
    [caption]="setColumnNameOfOptionPriceInformation(optionData.optionType, 3)"
  ></dxi-column>
  <dxi-column
    dataField="fortDruckKosten"
    [caption]="setColumnNameOfOptionPriceInformation(optionData.optionType, 4)"
  ></dxi-column>
</dx-data-grid>
