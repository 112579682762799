import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { GENERATE_UPLOAD_LINK } from "../graphql/uploadLinkGql";
import { UploadLink } from "../model/upload/uploadLink";

enum OrderPiece {
  INH = "INH",
  BT = "BT",
  VNS = "VNS",
  BU = "BU",
  BUV = "BUV",
  SU = "SU",
  SUV = "SUV",
  UE = "UE",
  UEV = "UEV",
  PS = "PS",
  ETI = "ETI",
  BEI = "BEI",
  BHE = "BHE",
  PK = "PK",
  BDR = "BDR",
  SO = "SO",
}

@Injectable({
  providedIn: "root",
})
export class UploadLinkService {
  constructor(private apollo: Apollo) {}

  generateUploadLink(
    orderId: String,
    orderPieceString: string
  ): Observable<UploadLink> {
    let orderPiece = orderPieceString as OrderPiece;
    return this.apollo
      .mutate({
        mutation: GENERATE_UPLOAD_LINK,
        variables: {
          orderId,
          orderPiece,
        },
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "generateUploadLink"));
  }
}
