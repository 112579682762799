import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ScalerService {
  constructor(private http: HttpClient) {}

  downloadPrintingTemplatePdf(filename: String, json: String) {
    this.http
      .post(`${environment.scalerBaseUrl}/p3PrintingTemplatePdfService`, json, {
        responseType: "blob",
      })
      .subscribe((response) => {
        const blob = new Blob([response], { type: "application/pdf" });
        saveAs(blob, filename + "-" + this.createTimestamp() + ".pdf");
      });
  }

  downloadCalculatorPdf(filename: String, json: String) {
    this.http
      .post(`${environment.scalerBaseUrl}/p3CalculatorPdfService `, json, {
        responseType: "blob",
      })
      .subscribe((response) => {
        const blob = new Blob([response], { type: "application/pdf" });
        saveAs(blob, filename + "_" + this.createTimestamp() + ".pdf");
      });
  }

  downloadUsCalculatorPdf(filename: String, json: String) {
    this.http
      .post(`${environment.scalerBaseUrl}/p3UsCalculatorPdfService`, json, {
        responseType: "blob",
      })
      .subscribe((response) => {
        const blob = new Blob([response], { type: "application/pdf" });
        saveAs(blob, filename + "_" + this.createTimestamp() + ".pdf");
      });
  }

  createTimestamp(): String {
    let date = new Date();
    let year = date.getFullYear();
    let month = this.padNumber(date.getMonth() + 1);
    let day = this.padNumber(date.getDate());
    let hours = this.padNumber(date.getHours());
    let minutes = this.padNumber(date.getMinutes());
    let seconds = this.padNumber(date.getSeconds());
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  padNumber(number: number): string {
    return number < 10 ? "0" + number : number.toString();
  }
}
