import { StoreReaderConfig } from "@apollo/client/cache/inmemory/readFromStore";
import { Publisher, PublisherGroup, Studio } from "./organization";

export interface OrganizationFormData {
  displayName: string;
}

export class PublisherGroupForm implements OrganizationFormData {
  disoNumber: number | undefined;
  displayName: string;

  constructor(publisherGroup?: PublisherGroup) {
    this.disoNumber = publisherGroup?.disoNumber;
    this.displayName = publisherGroup?.displayName || "";
  }
}

export class PublisherForm implements OrganizationFormData {
  disoNumber: number | undefined;
  name: string;
  displayName: string;
  publisherGroup: string;
  standardStudios: string[];
  preislistenKatalog: string;

  constructor(publisher?: Publisher) {
    this.disoNumber = publisher?.disoNumber;
    this.name = publisher?.name || "";
    this.displayName = publisher?.displayName || "";
    this.publisherGroup = publisher?.publisherGroup?.id || "";
    this.standardStudios = publisher?.standardStudios?.map((it) => it.id) || [];
    this.preislistenKatalog = publisher?.preislistenKatalog?.id || "";
  }
}

export class StudioForm implements OrganizationFormData {
  name: string;
  displayName: string;

  constructor(studio?: Studio) {
    this.displayName = studio?.displayName || "";
  }
}

export enum OrganizationType {
  PUBLISHER_GROUP = "publisherGroup",
  PUBLISHER = "publisher",
  STUDIO = "studio",
}
