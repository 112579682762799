import { Component, Input, OnInit } from "@angular/core";
import { CalculationResult } from "../../../../../../model/calculation/calculationResult";

@Component({
  selector: "app-empty-line",
  templateUrl: "./empty-line.component.html",
  styleUrls: ["./empty-line.component.scss"],
})
export class EmptyLineComponent implements OnInit {
  @Input()
  calculationResponse: CalculationResult[];
  constructor() {}

  ngOnInit(): void {}
}
