<div class="d-flex flex-grow flex-column">
  <span class="studios-title d-flex">{{ title }}</span>
  <ng-container *ngIf="studioEntityList?.length; else noAgencies">
    <div>
      <app-organization-tag
        *ngFor="let studio of studioEntityList"
        [organization]="asStudio(studio)"
      ></app-organization-tag>
    </div>
  </ng-container>
  <ng-template #noAgencies>
    <div
      class="
        d-flex
        flex-grow
        justify-content-center
        align-items-center
        text-center
        detail-no-data-text
      "
    >
      {{ formatMessage("order.details.empty") }}
    </div>
  </ng-template>
</div>
