import { Component, Input, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  asStudio,
  StudioEntity,
} from "../../../model/organization/organization";

@Component({
  selector: "app-order-details-agency",
  templateUrl: "./order-details-agency.component.html",
  styleUrls: ["./order-details-agency.component.scss"],
})
export class OrderDetailsAgencyComponent implements OnInit {
  formatMessage = formatMessage;
  asStudio = asStudio;

  @Input() studioEntityList: StudioEntity[];

  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
