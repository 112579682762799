import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Apollo, QueryRef } from "apollo-angular";
import { GraphQLModule } from "../../../graphql.module";
import { BrowserModule } from "@angular/platform-browser";
import { pluck } from "rxjs/operators";
import { combineLatest, Observable, Subscription } from "rxjs";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFileUploaderModule,
  DxProgressBarModule,
  DxResponsiveBoxModule,
  DxSelectBoxModule,
  DxToastModule,
  DxTooltipModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import {
  getBookGraphicResourceForProductType,
  isSingleUploadOrderPiece,
  productTypeMapping,
} from "../../model/productTypes";
import { P3AuthService, ScreenService } from "../../services";
import { FileUploaderModule } from "./file-uploader/file-uploader.component";
import { AVStatus, Upload } from "../../model/upload/upload";
import {
  isReadyForPrinting,
  isUploadAllowed,
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../model/order/order";
import { OrderService } from "../../services/order.service";
import { LocaleService } from "../../services/locale.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AlertModule } from "../alert/alert.component";
import { FIND_ACTIVE_UPLOAD_LINKS } from "../../graphql/uploadLinkGql";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { GET_UPLOADED_ORDER_PIECES } from "../../graphql/uploadGql";
import { UploadLinkService } from "../../services/upload-link.service";
import { UploadLink } from "../../model/upload/uploadLink";
import { UploadDataGridComponent } from "./upload-data-grid/upload-data-grid.component";
import { UploadLinkDataGridComponent } from "./upload-link-data-grid/upload-link-data-grid.component";
import { UploadService } from "../../services/upload.service";
import { ReturnButtonModule } from "../return-button/return-button.component";

@Component({
  selector: "app-upload-data",
  templateUrl: "./upload-data.component.html",
  styleUrls: ["./upload-data.component.scss"],
})
export class UploadDataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  currentLocale: string | null;

  isAllowedToGenerateUploadLinks: boolean;
  isAllowedToDownloadUploads: boolean;
  isAllowedToReactivateUpload: boolean;

  @Input() orderId: string;
  productTypeMapping = productTypeMapping;
  orderPieces: Array<string>;
  selectedOrderPiece: string;
  uploadedFiles: Array<Upload> = [];
  productType: string;
  bookGraphic: string;

  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;

  formatMessage = formatMessage;
  selectBoxDisabled = false;

  isXSmall: boolean;
  isSmallOrDown: boolean;
  isXLarge: boolean;
  isLargeOrUp: boolean;
  isMobile: boolean;

  order: Order;
  orderReadyForPrinting: boolean;
  statusNotUploadAllowed: boolean;
  order$: Observable<Order>;
  uploads$: Observable<Array<Upload>>;
  loading: boolean = true;

  uploadedOrderQuery: QueryRef<Array<Upload>, any>;
  activeUploadLinksQuery: QueryRef<Array<UploadLink>, any>;
  subscriptions: Array<Subscription> = [];

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  lastUpload: Upload | undefined = undefined;
  activeUploadLinks: UploadLink[] = [];

  orderPieceForUploadLink: string | undefined;

  constructor(
    private apollo: Apollo,
    private screen: ScreenService,
    public orderService: OrderService,
    private localeService: LocaleService,
    private uploadService: UploadService,
    private uploadLinkService: UploadLinkService,
    private http: HttpClient,
    private router: Router,
    private _p3AuthService: P3AuthService
  ) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
    this.isXLarge = this.screen.sizes["screen-x-large"];

    this.isLargeOrUp = this.screen.sizes["screen-large-or-up"];
    this.isSmallOrDown =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    const isAllowedToGenerateUploadLinksSubscription = this._p3AuthService
      .isAllowedToGenerateUploadLinks()
      .subscribe((data) => {
        this.isAllowedToGenerateUploadLinks = data;
      });

    const isAllowedToDownloadUploadsSubscription = this._p3AuthService
      .isAllowedToDownloadUploads()
      .subscribe((data) => {
        this.isAllowedToDownloadUploads = data;
      });

    const isAllowedToReactivateUploadSubscription = this._p3AuthService
      .isAllowedToReactivateUpload()
      .subscribe((data) => {
        this.isAllowedToReactivateUpload = data;
      });

    const currentLocaleSubscription = this.localeService
      .getCurrentLocale()
      .subscribe((locale) => (this.currentLocale = locale));

    this.subscriptions.push(
      isAllowedToGenerateUploadLinksSubscription,
      isAllowedToDownloadUploadsSubscription,
      isAllowedToReactivateUploadSubscription,
      currentLocaleSubscription
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.uploadedOrderQuery = this.apollo.watchQuery({
      query: GET_UPLOADED_ORDER_PIECES,
      variables: { orderId: this.orderId },
      pollInterval: 5000,
    });

    this.activeUploadLinksQuery = this.apollo.watchQuery({
      query: FIND_ACTIVE_UPLOAD_LINKS,
      variables: { orderId: this.orderId },
      pollInterval: 60000,
    });

    if ("orderId" in changes) {
      this.orderId = changes["orderId"].currentValue;
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getUploadedOrderPieces(): Observable<Array<Upload>> {
    return this.uploadedOrderQuery.valueChanges.pipe(
      pluck("data", "findUploadsByOrderId")
    );
  }

  disableSelectBox(e) {
    this.selectBoxDisabled = !!e;
  }

  // Currently never run through
  handleFileAlreadyExists(e) {
    this.toastMessage = formatMessage("upload.fileAlreadyExists", e);
    this.toastType = "error";
    this.showToast = true;
  }

  // Currently never run through
  handleSuccessfulUpload(filename) {
    this.toastMessage = formatMessage("upload.successText", filename);
    this.toastType = "success";
    this.showToast = true;

    let lastUpload = new Upload(
      this.order,
      this.selectedOrderPiece,
      filename,
      new Date(),
      "IN_PROGRESS",
      new AVStatus("IN PROGRESS"),
      undefined,
      true
    );

    this.uploadedFiles = this.uploadedFiles.concat([lastUpload]);

    if (this.orderReadyForPrinting) {
      this.orderPieces = this.orderPieces.filter(
        (it) => it != this.selectedOrderPiece && isSingleUploadOrderPiece(it)
      );
    }

    this.uploadedOrderQuery.refetch({ id: this.orderId });
    this.activeUploadLinksQuery.refetch({ orderId: this.orderId });
  }

  // Currently never run through
  handleUploadError(e) {
    this.toastMessage = formatMessage("upload.errorText", e);
    this.toastType = "error";
    this.showToast = true;
  }

  loadData() {
    this.order$ = this.orderService.getOrder(this.orderId);

    this.uploads$ = this.getUploadedOrderPieces();

    let orderSubscription = combineLatest([
      this.order$,
      this.uploads$,
    ]).subscribe(
      ([order, uploads_]) => {
        this.order = order;

        this.statusNotUploadAllowed = !isUploadAllowed(this.order);
        this.orderReadyForPrinting = isReadyForPrinting(this.order);

        this.uploadedFiles = [...uploads_];
        this.uploadedFiles = this.uploadedFiles.filter(
          (upload) => upload.avCheckStatus.status != "INITIAL"
        );

        this.productType = this.orderService.mapCategoryToProductType(
          order.order.titleinfo.category
        );

        if (this.productType === "Unknown") return;

        this.bookGraphic = getBookGraphicResourceForProductType(
          this.productType
        );

        const amountOfAvailableOrderPiecesBeforeUpload =
          this.orderPieces?.length || 0;

        if (this.orderReadyForPrinting) {
          this.orderPieces = this.productTypeMapping[this.productType]?.filter(
            (orderPiece: string) =>
              !this.uploadedFiles.some(
                (upload: any) =>
                  orderPiece === upload.orderPiece &&
                  isSingleUploadOrderPiece(orderPiece) &&
                  upload.active
              )
          );
        } else {
          this.orderPieces = this.productTypeMapping[this.productType];
        }

        if (
          this.orderPieces?.length !== amountOfAvailableOrderPiecesBeforeUpload
        ) {
          this.activeUploadLinksQuery.refetch({ orderId: this.orderId });
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;

        if (error.message === "NotFoundException") {
          this.toastMessage = formatMessage("upload.loading.orderNotFound");
        } else {
          this.toastMessage = formatMessage("upload.loading.error");
        }

        this.toastType = "error";
        this.showToast = true;
      }
    );

    let activateUploadLinkSubscription =
      this.activeUploadLinksQuery.valueChanges
        .pipe(pluck("data", "findActiveUploadLinks"))
        .subscribe((uploadLinks) => {
          this.activeUploadLinks = [...(uploadLinks as Array<UploadLink>)];
        });

    this.subscriptions.push(orderSubscription, activateUploadLinkSubscription);
  }

  translateOrderPiece(orderPiece: string): string {
    return formatMessage(`upload.orderPiece.${orderPiece}`);
  }

  deactivateUpload(upload: Upload) {
    this.uploadService
      .deactivateUpload(this.orderId, upload)
      .toPromise()
      .then((deactivatedUpload: Upload) => {
        const index = this.uploadedFiles.findIndex(
          (it) =>
            it.orderPiece === deactivatedUpload.orderPiece &&
            it.filename === deactivatedUpload.filename
        );
        this.uploadedFiles.splice(index, 1, deactivatedUpload);

        this.activeUploadLinksQuery.refetch({ orderId: this.orderId });

        this.toastMessage = formatMessage("upload.deactivated.success");
        this.toastType = "success";
        this.showToast = true;
      })
      .catch((_) => {
        this.toastMessage = formatMessage("upload.deactivated.error");
        this.toastType = "error";
        this.showToast = true;
      });
  }

  getGuidelinesLink() {
    if (this.currentLocale === "de-DE") {
      return "https://www.ggp-media.de/service/";
    } else {
      return "https://www.ggp-media.de/en/service/";
    }
  }

  navigateToOrders() {
    this.router.navigate(["/auftraege"]);
  }

  showLinkCopiedToast(copyResult: boolean) {
    if (copyResult) {
      this.toastMessage = formatMessage("upload.link.copied.success");
      this.toastType = "success";
      this.showToast = true;
    } else {
      this.toastMessage = formatMessage("upload.link.copied.error");
      this.toastType = "error";
      this.showToast = true;
    }
  }

  generateUploadLink() {
    if (!!this.orderPieceForUploadLink) {
      let generateUploadLinkSubscription = this.uploadLinkService
        .generateUploadLink(this.orderId, this.orderPieceForUploadLink)
        .subscribe((uploadLink) => {
          this.activeUploadLinks.push(uploadLink);
          this.orderPieceForUploadLink = undefined;
        });
      this.subscriptions.push(generateUploadLinkSubscription);
    }
  }

  navigateToDetailFromUpload(): void {
    this.router.navigate([`/orderdetails/${this.orderId}`]);
  }

  translateStatus(status: string): string {
    return formatMessage(`order.statusValue.${status}`);
  }
}

@NgModule({
  imports: [
    GraphQLModule,
    BrowserModule,
    DxResponsiveBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    DxToastModule,
    AlertModule,
    ClipboardModule,
    FileUploaderModule,
    DxTooltipModule,
    ReturnButtonModule,
  ],
  declarations: [
    UploadDataComponent,
    UploadDataGridComponent,
    UploadLinkDataGridComponent,
  ],
  exports: [UploadDataComponent],
})
export class UploadDataModule {}
