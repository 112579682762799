import { Component, Input, NgModule, OnInit } from "@angular/core";

@Component({
  selector: "app-kachel",
  templateUrl: "./kachel.component.html",
  styleUrls: ["./kachel.component.scss"],
})
export class KachelComponent implements OnInit {
  @Input() title: string;
  @Input() iconClass: string;

  constructor() {}

  ngOnInit(): void {}
}
@NgModule({
  imports: [],
  declarations: [KachelComponent],
  exports: [KachelComponent],
})
export class KachelModule {}
