import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import {
  Organization,
  PublisherGroup,
} from "../../../model/organization/organization";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-publisher-group-card",
  templateUrl: "./publisher-group-card.component.html",
  styleUrls: ["./publisher-group-card.component.scss"],
})
export class PublisherGroupCardComponent implements OnInit {
  formatMessage = formatMessage;
  showOverlay: boolean = false;

  @Input() showIcon: boolean = true;
  @Input() publisherGroup: PublisherGroup;
  @Output() onUpdate = new EventEmitter<PublisherGroup>();
  @Output() onDelete = new EventEmitter<PublisherGroup>();

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [CommonModule, DxButtonModule],
  declarations: [PublisherGroupCardComponent],
  exports: [PublisherGroupCardComponent],
})
export class PublisherGroupCardModule {}
