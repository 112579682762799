<div class="button-center">
  <dx-button
    stylingMode="text"
    [hint]="formatMessage('back')"
    type="normal"
    (onClick)="navigateToPreviousPage()"
    icon="arrowleft"
  >
  </dx-button>
</div>
