import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import {
  blattdickeUmschlag,
  aufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier,
  aufschlagRueckenbreiteByUmfang,
  aufschlagFormatbreiteKlappenbroschur,
  volumenfaktorBildteile,
  produktTypDe,
  produktTypEn,
  aufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke,
  falzbreite,
  abzugDeckelbreite,
  deckelkantenObenUnten,
  breiteHoeheEinschlaege,
  aufschlagSURueckenbreiteByRueckenformAndProduktstaerke,
} from "../model/printing-template/printingTemplatesCalculateValues";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class PrintingTemplateService {
  constructor(private apollo: Apollo) {}

  findBlattdickeUmschlaegeByBuPapierGruppe(buPapierGruppe: string): number {
    var f = blattdickeUmschlag.filter(
      (blattdickeUmschlag) => blattdickeUmschlag.papiergruppe === buPapierGruppe
    );
    return f[0].blattdicke;
  }

  findAufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier(
    blockstaerke: number,
    inhaltPapier: string
  ): number {
    var result = aufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier.filter(
      (a) =>
        blockstaerke >= a.blockStaerkeVon &&
        blockstaerke <= a.blockStaerkeBis &&
        inhaltPapier === a.inhaltPapier
    );
    if (result[0]) {
      return result[0].aufschlag;
    } else {
      return 0;
    }
  }

  findAufschlagRueckenbreiteByUmfang(umfang: number): number {
    var result = aufschlagRueckenbreiteByUmfang.filter(
      (a) => umfang >= a.umfangVon && umfang <= a.umfangBis
    );
    if (result[0]) {
      return result[0].aufschlag;
    } else {
      return 0;
    }
  }

  findAufschlagSURueckenbreiteByRueckenformAndProduktstaerke(
    rueckenform: string,
    produktstaerke: number
  ): number {
    var result = aufschlagSURueckenbreiteByRueckenformAndProduktstaerke.filter(
      (a) =>
        rueckenform === a.rueckenform &&
        produktstaerke >= a.produktstaerkeVon &&
        produktstaerke <= a.produktstaerkeBis
    );

    if (result[0]) {
      return result[0].aufschlag;
    } else {
      return 0;
    }
  }

  findAufschlagFormatbreiteKlappenbroschurByKlappenart(
    klappenart: string
  ): number {
    var result = aufschlagFormatbreiteKlappenbroschur.filter(
      (a) => a.klappenart === klappenart
    );
    if (result[0]) {
      return result[0].aufschlag;
    } else {
      return 0;
    }
  }

  findVolumenfaktorBildteileByBildteilMaterial(
    bildteilMaterial: string
  ): number {
    var result = volumenfaktorBildteile.filter(
      (a) => a.bildteilMaterial === bildteilMaterial
    );
    if (result[0]) {
      return result[0].volumenfaktor;
    } else {
      return 0;
    }
  }

  findGrammaturByBildteileMaterial(bildteilMaterial: string): number {
    var result = volumenfaktorBildteile.filter(
      (a) => a.bildteilMaterial === bildteilMaterial
    );
    if (result[0]) {
      return result[0].grammatur;
    } else {
      return 0;
    }
  }

  findAufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke(
    rueckenform: string,
    bindeart: string,
    produktstaerke: number
  ): number {
    var result =
      aufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke.filter(
        (a) =>
          produktstaerke >= a.produktstaerkeVon &&
          produktstaerke <= a.produktstaerkeBis &&
          rueckenform === a.rueckenform &&
          bindeart === a.bindeart
      );
    if (result[0]) {
      return result[0].aufschlag;
    } else {
      return 0;
    }
  }

  findFalzbreiteByRueckenformAndPappenstaerkeAndRueckenbreite(
    rueckenform: string,
    pappenstaerke: number,
    rueckenbreite: number
  ): number {
    var result = falzbreite.filter(
      (a) =>
        a.rueckenform === rueckenform &&
        pappenstaerke >= a.pappenstaerkeVon &&
        pappenstaerke <= a.pappenstaerkeBis &&
        rueckenbreite >= a.rueckenbreiteVon &&
        rueckenbreite <= a.rueckenbreiteBis
    );
    if (result[0]) {
      return result[0].falzbreite;
    } else {
      return 0;
    }
  }

  findAbzugDeckelbreiteByRueckenformAndBlockstaerkeAndPappenstaerke(
    rueckenform: string,
    blockstaerke: number,
    pappenstaerke: number
  ): number {
    var result = abzugDeckelbreite.filter(
      (a) =>
        rueckenform === a.rueckenform &&
        blockstaerke >= a.blockstaerkeVon &&
        blockstaerke <= a.blockstaerkeBis &&
        pappenstaerke >= a.pappenstaerkeVon &&
        pappenstaerke <= a.pappenstaerkeBis
    );
    if (result[0]) {
      return result[0].abschlag;
    } else {
      return 0;
    }
  }

  findDeckelkantenObenUntenByEndformatHoehe(endformatHoehe: number): number {
    var result = deckelkantenObenUnten.filter(
      (a) => a.endformatHoeheMax >= endformatHoehe
    );
    if (result[0]) {
      return result[0].deckelkantenGesamt;
    } else {
      return 0;
    }
  }

  findBreiteHoeheEinschlaegeByPappenstaerke(pappenstaerke: number): number {
    var result = breiteHoeheEinschlaege.filter(
      (a) =>
        pappenstaerke >= a.pappenstaerkeVon &&
        pappenstaerke <= a.pappenstaerkeBis
    );
    if (result[0]) {
      return result[0].abschlag;
    } else {
      return 0;
    }
  }

  getProdukttypName(produktTypKey: string, locale: string): string {
    let produktTyp;
    if (locale == "de-DE") {
      produktTyp = produktTypDe;
    } else {
      produktTyp = produktTypEn;
    }

    let result = produktTyp.filter((a) => a.key === produktTypKey);
    if (result[0]) {
      return result[0].name;
    } else {
      return "";
    }
  }

  getLanguage(locale: string): string {
    if (locale == "de-DE") {
      return "de";
    } else {
      return "en";
    }
  }

  getSelectBoxNameByValue(key: string, selectBox: any): string {
    let result = selectBox.filter((a) => a.value === key);
    if (result[0]) {
      return result[0].name;
    } else {
      return "";
    }
  }

  getFormattedEndFormat(breite: string, hoehe: string): string {
    return breite + "x" + hoehe + " mm";
  }

  getUmfang(locale: string, umfang: number): string {
    if (locale == "de-DE") {
      return umfang + " Seiten";
    } else {
      return umfang + " pages";
    }
  }

  getFormattedDate(locale: string) {
    var datePipe = new DatePipe("en-US");
    if (locale == "de-DE") {
      return datePipe.transform(new Date(), "dd.MM.yyyy") + "";
    } else {
      return datePipe.transform(new Date(), "dd/MM/yyyy") + "";
    }
  }

  getInhaltsPapier(
    locale: string,
    inhaltsPapier: string,
    grammatur: string,
    volumen: string
  ) {
    if (locale == "de-DE") {
      return grammatur + "g/m² " + volumen + " Vol. (" + inhaltsPapier + ")";
    } else {
      return grammatur + "gsm " + volumen + " Vol. (" + inhaltsPapier + ")";
    }
  }
}
