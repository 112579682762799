import { OptionGroup } from "./optionGroup";
import { OptionPriceInformation } from "./optionPriceInformation";
import { Edge } from "./edge";
import { OptionHeader } from "./optionHeader";
import { OptionUnit } from "./optionUnit";
import { OptionType } from "@angular/cli/models/interface";

export class OwnOption {
  optionName: string;
  optionType: string;
  optionPosition: string;
  sortId: number;
  optionPriceInformation: OptionPriceInformation[];
  optionGroup: OptionGroup;
}
