import { Component, Input, NgModule, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxDataGridModule } from "devextreme-angular";
import {
  DxiColumnModule,
  DxoEditingModule,
  DxoPagingModule,
} from "devextreme-angular/ui/nested";
import { OptionDataGridDeComponent } from "../option-data-grid-de/option-data-grid-de.component";
import { OptionFormData } from "../../../../model/calculation/optionFormData";

@Component({
  selector: "app-option-data-grid-inserts",
  templateUrl: "./option-data-grid-inserts.component.html",
  styleUrls: ["./option-data-grid-inserts.component.scss"],
})
export class OptionDataGridInsertsComponent implements OnInit {
  @Input() optionData: OptionFormData;
  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxiColumnModule,
    DxoEditingModule,
    DxoPagingModule,
  ],
  declarations: [OptionDataGridInsertsComponent],
  exports: [OptionDataGridInsertsComponent],
})
export class OptionDataGridInsertsModule {}
