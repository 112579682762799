import { Component, Input, NgModule, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import {
  DxiColumnModule,
  DxoEditingModule,
  DxoPagingModule,
} from "devextreme-angular/ui/nested";
import { OptionDataGridInsertsComponent } from "../option-data-grid-inserts/option-data-grid-inserts.component";
import { OptionFormData } from "../../../../model/calculation/optionFormData";

@Component({
  selector: "app-option-data-grid-otp",
  templateUrl: "./option-data-grid-otp.component.html",
  styleUrls: ["./option-data-grid-otp.component.scss"],
})
export class OptionDataGridOTPComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @Input() optionData: OptionFormData;

  allowAdding: boolean;
  constructor() {
    this.addOptionPricePossible = this.addOptionPricePossible.bind(this);
  }

  ngOnInit(): void {}
  addOptionPricePossible(e) {
    this.allowAdding = this.dataGrid.instance.totalCount() < 1;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxiColumnModule,
    DxoEditingModule,
    DxoPagingModule,
  ],
  declarations: [OptionDataGridOTPComponent],
  exports: [OptionDataGridOTPComponent],
})
export class OptionDataGridOTPModule {}
