import { Component, OnInit } from "@angular/core";
import { LocaleService } from "src/app/shared/services/locale.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"],
})
export class TermsOfUseComponent implements OnInit {
  locale: Observable<string | null>;
  constructor(private localeService: LocaleService) {}

  ngOnInit() {
    this.locale = this.localeService.getCurrentLocale();
  }
}
