<dx-button
  text="Create Column"
  stylingMode="contained"
  type="default"
  (onClick)="createColumn()"
>
</dx-button>
<dx-data-grid
  id="gridContainer"
  class="carton-grid"
  [dataSource]="optionData.optionPriceInformation"
  [showBorders]="true"
  [wordWrapEnabled]="true"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    mode="form"
    [allowUpdating]="true"
    [allowAdding]="true"
    [allowDeleting]="true"
  >
  </dxo-editing>

  <dxi-column dataField="from"> </dxi-column>
  <dxi-column dataField="to"></dxi-column>

  <dxi-column
    *ngFor="
      let columnName of optionData.optionPriceInformationColumnNames;
      let i = index
    "
    [dataField]="getDatafield(i)"
    [caption]="columnName"
    headerCellTemplate="dynamicColumns"
  ></dxi-column>

  <div
    *dxTemplate="let data of 'dynamicColumns'"
    class="custom-col d-flex align-items-center"
    style="justify-content: start"
  >
    <p style="margin: 0">{{ data.column.caption }}</p>
    <p class="d-flex">
      <dx-button
        stylingMode="contained"
        icon="edit"
        (onClick)="editColumn(data)"
        class="col-btn"
        hint="Rename Column"
      >
      </dx-button>
      <dx-button
        stylingMode="contained"
        icon="trash"
        (onClick)="showColumnConfirmationDialog(data)"
        class="col-btn"
        hint="Delete Column"
      >
      </dx-button>
    </p>
  </div>
</dx-data-grid>

<app-column-form-popup
  [(visible)]="columnFormPopupVisible"
  [columnForm]="columnFormData"
  (onSave)="saveColumnData($event)"
></app-column-form-popup>
<app-confirmation-dialog
  title="Delete Column"
  [message]="columnDialogMessage"
  [visible]="columnDialogVisible"
  [confirmButtonText]="'Delete Column'"
  (onCancel)="onCancelColumnConfirmationDialog()"
  (onConfirm)="deleteColumnData(columnDialogData)"
></app-confirmation-dialog>
