export const environment = {
  maintenanceMode: false,
  production: false,
  baseUrl: "https://backend.dev.p3.bpghub.de",
  cognitoPoolId: "eu-central-1_wSSSpo20C",
  cognitoWebClientId: "7bqbg80mr1nhgvvoa4ime9l8jo",
  cognitoAuthDomain: "auth.dev.p3.bpghub.de",
  listmonkUrl: "https://newsletter.dev.p3.bpghub.de/",
  printformerUrl: "https://pfproxy.dev.p3.bpghub.de",
  scalerBaseUrl: "https://scaler-dev.bpghub.de/rest/api/submit-job",
  scalerLabelUrl:
    "https://dev.p3.bpghub.de/assets/localization_calculator.json",
  awsProjectRegion: "eu-central-1",
  awsCognitoRegion: "eu-central-1",
  isLocalHostOrDev: true,
  isUsa: false,
  //Rum Monitor Settings
  rumSettings: {
    applicationId: "bd4f1cb1-d65b-41c5-b59c-ef83bd0b01b8",
    applicationVersion: "1.0.0",
    applicationRegion: "eu-central-1",
    rumConfig: {
      sessionSampleRate: 1,
      identityPoolId: "eu-central-1:4ed53417-da55-4208-8337-47b08ba4ecee",
      endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
      telemetries: ["http"],
      allowCookies: true,
      enableXRay: false,
    },
  },
};
