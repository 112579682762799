import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-produkt",
  templateUrl: "./produkt.component.html",
  styleUrls: ["./produkt.component.scss"],
})
export class ProduktComponent implements OnInit {
  formatMessage = formatMessage;
  produktId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.produktId = params.get("id") || "";
    });
  }
}
