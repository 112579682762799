import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { UserService } from "../../shared/services/user.service";
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { P3AuthService, ScreenService } from "../../shared/services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { DxDataGridComponent } from "devextreme-angular";
import { IdentityToAuthorize, User } from "../../shared/model/user/user";
import {
  AuthorizeInternalUserRequest,
  UpdateInternalUserRequest,
} from "../../shared/model/user/userRequests";
import { UserFormData } from "../../shared/model/user/userFormData";
import { OrganizationService } from "../../shared/services/organization.service";
import {
  getDisplayNameOfOrganization,
  getExtendedPublisherGroupDisplayName,
  Organization,
  Publisher,
  PublisherGroup,
  Studio,
} from "../../shared/model/organization/organization";
import { Subscription } from "rxjs";
import { getExtendedUserRole } from "../../shared/model/roles";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { environment } from "../../../environments/environment";

const enum OperationTypes {
  DELETE,
  CHANGE_ENABLED_DISABLED_STATE,
}

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  isAllowedToAuthorizeInternalUsers: boolean;

  currentUserSubscription: Subscription;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;
  isMobile: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmOperation: OperationTypes;
  confirmButtonText: string;

  publisherGroups: PublisherGroup[];
  studios: Studio[];
  publisher: Publisher[];

  worksForHeaderFilterDataSource: any[];

  users: User[] = [];
  showNewUserButton = false;
  notAuthorizedInternalUsers: IdentityToAuthorize[] = [];
  userPopupVisible = false;
  authorizeUserPopupVisible = false;
  selectedUserId: string | undefined = undefined;
  selectedUserEnabledState: boolean;
  userFormData: UserFormData = new UserFormData();

  environmentIsUsa = environment.isUsa;

  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    private screen: ScreenService,
    private _p3AuthService: P3AuthService
  ) {
    this.translateLocale = this.translateLocale.bind(this);
    this.translateRole = this.translateRole.bind(this);
  }

  ngOnInit(): void {
    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#user-grid",
          offset: "-30 25",
        },
      },
    });

    this.currentUserSubscription = this._p3AuthService
      .isAllowedToAuthorizeInternalUsers()
      .subscribe((data) => {
        this.isAllowedToAuthorizeInternalUsers = data;

        if (this.isAllowedToAuthorizeInternalUsers) {
          this.loadNotAuthorizedInternalUsers();
        }
      });

    this.loadUsers();

    this.loadOrganizations();
  }

  placeFloatingActionButton(): void {
    // The floating action button has a known bug, that the initial position is wrong on a dx-data-grid. By invoking
    // this method on the "onContentReady" event of the dx-data-grid, the button is on the correct position.
    // https://supportcenter.devexpress.com/ticket/details/t743517/floating-action-button-the-initial-position-is-incorrect-if-the-container-doesn-t-have
    this.showNewUserButton = true;
    repaintFloatingActionButton();
  }

  loadUsers(): void {
    this.userService
      .findAllUsers()
      .toPromise()
      .then((result) => (this.users = [...result]))
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("user.load.error");
        this.showToast = true;
      });
  }

  async loadNotAuthorizedInternalUsers(): Promise<void> {
    try {
      const response = await this.userService
        .findAllNotAuthorizedInternalUsers()
        .toPromise();

      this.notAuthorizedInternalUsers = response.map(
        (it) => new IdentityToAuthorize(it)
      );
    } catch (e) {
      console.log(e);
    }
  }

  async loadOrganizations(): Promise<void> {
    try {
      this.publisherGroups = await this.organizationService
        .findAllPublisherGroups()
        .toPromise();
      this.studios = await this.organizationService
        .findAllStudios()
        .toPromise();
      this.publisher = await this.organizationService
        .findAllPublisher()
        .toPromise();

      this.sortOrganizations();

      this.computeWorksForHeaderFilterDataSource();
    } catch (e) {
      console.log(e);
    }
  }

  sortOrganizations(): void {
    this.publisherGroups.sort((publisherGroup1, publisherGroup2) => {
      const disoNumber1 = publisherGroup1.disoNumber;
      const disoNumber2 = publisherGroup2.disoNumber;

      return disoNumber1 < disoNumber2 ? -1 : disoNumber1 > disoNumber2 ? 1 : 0;
    });

    this.studios.sort((studio1, studio2) =>
      this.compareOrganizationsByDisplayName(studio1, studio2)
    );

    this.publisher.sort((publisher1, publisher2) =>
      this.compareOrganizationsByDisplayName(publisher1, publisher2)
    );
  }

  compareOrganizationsByDisplayName(
    organization1: Organization,
    organization2: Organization
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      organization1.displayName,
      organization2.displayName
    );
  }

  computeWorksForHeaderFilterDataSource() {
    const filteredPublisherGroups = this.publisherGroups
      .map((pg) => getExtendedPublisherGroupDisplayName(pg))
      .filter((pg) =>
        this.users.some((user) =>
          user.worksFor.some((wf) => getDisplayNameOfOrganization(wf) === pg)
        )
      );

    const filteredPublishers = this.publisher
      .map((p) => p.displayName)
      .filter((p) =>
        this.users.some((user) =>
          user.worksFor.some((wf) => getDisplayNameOfOrganization(wf) === p)
        )
      );

    const filteredStudios = this.studios
      .map((s) => s.displayName)
      .filter((s) =>
        this.users.some((user) =>
          user.worksFor.some((wf) => getDisplayNameOfOrganization(wf) === s)
        )
      );

    this.worksForHeaderFilterDataSource = [
      ...filteredPublisherGroups,
      ...filteredPublishers,
      ...filteredStudios,
    ]
      .sort((a, b) => compareStringsAlphabeticallyIgnoreCase(a, b))
      .map((it) => ({
        text: it,
        value: ["worksFor", "contains", it],
      }));
  }

  createUser(): void {
    this.selectedUserId = undefined;
    this.userFormData = new UserFormData();
    this.userPopupVisible = true;
  }

  editUser(user: User): void {
    this.selectedUserId = user.id;
    this.userFormData = new UserFormData(user);
    this.userPopupVisible = true;
  }

  async save(data: UserFormData): Promise<void> {
    if (!this.selectedUserId) {
      await this.saveNewExternalUserAndUpdateView(data);
    } else {
      await this.updateUserAndUpdateView(this.selectedUserId, data);
    }
  }

  async updateUserAndUpdateView(
    userId: string,
    data: UserFormData
  ): Promise<void> {
    this.updateUser(userId, data)
      .then((updatedUser) => {
        this.updateUserInView(updatedUser);

        this.toastMessage = formatMessage("user.update.success");
        this.toastType = "success";
      })
      .catch((e) => {
        if (["EMAIL_TAKEN", "NOT_FOUND"].includes(e.message)) {
          this.toastMessage = formatMessage(`user.error.${e.message}`);
        } else {
          this.toastMessage = formatMessage("user.update.error");
        }

        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  updateUserInView(updatedUser: User) {
    const selectedUserIndex = this.users.findIndex(
      (it) => it.id === this.selectedUserId
    );

    if (selectedUserIndex !== -1) {
      this.users.splice(selectedUserIndex, 1, updatedUser);
    }
  }

  removeUserFromView() {
    const selectedUserIndex = this.users.findIndex(
      (it) => it.id === this.selectedUserId
    );

    if (selectedUserIndex !== -1) {
      this.users.splice(selectedUserIndex, 1);
    }
  }

  async updateUser(userId: string, data: UserFormData): Promise<User> {
    if (this.userFormData.isInternal) {
      return await this.userService
        .updateInternalUser(
          new UpdateInternalUserRequest(userId, data.role, data.locale)
        )
        .toPromise();
    } else {
      return await this.userService
        .updateExternalUser(userId, data)
        .toPromise();
    }
  }

  showConfirmationPopupForDeleteUser(user: User) {
    this.selectedUserId = user.id;

    const key = user.identity.isInternal ? "internal" : "external";

    this.confirmationPopupTitle = formatMessage(
      `user.delete.${key}.confirm.title`
    );
    this.confirmationPopupMessage = formatMessage(
      `user.delete.${key}.confirm.message`,
      user.identity.email
    );
    this.confirmButtonText = formatMessage("delete");

    this.confirmOperation = OperationTypes.DELETE;
    this.showConfirmationPopup = true;
  }

  deleteUserAndUpdateView(): void {
    if (!this.selectedUserId) return;

    this.userService
      .deleteUser(this.selectedUserId)
      .toPromise()
      .then(() => {
        this.removeUserFromView();

        this.toastType = "success";
        this.toastMessage = formatMessage("user.delete.success");
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("user.delete.error");
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  async saveNewExternalUserAndUpdateView(data: UserFormData): Promise<void> {
    await this.userService
      .createExternalUser(data)
      .toPromise()
      .then((createdUser) => {
        this.users.unshift(createdUser);
        this.toastMessage = formatMessage("user.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        if (e.message === "EMAIL_TAKEN") {
          this.toastMessage = formatMessage(`user.error.EMAIL_TAKEN`);
        } else {
          this.toastMessage = formatMessage("user.create.error");
        }
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async authorizeInternalUsers(data: AuthorizeInternalUserRequest[]) {
    const toastKey =
      data.length === 1 ? "user.authorizeOne." : "user.authorizeMultiple.";

    this.userService
      .authorizeInternalUser(data)
      .toPromise()
      .then((activatedUsers) => {
        this.toastType = "success";
        this.toastMessage = formatMessage(toastKey + "success");

        this.users.unshift(...activatedUsers);

        this.loadNotAuthorizedInternalUsers();
      })
      .catch((e) => {
        if (e.message === "ALREADY_AUTHORIZED") {
          this.toastMessage = formatMessage(
            toastKey + "error.ALREADY_AUTHORIZED"
          );
        } else if (e.message === "EMAIL_TAKEN") {
          this.toastMessage = formatMessage(toastKey + "error.EMAIL_TAKEN");
        } else {
          this.toastMessage = formatMessage(toastKey + "error");
        }
        this.toastType = "error";
      })
      .finally(() => {
        this.showToast = true;

        if (this.notAuthorizedInternalUsers.length === 0) {
          setTimeout(() => this.placeFloatingActionButton(), 1);
        }
      });
  }

  calculateWorksForCellValue(rowData) {
    return rowData.worksFor.map((it) => getDisplayNameOfOrganization(it));
  }

  filterWorksFor(filterValue) {
    const column = this as any;

    return [column.calculateCellValue, "contains", filterValue];
  }

  translateLocale(rowData): string {
    return formatMessage(`locale.${rowData.identity.locale}`);
  }

  translateRole(rowData): string {
    const extendedRole = getExtendedUserRole(rowData.role, rowData.worksFor);

    return formatMessage(`user.role.${extendedRole}`);
  }

  getPublisherGroupDisplayName(pg: PublisherGroup): string {
    return pg.disoNumber ? pg.disoNumber + " (" + pg.displayName + ")" : "";
  }

  translateInternalUserAlertMessage(): string {
    if (this.notAuthorizedInternalUsers.length === 1) {
      return formatMessage("user.oneNotAuthorized.alert");
    } else
      return formatMessage(
        "user.multipleNotAuthorized.alert",
        this.notAuthorizedInternalUsers.length.toString()
      );
  }

  showConfirmationPopupForChangeEnabledStatus(user: User) {
    this.selectedUserId = user.id;
    this.selectedUserEnabledState = user.identity.enabled;

    const key = user.identity.enabled ? "disable" : "enable";

    this.confirmationPopupTitle = formatMessage(
      "user." + key + ".confirmation.title"
    );
    this.confirmationPopupMessage = formatMessage(
      "user." + key + ".confirmation.message"
    );
    this.confirmButtonText = formatMessage("user." + key);

    this.confirmOperation = OperationTypes.CHANGE_ENABLED_DISABLED_STATE;
    this.showConfirmationPopup = true;
  }

  executeConfirmOperation(): void {
    if (
      this.confirmOperation === OperationTypes.CHANGE_ENABLED_DISABLED_STATE
    ) {
      this.changeEnabledStatusAndUpdateView();
    } else if (this.confirmOperation === OperationTypes.DELETE) {
      this.deleteUserAndUpdateView();
    }
  }

  changeEnabledStatusAndUpdateView() {
    const toastKey = this.selectedUserEnabledState
      ? "user.disable."
      : "user.enable.";

    if (!this.selectedUserId) return;

    this.userService
      .changeUserEnabledStatus(
        this.selectedUserId,
        this.selectedUserEnabledState
      )
      .toPromise()
      .then((updatedUser) => {
        this.updateUserInView(updatedUser);

        this.toastType = "success";
        this.toastMessage = formatMessage(toastKey + "success");
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage(toastKey + "error");
      })
      .finally(() => (this.showToast = true));
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }
}
