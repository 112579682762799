import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { Option } from "../../../../model/calculation/option";
import { CalculationService } from "../../../../../../../generated/api/services/calculation.service";
import { CalculatorService } from "../../../../services/calculator.service";
import { OptionService } from "../../../../services/option.service";
import { OptionGroup } from "../../../../model/calculation/optionGroup";
import DataSource from "devextreme/data/data_source";
import { Produkt } from "../../../../model/calculation/produkt";
import { OptionPriceInformation } from "../../../../model/calculation/optionPriceInformation";
import { $E } from "@angular/compiler/src/chars";
import { formatMessage } from "devextreme/localization";
import { DxListComponent } from "devextreme-angular";

@Component({
  selector: "app-additional-specification-list",
  templateUrl: "./additional-specification-list.component.html",
  styleUrls: ["./additional-specification-list.component.scss"],
})
export class AdditionalSpecificationListComponent implements OnInit {
  @ViewChild(DxListComponent)
  list: DxListComponent;
  formatMessage = formatMessage;

  @Input()
  optionGroups: OptionGroup[];

  @Input()
  selectedProduct: Produkt;

  showMissingInputs: Boolean = false;
  optionList: Option[];
  listDataSource: any;
  constructor(
    private calculationService: CalculatorService,
    private optionService: OptionService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.optionGroups) {
      this.prepareAdditionalOptions();
    }
  }

  prepareAdditionalOptions() {
    this.optionList = [];
    this.optionGroups.forEach((optionGroupOption) => {
      this.optionService
        .findOptionsByOptionGroup(optionGroupOption)
        .subscribe((data) => {
          this.optionList = [
            ...this.optionList,
            ...data.filter(
              (option) =>
                option.associatedProducts.includes(this.selectedProduct.id) &&
                !option.hideInFrontend
            ),
          ];
          this.prepareDataSource();
        });
    });
  }

  onSelectionChanged($event: any) {
    this.showMissingInputs = this.checkIfInputMissing();
  }

  onInitialized(e) {
    e.component.option("selectionByClick", false);
  }

  prepareDataSource() {
    this.listDataSource = new DataSource({
      store: this.optionList,
      postProcess: (data) => {
        return data.sort((a, b) => {
          return (
            a.items[0]?.optionGroup.sortId - b.items[0]?.optionGroup.sortId
          );
        });
      },
      sort: ["sortId"],
      key: "id",
      group: "optionGroup.groupName",
    });
  }

  onInitializedDropdown(item: Option) {
    item.optionPriceInformation = item.optionPriceInformation.filter(
      (optionPriceInfo, index, self) =>
        index ===
        self.findIndex((t) => t.insertPages === optionPriceInfo.insertPages)
    );
    return item;
  }

  checkIfInputMissing(): boolean {
    let missingInput: boolean = false;
    this.list.selectedItems.forEach((selecItem) => {
      return !!selecItem.items.forEach((option) => {
        if (option.optionType == "INSERTS" && !option.userInput) {
          missingInput = true;
          return true;
        } else if (
          option?.requiredQuantityType == "OPTION_VALUE" &&
          !option.userInput
        ) {
          missingInput = true;
          return true;
        }
        return false;
      });
    });
    return missingInput;
  }

  onDropDownSelectionChanged(item) {
    this.showMissingInputs = this.checkIfInputMissing();
  }
}
