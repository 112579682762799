import { Organization } from "../organization/organization";

export class User {
  id: string;
  identity: Identity;
  newsletterStatus: NewsLetterStatus;
  role: string;
  worksFor: Organization[];
  notification: boolean;
}

export class UserSuggestion {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
}

export class Identity {
  sub: string;
  loginName: string;
  firstName: string;
  lastName: string;
  email: string;
  isInternal: boolean;
  enabled: boolean;
  locale: string;
  userCreateDate: Date;
  userLastModifiedDate: Date;
}

export class NewsLetterStatus {
  subscribed: string;
}

export class IdentityToAuthorize {
  sub: string;
  loginName: string;
  firstName: string;
  lastName: string;
  email: string;
  userCreateDate: Date;
  userLastModifiedDate: Date;
  role: string;

  constructor(identity: Identity) {
    this.sub = identity.sub;
    this.loginName = identity.loginName;
    this.firstName = identity.firstName;
    this.lastName = identity.lastName;
    this.email = identity.email;
    this.userCreateDate = identity.userCreateDate;
    this.userLastModifiedDate = identity.userLastModifiedDate;
    this.role = "";
  }
}
