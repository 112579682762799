import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import {
  DEACTIVATE_UPLOAD,
  GET_ACTIVE_UPLOADS_BY_UPLOAD_DATE_IN_TIME_WINDOW,
} from "../graphql/uploadGql";
import { pluck } from "rxjs/operators";
import { Observable } from "rxjs";
import { Upload } from "../model/upload/upload";
import { TimeWindow } from "../model/timeWindow";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  constructor(private apollo: Apollo) {}

  getActiveUploadsByUploadDateInTimeWindow(
    timeWindow: TimeWindow
  ): Observable<Upload[]> {
    return this.apollo
      .query({
        query: GET_ACTIVE_UPLOADS_BY_UPLOAD_DATE_IN_TIME_WINDOW,
        variables: { timeWindow: timeWindow },
      })
      .pipe(pluck("data", "findActiveUploadsByUploadDateInTimeWindow"));
  }

  deactivateUpload(orderId: string, upload: Upload): Observable<Upload> {
    return this.apollo
      .mutate({
        mutation: DEACTIVATE_UPLOAD,
        variables: {
          orderId: orderId,
          orderPiece: upload.orderPiece,
          filename: upload.filename,
        },
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "deactivateUpload"));
  }
}
