import { environment } from "./environments/environment";

const awsConfig = {
  aws_project_region: environment.awsProjectRegion,
  aws_cognito_region: environment.awsCognitoRegion,
  aws_user_pools_id: environment.cognitoPoolId,
  aws_user_pools_web_client_id: environment.cognitoWebClientId,
  oauth: {
    domain: environment.cognitoAuthDomain,
    scope: ["email", "openid", "profile"],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code", // https://stackoverflow.com/a/63983451/170168
  },
  federationTarget: "COGNITO_USER_POOLS",
};

/* const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
}; */

export default awsConfig;
