import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { CalculationTableConfig } from "../calculator-us-formData";
import { CalculatorService } from "../../../../services/calculator.service";
import { UsCalculatorResult } from "../../../../model/calculation/calculationResult";
import DevExpress from "devextreme";
import data = DevExpress.data;

@Component({
  selector: "app-calculator-us-price-table",
  templateUrl: "./calculator-us-price-table.component.html",
  styleUrls: ["./calculator-us-price-table.component.scss"],
})
export class CalculatorUsPriceTableComponent implements OnInit {
  // Full Response
  @Input()
  calculationResponse: UsCalculatorResult;

  @Input()
  calculationTableConfig: CalculationTableConfig;

  // SubTitles --> Text Print; Text Prep; Bind
  @Input()
  responseAncillaryGroupTypes: any;

  @Input()
  quantities: number[];

  totals: number[] = [];

  json = JSON;

  constructor(public calculatorService: CalculatorService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log("this.responseAncillaryGroupTypes");
    console.log(this.responseAncillaryGroupTypes);
    if (this.calculationResponse) {
      console.log("lengthOfResponse 1-3:");
      console.log(this.calculationResponse.data.ancillaryItemGroupList.length);
      for (
        let i = 0;
        i < this.calculationResponse.data.ancillaryItemGroupList.length;
        i++
      ) {
        const itemList =
          this.calculationResponse?.data?.ancillaryItemGroupList[i]
            ?.ancillaryItemList;

        const total = itemList?.find(
          (item) => item.invoiceItemAncillaryCode == "SUMMARY_TOTAL"
        )?.totalPrice;

        this.totals[i] = total ? total : 0;
      }
    }
  }
}
