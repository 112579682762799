export class OptionUnit {
  id: string;
  unitNameDe: string;
  unitNameEn: string;
}

export class OptionUnitAdmin {
  id: string;
  unitName: string;
}
