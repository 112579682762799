import { Calculation } from "./calculation";

export class CalculationConfig {
  publisherName: string;
  publisherDisoNumber: number;
  apiHash: string;
  publisherHash: string;

  constructor(publisherName: string, publisherDisoNumber: number, apiHash: string, publisherHash: string) {
    this.publisherName = publisherName;
    this.publisherDisoNumber = publisherDisoNumber;
    this.apiHash = apiHash;
    this.publisherHash = publisherHash;
  }

  static fromCalculation(calculation: Calculation): CalculationConfig {
    return new CalculationConfig(
      calculation.publisherName,
      calculation.publisherDisoNumber,
      calculation.apiHash,
      calculation.publisherHash
    );
  }

}
