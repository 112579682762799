import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { P3AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  constructor(
    private http: HttpClient,
    private authService: P3AuthService,
  ) {}

  // suffix can be: contentPreview, contentEN, contentDE
  // use callbacks to easier deal with nested promises
  public loadNewsletterContent(newsId: string, suffix: string, callback: (any) => void): void {
    this.authService.getJwtToken().then((token) => {
      const url = `${environment.baseUrl}/api/news/${newsId}/${suffix}`;
      fetch(url, {
        method: 'GET',
        headers: { Authorization: "Bearer " + token }
      }).then((data) => data.blob().then((res) => callback(res)));
    });
  }

  public loadNewsletterInfo(newsId: string): Promise<any> {
    const url = `${environment.baseUrl}/api/news/${newsId}/newsInformation`;
    return this.http.get(url).toPromise();
  }

  public loadLastNewsletters(): Promise<string[]> {
    return this.http
      .get<any>(environment.baseUrl + "/api/news/last")
      .toPromise();
  }

  public deleteNewsletter(newsId: string): Promise<any> {
    const url = `${environment.baseUrl}/api/news/${newsId}`;
    return this.http
      .delete<any>(url)
      .toPromise();
  }

}
