import { gql } from "apollo-angular";

export const GET_UPLOADED_ORDER_PIECES = gql`
  query findUploadsByOrderId($orderId: String!) {
    findUploadsByOrderId(orderId: $orderId) {
      order {
        id
      }
      orderPiece
      filename
      uploadDate
      preflightStatus
      avCheckStatus {
        status
      }
      downloadUrl
      preflightReportUrl
      active
    }
  }
`;

export const GET_ACTIVE_UPLOADS_BY_UPLOAD_DATE_IN_TIME_WINDOW = gql`
  query findActiveUploadsByUploadDateInTimeWindow($timeWindow: TimeWindow!) {
    findActiveUploadsByUploadDateInTimeWindow(timeWindow: $timeWindow) {
      order {
        id
        order {
          idtitle
          titleinfo {
            title
            isbn
            author
            category
          }
          ordernumberdiso
          filedeliverydate
        }
        sender {
          publisher
        }
        receiver {
          contact
        }
      }
      orderPiece
      filename
      uploadDate
      preflightStatus
    }
  }
`;

export const DEACTIVATE_UPLOAD = gql`
  mutation deactivateUpload(
    $orderId: UUID!
    $orderPiece: OrderPiece!
    $filename: String!
  ) {
    deactivateUpload(
      orderId: $orderId
      orderPiece: $orderPiece
      filename: $filename
    ) {
      order {
        id
      }
      orderPiece
      filename
      uploadDate
      preflightStatus
      avCheckStatus {
        status
      }
      downloadUrl
      active
    }
  }
`;
