import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Order } from "../model/order/order";
import { Apollo } from "apollo-angular";
import { pluck } from "rxjs/operators";
import {
  GET_ORDER_TITLE_INFO_AND_TYPE_SETTING,
  GET_ORDERS,
  GET_ORDER,
  GET_ORDER_EVENTS,
  UPDATE_ORDERS_STUDIO_ASSIGNMENTS,
  GET_ORDERS_BY_FILE_DELIVERY_DATE,
  GET_ORDERS_WITH_FAILED_PREFLIGHTS_BY_FILE_DELIVERY_DATE,
} from "../graphql/orderGql";
import { Orderevent } from "../model/orderevent";
import { TimeWindow } from "../model/timeWindow";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

const CATEGORY_PRODUCT_TYPE_MAPPING = {
  "Broschur  farbig": "Paperback",
  "Druck-Erz. (ohne WV)": "Other",
  "Broschur  schw./weiß": "Paperback",
  "Hardcover farbig": "HardcoverWithWrapper",
  "HC Farbe ohne SU": "HardcoverWithoutWrapper",
  "HC Farbe mit SU": "HardcoverWithWrapper",
  "KlappenBrosch. s/w": "Softcover",
  "KlappenBrosch.farbig": "Softcover",
  "Rücken-Drahtheftung": "Paperback",
  "Hardcover schw./weiß": "HardcoverWithWrapper",
  "HC sw ohne SU": "HardcoverWithoutWrapper",
  "HC sw mit SU": "HardcoverWithWrapper",
  Sonstiges: "Other",
};

@Injectable({
  providedIn: "root",
})
export class OrderService {
  url: string = `${environment.baseUrl}/api/order/native`;

  constructor(private apollo: Apollo, private http: HttpClient) {}

  public getOrdersAsJsonByNativeQuery(): Observable<Order[]> {
    return this.http.get<Order[]>(this.url);
  }

  public getOrders(): Observable<Order[]> {
    return this.apollo
      .query({
        query: GET_ORDERS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "orders"));
  }

  public orderEvents(orderId: string): Observable<Orderevent[]> {
    return this.apollo
      .query({
        query: GET_ORDER_EVENTS,
        variables: { id: orderId },
      })
      .pipe(pluck("data", "orderEvents"));
  }

  public getOrder(orderId: string): Observable<Order> {
    return this.apollo
      .query({
        query: GET_ORDER,
        variables: { id: orderId },
      })
      .pipe(pluck("data", "order"));
  }

  public getOrderTitleInfoAndTypeSetting(orderId: string): Observable<Order> {
    return this.apollo
      .query({
        query: GET_ORDER_TITLE_INFO_AND_TYPE_SETTING,
        variables: { id: orderId },
      })
      .pipe(pluck("data", "order"));
  }

  public getOrdersByFileDeliveryDateInTimeWindow(
    timeWindow: TimeWindow
  ): Observable<Order[]> {
    return this.apollo
      .query({
        query: GET_ORDERS_BY_FILE_DELIVERY_DATE,
        variables: { timeWindow: timeWindow },
      })
      .pipe(pluck("data", "getOrdersByFileDeliveryDateInTimeWindow"));
  }

  public getOrdersWithFailedPreflightsByFileDeliveryDateInTimeWindow(
    timeWindow: TimeWindow
  ): Observable<Order[]> {
    return this.apollo
      .query({
        query: GET_ORDERS_WITH_FAILED_PREFLIGHTS_BY_FILE_DELIVERY_DATE,
        variables: { timeWindow: timeWindow },
      })
      .pipe(
        pluck(
          "data",
          "getOrdersWithFailedPreflightsByFileDeliveryDateInTimeWindow"
        )
      );
  }

  public updateOrderStudiosAssignments(
    ordersIds: string[],
    studiosToAddIds: string[],
    studiosToRemoveIds: string[]
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: UPDATE_ORDERS_STUDIO_ASSIGNMENTS,
        variables: {
          ordersIds: ordersIds,
          studiosToAddIds: studiosToAddIds,
          studiosToRemoveIds: studiosToRemoveIds,
        },
      })
      .pipe(pluck("data", "updateOrderStudiosAssignments"));
  }

  public mapCategoryToProductType(category: string): string {
    return CATEGORY_PRODUCT_TYPE_MAPPING[category] || "Unknown";
  }

  public isValidCategory(category: string): boolean {
    return category in CATEGORY_PRODUCT_TYPE_MAPPING;
  }
}
