import { gql } from "apollo-angular";

export const FIND_ALL_USERS = gql`
  query findAllUsers {
    findAllUsers {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
        ... on Publisher {
          disoNumber
          name
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const FIND_OWN_USER = gql`
  query findOwnUser {
    findOwnUser {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      newsletterStatus {
        subscribed
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
        ... on Publisher {
          disoNumber
          name
        }
      }
    }
  }
`;

export const FIND_ALL_NOT_AUTHORIZED_INTERNAL_USERS = gql`
  query findNotAuthorizedInternalUsers {
    findNotAuthorizedInternalUsers {
      sub
      loginName
      firstName
      lastName
      email
      isInternal
      locale
      enabled
      userCreateDate
      userLastModifiedDate
    }
  }
`;

export const CREATE_MANUFACTURER_FOR_PUBLISHER_GROUP = gql`
  mutation createManufacturerForPublisherGroup(
    $identity: IdentityRequest!
    $publisherGroup: UUID!
  ) {
    createManufacturerForPublisherGroup(
      identity: $identity
      publisherGroup: $publisherGroup
    ) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const CREATE_MANUFACTURER_FOR_PUBLISHER = gql`
  mutation createManufacturerForPublisher(
    $identity: IdentityRequest!
    $publisher: [UUID!]!
  ) {
    createManufacturerForPublisher(identity: $identity, publisher: $publisher) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on Publisher {
          disoNumber
          name
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const CREATE_AGENCY_EMPLOYEE = gql`
  mutation createAgencyEmployee($identity: IdentityRequest!, $studio: UUID!) {
    createAgencyEmployee(identity: $identity, studio: $studio) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const CREATE_ADMIN_US_USER = gql`
  mutation createExternalAdminUsUser($identity: IdentityRequest!) {
    createExternalAdminUsUser(identity: $identity) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_MANUFACTURER_FOR_PUBLISHER_GROUP = gql`
  mutation updateManufacturerForPublisherGroup(
    $id: UUID!
    $identity: IdentityRequest!
    $publisherGroup: UUID!
  ) {
    updateManufacturerForPublisherGroup(
      id: $id
      identity: $identity
      publisherGroup: $publisherGroup
    ) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_MANUFACTURER_FOR_PUBLISHER = gql`
  mutation updateManufacturerForPublisher(
    $id: UUID!
    $identity: IdentityRequest!
    $publisher: [UUID!]!
  ) {
    updateManufacturerForPublisher(
      id: $id
      identity: $identity
      publisher: $publisher
    ) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on Publisher {
          disoNumber
          name
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_AGENCY_EMPLOYEE = gql`
  mutation updateAgencyEmployee(
    $id: UUID!
    $identity: IdentityRequest!
    $studio: UUID!
  ) {
    updateAgencyEmployee(id: $id, identity: $identity, studio: $studio) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_ADMINUS_USER = gql`
  mutation updateAdminUsUser($id: UUID!, $identity: IdentityRequest!) {
    updateAdminUsUser(id: $id, identity: $identity) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_INTERNAL_USER = gql`
  mutation updateInternalUser($updateRequest: UpdateInternalUserRequest!) {
    updateInternalUser(updateRequest: $updateRequest) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const AUTHORIZE_INTERNAL_USERS = gql`
  mutation authorizeInternalUsers(
    $authorizationRequests: [AuthorizeInternalUserRequest]!
  ) {
    authorizeInternalUsers(authorizationRequests: $authorizationRequests) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const UPDATE_OWN_DETAILS = gql`
  mutation updateOwnDetails($identity: IdentityRequest!) {
    updateOwnDetails(identity: $identity) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      notification
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const ENABLE_USER = gql`
  mutation enableUser($id: UUID!) {
    enableUser(id: $id) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
        ... on Publisher {
          disoNumber
          name
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const DISABLE_USER = gql`
  mutation disableUser($id: UUID!) {
    disableUser(id: $id) {
      id
      identity {
        sub
        loginName
        firstName
        lastName
        email
        isInternal
        locale
        enabled
        userCreateDate
        userLastModifiedDate
      }
      role
      notification
      worksFor {
        id
        displayName
        ... on PublisherGroup {
          disoNumber
        }
        ... on Publisher {
          disoNumber
          name
        }
      }
      newsletterStatus {
        subscribed
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(id: $id)
  }
`;
