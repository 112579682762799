import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from "devextreme-angular";
import { ProduktProduktVariantenDatagridComponent } from "../produkt-produkt-varianten-datagrid/produkt-produkt-varianten-datagrid.component";
import { Option } from "../../../model/calculation/option";
import { CommonModule } from "@angular/common";
import { ProduktService } from "../../../services/produkt.service";
import { OptionService } from "../../../services/option.service";
import { defer, from } from "rxjs";
import { concatMap } from "rxjs/operators";
import { Edge } from "../../../model/calculation/edge";
import { formatMessage } from "devextreme/localization";
import { ProduktOptionRuleset } from "../../../model/calculation/produktOptionRuleset";
import DataSource from "devextreme/data/data_source";
import { OptionGroup } from "../../../model/calculation/optionGroup";
import DevExpress from "devextreme";
import data = DevExpress.data;
import DxDataGrid from "devextreme/ui/data_grid";

@Component({
  selector: "app-produkt-spezifikation-option-management",
  templateUrl: "./produkt-option-management.component.html",
  styleUrls: ["./produkt-option-management.component.scss"],
})
export class ProduktOptionManagementComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  formatMessage = formatMessage;
  @Input()
  options: Option[];

  @Input()
  produktId: string;

  dataGridLength = 0;

  optionsDataSource: any;

  updateProhibitOptions: boolean = false;

  rowGroup: string;

  private mappedOptionArray: any;

  constructor(private optionService: OptionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("options" in changes) {
      this.mapOptionArray();
    }
  }

  ngOnInit(): void {}

  selectProhibitedOption(eventName) {
    console.log(eventName);
    const option = eventName.changes[0];
    // Check if prohibit checkbox gets clicked
    if (option.data.prohibitsSomething && !this.updateProhibitOptions) {
      console.log("start select");

      this.updateProhibitOptions = true;

      const clickedOptionId = option.key.id;
      const prohibitedOptionId = option.key.groupId;

      for (let i = 0; i < this.optionsDataSource.length; i++) {
        if (
          this.optionsDataSource[i].id == prohibitedOptionId &&
          this.optionsDataSource[i].groupId == clickedOptionId
        ) {
          this.optionsDataSource[i].prohibitsSomething = true;

          console.log("found");
          console.log(this.optionsDataSource[i]);
          break;
        }
      }
    }
    //else check if prohibited checkbox got unselected
    else if (
      option.data.prohibitsSomething == false &&
      !this.updateProhibitOptions
    ) {
      console.log("start unselect");

      this.updateProhibitOptions = true;

      const clickedOptionId = option.key.id;
      const prohibitedOptionId = option.key.groupId;

      for (let i = 0; i < this.optionsDataSource.length; i++) {
        if (
          this.optionsDataSource[i].id == prohibitedOptionId &&
          this.optionsDataSource[i].groupId == clickedOptionId
        ) {
          this.optionsDataSource[i].prohibitsSomething = false;

          console.log("found");
          console.log(this.optionsDataSource[i]);
          break;
        }
      }
    }
  }

  setUpdateProhibitOptionsToFalse(eventName) {
    const clickProhibit = eventName.changes[0]?.data.prohibitsSomething;
    if (clickProhibit != undefined) {
      this.updateProhibitOptions = false;

      console.log("end update");
    }
  }

  private mapOptionsToDatagridStructure(optionArray) {
    this.mappedOptionArray = optionArray;

    let dataGridStructure: any = [];
    let optionGroupSet: OptionGroup[] = [];

    for (let i = 0; i < optionArray.length; i++) {
      optionGroupSet.push(optionArray[i].optionGroup);
    }

    optionGroupSet = optionGroupSet.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.groupName === value.groupName)
    );

    for (let i = 0; i < optionGroupSet.length; i++) {
      const group = optionGroupSet[i];
      optionArray.forEach((option) => {
        if (option.optionGroup.groupName == group.groupName) {
          const optionName = option.optionName;
          const optionId = option.id;
          let tempArray: Option[] = JSON.parse(JSON.stringify(optionArray));
          tempArray = Object.values(tempArray);

          tempArray = tempArray.filter(
            (item) => item.optionName !== option.optionName
          );
          tempArray.forEach((tOption) => {
            tOption.group = optionName;
            tOption.groupId = optionId;
            // set ids for grouping
            tOption.groupSortId = option.sortId;
            tOption.optionGroupSortId = group.sortId;
            tOption.optionGroupName = group.groupName;
            if (
              tOption.needs &&
              tOption.needs.edgeType == "AND" &&
              tOption.needs.nodeList &&
              tOption.needs.nodeList[0] != null
            ) {
              let found = false;
              tOption.needs.nodeList.forEach((node) => {
                if (node.id == option.id) {
                  found = true;
                  tOption.needsSomething = true;
                }
              });
              if (!found) {
                tOption.needs = <Edge>{};
                tOption.needsSomething = false;
              }
            } else {
              tOption.needsSomething = false;
            }

            if (tOption.prohibit && tOption.prohibit[0] != null) {
              let found = false;
              tOption.prohibit.forEach((node) => {
                if (node.id == option.id) {
                  found = true;
                  tOption.prohibitsSomething = true;
                }
              });
              if (!found) {
                tOption.prohibit = [<Option>{}];
                tOption.prohibitsSomething = false;
              }
            } else {
              tOption.prohibitsSomething = false;
            }
          });
          tempArray.forEach((entry) => {
            dataGridStructure.push(entry);
          });
        }
      });
    }
    let dataGridStructureWithGroup: any = [];

    dataGridStructure.sort((a, b) => a.sortId - b.sortId);

    dataGridStructure = this.sortOptionsByGroupAndSortId(dataGridStructure);

    this.optionsDataSource = dataGridStructure;

    let gridInstance: DxDataGrid = this.dataGrid.instance;

    setTimeout(() => {
      gridInstance.pageSize(dataGridStructure.length);
    }, 1);
  }

  getOptionNames(optionArray: Option[]) {
    let optionArrayCopy: Option[] = [];

    from(optionArray)
      .pipe(
        concatMap((option) =>
          defer(() => {
            return this.optionService.getOption(option);
          })
        )
      )
      .subscribe((option) => {
        if (option.id != undefined) {
          optionArrayCopy.push(option);
          if (optionArray.length == optionArrayCopy.length) {
            this.mapOptionsToDatagridStructure(optionArrayCopy);
          }
        }
      });
  }

  private mapOptionArray() {
    let optionArray: Option[] = [];
    if (this.options) {
      this.options.forEach((node) => {
        if (node.id)
          optionArray.push(<Option>{
            id: node.id,
            needs: node.needs,
            prohibit: node.prohibit,
          });
      });
      this.getOptionNames(optionArray);
    }
  }

  saveOptions() {
    let optionObjects: Option[] = [];

    this.mappedOptionArray.forEach((option) => {
      let optionObj = new Option(
        option.id,
        new Edge("AND", []),
        [],
        option.parent
      );
      optionObjects.push(optionObj);
    });

    // Groups
    optionObjects.forEach((option) => {
      console.log("datasource");
      console.log(this.optionsDataSource);

      // Lines
      this.optionsDataSource.forEach((dataSourceOption) => {
        // Row in group
        if (dataSourceOption.id == option.id) {
          // Checkboxes
          if (dataSourceOption.needsSomething) {
            optionObjects.forEach((groupObj) => {
              if (groupObj.id == dataSourceOption.groupId) {
                option.needs.nodeList.push(groupObj);
              }
            });
          }
          if (dataSourceOption.prohibitsSomething) {
            optionObjects.forEach((groupObj) => {
              if (groupObj.id == dataSourceOption.groupId) {
                option.prohibit.push(groupObj);
              }
            });
          }
        }
      });
    });

    let ruleset = <ProduktOptionRuleset>{
      id: this.produktId,
      produktOptions: optionObjects,
    };

    this.optionService.updateProduktOption(ruleset).subscribe(
      () => {
        console.log("done! 🥳");
      },
      (e) => {
        //TODO
        //Toast: Zuweisung nicht möglich
        console.log(e);
        console.log(ruleset);
      }
    );
  }

  sortByOptionGroup(rowData) {
    return rowData.optionGroup.sortId + ";" + rowData.optionGroup.groupName;
  }

  private sortOptionsByGroupAndSortId(dataGridStructure: any) {
    dataGridStructure.sort(
      (a, b) => a.optionGroup.sortId - b.optionGroup.sortId
    );
    return dataGridStructure;
  }

  getClickedNeededOptionsPerRow(data) {
    let needs: any = [];
    if (data && data.data && data.data.collapsedItems) {
      data.data.collapsedItems.forEach((group) => {
        if (group && group.items) {
          group.items.forEach((option) => {
            if (option) {
              if (option.needsSomething) {
                needs.push(option.optionName);
              }
            }
          });
        }
      });
      return needs;
    }
    return;
  }

  getClickedProhibitOptionsPerRow(data) {
    let prohibit: any = [];
    if (data && data.data && data.data.collapsedItems) {
      data.data.collapsedItems.forEach((group) => {
        if (group && group.items) {
          group.items.forEach((option) => {
            if (option) {
              if (option.prohibitsSomething) {
                prohibit.push(option.optionName);
              }
            }
          });
        }
      });
      return prohibit;
    }
    return;
  }

  getClickedNeededOptionsPerGroup(group) {
    let needs: any = [];
    if (group && group.data && group.data.collapsedItems) {
      group.data.collapsedItems.forEach((option) => {
        if (option) {
          if (option.needsSomething) {
            needs.push(option.optionName);
          }
        }
      });
      return needs;
    }
    return;
  }
  getClickedProhibitOptionsPerGroup(group) {
    let prohibit: any = [];
    if (group && group.data && group.data.collapsedItems) {
      group.data.collapsedItems.forEach((option) => {
        if (option) {
          if (option.prohibitsSomething) {
            prohibit.push(option.optionName);
          }
        }
      });
      return prohibit;
    }
    return;
  }

  setPager(dataGridObject) {
    if (this.dataGridLength == 0)
      this.dataGridLength = dataGridObject.value.length;
    let gridInstance: DxDataGrid = this.dataGrid.instance;

    setTimeout(() => {
      gridInstance.pageSize(dataGridObject.value.length);
    }, 1);
  }

  sortByGroupSortId(rowData) {
    let optionGroupSortId = String(rowData.optionGroupSortId).padStart(8, "0");
    let groupSortId = String(rowData.groupSortId).padStart(8, "0");

    return (
      optionGroupSortId +
      ";" +
      groupSortId +
      ";" +
      rowData.optionGroupName +
      ";" +
      rowData.group
    );
  }

  getFirstRowPerGroup(i) {
    let rowValue = i.value;
    let groupIndex = rowValue.split(";")[0];

    if (!this.rowGroup) {
      this.rowGroup = groupIndex;
      return true;
    } else {
      if (groupIndex != this.rowGroup) {
        this.rowGroup = groupIndex;
        return true;
      } else return false;
    }
  }
}

@NgModule({
  declarations: [ProduktOptionManagementComponent],
  exports: [ProduktOptionManagementComponent, ProduktOptionManagementComponent],
  imports: [CommonModule, DxDataGridModule, DxButtonModule],
})
export class ProduktSpezifikationOptionManagementModule {}
