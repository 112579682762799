import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { formatDate, formatMessage } from "devextreme/localization";
import { UploadLink } from "../../../model/upload/uploadLink";
import { Clipboard } from "@angular/cdk/clipboard";
import { isSingleUploadOrderPiece } from "../../../model/productTypes";

@Component({
  selector: "app-upload-link-data-grid",
  templateUrl: "./upload-link-data-grid.component.html",
  styleUrls: ["./upload-link-data-grid.component.scss"],
})
export class UploadLinkDataGridComponent implements OnInit {
  formatMessage = formatMessage;

  @Input()
  uploadLinks: UploadLink[];

  @Input()
  isOrderReadyForPrinting: boolean;

  @Input()
  orderTitle: string;

  @Input()
  orderIsbn: string;

  @Input()
  height: string;

  @Output()
  onLinkCopied: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {}

  translateOrderPiece(rowData) {
    return formatMessage(`upload.orderPiece.${rowData.orderPiece}`);
  }

  copyUploadLinkToClipboard(rowData) {
    const link = `${window.location.origin}/#/external-file-upload/${rowData.row.data.urlIdentifier}`;

    this.onLinkCopied.emit(this.clipboard.copy(link));
  }

  sendUploadLinkViaMail(rowData) {
    const link = `${window.location.origin}/#/external-file-upload/${rowData.row.data.urlIdentifier}`;

    const isSingleUpload =
      this.isOrderReadyForPrinting &&
      isSingleUploadOrderPiece(rowData.row.data.orderPiece);

    const translatedOrderPiece = formatMessage(
      `upload.orderPiece.${rowData.row.data.orderPiece}`
    );

    const subject = formatMessage(
      "upload.link.mail.subject",
      this.orderTitle,
      this.orderIsbn,
      translatedOrderPiece
    );

    const validUntil =
      formatDate(new Date(rowData.row.data.validUntilDate), "shortDate") +
      ", " +
      formatDate(new Date(rowData.row.data.validUntilDate), "longTime");

    const linkUsagesAddition = formatMessage(
      `upload.link.usages.${isSingleUpload ? "one" : "multiple"}`
    );

    const bodyAddition = isSingleUpload
      ? formatMessage("upload.link.usages.one.bodyAddition")
      : "";

    const body = formatMessage(
      "upload.link.mail.body",
      this.orderTitle,
      this.orderIsbn,
      translatedOrderPiece,
      link,
      validUntil,
      linkUsagesAddition,
      bodyAddition
    );

    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  }

  displayCounter(rowData) {
    let today = new Date().getTime();
    let endDate = new Date(rowData.validUntilDate).getTime();

    let delta = Math.abs(endDate - today) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let daysToShow = hours > 12 ? days + 1 : days;
    let hoursToShow = minutes > 30 ? hours + 1 : hours;

    if (daysToShow > 1) {
      return formatMessage(
        "upload.link.validTimeFormat.days",
        String(daysToShow)
      );
    } else if (daysToShow === 1) {
      return formatMessage("upload.link.validTimeFormat.day");
    } else {
      if (hoursToShow > 1) {
        return formatMessage(
          "upload.link.validTimeFormat.hours",
          String(hoursToShow)
        );
      } else if (hoursToShow === 1) {
        return formatMessage("upload.link.validTimeFormat.hour");
      } else {
        if (minutes > 0) {
          return formatMessage(
            "upload.link.validTimeFormat.minutes",
            String(minutes)
          );
        } else {
          return formatMessage("upload.link.validTimeFormat.expired");
        }
      }
    }
  }
}
