<dx-data-grid
  [dataSource]="orders"
  [columnHidingEnabled]="true"
  [columnAutoWidth]="true"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [visible]="true"
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[10, 25, 50]"
    [showInfo]="true"
    displayMode="compact"
  ></dxo-pager>

  <dxo-export [enabled]="true"></dxo-export>

  <dxi-column
    *ngIf="showOrderNumberDiso"
    dataField="order.ordernumberdiso"
    dataType="string"
    [caption]="formatMessage('order.ordernumberdiso')"
    [calculateCellValue]="orderNumberDisoDisplay"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    dataField="sender.publisher"
    [caption]="formatMessage('sender.publisher')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.titleinfo.author"
    [caption]="formatMessage('order.titleinfo.author')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    dataField="order.titleinfo.title"
    [caption]="formatMessage('order.titleinfo.title')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.titleinfo.isbn"
    [caption]="formatMessage('order.titleinfo.isbn')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="isExternalUser"
    dataField="order.idtitle"
    [caption]="formatMessage('order.idtitle')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    dataField="order.filedeliverydate"
    [caption]="formatMessage('order.filedeliverydate')"
    [allowSorting]="false"
    [format]="formatMessage('dateformat')"
    dataType="date"
  ></dxi-column>

  <dxi-column
    dataField="status.status"
    [caption]="formatMessage('order.status')"
    [allowSorting]="false"
    [calculateDisplayValue]="translateStatus"
    [calculateCellValue]="translateStatus"
  ></dxi-column>

  <dxi-column
    *ngIf="showReceiverContact"
    dataField="receiver.contact"
    [caption]="formatMessage('receiver.contact')"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    *ngIf="showNumberOfPreflights"
    dataType="string"
    [caption]="formatMessage('home.grid.numberFailedPreflights')"
    [calculateCellValue]="calculateNumberOfFailedPreflights"
    [allowSorting]="false"
  ></dxi-column>

  <dxi-column
    fixedPosition="right"
    [caption]="formatMessage('other.actions')"
    type="buttons"
    alignment="center"
    cellTemplate="actionsTemplate"
  >
    <div *dxTemplate="let data of 'actionsTemplate'">
      <dx-button
        class="action-button"
        icon="upload"
        [hint]="formatMessage('upload.title')"
        stylingMode="text"
        type="default"
        (onClick)="navigateToUpload(data.data)"
        [visible]="isValidCategory(data.data)"
      ></dx-button>
    </div>
  </dxi-column>
</dx-data-grid>
