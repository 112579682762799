import { Component, NgModule, OnInit, Pipe, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxFormComponent,
  DxSelectBoxComponent,
  DxButtonModule,
  DxFormModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from "devextreme-angular";
import { HttpClient } from "@angular/common/http";
import { CalculatorService } from "../../services/calculator.service";
import { UserService } from "../../services/user.service";
import {
  produktTypSelectboxEn,
  produktTypSelectboxDe,
  inhaltPapierSelectboxDe,
  inhaltPapierSelectboxEn,
  bindeartSelectboxDe,
  bindeartSelectboxEn,
  broschurumschlagMaterialSelectboxDe,
  broschurumschlagMaterialSelectboxEn,
  klappenSelectboxDe,
  klappenSelectboxEn,
  bildteilUmfangSelectbox,
  bildteilMaterialSelectboxDe,
  bildteilMaterialSelectboxEn,
  rueckenformSelectboxDe,
  rueckenformSelectboxEn,
  farbschnittSelectboxDe,
  farbschnittSelectboxEn,
} from "../../model/printing-template/printingTemplateSelectBoxValues";
import {
  CalculatedBroschurumschlagRecord,
  BroschurumschlagRecords,
  Broschurumschlag,
} from "../../model/printing-template/requests/broschurumschlag";
import {
  CalculatedKlappenRecord,
  KlappenRecords,
  Klappen,
} from "../../model/printing-template/requests/klappen";
import {
  CalculatedFarbschnittRecord,
  FarbschnittRecords,
  Farbschnitt,
} from "../../model/printing-template/requests/farbschnitt";
import notify from "devextreme/ui/notify";
import { User } from "../../model/user/user";
import { P3AuthService } from "../../services";
import { PrintingTemplateService } from "../../services/printing-template.service";
import {
  CalculatedVorUndNachsatzRecord,
  VorUndNachsatz,
  VorUndNachsatzRecords,
} from "../../model/printing-template/requests/vor-und-nachsatz";
import {
  CalculatedSchutzumschlagRecord,
  Schutzumschlag,
  SchutzumschlagRecords,
} from "../../model/printing-template/requests/schutzumschlag";
import {
  Bezug,
  BezugRecords,
  CalculatedBezugRecord,
} from "../../model/printing-template/requests/bezug";
import { CommonModule } from "@angular/common";
import { NumberConverterService } from "../../services/number-converter.service";
import { ScalerService } from "../../services/scaler.service";

export interface FormData {
  titel: string;
  produkttyp: string;
  breite: string;
  hoehe: string;
  umfang: number;
  inhaltPapier: string;
  inhaltPapierGrammatur: string;
  inhaltPapierVolumen: string;
  bindeart: string;
  bildteilUmfang: string;
  bildteilMaterial: string;
  broschurumschlagMaterial: string;
  klappenbreiteBu: number;
  klappen: string;
  rueckenform: string;
  pappenstaerke: string;
  deckelkanteAussen: string;
  deckelkanteObenUnten: string;
  klappenbreiteSu: number;
  farbschnitt: string;
}

@Component({
  selector: "app-printing-template-component",
  templateUrl: "./printing-template.component.html",
  styleUrls: ["./printing-template.component.scss"],
})
export class PrintingTemplateComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  @ViewChild("paperSelectBox") paperSelectBox: DxSelectBoxComponent;

  formatMessage = formatMessage;
  printingTemplateData = <FormData>{};

  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };
  produktTypenDatasource;
  inhaltPapierDatasource;
  bindeartDatasource;
  broschurumschlagMaterialDatasource;
  klappenDatasource;
  bildteilUmfangDatasource;
  bildteilMaterialDatasource;
  farbschnittDatasource;
  rueckenformDatasource;

  tmpLocale: string;

  constructor(
    private http: HttpClient,
    private calculatorService: CalculatorService,
    private userService: UserService,
    private authService: P3AuthService,
    private printingTemplateService: PrintingTemplateService,
    private converter: NumberConverterService,
    private auth: P3AuthService,
    private scalerService: ScalerService
  ) {
    this.isNumberBiggerThanBreite = this.isNumberBiggerThanBreite.bind(this);
  }

  ngOnInit(): void {
    this.loadSelectBoxes();

    this.printingTemplateData.produkttyp = "HARDCOVER_OHNE_SCHUTZUMSCHLAG";
    this.printingTemplateData.inhaltPapier = "WERKDRUCKPAPIER";
    this.printingTemplateData.bindeart = "KLEBEBINDEN";
    this.printingTemplateData.broschurumschlagMaterial = "KARTON_180_220";
    this.printingTemplateData.bildteilUmfang = "0";
    this.printingTemplateData.bildteilMaterial = "BILDERDRUCK_GLAENZEND_115";
    this.printingTemplateData.klappen = "LEICHT_UEBERSTEHEND";
    this.printingTemplateData.rueckenform = "RUND";
    this.printingTemplateData.deckelkanteAussen = "3";
    this.printingTemplateData.deckelkanteObenUnten = "2";
    this.printingTemplateData.farbschnitt = "NEIN";
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  loadSelectBoxes(): void {
    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
        }
        this.tmpLocale = this.getLocale();
        if (this.tmpLocale == "de-DE") {
          this.produktTypenDatasource = produktTypSelectboxDe;
          this.inhaltPapierDatasource = inhaltPapierSelectboxDe;
          this.bindeartDatasource = bindeartSelectboxDe;
          this.broschurumschlagMaterialDatasource =
            broschurumschlagMaterialSelectboxDe;
          this.klappenDatasource = klappenSelectboxDe;
          this.bildteilMaterialDatasource = bildteilMaterialSelectboxDe;
          this.rueckenformDatasource = rueckenformSelectboxDe;
          this.farbschnittDatasource = farbschnittSelectboxDe;
        } else {
          this.produktTypenDatasource = produktTypSelectboxEn;
          this.inhaltPapierDatasource = inhaltPapierSelectboxEn;
          this.bindeartDatasource = bindeartSelectboxEn;
          this.broschurumschlagMaterialDatasource =
            broschurumschlagMaterialSelectboxEn;
          this.klappenDatasource = klappenSelectboxEn;
          this.bildteilMaterialDatasource = bildteilMaterialSelectboxEn;
          this.rueckenformDatasource = rueckenformSelectboxEn;
          this.farbschnittDatasource = farbschnittSelectboxEn;
        }
        this.bildteilUmfangDatasource = bildteilUmfangSelectbox;

        this.converter.setLanguage(this.user?.identity.locale);
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  isBroschurUmschlagMaterialVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp === "SOFTCOVER" ||
      this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN"
    );
  }

  isKlappenbreiteBuVisible(): boolean {
    return this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN";
  }

  isKlappenVisible(): boolean {
    return this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN";
  }

  isRueckenformVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_OHNE_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    );
  }

  isPappenstaerkeVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_OHNE_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    );
  }

  isDeckelkanteAussenVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_OHNE_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    );
  }

  isDeckelkanteObenUntenVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_OHNE_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    );
  }

  isKlappenbreiteSuVisible(): boolean {
    return (
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    );
  }

  isNumberEven(e) {
    return e.value % 2 == 0;
  }

  isNumberBiggerThanBreite(e) {
    if (this.printingTemplateData.breite) {
      return Number(e.value) < Number(this.printingTemplateData.breite);
    }
    return true;
  }

  getColSpanBroschurumschlagMaterial(): number {
    if (this.printingTemplateData?.produkttyp === "SOFTCOVER") {
      return 6;
    }

    if (this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN") {
      return 2;
    }

    return 0;
  }

  getColSpanBindeart(): number {
    if (this.printingTemplateData?.produkttyp === "SOFTCOVER") {
      return 6;
    }

    if (this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN") {
      return 6;
    }

    return 2;
  }

  getColSpanRueckenform(): number {
    if (
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    ) {
      return 2;
    }

    if (
      this.printingTemplateData?.produkttyp === "HARDCOVER_OHNE_SCHUTZUMSCHLAG"
    ) {
      return 4;
    }

    return 0;
  }

  toNumber(numberAsString: string): number {
    return Number(numberAsString.replace(",", "."));
  }

  calculateDefaultValueKlappenbreite(e) {
    this.printingTemplateData.klappenbreiteBu = Math.round(e.value * 0.8);
    this.printingTemplateData.klappenbreiteSu = Math.round((e.value * 1.3) / 2);
  }

  generatePrintingTemplate() {
    if (!this.isValid()) {
      return;
    }

    //Broschurumschlag
    if (this.printingTemplateData?.produkttyp === "SOFTCOVER") {
      var calculatedRecord = this.calculateBroschurumschlag();
      this.generateBroschurumschlagRequest(calculatedRecord);
    }
    //Klappen
    if (this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN") {
      var calculatedKlappenRecord = this.calculateKlappenBroschurumschlag();
      this.generateKlappenBroschurumschlagRequest(calculatedKlappenRecord);
    }
    //Vor- und Nachsatz
    if (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_MIT_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_OHNE_SCHUTZUMSCHLAG"
    ) {
      var calculatedVorUndNachsatzRecord = this.calculateVorUndNachsatz();
      this.generateVorUndNachsatzRequest(calculatedVorUndNachsatzRecord);
    }
    //Schutzumschlag
    if (
      this.printingTemplateData?.produkttyp === "HARDCOVER_MIT_SCHUTZUMSCHLAG"
    ) {
      var calculatedSchutzumschlagRecord = this.calculateSchutzumschlag();
      this.generateSchutzumschlagRequest(calculatedSchutzumschlagRecord);
    }
    //Bezug
    if (
      this.printingTemplateData?.produkttyp ===
        "HARDCOVER_MIT_SCHUTZUMSCHLAG" ||
      this.printingTemplateData?.produkttyp === "HARDCOVER_OHNE_SCHUTZUMSCHLAG"
    ) {
      var calculatedBezugRecord = this.calculateBezug();
      this.generateBezugRequest(calculatedBezugRecord);
    }

    if (
      this.printingTemplateData.farbschnitt === "JA" ||
      this.printingTemplateData.farbschnitt === "YES"
    ) {
      //Farbschnitt HC
      if (
        this.printingTemplateData?.produkttyp ===
          "HARDCOVER_MIT_SCHUTZUMSCHLAG" ||
        this.printingTemplateData?.produkttyp ===
          "HARDCOVER_OHNE_SCHUTZUMSCHLAG"
      ) {
        var calculatedFarbschnittRecord = this.calculateFarbschnittHC();
        this.generateFarbschnittRequest(calculatedFarbschnittRecord);
      }
      //Farbschnitt SC
      if (
        this.printingTemplateData?.produkttyp === "SOFTCOVER_MIT_KLAPPEN" ||
        this.printingTemplateData?.produkttyp === "SOFTCOVER"
      ) {
        var calculatedFarbschnittRecord = this.calculateFarbschnittSC();
        this.generateFarbschnittRequest(calculatedFarbschnittRecord);
      }
    }
  }

  //Broschurumschlag
  calculateBroschurumschlag(): CalculatedBroschurumschlagRecord {
    console.log("calculateBroschurumschlag Start");
    var calculatedRecord = new CalculatedBroschurumschlagRecord();

    //BlattdickeUmschlag
    var blattdickeUmschlag =
      this.printingTemplateService.findBlattdickeUmschlaegeByBuPapierGruppe(
        this.printingTemplateData.broschurumschlagMaterial
      );
    console.log("blattdickeUmschlag: " + blattdickeUmschlag);

    //Dicke Umschlag
    var dickeUmschlag = 2 * blattdickeUmschlag;
    console.log("dickeUmschlag: " + dickeUmschlag);

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil + dickeUmschlag;
    console.log("blockstaerke: " + blockstaerke);

    //Rückenbreite
    calculatedRecord.rueckenBreite = blockstaerke;
    console.log("rueckenBreite (initial): " + calculatedRecord.rueckenBreite);

    if (this.printingTemplateData.bindeart === "FADENHEFTEN") {
      calculatedRecord.rueckenBreite =
        calculatedRecord.rueckenBreite +
        this.printingTemplateService.findAufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier(
          calculatedRecord.rueckenBreite,
          this.printingTemplateData.inhaltPapier
        );
      console.log(
        "rueckenBreite (nach Aufschlag Fadenheften): " +
          calculatedRecord.rueckenBreite
      );
    }

    if (this.printingTemplateData.inhaltPapier === "WERKDRUCKPAPIER") {
      calculatedRecord.rueckenBreite =
        calculatedRecord.rueckenBreite +
        this.printingTemplateService.findAufschlagRueckenbreiteByUmfang(
          this.printingTemplateData.umfang
        );
      console.log(
        "rueckenBreite (nach Aufschlag Werkdruckpapier): " +
          calculatedRecord.rueckenBreite
      );
    }

    //auf 0.5 gerundet
    var inv = 1.0 / 0.5;
    calculatedRecord.rueckenBreite =
      Math.round(calculatedRecord.rueckenBreite * inv) / inv;
    console.log(
      "rueckenBreite (nach runden): " + calculatedRecord.rueckenBreite
    );

    //Formatbreite
    calculatedRecord.formatBreite = this.toNumber(
      this.printingTemplateData.breite
    );
    console.log("formatBreite: " + calculatedRecord.formatBreite);

    //Nutzenbreite
    calculatedRecord.nutzenBreite =
      2 * calculatedRecord.formatBreite + calculatedRecord.rueckenBreite;
    console.log("nutzenBreite: " + calculatedRecord.nutzenBreite);

    //Nutzenhöhe
    calculatedRecord.nutzenHoehe = this.toNumber(
      this.printingTemplateData.hoehe
    );
    console.log("nutzenHoehe: " + calculatedRecord.nutzenHoehe);
    console.log("calculateBroschurumschlag End");

    return calculatedRecord;
  }

  generateBroschurumschlagRequest(
    calculatedRecord: CalculatedBroschurumschlagRecord
  ) {
    var broschurumschlag = new Broschurumschlag();
    broschurumschlag.produktTeil =
      this.printingTemplateService.getProdukttypName(
        "BROSCHURUMSCHLAG",
        this.tmpLocale
      );
    broschurumschlag.language = this.printingTemplateService.getLanguage(
      this.tmpLocale
    );

    var broschurumschlagRecord = new BroschurumschlagRecords();
    broschurumschlagRecord.produktTeilKnz = "BU";
    broschurumschlagRecord.nutzenBreite =
      calculatedRecord.nutzenBreite.toString();
    broschurumschlagRecord.nutzenHoehe =
      calculatedRecord.nutzenHoehe.toString();
    broschurumschlagRecord.formatBreite =
      calculatedRecord.formatBreite.toString();
    broschurumschlagRecord.rueckenBreite =
      calculatedRecord.rueckenBreite.toString();
    broschurumschlagRecord.anschnitt = "4";
    broschurumschlagRecord.ueberklebung2fR = "3";
    broschurumschlagRecord.ueberklebung4fR = "5";
    broschurumschlagRecord.bezeichnung = this.printingTemplateData.titel;
    broschurumschlagRecord.produktTyp =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.produkttyp,
        this.produktTypenDatasource
      );
    broschurumschlagRecord.endFormat =
      this.printingTemplateService.getFormattedEndFormat(
        this.printingTemplateData.breite,
        this.printingTemplateData.hoehe
      );
    broschurumschlagRecord.umfang = this.printingTemplateService.getUmfang(
      this.tmpLocale,
      this.printingTemplateData.umfang
    );

    broschurumschlagRecord.inhaltsPapier =
      this.printingTemplateService.getInhaltsPapier(
        this.tmpLocale,
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.inhaltPapier,
          this.inhaltPapierDatasource
        ),
        this.printingTemplateData.inhaltPapierGrammatur,
        this.printingTemplateData.inhaltPapierVolumen
      );
    broschurumschlagRecord.bindeArt =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.bindeart,
        this.bindeartDatasource
      );
    broschurumschlagRecord.umschlagPapier =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.broschurumschlagMaterial,
        this.broschurumschlagMaterialDatasource
      );
    if (this.printingTemplateData.bildteilUmfang !== "0") {
      broschurumschlagRecord.umfangBildTeil =
        this.printingTemplateService.getUmfang(
          this.tmpLocale,
          this.toNumber(
            this.printingTemplateService.getSelectBoxNameByValue(
              this.printingTemplateData.bildteilUmfang,
              this.bildteilUmfangDatasource
            )
          )
        );
      broschurumschlagRecord.papierBildTeil =
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.bildteilMaterial,
          this.bildteilMaterialDatasource
        );
    }

    broschurumschlagRecord.datum =
      this.printingTemplateService.getFormattedDate(this.tmpLocale);

    let records: BroschurumschlagRecords[];
    records = [broschurumschlagRecord];

    broschurumschlag.records = records;

    let jsonRequestBroschurumschlag = JSON.stringify(broschurumschlag);
    console.log("jsonRequestBroschurumschlag: " + jsonRequestBroschurumschlag);
    let filename = this.generateFilename(
      "BROSCHURUMSCHLAG",
      broschurumschlagRecord.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestBroschurumschlag);
  }

  //Klappenbroschurumschlag
  calculateKlappenBroschurumschlag(): CalculatedKlappenRecord {
    var calculatedRecord = new CalculatedKlappenRecord();
    console.log("calculateKlappenBroschurumschlag Start");

    //BlattdickeUmschlag
    var blattdickeUmschlag =
      this.printingTemplateService.findBlattdickeUmschlaegeByBuPapierGruppe(
        this.printingTemplateData.broschurumschlagMaterial
      );
    console.log("blattdickeUmschlag: " + blattdickeUmschlag);

    //Dicke Umschlag
    var dickeUmschlag = 2 * blattdickeUmschlag;
    console.log("dickeUmschlag: " + dickeUmschlag);

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil + dickeUmschlag;
    console.log("blockstaerke: " + blockstaerke);

    //Rückenbreite
    calculatedRecord.rueckenBreite = blockstaerke;
    console.log("rueckenBreite (initial): " + calculatedRecord.rueckenBreite);

    if (this.printingTemplateData.bindeart === "FADENHEFTEN") {
      calculatedRecord.rueckenBreite =
        calculatedRecord.rueckenBreite +
        this.printingTemplateService.findAufschlagRueckenbreiteByBlockstaerkeAndInhaltPapier(
          calculatedRecord.rueckenBreite,
          this.printingTemplateData.inhaltPapier
        );
      console.log(
        "rueckenBreite (nach Aufschlag Fadenheften): " +
          calculatedRecord.rueckenBreite
      );
    }

    if (this.printingTemplateData.inhaltPapier === "WERKDRUCKPAPIER") {
      calculatedRecord.rueckenBreite =
        calculatedRecord.rueckenBreite +
        this.printingTemplateService.findAufschlagRueckenbreiteByUmfang(
          this.printingTemplateData.umfang
        );
      console.log(
        "rueckenBreite (nach Aufschlag Werkdruckpapier): " +
          calculatedRecord.rueckenBreite
      );
    }

    //auf 0.5 gerundet
    var inv = 1.0 / 0.5;
    calculatedRecord.rueckenBreite =
      Math.round(calculatedRecord.rueckenBreite * inv) / inv;
    console.log(
      "rueckenBreite (nach runden): " + calculatedRecord.rueckenBreite
    );

    //Formatbreite
    calculatedRecord.formatBreite =
      this.toNumber(this.printingTemplateData.breite) +
      this.printingTemplateService.findAufschlagFormatbreiteKlappenbroschurByKlappenart(
        this.printingTemplateData.klappen
      );
    console.log("formatBreite: " + calculatedRecord.formatBreite);

    //Klappenbreite
    calculatedRecord.klappenBreite = this.printingTemplateData.klappenbreiteBu;
    console.log("klappenBreite: " + calculatedRecord.klappenBreite);

    //Nutzenbreite
    calculatedRecord.nutzenBreite =
      2 * calculatedRecord.formatBreite +
      2 * calculatedRecord.klappenBreite +
      calculatedRecord.rueckenBreite;
    console.log("nutzenBreite: " + calculatedRecord.nutzenBreite);

    //Nutzenhöhe
    calculatedRecord.nutzenHoehe = this.toNumber(
      this.printingTemplateData.hoehe
    );
    console.log("nutzenHoehe: " + calculatedRecord.nutzenHoehe);
    console.log("calculateKlappenBroschurumschlag End");

    return calculatedRecord;
  }

  generateKlappenBroschurumschlagRequest(
    calculatedRecord: CalculatedKlappenRecord
  ) {
    var klappen = new Klappen();
    klappen.produktTeil = this.printingTemplateService.getProdukttypName(
      "KLAPPENBROSCHUR",
      this.tmpLocale
    );
    klappen.language = this.printingTemplateService.getLanguage(this.tmpLocale);

    var kappenRecord = new KlappenRecords();
    kappenRecord.produktTeilKnz = "KBU";
    kappenRecord.nutzenBreite = calculatedRecord.nutzenBreite.toString();
    kappenRecord.nutzenHoehe = calculatedRecord.nutzenHoehe.toString();
    kappenRecord.formatBreite = calculatedRecord.formatBreite.toString();
    kappenRecord.rueckenBreite = calculatedRecord.rueckenBreite.toString();
    kappenRecord.anschnitt = "4";
    kappenRecord.ueberklebung2fR = "3";
    kappenRecord.ueberklebung4fR = "5";
    kappenRecord.klappenBreite = calculatedRecord.klappenBreite.toString();
    kappenRecord.fondEinschlag = "5";
    kappenRecord.textAbstand = "5";
    kappenRecord.bezeichnung = this.printingTemplateData.titel;
    kappenRecord.produktTyp =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.produkttyp,
        this.produktTypenDatasource
      );
    kappenRecord.klappen = this.printingTemplateService.getSelectBoxNameByValue(
      this.printingTemplateData.klappen,
      this.klappenDatasource
    );
    kappenRecord.endFormat = this.printingTemplateService.getFormattedEndFormat(
      this.printingTemplateData.breite,
      this.printingTemplateData.hoehe
    );
    kappenRecord.umfang = this.printingTemplateService.getUmfang(
      this.tmpLocale,
      this.printingTemplateData.umfang
    );
    kappenRecord.inhaltsPapier = this.printingTemplateService.getInhaltsPapier(
      this.tmpLocale,
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.inhaltPapier,
        this.inhaltPapierDatasource
      ),
      this.printingTemplateData.inhaltPapierGrammatur,
      this.printingTemplateData.inhaltPapierVolumen
    );
    kappenRecord.bindeArt =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.bindeart,
        this.bindeartDatasource
      );
    kappenRecord.umschlagPapier =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.broschurumschlagMaterial,
        this.broschurumschlagMaterialDatasource
      );

    if (this.printingTemplateData.bildteilUmfang !== "0") {
      kappenRecord.umfangBildTeil = this.printingTemplateService.getUmfang(
        this.tmpLocale,
        this.toNumber(
          this.printingTemplateService.getSelectBoxNameByValue(
            this.printingTemplateData.bildteilUmfang,
            this.bildteilUmfangDatasource
          )
        )
      );
      kappenRecord.papierBildTeil =
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.bildteilMaterial,
          this.bildteilMaterialDatasource
        );
    }

    kappenRecord.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    let records: KlappenRecords[];
    records = [kappenRecord];

    klappen.records = records;

    let jsonRequestKlappenbroschur = JSON.stringify(klappen);
    console.log("jsonRequestKlappenbroschur: " + jsonRequestKlappenbroschur);
    let filename = this.generateFilename(
      "BROSCHURUMSCHLAG",
      kappenRecord.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestKlappenbroschur);
  }

  //Vor- und Nachsatz
  calculateVorUndNachsatz(): CalculatedVorUndNachsatzRecord {
    var calculatedRecord = new CalculatedVorUndNachsatzRecord();
    console.log("calculateVorUndNachsatz Start");

    //Formatbreite
    calculatedRecord.formatBreite =
      this.toNumber(this.printingTemplateData.breite) - 2;
    console.log("formatBreite: " + calculatedRecord.formatBreite);

    //Nutzenbreite
    calculatedRecord.nutzenBreite = 2 * calculatedRecord.formatBreite;
    console.log("nutzenBreite: " + calculatedRecord.nutzenBreite);

    //Nutzenhöhe
    calculatedRecord.nutzenHoehe = this.toNumber(
      this.printingTemplateData.hoehe
    );
    console.log("nutzenHoehe: " + calculatedRecord.nutzenHoehe);
    console.log("calculateVorUndNachsatz End");

    return calculatedRecord;
  }

  generateVorUndNachsatzRequest(
    calculatedRecord: CalculatedVorUndNachsatzRecord
  ) {
    var vorUndNachsatz = new VorUndNachsatz();
    vorUndNachsatz.produktTeil = this.printingTemplateService.getProdukttypName(
      "HARDCOVER_MIT_SCHUTZUMSCHLAG",
      this.tmpLocale
    );
    vorUndNachsatz.language = this.printingTemplateService.getLanguage(
      this.tmpLocale
    );

    var vorUndNachsatzRecords = new VorUndNachsatzRecords();
    vorUndNachsatzRecords.produktTeilKnz = "VNS";
    vorUndNachsatzRecords.nutzenBreite =
      calculatedRecord.nutzenBreite.toString();
    vorUndNachsatzRecords.nutzenHoehe = calculatedRecord.nutzenHoehe.toString();
    vorUndNachsatzRecords.formatBreite =
      calculatedRecord.formatBreite.toString();
    vorUndNachsatzRecords.anschnitt = "4";
    vorUndNachsatzRecords.kritRandRL = "5";
    vorUndNachsatzRecords.kritRandOU = "3";
    vorUndNachsatzRecords.bezeichnung = this.printingTemplateData.titel;
    vorUndNachsatzRecords.produktTyp =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.produkttyp,
        this.produktTypenDatasource
      );
    vorUndNachsatzRecords.endFormat =
      this.printingTemplateService.getFormattedEndFormat(
        this.printingTemplateData.breite,
        this.printingTemplateData.hoehe
      );
    vorUndNachsatzRecords.umfang = this.printingTemplateService.getUmfang(
      this.tmpLocale,
      this.printingTemplateData.umfang
    );
    vorUndNachsatzRecords.inhaltsPapier =
      this.printingTemplateService.getInhaltsPapier(
        this.tmpLocale,
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.inhaltPapier,
          this.inhaltPapierDatasource
        ),
        this.printingTemplateData.inhaltPapierGrammatur,
        this.printingTemplateData.inhaltPapierVolumen
      );
    vorUndNachsatzRecords.bindeArt =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.bindeart,
        this.bindeartDatasource
      );
    vorUndNachsatzRecords.rueckenForm =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.rueckenform,
        this.rueckenformDatasource
      );
    vorUndNachsatzRecords.pappenDicke = this.printingTemplateData.pappenstaerke;

    if (this.printingTemplateData.bildteilUmfang !== "0") {
      vorUndNachsatzRecords.umfangBildTeil =
        this.printingTemplateService.getUmfang(
          this.tmpLocale,
          this.toNumber(
            this.printingTemplateService.getSelectBoxNameByValue(
              this.printingTemplateData.bildteilUmfang,
              this.bildteilUmfangDatasource
            )
          )
        );
      vorUndNachsatzRecords.papierBildTeil =
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.bildteilMaterial,
          this.bildteilMaterialDatasource
        );
    }

    vorUndNachsatzRecords.deckelKanteA =
      this.printingTemplateData.deckelkanteAussen;
    vorUndNachsatzRecords.deckelKanteOU =
      this.printingTemplateData.deckelkanteObenUnten;
    vorUndNachsatzRecords.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    let records: VorUndNachsatzRecords[];
    records = [vorUndNachsatzRecords];

    vorUndNachsatz.records = records;

    let jsonRequestVorUndNachsatz = JSON.stringify(vorUndNachsatz);
    console.log("jsonRequestVorUndNachsatz: " + jsonRequestVorUndNachsatz);
    let filename = this.generateFilename(
      "VOR_UND_NACHSATZ",
      vorUndNachsatzRecords.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestVorUndNachsatz);
  }

  //Schutzumschlag
  calculateSchutzumschlag(): CalculatedSchutzumschlagRecord {
    var calculatedRecord = new CalculatedSchutzumschlagRecord();
    console.log("calculateSchutzumschlag Start");

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil + 0.6;
    console.log("blockstaerke: " + blockstaerke);

    //Produktstaerke
    var produktstaerke =
      blockstaerke +
      2 * this.toNumber(this.printingTemplateData.pappenstaerke) +
      0.5;
    console.log("produktstaerke: " + produktstaerke);

    //Rückenbreite
    var rueckenbreiteBezug = Math.round(
      produktstaerke +
        this.printingTemplateService.findAufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke(
          this.printingTemplateData.rueckenform,
          this.printingTemplateData.bindeart,
          produktstaerke
        )
    );

    calculatedRecord.rueckenBreite = Math.round(
      rueckenbreiteBezug +
        this.printingTemplateService.findAufschlagSURueckenbreiteByRueckenformAndProduktstaerke(
          this.printingTemplateData.rueckenform,
          produktstaerke
        )
    );
    console.log("rueckenBreite: " + calculatedRecord.rueckenBreite);

    //Formatbreite
    calculatedRecord.formatBreite =
      this.toNumber(this.printingTemplateData.breite) + 3;
    console.log("formatBreite: " + calculatedRecord.formatBreite);

    //Klappenbreite
    calculatedRecord.klappenBreite = this.printingTemplateData.klappenbreiteSu;
    console.log("klappenBreite: " + calculatedRecord.klappenBreite);

    //Nutzenbreite
    calculatedRecord.nutzenBreite =
      2 * calculatedRecord.formatBreite +
      2 * calculatedRecord.klappenBreite +
      calculatedRecord.rueckenBreite;
    console.log("nutzenBreite: " + calculatedRecord.nutzenBreite);

    //Nutzenhöhe
    //Deckelhoehe
    var deckelHoehe =
      this.toNumber(this.printingTemplateData.hoehe) +
      this.toNumber(this.printingTemplateData.deckelkanteObenUnten) * 2;
    console.log("deckelHoehe: " + deckelHoehe);

    calculatedRecord.nutzenHoehe = deckelHoehe;
    console.log("nutzenHoehe: " + calculatedRecord.nutzenHoehe);
    console.log("calculateSchutzumschlag End");

    return calculatedRecord;
  }

  generateSchutzumschlagRequest(
    calculatedRecord: CalculatedSchutzumschlagRecord
  ) {
    var schutzumschlag = new Schutzumschlag();
    schutzumschlag.produktTeil = this.printingTemplateService.getProdukttypName(
      "SCHUTZUMSCHLAG",
      this.tmpLocale
    );
    schutzumschlag.language = this.printingTemplateService.getLanguage(
      this.tmpLocale
    );

    var schutzumschlagRecord = new SchutzumschlagRecords();
    schutzumschlagRecord.produktTeilKnz = "SU";
    schutzumschlagRecord.nutzenBreite =
      calculatedRecord.nutzenBreite.toString();
    schutzumschlagRecord.nutzenHoehe = calculatedRecord.nutzenHoehe.toString();
    schutzumschlagRecord.formatBreite =
      calculatedRecord.formatBreite.toString();
    schutzumschlagRecord.rueckenBreite =
      calculatedRecord.rueckenBreite.toString();
    schutzumschlagRecord.anschnitt = "4";
    schutzumschlagRecord.klappenBreite =
      calculatedRecord.klappenBreite.toString();
    schutzumschlagRecord.fondEinschlag = "7";
    schutzumschlagRecord.textAbstand = "5";
    schutzumschlagRecord.bezeichnung = this.printingTemplateData.titel;
    schutzumschlagRecord.produktTyp =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.produkttyp,
        this.produktTypenDatasource
      );
    schutzumschlagRecord.endFormat =
      this.printingTemplateService.getFormattedEndFormat(
        this.printingTemplateData.breite,
        this.printingTemplateData.hoehe
      );
    schutzumschlagRecord.umfang = this.printingTemplateService.getUmfang(
      this.tmpLocale,
      this.printingTemplateData.umfang
    );
    schutzumschlagRecord.inhaltsPapier =
      this.printingTemplateService.getInhaltsPapier(
        this.tmpLocale,
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.inhaltPapier,
          this.inhaltPapierDatasource
        ),
        this.printingTemplateData.inhaltPapierGrammatur,
        this.printingTemplateData.inhaltPapierVolumen
      );
    schutzumschlagRecord.bindeArt =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.bindeart,
        this.bindeartDatasource
      );
    schutzumschlagRecord.rueckenForm =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.rueckenform,
        this.rueckenformDatasource
      );
    schutzumschlagRecord.pappenDicke = this.printingTemplateData.pappenstaerke;

    if (this.printingTemplateData.bildteilUmfang !== "0") {
      schutzumschlagRecord.umfangBildTeil =
        this.printingTemplateService.getUmfang(
          this.tmpLocale,
          this.toNumber(
            this.printingTemplateService.getSelectBoxNameByValue(
              this.printingTemplateData.bildteilUmfang,
              this.bildteilUmfangDatasource
            )
          )
        );
      schutzumschlagRecord.papierBildTeil =
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.bildteilMaterial,
          this.bildteilMaterialDatasource
        );
    }

    schutzumschlagRecord.deckelKanteA =
      this.printingTemplateData.deckelkanteAussen;
    schutzumschlagRecord.deckelKanteOU =
      this.printingTemplateData.deckelkanteObenUnten;
    schutzumschlagRecord.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    let records: SchutzumschlagRecords[];
    records = [schutzumschlagRecord];

    schutzumschlag.records = records;

    let jsonRequestSchutzumschlag = JSON.stringify(schutzumschlag);
    console.log("jsonRequestSchutzumschlag: " + jsonRequestSchutzumschlag);
    let filename = this.generateFilename(
      "SCHUTZUMSCHLAG",
      schutzumschlagRecord.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestSchutzumschlag);
  }

  //Bezug
  calculateBezug(): CalculatedBezugRecord {
    var calculatedRecord = new CalculatedBezugRecord();
    console.log("CalculatedBezugRecord Start");

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil + 0.6;
    console.log("blockstaerke: " + blockstaerke);

    //Produktstaerke
    var produktstaerke =
      blockstaerke +
      2 * this.toNumber(this.printingTemplateData.pappenstaerke) +
      0.5;
    console.log("produktstaerke: " + produktstaerke);

    //Rückenbreite
    calculatedRecord.rueckenBreite = Math.round(
      produktstaerke +
        this.printingTemplateService.findAufschlagRueckenbreiteByRueckenformAndBindeartAndProduktstaerke(
          this.printingTemplateData.rueckenform,
          this.printingTemplateData.bindeart,
          produktstaerke
        )
    );
    console.log("rueckenBreite: " + calculatedRecord.rueckenBreite);

    //Deckelkante oben/unten
    calculatedRecord.deckelKanteOU = this.toNumber(
      this.printingTemplateData.deckelkanteObenUnten
    );
    console.log("deckelKanteOU: " + calculatedRecord.deckelKanteOU);

    //Deckelkante außen
    calculatedRecord.deckelKanteA = this.toNumber(
      this.printingTemplateData.deckelkanteAussen
    );
    console.log("deckelKanteA: " + calculatedRecord.deckelKanteA);

    //Falzbreite
    var falzBreiteZusatz = 0;
    if (calculatedRecord.deckelKanteA % 1 > 0) {
      falzBreiteZusatz = 0.5;
    }
    calculatedRecord.falzBreite =
      this.printingTemplateService.findFalzbreiteByRueckenformAndPappenstaerkeAndRueckenbreite(
        this.printingTemplateData.rueckenform,
        this.toNumber(this.printingTemplateData.pappenstaerke),
        calculatedRecord.rueckenBreite
      ) + falzBreiteZusatz;
    console.log("falzBreite: " + calculatedRecord.falzBreite);

    //Deckelbreite
    calculatedRecord.deckelBreite = Math.round(
      this.toNumber(this.printingTemplateData.breite) +
        this.printingTemplateService.findAbzugDeckelbreiteByRueckenformAndBlockstaerkeAndPappenstaerke(
          this.printingTemplateData.rueckenform,
          blockstaerke,
          this.toNumber(this.printingTemplateData.pappenstaerke)
        ) +
        calculatedRecord.deckelKanteA -
        3
    );
    console.log("deckelBreite: " + calculatedRecord.deckelBreite);

    //Deckelhoehe
    calculatedRecord.deckelHoehe =
      this.toNumber(this.printingTemplateData.hoehe) +
      this.toNumber(this.printingTemplateData.deckelkanteObenUnten) * 2;
    console.log("deckelHoehe: " + calculatedRecord.deckelHoehe);

    //Einschlagbreite
    calculatedRecord.einschlag =
      this.printingTemplateService.findBreiteHoeheEinschlaegeByPappenstaerke(
        this.toNumber(this.printingTemplateData.pappenstaerke)
      );
    console.log("einschlag: " + calculatedRecord.einschlag);

    //Nutzenbreite
    calculatedRecord.nutzenBreite =
      2 *
        (calculatedRecord.deckelBreite +
          calculatedRecord.falzBreite +
          calculatedRecord.einschlag) +
      calculatedRecord.rueckenBreite;
    console.log("nutzenBreite: " + calculatedRecord.nutzenBreite);

    //Nutzenhöhe
    calculatedRecord.nutzenHoehe =
      calculatedRecord.deckelHoehe +
      2 *
        this.printingTemplateService.findBreiteHoeheEinschlaegeByPappenstaerke(
          this.toNumber(this.printingTemplateData.pappenstaerke)
        );
    console.log("nutzenHoehe: " + calculatedRecord.nutzenHoehe);

    return calculatedRecord;
  }

  generateBezugRequest(calculatedRecord: CalculatedBezugRecord) {
    var bezug = new Bezug();
    bezug.produktTeil = this.printingTemplateService.getProdukttypName(
      "BEZUG",
      this.tmpLocale
    );
    bezug.language = this.printingTemplateService.getLanguage(this.tmpLocale);

    var bezugRecord = new BezugRecords();
    bezugRecord.produktTeilKnz = "UE";
    bezugRecord.nutzenBreite = calculatedRecord.nutzenBreite.toString();
    bezugRecord.nutzenHoehe = calculatedRecord.nutzenHoehe.toString();
    bezugRecord.rueckenBreite = calculatedRecord.rueckenBreite.toString();
    bezugRecord.deckelBreite = calculatedRecord.deckelBreite.toString();
    bezugRecord.deckelHoehe = calculatedRecord.deckelHoehe.toString();
    bezugRecord.falzBreite = calculatedRecord.falzBreite.toString();
    bezugRecord.einschlag = calculatedRecord.einschlag.toString();
    bezugRecord.bezeichnung = this.printingTemplateData.titel;
    bezugRecord.produktTyp =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.produkttyp,
        this.produktTypenDatasource
      );
    bezugRecord.endFormat = this.printingTemplateService.getFormattedEndFormat(
      this.printingTemplateData.breite,
      this.printingTemplateData.hoehe
    );
    bezugRecord.umfang = this.printingTemplateService.getUmfang(
      this.tmpLocale,
      this.printingTemplateData.umfang
    );
    bezugRecord.inhaltsPapier = this.printingTemplateService.getInhaltsPapier(
      this.tmpLocale,
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.inhaltPapier,
        this.inhaltPapierDatasource
      ),
      this.printingTemplateData.inhaltPapierGrammatur,
      this.printingTemplateData.inhaltPapierVolumen
    );
    bezugRecord.bindeArt = this.printingTemplateService.getSelectBoxNameByValue(
      this.printingTemplateData.bindeart,
      this.bindeartDatasource
    );
    bezugRecord.rueckenForm =
      this.printingTemplateService.getSelectBoxNameByValue(
        this.printingTemplateData.rueckenform,
        this.rueckenformDatasource
      );
    bezugRecord.pappenDicke = this.printingTemplateData.pappenstaerke;

    if (this.printingTemplateData.bildteilUmfang !== "0") {
      bezugRecord.umfangBildTeil = this.printingTemplateService.getUmfang(
        this.tmpLocale,
        this.toNumber(
          this.printingTemplateService.getSelectBoxNameByValue(
            this.printingTemplateData.bildteilUmfang,
            this.bildteilUmfangDatasource
          )
        )
      );
      bezugRecord.papierBildTeil =
        this.printingTemplateService.getSelectBoxNameByValue(
          this.printingTemplateData.bildteilMaterial,
          this.bildteilMaterialDatasource
        );
    }

    bezugRecord.deckelKanteA = calculatedRecord.deckelKanteA.toString();
    bezugRecord.deckelKanteOU = calculatedRecord.deckelKanteOU.toString();
    bezugRecord.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    let records: BezugRecords[];
    records = [bezugRecord];

    bezug.records = records;

    let jsonRequestBezug = JSON.stringify(bezug);
    console.log("jsonRequestBezug: " + jsonRequestBezug);
    let filename = this.generateFilename(
      "BEZUG",
      bezugRecord.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestBezug);
  }

  //Farbschnitt Hardcover
  calculateFarbschnittHC(): CalculatedFarbschnittRecord[] {
    console.log("CalculatedFarbschnittHCRecord Start");
    var calculatedRecordList: CalculatedFarbschnittRecord[] = [];

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil;
    console.log("blockstaerke: " + blockstaerke);

    //Nutzenhöhe Kopf/Fuss
    var nutzenHoeheKopfFuss = this.toNumber(this.printingTemplateData.breite);
    console.log("nutzenHoeheKopfFuss: " + nutzenHoeheKopfFuss);

    //Nutzenhöhe Vorderschnitt
    var nutzenHoeheVorderschnitt = this.toNumber(
      this.printingTemplateData.hoehe
    );
    console.log("nutzenHoeheVorderschnitt: " + nutzenHoeheVorderschnitt);

    //Farbschnitt Kopf/Fuß
    var calculatedRecordKopfFuss = new CalculatedFarbschnittRecord();
    calculatedRecordKopfFuss.nutzenBreite = Math.ceil(blockstaerke * 2) / 2;
    calculatedRecordKopfFuss.nutzenHoehe = nutzenHoeheKopfFuss;

    //Farbschnitt Vorderschnitt
    var calculatedRecordVorderschnitt = new CalculatedFarbschnittRecord();
    calculatedRecordVorderschnitt.nutzenBreite =
      Math.ceil(blockstaerke * 2) / 2;
    calculatedRecordVorderschnitt.nutzenHoehe = nutzenHoeheVorderschnitt;

    calculatedRecordList.push(calculatedRecordKopfFuss);
    calculatedRecordList.push(calculatedRecordVorderschnitt);
    calculatedRecordList.push(calculatedRecordKopfFuss);

    return calculatedRecordList;
  }

  //Farbschnitt Softcover
  calculateFarbschnittSC(): CalculatedFarbschnittRecord[] {
    console.log("CalculatedFarbschnittSCRecord Start");
    var calculatedRecordList: CalculatedFarbschnittRecord[] = [];

    //Blockstärke
    var blattdickeInhaltsteil =
      (this.toNumber(this.printingTemplateData.inhaltPapierGrammatur) *
        this.toNumber(this.printingTemplateData.inhaltPapierVolumen)) /
      1000;
    console.log("blattdickeInhaltsteil: " + blattdickeInhaltsteil);
    var dickeInhaltsteil =
      (this.printingTemplateData.umfang / 2) * blattdickeInhaltsteil;
    console.log("dickeInhaltsteil: " + dickeInhaltsteil);
    var blattdickeBildteil =
      (this.printingTemplateService.findGrammaturByBildteileMaterial(
        this.printingTemplateData.bildteilMaterial
      ) *
        this.printingTemplateService.findVolumenfaktorBildteileByBildteilMaterial(
          this.printingTemplateData.bildteilMaterial
        )) /
      1000;
    console.log("blattdickeBildteil: " + blattdickeBildteil);
    var dickeBildteil =
      (this.toNumber(this.printingTemplateData.bildteilUmfang) / 2) *
      blattdickeBildteil;
    console.log("dickeBildteil: " + dickeBildteil);
    var blockstaerke = dickeInhaltsteil + dickeBildteil;
    console.log("blockstaerke: " + blockstaerke);

    //Nutzenhöhe Kopf/Fuss
    var nutzenHoeheKopfFuss = this.toNumber(this.printingTemplateData.breite);
    console.log("nutzenHoeheKopfFuss: " + nutzenHoeheKopfFuss);

    //Nutzenhöhe Vorderschnitt
    var nutzenHoeheVorderschnitt = this.toNumber(
      this.printingTemplateData.hoehe
    );
    console.log("nutzenHoeheVorderschnitt: " + nutzenHoeheVorderschnitt);

    //Farbschnitt Kopf/Fuß
    var calculatedRecordKopfFuss = new CalculatedFarbschnittRecord();
    calculatedRecordKopfFuss.nutzenBreite = Math.ceil(blockstaerke * 2) / 2;
    calculatedRecordKopfFuss.nutzenHoehe = nutzenHoeheKopfFuss;

    //Farbschnitt Vorderschnitt
    var calculatedRecordVorderschnitt = new CalculatedFarbschnittRecord();
    calculatedRecordVorderschnitt.nutzenBreite =
      Math.ceil(blockstaerke * 2) / 2;
    calculatedRecordVorderschnitt.nutzenHoehe = nutzenHoeheVorderschnitt;

    calculatedRecordList.push(calculatedRecordKopfFuss);
    calculatedRecordList.push(calculatedRecordVorderschnitt);
    calculatedRecordList.push(calculatedRecordKopfFuss);

    return calculatedRecordList;
  }

  generateFarbschnittRequest(calculatedRecord: CalculatedFarbschnittRecord[]) {
    var farbschnitt = new Farbschnitt();
    farbschnitt.produktTeil = this.printingTemplateService.getProdukttypName(
      "FARBSCHNITT",
      this.tmpLocale
    );
    farbschnitt.language = this.printingTemplateService.getLanguage(
      this.tmpLocale
    );

    var bezugRecordKopf = new FarbschnittRecords();
    bezugRecordKopf.produktTeilKnz = "FS_K";
    bezugRecordKopf.nutzenBreite = calculatedRecord[0].nutzenBreite.toString();
    bezugRecordKopf.nutzenHoehe = calculatedRecord[0].nutzenHoehe.toString();
    bezugRecordKopf.bezeichnung = this.printingTemplateData.titel;

    bezugRecordKopf.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    var bezugRecordVorderschnitt = new FarbschnittRecords();
    bezugRecordVorderschnitt.produktTeilKnz = "FS_V";
    bezugRecordVorderschnitt.anschnitt = "3";
    bezugRecordVorderschnitt.nutzenBreite =
      calculatedRecord[1].nutzenBreite.toString();
    bezugRecordVorderschnitt.nutzenHoehe =
      calculatedRecord[1].nutzenHoehe.toString();
    bezugRecordVorderschnitt.bezeichnung = this.printingTemplateData.titel;

    bezugRecordVorderschnitt.datum =
      this.printingTemplateService.getFormattedDate(this.tmpLocale);

    var bezugRecordFuss = new FarbschnittRecords();
    bezugRecordFuss.produktTeilKnz = "FS_F";
    bezugRecordFuss.anschnitt = "3";
    bezugRecordFuss.nutzenBreite = calculatedRecord[2].nutzenBreite.toString();
    bezugRecordFuss.nutzenHoehe = calculatedRecord[2].nutzenHoehe.toString();
    bezugRecordFuss.bezeichnung = this.printingTemplateData.titel;

    bezugRecordFuss.datum = this.printingTemplateService.getFormattedDate(
      this.tmpLocale
    );

    var farbschnittRecords: FarbschnittRecords[] = [];
    farbschnittRecords.push(bezugRecordKopf);
    farbschnittRecords.push(bezugRecordVorderschnitt);
    farbschnittRecords.push(bezugRecordFuss);

    farbschnitt.records = farbschnittRecords;

    let jsonRequestBezug = JSON.stringify(farbschnitt);
    console.log("jsonRequestBezug: " + jsonRequestBezug);
    let filename = this.generateFilename(
      "FARBSCHNITT",
      bezugRecordKopf.bezeichnung || ""
    );
    this.downloadPDF(filename, jsonRequestBezug);
  }

  generateFilename(produktTeil: String, title: String): String {
    let filename = title.replace(/[\\/:+?"<>|]/g, "");
    let produktTeilName = "";

    if (produktTeil === "BROSCHURUMSCHLAG") {
      if (this.tmpLocale == "de-DE") {
        produktTeilName = "broschurumschlag_layout";
      } else {
        produktTeilName = "softcover_layout";
      }
    } else if (produktTeil === "BEZUG") {
      if (this.tmpLocale == "de-DE") {
        produktTeilName = "bezug_layout";
      } else {
        produktTeilName = "cover_layout";
      }
    } else if (produktTeil === "SCHUTZUMSCHLAG") {
      if (this.tmpLocale == "de-DE") {
        produktTeilName = "schutzumschlag_layout";
      } else {
        produktTeilName = "dustjacket_layout";
      }
    } else if (produktTeil === "VOR_UND_NACHSATZ") {
      if (this.tmpLocale == "de-DE") {
        produktTeilName = "vor-und-nachsatz_layout";
      } else {
        produktTeilName = "endpapers_layout";
      }
    } else if (produktTeil === "FARBSCHNITT") {
      if (this.tmpLocale == "de-DE") {
        produktTeilName = "farbschnitt_layout";
      } else {
        produktTeilName = "edgeprint_layout";
      }
    }

    return filename + "_" + produktTeilName;
  }

  downloadPDF(filename: String, json: String) {
    this.scalerService.downloadPrintingTemplatePdf(filename, json);
  }
}

@NgModule({
  imports: [
    DxButtonModule,
    DxFormModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    CommonModule,
  ],
  declarations: [PrintingTemplateComponent],
  exports: [PrintingTemplateComponent],
})
export class PrintingTemplateModule {}
