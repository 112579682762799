import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import DataSource from "devextreme/data/data_source";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxTextBoxModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { ScreenService } from "../../services";
import {
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../model/order/order";
import {
  asStudio,
  Studio,
  StudioEntity,
} from "../../model/organization/organization";
import { CommonModule } from "@angular/common";
import { AlertModule } from "../alert/alert.component";
import { OrganizationUnionTagModule } from "../tags/organization-union-tag/organization-union-tag.component";
import { OrganizationTagModule } from "../tags/organization-tag/organization-tag.component";

export class UpdateOrdersStudiosAssignmentsEvent {
  ordersIds: string[];
  studiosToAddIds: string[];
  studiosToRemoveIds: string[];

  constructor(
    ordersIds: string[],
    studiosToAddIds: string[],
    studiosToRemoveIds: string[]
  ) {
    this.ordersIds = ordersIds;
    this.studiosToAddIds = studiosToAddIds;
    this.studiosToRemoveIds = studiosToRemoveIds;
  }
}

@Component({
  selector: "app-order-studio-popup",
  templateUrl: "./order-studio-popup.component.html",
  styleUrls: ["./order-studio-popup.component.scss"],
})
export class OrderStudioPopupComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false })
  grid: DxDataGridComponent;

  formatMessage = formatMessage;
  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;
  asStudio = asStudio;

  @Input() orders: Order[];
  @Input() preFilter: any[] | undefined;
  @Input() studios: Studio[];

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<UpdateOrdersStudiosAssignmentsEvent>();

  isXSmall: boolean;
  isSmall: boolean;
  isMediumOrDown: boolean;

  simpleDataSource: DataSource;
  addableStudios: Studio[] = [];
  studiosToAdd: Studio[] = [];
  removableStudios: Studio[] = [];
  studiosToRemove: Studio[] = [];
  disableTagBoxes: boolean = true;
  isPrefilterActive: boolean = false;
  dateFilter: any[] | undefined = undefined;
  showConflictMessage: boolean;

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
    this.isSmall = this.screen.sizes["screen-small"];
    this.isMediumOrDown = this.screen.sizes["screen-medium-or-down"];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sortedOrders = this.sortOrdersByOrderDateDesc(this.orders);
    this.simpleDataSource = new DataSource<any, any>(sortedOrders);

    this.addableStudios = this.studios;
    this.studiosToAdd = [];
    this.removableStudios = [];
    this.studiosToRemove = [];
    this.dateFilter = undefined;
    this.disableTagBoxes = true;
    this.showConflictMessage = false;

    this.grid?.instance?.clearSelection();
    this.grid?.instance?.searchByText("");

    this.isPrefilterActive = !!this.preFilter;
    this.applyPrefilter();
  }

  handleOrderSelectionChanged(event) {
    this.computeStudiosToRemove(event.selectedRowsData);
    this.computeShowConflictMessage(event.selectedRowsData);
  }

  handleToAddValueChanged(): void {
    this.computeStudiosToRemove(this.grid.instance.getSelectedRowsData());
    this.computeShowConflictMessage(this.grid.instance.getSelectedRowsData());
  }

  handleToRemoveValueChanged(): void {
    this.addableStudios = this.studios.filter(
      (studio) =>
        this.studiosToRemove.findIndex(
          (toRemove) => toRemove.id === studio.id
        ) === -1
    );
  }

  applyPrefilter(): void {
    if (this.isPrefilterActive && !!this.preFilter) {
      this.grid?.instance?.filter(this.preFilter);
    }
  }

  removePrefilter(): void {
    this.grid.instance.filter(null);

    if (!!this.dateFilter) {
      this.grid.instance.filter(this.dateFilter);
    }

    this.isPrefilterActive = false;
  }

  handleDateValueChanged(event: any): void {
    if (event.value) {
      this.dateFilter = ["order.orderdate", ">=", event.value];

      const currentFilters = this.grid.instance.getCombinedFilter();

      if (!currentFilters) {
        this.grid.instance.filter(this.dateFilter);
      } else {
        const combined = [currentFilters, "and", this.dateFilter];

        this.grid.instance.filter(combined);
      }
    } else {
      this.dateFilter = undefined;

      if (this.isPrefilterActive && !!this.preFilter) {
        this.grid.instance.filter(this.preFilter);
      } else {
        this.grid.instance.filter(null);
      }
    }
  }

  asStudios(studioEntities: StudioEntity[]): Studio[] {
    return studioEntities.map((it) => asStudio(it));
  }

  calculateStudiosOfPublisherCellValue(rowData): string[] {
    return rowData.publisher.standardStudios.map((it) => it.displayName);
  }

  calculateStudiosCellValue(rowData): string[] {
    return rowData.studios.map((it) => it.displayName);
  }

  onSave() {
    const orderIds = this.grid.instance
      .getSelectedRowsData()
      .map((it) => it.id);

    const studiosToAddIds = this.studiosToAdd.map((it) => it.id);
    const studiosToRemoveIds = this.studiosToRemove.map((it) => it.id);

    this.save.emit(
      new UpdateOrdersStudiosAssignmentsEvent(
        orderIds,
        studiosToAddIds,
        studiosToRemoveIds
      )
    );

    this.dateFilter = undefined;
    this.visible = false;
  }

  private computeStudiosToRemove(selectedOrders): void {
    const studiosOfSelectedOrders = selectedOrders.flatMap((it) => it.studios);

    const studiosOfSelectedOrdersWithoutDuplicates =
      this.removeDuplicatesFromStudioArray(studiosOfSelectedOrders);

    this.removableStudios = studiosOfSelectedOrdersWithoutDuplicates.filter(
      (it) => this.studiosToAdd.findIndex((toAdd) => toAdd.id === it.id) === -1
    );

    this.disableTagBoxes =
      this.grid.instance.getSelectedRowsData().length === 0;
  }

  private removeDuplicatesFromStudioArray(studios): Studio[] {
    return studios.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  private computeShowConflictMessage(selectedOrders): void {
    const studiosOfSelectedOrders = selectedOrders.flatMap((it) => it.studios);
    const standardStudiosOfSelectedOrders = selectedOrders.flatMap(
      (it) => it.publisher.standardStudios
    );

    this.showConflictMessage =
      studiosOfSelectedOrders.some(
        (it) => this.studiosToAdd.findIndex((toAdd) => toAdd.id === it.id) != -1
      ) ||
      standardStudiosOfSelectedOrders.some(
        (it) => this.studiosToAdd.findIndex((toAdd) => toAdd.id === it.id) != -1
      );
  }

  getStandardStudioClass(studio: StudioEntity, rowData: any): string {
    if (
      rowData.row.isSelected &&
      this.studiosToAdd.findIndex((it) => it.id === studio.id) != -1
    ) {
      return "conflict";
    }

    return "";
  }

  getOrderStudioClass(studio: StudioEntity, rowData: any): string {
    if (rowData.row.isSelected) {
      if (this.studiosToRemove.findIndex((it) => it.id === studio.id) != -1) {
        return "to-remove";
      } else if (
        this.studiosToAdd.findIndex((it) => it.id === studio.id) != -1
      ) {
        return "conflict";
      }
    }

    return "";
  }

  private sortOrdersByOrderDateDesc(orders: Order[]): Order[] {
    return orders.sort(
      (a, b) =>
        new Date(b.order.orderdate).getTime() -
        new Date(a.order.orderdate).getTime()
    );
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxButtonModule,
    DxDataGridModule,
    DxTextBoxModule,
    DxTagBoxModule,
    CommonModule,
    DxDateBoxModule,
    AlertModule,
    OrganizationUnionTagModule,
    OrganizationTagModule,
    DxScrollViewModule,
  ],
  declarations: [OrderStudioPopupComponent],
  exports: [OrderStudioPopupComponent],
})
export class OrderStudioPopupModule {}
