<tr *ngIf="currentAncillaryItem.invoiceItemAncillaryCode == 'TEXTPREP_PREP'">
  <td>{{ "Prep" }}</td>
  <td>
    {{
      calculatorService.convertToLocaleUsPriceString(
        currentAncillaryItem.totalPrice,
        2
      )
    }}
  </td>
  <td *ngIf="calculationTableConfig.showSecondCol">
    {{
      calculatorService.convertToLocaleUsPriceString(
        calcReponse.ancillaryItemGroupList[1].ancillaryItemList[
          currentAncillaryItemIndex
        ].totalPrice,
        2
      )
    }}
  </td>
  <td *ngIf="calculationTableConfig.showThirdCol">
    {{
      calculatorService.convertToLocaleUsPriceString(
        calcReponse.ancillaryItemGroupList[2].ancillaryItemList[
          currentAncillaryItemIndex
        ].totalPrice,
        2
      )
    }}
  </td>
</tr>
<tr *ngIf="currentAncillaryItem.invoiceItemAncillaryCode == 'TEXTPREP_PLATES'">
  <td>{{ "Plates" }}</td>
  <td>
    {{
      calculatorService.convertToLocaleUsPriceString(
        currentAncillaryItem.totalPrice,
        2
      )
    }}
  </td>
  <td *ngIf="calculationTableConfig.showSecondCol">
    {{
      calculatorService.convertToLocaleUsPriceString(
        calcReponse.ancillaryItemGroupList[1].ancillaryItemList[
          currentAncillaryItemIndex
        ].totalPrice,
        2
      )
    }}
  </td>
  <td *ngIf="calculationTableConfig.showThirdCol">
    {{
      calculatorService.convertToLocaleUsPriceString(
        calcReponse.ancillaryItemGroupList[2].ancillaryItemList[
          currentAncillaryItemIndex
        ].totalPrice,
        2
      )
    }}
  </td>
</tr>
<tr class="sub-total" *ngIf="false">
  <td>{{ "Sub-Total" }}</td>
  <td>{{ "xx.xx" }}</td>
  <td *ngIf="calculationTableConfig.showSecondCol">{{ "xx.xx" }}</td>
  <td *ngIf="calculationTableConfig.showThirdCol">{{ "xx.xx" }}</td>
</tr>
