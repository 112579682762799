import {
  Component,
  Input,
  OnInit,
  NgModule,
  Output,
  EventEmitter,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { Produkt } from "../../../model/calculation/produkt";
import { ProduktService } from "../../../services/produkt.service";
import { CalculatorCsvImportModule } from "../../calculator/calculator-de/calculator-csv-import/calculator-csv-import.component";
import { ProduktFormData } from "../../../model/calculation/produktFormData";

@Component({
  selector: "app-produkt-csv-upload",
  templateUrl: "./produkt-csv-upload.component.html",
  styleUrls: ["./produkt-csv-upload.component.scss"],
})
export class ProduktCsvUploadComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() produktData: ProduktFormData;

  @Output() reloadEvent = new EventEmitter<any>();

  constructor(private produktService: ProduktService) {}

  ngOnInit(): void {}

  reloadDataGrid($event: any) {
    this.reloadEvent.next();
  }
}

@NgModule({
  imports: [CalculatorCsvImportModule],
  declarations: [ProduktCsvUploadComponent],
  exports: [ProduktCsvUploadComponent],
})
export class ProduktCsvUploadModule {}
