import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { formatMessage } from "devextreme/localization";
import { ScreenService } from "../../shared/services";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.scss"],
})
export class NewsletterComponent implements OnInit {
  constructor(private screen: ScreenService) {}

  formatMessage = formatMessage;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  listmonkUrl: string;

  isMobile: boolean;

  ngOnInit(): void {
    this.listmonkUrl = environment.listmonkUrl;

    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];
  }
}
