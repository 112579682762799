import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import {
  DxFileUploaderModule,
  DxFormComponent,
  DxFormModule,
} from "devextreme-angular";
import { NewsInformationRequest } from "../../../model/news/news";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-news-element-popup",
  templateUrl: "./news-element-popup.component.html",
  styleUrls: ["./news-element-popup.component.scss"],
})
export class NewsElementPopupComponent implements OnInit {
  @ViewChild(DxFormComponent) newsInfoForm: DxFormComponent;
  formatMessage = formatMessage;

  @ViewChild("newsletterDE") uploaderNewsletterDE: any;
  @ViewChild("newsletterEN") uploaderNewsletterEN: any;
  @ViewChild("previewImage") uploaderPreviewImage: any;

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onSave = new EventEmitter<void>();
  @Output() onError = new EventEmitter<void>();

  newsInformationRequest: NewsInformationRequest = new NewsInformationRequest();

  isNewsletterDeDropZoneActive: boolean = false;
  isNewsletterEnDropZoneActive: boolean = false;
  isPreviewImageDropZoneActive: boolean = false;

  showErrorBordersOnNewsletterDE: boolean = false;
  showErrorBordersOnNewsletterEN: boolean = false;
  showErrorBordersOnPreviewImage: boolean = false;

  newsletterDeRequiredError: boolean = false;
  newsletterEnRequiredError: boolean = false;
  previewImageRequiredError: boolean = false;

  newsletterDeErrorMessage: string = "";
  newsletterEnErrorMessage: string = "";
  previewImageErrorMessage: string = "";

  newsletterDeSelectedFileName: string = "";
  newsletterEnSelectedFileName: string = "";
  previewImageSelectedFileName: string = "";

  MAX_UPLOAD_FILESIZE = 10 * 1024 * 1024;
  maxFilesizeExceeded: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  save(): void {
    if (!this.newsInfoForm.instance.validate().isValid) {
      return;
    }

    if (this.maxFilesizeExceeded) {
      return;
    }

    const fileDE = this.uploaderNewsletterDE.instance._files[0];
    const fileEN = this.uploaderNewsletterEN.instance._files[0];
    const previewImage = this.uploaderPreviewImage.instance._files[0];

    if (fileDE?.isValid() && fileEN?.isValid() && previewImage?.isValid()) {
      const formData = new FormData();
      formData.append(
        "newsInfo",
        new Blob([JSON.stringify(this.newsInformationRequest)], {
          type: "application/json",
        })
      );
      formData.append("fileDE", fileDE.value);
      formData.append("fileEN", fileEN.value);
      formData.append("previewImage", previewImage.value);

      this.http
        .post(`${environment.baseUrl}/api/news`, formData)
        .toPromise()
        .then(() => this.onSave.emit())
        .catch((_) => this.onError.emit())
        .finally(() => (this.visible = false));
    } else {
      if (!fileDE) {
        this.newsletterDeRequiredError = true;
        this.showErrorBordersOnNewsletterDE = true;
      }

      if (!fileEN) {
        this.newsletterEnRequiredError = true;
        this.showErrorBordersOnNewsletterEN = true;
      }

      if (!previewImage) {
        this.previewImageRequiredError = true;
        this.showErrorBordersOnPreviewImage = true;
      }
      return;
    }
  }

  private checkSumOfFilesizes(): void {
    const newsletterDeSize = this.uploaderNewsletterDE.instance._files[0]?.value?.size || 0;
    const newsletterEnSize = this.uploaderNewsletterEN.instance._files[0]?.value?.size || 0;
    const newsletterPreviewSize = this.uploaderPreviewImage.instance._files[0]?.value?.size || 0;

    const sumOfFilesizes = newsletterDeSize + newsletterEnSize + newsletterPreviewSize;
    this.maxFilesizeExceeded = sumOfFilesizes > this.MAX_UPLOAD_FILESIZE;
  }

  handleNewsletterDeFileChanged(): void {
    this.resetNewsletterDeDropzoneValues();
    this.checkSumOfFilesizes();

    const file = this.uploaderNewsletterDE.instance._files[0];

    if (file?.value?.size > this.MAX_UPLOAD_FILESIZE) {
      this.isNewsletterDeDropZoneActive = false;
      this.showErrorBordersOnNewsletterDE = true;
      this.newsletterDeErrorMessage = formatMessage("news.invalidFileSize");
    } else if (!file?.isValid()) {
      this.isNewsletterDeDropZoneActive = false;
      this.showErrorBordersOnNewsletterDE = true;
      this.newsletterDeErrorMessage = formatMessage(
        "news.invalidFile.newsletterDE"
      );
    } else {
      this.resetNewsletterDeDropzoneErrorValues();
      this.newsletterDeSelectedFileName = file.value.name;
    }
  }

  handleNewsletterEnFileChanged(): void {
    this.resetNewsletterEnDropzoneValues();
    this.checkSumOfFilesizes();

    const file = this.uploaderNewsletterEN.instance._files[0];

    if (file?.value?.size > this.MAX_UPLOAD_FILESIZE) {
      this.isNewsletterEnDropZoneActive = false;
      this.showErrorBordersOnNewsletterEN = true;
      this.newsletterEnErrorMessage = formatMessage("news.invalidFileSize");
    } else if (!file?.isValid()) {
      this.isNewsletterEnDropZoneActive = false;
      this.showErrorBordersOnNewsletterEN = true;
      this.newsletterEnErrorMessage = formatMessage(
        "news.invalidFile.newsletterEN"
      );
    } else {
      this.resetNewsletterEnDropzoneErrorValues();
      this.newsletterEnSelectedFileName = file.value.name;
    }
  }

  handlePreviewImageFileChanged(): void {
    this.resetPreviewImageDropzoneValues();
    this.checkSumOfFilesizes();

    const file = this.uploaderPreviewImage.instance._files[0];

    if (file?.value?.size > this.MAX_UPLOAD_FILESIZE) {
      this.isPreviewImageDropZoneActive = false;
      this.showErrorBordersOnPreviewImage = true;
      this.previewImageErrorMessage = formatMessage("news.invalidFileSize");
    } else if (!file?.isValid()) {
      this.isPreviewImageDropZoneActive = false;
      this.showErrorBordersOnPreviewImage = true;
      this.previewImageErrorMessage = formatMessage(
        "news.invalidFile.previewImage"
      );
    } else {
      this.resetPreviewImageDropzoneErrorValues();
      this.previewImageSelectedFileName = file.value.name;
    }
  }

  resetNewsletterDeDropzoneErrorValues(): void {
    this.newsletterDeErrorMessage = "";
    this.newsletterDeRequiredError = false;
    this.showErrorBordersOnNewsletterDE = false;
  }

  resetNewsletterEnDropzoneErrorValues(): void {
    this.newsletterEnErrorMessage = "";
    this.newsletterEnRequiredError = false;
    this.showErrorBordersOnNewsletterEN = false;
  }

  resetPreviewImageDropzoneErrorValues(): void {
    this.previewImageErrorMessage = "";
    this.previewImageRequiredError = false;
    this.showErrorBordersOnPreviewImage = false;
  }

  private resetNewsletterDeDropzoneValues(): void {
    this.isNewsletterDeDropZoneActive = false;
    this.newsletterDeSelectedFileName = "";
    this.resetNewsletterDeDropzoneErrorValues();
  }

  private resetNewsletterEnDropzoneValues(): void {
    this.isNewsletterEnDropZoneActive = false;
    this.newsletterEnSelectedFileName = "";
    this.resetNewsletterEnDropzoneErrorValues();
  }

  private resetPreviewImageDropzoneValues(): void {
    this.isPreviewImageDropZoneActive = false;
    this.previewImageSelectedFileName = "";

    this.resetPreviewImageDropzoneErrorValues();
  }
}

@NgModule({
  imports: [
    ActionPopupModule,
    DxFormModule,
    DxFileUploaderModule,
    CommonModule,
  ],
  declarations: [NewsElementPopupComponent],
  exports: [NewsElementPopupComponent],
})
export class NewsElementPopupModule {}
