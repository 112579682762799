import {
  getPublisherGroupId,
  getPublisherIds,
  Organization,
} from "./organization/organization";

//  USA
export const ADMIN_US = "ADMIN_US";

export const USA_ROLES = [ADMIN_US];

//  DE
export const MANUFACTURER = "MANUFACTURER";
export const AGENCY_EMPLOYEE = "AGENCY_EMPLOYEE";
export const ADMIN = "ADMIN";
export const ACCOUNT_MANAGER = "ACCOUNT_MANAGER";
export const SALES_MANAGER = "SALES_MANAGER";
export const DATA_PROCESSING_PV = "DATA_PROCESSING_PV";
export const PREPRESS_EMPLOYEE = "PREPRESS_EMPLOYEE";

export const INTERNAL_USER_ROLES = [
  ADMIN,
  ADMIN_US,
  ACCOUNT_MANAGER,
  SALES_MANAGER,
  DATA_PROCESSING_PV,
  PREPRESS_EMPLOYEE,
];

export const EXTERNAL_USER_ROLES = [MANUFACTURER, AGENCY_EMPLOYEE];

export const MANUFACTURER_FOR_PUBLISHER_GROUP =
  "MANUFACTURER_FOR_PUBLISHER_GROUP";
export const MANUFACTURER_FOR_PUBLISHER = "MANUFACTURER_FOR_PUBLISHER";

export const EXTENDED_EXTERNAL_USER_ROLES = [
  MANUFACTURER_FOR_PUBLISHER_GROUP,
  MANUFACTURER_FOR_PUBLISHER,
  AGENCY_EMPLOYEE,
];

export function getExtendedUserRole(
  role: string,
  organization: Organization[]
): string {
  if (role === "MANUFACTURER" && !!organization) {
    if (!!getPublisherGroupId(organization)) {
      return MANUFACTURER_FOR_PUBLISHER_GROUP;
    } else if (getPublisherIds(organization)?.length > 0) {
      return MANUFACTURER_FOR_PUBLISHER;
    }
  }

  return role;
}
