import { Component, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ScreenService } from "../../shared/services";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  formatMessage = formatMessage;
  isXSmall: boolean;

  constructor(private screen: ScreenService) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }
}
