//SelectBox Values
export const produktTypSelectboxDe = [
  {
    name: "Hardcover ohne Schutzumschlag",
    value: "HARDCOVER_OHNE_SCHUTZUMSCHLAG",
  },
  {
    name: "Hardcover mit Schutzumschlag",
    value: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
  },
  {
    name: "Softcover",
    value: "SOFTCOVER",
  },
  {
    name: "Softcover mit Klappen",
    value: "SOFTCOVER_MIT_KLAPPEN",
  },
];

export const produktTypSelectboxEn = [
  {
    name: "Hardcover without Jacket",
    value: "HARDCOVER_OHNE_SCHUTZUMSCHLAG",
  },
  {
    name: "Hardcover with Jacket",
    value: "HARDCOVER_MIT_SCHUTZUMSCHLAG",
  },
  {
    name: "Softcover",
    value: "SOFTCOVER",
  },
  {
    name: "Softcover with Flaps",
    value: "SOFTCOVER_MIT_KLAPPEN",
  },
];

export const inhaltPapierSelectboxDe = [
  {
    name: "Werkdruckpapier",
    value: "WERKDRUCKPAPIER",
  },
  {
    name: "ungestrichenes Papier (kein Werkdruck)",
    value: "UNGESTRICHENES_PAPIER",
  },
  {
    name: "Bilderdruckpapier",
    value: "BILDERDRUCKPAPIER",
  },
];

export const inhaltPapierSelectboxEn = [
  {
    name: "Book Paper",
    value: "WERKDRUCKPAPIER",
  },
  {
    name: "Uncoated Paper (no Book Paper)",
    value: "UNGESTRICHENES_PAPIER",
  },
  {
    name: "Art Paper",
    value: "BILDERDRUCKPAPIER",
  },
];

export const bindeartSelectboxDe = [
  {
    name: "Klebebinden",
    value: "KLEBEBINDEN",
  },
  {
    name: "Fadenheften",
    value: "FADENHEFTEN",
  },
];

export const bindeartSelectboxEn = [
  {
    name: "Perfect Bound",
    value: "KLEBEBINDEN",
  },
  {
    name: "Thread Sewn",
    value: "FADENHEFTEN",
  },
];

export const broschurumschlagMaterialSelectboxDe = [
  {
    name: "Karton 180-220g/m²",
    value: "KARTON_180_220",
  },
  {
    name: "Karton 225-350g/m²",
    value: "KARTON_225_350",
  },
  {
    name: "Bilderdruck matt 130-200g/m²",
    value: "BILDERDRUCK_MATT_130_200",
  },
  {
    name: "Bilderdruck matt 210-300g/m²",
    value: "BILDERDRUCK_MATT_210_300",
  },
  {
    name: "Bilderdruck glänzend 130-200g/m²",
    value: "BILDERDRUCK_GLAENZEND_130_200",
  },
  {
    name: "Bilderdruck glänzend 210-300g/m²",
    value: "BILDERDRUCK_GLAENZEND_210_300",
  },
];

export const broschurumschlagMaterialSelectboxEn = [
  {
    name: "Cardboard 180-220gsm",
    value: "KARTON_180_220",
  },
  {
    name: "Cardboard 225-350gsm",
    value: "KARTON_225_350",
  },
  {
    name: "Art Paper matt 130-200gsm",
    value: "BILDERDRUCK_MATT_130_200",
  },
  {
    name: "Art Paper matt 210-300gsm",
    value: "BILDERDRUCK_MATT_210_300",
  },
  {
    name: "Art Paper glossy 130-200gsm",
    value: "BILDERDRUCK_GLAENZEND_130_200",
  },
  {
    name: "Art Paper glossy 210-300gsm",
    value: "BILDERDRUCK_GLAENZEND_210_300",
  },
];

export const klappenSelectboxDe = [
  {
    name: "leicht überstehend",
    value: "LEICHT_UEBERSTEHEND",
  },
  {
    name: "leicht zurückgesetzt",
    value: "LEICHT_ZURUECKGESETZT",
  },
  {
    name: "bündig",
    value: "BUENDIG",
  },
];

export const klappenSelectboxEn = [
  {
    name: "slightly supernatant",
    value: "LEICHT_UEBERSTEHEND",
  },
  {
    name: "slightly retracted",
    value: "LEICHT_ZURUECKGESETZT",
  },
  {
    name: "flush",
    value: "BUENDIG",
  },
];

export const bildteilUmfangSelectbox = [
  {
    name: "0",
    value: "0",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "6",
    value: "6",
  },
  {
    name: "8",
    value: "8",
  },
  {
    name: "10",
    value: "10",
  },
  {
    name: "12",
    value: "12",
  },
  {
    name: "14",
    value: "14",
  },
  {
    name: "16",
    value: "16",
  },
  {
    name: "18",
    value: "18",
  },
  {
    name: "20",
    value: "20",
  },
  {
    name: "22",
    value: "22",
  },
  {
    name: "24",
    value: "24",
  },
  {
    name: "26",
    value: "26",
  },
  {
    name: "28",
    value: "28",
  },
  {
    name: "30",
    value: "30",
  },
  {
    name: "32",
    value: "32",
  },
];

export const bildteilMaterialSelectboxDe = [
  {
    name: "Bilderdruck glänzend 115g/m²",
    value: "BILDERDRUCK_GLAENZEND_115",
  },
  {
    name: "Bilderdruck matt 115g/m²",
    value: "BILDERDRUCK_MATT_115",
  },
  {
    name: "Bilderdruck glänzend 130g/m²",
    value: "BILDERDRUCK_GLAENZEND_130",
  },
  {
    name: "Bilderdruck matt 130g/m²",
    value: "BILDERDRUCK_MATT_130",
  },
];

export const bildteilMaterialSelectboxEn = [
  {
    name: "Art Paper glossy 115gsm",
    value: "BILDERDRUCK_GLAENZEND_115",
  },
  {
    name: "Art Paper matt 115gsm",
    value: "BILDERDRUCK_MATT_115",
  },
  {
    name: "Art Paper glossy 130gsm",
    value: "BILDERDRUCK_GLAENZEND_130",
  },
  {
    name: "Art Paper matt 130gsm",
    value: "BILDERDRUCK_MATT_130",
  },
];

export const rueckenformSelectboxDe = [
  {
    name: "rund",
    value: "RUND",
  },
  {
    name: "gerade",
    value: "GERADE",
  },
];

export const rueckenformSelectboxEn = [
  {
    name: "round",
    value: "RUND",
  },
  {
    name: "square",
    value: "GERADE",
  },
];
export const farbschnittSelectboxDe = [
  {
    name: "Ja",
    value: "JA",
  },
  {
    name: "Nein",
    value: "NEIN",
  },
];

export const farbschnittSelectboxEn = [
  {
    name: "yes",
    value: "JA",
  },
  {
    name: "no",
    value: "NEIN",
  },
];
