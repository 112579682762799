import { Component, Input, OnInit } from "@angular/core";
import { CalculationResult } from "../../../../../../model/calculation/calculationResult";
import { NumberConverterService } from "../../../../../../services/number-converter.service";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "paper-handling",
  templateUrl: "./paper-handling.component.html",
  styleUrls: ["./paper-handling.component.scss"],
})
export class PaperHandlingComponent implements OnInit {
  @Input()
  calculationResponse: CalculationResult[];

  constructor(public converter: NumberConverterService) {}

  ngOnInit(): void {}

  formatMessage = formatMessage;
}
