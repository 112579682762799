import {
  Component,
  NgModule,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxListModule,
  DxPopoverModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToastModule,
  DxTooltipModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { CalculationService } from "generated/api/services";

import DataSource from "devextreme/data/data_source";
import { Router, RouterModule } from "@angular/router";
import { P3AuthService } from "../../services";
import { OrganizationUnionTagModule } from "../tags/organization-union-tag/organization-union-tag.component";
import { ConfirmationDialogModule } from "../confirmation-dialog/confirmation-dialog.component";
import { Calculation } from "generated/api/models";
import { custom } from "devextreme/ui/dialog";
import { TreeViewInstanceService } from "../../../shared/services/treeViewInstance.service";
import { LocalStorageService } from "../../services/localStorage.service";
import { Subject } from "rxjs";
import CustomStore from "devextreme/data/custom_store";

@Component({
  selector: "app-calculations-grid",
  templateUrl: "./calculations-grid.component.html",
  styleUrls: ["./calculations-grid.component.scss"],
})
export class CalculationsGridComponent implements OnInit, OnDestroy {
  // Alter Stand
  @ViewChild(DxDataGridComponent, { static: false })
  calculationGrid: DxDataGridComponent;
  customStore: CustomStore;
  dataSource: DataSource;
  calculations: Calculation[];
  locale: string;
  readonly allowedPageSizes = [10, 20, 50];

  showToast: boolean = false;
  toastMessage: string = "For some reason we need an initial value here...";
  toastType: string = "default";

  formatMessage = formatMessage;

  showConfirmationPopup: boolean = false;
  pagerText: string;
  stateStoreDelay: number = 0;

  constructor(
    private router: Router,
    private _p3AuthService: P3AuthService,
    private calculationService: CalculationService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  ngOnInit(): void {
    this._p3AuthService.getUser().subscribe((e) => {
      if (e.identity.locale) {
        this.locale = e.identity.locale;
      }
    });

    this.customStore = new CustomStore({
      key: "id",
      load: () => {
        return this.calculationService
          .listCalculations()
          .toPromise()
          .then((result) =>
            result.map((calculation) => ({
              ...calculation,
              bookFormat: `${calculation.width ?? "?"} x ${
                calculation.height ?? "?"
              }`,
            }))
          )
          .catch((e) => {
            throw e;
          });
      },
    });

    this.dataSource = new DataSource({
      store: this.customStore,
      pushAggregationTimeout: 0,
    });
  }

  contentReady(): void {
    this.resetPaging();
    this.updatePagerInfo();
  }

  resetPaging(): void {
    //BPGP3M-227
    let localStorageString = window.localStorage.getItem("p3-calculations");
    let gridColumns = this.calculationGrid.instance.getVisibleColumns();
    if (localStorageString) {
      let localStorage = JSON.parse(localStorageString);
      localStorage.columns.forEach((e) => {
        // triggered with sorting by column
        if (e.sortIndex != null && gridColumns[e.visibleIndex]) {
          if (
            gridColumns[e.visibleIndex]?.sortIndex != e.sortIndex ||
            gridColumns[e.visibleIndex]?.sortOrder != e.sortOrder ||
            gridColumns[e.visibleIndex]?.filterValue != e.filterValue
          ) {
            this.calculationGrid.instance.clearSelection();
            this.calculationGrid.instance.option("focusedRowIndex", -1);
          }
        }
      });
    }
  }

  updatePagerInfo(): void {
    //BPGP3M-318
    let currentPageSize = this.calculationGrid.instance.pageSize();
    let currentPage = this.calculationGrid.instance.pageIndex() + 1;
    let totalEntries = this.calculationGrid.instance.totalCount();

    let shownRows = new Array();
    shownRows[0] = currentPageSize * currentPage - (currentPageSize - 1); //min value per page
    if (currentPage * currentPageSize < totalEntries) {
      shownRows[1] = currentPageSize * currentPage; //max value per page
    } else {
      shownRows[1] = totalEntries;
    }
    shownRows[2] = totalEntries; //total amount of entries

    if (shownRows[0] != 0) {
      if (this.locale == "en-EN") {
        this.pagerText = `Showing ${shownRows[0]} to  ${shownRows[1]} of
        ${shownRows[2]} orders`;
      } else {
        this.pagerText = `Auftrag ${shownRows[0]} bis  ${shownRows[1]} von
        ${shownRows[2]}`;
      }
    }
  }

  showDetails(calculation: Calculation): void {
    if (calculation.pricesChanged || calculation.structureChanged) {
      var myDialog = custom({
        title: formatMessage("calculations.notice"),
        messageHtml: calculation.pricesChanged
          ? formatMessage("calculations.priceChanged")
          : formatMessage("calculations.structureChanged"),
        buttons: [
          {
            text: formatMessage("calculations.ok"),
            onClick: () => {
              return { ok: true };
            },
          },
        ],
      });
      myDialog.show().done((dialogResult) => {
        if (dialogResult.ok) {
          this.openCalculation(calculation);
        }
      });
    } else {
      this.openCalculation(calculation);
    }
  }

  private openCalculation(calculation: Calculation) {
    this.treeViewInstanceService
      .getTreeViewInstance()
      ?.selectItem("/kalkulationen");
    this.router.navigate([`/kalkulationen/${calculation.id}`]);
  }

  copyCalculation(calculation: Calculation): void {
    let calculationCopy: Calculation = { ...calculation };
    calculationCopy.id = undefined;
    calculationCopy.title += " (copy)";
    this.calculationService
      .saveCalculation({ body: calculationCopy })
      .subscribe((data) => {
        calculationCopy.id = data;
        this.showDetails(calculationCopy);
      });
  }

  deleteCalculation(calculation: Calculation): void {
    var myDialog = custom({
      title: formatMessage("calculations.delete"),
      messageHtml: formatMessage("calculations.sure"),
      buttons: [
        {
          text: formatMessage("calculations.yes"),
          onClick: () => {
            return { sure: true };
          },
        },
        {
          text: formatMessage("calculations.no"),
          onClick: () => {
            return { sure: false };
          },
        },
      ],
    });
    myDialog.show().done((dialogResult) => {
      if (dialogResult.sure) {
        this.calculationService
          .deleteCalculation({ id: calculation.id! })
          .subscribe(() => {
            //this.customStore.load()
            this.dataSource.reload();
          });
      }
    });
  }

  ngOnDestroy() {}

  resetLocalStorage(): void {
    window.localStorage.removeItem("p3-calculations");
    window.location.reload();
  }

  // Stand 29.07.2024: Archivierungslösung feature soll zu einem
  // anderen Zeitpunkt realeased werden oder geändert werden
  /*
  @ViewChild(DxDataGridComponent, { static: false })
  calculationGrid: DxDataGridComponent;
  dataSource: DataSource;
  calculations: Calculation[] = [];
  locale: string;
  readonly allowedPageSizes = [10, 20, 50];
  private storageObserver = new Subject<Calculation[]>();

  showToast: boolean = false;
  toastMessage: string = "For some reason we need an initial value here...";
  toastType: string = "default";

  formatMessage = formatMessage;

  showConfirmationPopup: boolean = false;
  pagerText: string;
  stateStoreDelay: number = 0;

  constructor(
    private router: Router,
    private _p3AuthService: P3AuthService,
    private calculationService: CalculationService,
    private treeViewInstanceService: TreeViewInstanceService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this._p3AuthService.getUser().subscribe((e) => {
      if (e.identity.locale) {
        this.locale = e.identity.locale;
      }
    });
    //Subscribe calculations to LocalStorage
    this.subscribeCalculationsToStorage();

    this.loadCalculationsDataStorage();

    this.loadCalculations();
  }

  contentReady(): void {
    this.resetPaging();
    this.updatePagerInfo();
  }

  resetPaging(): void {
    //BPGP3M-227
    let localStorageString = window.localStorage.getItem("p3-calculations");
    let gridColumns = this.calculationGrid.instance.getVisibleColumns();
    if (localStorageString) {
      let localStorage = JSON.parse(localStorageString);
      localStorage.columns.forEach((e) => {
        // triggered with sorting by column
        if (e.sortIndex != null && gridColumns[e.visibleIndex]) {
          if (
            gridColumns[e.visibleIndex]?.sortIndex != e.sortIndex ||
            gridColumns[e.visibleIndex]?.sortOrder != e.sortOrder ||
            gridColumns[e.visibleIndex]?.filterValue != e.filterValue
          ) {
            this.calculationGrid.instance.clearSelection();
            this.calculationGrid.instance.option("focusedRowIndex", -1);
          }
        }
      });
    }
  }

  updatePagerInfo(): void {
    //BPGP3M-318
    let currentPageSize = this.calculationGrid.instance.pageSize();
    let currentPage = this.calculationGrid.instance.pageIndex() + 1;
    let totalEntries = this.calculationGrid.instance.totalCount();

    let shownRows = new Array();
    shownRows[0] = currentPageSize * currentPage - (currentPageSize - 1); //min value per page
    if (currentPage * currentPageSize < totalEntries) {
      shownRows[1] = currentPageSize * currentPage; //max value per page
    } else {
      shownRows[1] = totalEntries;
    }
    shownRows[2] = totalEntries; //total amount of entries

    if (shownRows[0] != 0) {
      if (this.locale == "en-EN") {
        this.pagerText = `Showing ${shownRows[0]} to  ${shownRows[1]} of
        ${shownRows[2]} orders`;
      } else {
        this.pagerText = `Auftrag ${shownRows[0]} bis  ${shownRows[1]} von
        ${shownRows[2]}`;
      }
    }
  }

  showDetails(calculation: Calculation): void {
    if (calculation.pricesChanged || calculation.structureChanged) {
      var myDialog = custom({
        title: formatMessage("calculations.notice"),
        messageHtml: calculation.pricesChanged
          ? formatMessage("calculations.priceChanged")
          : formatMessage("calculations.structureChanged"),
        buttons: [
          {
            text: formatMessage("calculations.ok"),
            onClick: () => {
              return { ok: true };
            },
          },
        ],
      });
      myDialog.show().done((dialogResult) => {
        if (dialogResult.ok) {
          this.openCalculation(calculation);
        }
      });
    } else {
      this.openCalculation(calculation);
    }
  }

  private openCalculation(calculation: Calculation) {
    this.treeViewInstanceService
      .getTreeViewInstance()
      ?.selectItem("/kalkulationen");
    this.router.navigate([`/kalkulationen/${calculation.id}`]);
  }

  copyCalculation(calculation: Calculation): void {
    let calculationCopy: Calculation = { ...calculation };
    calculationCopy.id = undefined;
    calculationCopy.title += " (copy)";
    this.calculationService
      .saveCalculation({ body: calculationCopy })
      .subscribe((data) => {
        calculationCopy.id = data;
        this.showDetails(calculationCopy);
        this.loadCalculations();
      });
  }

  deleteCalculation(calculation: Calculation): void {
    var myDialog = custom({
      title: formatMessage("calculations.delete"),
      messageHtml: formatMessage("calculations.sure"),
      buttons: [
        {
          text: formatMessage("calculations.yes"),
          onClick: () => {
            return { sure: true };
          },
        },
        {
          text: formatMessage("calculations.no"),
          onClick: () => {
            return { sure: false };
          },
        },
      ],
    });
    myDialog.show().done((dialogResult) => {
      if (dialogResult.sure) {
        this.calculationService
          .deleteCalculation({ id: calculation.id! })
          .toPromise()
          .then(() => {
            //deleting row
            const index = this.calculations.findIndex(
              (data) => data.id === calculation.id
            );
            this.calculations.splice(index, 1);
            this.localStorageService.setData(
              "calculations-data",
              this.calculations
            );
            this.loadCalculationsDataStorage();

            this.toastMessage = formatMessage("calculations.deleted.success");
            this.toastType = "success";
          })
          .catch((e) => {
            this.toastMessage = formatMessage("calculations.deleted.error");
            this.toastType = "error";
          })
          .finally(() => (this.showToast = true));
      }
    });
  }
  resetLocalStorage(): void {
    window.localStorage.removeItem("p3-calculations");
    window.location.reload();
  }

  showConfirmDeletePopup() {
    this.showConfirmationPopup = true;
  }

  subscribeCalculationsToStorage() {
    this.storageObserver.subscribe((data) => {
      this.calculations = data;
    });
  }

  loadCalculations() {
    this.calculationService
      .listCalculationsByArchivedState({
        archivedState: ArchivedState.ACTIVE,
      })
      .toPromise()
      .then((result) =>
        this.localStorageService.setData(
          "calculations-data",
          result.map((calculation) => ({
            ...calculation,
            bookFormat: `${calculation.width ?? "?"} x ${
              calculation.height ?? "?"
            }`,
          }))
        )
      )
      .catch((e) => {
        throw e;
      })
      .finally(() => this.loadCalculationsDataStorage());
  }

  loadCalculationsDataStorage() {
    this.storageObserver.next(
      this.localStorageService.getData("calculations-data") as Calculation[]
    );
  }

  ngOnDestroy(): void {
    this.storageObserver.unsubscribe();
    //  saves latest calculations in localStorage before destroy
    this.loadCalculations();
  }

   */
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxFormModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxScrollViewModule,
    DxDateBoxModule,
    RouterModule,
    OrganizationUnionTagModule,
    DxSelectBoxModule,
    DxToastModule,
    DxPopoverModule,
    DxListModule,
    DxTooltipModule,
    ConfirmationDialogModule,
  ],
  declarations: [CalculationsGridComponent],
  exports: [CalculationsGridComponent],
})
export class CalculationsGridModule {}

export enum ArchivedState {
  ARCHIVED = "ARCHIVED",
  ACTIVE = "ACTIVE",
}
