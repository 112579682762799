import { Component, Input, NgModule, OnInit } from "@angular/core";
import {
  Order,
  stripTwoTrailingZerosFromOrderNumberDiso,
} from "../../../shared/model/order/order";
import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../../shared/services/treeViewInstance.service";
import { OrderService } from "../../../shared/services/order.service";
import { DxButtonModule, DxDataGridModule } from "devextreme-angular";
import { CommonModule } from "@angular/common";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-simple-order-grid",
  templateUrl: "./simple-order-grid.component.html",
})
export class SimpleOrderGridComponent implements OnInit {
  formatMessage = formatMessage;
  orderNumberDisoDisplay = stripTwoTrailingZerosFromOrderNumberDiso;

  @Input() orders: Order[];
  @Input() ordersByFailedPreflightsWithDuplicates: Order[] = [];
  @Input() isExternalUser: boolean;
  @Input() showOrderNumberDiso: boolean;
  @Input() showReceiverContact: boolean;
  @Input() showNumberOfPreflights: boolean = false;

  constructor(
    private router: Router,
    private treeViewInstanceService: TreeViewInstanceService,
    private orderService: OrderService
  ) {
    this.calculateNumberOfFailedPreflights =
      this.calculateNumberOfFailedPreflights.bind(this);
  }

  ngOnInit(): void {}

  navigateToUpload(order: Order): void {
    this.router.navigate([`/upload/${order.id}`]);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }

  isValidCategory(order: Order): boolean {
    return this.orderService.isValidCategory(order.order.titleinfo.category);
  }

  calculateNumberOfFailedPreflights(order: Order): number {
    return this.ordersByFailedPreflightsWithDuplicates.filter(
      (it) => it.id === order.id
    ).length;
  }
  translateStatus(rowData): string {
    try {
      if (rowData && rowData.status && !!rowData.status?.status) {
        return formatMessage(`order.statusValue.${rowData.status.status}`);
      }
      return formatMessage(`order.statusValue.NOT_SET`);
    } catch (e) {
      return formatMessage(`order.statusValue.NOT_SET`);
    }
  }
}

@NgModule({
  imports: [DxButtonModule, DxDataGridModule, CommonModule],
  declarations: [SimpleOrderGridComponent],
  exports: [SimpleOrderGridComponent],
})
export class SimpleOrderGridModule {}
