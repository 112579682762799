import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { formatMessage } from "devextreme/localization";
import { DxTabPanelModule } from "devextreme-angular";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class OrderComponent implements OnInit {
  formatMessage = formatMessage;
  orderId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.orderId = params.get("id") || "";
    });
  }
}
