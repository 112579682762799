import { Component, NgModule, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";

@Component({
  templateUrl: "./calculations-archived.component.html",
  styleUrls: ["./calculations-archived.component.scss"],
})
export class CalculationsArchivedComponent implements OnInit {
  formatMessage = formatMessage;
  constructor() {}

  ngOnInit(): void {}
}
