<ng-template [ngIf]="(locale | async) === 'en-EN'" [ngIfElse]="default">
  <h2 class="content-block">T&C</h2>
  <h3 class="content-block">Delivery and Payment Conditions</h3>

  <h4 class="content-block">1. Scope of application</h4>
  <div class="content-block">
    <h5>
      1.1 These delivery and payment conditions only apply if our client is an
      entrepreneur, (Section 14 BGB (German Civil Code)), a legal person under
      public law or a special fund under public law. Our deliveries, services
      and offers are solely made available on the basis of these terms and
      conditions, which form an integral part of all contracts concluded with
      the client and shall apply to all future deliveries, services or offers
      provided to the client without requiring a separate agreement in each
      instance. We are not bound by any deviating conditions of the client or
      third parties unless explicitly agreed to in writing; this shall also
      apply in the event that we do not explicitly reject said conditions.
    </h5>
    <h5>
      1.2 These delivery and payment conditions shall not apply individually if
      and in so far we have explicitly concluded a written deviating agreement.
      The requirement for the written form shall also apply in the event of a
      waiver or amendment of this clause (1.2). Our employees do not have the
      power to deviate from the present conditions.
    </h5>
    <h5>
      1.3 The authorisation to conclude deviating agreements or grant approvals
      is only granted to one person authorised to act on the company's behalf.
    </h5>
    <h5>
      1.4 Unless otherwise agreed, the version of these delivery and payment
      conditions which shall apply shall be those which were valid at the time
      of the client's order, or the last text version communicated to the
      client, and shall apply as a framework agreement to which future contracts
      will be subject without requiring us to reference said conditions for each
      individual case.
    </h5>
  </div>
  <h4 class="content-block">2. Conclusion of contract</h4>
  <h5 class="content-block">
    Our offers are subject to alteration and are non-binding, unless they are
    explicitly identified as binding or include a specific acceptance period.
    The contract only comes into effect upon our confirmation of the order.
  </h5>
  <h4 class="content-block">3. Prices</h4>
  <div class="content-block">
    <h5>
      3.1 The prices stipulated in our order confirmation shall apply. Unless
      otherwise agreed, prices are given in Euros ex works (EXW as per Incoterms
      2010) plus statutory sales tax and, in the case of export deliveries;
      customs charges and other fees.
    </h5>
    <h5>
      3.2 Preliminary work commissioned by the client, such as probes, samples,
      drafts, sketches, are calculated in addition.
    </h5>
    <h5>
      3.3 Should processing an order take longer than four months, we shall be
      entitled to adjust the prices stipulated in the order confirmation by an
      amount commensurate with the increase in the costs upon which the
      calculation is based (salary and wages, material, general business costs).
      In such an event, the client is entitled to withdraw from the contract
      should a price increase of more than 8% per year have been observed since
      conclusion of the contract.
    </h5>
    <h5>
      3.4 Should additional work be required following the awarding of the order
      which was not evident upon contract conclusion, we shall be entitled to
      invoice for this work in addition. Should the surcharge exceed 10% of the
      total price, the client is entitled to withdraw from the contract unless
      we previously referenced such an unplanned price increase and the client
      did not reject said change in writing.
    </h5>
    <h5>
      3.5 Required amendments for which we are not accountable, or other
      amendments deviating from the first printed copy, particularly corrections
      arranged by the client, will be invoiced in accordance with the working
      hours spent. The client also assumes liability for production standstills
      which arise as a result, including machinery standstills.
    </h5>
    <h5>
      3.6 For orders with delivery to third parties, the orderer shall be deemed
      the client unless an explicit agreement has been reached to the contrary.
    </h5>
  </div>
  <h4 class="content-block">4. Payment terms</h4>
  <div class="content-block">
    <h5>
      4.1 The invoice will be issued on the day of delivery, partial delivery or
      readiness for delivery (obligation to collect, delay in accepting).
      Payment of the invoice sum is to be made without deduction within 21
      calendar days of the invoice date, subject to the following regulations. A
      discount will only be granted following prior written agreement. Payment
      of costs for shipping (freight, customs, postage) and packaging is due
      immediately upon receipt of the invoice.
    </h5>
    <h5>
      4.2 Bills of exchange and cheques are only accepted following special
      written agreement and on account of performance without allowance for
      discount. Discount charges and other costs are borne by the client and are
      due immediately.
    </h5>
    <h5>
      4.3 For larger orders, interim invoices may be issued in accordance with
      work performed, or partial payments may be requested.
    </h5>
    <h5>
      4.4 We are entitled to demand immediate payment in the event of
      extraordinarily large quantities of paper and cardboard (including special
      materials) being supplied.
    </h5>
    <h5>
      4.5 Should the client fail to pay by the due date, the outstanding sums
      are to be subject to interest of 5% p.a. starting from the due date; the
      assertion of higher interest rates and other damages in the event of
      arrears remains unaffected.
    </h5>
    <h5>
      4.6 In the case of bank transfers and cheques, the day on which the amount
      is credited to us shall constitute the receipt of payment day.
    </h5>
    <h5>
      4.7 Should the client fall into arrears with regards to a payment
      obligation to which they are subject arising from the contract, we shall
      be entitled to demand immediate payment for residual debt which would be
      due at a later point in time under normal circumstances.
    </h5>
    <h5>
      4.8 In the event of extraordinary advance services, an appropriate advance
      payment may be requested.
    </h5>
  </div>
  <h4 class="content-block">5. Right of retention, offsetting</h4>
  <div class="content-block">
    <h5>
      5.1 Should it become evident following contract conclusion that payment of
      outstanding receivables is jeopardised by a lack of solvency on the part
      of the client (e.g. the client has applied for insolvency proceedings or
      judicial or extrajudicial settlement proceedings have been requested), we
      can demand advance payment or a security deposit, retain goods which have
      not yet been delivered, as well as cease ongoing/future work. We are also
      entitled to these rights if the client is in arrears with regard to the
      payment of properly performed deliveries, which relate to the same legal
      relationship.
    </h5>
    <h5>
      5.2 We are entitled to a commercial right of retention with regard to
      films, manuscripts, raw materials, data and other items delivered by the
      client in accordance with Section 369 HGB (German Commercial Code) until
      all due claims from the business relationship have been settled in full.
    </h5>
    <h5>
      5.3 We are entitled to offset the client's claims against all other claims
      which other Bertelsmann group companies (particularly Arvato Services,
      Arvato Digital Services, Direct Group) have against the client.
    </h5>
    <h5>
      5.4 The client may only offset their counter claims or withhold payments
      due to such counter claims in so far as the counter claims are undisputed
      or legally established. This does not apply to any claims for costs of
      completion or defect rectification of the client. Furthermore, the client
      is not entitled to refuse performance or exercise rights of retention.
    </h5>
  </div>
  <h4 class="content-block">6. Delivery time, delivery</h4>
  <div class="content-block">
    <h5>
      6.1 Deliveries are performed ex work (EXW as per Incoterms 2010). Our
      delivery dates are regular targets and not fixed deadlines, unless this is
      expressly agreed in writing.
    </h5>
    <h5>
      6.2 The agreed delivery deadline is interrupted for the duration in which
      proofs, plots, completed samples, etc. are checked by the client, starting
      from the day of shipment to the client until the day their official
      response is received or approval is granted.
    </h5>
    <h5>
      6.3 Should the client request changes to the order following order
      confirmation, which would affect the time required for production, a new
      delivery period will commence once the changes have been confirmed.
    </h5>
    <h5>
      6.4 We are entitled to partial deliveries (advance deliveries) in so far
      as the client can make use of said partial deliveries, the delivery of the
      remaining goods is assured and the client does not experience any
      significant additional expense or additional costs (unless the client
      declares their willingness to accept said costs).
    </h5>
    <h5>
      6.5 For call orders, the client is obliged to accept the entire volume
      upon which the call order is based. The call-and-hold obligation of the
      client does not constitute a primary obligation. Unless otherwise agreed,
      an acceptance deadline of 12 months shall apply to call orders, calculated
      from the day in which the order was confirmed. Should complete acceptance
      not have taken place by this time, the contractor is entitled to impose a
      deadline of two weeks upon the client in which the respective order volume
      is to be accepted. Once this period has expired fruitlessly, the client
      has the choice between requesting advance payment of the purchase price
      and delivering the remaining volume, or withdrawal from the contract in
      accordance with Section 323 BGB. Other rights of the client, such as to
      demand damages, remain unaffected.
    </h5>
  </div>
  <h4 class="content-block">7. Delivery delay, inability to deliver</h4>
  <div class="content-block">
    <h5>
      7.1 Should the contractor be delayed in performance, the client can only
      exercise the rights arising from Section 323 BGB if the contractor is
      responsible for the delay. A change to the burden of proof is not
      connected to this clause (7.1).
    </h5>
    <h5>
      7.2 We cannot be held liable for deliveries which cannot feasibly be
      performed, or for delivery delays due to force majeure or other events
      which were unforeseeable upon contract conclusion and for which we are not
      accountable (e.g. operational interruptions, labour disputes, unrest,
      official measures) on our part or that of our pre-suppliers. Should
      delivery or performance be made difficult or impossible due to such
      events, and should the impediment last longer than four weeks, we shall be
      entitled to withdraw from the contract. For impediments of a temporary
      duration, the delivery deadline shall be extended in line with the time
      period of said impediment plus an appropriate start-up period. Liability
      on the part of the contractor is excluded in such cases.
    </h5>
  </div>
  <h4 class="content-block">8. Transfer or risk, shipment</h4>
  <div class="content-block">
    <h5>
      8.1 Shipment takes place on the account of the client and at their risk.
      The risk of accidental loss and accidental deterioration transfers to the
      client once the shipment has been handed over to the person or institution
      performing transportation, or has left our workshop for the purposes of
      shipment. If the goods are ready for shipment and the shipment, or
      acceptance thereof, is delayed for reasons for which we cannot be held
      responsible, the risk of accidental loss and accidental deterioration
      transfers to the client once notification of readiness for shipment has
      been received.
    </h5>
    <h5>
      8.2 The choice of delivery route and means of delivery shall be at be at
      our discretion unless agreed otherwise.
    </h5>
    <h5>
      8.3 If requested, the delivery can be insured during delivery at the cost
      of the client.
    </h5>
  </div>
  <h4 class="content-block">9. Transfer of personal data</h4>
  <h5 class="content-block">
    Personal data must be protected against unauthorised access during
    electronic transmission in accordance with No. 4 of the Annex to Section 9
    BDSG (German Federal Data Protection Act) using encryption methods
    representing the latest technological advances.
  </h5>
  <h4 class="content-block">10. Default of acceptance</h4>
  <div class="content-block">
    <h5>
      10.1 If the client does not collect respectively take over the goods at
      the agreed date (or in case not date has been agreed within a reasonable
      period of time after production), respectively at the date to which
      dispatch is notified, then we are entitled to store the goods at the risk
      and account of client.
    </h5>
    <h5>
      10.2 Should the client continue to refuse acceptance following an
      appropriate grace period and under threat of refusal, or has previously
      definitively declared that they do not intend to accept, we are entitled,
      notwithstanding other possible rights, to withdraw from the contract or
      demand damages due to non-fulfilment.
    </h5>
  </div>
  <h4 class="content-block">11. Objections</h4>
  <div class="content-block">
    <h5>
      11.1 The client is to check in every case that the delivered goods are
      contractually compliant and that delivered preliminary and interim
      products are correct without delay. This obligation to examine the
      delivered goods shall also apply when samples have been sent. The risk for
      any errors transfers to the client once readiness for printing has been
      declared unless these errors only arise during the production process
      following the readiness for printing declaration, or could only be
      recognised at this point. The same shall apply for all other release
      declarations of the client for continued production.
    </h5>
    <h5>
      11.2 Obvious defects are to be reported in writing within a limitation
      period of one week following receipt of the goods; concealed defects are
      to be reported within a week of the discovering the defect, or by the
      point in time at which the defect becomes noticeable during normal use of
      the goods by the client without closer examination; in all other cases,
      the assertion of warranty claims is excluded. The buyer is to provide us
      with the required time and opportunity to perform the supplementary work;
      this particularly includes handing over the goods for which an objection
      has been received for the purposes of verification. Expenses required for
      the purposes of verification and supplementary performance, particularly
      transport, route, work and material costs (does not include expansion and
      installation work) are to be borne by us if an actual defect is present.
      Otherwise, we can demand reimbursement from the client should their
      request for defect rectification be unfounded (particularly verification
      and tran port costs), unless the lack of defectiveness could not be
      recognised by the client.
    </h5>
    <h5>
      11.3 Defects relating to a portion of the delivered goods do not entitle
      the client to object to the entire delivery, unless the client has no
      interest in said partial delivery.
    </h5>
    <h5>
      11.4 In the case of established objections, we initially have the right to
      supplementary performance and/or replacement delivery. Should
      supplementary performance fail (replacement delivery), or it is not
      performed within a reasonable time period or refused, the client can
      demand a reduction in remuneration or annulment of the contract. Any
      further warranty and liability for damages, particularly for the results
      of defects, are excluded unless we or our auxiliary agents committed
      intentional acts or gross negligence, or explicitly assured specific
      properties.
    </h5>
    <h5>
      11.5 For reproductions within a printing procedure, minor deviations from
      the original or the model cannot be objected to. The same shall apply to
      the comparison between proofs and production prints. Furthermore,
      liability for defects which do not impair the value or usability (or only
      to an insignificant extent) is excluded.
    </h5>
    <h5>
      11.6 In the case of significant deviations from standards and with regard
      to the quality of papers, cardboard etc. and other materials procured by
      us, we only assume liability to the extent of our own claims against the
      paper and cardboard suppliers or other suppliers. In such an event, we are
      to be released from our liability if we assign our claims against the
      suppliers to the client. We are liable as a guarantor if claims against
      the paper and cardboard suppliers, etc. have not arisen through our own
      fault or such claims cannot be enforced.
    </h5>
    <h5>
      11.7 For lightfastness, variability and deviations in colours and
      finishes, as well as for the texture of rubber, paint, impregnation, etc.,
      we are liable only insofar as defects in the materials were recognisable
      before use through proper examination. However, we are not liable for
      material-dependent deviations if the client specified said materials for
      use.
    </h5>
    <h5>
      11.8 Should special work, such as embossing, puncting, perforating,
      coating, laminating etc. be performed by a third party firm, the
      conditions in Clause 11.6 shall apply accordingly.
    </h5>
    <h5>
      11.9 Excess or short deliveries up to 5% of the ordered quantity cannot be
      objected to. The delivered quantity shall be used for calculations. This
      percentage shall rise to 10% in the case of particularly difficult print
      jobs as well as orders including up to 2,000 copies. In addition, the
      percentages for excess or short delivery shall increase in line with the
      tolerance rates of the delivery conditions of the professional
      associations of papermaking from which paper is procured.
    </h5>
    <h5>
      11.10 Deliveries (including data storage devices, transferred data) by the
      client or a third party acting on their behalf are not subject to an
      obligation to verify on our part. This shall not apply to data which is
      incapable of being processed or read. For data transfers, the client must
      employ programmes to protect against computer viruses which correspond to
      the latest technological advancements prior to transfers taking place.
      Data securing is solely incumbent upon the client. We are entitled to
      create a copy.
    </h5>
  </div>
  <h4 class="content-block">12. Commercial practice</h4>
  <h5 class="content-block">
    The commercial practices of the printing industry shall determine the
    commercial transactions (e.g. no obligation to surrender interim products
    such as data, lithographs or printing plates created for producing the end
    product) in so far as no order to the contrary has been granted.
  </h5>
  <h4 class="content-block">13. Retention of title</h4>
  <div class="content-block">
    <h5>
      13.1 The delivered goods remain our property until all claims arising from
      the business relationship have been settled and the bills of exchange or
      cheques have been redeemed. For open invoices, the retained title provides
      security for our outstanding balance claim. These goods may not be pledged
      to third parties before complete payment nor be transferred by way of
      security. The client is to provide written notification to us if and in so
      far as third parties have accessed the goods belonging to the client.
    </h5>
    <h5>
      13.2 In the event of exchanging cheques/bills of exchange, ownership only
      passes over to the client until recourse arising from the bill can be
      eliminated.
    </h5>
    <h5>
      13.3 The client is only entitled to resell in the course of ordinary
      business. Claims of the client arising from a resale of the goods
      delivered by us, from the printing of advertisements and from the
      distribution of supplements in the delivered goods are as of now assigned
      to us for the purposes of securing all claims arising from the business
      relationship. Should the client include claims from a resale of the
      product to which title is retained in a current account relationship
      existing with a third party, as well as from the printing of
      advertisements and the distribution of supplements, particularly with a
      customer with whom there is an existing current account relationship, the
      respective assigned balance shall be deemed assigned to the extent of our
      claims.
    </h5>
    <h5>
      13.4 A lien shall be appointed for all raw materials of any type
      surrendered by the client for the purposes of securing all present and
      future claims of the supplier arising from deliveries of goods. In the
      event of editing or processing of the goods delivered by the contractor
      and those which are their property, we are considered the manufacturer
      pursuant to Section 950 BGB and shall retain ownership of the products at
      the time of processing. Should third parties be involved in
      editing/processing, our joint ownership is restricted to the extent of the
      invoice value of the goods subject to retention of title. The property
      thus acquired is considered reserved property.
    </h5>
    <h5>
      13.5 Should the realisable value of the securities exceed our total claim
      arising from the business relationship by more than 10%, we are obliged to
      release securities upon request by the client; the decision regarding
      which securities remains at our discretion.
    </h5>
  </div>
  <h4 class="content-block">14. Liability</h4>
  <div class="content-block">
    <h5>
      14.1 Claims for damages of the client are excluded, irrespective of legal
      ground.
    </h5>
    <h5>
      14.2 This limitation of liability does not apply to damage caused by:
    </h5>
    <ul>
      <li>Intentional acts or gross negligence,</li>
      <li>
        Slightly negligent breaches of significant contractual obligations -
        even those committed by our legal representatives or auxiliary agents -
        thus, liability is limited in accordance with the typical and
        foreseeable damage for said type of product,
      </li>
      <li>
        In the event of culpable injury to life, limb or health of the client,
      </li>
      <li>
        Fraudulently concealed defects, or any warranties assumed for the
        quality of the goods,
      </li>
      <li>Claims arising from the German Product Liability Act.</li>
    </ul>
    <h5>
      Significant contractual obligations are those which must be fulfilled to
      enable proper performance of the contract, those upon which the creditor
      relies and should be able to rely, and whose non-fulfilment endangers the
      attainment of the contractual purpose.
    </h5>
    <h5>
      14.3 Should we be liable pursuant to 14.2, this liability (except in the
      case of intentional acts or gross negligence or culpable injury to life,
      limb or health) is limited to the extent of the invoice amount for the
      corresponding order, as well as to direct damage and subsequent damage
      attributable to defects in the delivery object; replacement shall only be
      due if such damages can typically be expected when using the delivery
      object for its intended purpose.
    </h5>
    <h5>
      14.4 For damage during transport, liability is limited to the compensation
      offered by the assigned carrier.
    </h5>
    <h5>
      14.5 Claims of the client to warranty and damages expire after a year -
      excluding the claims for damages stipulated under Clause 14.2 - starting
      from the delivery or provision until collection of the goods.
    </h5>
    <h5>
      14.6 An unrestricted right of termination of the buyer (especially
      pursuant to Sections 651, 649 BGB) is excluded.
    </h5>
  </div>
  <h4 class="content-block">15. Provision of materials</h4>
  <div class="content-block">
    <h5>
      15.1 Material procured by the client (including paper and semi-finished
      products), regardless of type, is to be delivered to us free of charge in
      flawless condition. Receipt shall be confirmed without assuming
      responsibility for the accuracy of the volume designated as delivered. For
      larger batches, we are to be reimbursed for costs associated with counting
      the number of items and verifying the weight as well as storage expenses.
    </h5>
    <h5>
      15.2 The client bears the risk for the processability of the material they
      have provided. We are entitled to reject material in so far as it appears
      unsuitable for executing the order from the outset.
    </h5>
    <h5>
      15.3 In the event of paper and cardboard being provided by the client, the
      inevitable waste which accrues from printing equipment and production
      runs, as well as through trimming, punching and the like, transfers to our
      ownership. The client is obliged to take back packaging material.
    </h5>
    <h5>
      15.4 In the case of damage to or loss of the material provided by the
      client, we shall only be liable in so far as we or our auxiliary agents
      caused said damage or loss through intentional acts or gross negligence.
    </h5>
    <h5>
      15.5 In the case of material meant for reuse as well as semi-finished and
      finished products, including any left-over material that may belong to the
      client, remuneration shall only be granted beyond the delivery date
      following prior approval. Should no agreement have been concluded and the
      items have not been requested by the client within four weeks of
      completing the order, we shall be entitled to store these with a carrier
      on the account of and at the risk of the client. The client must arrange
      insurance for said items.
    </h5>
  </div>
  <h4 class="content-block">16. Copyright</h4>
  <div class="content-block">
    <h5>
      16.1 The client shall be exclusively responsible for verifying that there
      are corresponding rights to reproduce all print copies. The client bears
      sole liability should third party rights, especially copyrights,
      trademarks or personal rights, be breached during the course of executing
      their order. At first request, the client is to release us in full from
      all third party claims arising from such a legal infringement, including
      all costs for legal defence and prosecution.
    </h5>
    <h5>
      16.2 We are entitled to refuse orders should we be of the opinion that
      printing such content would contravene legal provisions. The client cannot
      derive claims against us therefrom. We are not under an obligation to
      check documentation for such a legal infringement.
    </h5>
    <h5>
      16.3 The copyright and the right to reproduce by means of any procedure
      and for any purpose proprietary sketches, drafts, originals, data, films
      and the like remains with us, notwithstanding explicit arrangements to the
      contrary.
    </h5>
  </div>
  <h4 class="content-block">17. Corrections, correction proofs</h4>
  <div class="content-block">
    <h5>
      17.1 Correction proofs, plots and proofs are to be checked by the client
      for typesetting errors and other errors and returned to us designated as
      ready for printing. We are not liable for errors overlooked by the client.
    </h5>
    <h5>17.2 We are not liable for delays due to late returns.</h5>
    <h5>
      17.3 We are not responsible for sending the client a correction proof in
      the case of smaller printing orders (e.g. company imprints), as well as
      for delivered print copies. Should no request be issued for the sending of
      a correction proof, liability for typesetting errors is limited to
      intentional acts and gross negligence.
    </h5>
    <h5>
      17.4 Orthography shall be solely determined by 'Duden', latest edition.
    </h5>
  </div>
  <h4 class="content-block">18. Archiving</h4>
  <h5 class="content-block">
    Our assumption is that we receive a copy of your data for production
    purposes while you also bear responsibility for retaining originals or your
    own copy of this data. Products created for the client, or provided to the
    client, particularly data and data storage devices, are only archived
    following explicit written agreement and against special payment which
    extends beyond the handover of the end product to the client or their
    auxiliary agents. Unless otherwise agreed, all data - including data storage
    devices - will be deleted/destroyed three months after the end of
    production. If the above-mentioned objects should be insured, this is to be
    arranged by the client in the absence of an agreement.
  </h5>
  <h4 class="content-block">19. Periodic work</h4>
  <h5 class="content-block">
    Contracts regarding frequently reoccurring printing work can be ordinarily
    terminated by providing notice of three months to the end of a month, unless
    otherwise agreed. The right of termination without notice for good cause
    remains unaffected thereby.
  </h5>
  <h4 class="content-block">
    20. Place of performance, court of jurisdiction, applicable law
  </h4>
  <div class="content-block">
    <h5>20.1 The place of performance is Pößneck.</h5>
    <h5>
      20.2 If the customer is a businessman within the meaning of HGB a legal
      entity under public law or a special fund under public law, the sole place
      of jurisdiction for all disputes (including international) arising
      directly or indirectly from the contractual relationship is our place of
      business, Pößneck respectively Jena. The same shall apply accordingly
      should the buyer be an entrepreneur within the meaning of Section 14 BGB.
      However, we are also entitled in all cases to bring legal action at the
      place of performance of the delivery obligation in accordance with these
      delivery and payment conditions or, as the case be, pursuant to an
      individual agreement which takes precedence; or legal proceedings more
      generally can be initiated at the legal jurisdiction of the client.
      Specific legal regulations which take precedence, particularly exclusive
      jurisdiction, remain unaffected.
    </h5>
    <h5>
      20.3 Relationships between the parties are solely subject to the law of
      the Federal Republic of Germany to the exclusion of any conflict of laws
      and the United Nations Convention on Contracts for the International Sale
      of Goods (CISG).
    </h5>
  </div>
  <h4 class="content-block">21. Bertelsmann - Code of Conduct</h4>
  <h5 class="content-block">
    GGP media explicitly refers to the code of conduct applicable within the
    Bertelsmann organisation, which can be viewed here:
    <a href="https://www.bertelsmann.com" target="_blank"
      >www.bertelsmann.com</a
    >
    GGP media expects its business partners to support us in ensuring compliance
    with the regulations and principles stipulated therein, and particularly
    expects support in implementing the principles set out by the United
    Nation's Global Compact Initiative concerning human rights, labour
    relationships, the environment and anti-corruption (<a
      href="https://www.unglobalcompact.org"
      target="_blank"
      >www.unglobalcompact.org</a
    >).
  </h5>
</ng-template>
<ng-template #default>
  <h2 class="content-block">AGB</h2>
  <h3 class="content-block">Liefer- und Zahlungsbedingungen</h3>

  <h4 class="content-block">1. Geltungsbereich</h4>
  <div class="content-block">
    <h5>
      1.1 Diese Liefer- und Zahlungsbedingungen gelten nur, wenn unser
      Auftraggeber Unternehmer (§ 14 BGB), eine juristische Person des
      öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist.
      Unsere Lieferungen, Leistungen und Angebote erfolgen ausschließlich
      aufgrund dieser Geschäftsbedingungen, welche Bestandteil aller mit dem
      Auftraggeber geschlossener Verträge sind und auch für alle zukünftigen
      Lieferungen, Leistungen oder Angebote an den Auftraggeber gelten, ohne
      dass sie nochmals gesondert vereinbart werden. Abweichende Bedingungen des
      Auftraggebers oder Dritter sind, soweit nicht ausdrücklich und schriftlich
      anders vereinbart, für uns unverbindlich, auch wenn wir ihnen nicht
      ausdrücklich widersprechen.
    </h5>
    <h5>
      1.2 Diese Liefer- und Zahlungsbedingungen gelten im Einzelnen nur dann
      nicht, wenn und soweit wir ausdrücklich und schriftlich eine abweichende
      Vereinbarung getroffen haben. Das Schriftformerfordernis gilt auch für die
      Aufhebung oder Änderung dieser Ziff. 1.2. Unsere Mitarbeiter haben keine
      Vollmacht, von den vorliegenden Bedingungen abzuweichen.
    </h5>
    <h5>
      1.3 Über die Befugnis, abweichende Vereinbarungen zu treffen oder
      Zustimmungen zu erteilen, verfügt nur eine hierzu von uns bevollmächtigte
      Person.
    </h5>
    <h5>
      1.4 Sofern nichts anderes vereinbart, gelten Liefer- und
      Zahlungsbedingungen in der zum Zeitpunkt der Bestellung des Auftraggebers
      gültigen bzw. jedenfalls in der ihm zuletzt in Textform mitgeteilten
      Fassung als Rahmenvereinbarung auch für gleichartige künftige Verträge,
      ohne dass wir in jedem Einzelfall wieder auf sie hinweisen müssten.
    </h5>
  </div>
  <h4 class="content-block">2. Vertragsschluss</h4>
  <h5 class="content-block">
    Unsere Angebote sind freibleibend und unverbindlich, sofern sie nicht
    ausdrücklich als verbindlich gekennzeichnet sind oder eine bestimmte
    Annahmefrist enthalten. Der Vertrag kommt erst mit unserer
    Auftragsbestätigung zustande.
  </h5>
  <h4 class="content-block">3. Preise</h4>
  <div class="content-block">
    <h5>
      3.1 Es gelten die in unserer Auftragsbestätigung genannten Preise. Soweit
      nicht anders vereinbart, verstehen sich die Preise in Euro ab Werk (EXW
      gemäß Incoterms 2010) zuzüglich der gesetzlichen Mehrwertsteuer, bei
      Exportlieferungen Zoll sowie Gebühren und anderer Abgaben.
    </h5>
    <h5>
      3.2 Vorarbeiten, die vom Auftraggeber veranlasst sind, wie Proben, Muster,
      Entwürfe, Skizzen werden zusätzlich berechnet.
    </h5>
    <h5>
      3.3 Überschreitet die Abwicklung eines Auftrages den Zeitraum von vier
      Monaten, so sind wir berechtigt, die in der Auftragsbestätigung genannten
      Preise insoweit anzupassen, als sich die der Kalkulation zugrundeliegenden
      Kosten (Löhne und Gehälter, Material, allgemeine Geschäftskosten) erhöht
      haben. In diesem Fall ist der Auftraggeber berechtigt, vom Vertrag
      zurückzutreten, falls seit Vertragsschluss eine Preissteigerung von mehr
      als 8% pro Jahr zu verzeichnen ist.
    </h5>
    <h5>
      3.4 Stellen sich nach der Auftragsvergabe notwendige Mehrarbeiten heraus,
      die bei Vertragsschluss nicht erkennbar waren, so können wir diese
      zusätzlich berechnen. Übersteigt der Aufpreis 10% des Gesamtpreises, so
      ist der Auftraggeber zum Rücktritt vom Vertrag berechtigt, es sei denn, er
      ist vorher von uns auf die außerplanmäßige Preiserhöhung hingewiesen
      worden und hat ihr nicht schriftlich widersprochen.
    </h5>
    <h5>
      3.5 Von uns nicht verschuldete oder andere, in Abweichung von der ersten
      Druckvorlage erforderliche Abänderungen, insbesondere vom Auftraggeber
      veranlasste Korrekturen, werden nach der dafür aufgewendeten Arbeitszeit
      berechnet. Der Auftraggeber haftet auch für einen infolgedessen
      eintretenden Produktionsmittelstillstand einschließlich
      Maschinenstillstand.
    </h5>
    <h5>
      3.6 Bei Aufträgen mit Lieferung an Dritte gilt der Besteller als
      Auftraggeber, soweit keine anderweitige ausdrückliche Vereinbarung
      getroffen wurde.
    </h5>
  </div>
  <h4 class="content-block">4. Zahlungsbedingungen</h4>
  <div class="content-block">
    <h5>
      4.1 Die Rechnung wird unter dem Tag der Lieferung, Teillieferung oder
      Lieferbereitschaft (Holschuld, Annahmeverzug) ausgestellt. Die Zahlung des
      Rechnungsbetrages ist vorbehaltlich nachfolgender Regelungen innerhalb von
      21 Kalendertagen nach Rechnungsdatum ohne Abzug zu leisten. Ein
      Skontoabzug wird nur nach vorheriger schriftlicher Vereinbarung gewährt.
      Die Zahlung von Versand- (Fracht, Zoll, Porto) und Verpackungskosten ist
      sofort nach Erhalt der Rechnung ohne Abzug fällig.
    </h5>
    <h5>
      4.2 Wechsel und Schecks werden nur nach besonderer schriftlicher
      Vereinbarung und erfüllungshalber ohne Skontogewährung angenommen.
      Diskontspesen und sonstige Kosten trägt der Auftraggeber und sind sofort
      fällig.
    </h5>
    <h5>
      4.3 Bei größeren Aufträgen können gemäß der geleisteten Arbeit
      entsprechende Zwischenrechnungen aufgestellt oder Teilzahlungen gefordert
      werden.
    </h5>
    <h5>
      4.4 Bei Bereitstellung außergewöhnlich großer Papier- und Kartonmengen
      sowie besonderer Materialien sind wir berechtigt, hierfür sofortige
      Zahlung zu verlangen.
    </h5>
    <h5>
      4.5 Leistet der Auftraggeber bei Fälligkeit nicht, so sind die
      ausstehenden Beträge ab dem Tag der Fälligkeit mit 5 % p. a. zu verzinsen;
      die Geltendmachung höherer Zinsen und weiterer Schäden im Falle des
      Verzugs bleibt unberührt.
    </h5>
    <h5>
      4.6 Bei Banküberweisungen und Schecks gilt der Tag, an dem die Gutschrift
      bei uns erfolgt, als Zahlungseingang.
    </h5>
    <h5>
      4.7 Gerät der Auftraggeber mit einer bereits fälligen
      Zahlungsverpflichtung aus dem Vertrag in Zahlungsverzug, so sind wir
      berechtigt, die bei normalem Verlauf erst später zu erfüllende Restschuld
      auch sofort fällig zu stellen.
    </h5>
    <h5>
      4.8 Bei außergewöhnlichen Vorleistungen kann angemessene Vorauszahlung
      verlangt werden.
    </h5>
  </div>
  <h4 class="content-block">5. Zurückbehaltungsrecht, Aufrechnung</h4>
  <div class="content-block">
    <h5>
      5.1 Wird nach Vertragsabschluss erkennbar, dass die Bezahlung der offenen
      Forderungen durch die mangelnde Leistungsfähigkeit des Auftraggebers
      gefährdet wird (z.B., wenn beim Auftraggeber das Insolvenzverfahren oder
      ein gerichtliches oder außergerichtliches Vergleichsverfahren beantragt
      worden ist), so können wir Vorauszahlung oder Sicherheitsleistung
      verlangen, noch nicht ausgelieferte Ware zurückhalten sowie die
      Weiterarbeit einstellen. Diese Rechte stehen uns auch zu, wenn der
      Auftraggeber sich mit der Bezahlung von ordnungsgemäßen Lieferungen in
      Verzug befindet, die auf demselben rechtlichen Verhältnis beruhen.
    </h5>
    <h5>
      5.2 Uns steht an den vom Auftraggeber angelieferten Filmen, Manuskripten,
      Rohmaterialien, Daten und sonstigen Gegenständen ein kaufmännisches
      Zurückbehaltungsrecht nach § 369 HGB bis zur vollständigen Erfüllung
      unserer fälligen Forderung aus der Geschäftsverbindung zu.
    </h5>
    <h5>
      5.3 Wir sind berechtigt, gegen Forderungen des Auftraggebers mit
      sämtlichen fälligen Forderungen aufzurechnen, die andere
      Bertelsmann-Konzernfirmen (insbesondere Arvato Services, Arvato Digital
      Services, Direct Group) gegen den Auftraggeber haben.
    </h5>
    <h5>
      5.4 Der Auftraggeber darf nur mit Gegenansprüchen des Auftraggebers
      aufrechnen oder Zahlungen wegen solcher Gegenansprüche zurückbehalten,
      soweit die Gegenansprüche unbestritten oder rechtskräftig festgestellt
      sind. Dies gilt nicht für etwaige auf Fertigstellungs- oder
      Mängelbeseitigungskosten gerichtete Ansprüche des Auftraggebers. Ihm steht
      darüber hinaus die Ausübung von Leistungsverweigerungs- und
      Zurückbehaltungsrechten nicht zu.
    </h5>
  </div>
  <h4 class="content-block">6. Lieferzeit, Lieferung</h4>
  <div class="content-block">
    <h5>
      6.1 Lieferungen erfolgen ab Werk (EXW gemäß Incoterms 2010). Unsere
      Liefertermine sind regelmäßig Zielvorgaben und keine Fixtermine, es sei
      denn, dies wird ausdrücklich schriftlich vereinbart.
    </h5>
    <h5>
      6.2 Für die Dauer der Prüfung der Proofs, Plots, Fertigmuster, Andrucke
      usw. durch den Auftraggeber ist die vereinbarte Lieferfrist jeweils
      unterbrochen, und zwar vom Tag der Absendung an den Auftraggeber bis zum
      Tag des Eintreffens seiner Stellungnahme oder bis zur Freigabe.
    </h5>
    <h5>
      6.3 Verlangt der Auftraggeber nach der Auftragsbestätigung Änderungen des
      Auftrages, welche die Anfertigungsdauer beeinflussen, beginnt eine neue
      Lieferzeit, und zwar erst mit der Bestätigung der Änderungen.
    </h5>
    <h5>
      6.4 Wir sind zu Teillieferungen (Vorablieferungen) berechtigt, soweit die
      Teillieferung für den Auftraggeber verwendbar ist, die Lieferung der
      restlichen Ware sichergestellt ist und dem Auftraggeber hierdurch kein
      wesentlicher Mehraufwand oder zusätzliche Kosten entstehen (es sei denn,
      der Auftraggeber erklärt sich zur Übernahme dieser Kosten bereit).
    </h5>
    <h5>
      6.5 Bei Abrufaufträgen ist der Auftraggeber zur Abnahme der gesamten dem
      Abrufauftrag zugrunde liegenden Menge verpflichtet. Die Abrufpflicht des
      Auftraggebers stellt eine Hauptpflicht dar. Soweit nicht etwas anderes
      vereinbart ist, gilt bei Abrufaufträgen eine Abnahmefrist von 12 Monaten,
      gerechnet ab dem Tag der Auftragsbestätigung. Ist die vollständige Abnahme
      bis zu diesem Zeitpunkt nicht erfolgt, ist der Auftragnehmer berechtigt,
      dem Auftraggeber eine Frist von zwei Wochen zur Abnahme der noch
      abzunehmenden Auftragsmenge zu setzen. Nach fruchtlosem Verstreichen diese
      Frist hat der Auftragnehmer die Wahl, entweder Vorleistung des Kaufpreises
      zu verlangen und die Restmenge vollständig zu liefern oder nach §323 BGB
      vom Vertrag zurückzutreten. Weitere Rechte des Auftragnehmers, wie das
      Recht Schadenersatz zu verlangen, bleiben unberührt.
    </h5>
  </div>
  <h4 class="content-block">7. Lieferverzug, Lieferunmöglichkeit</h4>
  <div class="content-block">
    <h5>
      7.1 Verzögert der Auftragnehmer die Leistung, kann der Auftraggeber die
      Rechte aus § 323 BGB nur ausüben, wenn die Verzögerung vom Auftragnehmer
      zu vertreten ist. Eine Änderung der Beweislast ist mit dieser Ziff. 7.1
      nicht verbunden.
    </h5>
    <h5>
      7.2 Wir haften nicht für Unmöglichkeit der Lieferung oder für
      Lieferungsverzögerungen aufgrund höherer Gewalt oder sonstiger zum
      Zeitpunkt des Vertragsschlusses nicht vorhersehbarer und nicht durch uns
      zu vertretender Ereignisse (z.B. Betriebsstörungen, Arbeitskämpfe,
      Unruhen, behördliche Maßnahmen) auf unserer Seiten oder unserer
      Vorlieferanten. Wird durch solche Ereignisse die Lieferung oder Leistung
      wesentlich erschwert oder unmöglich und dauert die Behinderung länger als
      vier Wochen an, sind wir zum Rücktritt vom Vertrag berechtigt. Bei
      Hindernissen von vorübergehender Dauer verlängert sich die Lieferfrist um
      den Zeitraum der Behinderung zuzüglich einer angemessenen Anlauffrist.
      Eine Haftung des Auftragnehmers ist in diesen Fällen ausgeschlossen.
    </h5>
  </div>
  <h4 class="content-block">8. Gefahrtragung, Versand</h4>
  <div class="content-block">
    <h5>
      8.1 Der Versand erfolgt auf Rechnung und Gefahr des Auftraggebers. Die
      Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung geht
      auf den Auftraggeber über, sobald die Sendung an die den Transport
      ausführende Person oder Anstalt übergeben worden ist oder zwecks
      Versendung unser Werk verlassen hat. Ist die Ware versandbereit und
      verzögert sich die Versendung oder die Abnahme aus Gründen, die wir nicht
      zu vertreten haben, so geht die Gefahr des zufälligen Untergangs und der
      zufälligen Verschlechterung mit dem Zugang der Anzeige der
      Versandbereitschaft auf den Auftraggeber über.
    </h5>
    <h5>
      8.2 Versandweg und -mittel sind, wenn nicht anders vereinbart, unserer
      Wahl überlassen.
    </h5>
    <h5>
      8.3 Die Lieferung wird nur auf Wunsch und Kosten des Auftraggebers
      transportversichert.
    </h5>
  </div>
  <h4 class="content-block">9. Übermittlung personenbezogener Daten</h4>
  <h5 class="content-block">
    Personenbezogene Daten müssen gemäß Nr. 4 der Anlage zu § 9 BDSG während der
    elektronischen Übermittlung mit einem dem Stand der Technik entsprechendem
    Verschlüsselungsverfahren vor unbefugten Zugriff geschützt werden.
  </h5>
  <h4 class="content-block">10. Annahmeverzug</h4>
  <div class="content-block">
    <h5>
      10.1 Nimmt der Auftraggeber die Ware nicht zum vereinbarten Termin oder
      mangels Terminvereinbarung nicht innerhalb einer angemessenen Frist nach
      Fertigstellung bzw. bei avisiertem Versand nicht prompt ab, sind wir
      berechtigt, die Ware für Rechnung und auf Gefahr des Auftraggebers
      einzulagern.
    </h5>
    <h5>
      10.2 Wenn der Auftraggeber nach Ablauf einer ihm gesetzten angemessenen
      Nachfrist mit Ablehnungsandrohung die Annahme verweigert oder vorher
      ernsthaft und endgültig erklärt, nicht abnehmen zu wollen, können wir
      (unbeschadet möglicher sonstiger Rechte) vom Vertrag zurücktreten oder
      Schadensersatz wegen Nichterfüllung verlangen.
    </h5>
  </div>
  <h4 class="content-block">11. Beanstandungen</h4>
  <div class="content-block">
    <h5>
      11.1 Der Auftraggeber hat die Vertragsgemäßheit der gelieferten Ware sowie
      der zur Korrektur übersandten Vor- oder Zwischenerzeugnisse in jedem Fall
      unverzüglich zu prüfen. Seine Pflicht zur Untersuchung der gelieferten
      Waren besteht auch, wenn Ausfallmuster übersandt worden sind. Die Gefahr
      etwaiger Fehler geht mit der Druckreiferklärung auf den Auftraggeber über,
      soweit es sich nicht um Fehler handelt, die erst in dem sich an die
      Druckreiferklärung anschließenden Fertigungsvorgang entstanden sind oder
      erkannt werden konnten. Das gleiche gilt für alle sonstigen
      Freigabeerklärungen des Auftraggebers zur weiteren Herstellung.
    </h5>
    <h5>
      11.2 Offensichtliche Mängel sind unverzüglich innerhalb einer
      Ausschlussfrist von einer Woche nach Erhalt der Ware schriftlich zu rügen,
      versteckte Mängel innerhalb von einer Woche nach der Entdeckung des
      Mangels oder dem Zeitpunkt, in dem der Mangel für den Auftraggeber bei
      normaler Verwendung der Ware ohne nähere Untersuchung erkennbar war,
      andernfalls ist die Geltendmachung von Gewährleistungsansprüchen
      ausgeschlossen. Der Käufer hat uns die zur geschuldeten Nacherfüllung
      erforderliche Zeit und Gelegenheit zu geben, insbesondere die beanstandete
      Ware zu Prüfungszwecken zu übergeben. Die zum Zweck der Prüfung und
      Nacherfüllung erforderlichen Aufwendungen, insbesondere Transport-, Wege-,
      Arbeits- und Materialkosten (nicht: Ausbau- und Einbaukosten), tragen wir,
      wenn tatsächlich ein Mangel vorliegt. Andernfalls können wir vom
      Auftraggeber die aus dem unberechtigten Mangelbeseitigungsverlangen
      entstandenen Kosten (insbesondere Prüf- und Transportkosten) ersetzt
      verlangen, es sei denn, die fehlende Mangelhaftigkeit war für den
      Auftraggeber nicht erkennbar.
    </h5>
    <h5>
      11.3 Mängel eines Teils der gelieferten Waren berechtigen nicht zur
      Beanstandung der gesamten Lieferung, es sei denn, dass die Teillieferung
      für den Auftraggeber ohne Interesse ist.
    </h5>
    <h5>
      11.4 Bei berechtigten Beanstandungen haben wir zunächst das Recht zur
      Nachbesserung und/oder Ersatzlieferung. Misslingt die Nachbesserung
      (Ersatzlieferung) oder wird sie nicht in angemessener Frist erbracht oder
      wird sie verweigert, kann der Auftraggeber Herabsetzung der Vergütung oder
      Rückgängigmachung des Vertrages verlangen. Eine weitergehende
      Gewährleistung und Schadenshaftung, insbesondere für Mangelfolgeschäden,
      ist ausgeschlossen, es sei denn, dass wir oder unsere Erfüllungsgehilfen
      vorsätzlich oder grob fahrlässig gehandelt oder Eigenschaften ausdrücklich
      zugesichert haben.
    </h5>
    <h5>
      11.5 Bei Reproduktionen in einem Druckverfahren können geringfügige
      Abweichungen vom Original oder Vorlage nicht beanstandet werden. Das
      gleiche gilt für den Vergleich zwischen Andrucken, Proofs und
      Auflagendruck. Darüber hinaus ist die Haftung für Mängel, die den Wert
      oder die Gebrauchstauglichkeit nicht oder nur unwesentlich
      beeinträchtigen, ausgeschlossen.
    </h5>
    <h5>
      11.6 Für erhebliche Abweichungen von Normen und in der Beschaffenheit des
      von uns beschafften Papiers, Kartons etc. und sonstigen von uns
      beschafften Materials haften wir nur bis zur Höhe der eigenen Ansprüche
      gegen die Papier- und Pappenlieferanten sowie sonstigen Zulieferanten. In
      einem solchen Fall sind wir von unserer Haftung befreit, wenn wir unsere
      Ansprüche gegen die Zulieferanten an den Auftraggeber abtreten. Wir haften
      wie ein Bürge, soweit Ansprüche gegen den Papier- und Pappenlieferanten
      etc. durch unser Verschulden nicht bestehen oder solche Ansprüche nicht
      durchsetzbar sind.
    </h5>
    <h5>
      11.7 Für Lichtechtheit, Veränderlichkeit und Abweichungen der Farben und
      Lacke sowie für die Beschaffenheit von Gummierung, Lackierung,
      Imprägnierung usw. haften wir nur insoweit, als Mängel der Materialien vor
      deren Verwendung bei sachgemäßer Prüfung objektiv erkennbar waren. Für
      materialbedingte Abweichungen haften wir jedoch nicht, wenn uns der
      Auftraggeber diese Materialien zur Verwendung bestimmt hat.
    </h5>
    <h5>
      11.8 Soweit bestimmte Sonderarbeiten wie z. B. Prägen, Stanzen,
      Perforieren, Lackieren, Laminieren usw. durch eine dritte Firma ausgeführt
      werden, gelten die Bedingungen in Ziff. 11.6 entsprechend.
    </h5>
    <h5>
      11.9 Mehr- oder Minderlieferungen bis zu 5% der bestellten Auflage können
      nicht beanstandet werden. Berechnet wird die gelieferte Menge. Der
      Prozentsatz erhöht sich bei besonders schwierigen Drucken sowie bei
      Auflagen bis zu 2.000 Exemplaren auf 10%. Zusätzlich erhöhen sich die
      Prozentsätze der Mehr- oder Minderlieferung, wenn das Papier von uns
      aufgrund der Lieferungsbedingungen der Fachverbände der Papiererzeugung
      beschafft wurde, um deren Toleranzsätze.
    </h5>
    <h5>
      11.10 Zulieferungen (auch Datenträger, übertragene Daten) durch den
      Auftraggeber oder durch einen von ihm eingeschalteten Dritten unterliegen
      keiner Prüfungspflicht von unserer Seite. Dies gilt nicht für
      offensichtlich nicht verarbeitungsfähige oder nicht lesbare Daten. Bei den
      Datenübertragungen hat der Auftraggeber vor Übersendung jeweils dem
      neuesten technischen Stand entsprechende Schutzprogramme für Computerviren
      einzusetzen. Die Datensicherung obliegt allein dem Auftraggeber. Wir sind
      berechtigt, eine Kopie anzufertigen.
    </h5>
  </div>
  <h4 class="content-block">12. Handelsbrauch</h4>
  <h5 class="content-block">
    Im kaufmännischen Verkehr gelten die Handelsbräuche der Druckindustrie (z.B.
    keine Herausgabepflicht von Zwischenerzeugnissen wie Daten, Lithos, oder
    Druckplatten, die zur Herstellung des geschuldeten Endproduktes erstellt
    werden), sofern kein abweichender Auftrag erteilt wurde.
  </h5>
  <h4 class="content-block">13. Eigentumsvorbehalt</h4>
  <div class="content-block">
    <h5>
      13.1 Die gelieferte Ware bleibt unser Eigentum bis zur vollständigen
      Bezahlung aller unserer Forderungen aus der Geschäftsöverbindung sowie bis
      zur Einlösung der dafür gegebenen Schecks oder Wechsel. Bei laufender
      Rechnung gilt das vorbehaltene Eigentum als Sicherung für unsere
      Saldoforderung. Diese Ware darf vor vollständiger Bezahlung weder an
      Dritte verpfändet, noch zur Sicherheit übereignet werden. Der Auftraggeber
      hat den Auftragnehmer schriftlich zu benachrichtigen, wenn und soweit
      Zugriffe Dritter auf die dem Auftraggeber gehörende Ware erfolgen.
    </h5>
    <h5>
      13.2 Im Fall des Scheck-Wechsel-Austausches geht das Eigentum auf den
      Auftraggeber erst über, wenn für uns kein Rückgriff aus dem Wechsel mehr
      zu befürchten ist.
    </h5>
    <h5>
      13.3 Zur Weiterveräußerung ist der Auftraggeber nur im ordentlichen
      Geschäftsgang berechtigt. Die Forderungen des Auftraggebers aus einer
      Weiterveräußerung der von uns gelieferten Waren, aus dem Abdruck von
      Anzeigen und aus dem Vertrieb von Beilagen in den gelieferten Waren werden
      bereits jetzt zur Sicherung unserer sämtlichen Forderungen aus der
      Geschäftsverbindung an uns abgetreten. Nimmt der Auftraggeber Forderungen
      aus einer Weiterveräußerung der Vorbehaltsware, aus dem Abdruck von
      Anzeigen und aus dem Vertrieb von Beilagen in ein mit einem Dritten,
      insbesondere mit einem Kunden, bestehendes Kontokorrentverhältnis auf, so
      gilt der jeweilige abgetretene Saldo bis zur Höhe unserer Forderungen als
      abgetreten.
    </h5>
    <h5>
      13.4 An allen vom Auftraggeber übergebenen Rohmaterialien jeglicher Art
      wird mit der Übergabe zur Sicherung sämtlicher gegenwärtiger und
      zukünftiger Forderungen des Lieferanten aus Warenlieferungen ein
      Pfandrecht bestellt. Bei Be- oder Verarbeitung vom Auftragnehmer
      gelieferter und in dessen Eigentum stehender Waren sind wir als Hersteller
      gemäß § 950 BGB anzustehen und behält in jedem Zeitpunkt der Verarbeitung
      Eigentum an den Erzeugnissen. Sind Dritte an der Be- oder Verarbeitung
      beteiligt, sind wir auf einen Miteigentumsanteil in Höhe des
      Rechnungswertes der Vorbehaltsware beschränkt. Das so erworbene Eigentum
      gilt als Vorbehaltseigentum.
    </h5>
    <h5>
      13.5 Übersteigt der realisierbare Wert der Sicherheiten unsere
      Gesamtforderung aus der Geschäftsverbindung um mehr als 10%, so sind wir
      auf Verlangen des Auftraggebers insoweit zur Freigabe von Sicherheiten
      nach unserer Wahl verpflichtet.
    </h5>
  </div>
  <h4 class="content-block">14. Haftung</h4>
  <div class="content-block">
    <h5>
      14.1 Schadenersatzansprüche des Auftraggebers, gleich aus welchem
      Rechtsgrund, sind ausgeschlossen.
    </h5>
    <h5>14.2 Dieser Haftungsausschluss gilt nicht</h5>
    <ul>
      <li>bei vorsätzlich oder grob-fahrlässig verursachten Schäden,</li>
      <li>
        bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten, auch
        durch unsere gesetzlichen Vertreter oder unsere Erfüllungsgehilfen; eine
        Haftung ist insoweit auf den nach Art des Produkts vorhersehbaren,
        vertragstypischen Schaden begrenzt,
      </li>
      <li>
        im Falle schuldhafter Verletzung des Lebens, des Körpers oder der
        Gesundheit des Auftraggebers, oder
      </li>
      <li>
        bei arglistig verschwiegenen Mängeln oder übernommener Garantie für die
        Beschaffenheit der Ware,
      </li>
      <li>bei Ansprüchen aus dem Produkthaftungsgesetz.</li>
    </ul>
    <h5>
      Wesentliche Vertragspflichten sind solche Pflichten, die die ordnungsgemäß
      Erfüllung des Vertrages erst ermöglichen und auf die der Gläubiger
      vertraut hat und auch vertrauen durfte und deren Nichterfüllung die
      Erreichung des Vertragszwecks gefährdet.
    </h5>
    <h5>
      14.3 Soweit wir gemäß 14.2 dem Grunde nach haften, ist diese Haftung,
      außer im Fall von Vorsatz oder grober Fahrlässigkeit oder bei schuldhafter
      Verletzung des Lebens, des Körpers oder der Gesundheit, auf die Höhe des
      Rechnungsbetrages für den entsprechenden Auftrag begrenzt, mittelbare
      Schäden und Folgeschäden, die Folge von Mängeln des Liefergegenstands
      sind, sind außerdem nur ersatzfähig, soweit solche Schäden bei
      bestimmungsgemäßer Verwendung des Liefergegenstands typischerweise zu
      erwarten sind.
    </h5>
    <h5>
      14.4 Für Schäden beim Transport ist die Haftung auf den von dem
      beauftragten Spediteur geleisteten Ersatz beschränkt.
    </h5>
    <h5>
      14.5 Ansprüche des Auftraggebers auf Gewährleistung und Schadensersatz
      verjähren mit Ausnahme der unter Ziffer 14.2 genannten
      Schadensersatzansprüche in einem Jahr beginnend mit der Lieferung oder
      Bereitstellung zur Abholung der Ware.
    </h5>
    <h5>
      14.6 Ein freies Kündigungsrecht des Käufers (insbesondere gem. §§ 651, 649
      BGB) wird ausgeschlossen.
    </h5>
  </div>
  <h4 class="content-block">15. Materialbeistellung</h4>
  <div class="content-block">
    <h5>
      15.1 Vom Auftraggeber beschafftes Material (u.a. Papier und
      Halbfabrikate), gleichviel welcher Art, ist uns in einwandfreiem Zustand
      frei Haus zu liefern. Der Eingang wird bestätigt ohne Übernahme der Gewähr
      für die Richtigkeit der als geliefert bezeichneten Menge. Bei größeren
      Posten sind die mit der Zählung oder gewichtsmäßigen Prüfung verbundenen
      Kosten sowie die Lagerspesen zu erstatten.
    </h5>
    <h5>
      15.2 Der Auftraggeber trägt das Risiko der Verarbeitbarkeit des von ihm
      bereitgestellten Materials. Wir sind berechtigt, Material abzulehnen,
      soweit uns dieses von vornherein für die Ausführung des Auftrages als
      ungeeignet erscheint.
    </h5>
    <h5>
      15.3 Bei Zurverfügungstellung des Papiers und Kartons durch den
      Auftraggeber gehen die Abfälle durch unvermeidlichen Makulaturanfall bei
      Druckeinrichtungen und Fortdrucke durch Beschnitt, Ausstanzen und
      dergleichen in unser Eigentum über. Verpackungsmaterial hat der
      Auftraggeber zurückzunehmen.
    </h5>
    <h5>
      15.4 Bei Beschädigung oder Verlust des vom Auftraggeber beigestellten
      Materials haften wir nur, soweit wir oder unsere Erfüllungsgehilfen
      vorsätzlich oder grob fahrlässig gehandelt haben.
    </h5>
    <h5>
      15.5 Der Wiederverwendung dienendes Material sowie Halb- und
      Fertigerzeugnisse, einschließlich etwaiger dem Auftraggeber gehörender
      Restmaterialien, werden nur nach vorheriger Vereinbarung gegen Vergütung
      über den Auslieferungstermin hinaus verwahrt. Wird keine Vereinbarung
      getroffen und sind die Sachen nicht binnen vier Wochen nach Erledigung des
      Auftrages vom Auftraggeber abgefordert worden, sind wir berechtigt, diese
      auf Rechnung und Gefahr des Auftraggebers bei einem Spediteur einzulagern.
      Für die Versicherung der Sachen hat der Auftraggeber zu sorgen.
    </h5>
  </div>
  <h4 class="content-block">16. Urheberrecht</h4>
  <div class="content-block">
    <h5>
      16.1 Für die Prüfung des Rechts der Vervielfältigung aller Druckvorlagen
      ist der Auftraggeber allein verantwortlich. Der Auftraggeber haftet
      allein, wenn durch die Ausführung seines Auftrages Rechte Dritter,
      insbesondere Urheber-, Kennzeichen oder Persönlichkeitsrechte, verletzt
      werden. Der Auftraggeber hat uns von allen Ansprüchen Dritter wegen einer
      solchen Rechtsverletzung, einschließlich der Kosten für die
      Rechtsverfolgung- und -verteidigung, auf erstes Anfordern vollumfänglich
      freizustellen.
    </h5>
    <h5>
      16.2 Wir sind berechtigt Aufträge zu verweigern, sofern diese bzw. die zu
      druckenden Inhalte unserer Meinung nach gegen gesetzliche Bestimmungen
      verstoßen. Der Auftraggeber kann daraus keine Rechte gegen uns ableiten.
      Wir sind zu einer Prüfung der Unterlagen auf derartige Rechtsverletzungen
      nicht verpflichtet.
    </h5>
    <h5>
      16.3 Das Urheberrecht und das Recht der Vervielfältigung in jeglichen
      Verfahren und zu jeglichem Verwendungszweck an eigenen Skizzen, Entwürfen,
      Originalen, Daten, Filmen und dergleichen verbleiben, vorbehaltlich
      ausdrücklicher anderweitiger Regelung, bei uns.
    </h5>
  </div>
  <h4 class="content-block">17. Korrekturen, Korrekturabzüge</h4>
  <div class="content-block">
    <h5>
      17.1 Korrekturabzüge, Plots, Proofs und Andrucke sind vom Auftraggeber auf
      Satz- und sonstige Fehler zu überprüfen und uns druckreif erklärt
      zurückzugeben. Wir haften nicht für vom Auftraggeber übersehene Fehler.
    </h5>
    <h5>
      17.2 Für Verzögerungen infolge verspäteter Rücksendung haften wir nicht.
    </h5>
    <h5>
      17.3 Bei kleineren Druckaufträgen (z.B. Firmeneindrucke) sowie bei
      gelieferten Druckvorlagen sind wir nicht verpflichtet, dem Auftraggeber
      einen Korrekturabzug zu übersenden. Wird die Übersendung eines
      Korrekturabzuges nicht verlangt, so beschränkt sich die Haftung für
      Satzfehler auf Vorsatz und grobes Verschulden.
    </h5>
    <h5>
      17.4 Für die Rechtschreibung ist der Duden, letzte Ausgabe, maßgebend.
    </h5>
  </div>
  <h4 class="content-block">18. Archivierung</h4>
  <h5 class="content-block">
    Wir gehen davon aus, dass wir für die Produktion eine Kopie Ihrer Daten
    erhalten und sie die Originale oder eine Kopie dieser Daten
    selbstverantwortlich speichern. Für den Auftraggeber angefertigte oder von
    diesem zur Verfügung gestellte Produkte, insbesondere Daten und Datenträger,
    werden von uns nur nach ausdrücklicher schriftlicher Vereinbarung und gegen
    besondere Vergütung über den Zeitpunkt der Übergabe des Endproduktes an den
    Auftraggeber oder seine Erfüllungsgehilfen hinaus archiviert. Soweit nichts
    anderes vereinbart wurde, werden sämtliche Daten einschließlich aller
    erhaltenen Datenträger drei Monate nach Produktionsende gelöscht bzw.
    vernichtet. Sollen die vorbezeichneten Gegenstände versichert werden, so hat
    dies bei fehlender Vereinbarung der Auftraggeber selbst zu besorgen.
  </h5>
  <h4 class="content-block">19. Periodische Arbeiten</h4>
  <h5 class="content-block">
    Verträge über regelmäßig wiederkehrende Druckarbeiten können ordentlich nur
    mit einer Frist von drei Monaten zum Schluss eines Monats gekündigt werden,
    soweit nicht etwas Abweichendes vereinbart wurde. Das Recht zur fristlosen
    Kündigung aus wichtigem Grund bleibt daneben unberührt.
  </h5>
  <h4 class="content-block">
    20. Erfüllungsort, Gerichtsstand, Anwendbares Recht
  </h4>
  <div class="content-block">
    <h5>20.1 Erfüllungsort ist Pößneck.</h5>
    <h5>
      20.2 Ist der Käufer Kaufmann i.S.d. HGB, juristische Person des
      öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, ist
      ausschließlicher - auch internationaler Gerichtsstand für alle sich aus
      dem Vertragsverhältnis unmittelbar oder mittelbar ergebenden
      Streitigkeiten, unser Geschäftssitz in Pößneck bzw. Jena. Entsprechendes
      gilt, wenn der Käufer Unternehmer i.S.v. § 14 BGB ist. Wir sind jedoch in
      allen Fällen auch berechtigt, Klage am Erfüllungsort der
      Lieferverpflichtung gemäß diesen Liefer- und Zahlungsbedingungen bzw.
      einer vorrangigen Individualabrede oder am allgemeinen Gerichtsstand des
      Auftraggebers zu erheben. Vorrangige gesetzliche Vorschriften,
      insbesondere zu ausschließlichen Zuständigkeiten, bleiben unberührt.
    </h5>
    <h5>
      20.3 Die Beziehungen zwischen den Parteien unterliegen ausschließlich dem
      Recht der Bundesrepublik Deutschland unter Ausschluss des Kollisionsrechts
      sowie des UN Kaufrechts (CISG).
    </h5>
  </div>
  <h4 class="content-block">21. Bertelsmann - Code of Conduct</h4>
  <h5 class="content-block">
    GGP media weist ausdrücklich auf den im Bertelsmann geltenden Code of
    Conduct hin, der unter
    <a href="https://www.bertelsmann.de" target="_blank">www.bertelsmann.de</a>
    eingesehen werden kann. GGP media erwartet von seinen Geschäftspartnern,
    dass diese die Einhaltung der darin enthaltenen Regel und Prinzipien
    unterstützt und sich insbesondere zur Unterstützung und Umsetzung der im
    Rahmen der Global Compact Initiative der Vereinten Nationen aufgestellten
    Prinzipien zu den Menschenrechten, den Arbeitsbeziehungen und zur Umwelt
    sowie Korruption bekennt (<a
      href="https://www.unglobalcompact.org"
      target="_blank"
      >www.unglobalcompact.org</a
    >).
  </h5>
</ng-template>
