import { ProduktFormData } from "./produktFormData";

export class UpdateProduktRequest {
  id: string;
  name: string;
  produktType: string;
  circulationLimit: boolean;

  constructor(
    id: string,
    name: string,
    produktType: string,
    circulationLimit: boolean
  ) {
    this.id = id;
    this.name = name;
    this.produktType = produktType;
    this.circulationLimit = circulationLimit;
  }
}

export class UpdateProduktRequestUS {
  id: string;
  name: string;
  produktType: string;
  circulationLimit: boolean;
  width: number;
  height: number;
  edition: string;
  reprint: boolean;
  twoTitles: boolean;

  constructor(
    id: string,
    name: string,
    produktType: string,
    circulationLimit: boolean,
    width: number,
    height: number,
    edition: string,
    reprint: boolean,
    twoTitles: boolean
  ) {
    this.id = id;
    this.name = name;
    this.produktType = produktType;
    this.circulationLimit = circulationLimit;
    this.width = width;
    this.height = height;
    this.edition = edition;
    this.reprint = reprint;
    this.twoTitles = twoTitles;
  }
}
