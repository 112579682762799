import { Injectable } from "@angular/core";
import { P3AuthService } from "./auth.service";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  constructor(private authService: P3AuthService) {}

  getCurrentLocale(): Observable<string | null> {
    return this.authService.getUser().pipe(
      map((user) => {
        if (user?.identity?.locale) {
          return user.identity.locale;
        } else {
          return this.getCurrentLocaleWithoutUser();
        }
      }),
      catchError(() => {
        return of(this.getCurrentLocaleWithoutUser());
      })
    );
  }

  getCurrentLocaleWithoutUser(): string | null {
    if (environment.isUsa) {
      return "us-US";
    } else if (localStorage.getItem("locale") != null) {
      return localStorage.getItem("locale");
    } else {
      return navigator.language.startsWith("de") ? "de-DE" : "en-EN";
    }
  }
}
