import { Component, Input, OnInit } from "@angular/core";
import {
  CalculationResult,
  Mehrpreis,
  MehrpreisGroup,
} from "../../../../../../model/calculation/calculationResult";
import { NumberConverterService } from "../../../../../../services/number-converter.service";

@Component({
  selector: "app-mehrpreis",
  templateUrl: "./mehrpreis.component.html",
  styleUrls: ["./mehrpreis.component.scss"],
})
export class MehrpreisComponent implements OnInit {
  @Input()
  group: MehrpreisGroup;

  @Input()
  mehrpreisGroupA1: MehrpreisGroup[];

  @Input()
  mehrpreisGroupA2: MehrpreisGroup[];

  @Input()
  mehrpreisGroupA3: MehrpreisGroup[];

  mehrpreisListA1: Mehrpreis[];
  mehrpreisListA2: Mehrpreis[];
  mehrpreisListA3: Mehrpreis[];

  @Input()
  calculationResponse: CalculationResult[];

  @Input()
  index: number;
  constructor(public converter: NumberConverterService) {}

  ngOnInit(): void {
    console.log(this.index);
    if (this.mehrpreisGroupA1[this.index]) {
      this.mehrpreisListA1 = this.mehrpreisGroupA1[this.index].mehrpreisList;
    }
    if (this.mehrpreisGroupA2[this.index]) {
      this.mehrpreisListA2 = this.mehrpreisGroupA2[this.index].mehrpreisList;
    }
    if (this.mehrpreisGroupA3[this.index]) {
      this.mehrpreisListA3 = this.mehrpreisGroupA3[this.index].mehrpreisList;
    }
  }
}
