import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import {
  AppInfoService,
  P3AuthService,
  ScreenService,
} from "./shared/services";

import * as deMessages from "devextreme/localization/messages/de.json";
import * as enMessages from "devextreme/localization/messages/en.json";
import { formatMessage, loadMessages, locale } from "devextreme/localization";

import { Location } from "@angular/common";
import Auth from "@aws-amplify/auth";
import notify from "devextreme/ui/notify";
import { User } from "./shared/model/user/user";

import * as localizationOrderGrid from "../assets/localization_order_grid.json";
import * as localizationCalculations from "../assets/localization_calculations.json";
import * as localizationOrderDetails from "../assets/localization_order_details.json";
import * as localizationUpload from "../assets/localization_upload.json";
import * as localizationProductTypes from "../assets/localization_product_types.json";
import * as localizationNewsFeed from "../assets/localization_news_feed.json";
import * as localizationOther from "../assets/localization_other.json";
import * as localizationUserProfile from "../assets/localization_user_profile.json";
import * as localizationUserManagement from "../assets/localization_user_management.json";
import * as localizationCalculator from "../assets/localization_calculator.json";
import * as localizationMenu from "../assets/localization_menu.json";
import * as localizationHome from "../assets/localization_home.json";
import * as localizationUserSuggestion from "../assets/localization_user_suggestion.json";
import * as localizationOrganizationManagement from "../assets/localization_organization_management.json";
import * as localizationProduktManagement from "../assets/localization_produkt_management.json";
import * as localizationPaperCostsManagement from "../assets/localization_papercosts_management.json";
import * as localizationOptionManagement from "../assets/localization_option_management.json";
import * as localizationPaperManagement from "../assets/localization_paper_management.json";
import * as localizationPrintingTemplate from "../assets/localization_printing_template.json";
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "./shared/services/user.service";
import { UpdateInternalUserRequest } from "./shared/model/user/userRequests";
import { AwsRumService } from "./rumMonitorConfiguration";
import { IdleService } from "./shared/services/idle.service";

const externalFileUploadPathRegex = new RegExp(
  "\\/external-file-upload\\/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}"
);

const legalInformationPaths = [
  "/impressum",
  "/datenschutz",
  "/agb",
  "/nutzungsbedingungen",
];

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding("class") get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(" ");
  }

  routerEventsSubscription: Subscription;

  isLoggedIn: boolean;

  user: User | null;

  formatMessage = formatMessage;

  constructor(
    private authService: P3AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    public router: Router,
    private location: Location,
    private userService: UserService,
    private idleService: IdleService
  ) {
    loadMessages(localizationOrderGrid);
    loadMessages(localizationCalculations);
    loadMessages(localizationOrderDetails);
    loadMessages(localizationUpload);
    loadMessages(localizationOther);
    loadMessages(localizationUserProfile);
    loadMessages(localizationUserManagement);
    loadMessages(localizationCalculator);
    loadMessages(localizationMenu);
    loadMessages(localizationHome);
    loadMessages(localizationUserSuggestion);
    loadMessages(localizationOrganizationManagement);
    loadMessages(localizationProductTypes);
    loadMessages(localizationNewsFeed);
    loadMessages(localizationProduktManagement);
    loadMessages(localizationOptionManagement);
    loadMessages(localizationPaperManagement);
    loadMessages(localizationPaperCostsManagement);
    loadMessages(localizationPrintingTemplate);
    loadMessages(deMessages);
    loadMessages(enMessages);
  }

  ngOnInit() {
    this.idleService.checkLogout();

    this.loadAuthentication()
      .then(() => {
        if (this.isLoggedIn) {
          this.setLocalHostAndDevEnvironmentAttributes();
          this.loadOwnUserAndLocale();
        } else {
          this.loadLocale();
        }
      })
      .catch(() => {});

    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadAuthentication();
      }
    });
  }

  loadAuthentication() {
    return Auth.currentAuthenticatedUser()
      .then(() => {
        this.isLoggedIn = true;
      })
      .catch(() => {
        if (!this.isPublicPath()) {
          Auth.federatedSignIn();
        } else {
          this.isLoggedIn = false;
        }
      });
  }

  private isPublicPath() {
    return (
      externalFileUploadPathRegex.test(this.location.path()) ||
      legalInformationPaths.includes(this.location.path())
    );
  }

  loadOwnUserAndLocale() {
    this.authService.getUser().subscribe(
      (user) => {
        if (user) this.user = user;
        this.loadLocale();
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  loadLocale() {
    let userLocale = this.user?.identity.locale;
    let cookieLocale = localStorage.getItem("locale");

    if (!!userLocale) {
      locale(userLocale);
    } else if (!!cookieLocale) {
      locale(cookieLocale);
    } else {
      locale(navigator.language);
    }
  }

  ngOnDestroy() {
    this.routerEventsSubscription.unsubscribe();
  }

  setLocalHostAndDevEnvironmentAttributes() {
    let user;
    if (environment.isLocalHostOrDev) {
      this.userService.findOwnUser().subscribe((data) => {
        console.log("OwnUser");
        console.log(data);
        user = data;
        if (user.role == "ADMIN" || user.role == "ADMIN_US") {
          user.role = environment.isUsa ? "ADMIN_US" : "ADMIN";
          user.identity.locale = environment.isUsa
            ? "us-US"
            : user.identity.locale != "us-US"
            ? user.identity.locale
            : "de-DE";
          this.userService
            .updateInternalUser(
              new UpdateInternalUserRequest(
                user.id,
                user.role,
                user.identity.locale
              )
            )
            .toPromise()
            .then((user) => {
              console.log("Im local host als " + user.role + " angemeldet");
            });
        }
      });
    }
  }
}
