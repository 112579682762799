<div class="flex-container">
  <dx-select-box
    #selectBox
    [dataSource]="produktSpezifikationCategory.datasource"
    [acceptCustomValue]="true"
    displayExpr="value"
    [(value)]="produktSpezifikationCategory.value"
    (onCustomItemCreating)="addCustomItem($event)"
    customItemCreateEvent="focusout"
    (onSelectionChanged)="save($event)"
    (onFocusIn)="setInitializingToFalse()"
    width="25rem"
  ></dx-select-box>
  <dx-button
    class="button-container"
    icon="remove"
    (onClick)="resetValue()"
    [hint]="formatMessage('produktSpezifikation.update.remove')"
  >
  </dx-button>
</div>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
