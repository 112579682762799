import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Paperdefinition } from "../../../../../model/calculation/paperdefinition";
import { CalculationResult } from "../../../../../model/calculation/calculationResult";
import { NumberConverterService } from "../../../../../services/number-converter.service";
import { UserService } from "../../../../../services/user.service";
import { AuthService, P3AuthService } from "../../../../../services";
import { User } from "../../../../../model/user/user";
import { formatMessage } from "devextreme/localization";

@Component({
  selector: "app-price-table",
  templateUrl: "./price-table.component.html",
  styleUrls: ["./price-table.component.scss"],
})
export class PriceTableComponent implements OnInit, OnChanges {
  formatMessage = formatMessage;

  @Input()
  calculationResponse: CalculationResult[];
  technischeKosten: boolean = false;
  papierKosten: boolean = false;
  totalTechnischeKosten = 0;
  totalPapierKosten = 0;
  fixKostenBool: boolean = false;
  variableKostenBool: boolean = false;
  mehrpreise: boolean = false;
  total: number = 0;

  getActiveTableRows() {
    this.total = 0;
    this.totalTechnischeKosten = 0;
    this.totalPapierKosten = 0;
    this.technischeKosten = false;
    this.papierKosten = false;
    this.mehrpreise = false;
    if (this.calculationResponse) {
      console.log(this.calculationResponse);
      //technische Kosten
      if (
        this.calculationResponse[0].fixKosten?.technischeKosten
          ?.kategorieTotalKosten
      ) {
        this.technischeKosten = true;
      }
      if (
        this.calculationResponse[0].variableKosten?.summierteKosten
          ?.technischeKosten?.total
      ) {
        this.technischeKosten = true;
      }
      if (
        this.calculationResponse[0].fortdruckKosten?.summierteKosten
          ?.technischeKosten?.total
      ) {
        this.technischeKosten = true;
      }
      //Papierkosten
      if (
        this.calculationResponse[0].fixKosten?.papierkosten
          ?.kategorieTotalKosten ||
        this.calculationResponse[0].variableKosten?.summierteKosten
          ?.papierkosten?.total ||
        this.calculationResponse[0].fortdruckKosten?.summierteKosten
          ?.papierkosten?.total
      ) {
        this.papierKosten = true;
      }
      //Gesamtkosten
      this.total += this.calculationResponse[0].total;
      if (this.calculationResponse[1]?.gesamtAuflage) {
        this.total += this.calculationResponse[1].total;
      }
      if (this.calculationResponse[2]?.gesamtAuflage) {
        this.total += this.calculationResponse[2].total;
      }

      this.calculationResponse.forEach((response) => {
        if (response.mehrpreiseGesamtpreisGroups) {
          response.mehrpreiseGesamtpreisGroups.forEach((gruppe) => {
            gruppe.mehrpreisList.forEach((mmPreis) => {
              if (mmPreis) {
                this.mehrpreise = true;
              }
            });
          });
        }
      });
    }
  }

  constructor(
    public converter: NumberConverterService,
    private auth: P3AuthService
  ) {}

  ngOnChanges(): void {
    this.getActiveTableRows();
  }

  ngOnInit(): void {}
}
