import { Component, OnDestroy, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { CalculatorService } from "../../../../services/calculator.service";
import { Subscription } from "rxjs";
import { CalculationResult } from "../../../../model/calculation/calculationResult";
import { P3AuthService } from "../../../../services";
import { User } from "../../../../model/user/user";
import { NumberConverterService } from "../../../../services/number-converter.service";

@Component({
  selector: "app-calculator-calculated-price",
  templateUrl: "./calculator-calculated-price.component.html",
  styleUrls: ["./calculator-calculated-price.component.scss"],
})
export class CalculatorCalculatedPriceComponent implements OnInit, OnDestroy {
  formatMessage = formatMessage;

  calculationResponse: CalculationResult[];
  private calculationResponseSubscription: Subscription;
  private user: User;

  constructor(
    private calculatorService: CalculatorService,
    private auth: P3AuthService,
    private converter: NumberConverterService
  ) {
    this.calculationResponseSubscription = this.calculatorService
      .getCalculationResponse()
      .subscribe((response) => {
        this.calculationResponse = response;
      });
  }

  ngOnDestroy() {
    this.calculationResponseSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.auth.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        console.log(this.user?.identity.locale);
        this.converter.setLanguage(this.user?.identity.locale);
      }
    });
  }
}
