import { Component, Input, NgModule, OnInit } from "@angular/core";
import { DxScrollViewModule } from "devextreme-angular";
import { HeaderModule } from "../../shared/components";

@Component({
  selector: "app-header-and-scroll-view",
  templateUrl: "./header-and-scroll-view.component.html",
  styleUrls: ["./header-and-scroll-view.component.scss"],
})
export class HeaderAndScrollViewComponent implements OnInit {
  @Input()
  title: string;

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [DxScrollViewModule, HeaderModule],
  exports: [HeaderAndScrollViewComponent],
  declarations: [HeaderAndScrollViewComponent],
})
export class HeaderAndScrollViewModule {}
