import { formatMessage } from "devextreme/localization";

export const selectBoxMonthValuesDE = [
  {
    name: "Ganzes Jahr",
    value: -1, // gets calculations from whole year
  },
  {
    name: "Erstes Halbjahr",
    value: 6, // gets calculations from January-June
  },
  {
    name: "Zweites Halbjahr",
    value: 12, // gets calculations from July-December
  },
];
export const selectBoxMonthValuesEN = [
  {
    name: "Whole year",
    value: -1, // gets calculations from whole year
  },
  {
    name: "First half",
    value: 0, // gets calculations from January-June
  },
  {
    name: "Second half",
    value: 6, // gets calculations from July-December
  },
];
