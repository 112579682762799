import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToastModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  PaperCostsFormComponent,
  PaperCostsFormModule,
} from "../papercosts-form/papercosts-form.component";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { PaperHandlingPrice } from "generated/api/models";

@Component({
  selector: "app-papercosts-form-popup",
  templateUrl: "./papercosts-form-popup.component.html",
  styleUrls: ["./papercosts-form-popup.component.scss"],
})
export class PaperCostsFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(PaperCostsFormComponent) paperCostsForm: PaperCostsFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<PaperHandlingPrice>();
  @Input() paperCosts: PaperHandlingPrice;
 
  title: string;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = formatMessage("paperCosts.create");
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetPaperCostsForm(): void {
    console.log("reset")
  }

  save(): void {
    this.onSave.emit(this.paperCosts);
 
    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    PaperCostsFormModule,
    ActionPopupModule,
    DxToastModule,
  ],
  declarations: [PaperCostsFormPopupComponent],
  exports: [PaperCostsFormPopupComponent],
})
export class PaperCostsFormPopupModule {}
