<div class="content-block" id="container">
  <h2>{{ formatMessage("userSuggestion") }}</h2>

  <dx-data-grid
    id="user-grid"
    [dataSource]="userSuggestions"
    [columnAutoWidth]="!isMobile"
    [columnHidingEnabled]="true"
    class="dx-card wide-card"
  >
    <dxo-load-panel [enabled]="true"></dxo-load-panel>

    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager
      [visible]="true"
      [showPageSizeSelector]="true"
      [showInfo]="true"
      [allowedPageSizes]="[15, 30, 50]"
    ></dxo-pager>

    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column
      dataField="firstName"
      [caption]="formatMessage('usersuggestion.firstName')"
    ></dxi-column>
    <dxi-column
      dataField="lastName"
      [caption]="formatMessage('usersuggestion.lastName')"
    ></dxi-column>
    <dxi-column
      dataField="email"
      [caption]="formatMessage('usersuggestion.email')"
    ></dxi-column>
    <dxi-column
      dataField="locale"
      [caption]="formatMessage('locale')"
    ></dxi-column>
    <dxi-column
      dataField="createdDate"
      [caption]="formatMessage('usersuggestion.createDate')"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>
    <dxi-column
      dataField="lastModifiedDate"
      [caption]="formatMessage('usersuggestion.lastModifiedDate')"
      dataType="date"
      format="shortDateShortTime"
    ></dxi-column>
  </dx-data-grid>
</div>
<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
