<dx-form
  id="produktForm"
  labelMode="floating"
  [formData]="produktFormData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newProduktFormValidation"
>
  <dxi-item
    dataField="name"
    [label]="{ text: formatMessage('produkt.name') }"
    [editorOptions]="{ stylingMode: 'underlined', readOnly: true }"
    editorType="dxTextBox"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="edition"
    [colSpan]="2"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: editionDatasource,
      onValueChanged: onEditionChange,
      valueExpr: 'value',
      displayExpr: 'name'
    }"
    editorType="dxSelectBox"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="environmentIsUsa"
    dataField="width"
    [editorOptions]="{
      stylingMode: 'underlined',
      onValueChanged: setFormProduktName
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="numeric"
      [message]="formatMessage('validation.numericOnly')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="range"
      [min]="0.0001"
      [message]="formatMessage('validation.greaterZero')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="height"
    [editorOptions]="{
      stylingMode: 'underlined',
      onValueChanged: setFormProduktName
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="numeric"
      [message]="formatMessage('validation.numericOnly')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="range"
      [min]="0.0001"
      [message]="formatMessage('validation.greaterZero')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #produktTyp
    name="produktType"
    dataField="produktType"
    [colSpan]="2"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: produktTypenDatasource,
      onValueChanged: setFormProduktName,
      disabled: formProduktTypeDisabled,
      valueExpr: 'value',
      displayExpr: 'name'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label
      text="{{ formatMessage('printing.template.produkttyp') }}"
    ></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="reprint"
    editorType="dxCheckBox"
    [editorOptions]="{
      stylingMode: 'underlined',
      onValueChanged: setFormProduktName
    }"
  >
  </dxi-item>
  <dxi-item
    dataField="twoTitles"
    editorType="dxCheckBox"
    [editorOptions]="{
      stylingMode: 'underlined',
      onValueChanged: setFormProduktName
    }"
  >
  </dxi-item>
</dx-form>
