import {
  Component,
  Input,
  OnInit,
  NgModule,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ProduktSpezifikationService } from "../../../services/produkt-spezifikation.service";
import { PaperService } from "../../../services/paper.service";
import {
  Paperdefinition,
  ProduktPaperDefinition,
} from "../../../model/calculation/paperdefinition";
import {
  DxDataGridModule,
  DxButtonModule,
  DxToastModule,
} from "devextreme-angular";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { ProduktPapersGridModule } from "../produkt-papers-grid/produkt-papers-grid.component";
import { CommonModule } from "@angular/common";
import { ConfirmationDialogModule } from "../../confirmation-dialog/confirmation-dialog.component";
import { ProduktFormData } from "../../../model/calculation/produktFormData";
import { ProduktSpezifikationCategory } from "../../../model/calculation/produktSpezifikation";

const enum OperationTypes {
  DELETE,
  CHANGE_ENABLED_DISABLED_STATE,
}

@Component({
  selector: "app-produkt-spezifikation-papers",
  templateUrl: "./produkt-spezifikation-papers.component.html",
  styleUrls: ["./produkt-spezifikation-papers.component.scss"],
})
export class ProduktSpezifikationPapersComponent implements OnInit, OnChanges {
  formatMessage = formatMessage;

  @Input() produktData: ProduktFormData;
  @Input() produktSpezifikationCategory: ProduktSpezifikationCategory;

  allAssociatedPapers: Paperdefinition[] = [];
  allProduktPaperDefinitions: ProduktPaperDefinition =
    new ProduktPaperDefinition();

  selectedPaperId: string | undefined = undefined;

  paperPopupVisible: boolean = false;

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationPopup: boolean = false;
  confirmOperation: OperationTypes;
  confirmButtonText: string;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  constructor(
    private produktSpezifikationService: ProduktSpezifikationService,
    private paperService: PaperService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getPaperValues();
  }

  getPaperValues() {
    this.allAssociatedPapers = [];

    this.paperService
      .findAllPapers()
      .toPromise()
      .then(
        (data) =>
          (this.allProduktPaperDefinitions.paperDefinitionList = [...data])
      );

    this.allProduktPaperDefinitions.paperSingleSelect =
      this.produktSpezifikationCategory.paperSingleSelect;

    if (this.produktSpezifikationCategory.value) {
      const papers = this.produktSpezifikationCategory.value
        .split(";")
        .map((item) => item.trim());
      papers.forEach((paper) => {
        this.paperService
          .findPaperById(paper)
          .toPromise()
          .then((data) => this.allAssociatedPapers.push(data));
      });
    }
  }

  deleteProduktPapierAssociationAndUpdateView() {
    if (!this.selectedPaperId) return;
    this.paperService
      .deletePaperProduktAssociation(this.produktData.id, this.selectedPaperId)
      .toPromise()
      .then(() => {
        this.removePaperFromView();

        if (this.produktSpezifikationCategory.value) {
          const papers = this.produktSpezifikationCategory.value
            .split(";")
            .filter((entry) => entry !== "undefined");

          const filteredPapers = papers.filter(
            (item) => item !== this.selectedPaperId
          );

          this.produktSpezifikationCategory.value = filteredPapers.join(";");
        }

        this.toastType = "success";
        this.toastMessage = formatMessage(
          "paper.deleteProduktAssociation.success"
        );
      })
      .catch((e) => {
        this.toastType = "error";
        this.toastMessage = formatMessage(
          "paper.deleteProduktAssociation.success"
        );
      })
      .finally(() => (this.showToast = true));
  }

  saveProduktPapierAssociation() {
    this.saveProduktPapierAssociationAndUpdateView();
  }

  removePaperFromView() {
    const selectedPaperIndex = this.allAssociatedPapers.findIndex(
      (it) => it.id === this.selectedPaperId
    );

    if (selectedPaperIndex !== -1) {
      this.allAssociatedPapers.splice(selectedPaperIndex, 1);
    }
  }

  showConfirmationPopupForDeletePaperAssociation(paper: Paperdefinition) {
    this.selectedPaperId = paper.id;

    this.confirmationPopupTitle = formatMessage(
      `paperAssociation.delete.confirm.title`
    );
    this.confirmationPopupMessage = formatMessage(
      `paperAssociation.delete.confirm.message`,
      paper.name
    );
    this.confirmButtonText = formatMessage("delete");

    this.confirmOperation = OperationTypes.DELETE;
    this.showConfirmationPopup = true;
  }

  saveProduktPapierAssociationAndUpdateView() {
    if (this.allProduktPaperDefinitions.paperSingleSelect) {
      this.paperPopupVisible = false;
      const newAssociatedPapers =
        this.allProduktPaperDefinitions.paperDefinitionList.filter(
          (paper) => paper.addToProduktSpezifikation
        );

      this.produktSpezifikationCategory.value = newAssociatedPapers[0].id;
      this.allAssociatedPapers = newAssociatedPapers;
    } else {
      this.paperPopupVisible = false;
      const newAssociatedPapers =
        this.allProduktPaperDefinitions.paperDefinitionList.filter(
          (paper) => paper.addToProdukt
        );

      this.produktSpezifikationCategory.value = newAssociatedPapers
        .map((paper) => paper.id)
        .join(";");
      this.allAssociatedPapers = newAssociatedPapers;
    }
  }

  showAddPapersPopup() {
    this.allProduktPaperDefinitions.paperDefinitionList.forEach((paper) => {
      paper.addToProdukt = false;
      paper.addToProduktSpezifikation = false;
    });

    this.allAssociatedPapers.forEach((associatedPaper) => {
      const indexOfPaper =
        this.allProduktPaperDefinitions.paperDefinitionList.findIndex(
          (paper) => associatedPaper.id === paper.id
        );

      this.allProduktPaperDefinitions.paperDefinitionList[
        indexOfPaper
      ].addToProdukt = true;

      this.allProduktPaperDefinitions.paperDefinitionList[
        indexOfPaper
      ].addToProduktSpezifikation = true;
    });

    this.paperPopupVisible = true;
  }
}

@NgModule({
  imports: [
    DxDataGridModule,
    DxButtonModule,
    ActionPopupModule,
    ProduktPapersGridModule,
    CommonModule,
    ConfirmationDialogModule,
    DxToastModule,
  ],
  declarations: [ProduktSpezifikationPapersComponent],
  exports: [ProduktSpezifikationPapersComponent],
})
export class ProduktSpezifikationPapersModule {}
