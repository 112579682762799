import { Produkt } from "./produkt";

export class OptionRequest {
  //  both
  id: string;
  optionName: string;
  optionType: string;
  sortId: number;
  optionGroupId: string;
  preislistenKatalogId: string;
  optionPriceInformation:
    | OptionPriceInformationRequestDe[]
    | OptionPriceInformationRequestUsCarton[]
    | OptionPriceInformationRequestUsDifference[]
    | OptionPriceInformationRequestUsInsert[]
    | OptionPriceInformationRequestUsOTP[]
    | OptionPriceInformationRequestUsSurchargeAndSubSurcharge[];
  associatedProducts: string[];

  //  DE
  optionPosition: string;
  optionUnitId: string;
  fixedValue: number;

  //  US
  invoiceCategory: string;
  glCode: string;
  quantityType: string;
  requiredQuantityType: string;
  cartonColumnNames: string[];
  ancillaryCode: string;
  dependentOptions: string[];
  hideInFrontend: boolean;
  covers: boolean;

  constructor(
    optionName: string,
    optionType: string,
    optionPosition: string,
    sortId: number,
    fixedValue: number,
    optionGroupId: string,
    preislistenKatalogId: string,
    optionUnitId: string,
    optionPriceInformation:
      | OptionPriceInformationRequestDe[]
      | OptionPriceInformationRequestUsCarton[]
      | OptionPriceInformationRequestUsDifference[]
      | OptionPriceInformationRequestUsInsert[]
      | OptionPriceInformationRequestUsOTP[]
      | OptionPriceInformationRequestUsSurchargeAndSubSurcharge[],
    associatedProducts: string[],
    invoiceCategory: string,
    glCode: string,
    requiredQuantityType: string,
    quantityType: string,
    cartonColumnNames: string[],
    ancillaryCode: string,
    dependentOptions: string[],
    hideInFrontend: boolean,
    covers: boolean
  ) {
    this.optionName = optionName;
    this.optionType = optionType;
    this.optionPosition = optionPosition;
    this.sortId = sortId;
    this.fixedValue = fixedValue;
    this.optionGroupId = optionGroupId;
    this.preislistenKatalogId = preislistenKatalogId;
    this.optionUnitId = optionUnitId;
    this.optionPriceInformation = optionPriceInformation;
    this.associatedProducts = associatedProducts;
    this.invoiceCategory = invoiceCategory;
    this.glCode = glCode;
    this.requiredQuantityType = requiredQuantityType;
    this.quantityType = quantityType;
    this.cartonColumnNames = cartonColumnNames;
    this.ancillaryCode = ancillaryCode;
    this.dependentOptions = dependentOptions;
    this.hideInFrontend = hideInFrontend;
    this.covers = covers;
  }
}

// Aufteilung der

export class OptionPriceInformationRequestDe {
  von: number;
  bis: number;
  fixKosten: number;
  variableKosten: number;
  fortDruckKosten: number;

  constructor(
    von: number,
    bis: number,
    fixKosten: number,
    variableKosten: number,
    fortDruckKosten: number
  ) {
    this.von = von;
    this.bis = bis;
    this.fixKosten = fixKosten;
    this.variableKosten = variableKosten;
    this.fortDruckKosten = fortDruckKosten;
  }
}

export class OptionPriceInformationRequestUsCarton {
  from: number;
  to: number;
  col0: number;
  col1: number;
  col2: number;
  col3: number;
  col4: number;
  col5: number;
  col6: number;
  col7: number;
  col8: number;
  col9: number;

  constructor(
    from: number,
    to: number,
    col0: number,
    col1?: number,
    col2?: number,
    col3?: number,
    col4?: number,
    col5?: number,
    col6?: number,
    col7?: number,
    col8?: number,
    col9?: number
  ) {
    this.from = from;
    this.to = to;
    this.col0 = col0;
    if (col1) {
      this.col1 = col1!;
    }
    if (col2) {
      this.col2 = col2!;
    }
    if (col3) {
      this.col3 = col3!;
    }
    if (col4) {
      this.col4 = col4!;
    }
    if (col5) {
      this.col5 = col5!;
    }
    if (col6) {
      this.col6 = col6!;
    }
    if (col7) {
      this.col7 = col7!;
    }
    if (col8) {
      this.col8 = col8!;
    }
    if (col9) {
      this.col9 = col9!;
    }
  }
}

export class OptionPriceInformationRequestUsDifference {
  from: number;
  to: number;
  col0: number;

  constructor(from: number, to: number, col0: number) {
    this.from = from;
    this.to = to;
    this.col0 = col0;
  }
}

export class OptionPriceInformationRequestUsInsert {
  from: number;
  to: number;
  insertPages: number;
  insertConfig: string;
  makeReady: number;
  plates: number;
  run: number;

  constructor(
    from: number,
    to: number,
    insertPages: number,
    insertConfig: string,
    makeReady: number,
    plates: number,
    run: number
  ) {
    this.from = from;
    this.to = to;
    this.insertPages = insertPages;
    this.insertConfig = insertConfig;
    this.makeReady = makeReady;
    this.plates = plates;
    this.run = run;
  }
}

export class OptionPriceInformationRequestUsOTP {
  price: number;

  constructor(price: number) {
    this.price = price;
  }
}

export class OptionPriceInformationRequestUsSurchargeAndSubSurcharge {
  surcharge: number;

  constructor(surcharge: number) {
    this.surcharge = surcharge;
  }
}
