import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { environment } from "../../../../../environments/environment";
import {
  DxButtonModule,
  DxDataGridModule,
  DxFileUploaderComponent,
  DxFileUploaderModule,
  DxProgressBarModule,
  DxResponsiveBoxModule,
  DxSelectBoxModule,
  DxToastModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { HttpClient } from "@angular/common/http";
import { UploadInfo } from "../../../model/upload/uploadInfo";
import { GraphQLModule } from "../../../../graphql.module";
import { BrowserModule } from "@angular/platform-browser";
import { AlertModule } from "../../alert/alert.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { UploadLink } from "../../../model/upload/uploadLink";
import notify from "devextreme/ui/notify";
import {
  createAwsRumEvent,
  RumErrorTypes,
} from "../../../../rumMonitorConfiguration";
import { Router } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { FileUploadService } from "../../../services/file-upload.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @ViewChild(DxFileUploaderComponent) fileUploader: any;

  @Input() orderId: string;
  @Input() orderPiece: string;
  @Input() uploadLink: UploadLink | undefined = undefined;
  @Output() uploading = new EventEmitter<boolean>();
  @Output() fileAlreadyExists = new EventEmitter<string>();
  @Output() uploadSuccessful = new EventEmitter<string>();
  @Output() uploadError = new EventEmitter<string>();

  successAlertMessage: string = "";

  formatMessage = formatMessage;

  disabled = true;
  isDropZoneActive = false;
  noOrderPieceSelectedError = false;
  showErrorBorders = false;

  // Set by FileUploadService
  progressVisible = false;
  progressValue = 0;
  isUploading: boolean = false;
  fileUploadServiceSubscriptions: Subscription = new Subscription();

  lastUploadedFileName: string = "";
  invalidFileMessage: string = "";

  hasNetworkConnection: boolean;
  showReconnectedToNetwork = false;

  isLargeOrUp: boolean;

  //For AwsRum
  static _uploadUrl: string;
  static _user: object;
  static _orderId: string;

  constructor(
    private http: HttpClient,
    private screenService: ScreenService,
    private router: Router,
    private userService: UserService,
    private fileUploadService: FileUploadService
  ) {
    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
    this.onUploaded = this.onUploaded.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onUploadStarted = this.onUploadStarted.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onSelectedFileChanged = this.onSelectedFileChanged.bind(this);
  }

  @HostListener("window:online")
  setNetworkOnline(): void {
    this.showReconnectedToNetwork =
      !this.hasNetworkConnection && this.isUploading;
    this.hasNetworkConnection = true;
  }

  @HostListener("window:offline")
  setNetworkOffline(): void {
    this.showReconnectedToNetwork = false;
    this.hasNetworkConnection = false;
  }

  ngOnInit(): void {
    this.hasNetworkConnection = window.navigator.onLine;
    this.isLargeOrUp = this.screenService.sizes["screen-large-or-up"];
    this.prepareAwsRumErrorObjectAttributes();

    // State Subscription by FileUploadService
    this.fileUploadServiceSubscriptions.add(
      FileUploadService.progressValue.subscribe(
        (value) => (this.progressValue = value)
      )
    );
    this.fileUploadServiceSubscriptions.add(
      FileUploadService.progressVisible.subscribe(
        (value) => (this.progressVisible = value)
      )
    );
    this.fileUploadServiceSubscriptions.add(
      FileUploadService.isUploading.subscribe((value) => {
        this.isUploading = value;
        this.uploading.emit(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("orderPiece" in changes) {
      this.resetDropZoneErrorValues();

      this.disabled = changes["orderPiece"].currentValue == null;
      this.resetUploadValues();
    }
  }

  ngOnDestroy() {
    this.fileUploadServiceSubscriptions.unsubscribe();
  }

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === "dropzone-external") {
      if (!this.orderPiece) {
        this.isDropZoneActive = false;
        this.noOrderPieceSelectedError = true;
        this.showErrorBorders = true;
      } else {
        this.isDropZoneActive = true;
      }
    }
  }

  onDropZoneLeave(e) {
    this.resetDropZoneErrorValues();

    if (e.dropZoneElement.id === "dropzone-external")
      this.isDropZoneActive = false;
  }

  onDropZoneClick(e) {
    if (this.disabled || !this.hasNetworkConnection) {
      e.preventDefault();
    }
  }

  onSelectedFileChanged() {
    if (!this.orderPiece) {
      this.noOrderPieceSelectedError = true;
      this.showErrorBorders = true;
      return;
    }

    let firstFile = this.fileUploader.instance._files[0];
    if (!firstFile.isValid()) {
      this.invalidFileMessage = formatMessage(
        "upload.invalid.pdf",
        firstFile.value.name
      );
      this.isDropZoneActive = false;
      this.showErrorBorders = true;
    } else if (firstFile.value.size <= 0) {
      this.invalidFileMessage = formatMessage("upload.emptyFile");
      this.isDropZoneActive = false;
      this.showErrorBorders = true;
      notify(
        {
          message: formatMessage("upload.errorText", firstFile.value.name),
          type: "error",
          displayName: 7500,
        },
        { position: "bottom center", direction: "up-stack" }
      );
    } else {
      this.resetDropZoneErrorValues();

      // here file name adjusting
      let filename = this.filterFilename(firstFile.value.name);
      this.getPresignUrlAndUploadFile(firstFile, filename);
    }
  }

  customUploadFile(file) {
    let fileUploaderInstance = <any>this.fileUploader.instance;

    if (file?.value.size > 0) {
      let $file = file.$file,
        value = file.value;

      if ($file) {
        file.progressBar = fileUploaderInstance._createProgressBar(value.size);
        if (file.progressBar) $file.append(file.progressBar.element());
      }

      /*
      let request = new XMLHttpRequest();
      request.open(
        fileUploaderInstance.option("uploadMethod"),
        this.fileUploader.uploadUrl,
        true
      );
      file.request = request;

      request.onreadystatechange = function (oEvent) {
        if (request.readyState === 4) {
          if (request.status === 200) {
            FileUploaderComponent.successHandler(file.value);
          } else {
            let awsRumErrorObj = {
              errorStatus: request.status,
              errorMessage: request.statusText,
              requestUrl: FileUploaderComponent._uploadUrl,
              fileDetails: file.value,
            };
            FileUploaderComponent.errorHandler(file.value);
            FileUploaderComponent.errorHandlerAwsRum(
              RumErrorTypes.S3_REQUEST_ERROR,
              awsRumErrorObj
            );
          }
        }
      };
      file.request.send(file.value);
       */
      this.fileUploader.instance.upload();
    } else {
      FileUploaderComponent.errorHandler(file.value);
      FileUploaderComponent.errorHandlerAwsRum(
        RumErrorTypes.UPLOAD_FILE_VALUE_ERROR,
        {
          error: "File Value 0",
          fileDetails: file.value,
        }
      );
    }

    fileUploaderInstance._isProgressStarted = false;
  }

  customInitUploadRequest(file) {
    let fileUploaderInstance = <any>this.fileUploader.instance;
    let that = { ...fileUploaderInstance };

    file.request = this.customCreateRequest(this.fileUploader.uploadUrl);
    file.loadedSize = 0;

    fileUploaderInstance._initUploadHeaders(file.request);

    file.request["onreadystatechange"] = function (e) {
      if (e.currentTarget.readyState === 4) {
        var status = e.currentTarget.status;

        if (that._isStatusSuccess(status)) {
          fileUploaderInstance.onLoad.fire(e);
        } else if (
          that._isStatusError(status) ||
          !fileUploaderInstance._isProgressStarted
        ) {
          fileUploaderInstance._isError = true;
          fileUploaderInstance.onError.fire(e);
        }
      }
    }.bind(file);

    file.request.upload["onprogress"] = function (e) {
      if (fileUploaderInstance._isError) {
        return;
      }

      fileUploaderInstance._isProgressStarted = true;
      fileUploaderInstance.onProgress.fire(e);
    }.bind(file);

    file.request.upload["onloadstart"] = function (e) {
      fileUploaderInstance.onLoadStart.fire(e);
    }.bind(file);

    file.request.upload["onabort"] = function (e) {
      fileUploaderInstance.onAbort.fire(e);
    }.bind(file);
  }

  customCreateRequest(url) {
    let fileUploaderInstance = <any>this.fileUploader.instance;
    let request = new XMLHttpRequest();
    request.open(fileUploaderInstance.option("uploadMethod"), url, true);
    return request;
  }

  resetUploadValues() {
    this.isUploading = false;
    this.successAlertMessage = "";
    this.lastUploadedFileName = "";
    this.invalidFileMessage = "";

    this.progressVisible = false;
    this.progressValue = 0;
  }

  resetDropZoneErrorValues() {
    this.noOrderPieceSelectedError = false;
    this.showErrorBorders = false;
    this.invalidFileMessage = "";
  }

  refresh() {
    window.location.reload();
  }

  static errorHandler(file) {
    notify(
      {
        message: formatMessage("upload.errorText", file.name),
        type: "error",
        displayName: 7500,
      },
      { position: "bottom center", direction: "up-stack" }
    );
  }

  static successHandler(file) {
    notify(
      {
        message: formatMessage("upload.successText", file.name),
        type: "success",
        displayName: 7500,
      },
      { position: "bottom center", direction: "up-stack" }
    );
  }

  private prepareAwsRumErrorObjectAttributes() {
    this.userService.findOwnUser().subscribe((user) => {
      FileUploaderComponent._user = { id: user.id, identity: user.identity };
    });
    FileUploaderComponent._orderId = this.orderId;
  }

  public static errorHandlerAwsRum(
    logType: RumErrorTypes,
    errorObject: object | Error
  ) {
    let eventBody = {
      user: FileUploaderComponent._user,
      orderId: FileUploaderComponent._orderId,
      details: errorObject,
    };

    switch (logType) {
      case RumErrorTypes.INTERNAL_SERVER_ERROR:
      case RumErrorTypes.UPLOAD_FILE_VALUE_ERROR:
      case RumErrorTypes.S3_REQUEST_ERROR:
        createAwsRumEvent(logType, eventBody);
        break;
    }
  }

  private getPresignUrlAndUploadFile(
    firstFile: any,
    filename: string,
    counter?: number
  ) {
    let url: string;
    let loopFileName: string;
    const dotIndex = filename.lastIndexOf(".");
    const namePart =
      dotIndex !== -1 ? filename.substring(0, dotIndex) : filename;
    let extensionPart = dotIndex !== -1 ? filename.substring(dotIndex) : "";
    counter
      ? (loopFileName = namePart + `_${counter}` + extensionPart)
      : (loopFileName = namePart + extensionPart);

    if (this.uploadLink) {
      url = `${environment.baseUrl}/api/public/upload/uploadlink/${this.uploadLink.urlIdentifier}/${loopFileName}`;
    } else {
      url = `${environment.baseUrl}/api/upload/${this.orderId}/${this.orderPiece}/${loopFileName}`;
    }

    this.http
      .get<UploadInfo>(url)
      .toPromise()
      .then((data: UploadInfo) => {
        // Exit loop: Name unique

        // still Needed
        this.fileUploader.uploadUrl = data.presignedUrl;

        // Old Upload
        FileUploaderComponent._uploadUrl = data.presignedUrl;
        this.lastUploadedFileName = data.filename;

        // New UploadService
        FileUploadService.fileName = data.filename;
        FileUploadService.orderId = this.orderId;
        FileUploadService.orderPiece = this.orderPiece;

        this.fileUploadService.prepareAndUpload(
          firstFile,
          this.http,
          data.presignedUrl
        );
      })
      .catch((e) => {
        this.uploading.emit(false);

        // Local host returns e.error.message / Server returns e.error
        if (
          e.error.message === "FILE_ALREADY_EXISTS" ||
          e.error === "FILE_ALREADY_EXISTS"
        ) {
          this.getPresignUrlAndUploadFile(
            firstFile,
            filename,
            counter ? ++counter : 1
          );
        } else {
          FileUploaderComponent.errorHandlerAwsRum(
            RumErrorTypes.INTERNAL_SERVER_ERROR,
            {
              error: "Internal Server Error",
              url: url,
              isUploadLink: this.uploadLink,
            }
          );
          this.uploadError.emit(firstFile.value.name);
        }
        this.resetUploadValues();
      });
  }

  private filterFilename(filename: string) {
    const dotIndex = filename.lastIndexOf(".");
    const namePart =
      dotIndex !== -1 ? filename.substring(0, dotIndex) : filename;
    let extensionPart = dotIndex !== -1 ? filename.substring(dotIndex) : "";

    // Ä, Ö, Ü, ä, ö, ü ersetzen
    let filteredName = namePart
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .replace(/Ä/g, "Ae")
      .replace(/Ö/g, "Oe")
      .replace(/Ü/g, "Ue");

    // Zeichen mit Tilde oder Apostroph durch den entsprechenden Vokal ersetzen
    filteredName = filteredName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    // Leerzeichen und Sonderzeichen durch Unterstrich ersetzen
    filteredName = filteredName.replace(/[^a-zA-Z0-9]/g, "_");

    // Dateiendung in Kleinbuchstaben umwandeln
    extensionPart = extensionPart.toLowerCase();

    if (filename !== filteredName + extensionPart)
      notify(
        {
          message: formatMessage(
            "upload.fileNameChanged",
            filteredName + extensionPart
          ),
          type: "success",
          displayName: 7500,
        },
        { position: "bottom center", direction: "up-stack" }
      );

    return filteredName + extensionPart;
  }

  onUploaded(e) {
    const file = e.file;
    FileUploaderComponent.successHandler(file);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.isDropZoneActive = false;

      this.isUploading = false;
      this.uploading.emit(false);

      // die zwei zeilen unten löschen?!
      //this.successAlertMessage = formatMessage("upload.successText", file.name);
      //this.uploadSuccessful.emit(file.name);
    };
    fileReader.readAsDataURL(file);
    this.progressVisible = false;
    this.progressValue = 0;
  }

  onProgress(e) {
    this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
  }

  onUploadStarted(e) {
    this.successAlertMessage = "";

    this.isUploading = true;
    this.uploading.emit(true);

    this.progressVisible = true;
  }

  onUploadError(e) {
    // needen ?!
    //this.uploading.emit(false);
    //this.uploadError.emit(e.file.name);
    let awsRumErrorObj = {
      errorDetails: e,
    };
    FileUploaderComponent.errorHandler(e.file);
    FileUploaderComponent.errorHandlerAwsRum(
      RumErrorTypes.S3_REQUEST_ERROR,
      awsRumErrorObj
    );
    this.resetUploadValues();
  }
}

@NgModule({
  imports: [
    GraphQLModule,
    BrowserModule,
    DxResponsiveBoxModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxDataGridModule,
    DxFileUploaderModule,
    DxProgressBarModule,
    DxToastModule,
    AlertModule,
    ClipboardModule,
  ],
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
