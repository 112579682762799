import { Component, Input, NgModule, OnInit } from "@angular/core";
import { CalculatorCsvImportModule } from "../../calculator/calculator-de/calculator-csv-import/calculator-csv-import.component";
import { ProduktCsvUploadComponent } from "../produkt-csv-upload/produkt-csv-upload.component";
import { ProduktVariantenDataSource } from "../../../model/calculation/produkt";
import { DxDataGridModule } from "devextreme-angular";

@Component({
  selector: "app-produkt-produkt-varianten-datagrid",
  templateUrl: "./produkt-produkt-varianten-datagrid.component.html",
  styleUrls: ["./produkt-produkt-varianten-datagrid.component.scss"],
})
export class ProduktProduktVariantenDatagridComponent implements OnInit {
  @Input()
  produktVariantenDataSource: ProduktVariantenDataSource[];

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  declarations: [ProduktProduktVariantenDatagridComponent],
  exports: [
    ProduktProduktVariantenDatagridComponent,
    ProduktProduktVariantenDatagridComponent,
  ],
  imports: [DxDataGridModule],
})
export class ProduktProduktVariantenDatagridModule {}
