import { gql } from "apollo-angular";

export const FIND_ALL_PUBLISHER_GROUPS = gql`
  query findAllPublisherGroups {
    findAllPublisherGroups {
      id
      disoNumber
      displayName
    }
  }
`;

export const FIND_ALL_PUBLISHER = gql`
  query findAllPublisher {
    findAllPublisher {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const FIND_PUBLISHER_BY_PUBLISHER_GROUP = gql`
  query findAllPublisherByPublisherGroup($publisherGroupId: String!) {
    findAllPublisherByPublisherGroup(publisherGroupId: $publisherGroupId) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const FIND_ALL_STUDIOS = gql`
  query findAllStudios {
    findAllStudios {
      id
      displayName
    }
  }
`;

export const CREATE_PUBLISHER_GROUP = gql`
  mutation createPublisherGroup($disoNumber: Long!, $displayName: String!) {
    createPublisherGroup(disoNumber: $disoNumber, displayName: $displayName) {
      id
      disoNumber
      displayName
    }
  }
`;

export const CREATE_PUBLISHER = gql`
  mutation createPublisher(
    $disoNumber: Long!
    $name: String!
    $displayName: String!
    $publisherGroup: UUID
    $standardStudios: [UUID!]!
    $preislistenKatalog: UUID
  ) {
    createPublisher(
      disoNumber: $disoNumber
      name: $name
      displayName: $displayName
      publisherGroup: $publisherGroup
      standardStudios: $standardStudios
      preislistenKatalog: $preislistenKatalog
    ) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const CREATE_STUDIO = gql`
  mutation createStudio($displayName: String!) {
    createStudio(displayName: $displayName) {
      id
      displayName
    }
  }
`;

export const UPDATE_PUBLISHER_GROUP = gql`
  mutation updatePublisherGroup(
    $id: UUID!
    $disoNumber: Long!
    $displayName: String!
  ) {
    updatePublisherGroup(
      id: $id
      disoNumber: $disoNumber
      displayName: $displayName
    ) {
      id
      disoNumber
      displayName
    }
  }
`;

export const UPDATE_PUBLISHER = gql`
  mutation updatePublisher(
    $id: UUID!
    $disoNumber: Long!
    $name: String!
    $displayName: String!
    $publisherGroup: UUID
    $standardStudios: [UUID!]!
    $preislistenKatalog: UUID
  ) {
    updatePublisher(
      id: $id
      disoNumber: $disoNumber
      name: $name
      displayName: $displayName
      publisherGroup: $publisherGroup
      standardStudios: $standardStudios
      preislistenKatalog: $preislistenKatalog
    ) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio($id: UUID!, $displayName: String!) {
    updateStudio(id: $id, displayName: $displayName) {
      id
      displayName
    }
  }
`;

export const DELETE_PUBLISHER_GROUP = gql`
  mutation deletePublisherGroup($id: UUID!) {
    deletePublisherGroup(id: $id)
  }
`;

export const DELETE_PUBLISHER = gql`
  mutation deletePublisher($id: UUID!) {
    deletePublisher(id: $id)
  }
`;

export const DELETE_STUDIO = gql`
  mutation deleteStudio($id: UUID!) {
    deleteStudio(id: $id)
  }
`;
