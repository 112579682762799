import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  DxTreeViewModule,
  DxTabPanelModule,
  DxSelectBoxModule,
  DxButtonModule,
} from "devextreme-angular";
import { CommonModule } from "@angular/common";
import { formatMessage } from "devextreme/localization";
import { PaperService } from "../../../services/paper.service";
import { ProduktFormData } from "../../../model/calculation/produktFormData";
import { ProduktSpezifikationPapersModule } from "../produkt-spezifikation-papers/produkt-spezifikation-papers.component";
import { ProduktSpezifikationEditBoxModule } from "../produkt-spezifikation-editbox/produkt-spezifikation-editbox.component";
import { KachelModule } from "../../kachel/kachel.component";
import {
  ProduktSpezifikationMasterCategory,
  ProduktSpezifikationCategory,
  ProduktSpezifikation,
  ProduktSpezifikationRequest,
} from "../../../model/calculation/produktSpezifikation";
import { ProduktSpezifikationService } from "../../../services/produkt-spezifikation.service";

@Component({
  selector: "app-produkt-spezifikation-overview",
  templateUrl: "./produkt-spezifikation-overview.component.html",
  styleUrls: ["./produkt-spezifikation-overview.component.scss"],
})
export class ProduktSpezifikationOverviewComponent
  implements OnInit, OnChanges
{
  formatMessage = formatMessage;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  loading: boolean = true;

  @Input() produktData: ProduktFormData;

  produktSpezifikationMasterCategories: ProduktSpezifikationMasterCategory[];
  selectedProduktSpezifikationCategory: ProduktSpezifikationCategory;

  constructor(
    private produktSpezifikationService: ProduktSpezifikationService,
    private paperService: PaperService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.produktSpezifikationService.loadProduktSpezifikationMasterCategories(
      this.produktData
    );
    this.produktSpezifikationService.loadProduktSpezifikationValues(
      this.produktData
    );
    this.produktSpezifikationMasterCategories =
      this.produktSpezifikationService.produktSpezifikationTree;
    this.selectedProduktSpezifikationCategory =
      this.produktSpezifikationService.selectedProduktSpezifikationCategory;
  }

  changeSelection(e) {
    this.produktSpezifikationService.changeSelection(e);
    this.produktSpezifikationMasterCategories =
      this.produktSpezifikationService.produktSpezifikationTree;
    this.selectedProduktSpezifikationCategory =
      this.produktSpezifikationService.selectedProduktSpezifikationCategory;
  }

  saveProduktSpezifikationen() {
    const produktSpezifikationRequests: ProduktSpezifikationRequest[] = [];
    this.produktSpezifikationMasterCategories.forEach((masterCategory) => {
      masterCategory.items.forEach((category) => {
        console.log(category);
        if (
          category.masterCategory === "INHALT" &&
          category.category === "PAPIER"
        ) {
          if (category.value) {
            const newAssociatedPapers = category.value
              .split(";")
              .map((item) => item.trim());

            this.paperService
              .mergePaperProduktAssociations(
                this.produktData.id,
                newAssociatedPapers
              )
              .toPromise()
              .then()
              .catch((e) => {
                this.toastMessage = formatMessage(
                  "paperAssociation.update.update.error"
                );
                this.toastType = "error";
                this.showToast = true;
              });
          }
        } else {
          produktSpezifikationRequests.push(<ProduktSpezifikation>{
            masterCategory: category.masterCategory,
            category: category.category,
            produktId: this.produktData.id,
            value: category.value,
          });
        }
      });
    });

    this.produktSpezifikationService
      .saveOrDeleteProduktSpezifikation(produktSpezifikationRequests)
      .toPromise()
      .then(() => {
        this.toastMessage = formatMessage("paperAssociation.update.success");
        this.toastType = "success";
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("produkt.update.error");
      })
      .finally(() => {
        this.showToast = true;
      });
  }
}

@NgModule({
  imports: [
    DxTreeViewModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    CommonModule,
    ProduktSpezifikationPapersModule,
    ProduktSpezifikationEditBoxModule,
    KachelModule,
    DxButtonModule,
  ],
  declarations: [ProduktSpezifikationOverviewComponent],
  exports: [ProduktSpezifikationOverviewComponent],
})
export class ProduktSpezifikationOverviewModule {}
