export interface PaperCertification {
  id: string;
  name: string;
}

export interface CustomPaper {
  name: string;
  grammatur: number;
  volumen: number;
  price: number;
}

export interface Paperdefinition {
  lastModifiedDate?: any;
  createdDate?: any;
  id: string;
  name: string;
  grammatur: number;
  volumen: number;
  price: number;
  paperCertificationList: PaperCertification[];
  paperId: number;
  rollenBogenPapier: string;
  stoffklasse: string;
  faerbung: string;
  addToProdukt: boolean;
  addToProduktSpezifikation: boolean;
}

export interface ProduktPaperDefinitionInt {
  produktId: string;
  paperDefinitionList: Paperdefinition[];
  paperSingleSelect: boolean;
}

export class ProduktPaperDefinition {
  paperDefinitionList: Paperdefinition[];
  paperSingleSelect: boolean;

  constructor(
    paperDefinitionList?: Paperdefinition[],
    paperSingleSelect?: boolean
  ) {
    this.paperDefinitionList = paperDefinitionList || [];
    this.paperSingleSelect = paperSingleSelect || false;
  }
}
