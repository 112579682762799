import { Component, Input, NgModule, OnInit, ViewChild } from "@angular/core";
import { editonSelectboxUs } from "../../../../model/calculation/produktSelectBoxValues";
import { formatMessage } from "devextreme/localization";
import { CommonModule } from "@angular/common";
import { DxFormComponent, DxFormModule } from "devextreme-angular";
import {
  DxiItemModule,
  DxiValidationRuleModule,
  DxoLabelModule,
} from "devextreme-angular/ui/nested";
import { environment } from "../../../../../../environments/environment";
import { ProduktFormData } from "../../../../model/calculation/produktFormData";

@Component({
  selector: "app-product-form-de",
  templateUrl: "./product-form-de.component.html",
  styleUrls: ["./product-form-de.component.scss"],
})
export class ProductFormDeComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  formatMessage = formatMessage;

  @Input() produktFormData: ProduktFormData;
  @Input() produktTypenDatasource: any;

  environmentIsUsa = environment.isUsa;

  constructor() {}

  ngOnInit(): void {}

  resetValues(): void {
    this.form.instance.resetValues();
  }
}
@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxiItemModule,
    DxiValidationRuleModule,
    DxoLabelModule,
  ],
  declarations: [ProductFormDeComponent],
  exports: [ProductFormDeComponent],
})
export class ProductFormDeModule {}
