<div>
  <div class="d-flex align-items-center">
    <div style="margin-right: 0.5rem">
      {{ formatMessage("calculations.filter.year") }}
    </div>
    <dx-lookup
      [dataSource]="lookUpYears"
      style="min-width: 200px; margin-right: 0.5rem"
      displayExpr="name"
      valueExpr="value"
      [value]="selectedYear"
      (onValueChanged)="onYearSelected($event)"
    >
    </dx-lookup>

    <div style="margin-right: 0.5rem" *ngIf="selectedYear">
      {{ formatMessage("calculations.filter.month") }}
    </div>
    <dx-lookup
      *ngIf="selectedYear"
      [dataSource]="lookUpMonths"
      style="min-width: 200px"
      displayExpr="name"
      valueExpr="value"
      [value]="selectedMonth"
      (onValueChanged)="onMonthSelected($event)"
    >
    </dx-lookup>
  </div>

  <div class="grid-shadow">
    <dx-data-grid
      id="calculations-grid"
      class="dx-card wide-card"
      [dataSource]="dataSource"
      [showBorders]="false"
      [focusedRowEnabled]="true"
      [focusedRowIndex]="0"
      [columnAutoWidth]="true"
      [columnHidingEnabled]="true"
      [allowColumnResizing]="false"
      [allowColumnReordering]="true"
    >
      <dxo-header-filter
        [visible]="true"
        [allowSearch]="true"
      ></dxo-header-filter>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-state-storing
        [enabled]="true"
        type="localStorage"
        storageKey="p3-calculations-archived"
        [savingTimeout]="stateStoreDelay"
      ></dxo-state-storing>

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [allowedPageSizes]="allowedPageSizes"
        displayMode="compact"
      ></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>

      <dxo-column-chooser
        [enabled]="true"
        mode="select"
        [allowSearch]="true"
        [height]="520"
      ></dxo-column-chooser>
      <dxo-export [enabled]="true"></dxo-export>

      <dxi-column
        dataField="created"
        sortOrder="desc"
        [caption]="formatMessage('calculations.list.createdDate')"
        [format]="formatMessage('dateformat')"
        dataType="date"
        [allowHeaderFiltering]="false"
      ></dxi-column>

      <dxi-column
        dataField="updated"
        [caption]="formatMessage('calculations.list.modifiedDate')"
        [format]="formatMessage('dateformat')"
        dataType="date"
        [allowHeaderFiltering]="false"
      ></dxi-column>

      <dxi-column
        dataField="id"
        [caption]="formatMessage('calculations.list.id')"
      ></dxi-column>

      <dxi-column
        dataField="publisherName"
        [caption]="formatMessage('calculations.list.publisher')"
      ></dxi-column>

      <dxi-column
        dataField="publisherNumber"
        [caption]="formatMessage('calculations.list.publisherNumber')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="author"
        [caption]="formatMessage('calculations.list.author')"
      ></dxi-column>

      <dxi-column
        dataField="title"
        [caption]="formatMessage('calculations.list.title')"
      ></dxi-column>

      <dxi-column
        dataField="titleNumber"
        [caption]="formatMessage('calculations.list.titleNumber')"
      ></dxi-column>

      <dxi-column
        dataField="editionCounter"
        [caption]="formatMessage('calculations.list.editionCounter')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="orderNumber"
        [caption]="formatMessage('calculations.list.orderNumber')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="productName"
        [caption]="formatMessage('calculations.list.productType')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="volume"
        [caption]="formatMessage('calculations.list.volume')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="bookFormat"
        [caption]="formatMessage('calculations.list.bookFormat')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="paperName"
        [caption]="formatMessage('calculations.list.paper')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        dataField="userName"
        [caption]="formatMessage('calculations.list.creator')"
        [visible]="false"
      ></dxi-column>

      <dxi-column
        fixedPosition="right"
        [allowHeaderFiltering]="false"
        [caption]="formatMessage('other.actions')"
        [showInColumnChooser]="false"
        alignment="center"
        cellTemplate="actionsTemplate"
      >
      </dxi-column>

      <div *dxTemplate="let row of 'actionsTemplate'">
        <dx-button
          class="action-button"
          icon="box"
          [hint]="formatMessage('calculations.rearchive')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="reArchiveCalculation(row.data)"
        >
        </dx-button>
        <dx-button
          class="action-button"
          icon="trash"
          [hint]="formatMessage('calculations.delete')"
          stylingMode="text"
          type="default"
          style="margin-right: 8px"
          (onClick)="deleteCalculation(row.data)"
        >
        </dx-button>
      </div>

      <dxo-toolbar>
        <dxi-item location="before">
          <div *dxTemplate>
            <dx-button
              icon="deletetable"
              [hint]="formatMessage('order.grid.reset.localstorage.hint')"
              (onClick)="showConfirmDeletePopup()"
            >
            </dx-button>
          </div>
        </dxi-item>
        <dxi-item location="after" name="exportButton"></dxi-item>
        <dxi-item location="after" name="columnChooserButton"></dxi-item>
      </dxo-toolbar>
    </dx-data-grid>
    <div [hidden]="!pagerText" class="pager-info">
      {{ pagerText }}
    </div>
  </div>

  <app-confirmation-dialog
    [(visible)]="showConfirmationPopup"
    icon="dx-icon-warning"
    iconColor="warning"
    [title]="formatMessage('order.grid.reset.localstorage.popup.title')"
    [message]="formatMessage('order.grid.reset.localstorage.popup.message')"
    [confirmButtonText]="
      formatMessage('order.grid.reset.localstorage.popup.confirmButtonText')
    "
    (onConfirm)="resetLocalStorage()"
  ></app-confirmation-dialog>

  <dx-toast
    [(visible)]="showToast"
    [type]="toastType"
    [message]="toastMessage"
  ></dx-toast>
</div>
