import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  DxButtonModule,
  DxDataGridModule,
  DxTooltipModule,
} from "devextreme-angular";
import { CommonModule } from "@angular/common";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { Router } from "@angular/router";
import { P3AuthService } from "../../../shared/services";
import { CalculationService } from "../../../../../generated/api/services/calculation.service";
import { TreeViewInstanceService } from "../../../shared/services/treeViewInstance.service";
import { Calculation } from "generated/api/models";
import { formatMessage } from "devextreme/localization";
import { custom } from "devextreme/ui/dialog";

@Component({
  selector: "app-calculation-grid",
  templateUrl: "./calculation-grid.component.html",
  styleUrls: ["./calculation-grid.component.scss"],
})
export class CalculationGridComponent implements OnInit {
  @Input() calculations: Calculation[];
  private locale: string;
  customStore: CustomStore;
  dataSource: DataSource;
  formatMessage = formatMessage;
  stateStoreDelay: number = 0;

  constructor(
    private router: Router,
    private _p3AuthService: P3AuthService,
    private calculationService: CalculationService,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  ngOnInit(): void {
    this._p3AuthService.getUser().subscribe((e) => {
      if (e.identity.locale) {
        this.locale = e.identity.locale;
      }
    });

    this.dataSource = new DataSource({
      store: this.calculations,
      pushAggregationTimeout: 0,
      sort: ["updated"],
    });
  }

  private openCalculation(calculation: Calculation) {
    this.treeViewInstanceService
      .getTreeViewInstance()
      ?.selectItem("/kalkulationen");
    this.router.navigate([`/kalkulationen/${calculation.id}`]);
  }

  showDetails(calculation: Calculation): void {
    if (calculation.pricesChanged || calculation.structureChanged) {
      var myDialog = custom({
        title: formatMessage("calculations.notice"),
        messageHtml: calculation.pricesChanged
          ? formatMessage("calculations.priceChanged")
          : formatMessage("calculations.structureChanged"),
        buttons: [
          {
            text: formatMessage("calculations.ok"),
            onClick: () => {
              return { ok: true };
            },
          },
        ],
      });
      myDialog.show().done((dialogResult) => {
        if (dialogResult.ok) {
          this.openCalculation(calculation);
        }
      });
    } else {
      this.openCalculation(calculation);
    }
  }
}

@NgModule({
  imports: [DxButtonModule, DxDataGridModule, CommonModule, DxTooltipModule],
  declarations: [CalculationGridComponent],
  exports: [CalculationGridComponent],
})
export class CalculationGridModule {}
