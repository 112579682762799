import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { UserService } from "./user.service";
import { HttpClient } from "@angular/common/http";
import { mergeMap, pluck } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CalculationConfig } from "../model/calculation/calculationConfig";
import { Calculation } from "../model/calculation/calculation";
import { Paperdefinition } from "../model/calculation/paperdefinition";
import { PreislistenKatalog, Produkt } from "../model/calculation/produkt";
import {
  FIND_OPTION,
  FIND_PRODUKT_OPTIONS,
  GET_PAPERDEFINITIONS,
  GET_PRODUKTE,
  GET_PUBLISHER_BY_ID,
  GET_PUBLISHER_BY_ID_IMPORT,
  GET_PUBLISHER_BY_ID_NO_VARIANTS,
} from "../graphql/calculatorGql";
import { Apollo } from "apollo-angular";
import { CalculationResult } from "../model/calculation/calculationResult";
import { Publisher } from "../model/organization/organization";
import { Option } from "../model/calculation/option";
import { ProduktOptionResult } from "../model/calculation/produktOptionResult";
import { SelectedOption } from "../model/calculation/selectedOption";
import { ProduktInformation } from "../model/calculation/produktInformation";
import { OwnOption } from "../model/calculation/ownOption";

@Injectable({
  providedIn: "root",
})
export class CalculatorService {
  get defaultValues(): ProduktInformation {
    return this._defaultValues;
  }

  set defaultValues(value: ProduktInformation) {
    this._defaultValues = value;
  }
  get initializedOptionList(): boolean {
    return this._initializedOptionList;
  }

  set initializedOptionList(value: boolean) {
    this._initializedOptionList = value;
  }
  get selectedOptions(): SelectedOption[] {
    return this._selectedOptions;
  }

  set selectedOptions(value: SelectedOption[]) {
    this._selectedOptions = value;
  }

  get ownOptions(): OwnOption[] {
    return this._ownOptions;
  }

  set ownOptions(value: OwnOption[]) {
    this._ownOptions = value;
  }

  get selectedPublisher(): string {
    return this._selectedPublisher;
  }

  set selectedPublisher(value: string) {
    this._selectedPublisher = value;
  }

  get produktId(): string {
    return this._produktId;
  }

  set produktId(value: string) {
    this._produktId = value;
  }
  get produktOptions(): ProduktOptionResult {
    return this._produktOptions;
  }

  set produktOptions(value: ProduktOptionResult) {
    this._produktOptions = value;
  }

  private calculationResponse = new Subject<CalculationResult>();

  private _initializedOptionList: boolean = false;
  private _selectedPublisher: string;
  private _selectedOptions: SelectedOption[];
  private _ownOptions: OwnOption[];
  private _produktOptions: ProduktOptionResult;
  private _produktId: string;
  private _defaultValues: ProduktInformation;

  defaultValuesBS: BehaviorSubject<ProduktInformation> =
    new BehaviorSubject<ProduktInformation>(this.defaultValues);
  defaultValues$: Observable<ProduktInformation> =
    this.defaultValuesBS.asObservable();

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private userService: UserService
  ) {}

  getProdukte(): Observable<Produkt[]> {
    return this.apollo
      .query({
        query: GET_PRODUKTE,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllProdukt"));
  }

  getPublisherById(publisher: Publisher): Observable<Publisher> {
    return this.apollo
      .query({
        query: GET_PUBLISHER_BY_ID,
        fetchPolicy: "no-cache",
        variables: { id: publisher.id },
      })
      .pipe(pluck("data", "getPublisher"));
  }

  /*getPublisherByIdRawProductVariants(publisher: Publisher): Observable<Publisher> {
    return this.apollo
      .query({
        query: GET_PUBLISHER_BY_ID_IMPORT,
        fetchPolicy: "no-cache",
        variables: { id: publisher.id },
      })
      .pipe(pluck("data", "getPublisher"));
  }*/

  getPublisherByIdNoProduct(publisherId: String): Observable<Publisher> {
    return this.apollo
      .query({
        query: GET_PUBLISHER_BY_ID_NO_VARIANTS,
        fetchPolicy: "no-cache",
        variables: { id: publisherId },
      })
      .pipe(pluck("data", "getPublisherNoVariants"));
  }

  getCalculationResponse(): Observable<any> {
    return this.calculationResponse.asObservable();
  }

  sendCalculationResponse(value) {
    this.calculationResponse.next(value);
  }

  findOption(preislistenKatalogId: string): Observable<Option[]> {
    return this.apollo
      .query({
        query: FIND_OPTION,
        fetchPolicy: "no-cache",
        variables: { id: preislistenKatalogId },
      })
      .pipe(pluck("data", "findOption"));
  }

  findProduktOptions(
    produktId: string,
    selectedNodeIds: any[]
  ): Observable<ProduktOptionResult> {
    return this.apollo
      .query({
        query: FIND_PRODUKT_OPTIONS,
        fetchPolicy: "no-cache",
        variables: { produktId: produktId, selectedNodeIds: selectedNodeIds },
      })
      .pipe(pluck("data", "findProduktOptions"));
  }

  convertToLocaleUsString(value: number, decimalFriction?: number): string {
    let options = {};
    if (decimalFriction) {
      options = {
        minimumFractionDigits: decimalFriction,
        maximumFractionDigits: decimalFriction,
      };
    }
    return Number(value).toLocaleString("en-EN", options);
  }

  convertToLocaleUsPriceString(
    value: number,
    decimalFriction?: number
  ): string {
    return "$" + this.convertToLocaleUsString(value, decimalFriction);
  }
}
