import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToastModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  ProduktFormComponent,
  ProduktFormModule,
} from "../produkt-form/produkt-form.component";
import { ProduktFormData } from "../../../model/calculation/produktFormData";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import {
  PreislistenKatalog,
  Produkt,
} from "../../../model/calculation/produkt";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-produkt-form-popup",
  templateUrl: "./produkt-form-popup.component.html",
  styleUrls: ["./produkt-form-popup.component.scss"],
})
export class ProduktFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(ProduktFormComponent) produktForm: ProduktFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  @Input() visible: boolean;
  @Input() produkt: ProduktFormData;
  @Input() preislistenKataloge: PreislistenKatalog[];
  @Input() produkte: Produkt[];

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<ProduktFormData>();

  title: string;

  environmentIsUsa = environment.isUsa;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = formatMessage("produkt.create");
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetProduktForm(): void {
    this.produktForm.resetValues();
  }

  resetRoleValidation(): void {
    if (this.environmentIsUsa) {
      setTimeout(
        () =>
          this.produktForm?.formDe?.form?.instance
            .getEditor("role")
            ?.option("isValid", true),
        1
      );
    } else {
      setTimeout(
        () =>
          this.produktForm?.formDe?.form?.instance
            .getEditor("role")
            ?.option("isValid", true),
        1
      );
    }
  }

  save(): void {
    if (!this.produktForm.isValid()) {
      return;
    }

    let alreadyExists = false;
    this.produkte.forEach((p) => {
      if (p.name == this.produkt.name) {
        this.toastType = "error";
        this.toastMessage = formatMessage("produkt.create.alreadyExists");
        this.showToast = true;
        alreadyExists = true;
        return;
      }
    });

    if (!alreadyExists) {
      this.onSave.emit(this.produkt);
    }

    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    ProduktFormModule,
    ActionPopupModule,
    DxToastModule,
  ],
  declarations: [ProduktFormPopupComponent],
  exports: [ProduktFormPopupComponent],
})
export class ProduktFormPopupModule {}
