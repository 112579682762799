export class Farbschnitt {
  produktTeil: string;
  language: string;
  records: FarbschnittRecords[];
}

export class FarbschnittRecords {
  produktTeilKnz: string;
  nutzenBreite: string;
  nutzenHoehe: string;
  bezeichnung: string;
  datum: string;
  anschnitt: string;
}

export class CalculatedFarbschnittRecord {
  nutzenBreite: number;
  nutzenHoehe: number;
}
