<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetProduktForm()"
  [title]="title"
  (onSave)="save()"
>
  <app-produkt-form [(produktData)]="produkt"> </app-produkt-form>
</app-action-popup>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
