<div class="d-flex flex-grow flex-column content-block">
  <div
    class="d-flex flex-shrink flex-column"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
  >
    <app-publisher-group-grid
      [publisherGroups]="allPublisherGroups"
      (onCreate)="createOrganization($event)"
      (onUpdate)="updatePublisherGroup($event)"
      (onDelete)="
        showConfirmDeletePopup($event, organizationType.PUBLISHER_GROUP)
      "
    ></app-publisher-group-grid>
  </div>

  <div
    class="d-flex flex-grow"
    [class]="isMediumOrDownScreen ? 'flex-column' : ''"
  >
    <app-organization-data-grid
      [organizationType]="organizationType.PUBLISHER"
      [organizations]="allPublisher"
      (onCreate)="createOrganization($event)"
      (onUpdate)="updatePublisher($event)"
      (onDelete)="showConfirmDeletePopup($event, organizationType.PUBLISHER)"
      class="d-flex flex-column flex-grow"
      [style]="isMediumOrDownScreen ? '' : 'width: 70%; padding-right: 20px'"
    ></app-organization-data-grid>
    <app-organization-data-grid
      [organizationType]="organizationType.STUDIO"
      [organizations]="allStudios"
      (onCreate)="createOrganization($event)"
      (onUpdate)="updateStudio($event)"
      (onDelete)="showConfirmDeletePopup($event, organizationType.STUDIO)"
      class="d-flex flex-column flex-grow"
      [style]="isMediumOrDownScreen ? '' : 'width: 30%; padding-left: 20px'"
      *ngIf="!enviromentIsUsa"
    ></app-organization-data-grid>
  </div>
</div>

<app-action-popup
  *ngIf="selectedOrganization"
  [(visible)]="showOrganizationPopup"
  (onShowing)="resetValues(); resetRequiredFieldsValidation()"
  [title]="organizationPopupTitle"
  (onSave)="save()"
  [maxWidth]="
    selectedOrganizationType == organizationType.STUDIO ? '325px' : '650px'
  "
>
  <app-organization-form
    [organization]="selectedOrganization"
    [publisherGroups]="allPublisherGroups"
    [preislistenKatalogList]="allPreislistenKataloge"
    [studios]="allStudios"
  ></app-organization-form>
</app-action-popup>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  icon="dx-icon-warning"
  iconColor="warning"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="formatMessage('delete')"
  (onConfirm)="deleteOrganization()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
