import { User } from "./user";
import {
  getPublisherGroupId,
  getPublisherIds,
  getStudioId,
  isPublisher,
  isPublisherGroup,
  isStudio,
  Organization,
} from "../organization/organization";
import {
  getExtendedUserRole,
  MANUFACTURER_FOR_PUBLISHER,
  MANUFACTURER_FOR_PUBLISHER_GROUP,
} from "../roles";

export class UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  isInternal: boolean;
  locale: string;
  subscribeToNewsletter: string;

  role: string;
  notification: boolean;
  worksForPublisherGroup: string;
  worksForPublisher: string[];
  worksForStudio: string;

  constructor(user?: User) {
    this.firstName = user?.identity.firstName || "";
    this.lastName = user?.identity.lastName || "";
    this.email = user?.identity.email || "";
    this.isInternal = user?.identity.isInternal || false;
    this.locale = user?.identity.locale || "de-DE";
    this.role = user?.role ? getExtendedUserRole(user.role, user.worksFor) : "";
    this.notification = user?.notification || false;
    this.worksForPublisherGroup = !!user?.worksFor
      ? getPublisherGroupId(user.worksFor)
      : "";
    this.worksForPublisher = !!user?.worksFor
      ? getPublisherIds(user.worksFor)
      : [];
    this.worksForStudio = !!user?.worksFor ? getStudioId(user.worksFor) : "";
    this.subscribeToNewsletter = user?.newsletterStatus?.subscribed || "";
  }
}
