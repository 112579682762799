import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from "devextreme-angular";
import { ScreenService } from "../../../services";
import { supportedLocales } from "src/app/shared/model/supportedLocales";
import { CommonModule } from "@angular/common";
import {
  OptionGroupFormComponent,
  OptionGroupFormModule,
} from "../optionGroup-form/optionGroup-form.component";
import { PreislistenKatalogFormData } from "../../../model/calculation/preislistenKatalogFormData";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { PreislistenKatalog } from "../../../model/calculation/produkt";
import { OptionGroupFormData } from "../../../model/calculation/optionGroupFormData";

@Component({
  selector: "app-optiongroup-form-popup",
  templateUrl: "./optionGroup-form-popup.component.html",
  styleUrls: ["./optionGroup-form-popup.component.scss"],
})
export class OptionGroupFormPopupComponent implements OnInit, OnChanges {
  @ViewChild(OptionGroupFormComponent)
  optionGroupForm: OptionGroupFormComponent;
  isXSmall: boolean;

  formatMessage = formatMessage;
  supportedLocales = supportedLocales;

  @Input() visible: boolean;
  @Input() optionGroup: OptionGroupFormData;
  @Input() creating: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<OptionGroupFormData>();

  title: string;

  constructor(private screen: ScreenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.creating) {
      this.title = formatMessage("optionGroup.create");
    } else {
      this.title = formatMessage("optionGroup.update");
    }
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
  }

  resetOptionGroupForm(): void {
    this.optionGroupForm.resetValues();
  }

  resetRoleValidation(): void {
    setTimeout(
      () =>
        this.optionGroupForm?.form?.instance
          .getEditor("role")
          ?.option("isValid", true),
      1
    );
  }

  save(): void {
    if (!this.optionGroupForm.isValid()) {
      return;
    }

    this.onSave.emit(this.optionGroup);

    this.visible = false;
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    OptionGroupFormModule,
    ActionPopupModule,
  ],
  declarations: [OptionGroupFormPopupComponent],
  exports: [OptionGroupFormPopupComponent],
})
export class OptionGroupFormPopupModule {}
