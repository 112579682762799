export const environment = {
  maintenanceMode: false,
  production: false,
  baseUrl: "https://backend.dev.p3.bpghub.de",
  cognitoPoolId: "eu-central-1_wSSSpo20C",
  cognitoWebClientId: "7bqbg80mr1nhgvvoa4ime9l8jo",
  cognitoAuthDomain: "auth.dev.p3.bpghub.de",
  listmonkUrl: "https://newsletter.dev.p3.bpghub.de/",
  printformerUrl: "https://pfproxy.dev.p3.bpghub.de",
  scalerBaseUrl: "https://scaler-dev.bpghub.de/rest/api/submit-job",
  scalerLabelUrl:
    "https://dev.p3.bpghub.de/assets/localization_calculator.json",
  awsProjectRegion: "eu-central-1",
  awsCognitoRegion: "eu-central-1",
  isLocalHostOrDev: true,
  isUsa: false,
  //Rum Monitor Settings
  rumSettings: {
    applicationId: "d162eff7-9e02-4669-a1a9-ce7d1ef85fc0",
    applicationVersion: "1.0.0",
    applicationRegion: "eu-central-1",
    rumConfig: {
      sessionSampleRate: 1,
      identityPoolId: "eu-central-1:6a0dfd37-faaf-42b6-bf32-c317b68b72af",
      endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
      telemetries: ["http"],
      allowCookies: true,
      enableXRay: false,
    },
  },
};
