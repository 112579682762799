import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Apollo } from "apollo-angular";
import { pluck } from "rxjs/operators";
import { UserSuggestion } from "../model/user/user";
import { GET_USER_SUGGESTIONS } from "../graphql/userSuggestionGql";

@Injectable({
  providedIn: "root",
})
export class UserSuggestionService {
  constructor(private apollo: Apollo) {}

  findAllUserSuggestions(): Observable<UserSuggestion[]> {
    return this.apollo
      .query({
        query: GET_USER_SUGGESTIONS,
      })
      .pipe(pluck("data", "findAllUserSuggestions"));
  }
}
