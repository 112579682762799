<ng-container>
  <app-side-nav-outer-toolbar
    *ngIf="isLoggedIn; else publicLayout"
    title="{{ appInfo.title }}"
  >
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #publicLayout>
  <app-header-and-scroll-view title="{{ appInfo.title }}">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </app-header-and-scroll-view>
</ng-template>
