<div class="dx-card">
  <div style="width: 100%; display: flex">
    <dx-button
      [disabled]="!calculator?.calculationData?.produktArt"
      class="option-button"
      icon="arrowright"
      stylingMode="contained"
      [text]="formatMessage('calculator.calculation.options')"
      type="default"
      (onClick)="openOptions($event)"
    >
    </dx-button>
  </div>
</div>

<app-action-popup
  [(visible)]="showOptionen"
  maxWidth="80%"
  (onShowing)="(null)"
  [title]="optionTitle"
  (onSave)="submitOptionen()"
>
  <app-price-option-list
    [neededOptions]="neededOptions"
    [optionsWithMissingInput]="optionsWithMissingInput"
    [ownOptionsWithMissingInput]="ownOptionsWithMissingInput"
    [optionsWithPriceInformation]="optionWithPriceInformation"
  ></app-price-option-list>
</app-action-popup>
