import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DxFormComponent } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { PapierFormData } from "../../../model/calculation/papierFormData";
import {
  PaperCertification,
  Paperdefinition,
} from "../../../model/calculation/paperdefinition";
import { PaperService } from "../../../services/paper.service";

@Component({
  selector: "app-papier-form",
  templateUrl: "./papier-form.component.html",
  styleUrls: ["./papier-form.component.scss"],
})
export class PapierFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;

  @Input() papierFormData: PapierFormData;
  @Input() editing: boolean;

  dropDownRollenBogen = ["Rolle", "Bogen"];

  dropDownStoffklasse = [
    "holzhaltig",
    "leicht holzhaltig",
    "fast holzfrei",
    "holzfrei",
    "mit Recyclinganteil",
    "100% Recycling",
  ];

  dropDownFaerbung = [
    "cream",
    "white",
    "gelblichweiß",
    "stark gelblichweiß",
    "bläulichweiß",
    "weiß",
    "hochweiß",
    "farbig",
  ];

  paperCertificationList: Array<PaperCertification> = [];

  grammaturPattern = "^(0|[1-9]\\d*)$";

  doubleDigitPattern = "^(\\d+(?:.\\d{1,2})?)$";

  formatMessage = formatMessage;

  isValid() {
    return this.form.instance.validate().isValid;
  }

  constructor(private paperService: PaperService) {}

  ngOnInit(): void {
    this.paperService.findAllPaperCertifications().subscribe((certs) => {
      this.paperCertificationList = certs;
    });
  }

  resetValues() {
    this.form.instance.resetValues();
  }
}
