import { gql } from "apollo-angular";

export const FIND_ACTIVE_UPLOAD_LINKS = gql`
  query findActiveUploadLinks($orderId: UUID!) {
    findActiveUploadLinks(orderId: $orderId) {
      orderId
      orderPiece
      validUntilDate
      urlIdentifier
    }
  }
`;

export const GENERATE_UPLOAD_LINK = gql`
  mutation generateUploadLink($orderId: UUID!, $orderPiece: OrderPiece!) {
    generateUploadLink(orderId: $orderId, orderPiece: $orderPiece) {
      orderId
      orderPiece
      validUntilDate
      urlIdentifier
    }
  }
`;
