<div class="dx-tag">
  <div
    *ngIf="isPublisherGroup(organization)"
    class="dx-tag-content pr-11"
    [class]="hideColors ? '' : 'publisher-group-tag'"
    style="cursor: default !important"
  >
    {{ getPublisherGroupDisplayName(organization) }}
  </div>
  <div
    *ngIf="isPublisher(organization)"
    class="dx-tag-content pr-11"
    [class]="hideColors ? '' : 'publisher-tag'"
    style="cursor: default !important"
  >
    {{ organization.displayName }}
  </div>
  <div
    *ngIf="isStudio(organization)"
    class="dx-tag-content pr-11"
    [class]="hideColors ? '' : 'agency-tag'"
    style="cursor: default !important"
  >
    {{ organization.displayName }}
  </div>
</div>
