import { Component, NgModule } from "@angular/core";
import { DxButtonModule } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  formatMessage = formatMessage;

  constructor(
    private router: Router,
    private treeViewInstanceService: TreeViewInstanceService
  ) {}

  navigateAndResetTreeView(path: string) {
    this.router.navigate([path]);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }
}

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [DxButtonModule],
})
export class FooterModule {}
