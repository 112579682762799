<ng-container *ngIf="produkt$ | async as order; else noProdukt">
  <div
    class="d-flex"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.1); padding-bottom: 12px"
  >
    <div class="details-subtitle">
      <div>
        <span [title]="formatMessage('produkt.name')" style="cursor: default">
          {{ produktFormData.name }}
        </span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="environmentIsUsa">
    <app-product-form-us [produktFormData]="produktFormData">
    </app-product-form-us>
  </ng-container>

  <ng-container *ngIf="!environmentIsUsa">
    <app-product-form-de
      [produktFormData]="produktFormData"
      [produktTypenDatasource]="produktTypenDatasource"
    >
    </app-product-form-de>
  </ng-container>

  <div class="flex-grow d-flex" style="justify-content: end; align-items: end">
    <dx-button
      [hint]="formatMessage('produkt.details.save')"
      text="{{ formatMessage('produkt.details.save') }}"
      type="default"
      stylingMode="contained"
      style="margin-bottom: 10px"
      (onClick)="showConfirmationPopupForUpdateProdukt()"
    >
    </dx-button>
  </div>

  <div class="d-flex kachel-container">
    <app-kachel
      class="flex-grow properties-kachel"
      *ngIf="
        produktVariantenDataSource.length > 0 || priceScaleDataSource.length > 0
      "
    >
      <ng-container *ngIf="environmentIsUsa; else default">
        <app-product-price-scale-datagrid [dataSource]="priceScaleDataSource">
        </app-product-price-scale-datagrid>
      </ng-container>
      <ng-template #default>
        <app-produkt-produkt-varianten-datagrid
          [produktVariantenDataSource]="produktVariantenDataSource"
        ></app-produkt-produkt-varianten-datagrid>
      </ng-template>
    </app-kachel>
    <app-kachel class="flex-grow properties-kachel">
      <app-produkt-csv-upload
        (reloadEvent)="reloadDataGridData()"
        [produktData]="produktFormData"
        class="d-flex flex-grow flex-column kachel-component"
      ></app-produkt-csv-upload>
    </app-kachel>

    <app-kachel class="flex-grow properties-kachel">
      <app-produkt-spezifikation-overview
        [produktData]="produktFormData"
        class="d-flex flex-grow flex-column kachel-component"
      ></app-produkt-spezifikation-overview>
    </app-kachel>
  </div>
</ng-container>

<app-kachel>
  <app-produkt-spezifikation-option-management
    [options]="options"
    [produktId]="produktId"
  >
  </app-produkt-spezifikation-option-management>
</app-kachel>
<ng-template #noProdukt>
  <div
    class="
      d-flex
      flex-grow
      align-items-center
      justify-content-center
      text-center
    "
  >
    <dx-load-indicator [visible]="loading"></dx-load-indicator>
    <span *ngIf="!loading" style="font-weight: 300; font-size: 18px">
      {{ toastMessage }}
    </span>
  </div>
</ng-template>

<app-confirmation-dialog
  [(visible)]="showConfirmationPopup"
  [title]="confirmationPopupTitle"
  [message]="confirmationPopupMessage"
  [confirmButtonText]="confirmButtonText"
  (onConfirm)="update()"
></app-confirmation-dialog>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
