import { Component, NgModule, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DxListModule } from "devextreme-angular/ui/list";
import { DxContextMenuModule } from "devextreme-angular/ui/context-menu";
import { User } from "../../model/user/user";

@Component({
  selector: "app-user-panel",
  templateUrl: "user-panel.component.html",
  styleUrls: ["./user-panel.component.scss"],
})
export class UserPanelComponent {
  @Input()
  menuItems: any;

  @Input()
  menuMode!: string;
  @Input()
  user!: User | null;

  @Input()
  userButton;

  constructor() {}

  setContextMenuWidth() {
    const elementStyle = getComputedStyle(
      this.userButton.element.nativeElement
    );
    return parseFloat(elementStyle.width); // Gibt die Breite des Elements als Zahl aus
  }
}

@NgModule({
  imports: [DxListModule, DxContextMenuModule, CommonModule],
  declarations: [UserPanelComponent],
  exports: [UserPanelComponent],
})
export class UserPanelModule {}
