import { Component, OnDestroy, OnInit } from "@angular/core";
import { ExternalFileUploadService } from "../../shared/services/external-file-upload.service";
import { ActivatedRoute } from "@angular/router";
import {
  OrderInfo,
  UploadLink,
  UploadLinkInfo,
} from "../../shared/model/upload/uploadLink";
import { formatMessage } from "devextreme/localization";
import { Subscription } from "rxjs";
import { ScreenService } from "../../shared/services";
import { LocaleService } from "../../shared/services/locale.service";
import {
  getBookGraphicResourceForProductType,
  isSingleUploadOrderPiece,
} from "../../shared/model/productTypes";
import { OrderService } from "../../shared/services/order.service";
import { Order } from "../../shared/model/order/order";

@Component({
  selector: "app-external-file-upload",
  templateUrl: "./external-file-upload.component.html",
  styleUrls: ["./external-file-upload.component.scss"],
})
export class ExternalFileUploadComponent implements OnInit, OnDestroy {
  formatMessage = formatMessage;
  currentLocale: string | null;

  routerSubscription: Subscription;
  uploadLinkSubscription: Subscription;

  urlIdentifier: string;

  loading: boolean = true;

  uploadLink: UploadLink;
  orderPiece: string;
  orderInfo: OrderInfo;
  productType: string;

  statusLinkUploadNotAllowed: boolean;
  bookGraphicResource: string;
  guidelinesLink: string;

  uploadLinkErrorMessage: string = "";
  showErrorSubtitle: boolean = false;

  isXSmall: boolean;
  isMediumOrDownScreen: boolean;
  isLarge: boolean;
  isXLarge: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";

  showSuccessAlertForSingleUpload = false;
  successAlertMessage = "";

  constructor(
    private route: ActivatedRoute,
    private screen: ScreenService,
    private localeService: LocaleService,
    private orderService: OrderService,
    private externalFileUploadService: ExternalFileUploadService
  ) {}

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
    this.isMediumOrDownScreen = this.screen.sizes["screen-medium-or-down"];
    this.isLarge = this.screen.sizes["screen-large"];
    this.isXLarge = this.screen.sizes["screen-x-large"];

    this.currentLocale = this.localeService.getCurrentLocaleWithoutUser();
    this.guidelinesLink = this.getGuidelinesLink();

    this.routerSubscription = this.route.paramMap.subscribe((params) => {
      this.urlIdentifier = params.get("identifier") || "";

      this.loadData();
    });
  }

  private loadData() {
    this.uploadLinkSubscription = this.externalFileUploadService
      .findUploadLinkData(this.urlIdentifier)
      .subscribe(
        (uploadLinkInfo) => {
          this.uploadLink = uploadLinkInfo.uploadLink;
          this.orderInfo = uploadLinkInfo.orderInfo;
          this.statusLinkUploadNotAllowed = isLinkUploadAllowed(this.orderInfo);

          if (!this.statusLinkUploadNotAllowed) {
            this.uploadLinkErrorMessage = formatMessage(
              "upload.link.error.EXPIRED"
            );
          }

          console.log(this.statusLinkUploadNotAllowed);
          console.log(this.uploadLinkErrorMessage);
          this.orderPiece = this.uploadLink.orderPiece;

          try {
            this.setBookGraphicResource();
          } catch (e) {
            this.uploadLinkErrorMessage = formatMessage(
              "upload.link.error.invalid"
            );
            this.showErrorSubtitle = true;
          }

          this.loading = false;
        },
        (e) => {
          const linkInvalid = [
            "NOT_FOUND",
            "EXPIRED",
            "SINGLE_UPLOAD_CONSTRAINT_VIOLATION",
          ].includes(e.error);

          if (linkInvalid) {
            this.uploadLinkErrorMessage = formatMessage(
              "upload.link.error." + e.error
            );
          } else {
            this.uploadLinkErrorMessage = formatMessage("upload.link.error");
          }

          this.showErrorSubtitle = linkInvalid;

          this.loading = false;
        }
      );
  }

  private setBookGraphicResource() {
    this.productType = this.orderService.mapCategoryToProductType(
      this.orderInfo.category
    );

    if (this.productType === "Unknown") {
      throw new Error("Unknown Product type");
    }

    this.bookGraphicResource = getBookGraphicResourceForProductType(
      this.productType
    );
  }

  private getGuidelinesLink() {
    if (this.currentLocale === "de-DE") {
      return "https://www.ggp-media.de/service/";
    } else {
      return "https://www.ggp-media.de/en/service/";
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.uploadLinkSubscription?.unsubscribe();
  }

  handleUploadSuccess(fileName: string) {
    const isSingleUpload =
      this.orderInfo.readyForPrinting &&
      isSingleUploadOrderPiece(this.orderPiece);

    if (isSingleUpload) {
      this.successAlertMessage =
        formatMessage("upload.successText", fileName) +
        " " +
        formatMessage("upload.external.success.single.addition");
    }

    this.showSuccessAlertForSingleUpload = isSingleUpload;
  }

  handleFileAlreadyExists(fileName: string) {
    this.toastMessage = formatMessage("upload.fileAlreadyExists", fileName);
    this.showToast = true;
  }

  handleUploadError(fileName: string) {
    this.toastMessage = formatMessage("upload.errorText", fileName);
    this.showToast = true;
  }
}

export function isLinkUploadAllowed(orderInfo: OrderInfo): boolean {
  console.log(orderInfo.uploadStatus);
  if (
    orderInfo.uploadStatus == "DA_UEBERSCHRITTEN" ||
    orderInfo.uploadStatus == "IN_PRODUKTION" ||
    orderInfo.uploadStatus == "VERSENDET" ||
    orderInfo.uploadStatus == "FAKTURIERT" ||
    orderInfo.uploadStatus == "ERLEDIGT" ||
    orderInfo.uploadStatus == "STORNIERT"
  ) {
    return false;
  }
  return true;
}
