import { Component, Input, OnInit } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { isReadyForPrinting, Order } from "../../../model/order/order";
import { OrderService } from "../../../services/order.service";
import { LocaleService } from "src/app/shared/services/locale.service";

@Component({
  selector: "app-order-details-properties",
  templateUrl: "./order-details-properties.component.html",
  styleUrls: ["./order-details-properties.component.scss"],
})
export class OrderDetailsPropertiesComponent implements OnInit {
  formatMessage = formatMessage;

  @Input() order: Order;

  jobType: string;

  productType: string;

  currentLocale: string | null;

  constructor(
    private orderService: OrderService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.productType = this.orderService.mapCategoryToProductType(
      this.order.order.titleinfo.category
    );

    this.jobType = this.getJobtype(this.order);

    this.localeService
      .getCurrentLocale()
      .subscribe((locale) => (this.currentLocale = locale));
  }

  getJobtype(order): string {
    if (isReadyForPrinting(order)) {
      return formatMessage("order.jobtype.approved");
    } else {
      return formatMessage("order.jobtype.withDataEditing");
    }
  }

  getFormatInformation(order: Order, format: string) {
    let locale = this.currentLocale?.startsWith("de") ? "de-DE" : "en-EN";
    switch (format) {
      case "breite":
        return order.order.textpartprint.blockwidth.toLocaleString(locale);
      case "laenge":
        return order.order.textpartprint.blocklength.toLocaleString(locale);
      default:
        return null;
    }
  }
}
