<div *ngIf="showMissingInputs" class="dx-error-message">
  <app-alert
    type="error"
    [message]="
      formatMessage(
        'calculator.calculation.us.additional-specification.dropdown.error'
      )
    "
  ></app-alert>
</div>

<dx-list
  class="additional-options-list"
  selectionMode="multiple"
  [grouped]="true"
  [dataSource]="listDataSource"
  [showSelectionControls]="true"
  [repaintChangesOnly]="true"
  [selectedItemKeys]="[]"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onInitialized)="onInitialized($event)"
>
  <div *dxTemplate="let groupObj of 'group'" class="group">
    <div>
      {{
        formatMessage(
          "calculator.calculation.us.additional-specification.title.name",
          groupObj.key
        )
      }}
    </div>
    <div class="list-row-item">
      <div class="select-box-button"></div>

      <div class="item-1">
        {{
          formatMessage(
            "calculator.calculation.us.additional-specification.subtitle.name"
          )
        }}
      </div>
    </div>
  </div>
  <div *dxTemplate="let item of 'item'" class="item">
    <div class="list-row-item">
      <div class="item-1">
        <span *ngIf="!item?.optionPriceInformation[0]?.surcharge">{{
          item?.optionName
        }}</span>

        <span *ngIf="item?.optionPriceInformation[0]?.surcharge">
          {{
            item?.optionName +
              " " +
              item.optionPriceInformation[0]?.surcharge +
              "%"
          }}
        </span>
      </div>

      <div class="item-2" *ngIf="item?.optionType == 'INSERTS'; else userInput">
        <dx-select-box
          style="width: auto"
          [dataSource]="item.optionPriceInformation"
          [(value)]="item.userInput"
          displayExpr="insertPages"
          valueExpr="insertPages"
          [label]="
            formatMessage(
              'calculator.calculation.us.additional-specification.dropdown.insert'
            )
          "
          stylingMode="underlined"
          (onInitialized)="onInitializedDropdown(item)"
          (onSelectionChanged)="onDropDownSelectionChanged(item)"
        >
        </dx-select-box>
      </div>
      <ng-template #userInput>
        <div
          class="item-2"
          *ngIf="item?.requiredQuantityType == 'OPTION_VALUE'"
        >
          <dx-text-box
            style="width: auto"
            [(value)]="item.userInput"
            [label]="
              formatMessage(
                'calculator.calculation.us.additional-specification.userInput'
              )
            "
            stylingMode="underlined"
            (onValueChanged)="onDropDownSelectionChanged(item)"
          >
          </dx-text-box>
        </div>
      </ng-template>
    </div>
  </div>
</dx-list>
