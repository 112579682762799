import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NumberConverterService {
  language: string = "de-DE";

  setLanguage(lang?) {
    if (lang) {
      this.language = lang;
    }
  }

  convertToCurrency(num, dec?) {
    if (this.language == "de-DE") {
      if (num !== undefined) {
        if (dec) {
          return num
            .toFixed(dec)
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        } else {
          return num
            .toFixed(3) // always three decimal digits
            .replace(".", ",") // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        }
      } else return "";
    } else {
      if (num !== undefined) {
        if (dec) {
          return num.toFixed(dec);
        } else {
          return num.toFixed(3); // always three decimal digits
        }
      } else return "";
    }
  }

  convertToNumberWithThousandsSeparator(num) {
    if (this.language == "de-DE") {
      if (num !== undefined) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else return "";
    } else return num.toString();
  }

  constructor() {}
}
