import { PreislistenKatalog } from "./produkt";
import { Option } from "./option";

export class OptionGroup {
  id: string;
  groupName: string;
  sortId: number;
  preislistenKatalog: PreislistenKatalog;

  // US
  ancillaryType: string;
}
