import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFormComponent,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
} from "devextreme-angular";
import { CommonModule } from "@angular/common";
import { formatMessage } from "devextreme/localization";
import { AuthorizeInternalUserRequest } from "src/app/shared/model/user/userRequests";
import { IdentityToAuthorize } from "../../../model/user/user";
import { ScreenService } from "../../../services";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import {
  EXTENDED_EXTERNAL_USER_ROLES,
  INTERNAL_USER_ROLES,
  USA_ROLES,
} from "../../../model/roles";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-authorize-users-popup",
  templateUrl: "./authorize-users-popup.component.html",
  styleUrls: ["./authorize-users-popup.component.scss"],
})
export class AuthorizeUsersPopupComponent implements OnInit {
  @ViewChild(DxFormComponent) newUserForm: DxFormComponent;
  isXSmall: boolean;
  isSmall: boolean;
  isMediumOrDown: boolean;
  loginNameHidingPriority: number | undefined = undefined;
  emailHidingPriority: number | undefined = undefined;
  roleHidingPriority: number | undefined = undefined;

  formatMessage = formatMessage;
  internalUserRoles = INTERNAL_USER_ROLES;

  externalUserRolesDe = EXTENDED_EXTERNAL_USER_ROLES;
  internalUserRolesDe = INTERNAL_USER_ROLES.filter(
    (role) => !USA_ROLES.includes(role)
  );

  usaRoles = USA_ROLES;

  isEnvironmentUsa = environment.isUsa;

  @Input() users: IdentityToAuthorize[];

  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onSave = new EventEmitter<AuthorizeInternalUserRequest[]>();

  constructor(private screen: ScreenService) {
    this.translateRole = this.translateRole.bind(this);
  }

  ngOnInit(): void {
    this.isXSmall = this.screen.sizes["screen-x-small"];
    this.isSmall = this.screen.sizes["screen-small"];

    this.isMediumOrDown =
      this.isXSmall || this.isSmall || this.screen.sizes["screen-medium"];

    this.setTableHidingPriorities();
  }

  setTableHidingPriorities(): void {
    if (this.screen.sizes["screen-x-small"]) {
      this.loginNameHidingPriority = 6;
      this.roleHidingPriority = 7;
    } else if (this.screen.sizes["screen-small"]) {
      this.loginNameHidingPriority = 7;
    } else if (this.screen.sizes["screen-medium"]) {
      this.emailHidingPriority = 7;
    }
  }

  translateRole(data): string {
    return formatMessage(`user.role.${data}`);
  }

  resetRoles() {
    this.users.forEach((it) => (it.role = ""));
  }

  save() {
    const usersWithRole = this.users
      .filter((it) => it.role !== "")
      .map((it) => new AuthorizeInternalUserRequest(it.sub, it.role));

    this.onSave.emit(usersWithRole);

    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}

@NgModule({
  imports: [
    DxPopupModule,
    DxCheckBoxModule,
    DxFormModule,
    DxButtonModule,
    CommonModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    ActionPopupModule,
  ],
  declarations: [AuthorizeUsersPopupComponent],
  exports: [AuthorizeUsersPopupComponent],
})
export class AuthorizeUsersPopupModule {}
