export class OptionMappingDropdownValues {
  public widthValues = [4.125, 5, 6];

  public heightValues = [6.625, 6.75, 6.875, 7.5, 8, 9];

  public editionValues = [
    { displayExpr: "Trade", value: "TRD" },
    { displayExpr: "Mass Market", value: "MM" },
  ];

  public bindingValues = [
    { displayExpr: "Hardcover", value: "TRH" },
    { displayExpr: "Softcover", value: "TRS" },
    { displayExpr: "Premium", value: "MMP" },
    { displayExpr: "Rack", value: "MMR" },
    { displayExpr: "Digest", value: "MMD" },
  ];
}
