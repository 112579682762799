import { Component, Input, NgModule, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TreeViewInstanceService } from "../../services/treeViewInstance.service";
import { DxButtonModule } from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { Produkt } from "../../model/calculation/produkt";
import { ProduktService } from "../../services/produkt.service";

@Component({
  selector: "app-return-button",
  templateUrl: "./return-button.component.html",
  styleUrls: ["./return-button.component.scss"],
})
export class ReturnButtonComponent implements OnInit {
  @Input()
  returnPath: string;

  @Input()
  produkt: string;

  formatMessage = formatMessage;

  constructor(
    private router: Router,
    private treeViewInstanceService: TreeViewInstanceService,
    private produktService: ProduktService
  ) {}

  ngOnInit(): void {}

  navigateToPreviousPage(): void {
    if (this.produkt) {
      this.produktService.setSelectedProduktId(this.produkt);
    }
    setTimeout(() => this.router.navigate([this.returnPath]), 1);
    this.treeViewInstanceService.getTreeViewInstance()?.unselectAll();
  }
}

@NgModule({
  imports: [DxButtonModule],
  exports: [ReturnButtonComponent],
  declarations: [ReturnButtonComponent],
  providers: [],
})
export class ReturnButtonModule {}
