import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { formatMessage } from "devextreme/localization";
import { ProduktService } from "../../../../services/produkt.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { NavigationStart } from "@angular/router";
import { CalculatorService } from "../../../../services/calculator.service";
import {
  InformationWithId,
  ProduktInformation,
  SortedProduktInformation,
} from "../../../../model/calculation/produktInformation";
import { Produkt } from "../../../../model/calculation/produkt";

@Component({
  selector: "app-calculator-default-values",
  templateUrl: "./calculator-default-values.component.html",
  styleUrls: ["./calculator-default-values.component.scss"],
})
export class CalculatorDefaultValuesComponent implements OnInit, OnChanges {
  @Input()
  defaultValues: ProduktInformation[];
  @Input()
  selectedProdukt: Produkt;

  collapsed: any = true;
  sortedDefaultOptions: SortedProduktInformation[];
  formatMessage = formatMessage;

  subscription: Subscription;

  constructor(private calculatorService: CalculatorService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ("defaultValues" in changes) {
      this.sortedDefaultOptions = [];
      this.sortedDefaultOptions.push(
        this.mapProduktInformationToSortedProduktInformation(this.defaultValues)
      );
    }
  }

  private mapProduktInformationToSortedProduktInformation(value) {
    let options: SortedProduktInformation = {
      inhalt: {
        id: 10,
        group: "inhalt",
        value: value[0]["inhalt"],
      },
      vorNachsatz: {
        id: 20,
        group: "vorNachsatz",
        value: value[0]["vorNachsatz"],
      },
      broschurUmschlag: {
        id: 30,
        group: "broschurUmschlag",
        value: value[0]["broschurUmschlag"],
      },
      bezug: {
        id: 40,
        group: "bezug",
        value: value[0]["bezug"],
      },
      schutzumschlag: {
        id: 50,
        group: "schutzumschlag",
        value: value[0]["schutzUmschlag"],
      },
      sonstigesTeil: {
        id: 60,
        group: "sonstigesTeil",
        value: value[0]["sonstigesTeil"],
      },
      bindeArt: {
        id: 71,
        group: "bindeArt",
        value: value[0]["bindeArt"],
      },
      leimArt: {
        id: 72,
        group: "leimArt",
        value: value[0]["leimArt"],
      },
      kapitalBand: {
        id: 73,
        group: "kapitalBand",
        value: value[0]["kapitalBand"],
      },
      deckelPappenDicke: {
        id: 74,
        group: "deckelPappenDicke",
        value: value[0]["deckelPappenDicke"],
      },
      deckenPraegung: {
        id: 75,
        group: "deckenPraegung",
        value: value[0]["deckenPraegung"],
      },
      rueckenForm: {
        id: 76,
        group: "rueckenForm",
        value: value[0]["rueckenForm"],
      },
      rillungBroschurUmschlag: {
        id: 77,
        group: "rillungBroschurUmschlag",
        value: value[0]["rillungBroschurUmschlag"],
      },
      sonstigeWV: {
        id: 78,
        group: "sonstigeWV",
        value: value[0]["sonstigeWV"],
      },
      etiketten: {
        id: 80,
        group: "etiketten",
        value: value[0]["etiketten"],
      },
      verpackung: {
        id: 81,
        group: "verpackung",
        value: value[0]["verpackung"],
      },
      lieferung: {
        id: 83,
        group: "lieferung",
        value: value[0]["lieferung"],
      },
      sonstiges: {
        id: 90,
        group: "sonstiges",
        value: value[0]["sonstiges"],
      },
    };

    return options;
  }

  getItemKeys(item) {
    return Object.keys(item);
  }

  onSelectionChanged(e) {
    this.collapsed = e.component.option("selectedIndex") == -1;
  }

  getKeyByLanguage(key: string, itemData: InformationWithId) {
    if (key == "sonstigesTeil") {
      let match = new RegExp(/\[(.*?)\]/).exec(itemData.value);
      if (match) {
        return match[1];
      }
      return itemData.value;
    }
    if (key) {
      let keyValue = "calculator.pdf.produktInformationen." + key;
      return this.formatMessage(keyValue);
    }
    return;
  }

  getItemData(key: string, itemData: InformationWithId) {
    if (key == "sonstigesTeil") {
      let match = new RegExp(/^\[(.*?)\](.*)$/).exec(itemData.value);
      if (match) {
        return match[2];
      }
    }

    return itemData.value;
  }
}
